import { Box, Heading, Image } from "@chakra-ui/react";
// import { ButtonVariants } from "../../../theme/components/Button";
import ModalTemplate from "./ModalTemplate";
import { ButtonVariants } from "../../../theme/components/Button";
import ModalErrorIcon from "../../../assets/icons/components/Others/ModalErrorIcon";
import ModalSuccessIcon from "../../../assets/icons/components/Others/ModalSuccessIcon";
import { ReactElement, useEffect } from "react";
import ModalWaitingIcon from "../../../assets/icons/components/Others/ModalWaitingIcon";

interface ModalTemplatePropsV2 {
  /** Subtitle of the modal template */
  subtitle?: string;
  /** If the modal submit button is disabled or not */
  buttonDisabled?: boolean;
  /** Any child to be rendered by the modal */
  children?: JSX.Element | JSX.Element[];
  /** Show Close button icon */
  showCloseButtonIcon?: boolean;
  /** Show top right Close button */
  showCloseButton?: boolean;
  /** Callback for  when the save button of the modal is clicked */
  saveOnClick?: () => void;
  /** Callback for  when the close button of the modal is clicked */
  onClose: () => void;
  /** boolean to toggle modal open and close */
  isOpen: boolean;
  /** Any content to be rendered within the button (button display "Save" if this value is not defined) */
  footerContent?: JSX.Element | JSX.Element[];
  showFooterContent?: boolean;
  /** button text, will not be shown  */
  buttonText?: string;
  /** Show button */
  showSaveButton?: boolean;
  /** Button Loading */
  buttonLoading?: boolean;
  /** Any content Below the button */
  contentBelowFooter?: JSX.Element | JSX.Element[];
  /** custom button size */
  buttonVariant?: ButtonVariants;
  /** marginX: left and right(actual-px/4)*/
  marginX?: number;
  /** msx widht in px */
  maxWidth?: string;
  maxHeight?: string;
  paddingX?: string;
  paddingY?: string;
  /** allow modal to close on hover */
  closeOnOverlayClick?: boolean;
  scrollBehavior?: "inside" | "outside";
  // modal blue
  backdropFilter?: string;
  closeOnEsc?: boolean;
  isCentered?: boolean;
  borderRadius?: string | number;
  bgColor?: string;
  error?: boolean;
  extra?: ReactElement;
  text?: string | ReactElement;
  showConfetti?: boolean;
  hideIconWithConfetti?: boolean;
  customIcon?: ReactElement;
  waiting?: boolean;
  autoCloseInSeconds?: number;
  closeIconGtmEvent?: string;
  headingTextColor?: string;
}

const ModalTemplateV2 = (props: ModalTemplatePropsV2) => {
  // close modal after 5 seconds automatically
  useEffect(() => {
    if (props.autoCloseInSeconds) {
      if (props.isOpen) {
        setTimeout(() => {
          props.onClose();
        }, props.autoCloseInSeconds * 1000);
      }
    }
  }, [props.isOpen]);

  return (
    <ModalTemplate
      title=""
      marginX={2}
      bgColor="transparent"
      maxWidth={props.maxWidth || "504px"}
      modalOverlayProps={{ backgroundColor: "rgba(1, 10, 27, 0.9)" }}
      modalContentProps={{ shadow: "none", p: "0px" }}
      {...props}
    >
      <Box textAlign="center" position="relative">
        {props.customIcon && <Box mx="auto">{props.customIcon}</Box>}
        {props.showConfetti && (
          <Image pointerEvents={"none"} position="absolute" src="/confetti.gif" alt="img" /> // confetti gif part
        )}
        {props.waiting && <ModalWaitingIcon />}
        {!props.hideIconWithConfetti && (
          <>
            {" "}
            {props.error ? (
              <ModalErrorIcon />
            ) : (
              <Box w="151px" mx="auto">
                <ModalSuccessIcon />
              </Box>
            )}
          </>
        )}

        <Heading
          mt="9px"
          as="h3"
          color={props?.headingTextColor ? props?.headingTextColor : props.error ? "#FFB7A7" : "#24FF92"}
          //dangerouslySetInnerHTML={{ __html: props.text as string }}
        >
          {props.text}
        </Heading>
        {props.extra}
        {props.children}
      </Box>
    </ModalTemplate>
  );
};

export default ModalTemplateV2;
