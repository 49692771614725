/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Text } from "@chakra-ui/react";
import moment from "moment";

interface Props {
  day: string;
  date: string;
  slotAvailable: number;
  isSelected: boolean;
  setSelectedDaySlot: (value: any) => void;
  availability: any;
  startTime: any;
}

export const DateSlotCard = ({
  date,
  setSelectedDaySlot,
  slotAvailable,
  isSelected,
  availability,
  startTime,
}: Props) => {
  return (
    <Box
      textAlign="center"
      px="10px"
      py="8px"
      rounded="8px"
      display="flex"
      flexDirection="column"
      gap="6px"
      cursor="pointer"
      onClick={() => setSelectedDaySlot(availability)}
      bg={isSelected ? "#F2F6FF" : "#F2F2F5"}
      border="1px"
      borderColor={isSelected ? "#5a85e4" : "#C7C9D9"}
      color={isSelected ? "white" : "black"}
      minW={{ base: "120px", sm: "90px" }}
    >
      <Text fontSize="12px" color={isSelected ? "#05A660" : undefined}>
        {moment(startTime).format("dddd")}
      </Text>
      <Text fontSize={"14px"} fontWeight={"600"} color={isSelected ? "#05A660" : undefined}>
        {date}
      </Text>
      <Text fontSize="12px" color={isSelected ? "#05A660" : undefined}>
        {slotAvailable} Slot{slotAvailable > 1 ? "s" : ""}
      </Text>
    </Box>
  );
};
