import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
  isActive?: boolean;
}
export const SelfLearning = (props: Props) => (
  <Icon viewBox={props.isActive ? "0 0 24 24" : "0 0 20 20"} {...props}>
    {props.isActive ? (
      <>
        <path
          opacity="0.2"
          d="M20.25 4.5H3.75C3.55109 4.5 3.36032 4.57902 3.21967 4.71967C3.07902 4.86032 3 5.05109 3 5.25V15.75C3 15.9489 3.07902 16.1397 3.21967 16.2803C3.36032 16.421 3.55109 16.5 3.75 16.5H20.25C20.4489 16.5 20.6397 16.421 20.7803 16.2803C20.921 16.1397 21 15.9489 21 15.75V5.25C21 5.05109 20.921 4.86032 20.7803 4.71967C20.6397 4.57902 20.4489 4.5 20.25 4.5ZM10.5 13.5V7.5L15 10.5L10.5 13.5Z"
          fill="#3470E4"
        />
        <path
          d="M15.4163 9.87562L10.9163 6.87562C10.8033 6.80025 10.6719 6.75696 10.5363 6.75039C10.4006 6.74382 10.2657 6.77421 10.146 6.83831C10.0263 6.90241 9.92619 6.99782 9.85645 7.11436C9.7867 7.2309 9.74991 7.36419 9.75 7.5V13.5C9.74991 13.6358 9.7867 13.7691 9.85645 13.8856C9.92619 14.0022 10.0263 14.0976 10.146 14.1617C10.2657 14.2258 10.4006 14.2562 10.5363 14.2496C10.6719 14.243 10.8033 14.1998 10.9163 14.1244L15.4163 11.1244C15.5191 11.0559 15.6035 10.9631 15.6618 10.8542C15.7202 10.7452 15.7507 10.6236 15.7507 10.5C15.7507 10.3764 15.7202 10.2548 15.6618 10.1458C15.6035 10.0369 15.5191 9.94409 15.4163 9.87562ZM11.25 12.0984V8.90625L13.6481 10.5L11.25 12.0984ZM20.25 3.75H3.75C3.35218 3.75 2.97064 3.90804 2.68934 4.18934C2.40804 4.47064 2.25 4.85218 2.25 5.25V15.75C2.25 16.1478 2.40804 16.5294 2.68934 16.8107C2.97064 17.092 3.35218 17.25 3.75 17.25H20.25C20.6478 17.25 21.0294 17.092 21.3107 16.8107C21.592 16.5294 21.75 16.1478 21.75 15.75V5.25C21.75 4.85218 21.592 4.47064 21.3107 4.18934C21.0294 3.90804 20.6478 3.75 20.25 3.75ZM20.25 15.75H3.75V5.25H20.25V15.75ZM21.75 19.5C21.75 19.6989 21.671 19.8897 21.5303 20.0303C21.3897 20.171 21.1989 20.25 21 20.25H3C2.80109 20.25 2.61032 20.171 2.46967 20.0303C2.32902 19.8897 2.25 19.6989 2.25 19.5C2.25 19.3011 2.32902 19.1103 2.46967 18.9697C2.61032 18.829 2.80109 18.75 3 18.75H21C21.1989 18.75 21.3897 18.829 21.5303 18.9697C21.671 19.1103 21.75 19.3011 21.75 19.5Z"
          fill="#3470E4"
        />
      </>
    ) : (
      <>
        <path
          opacity="0.2"
          d="M17.375 3.75H3.625C3.45924 3.75 3.30027 3.81585 3.18306 3.93306C3.06585 4.05027 3 4.20924 3 4.375V13.125C3 13.2908 3.06585 13.4497 3.18306 13.5669C3.30027 13.6842 3.45924 13.75 3.625 13.75H17.375C17.5408 13.75 17.6997 13.6842 17.8169 13.5669C17.9342 13.4497 18 13.2908 18 13.125V4.375C18 4.20924 17.9342 4.05027 17.8169 3.93306C17.6997 3.81585 17.5408 3.75 17.375 3.75ZM9.25 11.25V6.25L13 8.75L9.25 11.25Z"
          fill={"white"}
        />
        <path
          d="M13.3469 8.22969L9.59688 5.72969C9.50273 5.66687 9.39329 5.6308 9.28024 5.62533C9.1672 5.61985 9.05479 5.64517 8.95501 5.69859C8.85523 5.75201 8.77183 5.83152 8.71371 5.92863C8.65559 6.02575 8.62493 6.13682 8.625 6.25V11.25C8.62493 11.3632 8.65559 11.4743 8.71371 11.5714C8.77183 11.6685 8.85523 11.748 8.95501 11.8014C9.05479 11.8548 9.1672 11.8801 9.28024 11.8747C9.39329 11.8692 9.50273 11.8331 9.59688 11.7703L13.3469 9.27031C13.4326 9.21326 13.5029 9.13591 13.5515 9.04513C13.6002 8.95436 13.6256 8.85298 13.6256 8.75C13.6256 8.64702 13.6002 8.54564 13.5515 8.45486C13.5029 8.36409 13.4326 8.28674 13.3469 8.22969ZM9.875 10.082V7.42188L11.8734 8.75L9.875 10.082ZM17.375 3.125H3.625C3.29348 3.125 2.97554 3.2567 2.74112 3.49112C2.5067 3.72554 2.375 4.04348 2.375 4.375V13.125C2.375 13.4565 2.5067 13.7745 2.74112 14.0089C2.97554 14.2433 3.29348 14.375 3.625 14.375H17.375C17.7065 14.375 18.0245 14.2433 18.2589 14.0089C18.4933 13.7745 18.625 13.4565 18.625 13.125V4.375C18.625 4.04348 18.4933 3.72554 18.2589 3.49112C18.0245 3.2567 17.7065 3.125 17.375 3.125ZM17.375 13.125H3.625V4.375H17.375V13.125ZM18.625 16.25C18.625 16.4158 18.5592 16.5747 18.4419 16.6919C18.3247 16.8092 18.1658 16.875 18 16.875H3C2.83424 16.875 2.67527 16.8092 2.55806 16.6919C2.44085 16.5747 2.375 16.4158 2.375 16.25C2.375 16.0842 2.44085 15.9253 2.55806 15.8081C2.67527 15.6908 2.83424 15.625 3 15.625H18C18.1658 15.625 18.3247 15.6908 18.4419 15.8081C18.5592 15.9253 18.625 16.0842 18.625 16.25Z"
          fill="currentColor"
        />
      </>
    )}
  </Icon>
);

export default SelfLearning;
