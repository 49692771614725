import { Box, Flex, LayoutProps, Text, VisuallyHidden } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { CalendarIcon } from "../../assets/icons/components";
import "./date.css";
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/dayjs";

dayjs.extend(relativeTime);

interface Props {
  w?: LayoutProps["w"];
  value: Dayjs | null;
  onChange: (value: Dayjs) => void;
  isInvalid?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  showRelativeDate?: boolean;
  onClickCallback?: () => void;
  yearAndMonthOnly?: boolean;
  bgColor?: string;
  selectedOption?: string;
}

const DateSelect = ({ w = "full", value, onChange, isInvalid, errorMessage, disabled, showRelativeDate, onClickCallback, yearAndMonthOnly, bgColor, selectedOption }: Props) => {
  const [date, setDate] = useState<Dayjs | null>(value);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleDateChange = (date: Dayjs) => {
    setDate(date);
    onChange(date);
    setIsOpen(false);
  };

  useEffect(() => {
    if (value) {
      setDate(value);
    }
  }, [value]);

  const formatDate = (date: Dayjs) => {
    return date && date.format("DD / MM / YYYY");
  };
  return (
    <Box bgColor={bgColor}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Flex
          onClick={() => {
            if (!disabled) {
              setIsOpen(!isOpen);
              onClickCallback && onClickCallback();
            }
          }}
          cursor="pointer"
          px="0.75rem"
          justifyContent="space-between"
          alignItems="center"
          h="3rem"
          borderRadius="ms-8"
          border="1px solid"
          borderColor={isInvalid ? "ms-error" : "ms-grey.300"}
          w={w}
          bgColor={disabled ? "ms-grey.50" : "#fff"}
        >
          <Text textStyle="body1" color={date ? (disabled ? "ms-grey.500" : "ms-red.900") : "ms-grey.400"}>
            {date
              ? `${yearAndMonthOnly ? date.format("MMM / YYYY") : formatDate(date)}${showRelativeDate ? " - " + dayjs().from(dayjs(date), true) + " old" : ""}`
              : yearAndMonthOnly
              ? "MM / YYYY"
              : "DD / MM / YYYY"}
          </Text>
          <Box>
            <CalendarIcon boxSize="20px" />
          </Box>
        </Flex>
        <VisuallyHidden>
          {yearAndMonthOnly ? (
            <DatePicker
              open={isOpen}
              // initial focus year should be 2021
              initialFocusedDate={dayjs().year(2024)}
              value={date}
              onChange={(date) => handleDateChange(dayjs(date))}
              animateYearScrolling
              onClose={() => setIsOpen(false)}
              views={["year", "month"]}
              label="Year and Month"
              helperText="Start from year selection"
              openTo="year"
              minDate={selectedOption === "cs" ? dayjs().month(3).toDate() : dayjs().subtract(34, "year")}
              maxDate={selectedOption === "nwns" ? dayjs().toDate() : dayjs().add(20, "year")}
            />
          ) : (
            <DatePicker open={isOpen} value={date} onChange={(date) => handleDateChange(dayjs(date))} animateYearScrolling onClose={() => setIsOpen(false)} maxDate={new Date()} />
          )}
        </VisuallyHidden>
      </MuiPickersUtilsProvider>
      {isInvalid && (
        <Text color="ms-error" mt="ms-8" textStyle="body2">
          {errorMessage}
        </Text>
      )}
    </Box>
  );
};

export default DateSelect;
