import { Box, Image, ChakraProvider, Heading, useBreakpointValue, Button } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import { updateProfileConsentValues } from "./api/utils/api/profile";
import { QueryClient, QueryClientProvider, useMutation } from "react-query";
// import { Image } from "@chakra-ui/react";
import theme from "./theme/theme.config";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import ApplicationContextProvider, { AppContext, ApplicationActionType } from "./context/ApplicationContext";
import * as Sentry from "@sentry/react";

import { updateUserIntent } from "./api/utils/api";
import { ModalTemplate } from "./components/common/general";
import { GreenCheckIcon } from "./assets/images";
import { GetProfileType } from "./api/schemas/schema";
import useSnackBar from "./components/common/general/SnackBar";
import { axiosErrorHandler } from "./api/utils/error";
import { useProfile } from "./api/utils/hooks/useProfile";
import OTPVerifyModalV2 from "./components/common/V2/OTPVerifyModalV2";
import UpdatePhoneModalContent from "./components/V2/Profile/UpdatePhoneModalContent";
import { sendPhoneOtpV2 } from "./api/utils/api/v2/profile";
import OnboardingContextProvider from "./context/OnbaordingContext";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
});
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any; //
  }
}
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Razorpay: any; //
  }
}

const App = () => {
  const state = useContext(AppContext)[0];
  const dispatch = React.useContext(AppContext)[1];
  const location = useLocation();

  useEffect(() => {
    // for user intent
    const url = new URL(window.location.href);
    const search = url.hash.split("?")[1];
    const urlIntent = new URLSearchParams(search).get("intent") || "";
    const urlIntent_key = new URLSearchParams(search).get("intent_key") || "";

    if (urlIntent) {
      localStorage.setItem("intent", urlIntent);
      localStorage.setItem("intent_key", urlIntent_key);
    }

    const localIntent = localStorage.getItem("intent");
    const localIntent_key = localStorage.getItem("intent_key");

    if (localIntent && state.profileData?.slug && !state.profileData?.intent_data) {
      updateUserIntent({
        intent_data: {
          intent: urlIntent || localIntent || "",
          intent_key: urlIntent_key || localIntent_key || "",
        },
        profile_slug: state.profileData?.slug,
      });
    }
    // if email or whatsapp updates type should be boolean
    const is_email_updates_choice_selected = typeof state.profileData?.email_updates === "boolean";
    const is_whatsapp_updates_choice_selected = typeof state.profileData?.whatsapp_updates === "boolean";
    const is_ivr_consent_choice_selected = typeof state.profileData?.ivr_consent === "boolean";

    // if (
    //   state.profileData?.slug &&
    //   (!is_email_updates_choice_selected ||
    //     !is_whatsapp_updates_choice_selected ||
    //     !is_ivr_consent_choice_selected)
    // ) {
    //   dispatch({
    //     type: ApplicationActionType.SET_SHOW_CONSENT_MODAL,
    //     payload: { showConsentModal: true },
    //   });
    // } else {
    //   dispatch({
    //     type: ApplicationActionType.SET_SHOW_CONSENT_MODAL,
    //     payload: { showConsentModal: false },
    //   });
    // }
  }, [state.profileData?.slug]);

  const { data: profile, isLoading: profileLoading, refetch: refetchProfile } = useProfile();
  const { mutate: sendPhoneOtpMutate, isLoading: sendPhoneOtpLoading } = useMutation(sendPhoneOtpV2, {
    onSuccess: (data) => {
      snackbar.success("Otp sent on mobile!");
      if (!showPhoneOtpModal) {
        setShowPhoneOtpModal(true);
      }
    },
    onError: (error) => {
      setShowUpdatePhoneModal(true);
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        if (e.message.includes("verified")) {
          setShowUpdatePhoneModal(true);
        }
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });
  useEffect(() => {
    if (!profile) return;
    if (!profile.otp_verified) {
      if (profile.phone_number) {
        sendPhoneOtpMutate(profile.phone_number);
      } else {
        setShowUpdatePhoneModal(true);
      }
    }
  }, [profile]);

  useEffect(() => {
    if (typeof window.location.host !== "string") {
      return;
    }
    // const isScholar = window.location.host.includes("prepleaf.com") ? true : false;
    const isScholar = false;
    dispatch({
      type: ApplicationActionType.SET_SCHOLAR_STATUS,
      payload: { isScholar },
    });
  }, []);

  useEffect(() => {
    if (window && window.dataLayer) {
      window.dataLayer.push({ user_id: profile?.slug });
    }
  }, [profile?.slug]);

  useEffect(() => {
    if (state.profileData?.redirect_to_learnworld && state.profileData?.learnworld_url) {
      window.location.href = state.profileData?.learnworld_url;
    }
  }, [state.profileData?.redirect_to_learnworld]);

  // Changing og image for prepleaf and masai dashboard
  useEffect(() => {
    const tag = document.querySelector("[property='og:image']");

    if (window.location.toString().includes("prepleaf.com")) {
      tag?.setAttribute("content", "https://masai-website-images.s3.ap-south-1.amazonaws.com/Prepleaf_Dashboard_ee0c5dce0c.png");
    } else {
      tag?.setAttribute("content", "https://d27028dliefpk3.cloudfront.net/brand/masai-school.png");
    }
  }, []);

  const [loading, setLoading] = React.useState(false);
  const snackbar = useSnackBar();
  const handleConsentConfirm = async (isAllowed: boolean) => {
    if (!state.profileData?.slug) {
      snackbar.error("Something went wrong, please try again!");
      return;
    }
    let response: GetProfileType;
    try {
      setLoading(true);
      response = await updateProfileConsentValues({
        masai_newsletter: isAllowed,
        email_updates: isAllowed,
        ivr_consent: isAllowed,
        whatsapp_updates: isAllowed,
      });
      snackbar.success("Consent updated successfully!");
      setLoading(false);
      dispatch({
        type: ApplicationActionType.SET_SHOW_CONSENT_MODAL,
        payload: { showConsentModal: false },
      });
    } catch (err) {
      setLoading(false);
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
      return;
    }
  };
  const [updatedPhone, setUpdatedPhone] = useState<string>("");
  const [showUpdatePhoneModal, setShowUpdatePhoneModal] = useState(false);
  const [showPhoneOtpModal, setShowPhoneOtpModal] = useState(false);

  return (
    <>
      <Routes />
      {/* -------------------------------------- Phone verify Modal flow----------------------------------------- */}
      <OTPVerifyModalV2
        showPhoneOtpModal={showPhoneOtpModal}
        setShowPhoneOtpModal={setShowPhoneOtpModal}
        updatedPhone={updatedPhone}
        setShowUpdatePhoneModal={setShowUpdatePhoneModal}
        isClosable={false}
      />
      {/* Phone update modal */}
      <ModalTemplate
        title=""
        isOpen={showUpdatePhoneModal ? showUpdatePhoneModal : false}
        onClose={() => {
          // console.log("clearing timers due to update Phone modal closed!");
          refetchProfile();
          setShowUpdatePhoneModal && setShowUpdatePhoneModal(false);
        }}
        marginX={2}
        maxWidth="464px"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <UpdatePhoneModalContent showPhoneOtpModal={showPhoneOtpModal} setShowPhoneOtpModal={setShowPhoneOtpModal} updatedPhone={updatedPhone} setUpdatedPhone={setUpdatedPhone} />
      </ModalTemplate>
      <ModalTemplate
        title=""
        isOpen={state.showConsentModal ? true : false}
        onClose={() => {
          dispatch({
            type: ApplicationActionType.SET_SHOW_CONSENT_MODAL,
            payload: { showConsentModal: false },
          });
        }}
        marginX={2}
        maxWidth="504px"
        showCloseButtonIcon={false}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered={true}
      >
        <Box>
          <Image w="56px" h="56px" mx="auto" src={GreenCheckIcon} alt="consent check" />
          <Box textAlign={"center"}>
            <Heading as="h3" mt="16px">
              Confirmation
            </Heading>
            <Heading as="h6" mt="8px">
              I would like to receive
              <Heading as="h6" color="ms-blue.500" d="inline">
                {" "}
                Email{" "}
              </Heading>
              and{" "}
              <Heading as="h6" color="ms-blue.500" d="inline">
                {" "}
                Whatsapp{" "}
              </Heading>{" "}
              updates.{" "}
            </Heading>
          </Box>
          <Box justifyContent={"center"} mt="24px" d="flex" gap="16px">
            <Button
              sx={{
                "&:focus": {
                  outline: "none",
                },
              }}
              disabled={loading}
              variant="secondary"
              onClick={() => {
                handleConsentConfirm(false);
              }}
            >
              Deny
            </Button>
            <Button
              disabled={loading}
              variant="primary"
              onClick={() => {
                handleConsentConfirm(true);
              }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </ModalTemplate>
      {/* <Image w={"100%"} h={"100%"} src={isMobile ? MaintenanceMobile : Maintenance} /> */}
    </>
  );
};
const queryClient = new QueryClient();

// Check if the URL contains hash-based routes
if (window.location.hash && window.location.hash !== "#/") {
  // Extract the route without the hash
  const cleanRoute = window.location.hash.replace("#", "");
  // Redirect to the clean route
  window.location.replace(cleanRoute);
} else {
  // Render your app with BrowserRouter
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme}>
            <ApplicationContextProvider>
              <OnboardingContextProvider>
                <App />
              </OnboardingContextProvider>
            </ApplicationContextProvider>
          </ChakraProvider>
        </QueryClientProvider>
      </Router>
    </React.StrictMode>,
    document.getElementById("root")
  );
}
