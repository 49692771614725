import {
  CAPOneTimeLink,
  ErrorResponseModelType,
  GetApplicationType,
  GetBatch,
  GetCollegeType,
  GetContestType,
  GetContestURLType,
  GetCouponType,
  GetCourse,
  GetMSATAssessmentInfoType,
  GetProfileType,
  GetReferralsType,
  GetUnappliedBatch,
  LeaderboardType,
  PaymentInfoOfRazorpayType,
  ReferralProgramInformationType,
  ReferralsInputType,
  IsEntityRatedResponse,
  GetRegistrationFeeType,
  ReferralAddressType,
  ReferralCoinResponseType,
  GetFormFilledStatusType,
  RequestCallbackResponseType,
  PaymentType,
} from "../schemas/schema";
import api from "./axios";

export const listCourses = async (): Promise<GetCourse[]> => {
  const client = await api.client;
  const response = await client.list_courses_courses_get();
  return response.data;
};

export const listBatches = async (): Promise<GetBatch[]> => {
  const client = await api.client;
  const response = await client.list_batches_batches_get();
  return response.data;
};

export const listUnappliedBatches = async ({ profile_slug }: { profile_slug: string }): Promise<GetUnappliedBatch[]> => {
  const client = await api.client;

  const response = await client.list_unapplied_batches_unapplied_batches_get(profile_slug);
  // batchData.course?.course_programs
  if (typeof window.location.host === "string" && window.location.host.includes("prepleaf")) {
    const filteredResponse = response.data.filter((batchData) => {
      const { course } = batchData;

      const programNames = course?.course_programs?.map((program) => program.program?.name);
      const isScholar = programNames?.includes("Scholar");
      const isCap = programNames?.includes("CAP");
      return isScholar || isCap;
    });
    return filteredResponse;
  }
  return response.data;
};

export const getServerTime = async () => {
  const client = await api.client;
  const response = await client.server_time_server_time_get();
  return response.data;
};
export const getCourseById = async (id: string): Promise<GetCourse> => {
  const client = await api.client;
  const response = await client.get_course_courses__course_id__get({ course_id: id });
  return response.data;
};

export const getBatchById = async (id: string): Promise<GetBatch> => {
  const client = await api.client;
  const response = await client.get_batch_batches__batch_id__get({ batch_id: id });
  return response.data;
};

export const getApplicationsByUser = async ({ profile_slug, limit = 10, offset = 0 }: { profile_slug: string; limit?: number; offset?: number }): Promise<GetApplicationType[]> => {
  const client = await api.client;
  const response = await client.list_applications_applications_get(null, null, {
    params: {
      profile_slug,
      limit,
      offset,
    },
  });
  if (typeof window.location.host === "string" && window.location.host.includes("prepleaf")) {
    // filter applications whose belong_to_programs array contains MasaiX or Masai_One
    const filteredResponse = response.data.filter((application) => {
      const { belong_to_programs } = application;
      const isCap = belong_to_programs?.includes("CAP");
      const isScholar = belong_to_programs?.includes("Scholar");
      return isCap || isScholar;
    });
    return filteredResponse;
  }
  // add filtteredResponse to response.data
  return response.data;
};

export const getApplicationById = async ({ profile_slug, application_slug }: { profile_slug: string; application_slug: string }): Promise<GetApplicationType> => {
  const client = await api.client;
  const response = await client.get_application_applications__application_slug__get(application_slug, null, {
    params: {
      profile_slug,
    },
  });
  return response.data;
};

export const getProfileDetails = async (profile_slug?: string): Promise<GetProfileType> => {
  const client = await api.client;
  const response = await client.get_profile_profile_get(null, null, {
    params: {
      profile_slug,
    },
  });
  return response.data;
};

export const createApplication = async ({ profile_slug, batch_campus_id }: { profile_slug: string; batch_campus_id: number }): Promise<GetApplicationType> => {
  const client = await api.client;
  const response = await client.create_application_applications_post(
    null,
    {
      batch_campus_id,
    },
    {
      params: {
        profile_slug,
      },
    }
  );
  return response.data;
};

export const createMettlAssessment = async ({
  profile_slug,
  application_slug,
  is_practice,
  is_mobile,
  isScholarhipTest,
  isCodingTest,
}: {
  profile_slug: string;
  application_slug: string;
  is_practice: boolean;
  is_mobile: boolean;
  isScholarhipTest?: boolean;
  isCodingTest?: boolean;
}) => {
  const client = await api.client;
  let response;
  if (isScholarhipTest) {
    response = await client.create_scholarship_assessment_applications_create_scholarship_assessment_post(null, {
      application_slug,
      is_practice,
    });
  } else if (isCodingTest) {
    response = await client.create_coding_assessment_applications_create_coding_assessment_post(null, {
      application_slug,
      is_practice,
    });
  } else {
    response = await client.create_mettl_assessment_applications_mettl_assesment_post(null, {
      application_slug,
      is_practice,
      is_mobile,
    });
  }
  return response.data;
};

//refer users
export const referUsers = async ({ profile_slug, referralData }: { profile_slug: string; referralData: ReferralsInputType[] }): Promise<ReferralsInputType[]> => {
  const client = await api.client;
  const response = await client.refer_users_profile_refer_users_post(null, referralData, {
    params: {
      profile_slug,
    },
  });
  return response.data;
};

export const getReferralsByUser = async ({ profile_slug, limit = 6, offset = 0 }: { profile_slug: string; limit?: number; offset?: number }): Promise<GetReferralsType> => {
  const client = await api.client;
  const response = await client.get_profile_referrals_profile_get_referrals_v2_get(null, null, {
    params: {
      profile_slug,
      limit,
      offset,
    },
  });
  return response.data;
};

//update referral
export const updateReferralCode = async ({ profile_slug, referral_code }: { profile_slug: string; referral_code?: string }): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.update_referral_code_api_func_profile__profile_slug__update_referral_code_patch({ profile_slug }, null, {
    params: {
      referral_code,
    },
  });
  return response.data;
};
export const getReferralDetails = async (profile_slug?: string): Promise<ReferralProgramInformationType> => {
  const client = await api.client;
  const response = await client.referral_program_information_profile_referral_program_information_get(null, null, {
    params: {
      profile_slug,
    },
  });
  return response.data;
};

export const sendEmailOtp = async (profile_slug: string, new_email?: string): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.send_email_verify_otp_profile__profile_slug__send_email_update_otp_patch({ profile_slug }, null, {
    params: {
      new_email,
    },
  });
  return response.data;
};

export const sendEmailOtpV2 = async (new_email?: string): Promise<ErrorResponseModelType> => {
  console.log("new_email updated", new_email);
  const client = await api.client;
  const response = await client.send_otp_v2_email_verification_send_otp_post(null, {
    new_email: new_email || "",
  });
  return response.data;
};

export const verifyEmailOtp = async (profile_slug: string, otp_input: string): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.verify_email_update_otp_profile__profile_slug__verify_email_update_otp_patch({ profile_slug }, null, {
    params: {
      otp_input,
    },
  });
  return response.data;
};

export const getBatchDocs = async (): Promise<unknown | ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.batchDocs_batch_docs_get();
  return response.data;
};
export const getColleges = async (): Promise<GetCollegeType[]> => {
  const client = await api.client;
  const response = await client.get_colleges_colleges_get();
  return response.data;
};
export const createCollege = async ({ name, created_by }: { name: string; created_by: string }) => {
  const client = await api.client;
  //name, created_by
  const response = await client.create_college_college_post(null, { name, created_by });
  return response.data;
};
export const getCity = async (): Promise<GetCollegeType[]> => {
  const client = await api.client;
  const response = await client.get_cities_cities_get();
  return response.data;
};
export const createCity = async ({ name, created_by }: { name: string; created_by: string }) => {
  const client = await api.client;
  //name, created_by
  const response = await client.create_city_city_post(null, { name, created_by });
  return response.data;
};
export const getMSATAssessmentInfo = async ({ application_slug, profile_slug }: { application_slug: string; profile_slug: string }): Promise<GetMSATAssessmentInfoType[]> => {
  const client = await api.client;
  const response = await client.get_msat_assesment_info_applications__application_slug__msat_assesment_info_get({ application_slug }, null, {
    params: {
      profile_slug,
    },
  });
  return response.data;
};
// generate razorpay order id
export const generateRazorpayOrderId = async ({
  application_slug,
  paymentType,
  payment_consent,
  source = undefined,
}: {
  application_slug: string;
  paymentType: "full-payment" | "subscription-payment";
  payment_consent: boolean;
  source: string | undefined;
}): Promise<PaymentInfoOfRazorpayType> => {
  const client = await api.client;
  const response = await client.generate_razorpay_order_id_generate_razorpay_order_id_get({ application_slug, payment_consent, payment_type: paymentType, source }, null);

  return response.data;
};
// generate razorpay order id for masai one
export const generateRazorpayOrderIdMasaiOne = async ({
  application_slug,
  payment_consent,
  source = undefined,
}: {
  application_slug: string;
  payment_consent: boolean;
  source: string | undefined;
}): Promise<GetRegistrationFeeType> => {
  const client = await api.client;
  const response = await client.generate_razorpay_order_id_for_registration_fee_registration_fee_razorpay_order_id_get({ application_slug, payment_consent, source }, null);
  return response.data;
};

// stage will be changes from PAYMENT_PENDING to SCHOLARSHIP_TEST_TO_BE_TAKEN
export const applyForScholarship = async ({ application_slug }: { application_slug: string }): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.apply_for_scholarship_applications__application_slug__apply_for_scholarship_get({ application_slug }, null);
  return response.data;
};

// stage will be changes from current stage to PAYMENT_PENDING
export const proceedToPaymentPending = async ({ application_slug }: { application_slug: string }): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.proceed_to_payment_applications__application_slug__proceed_to_payment_get({ application_slug }, null);
  return response.data;
};
// stage will be changes from current stage to INTERVIEW_BOOKING_PENDING
export const proceedToInterviewPending = async ({ application_slug }: { application_slug: string }): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.interview_pending_applications__application_slug__interview_pending_get({ application_slug }, null);
  return response.data;
};
export const razorpaySuccessReport = async ({
  profile_slug,
  application_slug,
  razorpay_payment_id,
  razorpay_order_id,
  razorpay_subscription_id,
  razorpay_signature,
}: {
  profile_slug?: string;
  application_slug?: string;
  razorpay_payment_id?: string;
  razorpay_order_id?: string;
  razorpay_subscription_id?: string;
  razorpay_signature?: string;
}): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.report_razorpay_success_report_razorpay_success_post(
    null,
    {
      razorpay_payment_id,
      razorpay_order_id,
      razorpay_subscription_id,
      razorpay_signature,
    },
    {
      params: {
        application_slug,
      },
    }
  );
  return response.data;
};
// get contests
export const getContests = async ({ limit, offset }: { limit?: number; offset?: number }): Promise<GetContestType[]> => {
  const client = await api.client;
  const response = await client.get_contests_contests_get({ limit, offset }, null);
  return response.data;
};

// contest register
export const registerForContest = async ({ contest_id }: { contest_id: number }): Promise<GetContestType> => {
  const client = await api.client;
  const response = await client.register_for_contest_register_for_contest_post(null, {
    contest_id,
  });
  return response.data;
};
export const getContestById = async (id: string): Promise<GetContestType> => {
  const client = await api.client;
  const response = await client.get_contest_contest__contest_id__get(id);
  return response.data;
};

export const verifyCoupon = async ({
  coupon_code,
  course_id,
  paymentType,
  is_partial_payment,
}: {
  coupon_code: string;
  course_id: number;
  paymentType: PaymentType;
  is_partial_payment: boolean;
}): Promise<GetCouponType> => {
  const client = await api.client;
  const response = await client.verify_coupon_verify_coupon_post(null, {
    coupon_code,
    course_id,
    paymentType,
    is_partial_payment,
  });
  return response.data;
};

// contest start
export const startContestEvent = async ({ contest_id }: { contest_id: number }): Promise<GetContestURLType> => {
  const client = await api.client;
  const response = await client.start_contest_start_contest_post(null, {
    contest_id,
  });
  return response.data;
};

export const getLeaderboardByContestId = async ({ contestId, limit = 10, offset = 0 }: { contestId: number; limit?: number; offset?: number }): Promise<LeaderboardType> => {
  const client = await api.client;
  const response = await client.get_leaderboard_api_contests__contest_id__leaderboard_get(contestId, null, {
    params: {
      limit,
      offset,
    },
  });
  return response.data;
};

export const sendEventForTracking = async ({
  payment_pending_next_button_click,
  payment_pending_continue_without_scholarship_click,
  application_slug,
}: {
  payment_pending_next_button_click: boolean;
  payment_pending_continue_without_scholarship_click: boolean;
  application_slug: string;
}): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.track_data_applications__application_slug__track_data_post(
    { application_slug },
    {
      payment_pending_next_button_click,
      payment_pending_continue_without_scholarship_click,
    }
  );
  return response.data;
};

export const applyFor2ndAttemptOnAssessment = async ({ application_slug }: { application_slug: string }): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.apply_for_second_attempt_on_assessment_applications__application_slug__apply_for_second_attempt_on_assessment_get({ application_slug }, null);
  return response.data;
};

export const capBookADemoOneTimeLink = async ({
  name,
  email_address,
  in_college,
  graduation_year,
  college_id,
  phone_number,
  source,
}: {
  name: string;
  email_address: string;
  in_college: boolean;
  graduation_year?: string;
  college_id?: number;
  phone_number: string;
  source?: string;
}): Promise<CAPOneTimeLink> => {
  const client = await api.client;
  const response = await client.cap_demo_one_time_link_cap_demo_one_time_link_post(null, {
    name,
    email_address,
    in_college,
    graduation_year: graduation_year ? graduation_year : undefined,
    college_id: college_id && college_id >= 0 ? college_id : undefined,
    phone_number,
    source,
  });
  return response.data;
};

export const capCreateADemoSession = async ({ phone_number, calendly_event_uri, fe_route }: { phone_number: string; calendly_event_uri: string; fe_route: string }): Promise<CAPOneTimeLink> => {
  const client = await api.client;
  const response = await client.create_cap_demo_session_cap_demo_session_post(null, {
    phone_number,
    calendly_event_uri,
    fe_route,
  });
  return response.data;
};

export const submitOnboardingDetailsForCap = async ({ application_slug, payment_method_id }: { application_slug: string; payment_method_id: string }): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.onboarding_details_for_cap_onboarding_details_for_cap_post(null, {
    application_slug,
    payment_method_id,
  });
  return response.data;
};

export const requestCallback = async ({
  name,
  phone_number,
  source,
}: {
  name: string;
  phone_number: string;
  source: "ONWARD_PAYMENT_DETAILS_PAGE" | "ONWARD_ONBOARDING_PAGE" | "PREPLEAF";
}): Promise<RequestCallbackResponseType> => {
  const client = await api.client;
  const response = await client.request_callback_request_callback_post(null, {
    name,
    phone_number,
    source,
  });
  return response.data;
};

export const rateEntity = async ({ points, comment, entity_type, entity_id }: { points: number; comment: string; entity_type: "APPLICATION"; entity_id: number }): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.rate_entity_rate_entity_post(null, {
    points,
    comment,
    entity_type,
    entity_id,
  });
  return response.data;
};

export const hasEntityRated = async ({ entity_type, entity_id }: { entity_type: "APPLICATION"; entity_id: number }): Promise<IsEntityRatedResponse> => {
  const client = await api.client;
  const response = await client.is_entity_rated_is_entity_rated_post(null, {
    entity_type,
    entity_id,
  });
  return response.data;
};

//reaattempt msat: move state from msat fail to msat to be taken
export const reattemptMsatMasaiOne = async ({ application_slug }: { application_slug: string }) => {
  const client = await api.client;
  const response = await client.reattempt_assessment_applications__application_slug__re_attempt_assessment_get(application_slug);
  return response.data;
};

// get payment status
export const fetchPaymentStatus = async () => {
  const client = await api.client;
  const response = await client.get_registration_fee_payment_details_registration_fee_payment_details_get();
  return response.data;
};

// update user intent
export const updateUserIntent = async ({ intent_data, profile_slug }: { intent_data: { [key: string]: string }; profile_slug: string }): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.update_user_intent_profile__profile_slug__update_user_intent_patch(profile_slug, {
    intent_data,
  });
  return response.data;
};

// redeem coins
export const redeemCoins = async ({ address }: { address: ReferralAddressType }): Promise<ReferralCoinResponseType> => {
  const client = await api.client;
  const response = await client.redeem_coins_profile_redeem_coins_post(null, address);
  return response.data;
};

export const getAadhaarFormFilledStatus = async ({ application_slug }: { application_slug: string }): Promise<GetFormFilledStatusType> => {
  const client = await api.client;
  const response = await client.get_form_responses_profile_check_form_responses__application_slug__get({ application_slug });
  return response.data;
};
