import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";

let lastFetchedAt = 0;
let lastFetchedTime = 0;

export async function getServerTime(): Promise<number> {
  // cache for 5 min
  if (lastFetchedAt && lastFetchedAt + 300 * 1000 > new Date().getTime()) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(lastFetchedTime);
      }, 1000);
    });
  }
  const res = await fetch("https://config.masaischool.com/time");
  const data = await res.json();
  lastFetchedAt = new Date().getTime();
  lastFetchedTime = new Date(data.timestamp * 1000).getTime() as number;
  return lastFetchedTime;
}

export function useTimeLeft(till: string) {
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const timeOffset = useRef<number>(0);
  const setCurrentTime = useCallback(() => {
    const now = dayjs();
    const left = dayjs(till).diff(now) + timeOffset.current;
    setTimeLeft(left);
  }, [till]);
  useEffect(() => {
    if (!isLoading) {
      setCurrentTime();
      const interval = setInterval(() => setCurrentTime(), 1000);
      return () => clearInterval(interval);
    }
  }, [isLoading]);

  const fetchCurrentTime = async () => {
    getServerTime()
      .then((time) => {
        timeOffset.current = new Date().getTime() - time;
        setCurrentTime();
        setIsLoading(false);
        setIsSuccess(true);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchCurrentTime();
    // Sync time every 5 minutes
    const interval = setInterval(fetchCurrentTime, 300 * 1000);
    return () => clearInterval(interval);
  }, []);

  return {
    timeLeft,
    isLoading,
    isSuccess,
  };
}

export function useCurrentTime() {
  const [currentTime, setCurrentTime] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const timeOffset = useRef<number>(0);
  const fetchCurrentTime = async () => {
    getServerTime()
      .then((time) => {
        timeOffset.current = new Date().getTime() - time;
        setCurrentTime(time);
        setIsLoading(false);
        setIsSuccess(true);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const updateCurrentTime = useCallback(() => {
    const now = dayjs();
    // offset and set
    setCurrentTime(now.add(timeOffset.current, "millisecond").valueOf());
  }, []);

  useEffect(() => {
    if (!isLoading) {
      updateCurrentTime();
      const interval = setInterval(() => updateCurrentTime(), 1000);
      return () => clearInterval(interval);
    }
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(true);
    fetchCurrentTime();
    // Sync time every 5 minutes
    const interval = setInterval(fetchCurrentTime, 300 * 1000);
    return () => clearInterval(interval);
  }, []);

  return {
    currentTime,
    isLoading,
    isSuccess,
  };
}
