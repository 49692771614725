import { Box, Text, Tooltip } from "@chakra-ui/react";

const MsatSDETCourseInfo = () => {
  return (
    <Box bg={"#FFF3CC"} display={"flex"} flexDir={"row"} alignItems={"center"} gap={3} maxW={"fit-content"} rounded={3} fontSize={{ md: "16px", base: "14px" }}>
      <Text fontSize={"15px"} fontWeight={600} color={"#544D4F"} mr="2px">
        Congratulations! You’ve unlocked access to start your career as a Software Development Engineer in Test (SDET).🎉
        <Tooltip
          minW={{ base: "100%", md: "450px" }}
          label={
            <Box display={"flex"} flexDir={"column"} alignItems={"center"} gap={2}>
              <Text>
                <span style={{ fontWeight: "bold" }}>1. No DSA Stress:</span> Say goodbye to the anxiety of complex data structures and algorithms. Our program offers a smoother learning journey.
              </Text>
              <Text>
                <span style={{ fontWeight: "bold" }}>2. Less Market Competition:</span> SDET roles have high demand and fewer candidates, which means more career openings for you.
              </Text>
              <Text>
                <span style={{ fontWeight: "bold" }}>3. Compact Curriculum:</span> Our efficient curriculum ensures you acquire essential skills within a short time.
              </Text>
              <Text>
                <span style={{ fontWeight: "bold" }}>4. Swift Career Advancement:</span> With an average CTC of 4.5 LPA, you’ll progress quickly in your career.
              </Text>
              <Text>
                <span style={{ fontWeight: "bold" }}>5. Masai School Perks:</span> Enjoy the benefits of zero upfront cost and our unique Pay After Placements model.
              </Text>
              <Text>
                <span style={{ fontWeight: "bold" }}>6. Hiring Partners Network:</span> Gain access to our extensive network of 4000+ hiring partners and top-notch placement assistance.
              </Text>
            </Box>
          }
          fontSize="md"
          bg="white"
          color="black"
          borderRadius="md"
          padding="4"
          boxShadow="md"
        >
          <span style={{ marginLeft: "10px", color: "blue", textDecoration: "underline", cursor: "pointer" }}>Know more</span>
        </Tooltip>
      </Text>
    </Box>
  );
};

export default MsatSDETCourseInfo;
