import { Box } from "@chakra-ui/react";
import Drawer from "react-drag-drawer";
interface Props {
  openDrawer: boolean;
  setOpenDrawer: (openDrawer: boolean) => void;
  source?: string;
  setOpenCounsellingSuccessModal?: (openCounsellingSuccessModal: boolean) => void;
}
import "./BottomDrawer.css";
import CounsellingDrawerContent from "../../V2/ModalContent/CounsellingDrawerContent";
const BottomDrawer = ({
  setOpenCounsellingSuccessModal,
  source,
  openDrawer,
  setOpenDrawer,
}: Props) => {
  return (
    <Box>
      <Drawer
        modalElementClass="bottomDrawerClass"
        open={openDrawer}
        onRequestClose={() => setOpenDrawer(false)}
        // if user is at the top then only allow to close the drawer
        notifyWillClose={() => setOpenDrawer(false)}
      >
        <CounsellingDrawerContent
          setOpenCounsellingSuccessModal={setOpenCounsellingSuccessModal}
          source={source || ""}
          setOpenDrawer={setOpenDrawer}
        />
      </Drawer>
    </Box>
  );
};

export default BottomDrawer;
