import { ErrorResponseModelType, GetCouponType, VerifyCouponType } from "../../../schemas/schema";
import api from "../../axios";

export const verifyCoupon = async (data: VerifyCouponType): Promise<GetCouponType> => {
  const client = await api.client;
  const response = await client.verify_coupon_verify_coupon_post(null, data);
  return response.data;
};

export const addCouponToUserBulk = async ({
  emails,
  direct_payment_coupon_code,
  emi_payment_coupon_code,
}: {
  emails: string[];
  direct_payment_coupon_code: string;
  emi_payment_coupon_code: string;
}): Promise<ErrorResponseModelType> => {
  const client = await api.client;

  const response = await client.add_coupon_codes_admin_add_bulk_coupon_patch(null, {
    emails,
    direct_payment_coupon_code,
    emi_payment_coupon_code,
  });

  return response.data;
};
