import { Box } from "@chakra-ui/react";
import React, { useContext } from "react";
import { AppContext } from "../../../context/ApplicationContext";
import { handleNewMsatScore } from "../../Application/msat/HandleNewMsatScore";
import { Banner } from "../Banner";
import ScoreDetails from "./ScoreDetails";

const CodingPending = () => {
  const state = useContext(AppContext)[0];
  const scores = state.applicationData?.coding_result;

  return (
    <Box p={"ms-24"}>
      <Banner
        type="pending"
        text={`Please note that we are currently evaluating your submission and you can expect your final results 
within 30 minutes`}
        title="Evaluation Pending"
      />
      {scores && <ScoreDetails scoreDetailsData={handleNewMsatScore(scores, "coding")} />}
    </Box>
  );
};

export default CodingPending;
