/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "react-query";
// import {
//   getLevelupAlumnis,
//   getLevelupAlumnisAvailableSlots,
//   GetLevelupAlumniType,
//   getParticipantSessions,
//   GetUserDetailsForAIInterview,
//   getUserDetailsForAIInterview,
// } from "../../api/v2/levelup";

import {
  getLevelupAlumnis,
  getLevelupAlumnisAvailableSlots,
  getParticipantSessions,
  getUserDetailsForAIInterview,
} from "../../api/v2/levelup_internal";
// import { GetLevelupAlumniType, GetUserDetailsForAIInterview } from "../../api/v2/levelup";
import { GetLevelupAlumniType, GetUserDetailsForAIInterview } from "../../../schemas/schema";

export function useGetLevelupAlumnis() {
  return useQuery({
    queryKey: "getLevelupAlumnis",
    queryFn: async () => (await getLevelupAlumnis()) as GetLevelupAlumniType[],
    staleTime: 1000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: true,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000), // Exponential backoff delay
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useGetLevelupAlumnisSchedules(userId: string) {
  console.log("userId: 35", userId);
  return useQuery({
    queryKey: ["getLevelupAlumnisAvailableSlots", userId],
    queryFn: async () => (await getLevelupAlumnisAvailableSlots(userId)) as any,
    staleTime: 1000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: true,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000), // Exponential backoff delay
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useGetParticipantSessions(profileSlug: string) {
  return useQuery({
    queryKey: ["getParticipantSessions", profileSlug],
    queryFn: async () => (await getParticipantSessions(profileSlug)) as any,
    staleTime: 1000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: true,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000), // Exponential backoff delay
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useGetUserDetailsForAIInterview(profileSlug: string) {
  return useQuery({
    queryKey: ["getUserDetailsForAIInterview", profileSlug],
    queryFn: async () =>
      (await getUserDetailsForAIInterview(profileSlug)) as GetUserDetailsForAIInterview,
    staleTime: 1000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: true,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000), // Exponential backoff delay
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
