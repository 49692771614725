import { useQuery } from "react-query";
import { GetAdminCounsellingRequestResponseType, GetAdminDostRequestResponseType, GetAdminEventType, GetSpeakerType, ProfileTypeByAdmin } from "../../schemas/schema";
import { getAdminEvents, getAllDostRequests, getAllUsersCounsellingRequests, getProfileByEmail, getSpeakers } from "../api/v2/admin";

/* ------------------------------------ Dost Flow ------------------------------------ */
export function useAllDostRequests(offset: number, limit: number) {
  return useQuery({
    queryKey: ["getAllDostRequests", offset],
    queryFn: async () => (await getAllDostRequests(offset, limit)) as GetAdminDostRequestResponseType,
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
/* ------------------------------------ Counselling Flow ------------------------------------ */
export function useAllUsersCounsellingRequests(offset: number, limit: number) {
  console.log("useAllUsersCounsellingRequests---", offset, limit);
  return useQuery({
    queryKey: ["getAllUsersCounsellingRequests", offset],
    // queryKey: "getAllUsersCounsellingRequests",
    queryFn: async () => (await getAllUsersCounsellingRequests(offset, limit)) as GetAdminCounsellingRequestResponseType,
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

/* ------------------------------------ Event Flow ------------------------------------ */

export function useAllAdminEvents() {
  return useQuery({
    queryKey: "getAdminEvents",
    queryFn: async () => (await getAdminEvents()) as GetAdminEventType[],
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,

    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useAllSpeakers() {
  return useQuery({
    queryKey: "getSpeakers",
    queryFn: async () => (await getSpeakers()) as GetSpeakerType[],
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useAdminProfile(email: string, onComplete?: (data: ProfileTypeByAdmin) => void) {
  return useQuery({
    queryKey: "getProfileByEmail",
    queryFn: async () => (await getProfileByEmail(email)) as ProfileTypeByAdmin,
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,
    onSuccess(data) {
      onComplete && onComplete(data);
    },
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
