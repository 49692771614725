export const isProgramPresent = (
  programName: string,
  programs?: {
    id: number;
    name: "Scholar" | "Masai_One" | "MasaiX" | "CAP";
  }[]
) => {
  // check if programName is present in programs
  const isPresent = programs?.some(
    (program) => program.name.toLowerCase() === programName.toLowerCase()
  );
  return isPresent;
};

export const isCourseProgramsIntersectsProfilePrograms = (
  applicationCoursePrograms?: { program?: { name?: "Scholar" | "Masai_One" | "MasaiX" | "CAP" } }[],
  programs?: {
    id: number;
    name: "Scholar" | "Masai_One" | "MasaiX" | "CAP";
  }[]
) => {
  // check if any program in applicationCoursePrograms is present in programs
  const isPresent = applicationCoursePrograms?.some((applicationCourseProgram) =>
    isProgramPresent(applicationCourseProgram?.program?.name?.toLowerCase() || "", programs)
  );
  return isPresent;
};
