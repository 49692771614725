import { Box } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { Constants } from "../../../../components/common/constants";

import { AppContext } from "../../../../context/ApplicationContext";
import ScholarInstructions from "./ScholarInstructions/ScholarInstructions";
import ScholarProgramPaymentPage from "./ScholarProgramPaymentPage";

const ScholarInstructionsPayment = () => {
  const [mobile, setMobile] = useState<string>();

  const [state, dispatch] = useContext(AppContext);

  const [currentPage, setCurrentPage] = React.useState<
    "SCHOLAR_INSTRUCTIONS" | "SCHOLAR_PAYMENT"
  >();
  useEffect(() => {
    const showPaymentFormPageDirectly =
      state.applicationData?.coding_result || state.applicationData?.scholar_result;
    setCurrentPage(showPaymentFormPageDirectly ? "SCHOLAR_PAYMENT" : "SCHOLAR_INSTRUCTIONS");
  }, []);

  const handleProceedToPayment = () => {
    setCurrentPage("SCHOLAR_PAYMENT");
  };
  const handlePreviousPage = () => {
    setCurrentPage("SCHOLAR_INSTRUCTIONS");
  };

  return (
    <Box mb={`${Constants.bottomNavbarHeight}`}>
      {currentPage === "SCHOLAR_INSTRUCTIONS" ? (
        <ScholarInstructions handleProceedToPayment={handleProceedToPayment} />
      ) : (
        <ScholarProgramPaymentPage
          handlePreviousPage={handlePreviousPage}
          mobile={mobile || ""}
          setMobile={setMobile}
        />
      )}
    </Box>
  );
};

export default ScholarInstructionsPayment;
