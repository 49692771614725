import React from "react";

import { Box, Flex, Text } from "@chakra-ui/react";
interface Props {
  schedule: string;
  icon: JSX.Element;
}
export const ScheduleCardBody = ({ schedule, icon }: Props) => {
  return (
    <>
      <Box>
        <Flex align="center">
          <Box mr="ms-4">{icon}</Box>
          <Text textStyle={"body2-md"} color={"ms-grey.500"}>
            {schedule}
          </Text>
        </Flex>
      </Box>
    </>
  );
};
