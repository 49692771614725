import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

const WaitingListCard = () => {
  const history = useHistory();
  return (
    <Box
      mt={{ base: 8, md: 0 }}
      bg={"#FFF"}
      //   maxW={"600px"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDir={"column"}
    >
      <Box
        borderTopLeftRadius="12px"
        borderTopRightRadius={"12px"}
        p="16px"
        w={"full"}
        textAlign={"center"}
        bgColor={"ms-yellow.100"}
      >
        <Text fontSize={"16px"}>
          Based on your Entrance Test result you have not cleared the cut off.
        </Text>
      </Box>
      <Flex flexDir={"column"} justifyContent={"space-between"} alignItems={"center"}>
        <Box
          color={"#3B3435"}
          textAlign={"center"}
          maxW={"510px"}
          mx={"auto"}
          p={2}
          display={"flex"}
          flexDir={"column"}
          gap={4}
          my={8}
        >
          <Text fontSize={"24px"} fontWeight={600}>
            You are in the waitlist !
          </Text>
          <Text fontSize={"16px"} fontWeight={600}>
            You have exhausted both of your attempts, and you are currently placed on the waitlist.
          </Text>
        </Box>
        <Box py={4}>
          <Button variant="primary" onClick={() => history.push("/")}>
            EXPLORE DASHBOARD
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default WaitingListCard;
