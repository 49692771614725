import React from "react";

import { Box } from "@chakra-ui/react";
import ProfileFieldCard from "./ProfileFieldCard";
import { useProfile } from "../../../api/utils/hooks/useProfile";

const AreYouWorking = () => {
  const { data: profile } = useProfile();
  return (
    <Box>
      <ProfileFieldCard
        keyName="are-you-working"
        heading="Are you currently Working?"
        title={
          typeof profile?.is_working === "boolean" ? (profile?.is_working ? "Yes" : "No") : "NA"
        }
      />
    </Box>
  );
};

export default AreYouWorking;
