import { useQuery } from "react-query";

import { ProfileAssessment } from "../../schemas/schema";
import { getBestAssessment, getBestAssessmentForEmpowerHer } from "../api/v2/assessment";

export function useBestAssessment() {
  return useQuery({
    queryKey: "useBestAssessment",
    queryFn: async () => (await getBestAssessment()) as ProfileAssessment,
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useBestAssessmentForEmpowerHer() {
  return useQuery({
    queryKey: "useBestAssessmentForEmpowerHer",
    queryFn: async () => (await getBestAssessmentForEmpowerHer()) as ProfileAssessment,
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
