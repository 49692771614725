import React, { useState } from "react";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Text } from "@chakra-ui/react";
import AddNewEvent, { EventLocation, EventType, RecordingPresent } from "./AddNewEvent";
import { GetAdminEventType } from "../../../../api/schemas/schema";
import { useAllAdminEvents } from "../../../../api/utils/hooks/useAdmin";
import { ModalTemplate } from "../../../common/general";
import PreviewEventModal from "./PreviewEventModal";
import { useHistory } from "react-router-dom";
import { useProfile } from "../../../../api/utils/hooks/useProfile";
import { useMutation } from "react-query";
import { startPipeline, updateEvent } from "../../../../api/utils/api/v2/admin";
import useSnackBar from "../../../common/general/SnackBar";
import { axiosErrorHandler } from "../../../../api/utils/error";
import ChecklistForm from "./Checklist/ChecklistForm";
import PreviewWebsiteEventModal from "./PreviewOnwardEventModal";
export const getFormattedEvent = (event: GetAdminEventType) => {
  const getEventType = (eventType: string) => {
    if (eventType === "AMA") {
      return EventType.AMA;
    } else if (eventType === "BOOTCAMP") {
      return EventType.BOOTCAMP;
    } else if (eventType === "TECHTONIC_SHIFT") {
      return EventType.TECHTONIC_SHIFT;
    } else if (eventType === "CRACK_THE_CODE") {
      return EventType.CRACK_THE_CODE;
    } else if (eventType === "FOUNDER_WEBINAR") {
      return EventType.FOUNDER_WEBINAR;
    } else if (eventType === "MASTERCLASS") {
      return EventType.MASTERCLASS;
    } else if (eventType === "ONBOARDING") {
      return EventType.ONBOARDING;
    } else if (eventType === "IIT_COUNSELING") {
      return EventType.IIT_COUNSELING;
    } else if (eventType === "IIT_ROPAR_COUNSELING") {
      return EventType.IIT_ROPAR_COUNSELING;
    } else if (eventType === "IIT_MANDI_COUNSELING") {
      return EventType.IIT_MANDI_COUNSELING;
    } else {
      return EventType.WEBINAR;
    }
  };
  const convertObjectToArray = (object?: { [key: string]: string }) => {
    const array: { key: string; value: string }[] = [];
    for (const key in object) {
      array.push({ key: key, value: object[key] });
    }
    return array;
  };

  return {
    name: event.name,
    event_type: getEventType(event.event_type),
    event_location: event.event_location === "OFFLINE" ? EventLocation.OFFLINE : EventLocation.ONLINE,
    cost: event.cost,
    details: {
      onward: convertObjectToArray(event?.details?.onward?.[0]),
      website: convertObjectToArray(event?.details?.website?.[0]),
    },
    event_end_date: event.event_end_data,
    event_start_date: event.event_start_date,
    event_visible_from: event.event_visible_from,
    is_recording_present: event.is_recording_present === true ? RecordingPresent.YES : RecordingPresent.NO,
  };
};
const AllEvents = () => {
  const { data: events, refetch: refetchEvents } = useAllAdminEvents();
  const [openActivityDetailsModal, setOpenActivityDetailsModal] = React.useState(false);
  const [openOnwardActivityDetailsModal, setOpenOnwardActivityDetailsModal] = React.useState(false);

  const [onwardActivityId, setOnwardActivityId] = useState<number | null>(null);
  const [websiteActivityId, setWebsiteActivityId] = useState<number | null>(null);

  const history = useHistory();
  const { data: profile } = useProfile();
  const snackbar = useSnackBar();
  const { mutate: updateEventMutate } = useMutation(updateEvent, {
    onSuccess: () => {
      refetchEvents();
      snackbar.success("Event Updated Successfully");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const isChecklistDone = (eventId: number) => {
    const ONE_HOUR_EMAIL = localStorage.getItem(`profile-${profile?.slug}-event-${eventId}-trigger-${"ONE_HOUR_EMAIL"}`);
    const FIFTEEN_MINUTES_EMAIL = localStorage.getItem(`profile-${profile?.slug}-event-${eventId}-trigger-${"FIFTEEN_MINUTES_EMAIL"}`);
    const LIVE_EMAIL = localStorage.getItem(`profile-${profile?.slug}-event-${eventId}-trigger-${"LIVE_EMAIL"}`);
    const FIFTEEN_MINUTES_WHATSAPP = localStorage.getItem(`profile-${profile?.slug}-event-${eventId}-trigger-${"FIFTEEN_MINUTES_WHATSAPP"}`);
    if (ONE_HOUR_EMAIL === "true" && FIFTEEN_MINUTES_EMAIL === "true" && LIVE_EMAIL === "true" && FIFTEEN_MINUTES_WHATSAPP === "true") {
      return true;
    }
    return false;
  };

  const { mutate: startPipelineMutate, isLoading: startPipelineLoading } = useMutation(startPipeline, {
    onSuccess: (data) => {
      snackbar.success("Deployment started successfully!");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });
  return (
    <>
      <Box p="24px">
        <Box display={"flex"} gap="10px" mb="24px">
          <Button
            isLoading={startPipelineLoading}
            onClick={() => {
              history.push("/admin/events/create");
            }}
            variant="primary"
            mb="8px"
          >
            Create Event
          </Button>

          <Button
            isLoading={startPipelineLoading}
            onClick={() => {
              if (process.env.REACT_APP_PIPELINE_NAME) {
                startPipelineMutate({
                  pipelineName: process.env.REACT_APP_PIPELINE_NAME,
                });
              } else {
                snackbar.error("Pipeline name is not present");
              }
            }}
            // bg={"red.500"}
            colorScheme="red"
            mb="8px"
            ml="auto"
          >
            Trigger Website Deployment
          </Button>
        </Box>

        <Box mb="16px" ml="16px" width={"100%"} display={"flex"}>
          <Text color="ms-blue.500" textAlign={"center"} minW={"100px"} textStyle={"body1-md"}>
            Id
          </Text>
          <Text color="ms-blue.500" textStyle={"body1-md"}>
            Event Name
          </Text>
        </Box>
        <Accordion allowToggle={true}>
          {events &&
            events.length > 0 &&
            events?.map((event) => {
              return (
                <AccordionItem key={event.id}>
                  <h2>
                    <AccordionButton _focus={{ outline: "none" }}>
                      <Box py="16px" width={"100%"} display={"flex"} alignItems={"center"}>
                        <Text minW={"100px"} textStyle={"body1-md"} color={"ms-grey.700"}>
                          {event.id}
                        </Text>
                        <Text color={"ms-grey.700"} textStyle={"body1-md"}>
                          {event.name}
                        </Text>
                      </Box>
                      <Accordion />
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Box>
                      <Box p="24px" display={"flex"} gap="24px">
                        <Button
                          onClick={() => {
                            localStorage.setItem(`cloned-event-${profile?.slug}`, JSON.stringify(event));
                            history.push("/admin/events/create?clone=true");
                          }}
                          variant="secondary"
                          size="sm"
                        >
                          Clone Event
                        </Button>
                        <Button
                          onClick={async () => {
                            if (event.id) {
                              setWebsiteActivityId(event.id);
                              setOpenActivityDetailsModal(true);
                            } else {
                              snackbar.error("Event Id is not present");
                            }
                          }}
                          variant="secondary"
                          size="sm"
                        >
                          Preview Website Detail Page
                        </Button>
                        <Button
                          onClick={async () => {
                            if (event.id) {
                              setOnwardActivityId(event.id);
                              setOpenOnwardActivityDetailsModal(true);
                            } else {
                              snackbar.error("Event Id is not present");
                            }
                          }}
                          variant="secondary"
                          size="sm"
                        >
                          Preview Onward Detail Page
                        </Button>
                        {
                          <Button
                            onClick={async () => {
                              if (event.is_published) {
                                window.location.href = `https://www.opengraph.xyz/url/https://dev.masaischool.com/events/${event.id}`;
                              } else {
                                snackbar.error("Event is not published yet!");
                              }
                            }}
                            variant="secondary"
                            size="sm"
                          >
                            Check OG Images
                          </Button>
                        }

                        <Button
                          onClick={() => {
                            // check local storage for event
                            if (!isChecklistDone(event.id)) {
                              snackbar.error("Please complete the checklist below!");
                              return;
                            }
                            if (event.id) {
                              updateEventMutate({
                                eventId: event.id,
                                event: {
                                  is_published: event.is_published === true ? false : true,
                                },
                              });
                            } else {
                              snackbar.error("Event Id is not present");
                            }
                          }}
                          variant="primary"
                          size="sm"
                        >
                          {event.is_published === true ? "Unpublish Event" : "Publish Event"}
                        </Button>
                      </Box>
                      <Box p="24px" border="1px solid #d0d0d0" borderRadius={"8px"}>
                        <ChecklistForm eventId={event.id} />
                      </Box>
                      <AddNewEvent event={getFormattedEvent(event)} isEdit={true} eventId={event.id} />
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
        </Accordion>
      </Box>
      <ModalTemplate
        title=""
        isOpen={openActivityDetailsModal}
        onClose={() => {
          setOpenActivityDetailsModal(false);
        }}
        maxWidth="90%"
        maxHeight="90vh"
        showCloseButtonIcon={false}
        // allow scroll
        scrollBehavior="inside"
      >
        <PreviewEventModal
          event={events?.find((event) => {
            return event.id === websiteActivityId;
          })}
        />
      </ModalTemplate>
      <ModalTemplate
        title=""
        isOpen={openOnwardActivityDetailsModal}
        onClose={() => {
          setOpenOnwardActivityDetailsModal(false);
        }}
        maxWidth="90%"
        maxHeight="90vh"
        showCloseButtonIcon={false}
        // allow scroll
        scrollBehavior="inside"
      >
        <PreviewWebsiteEventModal
          event={events?.find((event) => {
            return event.id === onwardActivityId;
          })}
        />
      </ModalTemplate>
    </>
  );
};

export default AllEvents;
