import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const Web = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M16.36 14C16.44 13.34 16.5 12.68 16.5 12C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12C20 12.69 19.9 13.36 19.74 14H16.36ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM14.34 14H9.66C9.56 13.34 9.5 12.68 9.5 12C9.5 11.32 9.56 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12C14.5 12.68 14.43 13.34 14.34 14ZM12 19.96C11.17 18.76 10.5 17.43 10.09 16H13.91C13.5 17.43 12.83 18.76 12 19.96ZM8 8.00001H5.08C6.03 6.34001 7.57 5.06001 9.4 4.44001C8.8 5.55001 8.35 6.75001 8 8.00001ZM5.08 16H8C8.35 17.25 8.8 18.45 9.4 19.56C7.57 18.93 6.03 17.65 5.08 16ZM4.26 14C4.1 13.36 4 12.69 4 12C4 11.31 4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12C7.5 12.68 7.56 13.34 7.64 14H4.26ZM12 4.03001C12.83 5.23001 13.5 6.57001 13.91 8.00001H10.09C10.5 6.57001 11.17 5.23001 12 4.03001ZM18.92 8.00001H15.97C15.65 6.75001 15.19 5.55001 14.59 4.44001C16.43 5.07001 17.96 6.34001 18.92 8.00001ZM12 2.00001C6.47 2.00001 2 6.50001 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7363 8.17317 21.2388C9.38642 21.7414 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38643 21.2388 8.17318C20.7362 6.95992 19.9997 5.85753 19.0711 4.92894C18.1425 4.00036 17.0401 3.26376 15.8268 2.76122C14.6136 2.25867 13.3132 2.00001 12 2.00001Z"
      fill="currentColor"
    />
  </Icon>
);

export default Web;
