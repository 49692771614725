import { Box, Text, Image } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Webcam from "react-webcam";

import CameraControls from "./CameraControls";
interface Props {
  webcamRef: React.RefObject<Webcam>;
  videoConstraints: boolean | MediaTrackConstraints | undefined;
  onUserMedia: () => void;
  onUserMediaError: () => void;
  loadingCamera: boolean;
  capturePhoto: () => void;
  aadhaarFrontCaptured?: string;
  aadhaarBackCaptured?: string;
  currentPictureType: "front" | "back" | null;
  setAadhaarFrontCaptured: (value: string) => void;
  setAadhaarBackCaptured: (value: string) => void;
  handleClose: () => void;
}
const AfterCameraAccess = ({
  webcamRef,
  videoConstraints,
  onUserMedia,
  onUserMediaError,
  loadingCamera,
  capturePhoto,
  aadhaarFrontCaptured,
  aadhaarBackCaptured,
  currentPictureType,
  setAadhaarFrontCaptured,
  setAadhaarBackCaptured,
  handleClose,
}: Props) => {
  useEffect(() => {
    console.log("After camera access 1", aadhaarFrontCaptured, currentPictureType);
  }, [aadhaarFrontCaptured]);
  useEffect(() => {
    console.log("After camera access 2", aadhaarFrontCaptured, currentPictureType);
  }, [currentPictureType]);

  const handleRetry = () => {
    if (currentPictureType === "front") {
      setAadhaarFrontCaptured("");
    } else if (currentPictureType === "back") {
      setAadhaarBackCaptured("");
    }
  };

  return (
    <>
      <Box px={{ md: "ms-16" }} d={"flex"} justifyContent={"center"} alignItems={"center"}>
        {aadhaarFrontCaptured && currentPictureType === "front" ? (
          <Image borderRadius={"16px"} src={aadhaarFrontCaptured} alt="front aadhaar" />
        ) : aadhaarBackCaptured && currentPictureType === "back" ? (
          <Image borderRadius={"16px"} src={aadhaarBackCaptured} alt="back aadhaar" />
        ) : (
          <Box borderRadius={"16px"}>
            {loadingCamera ? (
              <Box
                h={"386px"}
                maxW={"686px"}
                width={"100%"}
                d={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Text textStyle="body1-md">Loading camera...</Text>
              </Box>
            ) : (
              <Box d={"flex"} justifyContent={"center"}>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={videoConstraints}
                  onUserMedia={onUserMedia}
                  onUserMediaError={onUserMediaError}
                  style={{
                    borderRadius: "16px",
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
      <CameraControls
        currentPictureType={currentPictureType}
        aadhaarFrontCaptured={aadhaarFrontCaptured}
        aadhaarBackCaptured={aadhaarBackCaptured}
        capturePhoto={capturePhoto}
        handleRetry={handleRetry}
        handleClose={handleClose}
      />
    </>
  );
};

export default AfterCameraAccess;
