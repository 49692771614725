import { Box } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AadhaarInfoType } from "../../../../api/schemas/schema";
import { proceedToInterviewPending } from "../../../../api/utils/api";
import { axiosErrorHandler } from "../../../../api/utils/error";

import { BottomStickyNavbar } from "../../../../components/common/general";
import useSnackBar from "../../../../components/common/general/SnackBar";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import { AppContext } from "../../../../context/ApplicationContext";
import useApplicationHook from "../../../../hooks/useApplicationHook";
import { handleNewMsatScore } from "../../../Application/msat/HandleNewMsatScore";

import { AadhaarUploadedDataType } from "../../../Application/msat/MSATSuccess";
import { Banner } from "../../Banner";
import ScoreDetails from "../ScoreDetails";

import CurrentStage from "./CurrentStage";
import { MasaiXStageType } from "./MasaiXCodingInstructions";

interface Props {
  currentStage: MasaiXStageType;
}
export type ScoreDetailType = {
  id: number;
  title?: string;
  icon?: JSX.Element;
  marksObtained?: string;
  totalMarks?: number;
  // assumed status as passed failed and pending for now
  status?: string;
  // if score is pending for communication skills section
  pendingText?: string;
  hideMarks?: boolean;
};
const MasaiXCodingSuccess = ({ currentStage }: Props) => {
  const state = useContext(AppContext)[0];
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedValuesIsaConsent, setSelectedValuesIsaConsent] = useState<(string | number)[]>([
    "consent-isa",
  ]);
  const snackbar = useSnackBar();
  const { fetchApplicationData } = useApplicationHook()[1];
  const params = useParams() as { applicantID: string };
  const handleContinue = async () => {
    if (!state.applicationData?.slug) return;
    try {
      setLoading(true);
      await proceedToInterviewPending({ application_slug: state.applicationData?.slug });
    } catch (err) {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    } finally {
      setLoading(false);
      fetchApplicationData && fetchApplicationData(params.applicantID);
    }
  };
  const [selectedValuesHealthConsent, setSelectedValuesHealthConsent] = useState<
    (string | number)[]
  >(["consent-health"]);
  const [aadhaarUploadedData, setAadhaarUploadedData] = useState<AadhaarUploadedDataType>({});
  const [aadhaarOCRDetails, setAadhaarOCRDetails] = useState<AadhaarInfoType>();
  const consentDocsUrls = {
    coc_doc: "",
    isa_doc: "",
    mbp_doc: "",
  };
  const location = useLocation();
  const scores = state.applicationData?.coding_result;

  return (
    <>
      <Box pb={"4rem"}>
        {/* Current Stage */}
        <CurrentStage
          customMasaiXStagesText={[
            "Coding Test",
            "Technical Interview",
            "Aadhaar Verification",
            "Onboarding",
          ]}
          customMasaiXStagesMargin={["-24px", "-44px", "-55px", "-24px"]}
          customCurrentStageColor="ms-success"
          currentStage={currentStage}
        />
        <Box px={"ms-24"}>
          <Banner
            type="success"
            text={
              "You have now successfully completed the Masai School Coding Test! Please book your slot for the Technical Interview to complete the testing process for the MasaiX program. "
            }
            title={"Woohoo!!🤩"}
            hideShareButton={true}
          />
        </Box>
        <Box px={"ms-24"}>
          <ScoreDetails scoreDetailsData={handleNewMsatScore(scores, "coding")} />
        </Box>
      </Box>
      <BottomStickyNavbar
        loading={loading}
        btnPrimaryProps={{
          size: "lg",
          onClick: () => {
            sendTrackingEvent({
              event: "mx_ctp_continue",
              eventLabel: location.pathname,
            });
            handleContinue();
          },
        }}
        btnPrimary="CONTINUE"
      />
    </>
  );
};

export default MasaiXCodingSuccess;
