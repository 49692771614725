import { useEffect, useState } from "react";
import CoursesListCard from "./CoursesListCard";
import SelectCourseTab from "./SelectCourseTab";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { papPointers, papData, iitData, iitPointers, CourseDataInterface } from "../../constants";

export enum CourseType {
  PAP = "PAP",
  IIT = "IIT",
}
export type SelectCoursePreferencePageProps = {
  selectedCourse?: CourseType;
  isDashboard?: boolean;
  coursesList?: CourseDataInterface[] | null;
};

const SelectCoursePreferencePage = ({ selectedCourse, isDashboard = false, coursesList = [] }: SelectCoursePreferencePageProps) => {
  const [activePointers, setActivePointers] = useState(papPointers);
  const [activeTab, setActiveTab] = useState<CourseType>(CourseType.PAP);

  useEffect(() => {
    if (activeTab === CourseType.PAP) {
      setActivePointers(papPointers);
    } else {
      setActivePointers(iitPointers);
    }
  }, [activeTab]);

  return (
    <Box w={"full"} display={"flex"} h={selectedCourse ? "auto" : "full"} pt={{ base: "40px", md: 0 }} flexDir={"column"} alignItems={{ base: "start", md: "center" }} justifyContent={"center"}>
      <Box maxW="1440px" px={{ base: "16px", xl: "80px" }} mx="auto" w="full" display={"flex"} flexDir={"column"} alignItems={{ base: "start", md: "center" }} justifyContent={"center"}>
        {selectedCourse ? (
          <Text fontSize={{ base: "20px", md: "24px" }} fontWeight={"700"} lineHeight={"28.8px"} textAlign={"left"} mr="auto" fontFamily={"inter"}>
            {selectedCourse === CourseType.IIT ? "IIT courses" : "Masai courses"}
          </Text>
        ) : null}
        {selectedCourse ? null : (
          <Text fontSize={{ base: "24px", md: "32px" }} fontWeight={"800"} lineHeight={{ base: "28px", md: "42px" }} textAlign={"center"} maxW={"804px"} mx={"auto"} fontFamily={"Poppins"}>
            Before we get started select your course <br /> that best suits you
          </Text>
        )}
        {/* Dektop Tab For courses */}
        {selectedCourse ? null : <SelectCourseTab activeTab={activeTab} setActiveTab={setActiveTab} />}

        {selectedCourse ? null : (
          <Text fontSize={"18px"} fontWeight={600} color={"#544D4F"} mt={"30px"} fontFamily="inter">
            {activeTab === CourseType.IIT ? "Masai <-> IIT Partnership Courses" : "Masai’s flagship Pay-After-Placement courses"}
          </Text>
        )}

        {selectedCourse ? null : (
          <Flex gap={{ base: 4, md: 5 }} flexDir={{ base: "column", md: "row" }} alignItems={{ base: "flex-start", md: "center" }} mt={5}>
            {activePointers.map((item, index) => {
              return (
                <Flex key={index} gap={"14.2px"} alignItems={"center"}>
                  <Image src={item.icon} alt={item.text} w={"42px"} h={"42px"} objectFit={"contain"} />
                  <Text color={"#6C6768"} fontSize={"14px"} fontWeight={600} maxW={{ base: "auto", md: "218px" }} w={"full"} flexShrink={0}>
                    {item.text}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        )}
      </Box>

      {/* Courses Card */}
      <CoursesListCard
        courseList={coursesList && coursesList.length ? coursesList : (selectedCourse ? selectedCourse === CourseType.PAP : activeTab === CourseType.PAP) ? papData : iitData}
        activeTab={activeTab}
        selectedCourse={selectedCourse}
        isDashboard={isDashboard}
      />
    </Box>
  );
};

export default SelectCoursePreferencePage;
