import { Box } from "@chakra-ui/react";
import React from "react";
import Lottie from "react-lottie-player";
import waitingLottie from "../../../../assets/waiting.json";

type Props = {
  width?: string;
};
const ModalWaitingIcon = ({ width = "151px" }: Props) => {
  return (
    <Box w={width} mx="auto">
      <Lottie loop animationData={waitingLottie} play style={{ width: "100%" }} />
    </Box>
  );
};

export default ModalWaitingIcon;
