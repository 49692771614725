import React, { useEffect, useState } from "react";
import { Box, Button, Skeleton, Text } from "@chakra-ui/react";
import { useMutation } from "react-query";
import useSnackBar from "../../../common/general/SnackBar";
import { useHistory } from "react-router-dom";
import { useAdminProfile } from "../../../../api/utils/hooks/useAdmin";
import { axiosErrorHandler } from "../../../../api/utils/error";
import { updateUserBatch } from "../../../../api/utils/api/v2/admin";
import { Select } from "@chakra-ui/react";
import { CourseSelected } from "../../../../enums/ProgramType.enum";
import { OfferedCourseListForBatchChange } from "../../../../constants/Program-list";

interface UpdateUseBatchProps {
  email: string;
}

const UpdateUseBatch = ({ email }: UpdateUseBatchProps) => {
  const history = useHistory();
  const snackbar = useSnackBar();
  const [newCourseSelected, setNewCourseSelected] = useState<CourseSelected>();
  const [currentCourseSelected, setCurrentCourseSelected] = useState<CourseSelected>();

  const { refetch: refetchProfile, isLoading: isProfileLoading } = useAdminProfile(email);

  const { mutate: updateUserBatchMutate, isLoading: updateProfileLoading } = useMutation(updateUserBatch, {
    onSuccess: () => {
      snackbar.success("Profile Updated Successfully");
      history.push("/admin/users");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const handleSaveDetails = () => {
    if (newCourseSelected && currentCourseSelected) {
      updateUserBatchMutate({
        email: email,
        new_batch: newCourseSelected,
        curr_batch: currentCourseSelected,
      });
    } else {
      snackbar.error("Please select a course type");
    }
  };

  useEffect(() => {
    refetchProfile();
  }, [email]);

  return (
    <Skeleton p={{ base: "16px", md: "24px" }} h={"100vh"} isLoaded={true}>
      <Text as="h4" textStyle="h4">
        Select the new batch for the user
      </Text>

      <Box display={"grid"} gridTemplateColumns={"repeat(1,1fr)"} gap="24px" pt="4" overflowY={"auto"} position={"relative"} w={["100%", "50%", "40%", "40%"]}>
        <Box>
          <label htmlFor="courseSelected" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Select Current Course Type*
          </label>
          <Select
            placeholder={"Select Current Course Type"}
            onChange={(event) => {
              setCurrentCourseSelected(event.target.value as CourseSelected);
            }}
          >
            {OfferedCourseListForBatchChange.map((item) => (
              <option key={item?.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
        </Box>

        <Box>
          <label htmlFor="courseSelected" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Select New Course Type*
          </label>
          <Select
            placeholder={"Select New Course Type"}
            onChange={(event) => {
              setNewCourseSelected(event.target.value as CourseSelected);
            }}
          >
            {OfferedCourseListForBatchChange.map((item) => (
              <option key={item?.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
        </Box>

        <Button
          mt="10px"
          isLoading={updateProfileLoading}
          disabled={updateProfileLoading || isProfileLoading || !newCourseSelected || !currentCourseSelected}
          size="md"
          textStyle="btn-md"
          variant="primary"
          onClick={handleSaveDetails}
        >
          Update Batch
        </Button>
      </Box>
    </Skeleton>
  );
};

export default UpdateUseBatch;
