import { Box, Text, Heading, useBreakpointValue, Image } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";

import RegistrationFeeCard from "./RegistrationFeeCard";
import StepsCard from "../../../../components/cap-program/CapSteps/StepsCard";
import CapDownArrow from "../../../../assets/images/CapDownArrow.svg";
import CapDownArrowMobile from "../../../../assets/images/CapDownArrowMobile.svg";
import { TestimonialStudentOne, TestimonialStudentTwo } from "../../../../assets/images";

import AboutMSAT from "../../../Application/msat/new-msat/first-tab/AboutMSAT";
import useSnackBar from "../../../../components/common/general/SnackBar";
import { AppContext } from "../../../../context/ApplicationContext";
import { generateRazorpayOrderIdMasaiOne, razorpaySuccessReport } from "../../../../api/utils/api";
import { axiosErrorHandler } from "../../../../api/utils/error";
import dayjs from "dayjs";
import Testimonials from "../../../../components/msat/msat-instructions/Testimonials";
import MasaiOnePaymentBanner from "./MasaiOnePaymentBanner";
import { PaymentStatusType } from "../../../../api/schemas/schema";
import { useHistory } from "react-router-dom";
const PaymentPendingMasaiOne = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [loading, setLoading] = React.useState(false);
  const snackbar = useSnackBar();
  const [state, dispatch] = useContext(AppContext);
  const headingSize = useBreakpointValue<"h5" | "h6">({
    base: "h6",
    md: "h5",
  });
  const stepsData = [
    {
      id: 1,
      text1: (
        <Heading as={headingSize} color={"ms-blue.500"}>
          Pay{" "}
          <Text
            color={"ms-grey.800"}
            textStyle={{ base: "body2-md", md: "body1-md" }}
            display={"inline"}
          >
            MSAT registration fee
          </Text>
        </Heading>
      ),
      text2: (
        <Text color={"ms-grey.700"} textStyle={{ base: "body2-md", md: "body1-md" }}>
          Receive two MSAT attempts fee of ₹499/- only
        </Text>
      ),
    },
    {
      id: 2,
      text1: (
        <Heading as={headingSize} color={"ms-blue.500"}>
          Attempt and clear{" "}
          <Text
            color={"ms-grey.800"}
            textStyle={{ base: "body2-md", md: "body1-md" }}
            display={"inline"}
          >
            MSAT
          </Text>
        </Heading>
      ),
      text2: (
        <Text color={"ms-grey.700"} textStyle={{ base: "body2-md", md: "body1-md" }}>
          In order to apply for this course, you need to clear the MSAT
        </Text>
      ),
    },
  ];
  const [
    registrationPaymentStatus,
    setRegistrationPaymentStatus,
  ] = React.useState<PaymentStatusType>();
  useEffect(() => {
    if (
      state.applicationData?.registration_fees &&
      state.applicationData?.registration_fees.length > 0
    ) {
      setRegistrationPaymentStatus(
        state.applicationData?.registration_fees[0]?.payment_record?.status
      );
    }
  }, [state.applicationData?.registration_fees]);

  const handleMasaiOnePayment = () => {
    startRazorpayPayment();
  };
  const history = useHistory();
  // display razorpay modal once order id is available
  const loadScript = (src: string) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const startRazorpayPayment = async () => {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      snackbar.error("Payment Gateway failed to load. Please check internet connection!");
      return;
    }
    const courseName = state.applicationData?.batch_campus.batch.course.name;
    const appSlug = state.applicationData?.slug;
    if (!appSlug) return;
    try {
      setLoading(true);
      const res = await generateRazorpayOrderIdMasaiOne({
        application_slug: appSlug,
        payment_consent: true,
        source: "ONWARD_MASAI_ONE_PAYMENT_DETAILS_PAGE",
      });
      setLoading(false);
      snackbar.success("Starting payment process!");
      const options = {
        key: process.env.REACT_APP_RAZORPAY_CLIENT_ID || "",
        order_id: res?.payment_record?.order_id,
        // order_id: paymentType === "full-payment" ? res?.order_id : undefined,
        // subscription_id: paymentType === "subscription-payment" ? res?.subscription_id : undefined,
        name: `${courseName}`,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        handler: (response: any) => {
          console.log("Razorpay response: ", response);
          if (
            response?.razorpay_payment_id &&
            (response?.razorpay_order_id || response?.razorpay_subscription_id) &&
            response?.razorpay_signature
          ) {
            setLoading(true);
            // send request to backend to verify payment
            console.log("Payment successful response:", response);
            razorpaySuccessReport({
              profile_slug: state.profileData?.slug,
              application_slug: state.applicationData?.slug,
              razorpay_payment_id: response?.razorpay_payment_id,
              razorpay_order_id: response?.razorpay_order_id,
              razorpay_subscription_id: response?.razorpay_subscription_id,
              razorpay_signature: response?.razorpay_signature,
            })
              .then((res) => {
                setLoading(false);
                snackbar.success("Payment Successful!");
                // payment success stage: Start MSAT Page
                history.push(
                  `/batch/${state.applicationData?.batch_campus.batch_id}/${appSlug}/payment-success`
                );
              })
              .catch((err) => {
                setLoading(false);
                const e = axiosErrorHandler(err);
                if (typeof e === "object" && "message" in e) {
                  snackbar.error(e.message);
                } else {
                  snackbar.error("something went wrong");
                }
                history.push(
                  `/batch/${state.applicationData?.batch_campus.batch_id}/${appSlug}/instructions`
                );
              });
          } else {
            // move to payment failed page
            history.push(
              `/batch/${state.applicationData?.batch_campus.batch_id}/${appSlug}/instructions`
            );
          }
        },
        ondismiss: () => {
          console.log("razorpay payment cancelled");
        },
      };
      if (window && window.Razorpay) {
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }
    } catch (err) {
      setLoading(false);
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    }
  };
  const data = {
    courseName: state?.applicationData?.batch_campus.batch.course.name,
    start_date: dayjs(state?.applicationData?.batch_campus.batch.start_date).format("DD MMM YYYY"),
    course_type: state?.applicationData?.batch_campus.batch.course.course_type,
    application_end_date: dayjs(
      state?.applicationData?.batch_campus.batch.application_end_date
    ).format("DD MMM YYYY"),
  };
  const testimonialData = [
    {
      image: TestimonialStudentOne,
      name: "Chiranjeev Thapliyal",
      role: "SDE- 1",
      company: "Coinswitch Kuber",
      feedback:
        "Honestly, I didn't prepare for the test. I thought it would be a simple aptitude test and it turned out to be true. The questions asked were easy-medium level for the aptitude round. You can finish this easily before time.",
    },
    {
      image: TestimonialStudentTwo,
      name: "Gayathri",
      role: "SDE",
      company: "John Deere",
      feedback:
        "If we prepare properly we can clear it in the first attempt itself. I took practice tests given by Masai. That's how I knew basic concepts and topics of the aptitude test. Overall it was a good experience.",
    },
  ];
  return (
    <Box p="24px">
      <Heading as="h4" color={"ms-blue.500"}>
        {data?.courseName}{" "}
        <Heading as="h4" d={"inline"} color={"ms-cyan.500"}>
          ({data?.course_type?.replace("_", " ")})
        </Heading>
      </Heading>
      <Box display={"flex"} mt="8px" mb={"16px"}>
        <Text pr="16px" width={"fit-content"} textStyle="body2" borderRight={"1px solid #3B3435"}>
          Course starts on{" "}
          <Text textStyle="body2-md" d={"inline"}>
            {data?.start_date}
          </Text>
        </Text>
        <Text pl="16px" width={"fit-content"} textStyle="body2">
          Last date to attempt MSAT:{" "}
          <Text textStyle="body2-md" d={"inline"}>
            {data?.application_end_date}
          </Text>
        </Text>
      </Box>
      <MasaiOnePaymentBanner registrationPaymentStatus={registrationPaymentStatus} />

      {
        <Box mt={"16px"} display={"grid"} gridTemplateColumns={{ lg: "50% 50%" }}>
          <Box>
            <Heading as="h6">You are two steps away:</Heading>
            <Box mt={"28px"}>
              {stepsData.map((item) => {
                return (
                  <Box
                    mx={{ base: "-8px", md: "0px" }}
                    key={item.id}
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    <StepsCard stepData={item} />
                    <Image
                      display={item.id === 2 ? "none" : "block"}
                      h={{ base: "32px", md: "53px" }}
                      src={isMobile ? CapDownArrowMobile : CapDownArrow}
                      alt="cap steps"
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
          <RegistrationFeeCard
            registrationPaymentStatus={registrationPaymentStatus}
            loading={loading}
            handleMasaiOnePayment={handleMasaiOnePayment}
          />
        </Box>
      }
      <Box mt="32px">
        <AboutMSAT
          startPracticeTest={() => {
            //
          }}
          disablePracticeTest={true}
        />
      </Box>
      <Box mt="32px">
        <Testimonials testimonialData={testimonialData} />
      </Box>
    </Box>
  );
};

export default PaymentPendingMasaiOne;
