import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
  isActive?: boolean;
}
export const Home = (props: Props) => (
  <Icon viewBox={props.isActive ? "0 0 24 24" : "0 0 20 20"} {...props}>
    {props?.isActive ? (
      <>
        <path
          opacity="0.2"
          d="M20.25 10.8317V19.4999C20.25 19.6988 20.171 19.8895 20.0303 20.0302C19.8897 20.1708 19.6989 20.2499 19.5 20.2499H15C14.8011 20.2499 14.6103 20.1708 14.4697 20.0302C14.329 19.8895 14.25 19.6988 14.25 19.4999V14.9999C14.25 14.801 14.171 14.6102 14.0303 14.4695C13.8897 14.3289 13.6989 14.2499 13.5 14.2499H10.5C10.3011 14.2499 10.1103 14.3289 9.96967 14.4695C9.82902 14.6102 9.75 14.801 9.75 14.9999V19.4999C9.75 19.6988 9.67098 19.8895 9.53033 20.0302C9.38968 20.1708 9.19891 20.2499 9 20.2499H4.5C4.30109 20.2499 4.11032 20.1708 3.96967 20.0302C3.82902 19.8895 3.75 19.6988 3.75 19.4999V10.8317C3.75001 10.7272 3.77187 10.6239 3.81416 10.5283C3.85646 10.4327 3.91827 10.347 3.99562 10.2767L11.4956 3.19487C11.6337 3.06917 11.8137 2.99951 12.0005 2.99951C12.1872 2.99951 12.3672 3.06917 12.5053 3.19487L20.0053 10.2767C20.0825 10.3471 20.1441 10.4328 20.1863 10.5284C20.2284 10.624 20.2501 10.7273 20.25 10.8317Z"
          fill="#3470E4"
        />
        <path
          d="M20.5153 9.72831L13.0153 2.65206C13.0116 2.64886 13.0082 2.64541 13.005 2.64175C12.7289 2.39062 12.369 2.25146 11.9958 2.25146C11.6225 2.25146 11.2627 2.39062 10.9866 2.64175L10.9763 2.65206L3.48469 9.72831C3.33187 9.86883 3.20989 10.0395 3.12646 10.2296C3.04303 10.4197 2.99997 10.6251 3 10.8327V19.4999C3 19.8977 3.15804 20.2792 3.43934 20.5605C3.72064 20.8418 4.10218 20.9999 4.5 20.9999H9C9.39782 20.9999 9.77936 20.8418 10.0607 20.5605C10.342 20.2792 10.5 19.8977 10.5 19.4999V14.9999H13.5V19.4999C13.5 19.8977 13.658 20.2792 13.9393 20.5605C14.2206 20.8418 14.6022 20.9999 15 20.9999H19.5C19.8978 20.9999 20.2794 20.8418 20.5607 20.5605C20.842 20.2792 21 19.8977 21 19.4999V10.8327C21 10.6251 20.957 10.4197 20.8735 10.2296C20.7901 10.0395 20.6681 9.86883 20.5153 9.72831ZM19.5 19.4999H15V14.9999C15 14.602 14.842 14.2205 14.5607 13.9392C14.2794 13.6579 13.8978 13.4999 13.5 13.4999H10.5C10.1022 13.4999 9.72064 13.6579 9.43934 13.9392C9.15804 14.2205 9 14.602 9 14.9999V19.4999H4.5V10.8327L4.51031 10.8233L12 3.74987L19.4906 10.8214L19.5009 10.8308L19.5 19.4999Z"
          fill="#3470E4"
        />
      </>
    ) : (
      <>
        <path
          opacity="0.2"
          d="M16.875 9.02637V16.2498C16.875 16.4156 16.8092 16.5745 16.6919 16.6917C16.5747 16.809 16.4158 16.8748 16.25 16.8748H12.5C12.3342 16.8748 12.1753 16.809 12.0581 16.6917C11.9408 16.5745 11.875 16.4156 11.875 16.2498V12.4998C11.875 12.334 11.8092 12.1751 11.6919 12.0579C11.5747 11.9407 11.4158 11.8748 11.25 11.8748H8.75C8.58424 11.8748 8.42527 11.9407 8.30806 12.0579C8.19085 12.1751 8.125 12.334 8.125 12.4998V16.2498C8.125 16.4156 8.05915 16.5745 7.94194 16.6917C7.82473 16.809 7.66576 16.8748 7.5 16.8748H3.75C3.58424 16.8748 3.42527 16.809 3.30806 16.6917C3.19085 16.5745 3.125 16.4156 3.125 16.2498V9.02637C3.12501 8.93927 3.14322 8.85314 3.17847 8.77349C3.21372 8.69384 3.26523 8.62244 3.32969 8.56387L9.57969 2.66231C9.69477 2.55756 9.84478 2.49951 10.0004 2.49951C10.156 2.49951 10.306 2.55756 10.4211 2.66231L16.6711 8.56387C16.7354 8.62251 16.7868 8.69394 16.8219 8.77359C16.857 8.85323 16.8751 8.93933 16.875 9.02637Z"
          fill="white"
        />
        <path
          d="M17.0961 8.10717L10.8461 2.21029C10.843 2.20763 10.8402 2.20476 10.8375 2.2017C10.6074 1.99243 10.3075 1.87646 9.99648 1.87646C9.68545 1.87646 9.38558 1.99243 9.15547 2.2017L9.14688 2.21029L2.90391 8.10717C2.77656 8.22427 2.67491 8.36653 2.60538 8.52494C2.53586 8.68336 2.49997 8.85448 2.5 9.02748V16.2501C2.5 16.5817 2.6317 16.8996 2.86612 17.134C3.10054 17.3684 3.41848 17.5001 3.75 17.5001H7.5C7.83152 17.5001 8.14946 17.3684 8.38388 17.134C8.6183 16.8996 8.75 16.5817 8.75 16.2501V12.5001H11.25V16.2501C11.25 16.5817 11.3817 16.8996 11.6161 17.134C11.8505 17.3684 12.1685 17.5001 12.5 17.5001H16.25C16.5815 17.5001 16.8995 17.3684 17.1339 17.134C17.3683 16.8996 17.5 16.5817 17.5 16.2501V9.02748C17.5 8.85448 17.4641 8.68336 17.3946 8.52494C17.3251 8.36653 17.2234 8.22427 17.0961 8.10717ZM16.25 16.2501H12.5V12.5001C12.5 12.1686 12.3683 11.8507 12.1339 11.6163C11.8995 11.3818 11.5815 11.2501 11.25 11.2501H8.75C8.41848 11.2501 8.10054 11.3818 7.86612 11.6163C7.6317 11.8507 7.5 12.1686 7.5 12.5001V16.2501H3.75V9.02748L3.75859 9.01967L10 3.12514L16.2422 9.01811L16.2508 9.02592L16.25 16.2501Z"
          fill="currentColor"
        />
      </>
    )}
  </Icon>
);

export default Home;
