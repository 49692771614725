import React, { useEffect, useState } from "react";
import { Box, Button, Input, Skeleton, Text } from "@chakra-ui/react";
import { useMutation } from "react-query";
import useSnackBar from "../../../common/general/SnackBar";
import { useHistory } from "react-router-dom";
import { useAdminProfile } from "../../../../api/utils/hooks/useAdmin";
import { axiosErrorHandler } from "../../../../api/utils/error";
import { updateUserProfilePersonalInfoByEmailAndPhone } from "../../../../api/utils/api/v2/admin";
import { UpdateProfileByAdmin } from "../../../../api/schemas/schema";
import DateSelect from "../../../common/DateSelect";
import dayjs from "dayjs";

interface UpdateUserPersonalInfoProps {
  email: string;
  phone: string;
}

const UpdateUserPersonalInfo = ({ email, phone }: UpdateUserPersonalInfoProps) => {
  const history = useHistory();
  const snackbar = useSnackBar();
  const [profileDetailForm, setProfileDetailForm] = useState<UpdateProfileByAdmin>();
  const [viewProfileDetailForm, setViewProfileDetailForm] = useState<UpdateProfileByAdmin>();
  const [utm_data, setUtmData] = useState<{
    utm_source: string;
    utm_medium: string;
    utm_campaign: string;
  }>({
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
  });
  const { data: profile, refetch: refetchProfile, isLoading: isProfileLoading } = useAdminProfile(email);

  const { mutate: updateProfileMutate, isLoading: updateProfileLoading } = useMutation(updateUserProfilePersonalInfoByEmailAndPhone, {
    onSuccess: () => {
      snackbar.success("Profile Updated Successfully");
      history.push("/admin/users");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const handleSaveDetails = () => {
    let payload = {
      ...profileDetailForm,
    };
    if (utm_data?.utm_source && utm_data?.utm_medium && utm_data?.utm_campaign) {
      payload = {
        ...payload,
        meta: { ...utm_data },
      };
    }
    updateProfileMutate({
      email: email,
      phone_number: phone,
      data: {
        ...payload,
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (name: string, value: any) => {
    setProfileDetailForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // Filling Profile Meta Details in state
  useEffect(() => {
    if (!isProfileLoading && profile) {
      setViewProfileDetailForm({
        date_of_birth: profile.date_of_birth,
        // graduation_year: profile.graduation_year,
        // graduation_month: profile.graduation_month,
        // is_working: profile.is_working,
        // program_profile_type: profile.program_profile_type,
        email: profile.email,
        phone_number: profile.phone_number,
        name: profile.name,
        // aadhaar_verified: profile.aadhaar_verified,
        last_4_digit_of_aadhar_id: profile.last_4_digit_of_aadhar_id,
        meta: profile.meta,
      });
    }
  }, [profile]);

  useEffect(() => {
    refetchProfile();
  }, [email]);

  return (
    <Skeleton p={{ base: "16px", md: "24px" }} h={"100vh"} isLoaded={true}>
      <Text as="h4" textStyle="h4">
        Enter the details to update <span style={{ fontSize: "15px", fontStyle: "italic", color: "red" }}>(Leave the fields blank if you do not want to update)*</span>
      </Text>

      <Box display={"grid"} gridTemplateColumns={"repeat(1,1fr)"} gap={{ md: "24px" }} pt="10" overflowY={"auto"} position={"relative"} w={["100%", "50%", "40%", "40%"]}>
        <Box>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Enter user email to be updated
          </label>
          <Input name="email" type="email" placeholder="Enter user email" value={profileDetailForm?.email} onChange={(e) => handleChange("email", e.target.value)} />

          {viewProfileDetailForm?.email && <span style={{ fontStyle: "italic", fontSize: "15px", color: "red" }}>Current Value: {viewProfileDetailForm?.email}</span>}
        </Box>

        <Box>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Enter user phone number to be updated
          </label>
          <Input
            name="phone_number"
            type="text"
            placeholder="Enter user phone number +919876543210"
            value={profileDetailForm?.phone_number}
            onChange={(e) => handleChange("phone_number", e.target.value)}
          />

          {viewProfileDetailForm?.phone_number && <span style={{ fontStyle: "italic", fontSize: "15px", color: "red" }}>Current Value: {viewProfileDetailForm?.phone_number}</span>}
        </Box>

        <Box>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Enter user dob to be updated
          </label>
          <DateSelect
            showRelativeDate={false}
            onChange={(value) => {
              handleChange("date_of_birth", value.format("YYYY-MM-DD"));
            }}
            value={profileDetailForm?.date_of_birth ? dayjs(profileDetailForm?.date_of_birth) : null}
            isInvalid={false}
            errorMessage="Please add your Date of birth."
            yearAndMonthOnly={false}
          />

          {viewProfileDetailForm?.date_of_birth && <span style={{ fontStyle: "italic", fontSize: "15px", color: "red" }}>Current Value: {viewProfileDetailForm?.date_of_birth}</span>}
        </Box>

        <Box>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Enter user name to be updated
          </label>
          <Input name="name" type="text" placeholder="Enter user's name" value={profileDetailForm?.name} onChange={(e) => handleChange("name", e.target.value)} />

          {viewProfileDetailForm?.name && <span style={{ fontStyle: "italic", fontSize: "15px", color: "red" }}>Current Value: {viewProfileDetailForm?.name}</span>}
        </Box>

        <Box>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Mark Aadhar Verified
          </label>
          <Input
            name="last_4_digit_of_aadhar_id"
            type="text"
            placeholder="Enter user's last_4_digit_of_aadhar"
            value={profileDetailForm?.last_4_digit_of_aadhar_id}
            onChange={(e) => handleChange("last_4_digit_of_aadhar_id", e.target.value)}
          />

          {viewProfileDetailForm?.last_4_digit_of_aadhar_id && (
            <span style={{ fontStyle: "italic", fontSize: "15px", color: "red" }}>Current Value: {viewProfileDetailForm?.last_4_digit_of_aadhar_id}</span>
          )}
        </Box>

        <Box>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Update UTM Data
          </label>
          <Box display={"flex"} gap="10px" flexDirection={"column"}>
            <Input
              name="utm_source"
              type="text"
              placeholder="Enter utm_source"
              value={utm_data?.utm_source}
              onChange={(e) => {
                setUtmData({
                  ...utm_data,
                  utm_source: e.target.value,
                });
              }}
            />
            {viewProfileDetailForm?.meta?.utm_source && <span style={{ fontStyle: "italic", fontSize: "15px", color: "red" }}>Current Value: {viewProfileDetailForm?.meta?.utm_source}</span>}
            <Input
              name="utm_medium"
              type="text"
              placeholder="Enter utm_medium"
              value={utm_data?.utm_medium}
              onChange={(e) => {
                setUtmData({
                  ...utm_data,
                  utm_medium: e.target.value,
                });
              }}
            />
            {viewProfileDetailForm?.meta?.utm_medium && <span style={{ fontStyle: "italic", fontSize: "15px", color: "red" }}>Current Value: {viewProfileDetailForm?.meta?.utm_medium}</span>}
            <Input
              name="utm_campaign"
              type="text"
              placeholder="Enter utm_campaign"
              value={utm_data?.utm_campaign}
              onChange={(e) => {
                setUtmData({
                  ...utm_data,
                  utm_campaign: e.target.value,
                });
              }}
            />
            {viewProfileDetailForm?.meta?.utm_campaign && <span style={{ fontStyle: "italic", fontSize: "15px", color: "red" }}>Current Value: {viewProfileDetailForm?.meta?.utm_campaign}</span>}
          </Box>
        </Box>

        <Button
          mt="10px"
          isLoading={updateProfileLoading}
          disabled={updateProfileLoading || ((!profileDetailForm || Object.keys(profileDetailForm || {}).length === 0) && !utm_data?.utm_source && !utm_data?.utm_medium && !utm_data?.utm_campaign)}
          size="md"
          textStyle="btn-md"
          variant="primary"
          onClick={handleSaveDetails}
        >
          Update
        </Button>
      </Box>
    </Skeleton>
  );
};

export default UpdateUserPersonalInfo;
