import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CounsellingRequestList from "./CounsellingRequestList";
import CounsellingBulkUpload from "./CounsellingBulkUpload";
import { useLocation } from "react-router-dom";
import { useAllUsersCounsellingRequests } from "../../../../api/utils/hooks/useAdmin";
import Pagination from "../../../../pages/Profile/referrals/Pagination";

const Counselling = () => {
  const search = useLocation().search;
  const page = new URLSearchParams(search).get("page");
  const currentOffset = page === null ? 0 : (Number(page) - 1) * 6;
  const [offset, setOffset] = useState<number>(currentOffset);
  const limit = 10;
  const getNextPage = () => {
    setOffset(offset + limit);
  };
  const getPrevPage = () => {
    setOffset(offset - limit);
  };

  useEffect(() => {
    const currentPage = offset / limit + 1;
    // pass offset and limit to refetch function
    refetchAllCounsellingRequests();
    //  history.push({
    //    pathname,
    //    search: `page=${currentPage}`,
    //  });
  }, [offset, limit]);

  const {
    data: allCounsellingRequests,
    refetch: refetchAllCounsellingRequests,
    isLoading: allCounsellingRequestsLoading,
  } = useAllUsersCounsellingRequests(offset, limit);

  return (
    <Box px="12px" my="40px">
      <CounsellingBulkUpload offset={offset} limit={limit} />
      <CounsellingRequestList offset={offset} limit={limit} />
      {/* pagination */}
      <Box>
        {allCounsellingRequests && allCounsellingRequests.total > 0 && (
          <Pagination
            total={allCounsellingRequests.total}
            limit={limit}
            getNextPage={getNextPage}
            getPrevPage={getPrevPage}
            offset={offset}
            mb={"0x"}
            mt={"24px"}
          />
        )}{" "}
      </Box>
    </Box>
  );
};

export default Counselling;
