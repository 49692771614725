import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const Joomla = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M20.008 17.2533C20.5665 15.4877 20.1267 13.6486 18.9806 12.4679C17.6236 11.0702 14.8647 8.25 14.8647 8.25L12.75 10.4283C12.75 10.4283 15.6931 13.3922 16.8882 14.6232C17.4502 15.2021 17.4502 16.1406 16.8882 16.7195C16.3336 17.2907 15.439 17.2982 14.8755 16.742L12.7829 18.8974C13.9969 20.1305 15.7337 20.4892 17.2518 19.9735C17.3036 21.3776 18.4582 22.5 19.875 22.5C21.3247 22.5 22.5 21.3247 22.5 19.875C22.5 18.4698 21.3959 17.3226 20.008 17.2533Z"
      fill="#F44321"
    />
    <path
      d="M17.2533 3.99204C15.4877 3.43345 13.6486 3.87326 12.4679 5.01944C11.0702 6.37641 8.25 9.1353 8.25 9.1353L10.4283 11.25C10.4283 11.25 13.3922 8.30691 14.6232 7.11181C15.2021 6.54983 16.1406 6.54983 16.7195 7.11181C17.2907 7.66639 17.2982 8.56102 16.742 9.12453L18.8974 11.2171C20.1305 10.0031 20.4892 8.26632 19.9735 6.74819C21.3776 6.6964 22.5 5.54178 22.5 4.12501C22.5 2.67526 21.3247 1.50001 19.875 1.50001C18.4698 1.50001 17.3226 2.60407 17.2533 3.99204Z"
      fill="#FBB854"
    />
    <path
      d="M3.99204 6.74668C3.43345 8.5123 3.87326 10.3514 5.01944 11.5321C6.37641 12.9298 9.1353 15.75 9.1353 15.75L11.25 13.5717C11.25 13.5717 8.30691 10.6078 7.11181 9.37681C6.54983 8.79794 6.54982 7.85939 7.11181 7.28055C7.66639 6.70928 8.56102 6.70177 9.12453 7.258L11.2171 5.10258C10.0031 3.86945 8.26632 3.5108 6.74819 4.02654C6.6964 2.62241 5.54178 1.5 4.125 1.5C2.67526 1.5 1.5 2.67526 1.5 4.125C1.5 5.53015 2.60407 6.67742 3.99204 6.74668Z"
      fill="#7AC143"
    />
    <path
      d="M6.74668 20.008C8.5123 20.5665 10.3514 20.1267 11.5321 18.9806C12.9298 17.6236 15.75 14.8647 15.75 14.8647L13.5717 12.75C13.5717 12.75 10.6078 15.6931 9.37681 16.8882C8.79794 17.4502 7.85939 17.4502 7.28055 16.8882C6.70928 16.3336 6.70177 15.439 7.258 14.8755L5.10258 12.7829C3.86945 13.9969 3.5108 15.7337 4.02653 17.2518C2.62241 17.3036 1.5 18.4582 1.5 19.875C1.5 21.3247 2.67526 22.5 4.125 22.5C5.53015 22.5 6.67742 21.3959 6.74668 20.008Z"
      fill="#5091CD"
    />
  </Icon>
);

export default Joomla;
