import { onboardingStatus } from "../../../api/schemas/schema";
import { useActiveApplication } from "../../../api/utils/hooks/useApplication";
import { Box, Text, Image, useBreakpointValue, keyframes } from "@chakra-ui/react";
import { OnboardingGreenCheck, OnboardingLineDesktop } from "../../../assets/images";

interface Props {
  onboardingStep: {
    id: number;
    key: string;
    value: string;
    marginLeft?: string;
  };
  hideLine?: boolean;
  currentOnboardingStep?: number;
  showIITGuwahatiDashboard?: boolean;
  hasUserPaidSemesterFees?: boolean;
  timeline?: { date: string; overdue: boolean };
  isAllStepsCompleted?: boolean;
  isMsatResultPage?: boolean;
}

const pulse = keyframes`
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.9);
  }
`;

const OnboardingSingleStep = ({ currentOnboardingStep, onboardingStep, hideLine, showIITGuwahatiDashboard, isMsatResultPage, isAllStepsCompleted, timeline }: Props) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const isActive = currentOnboardingStep === onboardingStep.id;
  const { data: activeApplication } = useActiveApplication();

  return (
    <>
      <Box display={"flex"} w="100%" alignItems={"center"}>
        {isAllStepsCompleted && currentOnboardingStep === 5 && currentOnboardingStep === onboardingStep.id ? (
          <Box minW="28px" bg={"#CFEEDF"} rounded={"50%"} w={31} h={31} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Image width={"28px"} height={"28px"} src={"img/iit-course/confetti.svg"} />
          </Box>
        ) : (currentOnboardingStep && currentOnboardingStep > onboardingStep.id) || (onboardingStatus.includes(activeApplication?.status || "") && !showIITGuwahatiDashboard) ? (
          <Box minW="28px">
            <Image width={"28px"} height={"28px"} src={OnboardingGreenCheck} />
          </Box>
        ) : (
          <Box
            minW={"28px"}
            bgColor={isActive ? (timeline?.overdue ? "#FFE180" : "ms-purple.500") : "#fff"}
            color={isActive ? (timeline?.overdue ? "#6C6768" : "#fff") : "ms-grey.600"}
            padding={"2px"}
            borderRadius={"50%"}
            position={"relative"}
          >
            <Text textAlign={"center"} textStyle={"btn-md"}>
              {onboardingStep.id}
            </Text>
          </Box>
        )}

        <Text
          textStyle={"body2"}
          fontSize={"15px"}
          fontWeight={600}
          lineHeight={"24px"}
          position={"absolute"}
          mt="64px"
          color={isActive && !onboardingStatus.includes(activeApplication?.status || "") ? "ms-red.900" : "ms-grey.600"}
          ml={onboardingStep.marginLeft}
          w={"max-content"}
        >
          {onboardingStep.value}
        </Text>
        {timeline ? (
          <Text
            w={"max-content"}
            position={"absolute"}
            display={(currentOnboardingStep && currentOnboardingStep > onboardingStep.id) || isAllStepsCompleted ? "none" : "flex"}
            mt="120px"
            textStyle={"body2-md"}
            color={timeline.overdue ? "#ED0331" : "#3470E4"}
            fontStyle={timeline.overdue ? "italic" : "italic"}
            alignItems={"center"}
            ml={"-10px"}
          >
            {currentOnboardingStep === onboardingStep.id && showIITGuwahatiDashboard ? (
              <Text borderRadius="full" backgroundColor="red.500" width="8px" height="8px" animation={`${pulse} 0.8s infinite`} mr={"6px"}></Text>
            ) : null}
            <Text color={"#544D4F"}>{!timeline.overdue && "Deadline :"}</Text> {timeline.overdue ? "Overdue" : timeline.date}
          </Text>
        ) : null}
        {isMsatResultPage && (
          <Text
            w={"max-content"}
            position={"absolute"}
            display={(currentOnboardingStep && currentOnboardingStep > onboardingStep.id) || isAllStepsCompleted ? "none" : "flex"}
            mt="120px"
            textStyle={"body2-md"}
            color={"#3470E4"}
            fontStyle={"italic"}
            alignItems={"center"}
            ml={"-10px"}
          >
            {currentOnboardingStep === onboardingStep.id ? (
              <>
                <Text borderRadius="full" backgroundColor="red.500" width="8px" height="8px" animation={`${pulse} 0.8s infinite`} mr={"6px"}></Text>
              </>
            ) : null}
            <Text color={"#ED0331"}>Pending</Text>
          </Text>
        )}
        {!hideLine &&
          (isMobile ? (
            <Box
              overflow="hidden"
              _after={{
                w: "full",
                content: '"----------------------"',
                letterSpacing: "4px",
              }}
              minW="100%"
              h="29px"
            ></Box>
          ) : (
            <Image minW={"100%"} height={{ base: "", sm: "", md: "28px" }} src={OnboardingLineDesktop} alt="onboaridng step" />
          ))}
      </Box>
    </>
  );
};

export default OnboardingSingleStep;
