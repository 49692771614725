const spacing = {
  none: "0px",
  "ms-1": "1px",
  "ms-2": "0.125rem",
  "ms-4": "0.25rem",
  "ms-6": "0.375rem",
  "ms-8": "0.5rem",
  "ms-10": "0.625rem",
  "ms-12": "0.75rem",
  "ms-14": "0.875rem",
  "ms-16": "1rem",
  "ms-20": "1.25rem",
  "ms-24": "1.5rem",
  "ms-28": "1.75rem",
  "ms-32": "2rem",
  "ms-36": "2.25rem",
  "ms-40": "2.5rem",
  "ms-44": "2.75rem",
  "ms-48": "3rem",
  "ms-56": "3.5rem",
  "ms-64": "4rem",
  "ms-80": "5rem",
  "ms-96": "6rem",
  "ms-112": "7rem",
  "ms-128": "8rem",
  "ms-144": "9rem",
  "ms-160": "10rem",
  "ms-176": "11rem",
  "ms-192": "12rem",
  "ms-208": "13rem",
  "ms-224": "14rem",
  "ms-240": "15rem",
  "ms-256": "16rem",
  "ms-288": "18rem",
  "ms-320": "20rem",
  "ms-384": "24rem",
};

export default spacing;
