import React from "react"
import { Box, Flex } from "@chakra-ui/react"
interface Props {
    icon: JSX.Element;
    bg: string
}

export const OverviewCardLeft = ({ icon, bg }: Props) => {
    return <Box>
        <Flex justifyContent={"center"} alignItems={"center"} width={{base:"119px",md:"168px"}} height={"100%"} borderRadius={"16px 0px 0px 16px"} bg={bg}>
            {icon}
        </Flex>
    </Box>
}