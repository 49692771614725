import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const GPayLarge = (props: Props) => (
  <Icon viewBox="0 0 34 24" {...props}>
    <path
      d="M0 6C0 2.68629 2.68629 0 6 0H52C55.3137 0 58 2.68629 58 6V34C58 37.3137 55.3137 40 52 40H6C2.68629 40 0 37.3137 0 34V6Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.763 25.88V20.9889H29.2874C30.3218 20.9889 31.1949 20.6423 31.9067 19.9587L32.0775 19.7854C33.3776 18.37 33.2922 16.1652 31.9067 14.8558C31.2139 14.1626 30.2649 13.7871 29.2874 13.8063H25.2351V25.88H26.763ZM26.7632 19.5061V15.289H29.3259C29.8765 15.289 30.3985 15.5008 30.7877 15.8859C31.6134 16.6947 31.6324 18.0426 30.8351 18.8802C30.446 19.2943 29.8954 19.5253 29.3259 19.5061H26.7632ZM39.2046 18.2641C38.5498 17.6575 37.6577 17.3494 36.5284 17.3494C35.0764 17.3494 33.9851 17.8886 33.2638 18.9573L34.6114 19.8142C35.1049 19.0825 35.7787 18.7166 36.6328 18.7166C37.1737 18.7166 37.6957 18.9188 38.1038 19.2847C38.5024 19.6313 38.7301 20.132 38.7301 20.6615V21.0177C38.1417 20.6904 37.4015 20.5171 36.4905 20.5171C35.4276 20.5171 34.5735 20.7674 33.9376 21.2777C33.3018 21.788 32.9791 22.462 32.9791 23.3189C32.9601 24.0987 33.2923 24.8401 33.8807 25.3408C34.4786 25.8799 35.2378 26.1495 36.1298 26.1495C37.1832 26.1495 38.0184 25.6778 38.6542 24.7342H38.7206V25.8799H40.1821V20.7867C40.1821 19.7179 39.8595 18.8707 39.2046 18.2641ZM35.0577 24.3587C34.7445 24.1276 34.5546 23.7522 34.5546 23.3478C34.5546 22.8953 34.7635 22.5198 35.1716 22.2213C35.5892 21.9228 36.1113 21.7688 36.7282 21.7688C37.5825 21.7591 38.2469 21.9517 38.7215 22.3368C38.7215 22.9915 38.4652 23.5596 37.9621 24.041C37.5065 24.5031 36.8896 24.7631 36.2442 24.7631C35.817 24.7727 35.3994 24.6283 35.0577 24.3587ZM43.4657 29.5097L48.5714 17.619H46.9107L44.5476 23.5499H44.5191L42.0992 17.619H40.4384L43.7884 25.3504L41.8904 29.5097H43.4657Z"
      fill="#3C4043"
    />
    <path
      d="M21.4882 19.9298C21.4882 19.458 21.4502 18.9863 21.3743 18.5241H14.9305V21.1911H18.6221C18.4703 22.048 17.9768 22.8183 17.2556 23.2997V25.0327H19.4573C20.7479 23.8292 21.4882 22.048 21.4882 19.9298Z"
      fill="#4285F4"
    />
    <path
      d="M14.9308 26.708C16.7719 26.708 18.3282 26.0918 19.4576 25.0327L17.2559 23.2997C16.639 23.7233 15.8513 23.964 14.9308 23.964C13.1466 23.964 11.6377 22.7413 11.0968 21.1045H8.82861V22.8953C9.98641 25.2349 12.3495 26.708 14.9308 26.708Z"
      fill="#34A853"
    />
    <path
      d="M11.097 21.1045C10.8122 20.2476 10.8122 19.3137 11.097 18.4471V16.6659H8.82845C7.8508 18.6204 7.8508 20.9312 8.82845 22.8857L11.097 21.1045Z"
      fill="#FBBC04"
    />
    <path
      d="M14.9308 15.5876C15.9083 15.5683 16.8478 15.9438 17.5501 16.6274L19.505 14.644C18.2618 13.4694 16.6295 12.8243 14.9308 12.8435C12.3495 12.8435 9.98641 14.3263 8.82861 16.6659L11.0968 18.4567C11.6377 16.8103 13.1466 15.5876 14.9308 15.5876Z"
      fill="#EA4335"
    />
    <path
      d="M6 1H52V-1H6V1ZM57 6V34H59V6H57ZM52 39H6V41H52V39ZM1 34V6H-1V34H1ZM6 39C3.23858 39 1 36.7614 1 34H-1C-1 37.866 2.13401 41 6 41V39ZM57 34C57 36.7614 54.7614 39 52 39V41C55.866 41 59 37.866 59 34H57ZM52 1C54.7614 1 57 3.23858 57 6H59C59 2.13401 55.866 -1 52 -1V1ZM6 -1C2.13401 -1 -1 2.13401 -1 6H1C1 3.23858 3.23858 1 6 1V-1Z"
      fill="#F3F2F2"
    />
  </Icon>
);

export default GPayLarge;
