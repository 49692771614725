import { Box } from "@chakra-ui/react";
import { ActionMeta, PropsValue, Select, SingleValue } from "chakra-react-select";
import React from "react";

const CustomDropDown = ({
  allOptions,
  handleChange,
  value,
  placeholder = "",
  isDisabled,
}: {
  allOptions: { value: string; label: string }[];
  handleChange:
    | ((
        newValue: SingleValue<{
          value: string;
          label: string;
        }>,
        actionMeta: ActionMeta<{
          value: string;
          label: string;
        }>
      ) => void)
    | undefined;
  placeholder: string;
  value?:
    | PropsValue<{
        value: string;
        label: string;
      }>
    | undefined;
  isDisabled?: boolean;
}) => {
  return (
    <Box cursor={"pointer"}>
      <Select
        size="lg"
        onChange={handleChange}
        options={allOptions}
        value={value}
        useBasicStyles
        classNamePrefix="react-select"
        maxMenuHeight={200}
        placeholder={placeholder}
        isDisabled={isDisabled}
      />
    </Box>
  );
};

export default CustomDropDown;
