import { useHistory } from "react-router-dom";
import { Coin } from "../../../../assets/images";
import { Flex, Image, Text } from "@chakra-ui/react";

interface Props {
  hideText: boolean;
  hideBtn: boolean;
}

const HeaderReferAndEarnBtn = ({ hideBtn, hideText }: Props) => {
  const history = useHistory();
  if (hideBtn) return <div />;
  return (
    <Flex
      rounded={"32px"}
      cursor={"pointer"}
      border={"1px solid #FF832B"}
      align={"center"}
      display={{ base: "none", md: "flex" }}
      bg={"#F2F6FF"}
      _hover={{ bg: "#D6E2FA" }}
      onClick={() => {
        history.push("/referral-program");
      }}
    >
      <Image h="full" alt="call" loading="lazy" src={Coin} w={"26px"} height={"26px"} objectFit={"contain"} />
      <Text p={{ base: "0px !important", md: "0px 6px !important" }} fontSize={"14px"} fontWeight={600} display={{ base: "none", xl: hideText ? "none" : "block" }}>
        Refer and Earn
      </Text>
    </Flex>
  );
};

export default HeaderReferAndEarnBtn;
