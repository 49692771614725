import {
  Badge,
  Box,
  Button,
  Text,
  Flex,
  Heading,
  HStack,
  Image,
  Spacer,
  Spinner,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { GetContestType } from "../../api/schemas/schema";
import { registerForContest, startContestEvent } from "../../api/utils/api";
import { axiosErrorHandler } from "../../api/utils/error";
import {
  CalenderRange,
  ClockOutline,
  CurrencyINR,
  InformationOutline,
  Laptop,
} from "../../assets/icons/components";
import { ModalTemplate } from "../../components/common/general";
import IconWithLabel from "../../components/common/general/IconWithLabel";
import useSnackBar from "../../components/common/general/SnackBar";
import EventDetailsSection from "../../components/events/EventDetailsSection";
import { convertMsToTime } from "./getFormattedTime";
import LeaderboardModalContent from "./LeaderboardModalContent";
import ParticipateModalContent from "./ParticipateModalContent";
import { sendTrackingEvent } from "../../components/common/utils/gtm";
interface Props {
  contest: GetContestType;
  getAllContests: () => void;
  serverTime: string;
  autoRegisterThisContest?: boolean;
  setAutoRegisterContestId: (id: number | null) => void;
}

export const getContestDetails = ({
  cost,
  event_start_date,
  event_end_date,
  event_location,
}: {
  cost: number;
  event_start_date: string;
  event_end_date: string;
  event_location: string;
}): {
  icon: React.ReactElement;
  text: string;
  name: string;
}[] => {
  return [
    {
      name: "calendar",
      icon: <CalenderRange boxSize="5" color="ms-grey.600" />,
      text: dayjs(event_start_date).format("ddd, MMM DD,YYYY"),
    },
    {
      name: "clock",
      icon: <ClockOutline boxSize="5" color="ms-grey.600" />,
      text: `${dayjs(event_start_date).format("hh:mm A")} - ${dayjs(event_end_date).format(
        "hh:mm A"
      )}`,
    },
    {
      name: "laptop",
      icon: <Laptop boxSize="5" color="ms-grey.600" />,
      text: `${event_location}`,
    },
    {
      name: "currency",
      icon: <CurrencyINR boxSize="5" color="ms-grey.600" />,
      text: cost === 0 ? "Free" : cost.toString(),
    },
  ];
};
const NewContestCard = ({
  contest,
  getAllContests,
  serverTime,
  autoRegisterThisContest,
  setAutoRegisterContestId,
}: Props) => {
  const [showLeaderboardModal, setShowLeaderboardModal] = useState<boolean>(false);
  const [showParticipateModal, setShowParticipateModal] = useState<boolean>(false);

  const breakpointValues = useBreakpointValue({
    base: { badgeSize: undefined, btnVariant: "secondary", btnSize: "sm" },
    md: { badgeSize: "lg", btnVariant: "tertiary", btnSize: "md" },
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<number>(0);

  useEffect(() => {
    setTimeLeft(dayjs(contest.start_time).diff(dayjs(serverTime), "seconds"));
  }, []);
  useEffect(() => {
    if (autoRegisterThisContest) {
      if (contest.is_registered) {
        history.push(`contests/${contest.id}/details`);
      } else {
        handleContestRegister(contest.id, true);
        setAutoRegisterContestId(null);
      }
    }
  }, [autoRegisterThisContest]);

  const [currentTimeoutId, setCurrentTimeoutId] = useState<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (timeLeft && timeLeft > 0) {
      currentTimeoutId && clearTimeout(currentTimeoutId);
      const id = setTimeout(() => {
        setTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);
      setCurrentTimeoutId(id);
      return () => clearTimeout(id);
    }
  }, [timeLeft]);

  const contestDetails = getContestDetails({
    cost: 0,
    event_start_date: contest.start_time,
    event_end_date: contest.end_time,
    event_location: "Online",
  });

  const snackbar = useSnackBar();
  const location = useLocation();
  const history = useHistory();

  const handleContestRegister = async (contest_id: number, autoRegister?: boolean) => {
    setLoading(true);
    registerForContest({ contest_id })
      .then((res) => {
        // getAllContests();
        setShowParticipateModal(true);
        setLoading(false);
        if (autoRegister) {
          snackbar.success("Registered for contest successfully!");
          history.push(`contests/${contest.id}/details`);
        }
      })
      .catch((err) => {
        setLoading(false);
        const e = axiosErrorHandler(err);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("something went wrong");
        }
      });
  };
  return (
    <>
      <Box
        minW={{ base: "full", md: "360px" }}
        pt="ms-16"
        pb={timeLeft > 0 && timeLeft < 86400 ? "0px" : "ms-16"}
        borderRadius={"ms-16"}
        border={"1px solid #E7E6E6"}
        d={"flex"}
        flexDirection={"column"}
      >
        <Box px={{ base: "0.75rem", md: "1rem" }} h={"100%"} d={"flex"} flexDirection={"column"}>
          <Image
            src={contest?.details?.contest_image_url[0]}
            alt="event"
            borderRadius={"ms-16"}
            w="full"
          />
          <Box pt={"ms-12"} pb="ms-8" mt={"auto"}>
            <HStack>
              <Badge variant={"cyan"} size={breakpointValues?.badgeSize}>
                Contest
              </Badge>
              <Spacer />
              <Box mt="ms-8">
                <IconWithLabel
                  color="ms-grey.600"
                  textStyle={"caption"}
                  icon={<InformationOutline boxSize="4" color="#B2B2B2" />}
                  label="Laptop/deskop required"
                />
              </Box>
            </HStack>
            <Box minH={"48px"} mt={"ms-8"} mb={"ms-16"}>
              <Heading color="ms-red.900" as="h5">
                {contest.name}
              </Heading>
            </Box>
            <Stack mb={"ms-32"} spacing={"ms-16"}>
              <EventDetailsSection eventDetail={contestDetails} />
            </Stack>
          </Box>
        </Box>

        {/* leaderboard button */}
        {serverTime !== "" && dayjs(contest.end_time).isBefore(dayjs(serverTime)) ? (
          <Box mt={"auto"}>
            <Flex justifyContent="flex-end" px="14px" gap="ms-24">
              <Button
                size={breakpointValues?.btnSize}
                variant={"primary"}
                onClick={() => {
                  sendTrackingEvent({
                    event: "dash_events_contests_view_leaderboard",
                    eventLabel: location.pathname,
                  });
                  setShowLeaderboardModal(true);
                }}
                position={loading ? "relative" : "static"}
              >
                View Leaderboard{" "}
                {loading && (
                  <Spinner
                    speed={"0.5s"}
                    thickness={"2px"}
                    size={"md"}
                    position={"absolute"}
                    color={"ms-blue.500"}
                  />
                )}
              </Button>
            </Flex>
          </Box>
        ) : (
          <Box mt={"auto"}>
            <Flex justifyContent="flex-end" px="14px" gap="ms-24">
              <Link
                to={`/contests/${contest.id}/details`}
                onClick={() => {
                  sendTrackingEvent({
                    event: "dash_events_contests_view_details",
                    eventLabel: location.pathname,
                  });
                }}
              >
                <Button size={breakpointValues?.btnSize} variant={breakpointValues?.btnVariant}>
                  View Details
                </Button>
              </Link>
              {contest.is_registered ? (
                <Button
                  size={breakpointValues?.btnSize}
                  variant={"primary"}
                  onClick={() => {
                    setLoading(true);
                    startContestEvent({ contest_id: contest.id })
                      .then((res) => {
                        setTimeout(() => {
                          window.open(res.url, "_blank");
                        });
                        setLoading(false);
                      })
                      .catch((err) => {
                        setLoading(false);
                        const e = axiosErrorHandler(err);
                        if (typeof e === "object" && "message" in e) {
                          snackbar.error(e.message);
                        } else {
                          snackbar.error("something went wrong");
                        }
                      });
                  }}
                  disabled={timeLeft > 0 || loading ? true : false}
                  position={loading ? "relative" : "static"}
                >
                  Start
                  {loading && (
                    <Spinner
                      speed={"0.5s"}
                      thickness={"2px"}
                      size={"md"}
                      position={"absolute"}
                      color={"ms-blue.500"}
                    />
                  )}
                </Button>
              ) : (
                <Button
                  size={breakpointValues?.btnSize}
                  variant={"primary"}
                  onClick={() => handleContestRegister(contest.id)}
                  disabled={loading}
                  position={loading ? "relative" : "static"}
                >
                  Register
                  {loading && (
                    <Spinner
                      speed={"0.5s"}
                      thickness={"2px"}
                      size={"md"}
                      position={"absolute"}
                      color={"ms-blue.500"}
                    />
                  )}
                </Button>
              )}
            </Flex>
          </Box>
        )}
        {timeLeft > 0 && timeLeft < 86400 && (
          <Box
            borderBottomLeftRadius={"ms-16"}
            borderBottomRightRadius={"ms-16"}
            bgColor={"ms-blue.500"}
            mt="ms-16"
            py={"ms-4"}
            textAlign="center"
          >
            <Text textStyle={"body2-md"} color={"#fff"}>
              Contest starts in {convertMsToTime(timeLeft * 1000)}
            </Text>
          </Box>
        )}
      </Box>
      <ModalTemplate
        title=""
        isOpen={showParticipateModal}
        onClose={() => {
          setShowParticipateModal(false);
          getAllContests();
        }}
        marginX={2}
        maxWidth="504px"
        showCloseButtonIcon={true}
        scrollBehavior={"inside"}
      >
        <ParticipateModalContent
          contestName={contest.name}
          setShowParticipateModal={(value) => {
            if (value == false) getAllContests();
            setShowParticipateModal(value);
          }}
        />
      </ModalTemplate>
      <ModalTemplate
        title=""
        isOpen={showLeaderboardModal}
        onClose={() => {
          setShowLeaderboardModal(false);
          history.push({
            pathname: `/events`,
            search: ``,
          });
        }}
        marginX={2}
        maxWidth="540px"
        showCloseButtonIcon={true}
        scrollBehavior={"inside"}
      >
        <LeaderboardModalContent
          contestId={contest.id}
          contestName={contest.name}
          pathname={"/events"}
        />
      </ModalTemplate>
    </>
  );
};

export default NewContestCard;
