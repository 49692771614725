import { Box, useBreakpointValue } from "@chakra-ui/react";
import ReactPlayer from "react-player";

type Props = {
  videoUrl: string;
};

const VideoModal = ({ videoUrl }: Props) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box>
      <ReactPlayer
        controls={true}
        playing
        height={isMobile ? "220px" : "400px"}
        width={"full"}
        url={videoUrl}
      />
    </Box>
  );
};

export default VideoModal;
