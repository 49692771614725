import { Box, Circle, Flex, Heading, Text, useBreakpointValue } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import { useLocation, useParams } from "react-router-dom";
import failedLottie from "../../assets/failedLottie.json";
import ShareAllIcon from "../../assets/icons/components/ShareAllIcon";
import pendingLottie from "../../assets/pendingLottie2.json";
import successLottie from "../../assets/successLottie.json";
import useCleverTap, {
  CleverTapEvents,
  ModalNames,
  ScreenNames,
} from "../../clevertap/useCleverTap";
import { ModalTemplate } from "../../components/common/general";
import { sendTrackingEvent } from "../../components/common/utils/gtm";
import { AppContext } from "../../context/ApplicationContext";
import ShareModal from "./ShareModal";
import { ProgramProfileType } from "../../enums/ProgramType.enum";
// TODO: api integration, take links etc as props
export const Banner = ({
  type,
  text,
  name,
  title,
  onShareClick,
  certificateUrl,
  hideLottie,
  customCirleContent,
  customWidth,
  hideShareButton,
  showMobileDesign,
  extras,
  minHeight,
  textZIndex = 10,
  borderRadius = "16px",
  border,
}: {
  type: "error" | "success" | "pending";
  text?: string | JSX.Element | JSX.Element[];
  name?: string;
  title: string;
  certificateUrl?: string;
  onShareClick?: () => void;
  hideLottie?: boolean;
  customCirleContent?: JSX.Element;
  customWidth?: string[];
  hideShareButton?: boolean;
  showMobileDesign?: boolean;
  extras?: JSX.Element | undefined;
  minHeight?: string;
  textZIndex?: number | string;
  borderRadius?: string;
  border?: string;
}) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const params = useParams() as { applicantID: string };
  const clevertap = useCleverTap()[0];
  const onShareModalClose = () => {
    setIsShareModalOpen(false);
    clevertap.fireEvent(CleverTapEvents.click_close_modal, {
      screen_name: ScreenNames.msatSuccess,
      modal_name: ModalNames.msatSuccess,
    });
  };
  // Breakpoints for lottie animation size
  const width = showMobileDesign
    ? "68px"
    : useBreakpointValue({
        base: "68px",
        lg: "120px",
      });

  useEffect(() => {
    const msatSuccessModalVisited = JSON.parse(
      localStorage.getItem("msatSuccessModalVisited-" + params.applicantID) as string
    );
    if (!msatSuccessModalVisited && window.location.href.includes("msat-success")) {
      // show modal and set visited to true in localstorage
      setIsShareModalOpen(true);
      onShareClick && onShareClick();
      localStorage.setItem("msatSuccessModalVisited-" + params.applicantID, JSON.stringify(true));
    }
  }, []);
  const isMobile = useBreakpointValue({ base: true, md: false });
  // States data
  const map = {
    error: {
      bg: "ms-red.50",
      lottie: failedLottie,
      borderColor: "ms-red.100",
    },
    success: {
      bg: "ms-green.50",
      lottie: successLottie,
      borderColor: "ms-cyan.100",
    },
    pending: {
      bg: "ms-yellow.50",
      lottie: pendingLottie,
      borderColor: "ms-cyan.100",
    },
  };
  const headingStyle = isMobile || showMobileDesign ? "h6" : "h3";
  const location = useLocation();
  const state = useContext(AppContext)[0];
  const batch_id = state.applicationData?.batch_campus.batch_id;
  const handleShareWithFriends = () => {
    onShareClick && onShareClick();
    if (location.pathname.includes("msat-success")) {
      sendTrackingEvent({
        event: "MP-Share_this_with_your_friends",
        eventLabel: `/batch/${batch_id}/msat-success`,
      });
    }

    setIsShareModalOpen(true);
  };

  const isIITGuwahatiDashboard =
    state.coursePreference === ProgramProfileType.IIT_GUWAHATI ? true : false;
  const isIITMandiDashboard =
    state.coursePreference === ProgramProfileType.IIT_MANDI ? true : false;
  return (
    <Flex
      w="full"
      p={isMobile || showMobileDesign ? "ms-12" : "ms-24"}
      borderRadius={borderRadius}
      border={border ? border : isMobile || showMobileDesign ? "1px solid" : "0px"}
      borderColor={"ms-cyan.100"}
      bg={map[type].bg}
      alignItems={"center"}
      overflow={"hidden"}
      pos="relative"
      minH={isMobile || showMobileDesign ? minHeight || "152px" : "136px"}
    >
      <Flex w={customWidth || ["60%", "50%"]} flexDir={"column"} gap="0.5rem">
        <Heading as={headingStyle} color={"ms-blue.500"}>
          {title}
        </Heading>
        <Text
          zIndex={0}
          textStyle={isMobile || showMobileDesign ? "body1" : "body2"}
          color={"ms-grey.700"}
        >
          {text}
        </Text>
        <Flex>
          {/* Share button on success only */}

          {type === "success" && !hideShareButton ? (
            <Flex gap="0.5rem" cursor="pointer" onClick={handleShareWithFriends} align="center">
              <Text
                textStyle={isMobile || showMobileDesign ? "btn-sm" : "btn-md"}
                uppercase
                color="ms-blue.500"
              >
                SHARE THIS WITH YOUR FRIENDS
              </Text>
              <ShareAllIcon boxSize="24px" />
            </Flex>
          ) : null}
          {/* Icon */}
        </Flex>
        <Circle
          position="absolute"
          bg={map[type].borderColor}
          right={showMobileDesign ? "-57px" : { base: "-57px", lg: "-16px" }}
          size={showMobileDesign ? "162px" : { base: "162px", lg: "380px" }}
          top={showMobileDesign ? "-57px" : { base: "-57px", lg: "-122px" }}
          justifyContent="center"
        >
          <Circle bg="white" size={showMobileDesign ? "124px" : { base: "124px", lg: "200px" }}>
            <Box
              borderRadius={type === "pending" ? "50%" : ""}
              bgColor={customCirleContent ? "#fff" : type === "pending" ? "ms-cyan.50" : ""}
              position={showMobileDesign ? "absolute" : { base: "absolute", lg: "inherit" }}
              top={type === "pending" ? ["75px", "57px"] : ["57px"]}
              right={type === "pending" ? ["75px", "57px"] : ["57px"]}
            >
              {customCirleContent ? customCirleContent : null}
              {!hideLottie && (
                <Lottie
                  loop
                  animationData={map[type].lottie}
                  play
                  style={{
                    width:
                      type === "pending" ? (isMobile || showMobileDesign ? "40px" : "80px") : width,
                  }}
                />
              )}
            </Box>
          </Circle>
        </Circle>
        {extras}
      </Flex>
      <ModalTemplate
        title=""
        isOpen={isShareModalOpen && window.location.href.includes("msat-success")}
        onClose={onShareModalClose}
        marginX={2}
        maxWidth="600px"
      >
        <ShareModal
          referrerName={state.profileData?.referrer_name}
          certificateUrl={certificateUrl ? certificateUrl : ""}
          name={name}
          isIITGuwahatiDashboard={isIITGuwahatiDashboard}
          isIITMandiDashboard={isIITMandiDashboard}
        />
      </ModalTemplate>
    </Flex>
  );
};
