import { Box, Heading, Text, ListItem, UnorderedList } from "@chakra-ui/react";
import React from "react";

const MasaiXInterviewTips = () => {
  const list = [
    `Have a light source behind or next to your camera, which projects on your face.`,
    `Position the camera to your eye level. If using a laptop, consider placing it on top of
    some books or a box.`,
    `Select a neutral background. If interviewing from your bedroom, make sure that
    everything in the background is neat and tidy.`,
    `Test your audio and video. Head/Earphones are usually helpful in improving your audio
    quality.`,
    `Find a quiet space with little to no possibility for interruptions.`,
    `Clear your screen and close extra browsers, especially if there is a chance you’ll be
    sharing your screen.`,
    `Before the interview, silence your phone and turn off other notifications (like email,
        or slack) and make sure your device is charged.`,
  ];
  return (
    <Box>
      <Heading as="h4">Interview Tips</Heading>
      <UnorderedList mt={"ms-12"}>
        {list.map((item) => (
          <ListItem mb={"2px"} key={item}>
            <Text color={"ms-grey.900"} textStyle={{ base: "body2", md: "body1" }}>
              {item}
            </Text>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
};

export default MasaiXInterviewTips;
