import { Box, Image, Heading, Text, Button } from "@chakra-ui/react";
import React from "react";
import CongoPoper from "../../../../assets/images/CoursePrograms/CongoPopper.svg";
const InterviewPassedModal = () => {
  return (
    <Box>
      <Image m={"auto"} src={CongoPoper} alt="emoji" />
      <Box textAlign={"center"}>
        <Heading mt={"ms-24"} as="h3">
          Congratulations!🤩
        </Heading>
        <Text mt={"ms-16"} textStyle={"body1"}>
          You have succesfully cleared all the tests required for your admission. Get onboarded now.
        </Text>
        <Button mt={"ms-24"} variant="primary">
          continue to onboarding
        </Button>
      </Box>
    </Box>
  );
};

export default InterviewPassedModal;
