import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
  isActive?: boolean;
}

const MediaTab = (props: Props) => {
  return (
    <Icon viewBox={props.isActive ? "0 0 24 24" : "0 0 24 24"} {...props}>
      {props?.isActive ? (
        <>
          <path
            d="M8.25 10.5C8.25 10.3011 8.32902 10.1103 8.46967 9.96967C8.61032 9.82902 8.80109 9.75 9 9.75H16.5C16.6989 9.75 16.8897 9.82902 17.0303 9.96967C17.171 10.1103 17.25 10.3011 17.25 10.5C17.25 10.6989 17.171 10.8897 17.0303 11.0303C16.8897 11.171 16.6989 11.25 16.5 11.25H9C8.80109 11.25 8.61032 11.171 8.46967 11.0303C8.32902 10.8897 8.25 10.6989 8.25 10.5ZM9 14.25H16.5C16.6989 14.25 16.8897 14.171 17.0303 14.0303C17.171 13.8897 17.25 13.6989 17.25 13.5C17.25 13.3011 17.171 13.1103 17.0303 12.9697C16.8897 12.829 16.6989 12.75 16.5 12.75H9C8.80109 12.75 8.61032 12.829 8.46967 12.9697C8.32902 13.1103 8.25 13.3011 8.25 13.5C8.25 13.6989 8.32902 13.8897 8.46967 14.0303C8.61032 14.171 8.80109 14.25 9 14.25ZM21.75 6V17.25C21.75 17.8467 21.5129 18.419 21.091 18.841C20.669 19.2629 20.0967 19.5 19.5 19.5H3C2.40505 19.5 1.83431 19.2644 1.41264 18.8446C0.990983 18.4249 0.752727 17.8553 0.75 17.2603V8.25C0.75 8.05109 0.829018 7.86032 0.96967 7.71967C1.11032 7.57902 1.30109 7.5 1.5 7.5C1.69891 7.5 1.88968 7.57902 2.03033 7.71967C2.17098 7.86032 2.25 8.05109 2.25 8.25V17.25C2.25 17.4489 2.32902 17.6397 2.46967 17.7803C2.61032 17.921 2.80109 18 3 18C3.19891 18 3.38968 17.921 3.53033 17.7803C3.67098 17.6397 3.75 17.4489 3.75 17.25V6C3.75 5.60218 3.90804 5.22064 4.18934 4.93934C4.47064 4.65804 4.85218 4.5 5.25 4.5H20.25C20.6478 4.5 21.0294 4.65804 21.3107 4.93934C21.592 5.22064 21.75 5.60218 21.75 6ZM20.25 6H5.25V17.25C5.25037 17.5055 5.20693 17.7592 5.12156 18H19.5C19.6989 18 19.8897 17.921 20.0303 17.7803C20.171 17.6397 20.25 17.4489 20.25 17.25V6Z"
            fill="#3470E4"
          />
        </>
      ) : (
        <>
          <path
            d="M8.25 10.5C8.25 10.3011 8.32902 10.1103 8.46967 9.96967C8.61032 9.82902 8.80109 9.75 9 9.75H16.5C16.6989 9.75 16.8897 9.82902 17.0303 9.96967C17.171 10.1103 17.25 10.3011 17.25 10.5C17.25 10.6989 17.171 10.8897 17.0303 11.0303C16.8897 11.171 16.6989 11.25 16.5 11.25H9C8.80109 11.25 8.61032 11.171 8.46967 11.0303C8.32902 10.8897 8.25 10.6989 8.25 10.5ZM9 14.25H16.5C16.6989 14.25 16.8897 14.171 17.0303 14.0303C17.171 13.8897 17.25 13.6989 17.25 13.5C17.25 13.3011 17.171 13.1103 17.0303 12.9697C16.8897 12.829 16.6989 12.75 16.5 12.75H9C8.80109 12.75 8.61032 12.829 8.46967 12.9697C8.32902 13.1103 8.25 13.3011 8.25 13.5C8.25 13.6989 8.32902 13.8897 8.46967 14.0303C8.61032 14.171 8.80109 14.25 9 14.25ZM21.75 6V17.25C21.75 17.8467 21.5129 18.419 21.091 18.841C20.669 19.2629 20.0967 19.5 19.5 19.5H3C2.40505 19.5 1.83431 19.2644 1.41264 18.8446C0.990983 18.4249 0.752727 17.8553 0.75 17.2603V8.25C0.75 8.05109 0.829018 7.86032 0.96967 7.71967C1.11032 7.57902 1.30109 7.5 1.5 7.5C1.69891 7.5 1.88968 7.57902 2.03033 7.71967C2.17098 7.86032 2.25 8.05109 2.25 8.25V17.25C2.25 17.4489 2.32902 17.6397 2.46967 17.7803C2.61032 17.921 2.80109 18 3 18C3.19891 18 3.38968 17.921 3.53033 17.7803C3.67098 17.6397 3.75 17.4489 3.75 17.25V6C3.75 5.60218 3.90804 5.22064 4.18934 4.93934C4.47064 4.65804 4.85218 4.5 5.25 4.5H20.25C20.6478 4.5 21.0294 4.65804 21.3107 4.93934C21.592 5.22064 21.75 5.60218 21.75 6ZM20.25 6H5.25V17.25C5.25037 17.5055 5.20693 17.7592 5.12156 18H19.5C19.6989 18 19.8897 17.921 20.0303 17.7803C20.171 17.6397 20.25 17.4489 20.25 17.25V6Z"
            fill="black"
          />
        </>
      )}
    </Icon>
  );
};

export default MediaTab;
