import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
  isActive?: boolean;
}
export const Activity = (props: Props) => (
  <Icon viewBox={props.isActive ? "0 0 24 24" : "0 0 20 20"} {...props}>
    {props.isActive ? (
      <>
        <path
          d="M4 6H20V16H4V6ZM20 18C20.5304 18 21.0391 17.7893 21.4142 17.4142C21.7893 17.0391 22 16.5304 22 16V6C22 4.89 21.1 4 20 4H4C2.89 4 2 4.89 2 6V16C2 16.5304 2.21071 17.0391 2.58579 17.4142C2.96086 17.7893 3.46957 18 4 18H0V20H24V18H20Z"
          fill="#3470E4"
        />
        <path opacity="0.2" d="M4 16V6H20V16H4Z" fill="#3470E4" />
      </>
    ) : (
      <>
        <g clipPath="url(#clip0_16491_1819)">
          <path
            d="M3.83333 5.00016H17.1667V13.3335H3.83333V5.00016ZM17.1667 15.0002C17.6087 15.0002 18.0326 14.8246 18.3452 14.512C18.6577 14.1994 18.8333 13.7755 18.8333 13.3335V5.00016C18.8333 4.07516 18.0833 3.3335 17.1667 3.3335H3.83333C2.90833 3.3335 2.16667 4.07516 2.16667 5.00016V13.3335C2.16667 13.7755 2.34226 14.1994 2.65482 14.512C2.96738 14.8246 3.39131 15.0002 3.83333 15.0002H0.5V16.6668H20.5V15.0002H17.1667Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="clip0_16491_1819">
            <rect width="20" height="20" fill="white" transform="translate(0.5)" />
          </clipPath>
        </defs>
      </>
    )}
  </Icon>
);

export default Activity;
