import { useContext } from "react";
import {
  AccountTieVoice,
  BrainIcon,
  EyeOutline,
  SpellCheck,
} from "../../../assets/icons/components";
import Refferals from "../../../assets/icons/components/Others/Refferals";
import { AppContext } from "../../../context/ApplicationContext";

export const handleNewMsatScore = (
  scores: { [key: string]: string[] } | undefined,
  testType?: "coding" | "msft"
): {
  id: number;
  title: string;
  status: string;
  marksObtained?: string;
  totalMarks?: number;
  icon: JSX.Element;
  pendingText?: string;
  hideMarks?: boolean;
}[] => {
  /*
  Cognitive
  Attention to details
  Verbal Ability
  Verbal Communication
   */

  const cognitiveScores = scores?.cognitive_ability ?? ["", "", ""];
  const attentiontoDetailsScores = scores?.attention_to_detail ?? ["", "", ""];
  const verbalAbilityScores = scores?.verbal_ability ?? ["", "", ""];
  const verbalCommunicationScores = scores?.verbal_communication ?? ["", "", ""];

  const aptitudeScores = scores?.aptitude_score || scores?.total_msat_score || ["", "", ""];
  const credibility = scores?.credibility ?? [""];
  const state = useContext(AppContext)[0];
  const mettlStatus = state.applicationData?.status;
  const isMalPracticeDetected = state.applicationData?.mettl_score?.isMalPracticeDetected;
  const failedInAtleastOneSection: boolean = [
    cognitiveScores[1],
    attentiontoDetailsScores[1],
    verbalAbilityScores[1],
  ].includes("FAILED");

  if (testType === "coding") {
    const codingScores = scores?.coding_marks ?? ["", "", ""];
    const scoreDetails = [
      {
        id: 1,
        title: "Coding Test",
        status: codingScores[1],
        marksObtained: codingScores[0],
        totalMarks: isNaN(Number(codingScores[2])) ? 0 : Number(codingScores[2]),
        icon: <BrainIcon />,
        pendingText: "Evaluating submission (Please refresh this page after 30 minutes) ",
      },
    ];
    return scoreDetails;
  }

  let scoreDetails = [
    {
      id: 1,
      title: "Cognitive Ability (CA)",
      status: cognitiveScores[1],
      marksObtained: cognitiveScores[0],
      totalMarks: isNaN(Number(cognitiveScores[2])) ? 0 : Number(cognitiveScores[2]),
      icon: <BrainIcon />,
      pendingText: "Evaluating submission (Please refresh this page after 30 minutes) ",
    },
    {
      id: 2,
      title: "Attention to Detail (ATD)",
      status: attentiontoDetailsScores[1],
      marksObtained: attentiontoDetailsScores[0],
      totalMarks: isNaN(Number(attentiontoDetailsScores[2]))
        ? 0
        : Number(attentiontoDetailsScores[2]),
      icon: <EyeOutline />,
      pendingText: "Evaluating submission (Please refresh this page after 30 minutes) ",
    },
    {
      id: 3,
      title: "Aptitude Score (CA + ATD)",
      status: aptitudeScores[1],
      marksObtained: aptitudeScores[0],
      totalMarks: isNaN(Number(aptitudeScores[2])) ? 0 : Number(aptitudeScores[2]),
      pendingText: "Evaluating submission (Please refresh this page after 30 minutes) ",
      icon: <Refferals />,
    },
    {
      id: 4,
      title: "Verbal Ability",
      status: verbalAbilityScores[1],
      marksObtained: verbalAbilityScores[0],
      totalMarks: isNaN(Number(verbalAbilityScores[2])) ? 0 : Number(verbalAbilityScores[2]),
      icon: <SpellCheck />,
      pendingText: "Evaluating submission (Please refresh this page after 30 minutes) ",
    },
    {
      id: 5,
      title: "Verbal Communication",
      status: verbalCommunicationScores[1],
      marksObtained: verbalCommunicationScores[0],
      totalMarks: isNaN(Number(verbalCommunicationScores[2]))
        ? 0
        : Number(verbalCommunicationScores[2]),
      icon: <AccountTieVoice />,
      pendingText: "Evaluating submission (Expect your final results within 72 hours)",
      hideMarks: true,
    },
  ];
  // remove Aptitude Score (CA + ATD) card if student is failed in CA or ATD
  if (cognitiveScores[1] === "FAILED" || attentiontoDetailsScores[1] === "FAILED") {
    scoreDetails = scoreDetails.filter((scoreDetail) => scoreDetail.id !== 3);
  }
  if (mettlStatus === "METTL_FAILED" || mettlStatus === "SCHOLARSHIP_TEST_FAILED") {
    // METTL_FAILED || SCHOLARSHIP_TEST_FAILED
    if (failedInAtleastOneSection) {
      scoreDetails = scoreDetails.filter((scoreDetail) => scoreDetail.id !== 5);
    } else {
      if (isMalPracticeDetected) {
        // Show only message and not score
        scoreDetails = [];
      } else if (
        (credibility[0] === "low" || credibility[0] === "medium" || credibility[0] === "high") &&
        verbalCommunicationScores[1] === "FAILED"
      ) {
        // Show scores of 4 sections
      }
    }
  }

  // METTL_DESCISION_PENDING
  else if (
    mettlStatus === "METTL_DESCISION_PENDING" ||
    mettlStatus === "SCHOLARSHIP_TEST_DECISION_PENDING"
  ) {
    // Show scores of 4 sections
  }

  // METTL_PASSED
  else if (mettlStatus === "METTL_PASSED" || mettlStatus === "SCHOLARSHIP_TEST_PASSED") {
    // Show scores of 4 sections
  }

  if (testType === "msft") {
    scoreDetails = scoreDetails.filter((scoreDetail) => scoreDetail.id !== 5);
  }
  return scoreDetails;
};
