import { Box, Link, Text } from "@chakra-ui/react";
import YoutubeCard from "../../msat/msat-instructions/YoutubeCard";

const TakeMSAT = () => {
  return (
    <Box>
      <Text textStyle={"h4"}>Complete and clear MSAT </Text>
      <Text textStyle={"body1"}>(Masai School Admission Test)</Text>
      <Box maxW={629} mt={3}>
        <YoutubeCard baseHeight="208px" mdHeight={"327px"} url="https://youtu.be/cA80lsg0mlo" padding="0px" eventName="d_msat_video" />
      </Box>
      <Link href="https://masaischool.com/msat/syllabus" mt={4} pb={4} fontSize="sm" fontWeight="semibold" lineHeight="short" color="#3470E4" textDecoration="underline" target="_blank">
        View MSAT Syllabus
      </Link>
    </Box>
  );
};

export default TakeMSAT;
