import React from "react";
import { Box, Skeleton } from "@chakra-ui/react";
import DostNav from "../../../components/V2/Dost/DostNav";
import DostProfile from "../../../components/V2/Dost/DostProfile";
import DostChatFlow from "../../../components/V2/Dost/DostChatFlow";
import { useDost } from "../../../api/utils/hooks/useDost";

const index = () => {
  const { data: dostData, isLoading: isDostDataLoading } = useDost();

  React.useEffect(() => {
    if (!isDostDataLoading && !dostData) {
      window.location.href = "/";
    }
  }, [dostData, isDostDataLoading]);
  return isDostDataLoading ? (
    <Skeleton h="100vh" w="full" />
  ) : (
    <Box h="full">
      {dostData ? (
        <Box position="sticky" display={{ base: "block", md: "none" }} top="56px" zIndex="1">
          <DostNav />
        </Box>
      ) : (
        <></>
      )}
      <Box p="14px 16px" position="sticky" top={{ base: "98px", md: "56px" }} zIndex="1">
        {dostData ? <DostProfile dostData={dostData} /> : <></>}
      </Box>
      {!isDostDataLoading && dostData ? <DostChatFlow /> : <></>}
    </Box>
  );
};

export default index;
