import React, { Dispatch } from "react";
import { ProgramType } from "../../../pages/Courses";

type HeaderConetxtState = {
  selectedProgram?: ProgramType;
  fromHeader?: boolean;
};
type HeaderConetxtAction = {
  type: "SET_SELECTED_PROGRAM";
  payload: HeaderConetxtState;
};
export const initState: HeaderConetxtState = {
  selectedProgram: undefined,
  fromHeader: undefined,
};
export const HeaderContext = React.createContext<
  [HeaderConetxtState, Dispatch<HeaderConetxtAction>]
>([initState, () => null]);

export const HeaderReducer = (state: HeaderConetxtState, action: HeaderConetxtAction) => {
  switch (action.type) {
    case "SET_SELECTED_PROGRAM": {
      return {
        ...state,
        selectedProgram: action.payload.selectedProgram,
        fromHeader: action.payload.fromHeader,
      };
    }
    default:
      return state;
  }
};
