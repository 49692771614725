import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useBreakpointValue,
  Heading,
  Text,
  Box,
} from "@chakra-ui/react";

interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}
const TnCDrawer = ({ isOpen, setIsOpen }: Props) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Drawer
      size={isMobile ? "full" : "md"}
      isOpen={isOpen}
      placement={isMobile ? "bottom" : "right"}
      onClose={() => setIsOpen(false)}
    >
      <DrawerOverlay />
      <DrawerContent height={{ base: "60vh", md: "100%" }}>
        <DrawerCloseButton />
        <DrawerHeader>
          <Heading as="h3">Mandatory Conditions</Heading>
        </DrawerHeader>
        <DrawerBody mt={{ base: "-12px", md: "0px" }}>
          <Text color={"ms-grey.800"} textStyle={"body2-md"}>
            Kindly note that your admission is provisional and is subject to the following
            conditions:
          </Text>
          <Box mt={"ms-8"}>
            <Box d={"flex"}>
              <Text color={"ms-grey.700"} mr={"ms-4"} textStyle={"body2"}>{`a.`}</Text>
              <Text color={"ms-grey.700"} textStyle={"body2"}>
                {`Your admission is provisional and is subject to completion of the verification process(emergency contact , background & technical checks).`}
              </Text>
            </Box>
            <Box d={"flex"}>
              <Text color={"ms-grey.700"} mr={"ms-4"} textStyle={"body2"}>{`b.`}</Text>
              <Text color={"ms-grey.700"} textStyle={"body2"}>
                {`A minimum CIBIL score of 650 &  approval from NBFC is mandatory to continue in the course.`}
              </Text>
            </Box>
            <Box d={"flex"}>
              <Text color={"ms-grey.700"} mr={"ms-4"} textStyle={"body2"}>{`c.`}</Text>
              <Text color={"ms-grey.700"} textStyle={"body2"}>
                {`Clearing communication assessment is mandatory in Unit 1.`}
              </Text>
            </Box>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default TnCDrawer;
