import { ProgramProfileType } from "../../api/schemas/schema";

export interface CourseDataInterface {
  icon: string;
  title: string;
  description: string;
  pointers: { isBold: boolean; text: string }[];
  courseLinkUrl: string;
  courseType: ProgramProfileType;
}

export const papPointers = [
  { icon: "/img/revamp/red-currency-inr.svg", text: "Apply and study for ZERO upfront fee" },
  { icon: "/img/revamp/red-briefcase.svg", text: "Pay only when you get a job of 3.5LPA or more" },
  { icon: "/img/revamp/red-chalkboard-teacher.svg", text: "Taught by Masai instructors & industry experts" },
];
export const papData: CourseDataInterface[] = [
  {
    icon: "/img/revamp/courses/sd.svg",
    title: "Software Development",
    description: "",
    pointers: [
      { isBold: false, text: "Become job-ready in 30-35 weeks." },
      { isBold: true, text: "Full Stack Developer, Backend Developer, Software Engineer & much more." },
    ],
    courseLinkUrl: "/courses/12/details",
    courseType: "MASAI",
  },
  {
    icon: "/img/revamp/courses/da.svg",
    title: "Data Analytics",
    description: "",
    pointers: [
      { isBold: false, text: "Become job-ready in 25 weeks." },
      { isBold: true, text: "Data Engineer, Data Analyst, Machine Learning Engineer & much more." },
    ],
    courseLinkUrl: "/courses/14/details",
    courseType: "MASAI",
  },
];

export const iitPointers = [
  { icon: "/img/revamp/blue-chalkboard-teacher.svg", text: "Live lectures & evaluations by IIT professors" },
  { icon: "/img/revamp/blue-briefcase.svg", text: "Placement assistance upon course completion" },
  { icon: "/img/revamp/blue-identification-card.svg", text: "Access to IIT email ID & ID card" },
];

export const iitData: CourseDataInterface[] = [
  {
    icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp",
    title: "IIT Guwahati",
    description: "Micro-Credit Program in Computer Science",
    pointers: [
      { isBold: false, text: "Get 24 program credits in 12 months" },
      { isBold: false, text: "Access IIT Guwahati Daksh Gurukul Alumni Network" },
    ],
    courseLinkUrl: "/mc-iitg-cs",
    courseType: "IIT_GUWAHATI",
  },
  {
    icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT_M_81a2d8f496.webp",
    title: "IIT Mandi",
    description: "Minor in Data Science and Machine Learning",
    pointers: [
      { isBold: false, text: "Get 24 program credits in 12 months" },
      { isBold: false, text: "Access IIT Mandi Campus" },
    ],
    courseLinkUrl: "/iit-mandi-ai-ml",
    courseType: "IIT_MANDI",
  },
];

export const coursesList = [
  { iconUrl: "/img/revamp/courses/sd.svg", title: "Software Development", courseLink: "/courses/12/details", courseType: "MASAI" },
  { iconUrl: "/img/revamp/courses/da.svg", title: "Data Analytics", courseLink: "/courses/14/details", courseType: "MASAI" },
];

export const iitCoursesList = [
  {
    iconUrl: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp",
    title: "IIT Guwahati",
    description: "Micro-Credit Program in Computer Science",
    courseLink: "/mc-iitg-cs",
    courseType: "IIT_GUWAHATI",
  },
  {
    iconUrl: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT_M_81a2d8f496.webp",
    title: "IIT Mandi",
    description: "Minor in Data Science and Machine Learning",
    courseLink: "/iit-mandi-ai-ml",
    courseType: "IIT_MANDI",
  },
];

export const sdCourseData: CourseDataInterface[] = [
  {
    icon: "/img/revamp/courses/sd.svg",
    title: "Software Development",
    description: "",
    pointers: [
      { isBold: false, text: "Become job-ready in 30-35 weeks." },
      { isBold: true, text: "Full Stack Developer, Backend Developer, Software Engineer & much more." },
    ],
    courseLinkUrl: "/courses/12/details",
    courseType: "MASAI",
  },
];

export const daCourseData: CourseDataInterface[] = [
  {
    icon: "/img/revamp/courses/da.svg",
    title: "Data Analytics",
    description: "",
    pointers: [
      { isBold: false, text: "Become job-ready in 25 weeks." },
      { isBold: true, text: "Data Engineer, Data Analyst, Machine Learning Engineer & much more." },
    ],
    courseLinkUrl: "/courses/14/details",
    courseType: "MASAI",
  },
];

export const iitGCourseData: CourseDataInterface[] = [
  {
    icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp",
    title: "IIT Guwahati",
    description: "Micro-Credit Program in Computer Science",
    pointers: [
      { isBold: false, text: "Get 24 program credits in 12 months" },
      { isBold: false, text: "Access IIT Guwahati Daksh Gurukul Alumni Network" },
    ],
    courseLinkUrl: "/mc-iitg-cs",
    courseType: "IIT_GUWAHATI",
  },
];

export const iitMandiCourseData: CourseDataInterface[] = [
  {
    icon: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT_M_81a2d8f496.webp",
    title: "IIT Mandi",
    description: "Minor in Data Science and Machine Learning",
    pointers: [
      { isBold: false, text: "Get 24 program credits in 12 months" },
      { isBold: false, text: "Access IIT Mandi Campus" },
    ],
    courseLinkUrl: "/iit-mandi-ai-ml",
    courseType: "IIT_MANDI",
  },
];
