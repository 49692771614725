import { Button, Flex, Image, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { convertUrlToCDNUrl } from "../../../utils/utils";
import { AppContext, ApplicationActionType } from "../../../context/ApplicationContext";

interface Props {
  setIsEligible: (value: boolean) => void;
  inEligibleReason: string | null;
}

const InEligibleCard = ({ setIsEligible, inEligibleReason }: Props) => {
  const dispatch = useContext(AppContext)[1];
  return (
    <Flex direction={"column"} gap={2} align={"center"} justify={"center"}>
      <Image src={convertUrlToCDNUrl("https://masai-website-images.s3.ap-south-1.amazonaws.com/Group_1321316728_b0409fc445.png")} w={"84px"} h={"84px"} objectFit={"contain"} />
      <Text fontSize={"24px"} fontWeight={700} fontFamily={"Poppins"} color={"#3470E4"}>
        Not Eligible!
      </Text>
      <Text fontSize={"16px"} fontWeight={500} fontFamily={"Poppins"} color={"#544D4F"} maxW={"595px"} mx={"auto"} textAlign={"center"}>
        You are not eligible for the course because {inEligibleReason && inEligibleReason}. Please visit our website to explore other courses <br /> that may suit you.
      </Text>

      <Button
        variant="solid"
        color={"#3470E4"}
        mt={6}
        minW={"180px"}
        onClick={() => {
          setIsEligible(false);
          dispatch({ type: ApplicationActionType.FETCH_PROFILE_V2, payload: { fetchProfileDataV2: true } });
        }}
      >
        Edit Profile
      </Button>
    </Flex>
  );
};

export default InEligibleCard;
