import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";

interface Props {
  children: JSX.Element[] | JSX.Element;
  path: string;
  exact?: boolean;
}

const PrivateRoute: React.FunctionComponent<Props> = ({ children, path, ...rest }) => {
  const [ret, setReturn] = useState<JSX.Element | null>(null);

  useEffect(() => {
    // * manage auth here?
    // * manage global auth for logout if required
    if (!ret) {
      setReturn(<>{children}</>);
    }
  }, [children, ret]);

  return (
    <Route path={path} {...rest}>
      {ret}
    </Route>
  );
};

export default PrivateRoute;
