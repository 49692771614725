import React from "react";
import { Box, Button, Heading, Text, Link as ChakraLink } from "@chakra-ui/react";
import successLottie from "../../../assets/successLottie.json";
import Lottie from "react-lottie-player";
import useCleverTap, { CleverTapEvents, ModalNames } from "../../../clevertap/useCleverTap";
import { Discord } from "../../../assets/icons/components";

interface Props {
  url?: string;
  onModalDiscordBtnTrack: () => void;
}

const EventRegisterModal = ({ url, onModalDiscordBtnTrack }: Props) => {
  const clevertap = useCleverTap()[0];

  React.useEffect((): void => {
    clevertap.fireEvent(CleverTapEvents.viewed_modal, {
      modal_name: ModalNames.bootcampRegisteredSuccessfully,
    });
  }, []);

  return (
    <Box textAlign={"center"}>
      <Box
        m={"auto"}
        mb={"ms-32"}
        w={{ base: "97px", md: "112px" }}
        h={{ base: "104px", md: "120px" }}
      >
        <Lottie loop animationData={successLottie} play style={{ width: "100%" }} />
      </Box>
      <Heading mb={"ms-16"} as="h3">
        Successfully Registered
      </Heading>
      <Text d={"inline"} textStyle="body1">
        You have successfully registered the Masai School bootcamp event. All the important updates
        regarding the workshop will be shared in our exclusive Discord community! Make sure you sign
        up.
      </Text>
      <ChakraLink isExternal href={url} _hover={{ textDecoration: "none" }}>
        <Box mt={"40px"}>
          <Button
            onClick={onModalDiscordBtnTrack}
            variant={"secondary-grey"}
            size="lg"
            color={"ms-grey.900"}
          >
            <Discord boxSize={"6"} />
            <Text textStyle="btn-md" ml={"ms-12"}>
              Continue with discord
            </Text>
          </Button>
        </Box>
      </ChakraLink>
    </Box>
  );
};

export default EventRegisterModal;
