import React from "react";
import { GetAdminEventType } from "../../../../api/schemas/schema";
import { Box } from "@chakra-ui/react";
import { ActivityDetails } from "../../../activities/Details";
import { convertGetEventToActivity } from "../../../activities/Activity";

interface Props {
  event?: GetAdminEventType;
}
const PreviewOnwardEventModal = ({ event }: Props) => {
  const activity = event ? convertGetEventToActivity(event) : null;
  return <>{activity && <ActivityDetails isAdmin={true} activity={activity} />}</>;
};

export default PreviewOnwardEventModal;
