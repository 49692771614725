import { Box, Text, Image } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { SuccessTick, FailTick, UncheckedRadioDisabled } from "../../../assets/images";
import CheckedRadioImage from "../../../assets/images/CheckedRadioImage.svg";
import UncheckedRadioImage from "../../../assets/images/UncheckedRadioImage.svg";
import { useCourses } from "../../../api/utils/hooks/useCourse";
import { GetCourseV2Type } from "../../../api/schemas/schema";
import { useHistory } from "react-router-dom";
import { useBestAssessment } from "../../../api/utils/hooks/useBestAssessment";
interface Props {
  selectedCourse: number | null;
  setSelectedCourse: (value: number | null) => void;
}

const CourseSelectionCard = ({ setSelectedCourse, selectedCourse }: Props) => {
  const { data: allCourses } = useCourses();

  const { data: bestAssessment } = useBestAssessment();

  const [courses, setCourses] = React.useState<
    {
      id: number;
      name: string;
      isEligible: boolean;
      batchId?: number;
    }[]
  >([]);
  useEffect(() => {
    if (allCourses) {
      setCourses(
        allCourses
          ?.filter((course: GetCourseV2Type) => course.id !== 20)
          .map((course: GetCourseV2Type) => ({
            id: course.id,
            name: course.name,
            isEligible: bestAssessment?.eligible_courses?.includes(course.id) ? true : false,
            batchId: course.active_batch?.id,
          }))
      );

      if (bestAssessment && bestAssessment?.eligible_courses && bestAssessment?.eligible_courses?.filter((course) => course !== 20).length > 0) {
        if (bestAssessment.eligible_courses.includes(14)) {
          setSelectedCourse(14);
        } else if (bestAssessment.eligible_courses.includes(12)) {
          setSelectedCourse(12);
        } else if (bestAssessment.eligible_courses.includes(15)) {
          setSelectedCourse(15);
        }
      }
    }
  }, [allCourses, bestAssessment, bestAssessment?.eligible_courses]);

  {
    /* Eligible Courses List */
  }
  const history = useHistory();
  return (
    <Box position={"relative"} borderBottomLeftRadius="12px" borderBottomRightRadius={"12px"} bgColor={"#fff"}>
      <Box height="96%" borderRight="1px solid #ccc" position={"absolute"} top="12px" left="65%"></Box>

      <Box p="16px" display={"grid"}>
        {/* courses header */}
        <Box pb="4px" borderBottom={"1px solid #ccc"} display={"grid"} gridTemplateColumns={"70% 30%"}>
          <Text textStyle={"body2"} color="ms-grey.900">
            Courses
          </Text>
          <Text color="ms-grey.900" textStyle={"body2"}>
            Cut-off clear
          </Text>
        </Box>
        {/* courses list items */}
        <Box mt="12px" display={"grid"} gap="24px">
          {courses.map((course) => {
            return (
              <Box
                onClick={() => {
                  course.isEligible && setSelectedCourse(course.id);
                }}
                key={course.id}
                display={course.batchId ? "grid" : "none"}
                gridTemplateColumns={"70% 30%"}
              >
                <Box display={"flex"} gap="6px">
                  {!course.isEligible ? (
                    <Image cursor={"pointer"} src={UncheckedRadioDisabled} />
                  ) : selectedCourse === course.id ? (
                    <Box cursor={"pointer"}>
                      <Image src={CheckedRadioImage} />
                    </Box>
                  ) : (
                    <Box cursor={"pointer"}>
                      <Image src={UncheckedRadioImage} />
                    </Box>
                  )}
                  <Box>
                    <Text textStyle={"body1-md"} color={course.isEligible ? "ms-grey.900" : "ms-grey.500"} mb="8px">
                      {course.name} <Text color={course.isEligible ? "ms-red.900" : "ms-red.500"}>{course.id === 12 ? "(Starts on 5th August)" : "(Starts on 2nd September)"}</Text>
                    </Text>
                    {course.isEligible && (
                      <Text
                        cursor={"pointer"}
                        onClick={() => {
                          history.push(`/courses/${course.id}/details`);
                        }}
                        as="a"
                        textStyle={"body1-md"}
                        color="ms-blue.500"
                      >
                        (view details)
                      </Text>
                    )}
                  </Box>
                </Box>
                <Box>{course.isEligible ? <Image src={SuccessTick} /> : <Image src={FailTick} />}</Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default CourseSelectionCard;
