import { Box, Button, Flex, Heading, Image, Link, Spinner, Text } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { BookOpen } from "../../../../assets/icons/components";
import { Steps } from "./types";
import Onboarding from "./Onboarding";
import { OnboardingContext } from "../OnboardingProgressPage";
import { enrollSelfLearningCourse } from "../../../../api/utils/api/self_learning";
import { AppContext } from "../../../../context/ApplicationContext";
import { axiosErrorHandler } from "../../../../api/utils/error";
import useSnackBar from "../../../../components/common/general/SnackBar";
import { useDebouncedCallback } from "use-debounce/lib";
import dayjs from "dayjs";
import { BannerZoomTelegramLinks } from "./BannerZoomTelegramLinks";
import useCleverTap, {
  ButtonTypes,
  CleverTapEvents,
  ScreenNames,
} from "../../../../clevertap/useCleverTap";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import { useLocation } from "react-router-dom";

const PendingCard = ({ start_date }: { start_date?: string }) => {
  const [state, dispatch] = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const snackbar = useSnackBar();
  const clevertap = useCleverTap()[0];
  const location = useLocation();
  const handleStartSelfLearningCourse = useDebouncedCallback(async () => {
    sendTrackingEvent({
      event: "os_start_self_learning_course",
      eventLabel: location.pathname,
    });
    clevertap.fireEvent(CleverTapEvents.click_start_self_learning, {
      screen_name: ScreenNames.onboarding,
      button_text: "START SELF LEARNING COURSE",
      button_type: ButtonTypes.primary,
    });
    try {
      if (!state.applicationData?.batch_campus?.course_specialization?.learnworlds_course_id)
        throw new Error("course id not found!");
      setLoading(true);
      const response = await enrollSelfLearningCourse({
        self_learning_course_id:
          state.applicationData?.batch_campus.course_specialization.learnworlds_course_id,
      });
      if (response.url) {
        snackbar.success(`Redirecting to course!`);
        window.location.href = response.url;
      }
    } catch (err) {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    }
    setLoading(false);
  }, 200);

  return (
    <>
      <Heading as="h4">Congrats!</Heading>
      <Text mt="0.5rem" textStyle="body-md">
        Your first class will be on{" "}
        <b>
          {start_date &&
            `${dayjs(start_date).format("DD MMM YYYY")} at ${dayjs(start_date).format("h A")}`}
        </b>
        .
        <br />
        You will receive an email with the zoom link and other information for the first class.
        <br />
        While you are waiting for the first class, you can complete the learn the basics of coding
        through our self learning course.
      </Text>
      <Flex mt="1.5rem" direction={["column", "row"]} gap={["1.5rem", "13rem"]}></Flex>
      <Flex mt={["1rem", "2rem"]} justify="end">
        <Link isExternal>
          <Button
            onClick={handleStartSelfLearningCourse}
            variant="primary"
            rightIcon={<BookOpen boxSize="24px" />}
            disabled={loading}
            position={loading ? "relative" : "static"}
          >
            START SELF LEARNING COURSE{" "}
            {loading && (
              <Spinner
                speed={"0.5s"}
                thickness={"2px"}
                size={"md"}
                position={"absolute"}
                color={"ms-blue.500"}
              />
            )}
          </Button>
        </Link>
      </Flex>
    </>
  );
};

const ProgressCard = ({ start_date }: { start_date?: string }) => {
  return (
    <>
      <Heading as="h4">Welcome to Masai School!</Heading>
      <Text mt="0.5rem" textStyle="body-md">
        Your first class will be on{" "}
        <b>
          {start_date &&
            `${dayjs(start_date).format("DD MMM YYYY")} at ${dayjs(start_date).format("h A")}`}
        </b>
        .
        <br />
        You will receive an email with the zoom link and other information for the first class.
        <br />
        While you are waiting for the first class, you can complete the learn the basics of coding
        through our self learning course.
      </Text>
      <Flex mt="1.5rem" direction={["column", "row"]} gap={["1.5rem", "13rem"]}></Flex>
      <Box mt="1rem">
        <Image
          h="16rem"
          borderRadius="ms-16"
          alt="success"
          src={"https://media2.giphy.com/media/OoTKFwKiOAbYc/giphy.gif"}
        />
      </Box>
    </>
  );
};

export const StepTwo = ({ handleStepCick }: { handleStepCick: (steps: Steps) => void }) => {
  const state = useContext(AppContext)[0];
  const onboarding = useContext(OnboardingContext)[0];
  const learnwordsProgress = onboarding.onboardingInfo?.learnworlds_completed
    ? "completed"
    : "pending";

  const start_date = state.applicationData?.batch_campus.batch.start_date;
  return (
    <>
      <BannerZoomTelegramLinks />
      <Onboarding
        statusArray={["completed", learnwordsProgress]}
        handleStepCick={handleStepCick}
        subtitle={
          <>
            Just a few more steps and you’ll be ready for the{" "}
            <b>batch of {start_date && dayjs(start_date).format("MMM YYYY")}</b>
          </>
        }
      >
        <Box mt={["1rem", "2rem"]}>
          {learnwordsProgress === "pending" ? (
            <PendingCard start_date={start_date} />
          ) : learnwordsProgress === "completed" ? (
            <ProgressCard start_date={start_date} />
          ) : null}
        </Box>
      </Onboarding>
    </>
  );
};
