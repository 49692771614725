import { Box, Heading, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { MasaiXStageType } from "./MasaiXCodingInstructions";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AppContext } from "../../../../context/ApplicationContext";
import CurrentStageModalMobile from "./CurrentStageModalMobile";
interface Props {
  currentStage: MasaiXStageType;
  insideModal?: boolean;
  masaiXStages: MasaiXStageType[];
  masaiXStagesText: string[];
}
const CurrentStageMobile = ({
  insideModal,
  currentStage,
  masaiXStages,
  masaiXStagesText,
}: Props) => {
  const state = useContext(AppContext)[0];

  let percentage = 25;
  let circleText = "-";
  let progressText = "";
  switch (currentStage) {
    case "CODING":
      percentage = 25;
      circleText = "1/4";
      progressText =
        state.applicationData?.status === "CODING_TEST_PASSED" ? "Completed" : "In Progress";
      break;
    case "INTERVIEW":
      percentage = 50;
      circleText = "2/4";
      progressText =
        state.applicationData?.status === "INTERVIEW_PASSED" ? "Completed" : "In Progress";
      break;
    case "AADHAAR_VERIFICATION":
      percentage = 75;
      circleText = "3/4";
      progressText = "In Progress";
      break;
    case "ONBOARDING":
      percentage = 100;
      circleText = "4/4";
      break;
    default:
      percentage = 0;
  }

  return (
    <>
      {insideModal ? (
        <CurrentStageModalMobile currentStage={currentStage} masaiXStagesText={masaiXStagesText} />
      ) : (
        <Box p={"ms-16"} display={"flex"} bgColor={"ms-red.50"}>
          <Box mr={"ms-16"} maxW={"72px"}>
            <CircularProgressbar
              styles={buildStyles({
                // Text size
                textSize: "24px",
                // Can specify path transition in more detail, or remove it entirely
                // pathTransition: 'none',
                // Colors
                pathColor: `#3470E4`,
                textColor: "#21191B",
                trailColor: "#D6E2FA",
              })}
              value={percentage < 0 ? 0 : percentage}
              text={percentage < 0 ? `-` : circleText}
            />
          </Box>
          <Box>
            <Heading as="h5" color={"ms-grey.600"}>
              {masaiXStagesText[masaiXStages.indexOf(currentStage)]}
            </Heading>
            <Text
              p={"ms-4"}
              px={"8px"}
              mt={"ms-16"}
              color={progressText.toUpperCase() === "COMPLETED" ? "ms-success" : "ms-blue.500"}
              fontWeight={600}
              fontSize={"12px"}
              lineHeight={"16px"}
              bgColor={progressText.toUpperCase() === "COMPLETED" ? "ms-green.50" : "ms-blue.50"}
              textAlign={"center"}
              borderRadius={"100px"}
              width={"fit-content"}
            >
              {progressText.toUpperCase()}
            </Text>
          </Box>
        </Box>
      )}
    </>
  );
};

export default CurrentStageMobile;
