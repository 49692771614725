import { Box, Text, Link, Flex } from "@chakra-ui/react";

interface Props {
  phone?: string;
  handlePhoneEditClick: () => void;
}

const PhoneEdit = ({ phone, handlePhoneEditClick }: Props) => {
  return (
    <Box>
      <Text textStyle="body2" color="ms-grey.700">
        The OTP has been sent to your phone number
      </Text>
      <Flex alignItems={"center"}>
        <Text mb={"ms-8"} mt={"ms-4"} textStyle="body2-md" color="ms-grey.700">
          {phone}
        </Text>
        <Link
          _hover={{ textDecoration: "none" }}
          onClick={() => {
            //
          }}
        >
          <Text
            onClick={handlePhoneEditClick}
            mb={"ms-2"}
            ml={"ms-8"}
            color="ms-blue.500"
            textStyle="body2-md"
          >
            Edit
          </Text>
        </Link>
      </Flex>
    </Box>
  );
};

export default PhoneEdit;
