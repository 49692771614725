import { Box, Text, Image } from "@chakra-ui/react";
import ContestRank1 from "../../assets/images/ContestRank1.svg";
import ContestRank2 from "../../assets/images/ContestRank2.svg";
import ContestRank3 from "../../assets/images/ContestRank3.svg";

import { LeaderboardDataType } from "../../api/schemas/schema";

interface Props {
  scoreData?: LeaderboardDataType;
  currentUser?: boolean;
}
const LeaderboardRow = ({ scoreData, currentUser }: Props) => {
  let name = "",
    score = "",
    rank = "";
  if (scoreData) {
    rank = scoreData.rank + "";
    score = scoreData.score + "";
    name = scoreData.name;
  }

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        py={"ms-8"}
        px={"ms-24"}
        border="1px solid #E5E5E5"
        borderRadius={"ms-8"}
        bgColor={scoreData?.me || currentUser ? "ms-yellow.100" : ""}
        alignItems={"center"}
      >
        <Box d={"flex"} alignItems={"center"}>
          {[1, 2, 3].includes(+rank) ? (
            <Box>
              <Image
                src={
                  +rank === 1
                    ? ContestRank1
                    : +rank === 2
                    ? ContestRank2
                    : +rank === 3
                    ? ContestRank3
                    : ""
                }
                alt="rank"
              />
            </Box>
          ) : (
            <Text
              borderRadius={"6px"}
              px={"ms-12"}
              py={"ms-4"}
              bgColor={"ms-blue.50"}
              color={"ms-blue.500"}
              textStyle={"body2-md"}
            >
              {rank}
            </Text>
          )}

          <Text ml={"72px"} textStyle={"body1"}>
            {name}
          </Text>
        </Box>
        <Text textStyle={"body1"}>{score}</Text>
      </Box>
    </>
  );
};

export default LeaderboardRow;
