import { useContext, useState, useEffect } from "react";
import { Box, Heading, Text, Link } from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import { AadhaarInfoType } from "../../../../api/schemas/schema";
import {
  confirmAadhaarDetails,
  processAadhaarOCR,
  sendOtpAadhaar,
  verifyOtpAadhaar,
} from "../../../../api/utils/api/documentation";
import { axiosErrorHandler } from "../../../../api/utils/error";
import { InformationOutline } from "../../../../assets/icons/components";
import { BottomStickyNavbar, ModalTemplate } from "../../../../components/common/general";
import useSnackBar from "../../../../components/common/general/SnackBar";
import IdVerification from "../../../../components/msat/success-fail/IdVerification";
import VerificationSuccessModal from "../../../../components/msat/success-fail/VerificationSuccessModal";
import { AppContext, ApplicationActionType } from "../../../../context/ApplicationContext";
import useApplicationHook from "../../../../hooks/useApplicationHook";
import ConsentCheck from "../../../Application/msat/ConsentCheck";

import { AadhaarUploadedDataType } from "../../../Application/msat/MSATSuccess";
import { Banner } from "../../Banner";

import CurrentStage from "./CurrentStage";
import { MasaiXStageType } from "./MasaiXCodingInstructions";
import { Constants } from "../../../../components/common/constants";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import OTPModal from "../../../../components/common/auth/OTPModal";

interface Props {
  currentStage: MasaiXStageType;
}
export type ScoreDetailType = {
  id: number;
  title?: string;
  icon?: JSX.Element;
  marksObtained?: string;
  totalMarks?: number;
  // assumed status as passed failed and pending for now
  status?: string;
  // if score is pending for communication skills section
  pendingText?: string;
  hideMarks?: boolean;
};
const MasaiXInterviewSuccess = ({ currentStage }: Props) => {
  const showOcrUi = false;
  const location = useLocation();
  const [appData] = useContext(AppContext);
  const [formValues, setFormValues] = useState<FormValues>({});
  const [isFormValuesValid, setIsFormValuesValid] = useState(false);
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  // Consent checkbox group state: This checkbox group consists of 1 checkbox.
  const [isAadhaarVerifPending, setIsAadhaarVerifPending] = useState<boolean>(false);
  const [isVerifSuccessModalOpen, setIsVerifSuccessModalOpen] = useState(false);

  const [aadhaarUploadedData, setAadhaarUploadedData] = useState<AadhaarUploadedDataType>({});
  const [aadhaarOCRDetails, setAadhaarOCRDetails] = useState<AadhaarInfoType>();
  const [showfetchingInfoModal, setShowFetchingInfoModal] = useState<boolean>(false);
  const [showConfirmAadhaarModal, setShowConfirmAadhaarModal] = useState<boolean>(false);
  const [invalidAadhaarModal, setInvalidAadhaarModal] = useState<boolean>(false);
  const [isAadhaarUploadedDataValid, setIsAadhaarUploadedDataValid] = useState<boolean>(false);
  const [selectedValuesIsaConsent, setSelectedValuesIsaConsent] = useState<(string | number)[]>([
    "consent-isa",
  ]);
  const [selectedValuesHealthConsent, setSelectedValuesHealthConsent] = useState<
    (string | number)[]
  >(["consent-health"]);
  const [selectedValuesScholarshipConsent, setSelectedValuesScholarshipConsent] = useState<
    (string | number)[]
  >([]);
  const [tabHasFocus, setTabHasFocus] = useState(true);

  const [loading, setLoading] = useState<boolean>(false);
  const [state, dispatch] = useContext(AppContext);
  const params = useParams() as { applicantID: string };
  const snackbar = useSnackBar();
  const { fetchApplicationData } = useApplicationHook()[1];

  const application = appData.applicationData;
  const batch = application?.batch_campus?.batch;
  const consentDocsUrls = {
    coc_doc: batch?.coc_doc,
    isa_doc: batch?.isa_doc,
    mbp_doc: batch?.mbp_doc,
  };
  const onFormValuesChange = (formValues: { [key: string]: string }) => {
    setFormValues(formValues);
  };
  useEffect(() => {
    if (application && application.coc_isa_mbp_consent) {
      setSelectedValuesIsaConsent(["consent-isa"]);
      setSelectedValuesHealthConsent(["consent-health"]);
    }

    if (application && application.scholarship_consent) {
      setSelectedValuesScholarshipConsent(["scholarship"]);
    }
  }, [application]);
  useEffect(() => {
    fetchApplicationData && fetchApplicationData(params.applicantID);
    dispatch({
      type: ApplicationActionType.FETCH_PROFILE,
      payload: {},
    });
    const handleFocus = () => {
      setTabHasFocus(true);
    };
    const handleBlur = () => {
      setTabHasFocus(false);
    };
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);
  const profile_slug = state.profileData?.slug;
  useEffect(() => {
    if (tabHasFocus && isAadhaarVerifPending) {
      fetchApplicationData && fetchApplicationData(params.applicantID);
    }
  }, [tabHasFocus]);
  useEffect(() => {
    if (application?.profile.aadhaar_verified && application?.docs_verified) {
      setIsVerifSuccessModalOpen(true);
      setIsAadhaarVerifPending(false);
    } else if (state.profileData?.aadhaar_verified && application?.docs_verified === false) {
      setIsAadhaarVerifPending(true);
      setIsVerifSuccessModalOpen(false);
    }
  }, [state.profileData, state.applicationData]);
  // here we are checking if the sticky bottom button will be disabled or not.
  useEffect(() => {
    validate();
  }, [formValues]);
  // validate form values
  const validate = () => {
    let isValid = true;
    for (const [key, value] of Object.entries(formValues)) {
      // empty field validation
      if (value === "") {
        isValid = false;
        break;
      }
      // min and max length validation
      const currentField = formData.find((oneField) => oneField.name === key);
      if (currentField?.maxLegnth && value.length > currentField.maxLegnth) {
        isValid = false;
      }
      if (currentField?.minLegnth && value.length < currentField.minLegnth) {
        isValid = false;
      }
      // number validation

      if (currentField?.numberOnly) {
        const digitsOnly = new RegExp("^[0-9]+$");
        if (!digitsOnly.test(value)) isValid = false;
      }
    }
    setIsFormValuesValid(isValid);
  };

  const handleSendOTP = async () => {
    setLoading(true);
    // snackbar.info("Sending OTP!");
    if (profile_slug) {
      try {
        const res = await sendOtpAadhaar({
          profile_slug,
          aadhaar_id: formValues.adhaar,
          address: formValues.address,
          application_slug: params.applicantID,
          coc_isa_mbp_consent:
            selectedValuesIsaConsent.includes("consent-isa") &&
            selectedValuesHealthConsent.includes("consent-health"),
          scholarship_consent: selectedValuesScholarshipConsent.includes("scholarship"),
        });
        localStorage.setItem(
          "idVerifErrorOccured-" + state.applicationData?.slug,
          JSON.stringify(false)
        );
        snackbar.info("OTP Sent!");
        setIsOTPModalOpen(true);
      } catch (err) {
        localStorage.setItem(
          "idVerifErrorOccured-" + state.applicationData?.slug,
          JSON.stringify(true)
        );
        const e = axiosErrorHandler(err);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("something went wrong, please try again!");
        }
        console.error(err);
      }
    }
    setLoading(false);
  };
  const handleVerifyOTP = async (otp: string) => {
    //
    setLoading(true);
    if (!profile_slug) return;
    try {
      const res = await verifyOtpAadhaar({
        profile_slug,
        application_slug: params.applicantID,
        otp_input: Number(otp),
      });
      localStorage.setItem(
        "idVerifErrorOccured-" + state.applicationData?.slug,
        JSON.stringify(false)
      );
      snackbar.success("OTP Verification Successful!");
      setIsOTPModalOpen(false);
      setIsAadhaarVerifPending(true);
      // setIsVerifSuccessModalOpen(true);
      fetchApplicationData && fetchApplicationData(params.applicantID);
    } catch (err) {
      localStorage.setItem(
        "idVerifErrorOccured-" + state.applicationData?.slug,
        JSON.stringify(true)
      );
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong, please try again!");
      }
    }
    setLoading(false);
  };

  const handleAadhaarOcr = async () => {
    setLoading(true);

    const files = ["", ""];
    if (aadhaarUploadedData.aadhaarFrontCaptured) {
      files[0] = aadhaarUploadedData.aadhaarFrontCaptured;
    } else if (aadhaarUploadedData.aadhaarFront) {
      files[0] = aadhaarUploadedData.aadhaarFront;
    }
    if (aadhaarUploadedData.aadhaarBackCaptured) {
      files[1] = aadhaarUploadedData.aadhaarBackCaptured;
    } else if (aadhaarUploadedData.aadhaarBack) {
      files[1] = aadhaarUploadedData.aadhaarBack;
    }
    if (profile_slug) {
      setShowFetchingInfoModal(true);
      try {
        const res = await processAadhaarOCR({
          profile_slug,
          files: files,
          application_slug: params.applicantID,
          coc_isa_mbp_consent:
            selectedValuesIsaConsent.includes("consent-isa") &&
            selectedValuesHealthConsent.includes("consent-health"),
        });
        setAadhaarOCRDetails(res);
        setShowConfirmAadhaarModal(true);
        // snackbar.info("Aadhaar Verification Done!");
      } catch (err) {
        const e = axiosErrorHandler(err);
        if (typeof e === "object" && "message" in e) {
          if (e.message === "AADHAAR_NOT_RECOGNIZED" || e.message === "PARTIAL_INFORMATION_FOUND") {
            setInvalidAadhaarModal(true);
          } else snackbar.error(e.message);
        } else {
          snackbar.error("Something went wrong! Please try again after 2 minutes.");
        }
        console.error(err);
      }
      setShowFetchingInfoModal(false);
    }
    setLoading(false);
  };
  const handleConfirmAadhaarDetails = async () => {
    setLoading(true);

    if (profile_slug && aadhaarOCRDetails && aadhaarOCRDetails.id) {
      try {
        const res = await confirmAadhaarDetails({
          ocr_aadhaar_info_entry_id: aadhaarOCRDetails.id,
          application_slug: params.applicantID,
        });
        if (state.profileData?.aadhaar_verified && application?.docs_verified === false) {
          setIsAadhaarVerifPending(true);
        }
        setShowConfirmAadhaarModal(false);
        setIsAadhaarVerifPending(true);
        fetchApplicationData && fetchApplicationData(params.applicantID);
        // snackbar.info("Aadhaar Confirmation Done!");
      } catch (err) {
        const e = axiosErrorHandler(err);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("something went wrong");
        }
        console.error(err);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const hasFrontImage =
      aadhaarUploadedData.aadhaarFront || aadhaarUploadedData.aadhaarFrontCaptured;
    const hasBackImage = aadhaarUploadedData.aadhaarBack || aadhaarUploadedData.aadhaarBackCaptured;
    if (hasFrontImage && hasBackImage) {
      setIsAadhaarUploadedDataValid(true);
    } else {
      setIsAadhaarUploadedDataValid(false);
    }
  }, [aadhaarUploadedData]);
  // id verification form data

  const formData: {
    key: number;
    name: string;
    label: string;
    required: boolean;
    placeholder: string;
    type: "text" | "password" | "email" | "url" | "tel" | "file"; // type should be text for numbers also
    maxLegnth?: number; //
    minLegnth?: number; //
    helperText?: string;
    numberOnly?: boolean;
  }[] = [
    {
      key: 1,
      name: "adhaar",
      label: "Aadhaar Number",
      required: true,
      placeholder: "Enter 12 digit Aadhaar number",
      type: "text",
      maxLegnth: 12,
      minLegnth: 12,
      helperText: "Aadhaar number should be exactly 12 digits.",
      numberOnly: true,
    },
    {
      key: 2,
      name: "address",
      label: "Address as per Aadhaar",
      required: true,
      placeholder: "Enter your address",
      type: "text",
    },
  ];
  interface FormValues {
    [key: string]: string;
  }
  const onOTPModalClose = () => {
    setIsOTPModalOpen(false);
  };

  return (
    <>
      <Box pb={"4rem"} mb={Constants.bottomNavbarHeight}>
        {/* Current Stage */}
        <CurrentStage
          customMasaiXStagesText={[
            "Coding Test",
            "Technical Interview",
            "Aadhaar Verification",
            "Onboarding",
          ]}
          customMasaiXStagesMargin={["-24px", "-44px", "-55px", "-24px"]}
          currentStage={currentStage}
        />
        <Box px={"ms-24"}>
          <Banner
            type="success"
            text={
              "You have now successfully cleared the technical interview! Please complete your ID verification to complete the testing process for the Masai X program. "
            }
            title={"Woohoo!!🤩"}
            hideShareButton={true}
          />
        </Box>
        <Box mt={"ms-24"} px={"ms-24"}>
          <ConsentCheck
            selectedValuesIsaConsent={selectedValuesIsaConsent}
            setSelectedValuesIsaConsent={setSelectedValuesIsaConsent}
            selectedValuesHealthConsent={selectedValuesHealthConsent}
            setSelectedValuesHealthConsent={setSelectedValuesHealthConsent}
            selectedValuesScholarshipConsent={selectedValuesScholarshipConsent}
            setSelectedValuesScholarshipConsent={setSelectedValuesScholarshipConsent}
            consentDocsUrls={consentDocsUrls}
            type="masaix"
          />
        </Box>
        <Box px={"ms-24"} mt={"ms-24"}>
          <IdVerification
            isConsent={true}
            heading="ID Verification"
            aadhaarUploadedData={aadhaarUploadedData}
            setAadhaarUploadedData={setAadhaarUploadedData}
            handleConfirmAadhaarDetails={handleConfirmAadhaarDetails}
            aadhaarOCRDetails={aadhaarOCRDetails}
            showfetchingInfoModal={showfetchingInfoModal}
            setShowFetchingInfoModal={setShowFetchingInfoModal}
            showConfirmAadhaarModal={showConfirmAadhaarModal}
            setShowConfirmAadhaarModal={setShowConfirmAadhaarModal}
            invalidAadhaarModal={invalidAadhaarModal}
            setInvalidAadhaarModal={setInvalidAadhaarModal}
            formData={formData}
            onFormValuesChange={onFormValuesChange}
          />
        </Box>
      </Box>
      {/* OTP MODAL */}
      {isOTPModalOpen && (
        <OTPModal
          isOpen={isOTPModalOpen}
          onClose={() => {
            onOTPModalClose();
          }}
          primaryText="Please enter the OTP to verify your Adhaar"
          secondaryText="The OTP has been sent to your mobile number registered with Aadhaar"
          otpLength={6}
          timeGap={60}
          onComplete={(value) => {
            sendTrackingEvent({
              event: "bd_aadhaar_otp_verify",
              eventLabel: location.pathname,
            });
            handleVerifyOTP(value);
          }}
          onResendCallback={async () => {
            handleSendOTP();
          }}
          loading={loading}
        />
      )}
      <ModalTemplate
        title=""
        isOpen={isVerifSuccessModalOpen}
        onClose={() => setIsVerifSuccessModalOpen(false)}
        marginX={2}
        maxWidth="552px"
        showCloseButtonIcon={false}
        closeOnOverlayClick={false}
      >
        <Box my={"-16px"}>
          <VerificationSuccessModal
            btnText="continue to onboarding"
            onContinue={() => {
              sendTrackingEvent({
                event: "mx_avp_db_verification_done",
                eventLabel: location.pathname,
              });
              fetchApplicationData && fetchApplicationData(params.applicantID);
              snackbar.info("Moving to onboarding!");
              setIsVerifSuccessModalOpen(false);
            }}
            text={`Your Aadhaar is verified successfully. Congratulations on successfully clearing all the tests. Get onboarded now.`}
          />
        </Box>
      </ModalTemplate>
      {/* Aadhaar verification pending modal */}
      <ModalTemplate
        title=""
        isOpen={isAadhaarVerifPending}
        onClose={() => {
          //
        }}
        marginX={2}
        maxWidth="484px"
      >
        <Box textAlign={"center"}>
          <InformationOutline color="ms-alert" boxSize="56px" />
          <Heading mt={"ms-16"} as="h4">
            Verification Pending
          </Heading>
          <Text mt={"ms-8"} textStyle={"body2"}>
            You will move forward only after our team has verified your profile. Verification could
            take 24 to 48 hours. Do reach out to us at{" "}
            <Text mt={"ms-8"} textStyle={"body2-md"} d={"inline"}>
              <Link
                cursor={"pointer"}
                isExternal={true}
                href="mailto:admissions@masaischool.com"
                color="ms-blue.500"
                textDecoration={"underline"}
              >
                admissions@masaischool.com
              </Link>{" "}
            </Text>
            for any queries.
          </Text>
          <Box></Box>
        </Box>
      </ModalTemplate>
      <BottomStickyNavbar
        loading={loading}
        btnPrimaryProps={{
          size: "lg",
          onClick: () => {
            sendTrackingEvent({
              event: "bd_aadhaar_verify",
              eventLabel: location.pathname,
            });
            if (showOcrUi) {
              handleAadhaarOcr();
            } else {
              handleSendOTP();
            }
          },
          // disable Verify button if formValues/aadhaarimages are not valid OR if consent is not given
          isDisabled: showOcrUi
            ? !isAadhaarUploadedDataValid ||
              !selectedValuesIsaConsent.includes("consent-isa") ||
              !selectedValuesHealthConsent.includes("consent-health")
            : !isFormValuesValid ||
              !selectedValuesIsaConsent.includes("consent-isa") ||
              !selectedValuesHealthConsent.includes("consent-health"),
        }}
        btnPrimary="Verify"
      />
    </>
  );
};

export default MasaiXInterviewSuccess;
