import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const GPayMedium = (props: Props) => (
  <Icon viewBox="0 0 34 24" {...props}>
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H42C44.2091 0 46 1.79086 46 4V28C46 30.2091 44.2091 32 42 32H4C1.79086 32 0 30.2091 0 28V4Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.4104 20.704V16.7911H23.4299C24.2575 16.7911 24.956 16.5138 25.5254 15.9669L25.662 15.8283C26.7021 14.696 26.6338 12.9322 25.5254 11.8846C24.9711 11.33 24.2119 11.0297 23.4299 11.0451H20.1881V20.704H21.4104ZM21.4106 15.6049V12.2312H23.4608C23.9012 12.2312 24.3188 12.4006 24.6301 12.7087C25.2908 13.3557 25.306 14.4341 24.6681 15.1042C24.3568 15.4354 23.9164 15.6203 23.4608 15.6049H21.4106ZM31.3637 14.6113C30.8399 14.126 30.1262 13.8795 29.2228 13.8795C28.0612 13.8795 27.1881 14.3109 26.6111 15.1659L27.6892 15.8514C28.0839 15.266 28.623 14.9733 29.3063 14.9733C29.739 14.9733 30.1566 15.135 30.4831 15.4277C30.8019 15.705 30.9841 16.1056 30.9841 16.5292V16.8142C30.5134 16.5523 29.9212 16.4137 29.1924 16.4137C28.3421 16.4137 27.6588 16.6139 27.1501 17.0222C26.6414 17.4304 26.3833 17.9696 26.3833 18.6551C26.3681 19.279 26.6339 19.8721 27.1046 20.2726C27.5829 20.704 28.1902 20.9196 28.9039 20.9196C29.7466 20.9196 30.4147 20.5422 30.9234 19.7874H30.9765V20.704H32.1457V16.6293C32.1457 15.7744 31.8876 15.0965 31.3637 14.6113ZM28.0462 19.487C27.7956 19.3021 27.6437 19.0017 27.6437 18.6782C27.6437 18.3162 27.8108 18.0158 28.1373 17.777C28.4714 17.5382 28.889 17.415 29.3826 17.415C30.066 17.4073 30.5975 17.5614 30.9772 17.8695C30.9772 18.3932 30.7722 18.8477 30.3697 19.2328C30.0053 19.6025 29.5117 19.8105 28.9954 19.8105C28.6537 19.8182 28.3195 19.7027 28.0462 19.487ZM34.7726 23.6078L38.8572 14.0952H37.5286L35.6381 18.8399H35.6153L33.6794 14.0952H32.3507L35.0308 20.2803L33.5123 23.6078H34.7726Z"
      fill="#3C4043"
    />
    <path
      d="M17.1905 15.9439C17.1905 15.5664 17.1602 15.189 17.0994 14.8193H11.9444V16.9529H14.8977C14.7763 17.6384 14.3815 18.2546 13.8045 18.6397V20.0262H15.5658C16.5984 19.0634 17.1905 17.6384 17.1905 15.9439Z"
      fill="#4285F4"
    />
    <path
      d="M11.9446 21.3664C13.4175 21.3664 14.6626 20.8735 15.566 20.0262L13.8047 18.6397C13.3112 18.9787 12.681 19.1712 11.9446 19.1712C10.5173 19.1712 9.31013 18.193 8.87738 16.8836H7.06287V18.3162C7.9891 20.188 9.87954 21.3664 11.9446 21.3664Z"
      fill="#34A853"
    />
    <path
      d="M8.87759 16.8836C8.64979 16.1981 8.64979 15.4509 8.87759 14.7577V13.3327H7.06278C6.28067 14.8963 6.28067 16.7449 7.06278 18.3086L8.87759 16.8836Z"
      fill="#FBBC04"
    />
    <path
      d="M11.9446 12.47C12.7266 12.4546 13.4782 12.755 14.04 13.3019L15.604 11.7152C14.6094 10.7755 13.3036 10.2594 11.9446 10.2748C9.87954 10.2748 7.9891 11.461 7.06287 13.3327L8.87738 14.7654C9.31013 13.4483 10.5173 12.47 11.9446 12.47Z"
      fill="#EA4335"
    />
    <path
      d="M4 1H42V-1H4V1ZM45 4V28H47V4H45ZM42 31H4V33H42V31ZM1 28V4H-1V28H1ZM4 31C2.34315 31 1 29.6569 1 28H-1C-1 30.7614 1.23858 33 4 33V31ZM45 28C45 29.6569 43.6569 31 42 31V33C44.7614 33 47 30.7614 47 28H45ZM42 1C43.6569 1 45 2.34315 45 4H47C47 1.23858 44.7614 -1 42 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F3F2F2"
    />
  </Icon>
);

export default GPayMedium;
