import React from "react";
import { Button, Skeleton } from "@chakra-ui/react";
import { ModalTemplateV2 } from "../../common/general";
import { useEvent } from "../../../api/utils/hooks/useEvents";
import ActivityCardV2 from "./ActivityCardV2";

const ActivityCardContainerV2 = ({
  eventType,
  eventId,
  isDashboard,
  onRegister,
  maxWidth,
}: {
  maxWidth?: string;
  eventType?: string;
  eventId?: number;
  isDashboard?: boolean;
  onRegister?: () => void;
}) => {
  const { data: event, isLoading, isSuccess, refetch } = useEvent(eventId, eventType);
  const [openFounderWebinarSuccess, setOpenFounderWebinarSuccess] = React.useState(false);
  return isSuccess && event ? (
    <>
      <ActivityCardV2
        onRegister={() => {
          setOpenFounderWebinarSuccess(true);

          onRegister && onRegister();
        }}
        maxWidth={{ base: "100%", md: maxWidth || "360px" }}
        isDashboard={isDashboard}
        event={event}
      />
      <ModalTemplateV2
        closeOnOverlayClick={true}
        isOpen={openFounderWebinarSuccess}
        onClose={() => {
          refetch();
          // refetchFounderWebinar();
          setOpenFounderWebinarSuccess(false);
        }}
        autoCloseInSeconds={5}
        text="Registered Successfully!"
        extra={
          <>
            <Button
              onClick={() => {
                refetch();
                // refetchFounderWebinar();
                setOpenFounderWebinarSuccess(false);
              }}
              mt="28px"
              variant="secondary"
            >
              Close
            </Button>
          </>
        }
      />
    </>
  ) : isLoading ? (
    <Skeleton h="386px" />
  ) : (
    <></>
  );
};

export default ActivityCardContainerV2;
