import {
  Box,
  Heading,
  Link,
  SimpleGrid,
  Skeleton,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import phone from "phone";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  applyForScholarship,
  generateRazorpayOrderId,
  razorpaySuccessReport,
} from "../../../../api/utils/api";
import { sendOtpToUser, updateProfile, verifyOTPUser } from "../../../../api/utils/api/profile";
import { axiosErrorHandler } from "../../../../api/utils/error";
import OTPModal from "../../../../components/common/auth/OTPModal";

import {
  BottomStickyNavbar,
  CheckboxGroup,
  InputCustom,
  ModalTemplate,
} from "../../../../components/common/general";
import useSnackBar from "../../../../components/common/general/SnackBar";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import { AppContext, ApplicationActionType } from "../../../../context/ApplicationContext";
import useApplicationHook from "../../../../hooks/useApplicationHook";

import ApplyForFellowship from "./ScholarInstructions/ApplyForFellowship";
import ScholarPaymentPlanModal from "./ScholarPaymentPlanModal";

interface Props {
  handlePreviousPage: () => void;
  mobile: string;
  setMobile: (mobile: string) => void;
}

const ScholarProgramPaymentPage = ({ mobile, setMobile, handlePreviousPage }: Props) => {
  const [isOTPModalOpen, setOTPModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const snackbar = useSnackBar();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [scholarPaymentPlanModalOpen, setScholarPaymentPlanModalOpen] = useState<boolean>(false);
  const [selectScholarshipOptionModal, setSelectScholarshipOptionModal] = useState<boolean>(false);
  const [paymentsTermsAccepted, setPaymentsTermsAccepted] = React.useState<(string | number)[]>([
    "payment-terms",
  ]);
  const [state, dispatch] = useContext(AppContext);
  const { applicationData } = state;
  const location = useLocation();

  useEffect(() => {
    if (state.profileData?.phone_number) {
      setMobile(phone(state.profileData?.phone_number)[0]);
    }
  }, [state.profileData?.phone_number]);
  const scholarResultPresent =
    applicationData?.scholar_result !== null && applicationData?.scholar_result !== undefined;
  const courseLevel = applicationData?.batch_campus?.batch?.course?.level;
  const coursePrice = state.applicationData?.batch_campus.batch.course_price || 0;
  const discount_percentage = state.applicationData?.discount_percentage || 0;
  const sendOtp = async (otp_verified?: boolean) => {
    if (!state.profileData?.slug) return;
    try {
      if (!otp_verified) {
        setOTPModalOpen(true);
        await sendOtpToUser({ profile_slug: state.profileData?.slug });
        snackbar.info("OTP Sent!");
      } else {
        // startRazorpayPayment();
        setScholarPaymentPlanModalOpen(true);
      }
    } catch (err) {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
      return;
    }
  };
  const handleVerifyOTP = async (otp: string) => {
    if (!state.profileData?.slug) return;
    setLoading(true);
    try {
      const res = await verifyOTPUser({
        profile_slug: state.profileData?.slug,
        otp_input: Number(otp),
      });
      snackbar.success("Successfully verified!");
      setOTPModalOpen(false);
      setScholarPaymentPlanModalOpen(true);
      dispatch({
        type: ApplicationActionType.FETCH_APPLICATION,
        payload: {},
      });
      dispatch({
        type: ApplicationActionType.FETCH_PROFILE,
        payload: {},
      });
    } catch (err) {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    }

    setLoading(false);
  };
  // handle razorpay modal flow
  const handleContinueToPayment = async () => {
    if (!state.profileData?.slug) return;
    try {
      const response = await updateProfile(state.profileData?.slug, { phone_number: mobile });
      await sendOtp(response.otp_verified);
    } catch (err) {
      setLoading(false);
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    }
  };
  const startRazorpayPayment = async (paymentType: "full-payment" | "subscription-payment") => {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      snackbar.error("Payment Gateway failed to load. Please check internet connection!");
      return;
    }
    const courseName = state.applicationData?.batch_campus.batch.course.name;
    const appSlug = state.applicationData?.slug;
    if (!appSlug) return;
    try {
      setLoading(true);
      const res = await generateRazorpayOrderId({
        application_slug: appSlug,
        paymentType,
        payment_consent: paymentsTermsAccepted.length > 0,
        source : "ONWARD_SCHOLAR_PAYMENT_DETAILS_PAGE"
      });
      setLoading(false);
      snackbar.success("Starting payment process!");
      const options = {
        key: process.env.REACT_APP_RAZORPAY_CLIENT_ID || "",
        order_id: res?.order_id,
        // order_id: paymentType === "full-payment" ? res?.order_id : undefined,
        // subscription_id: paymentType === "subscription-payment" ? res?.subscription_id : undefined,
        name: `${courseName}`,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        handler: (response: any) => {
          console.log("Razorpay response: ", response);
          if (
            response?.razorpay_payment_id &&
            (response?.razorpay_order_id || response?.razorpay_subscription_id) &&
            response?.razorpay_signature
          ) {
            setLoading(true);
            // send request to backend to verify payment
            razorpaySuccessReport({
              profile_slug: state.profileData?.slug,
              application_slug: state.applicationData?.slug,
              razorpay_payment_id: response?.razorpay_payment_id,
              razorpay_order_id: response?.razorpay_order_id,
              razorpay_subscription_id: response?.razorpay_subscription_id,
              razorpay_signature: response?.razorpay_signature,
            })
              .then((res) => {
                setLoading(false);
                snackbar.success("Payment Successful!");
                history.push(
                  `/batch/${state.applicationData?.batch_campus.batch_id}/${appSlug}/payment-success`
                );
              })
              .catch((err) => {
                setLoading(false);
                const e = axiosErrorHandler(err);
                if (typeof e === "object" && "message" in e) {
                  snackbar.error(e.message);
                } else {
                  snackbar.error("something went wrong");
                }
                history.push(
                  `/batch/${state.applicationData?.batch_campus.batch_id}/${appSlug}/instructions`
                );
              });
          } else {
            // move to payment failed page
            history.push(
              `/batch/${state.applicationData?.batch_campus.batch_id}/${appSlug}/instructions`
            );
          }
        },
        ondismiss: () => {
          console.log("razorpay payment cancelled");
        },
      };
      if (window && window.Razorpay) {
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }
    } catch (err) {
      setLoading(false);
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    }
  };
  // display razorpay modal once order id is available
  const loadScript = (src: string) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const headingSize = useBreakpointValue<"h3" | "h4">({
    base: "h3",
    md: "h4",
  });
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (scholarResultPresent || courseLevel == 2) {
      setSelectScholarshipOptionModal(false);
    } else {
      setSelectScholarshipOptionModal(true);
    }
  }, [scholarResultPresent]);

  const params = useParams() as { applicantID: string };
  const { fetchApplicationData } = useApplicationHook()[1];
  const handleConfirm = async () => {
    if (!applicationData?.slug) return;
    try {
      setLoading(true);
      await applyForScholarship({ application_slug: applicationData?.slug });
    } catch (err) {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    } finally {
      setLoading(false);
      fetchApplicationData && fetchApplicationData(params.applicantID);
    }
  };
  return (
    <>
      <Skeleton isLoaded={!loading}>
        <Box p={{ base: "ms-16", md: "ms-24" }} pt={"ms-24"}>
          {scholarResultPresent || courseLevel == 2 ? null : (
            <Box mb={"ms-24"}>
              <ApplyForFellowship handleConfirm={handleConfirm} />
            </Box>
          )}
          <Heading as={headingSize}>Payment Details</Heading>
          <SimpleGrid
            mt={{ base: "ms-16", md: "ms-24" }}
            mb={{ base: "ms-16", md: "ms-40" }}
            columns={[1, 1, 1, 2]}
            spacingY={{ base: "ms-12", md: "ms-24" }}
            spacingX="ms-24"
          >
            <InputCustom
              placeholder="Enter full name"
              label="Full name"
              type="text"
              preFilledValue={state.profileData?.name}
              getValueCallback={(value) => {
                // setName(value);
                // nameValidator.validate(value) ? setIsNameValid(true) : setIsNameValid(false);
              }}
              disabled={true}
              onClick={() => {
                //
              }}
              //   preFilledValue={name}
              //   errorMessage={name ? "Name shouldn't contain special characters" : "Please add your name"}
              //   isInvalid={isNameValid === false}
            />
            <InputCustom
              placeholder="Enter email"
              label="Email"
              type="email"
              getValueCallback={(value) => {
                //
              }}
              onClick={() => {
                //
              }}
              disabled={true}
              preFilledValue={state.profileData?.email}
              // preFilledValue={email}
            />

            <InputCustom
              label="Course"
              type="text"
              preFilledValue={state.applicationData?.batch_campus.batch.course.name}
              getValueCallback={(value) => {
                //
              }}
              disabled={true}
              onClick={() => {
                //
              }}
              // preFilledValue={email}
            />
            <InputCustom
              label="Amount"
              type="text"
              disabled={true}
              getValueCallback={(value) => {
                // setName(value);
                // nameValidator.validate(value) ? setIsNameValid(true) : setIsNameValid(false);
              }}
              preFilledValue={`₹ ${Math.floor(
                coursePrice - (coursePrice * discount_percentage) / 100
              )}`}
              onClick={() => {
                //
              }}
              //   preFilledValue={name}
              //   errorMessage={name ? "Name shouldn't contain special characters" : "Please add your name"}
              //   isInvalid={isNameValid === false}
            />
            <InputCustom
              disabled={state.profileData?.otp_verified ? true : false}
              label="Phone *"
              type="tel"
              placeholder="Enter mobile number"
              leadingText="+91"
              preFilledValue={mobile ? phone(mobile, "IND")[0].split("+91")[1] : undefined}
              maxlength={10}
              getValueCallback={(value) => {
                const number = phone(value, "IND")[0];
                if (number) {
                  setMobile(phone(value, "IND")[0]);
                } else {
                  setMobile("");
                }
              }}
              //   disabled={profileData?.phone_number ? true : false}
              onClick={() => {
                //
              }}
              errorMessage={
                mobile ? "Mobile number should be 10 digits" : "Please add your mobile number."
              }
              isInvalid={mobile === ""}
            />
          </SimpleGrid>
          <Box d={"flex"} mt={"ms-8"}>
            <CheckboxGroup
              options={[{ label: "", value: "payment-terms" }]}
              handleChange={(values) => {
                //TODO:GTM event
                setPaymentsTermsAccepted([...values]);
              }}
              selectedValues={paymentsTermsAccepted}
              spacingY="ms-16"
              spacingX="ms-8"
              justify="start"
            />
            <Text
              textStyle={{ base: "body2", md: "body1" }}
              color={"ms-grey.900"}
              ml={"ms-8"}
              mt={"-4px"}
            >
              I hereby agree to the{" "}
              <Link href="https://masaischool.com/terms/" isExternal>
                <Text
                  textStyle={{ base: "body2-md", md: "body1-md" }}
                  color={"ms-blue.500"}
                  display={"inline"}
                  onClick={() => {
                    sendTrackingEvent({
                      event: "sp_pp_view_t&c",
                      eventLabel: location.pathname,
                    });
                  }}
                >
                  Terms & Conditions
                </Text>{" "}
              </Link>
              and{" "}
              <Link href="https://masaischool.com/privacy/" isExternal>
                <Text
                  textStyle={{ base: "body2-md", md: "body1-md" }}
                  color={"ms-blue.500"}
                  display={"inline"}
                  onClick={() => {
                    sendTrackingEvent({
                      event: "sp_pp_privacy_policy",
                      eventLabel: location.pathname,
                    });
                  }}
                >
                  Privacy Policy of Masai.
                </Text>
              </Link>
            </Text>
          </Box>
        </Box>
      </Skeleton>
      {/* Scholar Payment Plan Modal */}
      <ModalTemplate
        title=""
        isOpen={scholarPaymentPlanModalOpen}
        onClose={() => {
          sendTrackingEvent({
            event: "sp_pp_db_x",
            eventLabel: location.pathname,
          });
          setScholarPaymentPlanModalOpen(false);
        }}
        showCloseButtonIcon={true}
        marginX={2}
        maxWidth="600px"
      >
        <ScholarPaymentPlanModal
          setScholarPaymentPlanModalOpen={setScholarPaymentPlanModalOpen}
          startRazorpayPayment={startRazorpayPayment}
        />
      </ModalTemplate>
      {/* Select with scholarship or without scholarship option modal */}
      {/* <ModalTemplate
        title=""
        isOpen={selectScholarshipOptionModal}
        onClose={() => {
          sendTrackingEvent({
            event: "sp_pp_db_category_selection_continue",
            eventLabel: location.pathname,
          });
          setSelectScholarshipOptionModal(false);
        }}
        showCloseButtonIcon={false}
        marginX={2}
        maxWidth="484px"
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <ScholarshipOptionModal
          // setSelectScholarshipOptionModal={setSelectScholarshipOptionModal}
          handleConfirm={handleConfirm}
          coursePrice={coursePrice}
        />
      </ModalTemplate> */}
      <BottomStickyNavbar
        // loading={loading}
        btnSecondary={"PREVIOUS"}
        btnSecondaryProps={{
          size: isMobile ? "sm" : "lg",
          onClick: () => {
            sendTrackingEvent({
              event: "sp_pp_previous",
              eventLabel: location.pathname,
            });
            handlePreviousPage();
          },
        }}
        btnPrimary={isMobile ? "continue" : "continue to payment"}
        btnPrimaryProps={{
          onClick: () => {
            sendTrackingEvent({
              event: "sp_pp_continue_to_payment",
              eventLabel: location.pathname,
            });
            handleContinueToPayment();
          },
          size: isMobile ? "sm" : "lg",
          isDisabled: !mobile || paymentsTermsAccepted.length === 0,
        }}
      ></BottomStickyNavbar>
      {isOTPModalOpen && (
        <OTPModal
          isOpen
          loading={loading}
          onClose={() => {
            setOTPModalOpen(false);
          }}
          primaryText="Please enter the OTP to verify your number"
          secondaryText={`A OTP has been sent to ${mobile}`}
          timeGap={60}
          otpLength={6}
          onComplete={(value) => {
            handleVerifyOTP(value);
          }}
          onResendCallback={async () => {
            sendOtp();
          }}
        />
      )}
    </>
  );
};

export default ScholarProgramPaymentPage;
