import { Box } from "@chakra-ui/react";
import React from "react";
import CodingFailed from "../CodingFailed";
import { MasaiXStageType } from "./MasaiXCodingInstructions";
interface Props {
  currentStage: MasaiXStageType;
}
const MasaiXInterviewFailed = ({ currentStage }: Props) => {
  return (
    <Box>
      {/* reusing same component */}
      <CodingFailed
        text="Better luck next time"
        title="You could not clear the interview round and hence you’re not eligible for this course in this batch, check out our other courses that you can apply for.    "
      />
    </Box>
  );
};

export default MasaiXInterviewFailed;
