import { Box, Button, Image, Text } from "@chakra-ui/react";

const StillHaveQuestions = () => {
  return (
    <Box>
      <Box maxW={{ md: "330px" }} rounded="8px" bg="white" border="1px solid #E5E5E5" py="24px" px="16px">
        <Image mx="auto" w="105px" h="126.181px" src="/img/revamp/still-have-questions.svg" alt="still-have-questions" />

        <Text mt="16px" textStyle="h5">
          Still have questions?
        </Text>
        <Text mt="8px" fontSize="12px" fontWeight="500" fontFamily="Inter" color="ms-grey.600">
          Our career advisors are here to answer your questions. Get honest and impartial advice on whether the IIT course is right for you.
        </Text>

        <Button
          mt="16px"
          variant="outline"
          colorScheme="blue"
          size="sm"
          onClick={() => {
            window.open("https://calendly.com/priya-66/one-on-one-counselling-for-iit-programs?preview_source=et_card&month=2024-07", "_blank");
          }}
        >
          Get in Touch
        </Button>
      </Box>
    </Box>
  );
};

export default StillHaveQuestions;
