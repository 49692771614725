import React, { useEffect } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  FormLabel,
  VStack,
  Box,
} from "@chakra-ui/react";

import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

interface Props {
  options: string[];
  customLabels?: { key: string; label: string }[];
  placeholder: string;
  label?: string | React.ReactElement;
  getValueCallback: (value: string) => void;
  errorMessage?: string;
  isInvalid?: boolean;
  disabled?: boolean;
  value?: string;
  height?: string;
  id?: string;
  flip?: boolean;
}

const Dropdown = ({
  label,
  options,
  placeholder,
  getValueCallback,
  errorMessage,
  isInvalid,
  disabled,
  value,
  height,
  customLabels,
  id,
  flip = false,
}: Props) => {
  const [option, setOption] = React.useState<string>();

  const onClickHandler = (value: string): void => {
    setOption(value);
    getValueCallback(value);
  };

  useEffect(() => {
    if (!value) {
      setOption(undefined);
    }
    if (value) {
      const val = options.find((item) => item === value);
      console.log(val);
      val && setOption(val);
    }
  }, [value]);

  return (
    <VStack align="flex-start">
      {label && (
        <FormLabel mb={"-0.1px"}>
          <Text textStyle="body2-md" color="ms-red.900">
            {label}
          </Text>
        </FormLabel>
      )}
      <Box w="full">
        <Menu gutter={8} matchWidth={true} autoSelect={false} flip={flip}>
          {({ isOpen }) => (
            <>
              <MenuButton
                w="full"
                h={height || "48px"}
                isActive={isOpen}
                _disabled={{ color: "ms-grey.500", bgColor: "ms-grey.50" }}
                disabled={disabled}
                as={Button}
                textAlign="left"
                bgColor="white"
                borderRadius="ms-8"
                border="1px solid"
                borderColor={isInvalid ? "ms-error" : "#CCCCCC"}
                _focus={{}}
                _hover={{}}
                _expanded={{ bg: "white" }}
                rightIcon={
                  isOpen ? (
                    <ChevronUpIcon boxSize="20px" color="ms-red.900" />
                  ) : (
                    <ChevronDownIcon boxSize="20px" color="ms-red.900" />
                  )
                }
              >
                <Text
                  textStyle="body1"
                  color={!option ? "ms-grey.400" : "ms-red.900"}
                  textTransform="none"
                >
                  {!option
                    ? placeholder
                    : customLabels
                    ? customLabels.filter((item) => item?.key === option)[0]?.label
                    : option}
                </Text>
              </MenuButton>
              <MenuList
                minWidth="164px"
                borderRadius="ms-8"
                overflow="scroll"
                maxHeight="245px"
                zIndex="2"
              >
                {options.length &&
                  options.map((value, idx) => (
                    <MenuItem
                      key={value}
                      _hover={{ bgColor: "ms-blue.50", boxShadow: "none" }}
                      onClick={() => onClickHandler(value)}
                      justifyContent="space-between"
                      closeOnSelect
                      textStyle={value === option ? "body1-md" : "body1"}
                      color={value === option ? "ms-blue.500" : "ms-red.900"}
                      boxShadow={options.length - 1 !== idx ? "0px 1px 0px #D9D9D9" : "none"}
                      h={height || "48px"}
                    >
                      {customLabels
                        ? customLabels.filter((item) => item?.key === value)[0]?.label
                        : value}
                    </MenuItem>
                  ))}
              </MenuList>
            </>
          )}
        </Menu>
      </Box>
      {isInvalid && (
        <Text color="ms-error" textStyle="body2">
          {errorMessage}
        </Text>
      )}
    </VStack>
  );
};

export default Dropdown;
