import { useContext } from "react";
import { capBookADemoOneTimeLink, capCreateADemoSession } from "../api/utils/api";
import {
  createCounsellingSession,
  createMasaiXInterviewEntry,
  createOnboardingSession,
  createOneTimeLinkCounsellingSession,
  createOneTimeLinkMasaiXInterviewSession,
  createOneTimeLinkOnbaordingSession,
} from "../api/utils/api/onboarding";
import { AppContext } from "../context/ApplicationContext";

export const useCalendly = () => {
  const state = useContext(AppContext)[0];

  const generateOTLOnboarding = async (): Promise<string | undefined> => {
    try {
      const application_slug = state.applicationData?.slug;
      if (!application_slug) throw new Error("No application_slug found");

      if (state.applicationData?.onboarding_session_one_time_link) {
        console.log(`found`);
        return state.applicationData.onboarding_session_one_time_link;
      }

      const res = await createOneTimeLinkOnbaordingSession({
        application_slug,
      });
      return res.onboarding_session_one_time_link;
    } catch (err) {
      console.log(err);
    }
  };

  const generateOTLCounselling = async (app_slug?: string) => {
    try {
      const application_slug = state.applicationData?.slug || app_slug;
      if (!application_slug) throw new Error("No application_slug found");

      if (state.applicationData?.counselling_session_one_time_link) {
        return state.applicationData.counselling_session_one_time_link;
      }

      const res = await createOneTimeLinkCounsellingSession({
        application_slug,
      });

      return res.counselling_session_one_time_link;
    } catch (err) {
      console.log(err);
    }
  };
  const generateOTLMasaiXInterview = async (app_slug?: string) => {
    try {
      const application_slug = state.applicationData?.slug || app_slug;
      if (!application_slug) throw new Error("No application_slug found");

      if (state.applicationData?.interview_booking_one_time_link) {
        return state.applicationData.interview_booking_one_time_link;
      }

      const res = await createOneTimeLinkMasaiXInterviewSession({
        application_slug,
      });

      return res.interview_booking_one_time_link;
    } catch (err) {
      console.log(err);
    }
  };

  const createCounsellingInterview = async (uri: string, appSlug?: string) => {
    try {
      const application_slug = state.applicationData?.slug || appSlug;
      if (!application_slug) throw new Error("No application_slug found");
      const res = await createCounsellingSession({
        application_slug,
        calendly_event_uri: uri,
      });
      if (res.counselling_sessions) {
        return res.counselling_sessions[0];
      }
    } catch (err) {
      console.log(err);
    }
  };
  const createMasaiXInterview = async (uri: string, appSlug?: string) => {
    try {
      const application_slug = state.applicationData?.slug || appSlug;
      if (!application_slug) throw new Error("No application_slug found");
      const res = await createMasaiXInterviewEntry({
        application_slug,
        calendly_event_uri: uri,
      });
      if (res.interview_booking) {
        return res.interview_booking[0];
      }
    } catch (err) {
      console.log(err);
    }
  };

  const createOnboardingInterview = async (uri: string) => {
    try {
      const application_slug = state.applicationData?.slug;
      if (!application_slug) throw new Error("No application_slug found");
      const res = await createOnboardingSession({
        application_slug,
        calendly_event_uri: uri,
      });
      if (res.onboarding_sessions) {
        return res.onboarding_sessions[0];
      }
    } catch (err) {
      console.log(err);
    }
  };

  const generateOTLCAPBookADemo = async (data: {
    name: string;
    email: string;
    graduation_year: string;
    college_id: number;
    in_college: boolean;
    phone_number: string;
    source?: string
  }) => {
    const { name, phone_number, email, in_college, college_id, graduation_year, source } = data;
    try {
      const response = await capBookADemoOneTimeLink({
        name,
        phone_number,
        email_address: email,
        in_college: in_college,
        college_id: college_id,
        graduation_year,
        source
      });
      if (response?.one_time_link) {
        return response;
      }
    } catch (err) {
      throw new Error("Something went wrong");
    }
  };

  const createCAPDemoSession = async (uri: string, phone_number: string, location: string) => {
    try {
      const response = await capCreateADemoSession({
        calendly_event_uri: uri,
        phone_number: phone_number,
        fe_route: location,
      });
      if (response?.join_url) {
        return response;
      }
    } catch (err) {
      throw new Error("Something went wrong");
    }
  };

  return {
    generateOTLOnboarding,
    generateOTLCounselling,
    createOnboardingInterview,
    createCounsellingInterview,
    generateOTLMasaiXInterview,
    createMasaiXInterview,
    generateOTLCAPBookADemo,
    createCAPDemoSession,
  };
};
