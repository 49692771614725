import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const MasterCardLarge = (props: Props) => (
  <Icon viewBox="0 0 34 24" {...props}>
    <path
      d="M0 6C0 2.68629 2.68629 0 6 0H52C55.3137 0 58 2.68629 58 6V34C58 37.3137 55.3137 40 52 40H6C2.68629 40 0 37.3137 0 34V6Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.6316 28.049C26.6582 29.7125 24.0984 30.7166 21.3011 30.7166C15.0597 30.7166 10 25.7171 10 19.55C10 13.3828 15.0597 8.38329 21.3011 8.38329C24.0984 8.38329 26.6582 9.38745 28.6316 11.0509C30.605 9.38745 33.1649 8.38329 35.9621 8.38329C42.2036 8.38329 47.2632 13.3828 47.2632 19.55C47.2632 25.7171 42.2036 30.7166 35.9621 30.7166C33.1649 30.7166 30.605 29.7125 28.6316 28.049Z"
      fill="#ED0006"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.6316 28.049C31.0615 26.0009 32.6023 22.9532 32.6023 19.55C32.6023 16.1467 31.0615 13.099 28.6316 11.0509C30.605 9.38745 33.1649 8.38329 35.9621 8.38329C42.2035 8.38329 47.2632 13.3828 47.2632 19.55C47.2632 25.7171 42.2035 30.7166 35.9621 30.7166C33.1649 30.7166 30.605 29.7125 28.6316 28.049Z"
      fill="#F9A000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.6316 28.049C31.0615 26.0009 32.6023 22.9532 32.6023 19.55C32.6023 16.1467 31.0615 13.0991 28.6316 11.0509C26.2018 13.0991 24.661 16.1467 24.661 19.55C24.661 22.9532 26.2018 26.0009 28.6316 28.049Z"
      fill="#FF5E00"
    />
    <path
      d="M6 1H52V-1H6V1ZM57 6V34H59V6H57ZM52 39H6V41H52V39ZM1 34V6H-1V34H1ZM6 39C3.23858 39 1 36.7614 1 34H-1C-1 37.866 2.13401 41 6 41V39ZM57 34C57 36.7614 54.7614 39 52 39V41C55.866 41 59 37.866 59 34H57ZM52 1C54.7614 1 57 3.23858 57 6H59C59 2.13401 55.866 -1 52 -1V1ZM6 -1C2.13401 -1 -1 2.13401 -1 6H1C1 3.23858 3.23858 1 6 1V-1Z"
      fill="#F3F2F2"
    />
  </Icon>
);

export default MasterCardLarge;
