import { Box, Flex, Image, Text, Link } from "@chakra-ui/react";
import { useState } from "react";
import { GreenCheckIcon } from "../../../assets/images";
import { IITGuwahatiProfileDetailInputInterface } from "../../../constants/IIt-course/iit-form-onboarding";
import CustomUploadFileInput from "../IITGOnboarding/CustomUploadFileInput";
import { GetS3PresignedUrlType } from "../../../api/schemas/schema";
import axios from "axios";

export type NewAadhaarUploadedDataType = {
  aadhaarFront?: string | File;
  aadhaarBack?: string | File;
  aadhaarFrontCaptured?: string;
  aadhaarBackCaptured?: string;
};

interface Props {
  parentFlexDir?: string;
  profileDetailForm?: IITGuwahatiProfileDetailInputInterface;
  setProfileDetailForm?: (data: IITGuwahatiProfileDetailInputInterface) => void;
  aadhaarUploadedData?: NewAadhaarUploadedDataType;
  setAadhaarUploadedData?: (data: NewAadhaarUploadedDataType) => void;
}
const AadhaarOcr = ({ parentFlexDir, profileDetailForm, setProfileDetailForm, aadhaarUploadedData, setAadhaarUploadedData }: Props) => {
  // aadhaar image states we get from drag and drop or upload
  const [aadhaarBack, setAadhaarBack] = useState<File>();
  const [aadhaarFront, setAadhaarFront] = useState<File>();

  const handleOnSuccess = async (presignedUrl: string, imageFile: File): Promise<string> => {
    const imageData = imageFile;
    const fileType = imageData as File;
    try {
      await axios.put(presignedUrl, imageData, {
        headers: {
          "Content-Type": fileType.type || "image/png",
        },
      });
      const imageUrl = presignedUrl.split("?")[0];
      return imageUrl;
    } catch (error) {
      console.error("Error uploading image:", error);
      return "";
    }
  };

  return (
    <>
      <Box display={"flex"} flexDir={parentFlexDir ? { base: "column", md: "row" } : { base: "column" }} gap={{ base: "14px", md: "64px" }}>
        {/* Upload front Aadhaar */}
        <Box>
          {/* Header Text */}
          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Box display={"flex"} alignItems={"center"}>
              <Text display={"inline"} mr={"ms-4"} mb={"ms-8"} textStyle={"body2-md"}>
                Upload the front side of your Aadhaar *{aadhaarFront && <Image ml={"ms-8"} d={{ base: "inline", md: "none" }} src={GreenCheckIcon} alt="uploaded" />}
              </Text>
              {aadhaarFront && <Image display={{ base: "none", md: "block" }} mb={"ms-8"} src={GreenCheckIcon} alt="uploaded" />}
            </Box>
            {profileDetailForm && profileDetailForm?.aadharFront && (
              <Text
                display={"inline"}
                textStyle={"body2"}
                color={"ms-blue.500"}
                cursor={"pointer"}
                _hover={{ textDecoration: "underline" }}
                onClick={() => {
                  window.open(profileDetailForm?.aadharFront, "_blank");
                }}
              >
                Preview
              </Text>
            )}
          </Box>

          {/* Drag and drop Flow */}
          <CustomUploadFileInput
            disabled={false}
            toastMessage="Aadhaar Front Uploaded Successfully"
            fileUploadData={aadhaarFront as File}
            setFileUploadData={setAadhaarFront}
            handleDelete={() => {
              if (setProfileDetailForm && profileDetailForm) {
                setProfileDetailForm({ ...profileDetailForm, aadharFront: undefined });
              } else if (aadhaarUploadedData && setAadhaarUploadedData) {
                setAadhaarUploadedData({ ...aadhaarUploadedData, aadhaarFront: undefined });
              }
            }}
            handleFileUpload={async (presignedUrls: GetS3PresignedUrlType) => {
              const imageUrl = await handleOnSuccess(presignedUrls.presigned_url_for_aadhaar_front, aadhaarFront as File);
              if (setProfileDetailForm && profileDetailForm) {
                setProfileDetailForm({ ...profileDetailForm, aadharFront: imageUrl });
              } else if (aadhaarUploadedData && setAadhaarUploadedData) {
                setAadhaarUploadedData({ ...aadhaarUploadedData, aadhaarFront: imageUrl });
              }
            }}
          />
        </Box>
        {/* Upload back Aadhaar */}
        <Box mt={parentFlexDir ? "" : "ms-16"}>
          {/* Header Text */}
          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Box display={"flex"} alignItems={"center"}>
              <Text mr={"ms-4"} mb={"ms-8"} textStyle={"body2-md"}>
                Upload the back side of your Aadhaar *{aadhaarBack && <Image ml={"ms-8"} d={{ base: "inline", md: "none" }} src={GreenCheckIcon} alt="uploaded" />}
              </Text>
              {aadhaarBack && <Image d={{ base: "none", md: "block" }} mb={"ms-8"} src={GreenCheckIcon} alt="uploaded" />}
            </Box>
            {profileDetailForm && profileDetailForm?.aadharBack && (
              <Text
                display={"inline"}
                textStyle={"body2"}
                color={"ms-blue.500"}
                cursor={"pointer"}
                _hover={{ textDecoration: "underline" }}
                onClick={() => {
                  window.open(profileDetailForm?.aadharBack, "_blank");
                }}
              >
                Preview
              </Text>
            )}
          </Box>
          {/* Drag and drop Flow */}
          <CustomUploadFileInput
            disabled={false}
            toastMessage="Aadhaar Back Uploaded Successfully"
            fileUploadData={aadhaarBack as File}
            setFileUploadData={setAadhaarBack}
            handleDelete={() => {
              if (setProfileDetailForm && profileDetailForm) {
                setProfileDetailForm({ ...profileDetailForm, aadharBack: undefined });
              } else if (aadhaarUploadedData && setAadhaarUploadedData) {
                setAadhaarUploadedData({ ...aadhaarUploadedData, aadhaarBack: undefined });
              }
            }}
            handleFileUpload={async (presignedUrls: GetS3PresignedUrlType) => {
              const imageUrl = await handleOnSuccess(presignedUrls.presigned_url_for_aadhaar_back, aadhaarBack as File);
              if (setProfileDetailForm && profileDetailForm) {
                setProfileDetailForm({ ...profileDetailForm, aadharBack: imageUrl });
              } else if (aadhaarUploadedData && setAadhaarUploadedData) {
                setAadhaarUploadedData({ ...aadhaarUploadedData, aadhaarBack: imageUrl });
              }
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default AadhaarOcr;
