import { Box, Button, Flex, Image, Progress, Skeleton, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { ApplicationProgressData } from "../../utils/dashboard";
import { useActiveBatch } from "../../api/utils/hooks/useBatch";
import { formatDateWithOrdinal } from "../../utils/utils";

interface ApplicationProgressCardProps {
  data: ApplicationProgressData;
  isApplicationCompleted: boolean;
  isIITDashboard?: boolean;
}

const ApplicationProgressCard: React.FC<ApplicationProgressCardProps> = ({ data, isApplicationCompleted, isIITDashboard }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { courseId, btnText, iconUrl, courseName, applicationDeadline, currentStepName, currentStepNumber, totalSteps, onSubmit } = data;

  const { data: activeBatch, isLoading } = useActiveBatch(courseId);

  return (
    <Box maxW="1440px" mx={"auto"} mb={8} px={{ base: "16px", xl: "80px" }}>
      <Text fontSize={{ base: "20px", md: "24px" }} fontWeight={"700"} lineHeight={"28.8px"} textAlign={"left"} mr="auto" fontFamily={"inter"}>
        {isApplicationCompleted ? "Completed applications" : "Continue application"}
      </Text>
      <Flex
        gap={{ base: "17.33px", md: 4 }}
        my={4}
        w="full"
        direction={{ base: "column", md: "row" }}
        justify="space-between"
        rounded="8px"
        bg="#FFFFFF"
        overflow="hidden"
        border="1px solid #E5E5E5"
        p={{ base: 4, md: 6 }}
      >
        <Flex gap={4}>
          <Image src={iconUrl} w={{ base: "48px", md: "64px" }} h={{ base: "41px", md: "55px" }} objectFit="contain" />
          <Flex direction="column" gap={1}>
            <Text fontSize={{ base: "16px", md: "20px" }} fontWeight={700} fontFamily={"Poppins"}>
              {courseName}
            </Text>
            <Flex w="full" align="center" gap={5}>
              <Progress value={currentStepNumber} size="sm" colorScheme="green" w="100px" rounded="8px" max={totalSteps} isAnimated />
              <Box rounded="100px" bg="#F2F6FF" p={{ base: "3.333px 6.667px", md: "4px 8px" }} color="#3470E4" fontSize={{ base: "10px", md: "12px" }} fontFamily={"inter"} fontWeight={500}>
                {currentStepName}
              </Box>
            </Flex>
          </Flex>
        </Flex>
        {/* Button And Deadline */}
        <Flex direction={{ base: "row-reverse", md: "row" }} align="center" justify={{ base: "flex-end", md: "center" }} gap={5}>
          <Box display={applicationDeadline ? "block" : "none"}>
            <Text fontSize="10px" fontWeight={500} color="#544D4F">
              Application deadline
            </Text>
            <Skeleton isLoaded={!isLoading} h="full" rounded={"8px"}>
              <Flex gap={1} align="center">
                <Image src="/img/revamp/red-calendar.svg" alt="calendar" w="16px" h="16px" />
                <Text fontSize="16px" fontWeight={700} color="#ED0331" fontFamily="sans-serif">
                  {formatDateWithOrdinal(isIITDashboard ? (activeBatch?.meta_data?.programStartDate as string) : (activeBatch?.application_end_date as string))}
                </Text>
              </Flex>
            </Skeleton>
          </Box>
          <Button variant="primary" size={isMobile ? "sm" : "md"} onClick={onSubmit}>
            {btnText}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ApplicationProgressCard;
