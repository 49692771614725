import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import { Speaker } from "./Activity";
import { LinkedinIcon } from "react-share";

function SpeakerView({ speaker }: { speaker: Speaker }) {
  return (
    <Flex
      position={"relative"}
      border={"solid 1px #CFD0EE"}
      backgroundColor={"#F3F2F2"}
      alignItems={{ md: "center" }}
      rounded={"xl"}
      px={{ md: 8 }}
      py={{ md: 10, base: 5 }}
      gap={{ base: 4, md: 0 }}
      direction={{ base: "column", md: "row" }}
    >
      <Box
        pr={{ base: 4, md: 12 }}
        textAlign={{ base: "center", md: "left" }}
        spacing={3}
        borderRight={"solid 1px #D9D9D9"}
        pl={{ base: 4, md: 0 }}
      >
        <Text>Know your speaker</Text>
        <Heading as="h4" color={"#000"}>
          Meet
        </Heading>
        <Text fontFamily={"heading"} color="#6E71CC" fontSize={{ base: "2xl", md: "3xl" }}>
          {speaker.name}
        </Text>
      </Box>
      <Box display={{ base: "flex", md: "none" }} justifyContent={"center"}>
        <Box position={"relative"}>
          <Image
            src={speaker.image}
            alt={speaker.name}
            style={{ maxWidth: 140 }}
            border={"solid 1px #000"}
            rounded={"lg"}
          />
          <Box
            as="a"
            href={speaker.linkedInUrl}
            target="_blank"
            rel="noreferrer"
            right={"-14px"}
            bottom={"14px"}
            position={"absolute"}
            rounded="full"
            overflow={"hidden"}
          >
            <LinkedinIcon size={32} />
          </Box>
        </Box>
      </Box>
      <Box
        textAlign={{ base: "center", md: "left" }}
        flexGrow={"1"}
        pl={{ base: 4, md: 12 }}
        pr={{ base: 4, md: 0 }}
        mr={{ md: "150px" }}
      >
        <Text whiteSpace="pre-line">{speaker.about}</Text>
      </Box>
      <Box
        position={{ base: "relative", md: "absolute" }}
        bottom={{ md: 0 }}
        right={{ md: 6 }}
        display={{ base: "none", md: "block" }}
      >
        <img style={{ maxHeight: 150 }} src={speaker.image} alt={speaker.name} />
      </Box>
      <Box
        display={{ base: "none", md: "block" }}
        as="a"
        href={speaker.linkedInUrl}
        target="_blank"
        rel="noreferrer"
        right={"10px"}
        bottom={"10px"}
        position={"absolute"}
        rounded="full"
        overflow={"hidden"}
      >
        <LinkedinIcon size={32} />
      </Box>
    </Flex>
  );
}

export default SpeakerView;
