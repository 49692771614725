import { Box, Button, Heading, Image, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { CheckFat } from "../../../assets/images";
import { useMutation } from "react-query";
import { createAssessment, recordCancelAssessment } from "../../../api/utils/api/v2/assessment";
import { axiosErrorHandler } from "../../../api/utils/error";
import useSnackBar from "../../common/general/SnackBar";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import { useAllCounsellingStatus } from "../../../api/utils/hooks/useCounsellingStatus";
import { Dropdown, ModalTemplateV2 } from "../../common/general";
import { sendTrackingEvent } from "../../common/utils/gtm";
import { useLocation } from "react-router-dom";
import { useCourses } from "../../../api/utils/hooks/useCourse";
import { useBestAssessment } from "../../../api/utils/hooks/useBestAssessment";
interface Props {
  setShowMsatConfirmModal: (value: boolean) => void;
  createNewTest: boolean;
  isEmpowerHer?: boolean;
}

const MSATModalContent = ({ setShowMsatConfirmModal, createNewTest, isEmpowerHer }: Props) => {
  const { data: allCourses, isLoading: loadingCourses } = useCourses();

  const location = useLocation();
  const { data: bestAssessment, refetch: refetchBestAssessment } = useBestAssessment();
  const { refetch: refetchAllCounsellingStatus } = useAllCounsellingStatus();
  const { data: profile, refetch: refetchProfile } = useProfile();
  const [openRedirectModal, setOpenRedirectModal] = React.useState(false);
  const snackbar = useSnackBar();
  const { mutate: createAssessmentMutate, isLoading: isCreateAssessmentLoading } = useMutation(createAssessment, {
    onSuccess: (data) => {
      window.location.href = data.test_url;
      refetchBestAssessment();
    },
    onError: (error) => {
      setOpenRedirectModal(false);
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });
  const [selectedDropdownCourse, setSelectedDropdownCourse] = React.useState("");

  const [dropdownOptions, setDropdownOptions] = React.useState<string[]>([]);

  const showIITGuwahatiDashboard = profile?.meta?.is_masai_course_prefered === "False" || false;

  useEffect(() => {
    if (allCourses && allCourses.length > 0) {
      if (isEmpowerHer) {
        setDropdownOptions(allCourses.filter((course) => course?.id === 20 && course?.active_batch).map((course) => course.name));
      } else {
        setDropdownOptions(allCourses.filter((course) => course?.id !== 20 && course?.active_batch).map((course) => course.name));
      }
    }
  }, [allCourses]);

  const { mutate: cancelAssessmentMutate, isLoading: isCancelAssessmentLoading } = useMutation(recordCancelAssessment, {
    onSuccess: (data) => {
      refetchProfile();
      refetchAllCounsellingStatus();
      setShowMsatConfirmModal(false);

      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const points = [
    <Text key={1} textStyle={"body1-md"}>
      upto{" "}
      <Text as="span" textStyle={"body1-md"} color="#5E4FB7">
        28 years{" "}
      </Text>
      of age (on batch starting date)
    </Text>,
    <Text key={2} textStyle={"body1-md"}>
      12th{" "}
      <Text as="span" textStyle={"body1-md"} color="#5E4FB7">
        {" "}
        pass{" "}
      </Text>{" "}
      or a college{" "}
      <Text as="span" textStyle={"body1-md"} color="#5E4FB7">
        graduate
      </Text>
    </Text>,
    <Text key={3} textStyle={"body1-md"}>
      available for{" "}
      <Text as="span" textStyle={"body1-md"} color="#5E4FB7">
        12-hour{" "}
      </Text>
      daily study,{" "}
      <Text as="span" textStyle={"body1-md"} color="#5E4FB7">
        6 days a week{" "}
      </Text>
      (11 am - 11 pm) once selected
    </Text>,
    <Text key={3} textStyle={"body1-md"}>
      have a{" "}
      <Text as="span" textStyle={"body1-md"} color="#5E4FB7">
        desktop/laptop{" "}
      </Text>
      once the course starts
    </Text>,
  ];
  return (
    <>
      <Box>
        <Heading textAlign={"center"} color={"ms-red.900"} as="h6">
          Before you proceed accept that you are
        </Heading>
        <Box mt="16px" p="16px" bgColor={"#fff"} borderRadius={"16px"} display={"grid"} gap="20px">
          {points.map((point, index) => (
            <Box display={"flex"} alignItems={"center"} gap="16px" key={index}>
              <Image src={CheckFat} alt="check icon" />
              {point}
            </Box>
          ))}
        </Box>
        {createNewTest && allCourses && allCourses.length > 0 && (
          <Box mt="ms-16">
            <Dropdown
              options={dropdownOptions}
              label="Please select the course you want to apply for"
              placeholder={"Select course"}
              getValueCallback={(value) => {
                setSelectedDropdownCourse(value);
              }}
              isInvalid={false}
              value={selectedDropdownCourse}
            />
          </Box>
        )}
        <Box my="16px" borderTop={"1px solid #85A9EF"} mx="-24px"></Box>
        <Box display={"grid"} gridTemplateColumns={"30% 70%"} gap="16px">
          <Button
            isLoading={isCancelAssessmentLoading}
            variant="secondary"
            onClick={() => {
              sendTrackingEvent({
                event: "d_start_msat_cancel",
                eventLabel: location.pathname,
              });
              cancelAssessmentMutate();
              window.history.replaceState({}, document.title, window.location.pathname);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              sendTrackingEvent({
                event: "d_start_msat_confirm",
                eventLabel: location.pathname,
              });
              if (createNewTest) {
                // set localstorage for the last assessment hide_switch = "true"
                localStorage.setItem("hide_switch", "true");
                const selectedCourseId = allCourses?.find((course) => course.name === selectedDropdownCourse);
                createAssessmentMutate({
                  for_practice: false,
                  course_preference: selectedCourseId?.id || -1,
                });
              } else if (bestAssessment) {
                window.location.href = bestAssessment.test_url;
              }
              setOpenRedirectModal(true);
              window.history.replaceState({}, document.title, window.location.pathname);
            }}
            variant="primary"
            isLoading={isCreateAssessmentLoading}
            disabled={(selectedDropdownCourse === "" || loadingCourses) && createNewTest}
          >
            Confirm & Continue
          </Button>
        </Box>
      </Box>
      <ModalTemplateV2
        closeOnOverlayClick={false}
        isOpen={openRedirectModal}
        closeOnEsc={false}
        hideIconWithConfetti={true}
        showConfetti={false}
        onClose={() => {
          //
        }}
        waiting={true}
        text={showIITGuwahatiDashboard ? "You are being redirected to our Entrance Test Platform." : "You are being redirected to our MSAT Platform."}
      />
    </>
  );
};

export default MSATModalContent;
