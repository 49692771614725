import { Box, Button, Image } from "@chakra-ui/react";
import React from "react";
import { ReplayIcon } from "../../../../../assets/icons/components";
import FileUploadIcon from "../../../../../assets/icons/components/FileUploadIcon";
import { CameraShutter, DisabledCamera2 } from "../../../../../assets/images";

interface Props {
  currentPictureType?: "front" | "back" | null;
  aadhaarFrontCaptured?: string;
  aadhaarBackCaptured?: string;
  capturePhoto?: () => void;
  handleRetry?: () => void;
  handleClose?: () => void;
  disabled?: boolean;
}

const CameraControls = ({
  currentPictureType,
  aadhaarFrontCaptured,
  aadhaarBackCaptured,
  capturePhoto,
  handleRetry,
  handleClose,
  disabled,
}: Props) => {
  return (
    <Box mt={"ms-16"} px={"ms-16"} d={"flex"} justifyContent={"center"} alignItems={"center"}>
      <Button
        disabled={
          disabled ||
          (currentPictureType === "front" && !aadhaarFrontCaptured) ||
          (currentPictureType === "back" && !aadhaarBackCaptured)
        }
        onClick={handleRetry}
        mr={"ms-16"}
        variant="tertiary"
        leftIcon={<ReplayIcon boxSize="6" color="ms-blue.500" />}
      >
        Retry
      </Button>
      {disabled ||
      (currentPictureType === "front" && aadhaarFrontCaptured) ||
      (currentPictureType === "back" && aadhaarBackCaptured) ? (
        <Image mr={"ms-16"} w={"56px"} h={"56px"} src={DisabledCamera2} alt={"camera"} />
      ) : (
        <Image
          cursor={"pointer"}
          onClick={capturePhoto}
          mr={"ms-16"}
          w={"56px"}
          h={"56px"}
          src={CameraShutter}
          alt={"camera"}
        />
      )}

      <Button
        disabled={
          disabled ||
          (currentPictureType === "front" && !aadhaarFrontCaptured) ||
          (currentPictureType === "back" && !aadhaarBackCaptured)
        }
        onClick={handleClose}
        variant="secondary"
        rightIcon={<FileUploadIcon boxSize="6" color="ms-blue.500" />}
      >
        Upload
      </Button>
    </Box>
  );
};

export default CameraControls;
