import { useQuery } from "react-query";
import { getDost } from "../api/v2/activity";
import { GetDostType } from "../../schemas/schema";

export function useDost() {
  return useQuery({
    queryKey: "getDost",
    queryFn: async () => (await getDost()) as GetDostType,
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,

    refetchOnWindowFocus: false,
    enabled: true,
  });
}
