import { Box, Button, Heading, Text } from "@chakra-ui/react";
import React, {useState } from "react";
import { OnboardingFormType } from "../V2/Onboarding/OnboardingForm";
import { useMutation } from "react-query";
import { sendBorucherOnEmail, updateProfile } from "../../api/utils/api/v2/profile";
import { axiosErrorHandler } from "../../api/utils/error";
import useSnackBar from "../common/general/SnackBar";
import dayjs from "dayjs";
import { Select } from "chakra-react-select";
import DateSelect from "../common/DateSelect";
import { RadioButtonGroup } from "../common/general";
import { submitCounsellingRequest } from "../../api/utils/api/v2/activity";
import {ProfileType } from "../../api/schemas/schema";
import { getCounsellingSource, isInEligibleUserFn } from "../../utils/user";
import { useLocation } from "react-router-dom";

interface Props {
  setIsFirstTimeShowingBanner: (params: boolean) => void;
  setIsCounsellingSessionModalOpen: (param: boolean) => void;
  refetchCounsellingStatus: () => void;
  refetchProfile: () => void;
  setIsFounderWebinarModalOpen: (params: boolean) => void;
}

const CounsellingSessionModal = ({
  setIsFirstTimeShowingBanner,
  setIsCounsellingSessionModalOpen,
  refetchCounsellingStatus,
  refetchProfile,
  setIsFounderWebinarModalOpen,
}: Props) => {
  const location = useLocation();
  const snackbar = useSnackBar();
  const [formValues, setFormValues] = useState<OnboardingFormType>({
    dob: null,
    graduationMonth: null,
    graduationYear: null,
    currentlyWorking: undefined,
  });
  const [selectedWorkingOption, setSelectedWorkingOption] = useState<string>("");
  const [isGraduationCompleted, setIsGraduationCompleted] = useState<boolean>(true);

  const { mutate: sendBorucher } = useMutation(sendBorucherOnEmail, {
    onSuccess: () => {
      //
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const {
    mutate: submitCounsellingRequestMutate,
    isLoading: submitCounsellingRequestLoading,
  } = useMutation(submitCounsellingRequest, {
    onSuccess: () => {
      snackbar.success("Callback Requested Successfully");
      refetchCounsellingStatus();
      setIsCounsellingSessionModalOpen(false);
      setIsFounderWebinarModalOpen(true);
      if (formValues.queryType) {
        sendBorucher({
          request_callback_immediate: true,
        });
      }
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: updateProfileMutate, isLoading: updateProfileLoading } = useMutation(
    updateProfile,
    {
      onSuccess: (data) => {
        refetchProfile();
        const isInEligibleUser = isInEligibleUserFn(data as ProfileType);
        if (!isInEligibleUser) {
          const source = getCounsellingSource(location?.pathname || "");
          submitCounsellingRequestMutate({ source });
        }
        setIsCounsellingSessionModalOpen(false);
        setIsFirstTimeShowingBanner(true);
      },
      onError: (error) => {
        const e = axiosErrorHandler(error);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("Something went wrong");
        }
      },
    }
  );

  const occupationOptions = [
    { value: "cw", label: "currently working" },
    { value: "cs", label: "currently studying (in college)" },
    { value: "nwns", label: "Neither working nor studying" },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFormValuesChange = (key: string, value?: any) => {
    if (key === "monthYearOfGraduation") {
      const parsedDate = dayjs(value);
      const year = parsedDate.year();
      const month = parsedDate.month() + 1;
      setFormValues((prev) => ({
        ...prev,
        graduationMonth: month,
        graduationYear: year,
      }));
    } else if (key === "dob") {
      //convert date in this format 2000-07-05
      setFormValues((prev) => ({
        ...prev,
        [key]: value.format("YYYY-MM-DD"),
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const handleSubmit = () => {
    updateProfileMutate({
      date_of_birth: formValues.dob || "",
      graduation_year:
        formValues.currentlyWorking && formValues.graduationYear
          ? -1
          : formValues.graduationYear
          ? formValues.graduationYear
          : -1,
      graduation_month:
        formValues.currentlyWorking && formValues.graduationMonth
          ? -1
          : formValues.graduationMonth
          ? formValues.graduationMonth
          : -1,
      is_working: formValues.currentlyWorking || false,
    });
  };

  const getIsDisabledValue = () => {
    return (
      formValues.dob == null ||
      formValues.currentlyWorking == undefined ||
      (!formValues.currentlyWorking && selectedWorkingOption === "cw") ||
      (selectedWorkingOption === "cs" &&
        (formValues.graduationMonth == null || formValues.graduationYear == null)) ||
      (isGraduationCompleted &&
        selectedWorkingOption === "nwns" &&
        (formValues.graduationMonth == null || formValues.graduationYear == null))
    );
  };
  return (
    <Box w={"full"}>
      <Heading as="h4" size="md">
        Book a counselling session
      </Heading>
      <Text>Before you proceed, we require a few details.</Text>
      <Box display="flex" flexDir={"column"} gap={"16px"} mt={"16px"}>
        {/* Date of Birth (DOB) Field */}
        <Box display="flex" flexDir={"column"} gap={"6px"}>
          <label htmlFor="age" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Enter your age
          </label>
          <DateSelect
            showRelativeDate={true}
            onChange={(value) => {
              handleFormValuesChange("dob", value);
            }}
            value={formValues.dob ? dayjs(formValues.dob) : null}
            isInvalid={false}
            errorMessage="Please add your date of birth."
            disabled={false}
            w={"full"}
          />
        </Box>

        {/* Occupation Field */}
        <Box display="flex" flexDir={"column"} gap={"6px"}>
          <label htmlFor="occupation" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Your current occupation status
          </label>
          <Select
            onChange={(event) => {
              if (setSelectedWorkingOption && event?.value) {
                setSelectedWorkingOption(event.value);
              }
              handleFormValuesChange("currentlyWorking", event?.value === "cw" ? true : false);
            }}
            options={occupationOptions}
          />
        </Box>

        {/* Is Gratudated Option field */}
        {selectedWorkingOption === "nwns" ? (
          <Box mt={{ base: "ms-16", md: "0px" }}>
            <label htmlFor="occupation" style={{ fontWeight: "bold", fontSize: "14px" }}>
              3. Have you finished your graduation?
            </label>
            <RadioButtonGroup
              options={[
                { key: "yes", value: "Yes" },
                { key: "no", value: "No" },
              ]}
              selectedValue={!isGraduationCompleted ? "no" : "yes"}
              onChange={(key) => {
                if (setIsGraduationCompleted) {
                  setIsGraduationCompleted(key === "yes" ? true : false);
                }
                if (key === "no") {
                  setFormValues((prev) => ({
                    ...prev,
                    graduationMonth: null,
                    graduationYear: null,
                  }));
                }
              }}
              gridTemplateComlumns={{ md: "repeat(3,1fr)" }}
              columnGap={"ms-4"}
              rowGap={"ms-24"}
              disabled={false}
            />
          </Box>
        ) : null}

        {/* Graduation Year and Month Field */}
        {selectedWorkingOption === "cs" ||
        (isGraduationCompleted && selectedWorkingOption === "nwns") ? (
          <Box display="flex" flexDir={"column"} gap={"6px"}>
            <label htmlFor="occupation" style={{ fontWeight: "bold", fontSize: "14px" }}>
              What is your month & year of graduation?
            </label>
            <DateSelect
              showRelativeDate={false}
              onChange={(value) => {
                handleFormValuesChange("monthYearOfGraduation", value);
              }}
              value={
                formValues.graduationMonth && formValues.graduationYear
                  ? dayjs()
                      .month(formValues.graduationMonth - 1)
                      .year(formValues.graduationYear)
                  : null
              }
              isInvalid={false}
              errorMessage="Please add your month and year of graduation."
              yearAndMonthOnly={true}
              disabled={false}
              w={["100%", "50%", "60%", "60%"]}
              selectedOption={selectedWorkingOption}
            />
          </Box>
        ) : null}
      </Box>

      {/* Cancel and Submit Button */}
      <Box
        display={"flex"}
        gap={"12px"}
        justifyContent={"end"}
        mt="14px"
        py="12px"
        px="16px"
        p="12px"
        mx="-32px"
        mb="-24px"
      >
        <Button
          onClick={() => {
            setIsCounsellingSessionModalOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          mr={"20px"}
          onClick={handleSubmit}
          isDisabled={
            getIsDisabledValue() || updateProfileLoading || submitCounsellingRequestLoading
          }
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default CounsellingSessionModal;
