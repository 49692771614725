import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const Zoom = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M0 8.22857C0 5.3483 0 3.90817 0.560538 2.80805C1.0536 1.84036 1.84036 1.0536 2.80805 0.560538C3.90817 0 5.3483 0 8.22857 0H15.7714C18.6517 0 20.0918 0 21.192 0.560538C22.1596 1.0536 22.9464 1.84036 23.4395 2.80805C24 3.90817 24 5.3483 24 8.22857V15.7714C24 18.6517 24 20.0918 23.4395 21.192C22.9464 22.1596 22.1596 22.9464 21.192 23.4395C20.0918 24 18.6517 24 15.7714 24H8.22857C5.3483 24 3.90817 24 2.80805 23.4395C1.84036 22.9464 1.0536 22.1596 0.560538 21.192C0 20.0918 0 18.6517 0 15.7714V8.22857Z"
      fill="#4087FC"
    />
    <path
      d="M5.37148 6.85718C4.77186 6.85718 4.28577 7.4054 4.28577 8.08167V14.0204C4.28577 15.7449 5.5253 17.1429 7.05434 17.1429L13.4858 17.0817C14.0854 17.0817 14.5715 16.5335 14.5715 15.8572V9.8572C14.5715 8.13272 13.1005 6.8572 11.5715 6.8572L5.37148 6.85718Z"
      fill="white"
    />
    <path
      d="M16.0391 9.19512C15.6511 9.57875 15.4286 10.1324 15.4286 10.7143V13.1994C15.4286 13.7814 15.6511 14.335 16.0391 14.7187L18.4533 16.845C18.9446 17.3307 19.7143 16.9373 19.7143 16.2005V7.83017C19.7143 7.09336 18.9446 6.69992 18.4533 7.18565L16.0391 9.19512Z"
      fill="white"
    />
  </Icon>
);

export default Zoom;
