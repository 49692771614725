import { Box, Button, Image, Text } from "@chakra-ui/react";

interface SessionScheduleModalProps {
  alumniName: string;
  setOpenDrawer: (value: boolean) => void;
  setOpenSuccessModal: (value: boolean) => void;
}
const SessionScheduleModal = ({
  alumniName,
  setOpenDrawer,
  setOpenSuccessModal,
}: SessionScheduleModalProps) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={{ md: "12px" }}
      maxW={{ base: "auto", sm: "310px" }}
      py="0"
    >
      <Image alt="check-img" src="/img/check-icon.svg" w="56px" h="56px" margin={"auto"} />

      <Text color="ms-grey.900" as="h4" textStyle={"h4"} textAlign={"center"}>
        Your connect with {alumniName} is scheduled. You will receive an email invite shortly.
      </Text>

      <Box
        boxShadow={"0px -8px 32px 0px rgba(0, 0, 0, 0.10);"}
        display={"flex"}
        margin={"auto"}
        mt="4"
      >
        <Button
          variant="primary"
          onClick={() => {
            setOpenSuccessModal(false);
            setOpenDrawer(false);
          }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default SessionScheduleModal;
