import { Box, Button, Heading, Input } from "@chakra-ui/react";
import { useState } from "react";
import { useMutation } from "react-query";
import { updateBatchDetails } from "../../../../api/utils/api/v2/admin";
import { axiosErrorHandler } from "../../../../api/utils/error";
import useSnackBar from "../../../common/general/SnackBar";
import { Select } from "@chakra-ui/react";
import { BatchTypes } from "../../../../enums/ProgramType.enum";
import { ActiveBatchList } from "../../../../constants/Program-list";
import { UpdateBatchDetailsType } from "../../../../api/schemas/schema";
import get from "lodash.get";

const UpdateBatchInfo = () => {
  const snackbar = useSnackBar();
  const [link, setLink] = useState("");
  const [batchSelected, setBatchSelected] = useState<BatchTypes>();

  const { mutate: updateBatchDetailsFn, isLoading: isUpdatingBatch } = useMutation(updateBatchDetails, {
    onSuccess: (data) => {
      snackbar.success(data.message);
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        const message = get(e, ["detail"], "Something went wrong");
        snackbar.error(message);
      }
    },
  });

  const handleBatchUpdate = () => {
    const payload = {
      link,
      batch_type: batchSelected,
    } as UpdateBatchDetailsType;
    updateBatchDetailsFn(payload);
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap="10px" margin="10px">
      <Heading as="h3">Update Batch Details</Heading>
      <Box display={"flex"} flexDirection={"column"} gap="10px" mt="40px" width={"400px"}>
        <Box>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Select Batch Name*
          </label>
          <Select
            placeholder={"Select Batch Name"}
            onChange={(event) => {
              setBatchSelected(event.target.value as BatchTypes);
            }}
          >
            {ActiveBatchList.map((item) => (
              <option key={item?.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </Select>
        </Box>

        <Box>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Enter Whatsapp/Slack Link*
          </label>
          <Input name="email" type="text" placeholder="Enter whatsapp/slack link" value={link} onChange={(e) => setLink(e.target.value.trim())} />
        </Box>

        <Button isLoading={isUpdatingBatch} isDisabled={!link || !batchSelected || isUpdatingBatch} width={"100px"} variant="primary" onClick={handleBatchUpdate} ml="auto">
          Update
        </Button>
      </Box>
    </Box>
  );
};

export default UpdateBatchInfo;
