import { Box, Text, useBreakpointValue } from "@chakra-ui/react";
import CurrentStageMobile from "./CurrentStageMobile";
import { MasaiXStageType } from "./MasaiXCodingInstructions";
//import MasaiXStageLineSvg from "../../../../assets/images/CoursePrograms/MasaiXStageLine.svg";
interface Props {
  currentStage: MasaiXStageType;
  insideModal?: boolean;
  customCurrentStageColor?: string;
  customMasaiXStagesText?: string[];
  customMasaiXStagesMargin?: string[];
}
const CurrentStage = ({
  customMasaiXStagesText,
  currentStage,
  insideModal,
  customCurrentStageColor,
  customMasaiXStagesMargin,
}: Props) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const masaiXStages: MasaiXStageType[] = [
    "CODING",
    "INTERVIEW",
    "AADHAAR_VERIFICATION",
    "ONBOARDING",
  ];
  const masaiXStagesText: string[] = customMasaiXStagesText || [
    "Coding Test",
    "Interview",
    "Aadhaar Verification",
    "Onboarding",
  ];
  const masaiXStagesMargin: string[] = insideModal
    ? ["-24px", "-18px", "-55px", "-24px"]
    : customMasaiXStagesMargin
    ? customMasaiXStagesMargin
    : ["-18px", "-14px", "-50px", "-20px"];

  return (
    <>
      {isMobile ? (
        <Box mb={insideModal ? "ms-24" : "ms-16"} mt={insideModal ? "ms-24" : "0px"}>
          <CurrentStageMobile
            currentStage={currentStage}
            insideModal={insideModal}
            masaiXStagesText={masaiXStagesText}
            masaiXStages={masaiXStages}
          />
        </Box>
      ) : (
        <Box
          p={"ms-24"}
          pl={insideModal ? "ms-32" : "ms-48"}
          display={"flex"}
          justifyContent={"space-between"}
        >
          {masaiXStages.map((stage) => (
            <Box
              width={masaiXStages.indexOf(stage) !== masaiXStages.length - 1 ? "100%" : undefined}
              key={stage}
            >
              {/* number and line */}
              <Box w={"100%"} display={"flex"} alignItems={"center"}>
                {/* number */}
                <Box
                  borderRadius={"50%"}
                  textAlign={"center"}
                  minW={"32px"}
                  minH={"32px"}
                  p={"4px"}
                  bgColor={
                    insideModal
                      ? "ms-cyan.500"
                      : masaiXStages.indexOf(stage) < masaiXStages.indexOf(currentStage)
                      ? "ms-success"
                      : masaiXStages.indexOf(stage) === masaiXStages.indexOf(currentStage)
                      ? customCurrentStageColor
                        ? customCurrentStageColor
                        : "ms-blue.500"
                      : "ms-grey.500"
                  }
                >
                  <Text fontSize={"18px"} lineHeight={"24px"} fontWeight={"600"} color={"#fff"}>
                    {masaiXStages.indexOf(stage) + 1}
                  </Text>
                </Box>
                {/*  line */}
                {masaiXStages.indexOf(stage) !== masaiXStages.length - 1 && (
                  <Box w={"100%"} h={"0px"} border="1px solid #3470E4"></Box>
                )}
              </Box>

              <Text
                ml={masaiXStagesMargin[masaiXStages.indexOf(stage)]}
                minW={"100px"}
                mt={"ms-8"}
                textStyle={"body1-md"}
              >
                {masaiXStagesText[masaiXStages.indexOf(stage)]}
              </Text>
            </Box>
            // <Box key={stage} display={"flex"} alignItems={"center"}>
            //   <Box display={"flex"}>
            //     <Box display={"flex"} flexDirection={"column"}>
            //       <Box>
            //         <Box
            //           display={"flex"}
            //           justifyContent={"center"}
            //           p={"4px"}
            //           width={"32px"}
            //           height="32px"
            //           borderRadius={"50%"}
            //           bgColor={
            //             masaiXStages.indexOf(stage) <= masaiXStages.indexOf(currentStage)
            //               ? "ms-success"
            //               : "ms-grey.500"
            //           }
            //           m={"auto"}
            //         >
            //           <Text color={"#fff"} fontSize={"18px"} fontWeight={"600"} lineHeight={"24px"}>
            //             {masaiXStages.indexOf(stage) + 1}
            //           </Text>
            //         </Box>
            //       </Box>

            //       <Text textAlign={"center"} minW={"100px"} mt={"ms-8"} textStyle={"body1-md"}>
            //         {masaiXStagesText[masaiXStages.indexOf(stage)]}
            //       </Text>
            //     </Box>
            //   </Box>
            //   {stage !== "ONBOARDING" && (
            //     <Box>
            //       <Image w={"100%"} src={MasaiXStageLineSvg} alt="current stage" />
            //     </Box>
            //   )}
            // </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default CurrentStage;
