/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Skeleton, Text, useBreakpointValue } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { InputCustom, ModalTemplate } from "../../common/general";
import Drawer from "react-drag-drawer";
import GreenCheckCircle from "../../../assets/icons/components/GreenCheckCircle";
import { useMutation } from "react-query";
import { alumniGetInTouch } from "../../../api/utils/api/v2/activity";
import { axiosErrorHandler } from "../../../api/utils/error";
import useSnackBar from "../../common/general/SnackBar";
import { getRandomElementsFromArray } from "../../common/utils/getRandomElementsFromArray";
import AlumniSlotBookingDrawer from "../../common/V2/AlumniSlotBookingDrawer";
import NewAlumniCard from "./NewAlumniCard";
// import { GetLevelupAlumniType } from "../../../api/utils/api/v2/levelup";
import {
  useGetLevelupAlumnis,
  useGetParticipantSessions,
} from "../../../api/utils/hooks/levelup/useGetLevelupAlumnis";
import AlumniFeedbackForm from "./AlumniFeedbackForm";
import SessionScheduleModal from "./SessionScheduleModal";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import { GetLevelupAlumniType } from "../../../api/schemas/schema";

const AlumniTestimonials = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [studentQuery, setStudentQuery] = useState<string>("");
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [openAlumniModal, setOpenAlumniModal] = React.useState(false);
  const [selectedAlumni, setSelectedAlumni] = useState<GetLevelupAlumniType>({
    id: "-1",
    name: "",
  });
  const [alumniRandomData, setAlumniRandomData] = useState<GetLevelupAlumniType[] | null>(null);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [alumniName, setAlmniName] = useState<string>("");
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

  const { data: profile, refetch: refetchProfile, isLoading: isProfileLoading } = useProfile();

  const {
    data: alumniSessions,
    refetch: refetchParticipantSessions,
    isLoading: isAlumniSessionLoading,
  } = useGetParticipantSessions(profile?.slug || "");

  const {
    data: alumnisData,
    refetch: alumniDataRefetch,
    isLoading: isAlumnisDataLoading,
  } = useGetLevelupAlumnis();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const snackbar = useSnackBar();

  const { mutate: requestAlumni, isLoading: isRequestAlumniLoading } = useMutation(
    alumniGetInTouch,
    {
      onSuccess: () => {
        setShowSuccess(true);
      },
      onError: (error) => {
        const e = axiosErrorHandler(error);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("Something went wrong");
        }
      },
    }
  );

  const modalProps = {
    open: openDrawer,
    modalElementClass: "bottomDrawerClass",
    onRequestClose: () => {
      setOpenDrawer(false);
      setShowSuccess(false);
    },
    // if user is at the top then only allow to close the drawer
    notifyWillClose: () => {
      setOpenDrawer(false);
      setShowSuccess(false);
    },
  };

  useEffect(() => {
    if (alumnisData) {
      const data = getRandomElementsFromArray(alumnisData);
      if (data) {
        setAlumniRandomData(data);
      }
    }
  }, [alumnisData]);

  useEffect(() => {
    alumniDataRefetch();
    refetchProfile();
  }, []);

  useEffect(() => {
    refetchParticipantSessions();
    refetchProfile();
  }, [profile?.slug, openSuccessModal]);

  const currentScheduledSession = alumniSessions?.find(
    (session: any) =>
      (session.participantFeedback === undefined || session.participantFeedback.length === 0) &&
      session.sessionStatus === "Completed"
  );
  const alumniNameForFeedback = currentScheduledSession?.host?.name;

  useEffect(() => {
    const currentScheduledSession = alumniSessions?.find(
      (session: any) =>
        (session.participantFeedback === undefined || session.participantFeedback.length === 0) &&
        session.sessionStatus === "Completed"
    );
    if (currentScheduledSession) {
      setFeedbackModalOpen(true);
    }
  }, [alumniSessions]);

  // sort based on current scheduled session
  const currentScheduledSessionId = (a: any, b: any) => {
    const aScheduledSession = alumniSessions?.find(
      (session: any) =>
        session?.host?._id === a?._id && ["Scheduled", "Started"].includes(session.sessionStatus)
    );
    const bScheduledSession = alumniSessions?.find(
      (session: any) =>
        session?.host?._id === b?._id && ["Scheduled", "Started"].includes(session.sessionStatus)
    );

    if (aScheduledSession && !bScheduledSession) {
      return -1;
    } else if (!aScheduledSession && bScheduledSession) {
      return 1;
    } else {
      return 0;
    }
  };

  return (
    <Box>
      <Box display={"flex"} flexDir={"column"} justifyContent={"space-between"} mb={3}>
        <Text textStyle="body1-md" mb="12px">
          Get your queries resolved now{" "}
        </Text>
        {alumniSessions?.length > 0 && (
          <Box
            p="8px"
            border="1px solid #AEC6F4"
            mb="2"
            bg="#FFF"
            rounded={"4px"}
            color="#3470E4"
            fontSize={"14px"}
            fontWeight={600}
            justifyContent={"center"}
            mr={{ base: 0, md: "60px" }}
            display={"inline"}
            maxW={"174px"}
          >
            Available Connects : {3 - alumniSessions?.length}
          </Box>
        )}
      </Box>

      <Skeleton
        h={isAlumnisDataLoading ? "100vh" : "full"}
        display="flex"
        flexWrap="wrap"
        isLoaded={!isAlumnisDataLoading && !isAlumniSessionLoading && !isProfileLoading}
        gap={{ base: "16px", md: "24px" }}
        justifyContent={{ base: "center", md: "normal" }}
        alignItems={"center"}
      >
        {alumniRandomData && alumniRandomData.length > 0 ? (
          alumniRandomData
            .sort((a, b) => (b?.totalSlots ?? 0) - (a?.totalSlots ?? 0))
            .sort((a, b) => currentScheduledSessionId(a, b))
            .map((alumni, index) => {
              const currentScheduledSession = alumniSessions?.find(
                (session: any) =>
                  session?.host?._id === alumni?.id &&
                  ["Scheduled", "Started"].includes(session.sessionStatus)
              );
              const hasAnyMeetingScheduledOrStarted = alumniSessions?.some((session: any) =>
                ["Scheduled", "Started"].includes(session.sessionStatus)
              );
              const hasScheduledSession = alumniSessions && alumniSessions.length > 0;

              const hasCompletedAllSessions = alumniSessions?.every(
                (session: any) => session.sessionStatus === "Completed"
              );

              const disableScheduleButton =
                isAlumniSessionLoading ||
                isAlumnisDataLoading ||
                !profile?.slug ||
                (hasAnyMeetingScheduledOrStarted && !currentScheduledSession) ||
                (alumniSessions?.length >= 3 && hasCompletedAllSessions);

              return (
                <NewAlumniCard
                  onTextClick={() => {
                    setOpenAlumniModal(true);
                    setSelectedAlumni(alumni);
                  }}
                  onCtaClick={() => {
                    setSelectedAlumni(alumni);
                    setOpenDrawer(true);
                  }}
                  shouldDisableButton={disableScheduleButton}
                  btnText={
                    hasScheduledSession && currentScheduledSession ? "Join Session" : "get in touch"
                  }
                  joinLink={
                    hasScheduledSession &&
                    currentScheduledSession &&
                    `/alumni-session?sessionId=${currentScheduledSession._id}&token=${profile?.meta?.jwtToken}`
                  }
                  key={index}
                  {...alumni}
                />
              );
            })
        ) : (
          <Text>None of the alumni are available for the connect. </Text>
        )}
      </Skeleton>

      <ModalTemplate
        title=""
        marginX={0}
        paddingX="0px"
        paddingY="0px"
        maxWidth="343px"
        onClose={() => {
          setOpenAlumniModal(false);
        }}
        isOpen={false}
        closeOnOverlayClick={true}
        showCloseButtonIcon={false}
      >
        <NewAlumniCard
          extendedText={true}
          {...selectedAlumni}
          onCtaClick={() => {
            setOpenAlumniModal(false);
            setOpenDrawer(true);
          }}
        />
      </ModalTemplate>

      <Box
        as={isMobile ? Drawer : ModalTemplate}
        title=""
        marginX={0}
        paddingX="0px"
        paddingY="0px"
        maxWidth="343px"
        onClose={() => {
          setOpenDrawer(false);
          setShowSuccess(false);
        }}
        isOpen={false}
        {...modalProps}
      >
        {showSuccess ? (
          <>
            <Box w="full" bg="white" pt="40px" maxW={{ base: "267px", md: "full" }} mx="auto">
              <Box mx="auto" h="56px" w="56px">
                <GreenCheckCircle boxSize="56px" />
              </Box>
              <Text
                mt="24px"
                w={{ base: "full", md: "319px" }}
                textStyle={{ base: "body1-md", md: "h4" }}
                textAlign="center"
              >
                We have received your queries, you will be receiving a reply from our alumni
                shortly.
              </Text>
            </Box>
            <Box textAlign="center">
              <Button
                onClick={() => {
                  alumniDataRefetch();
                  setOpenDrawer(false);
                  setShowSuccess(false);
                }}
                variant="primary"
                w={{ base: "full", md: "fit-content" }}
                textStyle="btn-md"
                size="md"
                mt="46px"
              >
                continue
              </Button>
            </Box>
          </>
        ) : (
          <Box w={{ base: "full", md: "477px" }} bg="white">
            <Text mb="16px" textStyle={{ base: "h5", md: "h3" }} color="#0A0103" textAlign="center">
              Get in touch!
            </Text>
            <InputCustom
              p="8px"
              type="textarea"
              minHeight="133px"
              getValueCallback={(value) => {
                setStudentQuery(value.trim());
              }}
              placeholder="Mention your query..."
              label={`What would you like to ask ${selectedAlumni?.name}?`}
            />
            <Box textAlign={{ base: "center", md: "right" }}>
              <Button
                isLoading={isRequestAlumniLoading}
                onClick={() => {
                  if (selectedAlumni) {
                    requestAlumni({
                      id: 1,
                      student_query: studentQuery,
                    });
                  }
                }}
                variant="primary"
                w={{ base: "full", md: "fit-content" }}
                textStyle="btn-md"
                size="md"
                mt={{ base: "36px", md: "16px" }}
              >
                submit
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      <AlumniSlotBookingDrawer
        isOpen={openDrawer}
        onClose={() => setOpenDrawer(false)}
        {...selectedAlumni}
        onCtaClick={() => {
          setOpenAlumniModal(false);
          setOpenDrawer(true);
        }}
        setOpenSuccessModal={setOpenSuccessModal}
        setAlmniName={setAlmniName}
        selectedAlumni={selectedAlumni}
        profile={profile}
      />

      <ModalTemplate
        paddingX="0px"
        modalOverlayProps={{ backgroundColor: "rgba(1, 10, 21, 0.8)" }}
        title={""}
        isOpen={feedbackModalOpen}
        onClose={() => {
          //
        }}
        showCloseButtonIcon={false}
        marginX={2}
      >
        <AlumniFeedbackForm
          sessionId={currentScheduledSession?._id || ""}
          setFeedbackModalOpen={setFeedbackModalOpen}
          alumniName={alumniNameForFeedback}
        />
      </ModalTemplate>

      <ModalTemplate
        modalOverlayProps={{ backgroundColor: "rgba(1, 10, 21, 0.8)" }}
        title={""}
        isOpen={openSuccessModal}
        onClose={() => {
          //
        }}
        showCloseButtonIcon={false}
        marginX={2}
      >
        <SessionScheduleModal
          alumniName={alumniName}
          setOpenDrawer={setOpenDrawer}
          setOpenSuccessModal={setOpenSuccessModal}
        />
      </ModalTemplate>
    </Box>
  );
};

export default AlumniTestimonials;
