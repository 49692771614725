import React from "react";
import { Stack, Box, Spacer, Text } from "@chakra-ui/react";
interface Props {
  id: number;
  title?: string;
  icon?: JSX.Element;
  status?: string;
  finalCredibilityStatus?: string;
  finalCredibilityStatusColor?: string;
  isNewMsat?: boolean;
}

export const ScoreCardTop = ({
  id,
  title,
  icon,
  status,
  finalCredibilityStatus,
  finalCredibilityStatusColor,
  isNewMsat,
}: Props) => {
  return (
    <Stack direction={"row"}>
      <Box
        p={{ base: "6px", md: "ms-8" }}
        bg="ms-purple.50"
        borderRadius={{ base: "ms-8", md: "ms-16" }}
      >
        {icon &&
          React.cloneElement(icon, {
            boxSize: { base: "20px", md: "24px" },
            color: "ms-purple.500",
          })}
      </Box>
      <Spacer />
      {id === 4 && !isNewMsat ? (
        <Text
          textStyle={{ base: "body2-md", md: "body1-md" }}
          pt="ms-8"
          color={finalCredibilityStatusColor}
        >
          {finalCredibilityStatus}
        </Text>
      ) : (
        <Text
          textStyle={{ base: "body2-md", md: "body1-md" }}
          pt="ms-8"
          color={`ms-${
            status === "PASSED" ||
            status === "PASSED_WITH_MFP" ||
            status === "PASSED_WITH_MASS_RECRUIT"
              ? "success"
              : status == "FAILED"
              ? "error"
              : "warning"
          }`}
        >
          {status === "PASSED_WITH_MFP" || status === "PASSED_WITH_MASS_RECRUIT"
            ? "Passed for MFP"
            : status === "PASSED"
            ? "Passed"
            : status == "FAILED"
            ? "Not Cleared"
            : "Pending"}
        </Text>
      )}
    </Stack>
  );
};
