let globalProgramName: string;
export const setGlobalProgramName = (programName: string) => {
  globalProgramName = programName;
};
export const sendTrackingEvent = (eventObj: { [key: string]: string }) => {
  // const [state, dispatch] = useContext(AppContext);
  if (window && window.dataLayer && window.dataLayer.push) {
    window.dataLayer.push({ ...eventObj, programName: globalProgramName || "" });
  }
};

// export const sendTrackingEvent = (eventObj: { [key: string]: string }) => {
//   // const [state, dispatch] = useContext(AppContext);
//   if (window && window.dataLayer && window.dataLayer.push) {
//     window.dataLayer.push(eventObj);
//   }
// };
