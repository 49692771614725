import { Box } from "@chakra-ui/layout";
import React from "react";
import EmailEdit from "../general/EmailVerifyModal/EmailEdit";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import OTPModal from "../auth/OTPModal";
import { sendEmailOtpV2, verifyEmailOtpV2 } from "../../../api/utils/api/v2/profile";
import { useMutation } from "react-query";
import useSnackBar from "../general/SnackBar";
import { axiosErrorHandler } from "../../../api/utils/error";

interface Props {
  showEmailOtpModal: boolean;
  setShowEmailOtpModal: React.Dispatch<React.SetStateAction<boolean>>;
  updatedEmail?: string;
  setShowUpdateEmailModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleRoparStudents?: () => void;
}
const EmailVerifyModalV2 = ({ updatedEmail, showEmailOtpModal, setShowEmailOtpModal, setShowUpdateEmailModal, handleRoparStudents }: Props) => {
  const onOTPModalClose = () => {
    setShowEmailOtpModal(false);
    refetchProfile();
  };
  const { data: profile, refetch: refetchProfile, isLoading: profileLoading } = useProfile();
  const handleEmailEditClick = () => {
    setShowEmailOtpModal(false);
    setShowUpdateEmailModal(true);
  };
  const snackbar = useSnackBar();
  const { mutate: sendEmailOtpMutate, isLoading: sendEmailOtpLoading } = useMutation(sendEmailOtpV2, {
    onSuccess: () => {
      snackbar.success("Otp sent on email!");
      if (!showEmailOtpModal) {
        setShowEmailOtpModal(true);
      }
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });
  const { mutate: verifyEmailOtpMutate, isLoading: verifyEmailOtpLoading } = useMutation(verifyEmailOtpV2, {
    onSuccess: () => {
      snackbar.success("Email verified successfully!");
      refetchProfile();
      if (showEmailOtpModal) {
        setShowEmailOtpModal(false);
      }
      handleRoparStudents && handleRoparStudents();
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  return (
    <Box>
      <OTPModal
        isOpen={showEmailOtpModal}
        onClose={() => {
          refetchProfile();
          onOTPModalClose();
        }}
        title={"Email Verification"}
        primaryText={`Please enter the OTP to verify your email address`}
        secondaryText={<EmailEdit handleEmailEditClick={handleEmailEditClick} email={updatedEmail ? updatedEmail : profile?.email} />}
        otpLength={6}
        timeGap={60}
        onComplete={(value) => {
          verifyEmailOtpMutate({
            otp_input: value,
          });
        }}
        onResendCallback={async () => {
          sendEmailOtpMutate(updatedEmail || profile?.email || "");
        }}
        loading={profileLoading || sendEmailOtpLoading || verifyEmailOtpLoading}
      />
    </Box>
  );
};

export default EmailVerifyModalV2;
