import { Box, Heading, Link, Text, useBreakpointValue } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AadhaarInfoType } from "../../../../api/schemas/schema";
import { proceedToPaymentPending } from "../../../../api/utils/api";
import { confirmAadhaarDetails, processAadhaarOCR } from "../../../../api/utils/api/documentation";
import { axiosErrorHandler } from "../../../../api/utils/error";
import { InformationOutline } from "../../../../assets/icons/components";
import { BottomStickyNavbar, ModalTemplate } from "../../../../components/common/general";
import useSnackBar from "../../../../components/common/general/SnackBar";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import IdVerification from "../../../../components/msat/success-fail/IdVerification";
import VerificationSuccessModal from "../../../../components/msat/success-fail/VerificationSuccessModal";
import { AppContext, ApplicationActionType } from "../../../../context/ApplicationContext";
import useApplicationHook from "../../../../hooks/useApplicationHook";
import { handleNewMsatScore } from "../../../Application/msat/HandleNewMsatScore";
import { AadhaarUploadedDataType } from "../../../Application/msat/MSATSuccess";
import { Banner } from "../../Banner";

import ScoreDetails from "../ScoreDetails";
interface Props {
  testType: "coding" | "msft";
}
const ScholarMSFTCodingSuccess = ({ testType }: Props) => {
  const [showVerificationDoneModal, setShowVerificationDoneModal] = React.useState(false);
  const [state, dispatch] = useContext(AppContext);
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [aadhaarUploadedData, setAadhaarUploadedData] = useState<AadhaarUploadedDataType>({});
  const [aadhaarOCRDetails, setAadhaarOCRDetails] = useState<AadhaarInfoType>();
  const [showfetchingInfoModal, setShowFetchingInfoModal] = useState<boolean>(false);
  const [showConfirmAadhaarModal, setShowConfirmAadhaarModal] = useState<boolean>(false);
  const [invalidAadhaarModal, setInvalidAadhaarModal] = useState<boolean>(false);
  const [isAadhaarVerifPending, setIsAadhaarVerifPending] = useState<boolean>(false);
  const [isAadhaarUploadedDataValid, setIsAadhaarUploadedDataValid] = useState<boolean>(false);
  const params = useParams() as { applicantID: string };
  const { fetchApplicationData } = useApplicationHook()[1];
  const [tabHasFocus, setTabHasFocus] = useState(true);
  const coursePrice = state.applicationData?.batch_campus.batch.course_price || 0;
  const discount_percentage = state.applicationData?.discount_percentage || 0;
  const snackbar = useSnackBar();
  const isMobile = useBreakpointValue({ base: true, md: false });
  useEffect(() => {
    const hasFrontImage =
      aadhaarUploadedData.aadhaarFront || aadhaarUploadedData.aadhaarFrontCaptured;
    const hasBackImage = aadhaarUploadedData.aadhaarBack || aadhaarUploadedData.aadhaarBackCaptured;
    if (hasFrontImage && hasBackImage) {
      setIsAadhaarUploadedDataValid(true);
    } else {
      setIsAadhaarUploadedDataValid(false);
    }
  }, [aadhaarUploadedData]);
  const handleConfirmAadhaarDetails = async () => {
    setLoading(true);

    if (state.profileData?.slug && aadhaarOCRDetails && aadhaarOCRDetails.id) {
      try {
        const res = await confirmAadhaarDetails({
          ocr_aadhaar_info_entry_id: aadhaarOCRDetails.id,
          application_slug: params.applicantID,
        });
        if (state.profileData?.aadhaar_verified && state.applicationData?.docs_verified === false) {
          setIsAadhaarVerifPending(true);
        }
        setShowConfirmAadhaarModal(false);
        setIsAadhaarVerifPending(true);
        fetchApplicationData && fetchApplicationData(params.applicantID);
        // snackbar.info("Aadhaar Confirmation Done!");
      } catch (err) {
        const e = axiosErrorHandler(err);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("something went wrong");
        }
        console.error(err);
      }
    }
    setLoading(false);
  };

  const history = useHistory();
  const headingSize = useBreakpointValue<"h4" | "h2">({
    base: "h4",
    lg: "h2",
  });
  const getCustomCircleContent = () => {
    return (
      <Box mr={{ base: "-16px", sm: "0px" }} mt={{ base: "0px", lg: "ms-16" }} textAlign={"center"}>
        <Text display={{ base: "none", lg: "block" }} textStyle="btn-md">
          Scholarship price
        </Text>
        <Heading mt={{ base: "0px", lg: "ms-8" }} as={headingSize} color={"ms-blue.500"}>
          ₹ {Math.floor(coursePrice - (coursePrice * discount_percentage) / 100)}
        </Heading>
        <Text
          as="s"
          color={"ms-brick.500"}
          textStyle={{ base: "caption", lg: "body1" }}
          mt={{ base: "0px", lg: "ms-8" }}
        >
          ₹{coursePrice}
        </Text>
      </Box>
    );
  };
  useEffect(() => {
    if (tabHasFocus && isAadhaarVerifPending) {
      fetchApplicationData && fetchApplicationData(params.applicantID);
    }
  }, [tabHasFocus]);
  useEffect(() => {
    if (state.profileData?.aadhaar_verified && state.applicationData?.docs_verified) {
      setShowVerificationDoneModal(true);
      setIsAadhaarVerifPending(false);
    } else if (
      state.profileData?.aadhaar_verified &&
      state.applicationData?.docs_verified === false
    ) {
      setIsAadhaarVerifPending(true);
      setShowVerificationDoneModal(false);
    }
  }, [state.profileData?.aadhaar_verified, state.applicationData?.docs_verified]);
  const handleProceedToPayment = async () => {
    if (!state.applicationData?.slug) return;
    try {
      setLoading(true);
      await proceedToPaymentPending({ application_slug: state.applicationData?.slug });
    } catch (err) {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    } finally {
      setLoading(false);
      fetchApplicationData && fetchApplicationData(params.applicantID);
    }
  };
  useEffect(() => {
    fetchApplicationData && fetchApplicationData(params.applicantID);
    dispatch({
      type: ApplicationActionType.FETCH_PROFILE,
      payload: {},
    });
    const handleFocus = () => {
      setTabHasFocus(true);
    };
    const handleBlur = () => {
      setTabHasFocus(false);
    };
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);
  const scores =
    testType === "msft"
      ? state.applicationData?.scholar_result
      : state.applicationData?.coding_result;
  const handleAadhaarOcr = async () => {
    setLoading(true);

    const files = ["", ""];
    if (aadhaarUploadedData.aadhaarFrontCaptured) {
      files[0] = aadhaarUploadedData.aadhaarFrontCaptured;
    } else if (aadhaarUploadedData.aadhaarFront) {
      files[0] = aadhaarUploadedData.aadhaarFront;
    }
    if (aadhaarUploadedData.aadhaarBackCaptured) {
      files[1] = aadhaarUploadedData.aadhaarBackCaptured;
    } else if (aadhaarUploadedData.aadhaarBack) {
      files[1] = aadhaarUploadedData.aadhaarBack;
    }
    if (state.profileData?.slug) {
      setShowFetchingInfoModal(true);
      try {
        const res = await processAadhaarOCR({
          profile_slug: state.profileData?.slug,
          files: files,
          application_slug: params.applicantID,
          coc_isa_mbp_consent: true,
        });
        setAadhaarOCRDetails(res);
        setShowConfirmAadhaarModal(true);
        // snackbar.info("Aadhaar Verification Done!");
      } catch (err) {
        const e = axiosErrorHandler(err);
        if (typeof e === "object" && "message" in e) {
          if (e.message === "AADHAAR_NOT_RECOGNIZED" || e.message === "PARTIAL_INFORMATION_FOUND") {
            setInvalidAadhaarModal(true);
          } else snackbar.error(e.message);
        } else {
          snackbar.error("something went wrong");
        }
        console.error(err);
      }
      setShowFetchingInfoModal(false);
    }
    setLoading(false);
  };
  // id verification form data
  const formData: {
    key: number;
    name: string;
    label: string;
    required: boolean;
    placeholder: string;
    // type should be text for numbers also
    type: "text" | "password" | "email" | "url" | "tel" | "file";
    maxLegnth?: number; //
    minLegnth?: number; //
    helperText?: string;
    disabled?: boolean;
    numberOnly?: boolean;
  }[] = [
    {
      key: 1,
      name: "adhaar",
      label: "Aadhaar Number",
      required: true,
      placeholder: "Enter 12 digit Aadhaar number",
      type: "text",
      maxLegnth: 12,
      minLegnth: 12,
      disabled: true,
      helperText: "Aadhaar number should be exactly 12 digits.",
    },
    {
      key: 2,
      name: "address",
      label: "Address as per Aadhaar",
      required: true,
      placeholder: "Enter your address",
      type: "text",
      disabled: true,
    },
  ];
  return (
    <>
      <Box p={"ms-24"}>
        <Box>
          {testType === "msft" && discount_percentage > 0 ? (
            <Banner
              hideLottie={true}
              customCirleContent={getCustomCircleContent()}
              type="pending"
              text={
                <Text textStyle={"body2"}>
                  Based on your performance in the Prepleaf Scholarship Test, you have received a
                  discount of{" "}
                  <Text d={"inline"} textStyle={"body2-md"} color={"ms-error"}>
                    {`Flat ${discount_percentage || 0}%`}
                  </Text>{" "}
                  over the introductory price.
                </Text>
              }
              title={"Hurray!!🤩"}
              onShareClick={() => {
                //
              }}
            />
          ) : (
            <Banner
              customWidth={["60%", "50%", "65%"]}
              hideLottie={true}
              customCirleContent={getCustomCircleContent()}
              type="pending"
              text={
                <Text
                  maxW={{ base: "100%", sm: "70%", lg: "75%", "2xl": "100%" }}
                  textStyle={"body2"}
                >
                  Based on your performance in the Coding Test, you have received a scholarship of{" "}
                  <Text d={"inline"} textStyle={"body2-md"} color={"ms-error"}>
                    {`Flat ${discount_percentage || 0}%`}
                  </Text>{" "}
                  over the course fee. Complete the full payment in one go to avail the scholarship
                  and confirm your admission to Prepleaf (by Masai).
                </Text>
              }
              title={"Hurray!!🤩"}
              onShareClick={() => {
                //
              }}
            />
          )}
        </Box>
        <ScoreDetails scoreDetailsData={handleNewMsatScore(scores, testType)} />
      </Box>

      {/* Keeping the display as none for now as we dont want to do Id verif for scholar */}
      <Box display={"none"} px={"ms-24"}>
        <IdVerification
          isConsent={true}
          heading="ID Verification"
          aadhaarUploadedData={aadhaarUploadedData}
          setAadhaarUploadedData={setAadhaarUploadedData}
          handleConfirmAadhaarDetails={handleConfirmAadhaarDetails}
          aadhaarOCRDetails={aadhaarOCRDetails}
          showfetchingInfoModal={showfetchingInfoModal}
          setShowFetchingInfoModal={setShowFetchingInfoModal}
          showConfirmAadhaarModal={showConfirmAadhaarModal}
          setShowConfirmAadhaarModal={setShowConfirmAadhaarModal}
          invalidAadhaarModal={invalidAadhaarModal}
          setInvalidAadhaarModal={setInvalidAadhaarModal}
          formData={formData}
          onFormValuesChange={(formValues: { [key: string]: string }) => {
            // on this page ID verification form is disabled so no need of any logic here
          }}
        />
      </Box>

      <ModalTemplate
        title=""
        isOpen={showVerificationDoneModal}
        onClose={() => setShowVerificationDoneModal(false)}
        marginX={2}
        maxWidth="552px"
        showCloseButtonIcon={false}
        closeOnOverlayClick={false}
      >
        <Box my={"-16px"}>
          <VerificationSuccessModal
            btnText="Proceed to payment"
            onContinue={handleProceedToPayment}
            text={`Your Aadhaar is verified successfully. Complete the payment and confirm your admission to Masai.`}
          />
        </Box>
      </ModalTemplate>
      {/* Aadhaar verification pending modal */}
      <ModalTemplate
        title=""
        isOpen={isAadhaarVerifPending}
        onClose={() => {
          //
        }}
        marginX={2}
        maxWidth="484px"
      >
        <Box textAlign={"center"}>
          <InformationOutline color="ms-alert" boxSize="56px" />
          <Heading mt={"ms-16"} as="h4">
            Verification Pending
          </Heading>
          <Text mt={"ms-8"} textStyle={"body2"}>
            You will move forward only after our team has verified your profile. Verification could
            take 24 to 48 hours. Do reach out to us at{" "}
            <Text mt={"ms-8"} textStyle={"body2-md"} d={"inline"}>
              <Link
                cursor={"pointer"}
                isExternal={true}
                href="mailto:admissions@masaischool.com"
                color="ms-blue.500"
                textDecoration={"underline"}
              >
                admissions@masaischool.com
              </Link>{" "}
            </Text>
            for any queries.
          </Text>
          <Box></Box>
        </Box>
      </ModalTemplate>
      <BottomStickyNavbar
        btnPrimaryProps={{
          size: "lg",
          onClick: () => {
            sendTrackingEvent({ event: "SP_ctp_continue", eventLabel: location.pathname });
            handleProceedToPayment();
          },
        }}
        btnPrimary="Continue"
      />
    </>
  );
};

export default ScholarMSFTCodingSuccess;
