import { Box, Select, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import DateSelect from "../../common/DateSelect";
import dayjs from "dayjs";

import { OnboardingFormType } from "../Onboarding/OnboardingForm";
import { RadioButtonGroup } from "../../common/general";

interface Props {
  formValues: OnboardingFormType;
  setFormValues: React.Dispatch<React.SetStateAction<OnboardingFormType>>;
  gridTemplateColumns?: string;
  hideHeaderText?: boolean;
  queryType?: string;
  howDidYouHearAboutUs?: string;
  areYouComfortableVisitingCampus?: boolean;
}

const IITGRequestCallbackForm = ({ formValues, setFormValues }: Props) => {
  const [
    areYouComfortableVisitingCampus,
    setAreYouComfortableVisitingCampus,
  ] = React.useState<boolean>(true);

  const [preferredTiming, setPreferredTiming] = useState<string>("11 AM - 4 PM");

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFormValuesChange = (key: string, value?: any) => {
    if (key === "dob") {
      //convert date in this format 2000-07-05
      setFormValues((prev) => ({
        ...prev,
        [key]: value.format("YYYY-MM-DD"),
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  return (
    <>
      <Box>
        <Box display={"flex"} flexDirection={"column"} gap={{ md: "24px" }} mt={"ms-24"}>
          {/* Date of birth */}
          <Box display={"flex"} flexDirection={"column"}>
            <Text mb={{ base: "ms-8", md: "8px" }} textStyle="body2-md" color="ms-red.900">
              1 Enter your date of birth *
            </Text>
            <DateSelect
              showRelativeDate={true}
              onChange={(value) => {
                handleFormValuesChange("dob", value);
              }}
              value={formValues.dob ? dayjs(formValues.dob) : null}
              isInvalid={false}
              errorMessage="Please add your date of birth."
              onClickCallback={() => {
                //
              }}
              disabled={false}
              w={["100%", "100%", "100%", "100%"]}
            />
          </Box>

          {/* Query type */}
          <Box mt={"ms-16"}>
            <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
              2 Query Type *
            </Text>
            <Select
              name="queryType"
              value={formValues.queryType}
              onChange={(event) => handleFormValuesChange(event.target.name, event.target.value)}
            >
              <option value="" disabled selected>
                Select your query type
              </option>
              <option value={"Admission Related"}>Admission Related</option>
              <option value={"Course Related"}>Course Related</option>
              <option value={"Fees Related"}>Fees Related</option>
            </Select>
          </Box>

          {/* How did you hear about the program? */}
          <Box mt={"ms-16"}>
            <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
              3 How did you hear about the program?*
            </Text>
            <Select
              name="howDidYouHearAboutUs"
              value={formValues.howDidYouHearAboutUs}
              onChange={(event) => handleFormValuesChange(event.target.name, event.target.value)}
            >
              <option value="" disabled selected>
                Select source
              </option>
              <option value={"YouTube"}>YouTube</option>
              <option value={"LinkedIn"}>LinkedIn</option>
              <option value={"Instagram"}>Instagram</option>
              <option value={"Webinar"}>Webinar</option>
              <option value={"Friends and Family"}>Friends and Family</option>
              <option value={"Others"}>Others</option>
            </Select>
          </Box>

          {/* Are you comfortable in visiting IITG Campus?* */}
          <Box mt={{ base: "ms-16", md: "0px" }}>
            <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
              4 Are you comfortable in visiting IITG Campus?*
            </Text>
            <RadioButtonGroup
              options={[
                { key: "yes", value: "Yes" },
                { key: "no", value: "No" },
              ]}
              selectedValue={!areYouComfortableVisitingCampus ? "no" : "yes"}
              onChange={(key) => {
                setAreYouComfortableVisitingCampus(key === "yes" ? true : false);
                handleFormValuesChange(
                  "areYouComfortableVisitingCampus",
                  key === "yes" ? "Yes" : "No"
                );
              }}
              gridTemplateComlumns={"repeat(3,1fr)"}
              columnGap={"ms-4"}
              rowGap={"ms-24"}
              disabled={false}
            />
          </Box>

          {/* Preferred Time */}
          <Box mt={{ base: "ms-16", md: "0px" }}>
            <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
              5 Preferred Timings to contact you*
            </Text>
            <RadioButtonGroup
              options={[
                { key: "11 AM - 4 PM", value: "11 AM - 4 PM" },
                { key: "4 PM - 9 PM", value: "4 PM - 9 PM" },
              ]}
              selectedValue={preferredTiming}
              onChange={(key) => {
                setPreferredTiming(key);
                handleFormValuesChange("preferredTiming", key);
              }}
              gridTemplateComlumns={"repeat(2,1fr)"}
              columnGap={"ms-4"}
              rowGap={"ms-24"}
              disabled={false}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default IITGRequestCallbackForm;
