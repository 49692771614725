import { CheckCircle, ClockCircleOutline } from "../../../../assets/icons/components";
import InProgressStepperIcon from "../../../../assets/icons/components/InProgressStepperIcon";
import { StatusTypes } from "./types";

export const getColors = (state: StatusTypes) => {
  switch (state) {
    case "in-progress":
      return {
        iconColor: "ms-blue.500",
        stepperColor: "ms-blue.200",
        badge: "blue",
        icon: InProgressStepperIcon,
        descriptionColor: "ms-grey.600",
        badgeText: "In Progress",
        cursor: "pointer",
      };
    case "completed":
      return {
        iconColor: "ms-success",
        stepperColor: "ms-success",
        badge: "green",
        icon: CheckCircle,
        descriptionColor: "ms-grey.600",
        badgeText: "Completed",
        cursor: "pointer",
      };
    case "pending":
      return {
        iconColor: "ms-brick.300",
        stepperColor: "ms-brick.100",
        badge: "brick",
        icon: ClockCircleOutline,
        descriptionColor: "ms-grey.600",
        badgeText: "Pending",
        cursor: undefined,
      };
  }
};
