import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
  isActive?: boolean;
}

const CurriculumTab = (props: Props) => {
  return (
    <Icon viewBox={props.isActive ? "0 0 24 24" : "0 0 24 24"} {...props}>
      {props?.isActive ? (
        <>
          <path
            d="M21 4.5H15C14.4178 4.5 13.8437 4.63554 13.3229 4.8959C12.8022 5.15625 12.3493 5.53426 12 6C11.6507 5.53426 11.1978 5.15625 10.6771 4.8959C10.1563 4.63554 9.58217 4.5 9 4.5H3C2.60218 4.5 2.22064 4.65804 1.93934 4.93934C1.65804 5.22064 1.5 5.60218 1.5 6V18C1.5 18.3978 1.65804 18.7794 1.93934 19.0607C2.22064 19.342 2.60218 19.5 3 19.5H9C9.59674 19.5 10.169 19.7371 10.591 20.159C11.0129 20.581 11.25 21.1533 11.25 21.75C11.25 21.9489 11.329 22.1397 11.4697 22.2803C11.6103 22.421 11.8011 22.5 12 22.5C12.1989 22.5 12.3897 22.421 12.5303 22.2803C12.671 22.1397 12.75 21.9489 12.75 21.75C12.75 21.1533 12.9871 20.581 13.409 20.159C13.831 19.7371 14.4033 19.5 15 19.5H21C21.3978 19.5 21.7794 19.342 22.0607 19.0607C22.342 18.7794 22.5 18.3978 22.5 18V6C22.5 5.60218 22.342 5.22064 22.0607 4.93934C21.7794 4.65804 21.3978 4.5 21 4.5ZM9 18H3V6H9C9.59674 6 10.169 6.23705 10.591 6.65901C11.0129 7.08097 11.25 7.65326 11.25 8.25V18.75C10.6015 18.262 9.8116 17.9987 9 18ZM21 18H15C14.1884 17.9987 13.3985 18.262 12.75 18.75V8.25C12.75 7.65326 12.9871 7.08097 13.409 6.65901C13.831 6.23705 14.4033 6 15 6H21V18Z"
            fill="#3470E4"
          />
        </>
      ) : (
        <>
          <path
            d="M21 4.5H15C14.4178 4.5 13.8437 4.63554 13.3229 4.8959C12.8022 5.15625 12.3493 5.53426 12 6C11.6507 5.53426 11.1978 5.15625 10.6771 4.8959C10.1563 4.63554 9.58217 4.5 9 4.5H3C2.60218 4.5 2.22064 4.65804 1.93934 4.93934C1.65804 5.22064 1.5 5.60218 1.5 6V18C1.5 18.3978 1.65804 18.7794 1.93934 19.0607C2.22064 19.342 2.60218 19.5 3 19.5H9C9.59674 19.5 10.169 19.7371 10.591 20.159C11.0129 20.581 11.25 21.1533 11.25 21.75C11.25 21.9489 11.329 22.1397 11.4697 22.2803C11.6103 22.421 11.8011 22.5 12 22.5C12.1989 22.5 12.3897 22.421 12.5303 22.2803C12.671 22.1397 12.75 21.9489 12.75 21.75C12.75 21.1533 12.9871 20.581 13.409 20.159C13.831 19.7371 14.4033 19.5 15 19.5H21C21.3978 19.5 21.7794 19.342 22.0607 19.0607C22.342 18.7794 22.5 18.3978 22.5 18V6C22.5 5.60218 22.342 5.22064 22.0607 4.93934C21.7794 4.65804 21.3978 4.5 21 4.5ZM9 18H3V6H9C9.59674 6 10.169 6.23705 10.591 6.65901C11.0129 7.08097 11.25 7.65326 11.25 8.25V18.75C10.6015 18.262 9.8116 17.9987 9 18ZM21 18H15C14.1884 17.9987 13.3985 18.262 12.75 18.75V8.25C12.75 7.65326 12.9871 7.08097 13.409 6.65901C13.831 6.23705 14.4033 6 15 6H21V18Z"
            fill="black"
          />
        </>
      )}
    </Icon>
  );
};

export default CurriculumTab;
