import { Box, Link, Text, Image } from "@chakra-ui/react";
import React, { useContext } from "react";
import { FileDocument } from "../../../assets/icons/components";
import { CheckList } from "../../../assets/images";
import PlayboxOutline from "../../../assets/icons/components/Others/PlayBoxOutline";
import useCleverTap, {
  CleverTapEvents,
  ModalNames,
  ScreenNames,
} from "../../../clevertap/useCleverTap";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { ModalTemplate } from "../../../components/common/general";
import CheckboxGroup from "../../../components/common/general/CheckboxGroup";
import YoutubeCard from "../../../components/msat/msat-instructions/YoutubeCard";
import { sendTrackingEvent } from "../../../components/common/utils/gtm";
import { AppContext } from "../../../context/ApplicationContext";
import TnCDrawer from "./TnCDrawer";
interface Props {
  selectedValuesIsaConsent: (string | number)[];
  setSelectedValuesIsaConsent: (value: (string | number)[]) => void;
  selectedValuesHealthConsent: (string | number)[];
  setSelectedValuesHealthConsent: (value: (string | number)[]) => void;
  selectedValuesScholarshipConsent: (string | number)[];
  setSelectedValuesScholarshipConsent: (value: (string | number)[]) => void;
  consentDocsUrls: {
    coc_doc?: string;
    isa_doc?: string;
    mbp_doc?: string;
  } | null;
  isMFP?: boolean;
  type?: string;
}
const ConsentCheck = ({
  selectedValuesIsaConsent,
  setSelectedValuesIsaConsent,
  selectedValuesHealthConsent,
  setSelectedValuesHealthConsent,
  selectedValuesScholarshipConsent,
  setSelectedValuesScholarshipConsent,
  consentDocsUrls,
  type,
  isMFP,
}: Props) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = React.useState(false);
  const [isTnCDrawerOpen, setIsTnCDrawerOpen] = React.useState(false);
  const clevertap = useCleverTap()[0];
  const location = useLocation();
  React.useEffect(() => {
    if (isVideoModalOpen) {
      clevertap.fireEvent(CleverTapEvents.viewed_modal, {
        modal_name: ModalNames.isaVideo,
      });
    }
  }, [isVideoModalOpen]);
  const state = useContext(AppContext)[0];
  const batch_id = state.applicationData?.batch_campus.batch_id;
  const eventLabel = location.pathname;
  return (
    <>
      <Box>
        {/* ISA Consent */}

        <Box d={"flex"}>
          <CheckboxGroup
            options={[{ label: "", value: "consent-isa" }]}
            handleChange={(values) => {
              clevertap.fireEvent(CleverTapEvents.click_checkbox, {
                screen_name: isMFP ? ScreenNames.mfpSuccess : ScreenNames.msatSuccess,
                selected: values.includes("consent-isa") ? "yes" : "no",
              });
              sendTrackingEvent({
                event: values.includes("consent-isa")
                  ? "tnc-checkbox-1-checked"
                  : "tnc-checkbox-1-unchecked",
                eventLabel,
              });

              setSelectedValuesIsaConsent([...values]);
            }}
            selectedValues={selectedValuesIsaConsent}
            spacingY="ms-16"
            spacingX="ms-8"
            justify="start"
          />
          <Text textStyle={"body2"} color={"ms-grey.700"} ml={"ms-8"} mt={"-4px"}>
            I hereby agree and undertake that I have read and understood the terms and conditions as
            stated in the PAP, Masai CoC and MBP which can be accessed below and reaffirm my
            acceptance. <br />
            <Box d={"flex"} mt={"ms-4"} flexDirection={{ base: "column", md: "row" }}>
              <Box d={"flex"} alignItems={"center"} alignContent={"center"}>
                <FileDocument color={"ms-blue.500"} boxSize={"24px"} />
                <Text
                  textDecoration={"underline"}
                  textStyle={"body2-md"}
                  ml={"ms-8"}
                  color="ms-blue.500"
                  onClick={() => {
                    sendTrackingEvent({
                      event: "d _onboarding_view_agreement",
                      eventLabel,
                    });
                  }}
                >
                  <Link
                    isExternal
                    href={
                      "https://masai-website-images.s3.ap-south-1.amazonaws.com/PAP+-+Final+Version+-NJ_30th+June+2023+%2C+July+Batch+.pdf"
                    }
                  >
                    View the agreement & related documents.{" "}
                  </Link>
                </Text>
              </Box>
              <Box
                ml={{ md: "ms-32" }}
                mt={{ base: "ms-8", md: "0" }}
                mb={{ base: "ms-16", md: "0" }}
                d={"none"}
                alignItems={"center"}
                alignContent={"center"}
              >
                <PlayboxOutline boxSize="24px" color={"ms-blue.500"} />
                <Text textDecoration={"underline"} ml={"ms-8"} textStyle={"body2-md"}>
                  <Link
                    onClick={() => {
                      if (location.pathname.includes("msat-success")) {
                        sendTrackingEvent({
                          event: type === "masaix" ? "mx_avp_isa_video" : "ISA_video_MP",
                          eventLabel: `/batch/${batch_id}/msat-success`,
                        });
                      }
                      setIsVideoModalOpen(true);
                    }}
                    color="ms-blue.500"
                  >
                    View a short video on PAP.
                  </Link>
                </Text>
              </Box>
              {/* Terms and condition */}
              <Box
                ml={{ md: "ms-32" }}
                mt={{ base: "ms-8", md: "0" }}
                mb={{ base: "ms-16", md: "0" }}
                d={"flex"}
                alignItems={"center"}
                alignContent={"center"}
              >
                <Image src={CheckList} />
                <Text textDecoration={"underline"} ml={"ms-8"} textStyle={"body2-md"}>
                  <Link
                    onClick={() => {
                      if (location.pathname.includes("msat-success")) {
                        sendTrackingEvent({
                          event: "d_onboarding_view_conditions",
                          eventLabel,
                        });
                      }
                      setIsTnCDrawerOpen(true);
                    }}
                    color="ms-blue.500"
                  >
                    View Mandatory Conditions.
                  </Link>
                </Text>
              </Box>
            </Box>
          </Text>
        </Box>
        {/* Mental and Physical Consent */}
        <Box d={"flex"} mt={"ms-8"}>
          <CheckboxGroup
            options={[{ label: "", value: "consent-health" }]}
            handleChange={(values) => {
              clevertap.fireEvent(CleverTapEvents.click_checkbox, {
                screen_name: isMFP ? ScreenNames.mfpSuccess : ScreenNames.msatSuccess,
                selected: values.includes("consent-health") ? "yes" : "no",
              });
              sendTrackingEvent({
                event: values.includes("consent-health")
                  ? "tnc-checkbox-2-checked"
                  : "tnc-checkbox-2-unchecked",
                eventLabel,
              });
              setSelectedValuesHealthConsent([...values]);
            }}
            selectedValues={selectedValuesHealthConsent}
            spacingY="ms-16"
            spacingX="ms-8"
            justify="start"
          />
          <Text textStyle={"body2"} color={"ms-grey.700"} ml={"ms-8"} mt={"-4px"}>
            I acknowledge that Masai is an intensive practice based learning experience and I am
            mentally and physically of sound state to complete the course. <br />
          </Text>
        </Box>
        {/* Scholarship Consent */}
        {/* <Box d={"flex"} mt={"ms-8"}>
          <CheckboxGroup
            options={[{ label: "", value: "scholarship" }]}
            handleChange={(values) => {
              sendTrackingEvent({
                event: values.includes("scholarship")
                  ? "tnc-checkbox-3-checked"
                  : "tnc-checkbox-3-unchecked",
                eventLabel,
              });
              setSelectedValuesScholarshipConsent([...values]);
            }}
            selectedValues={selectedValuesScholarshipConsent}
            spacingY="ms-16"
            spacingX="ms-8"
            justify="start"
          />
          <Text textStyle={"body2"} color={"ms-grey.700"} ml={"ms-8"} mt={"-4px"}>
            Apply for scholarship
            <Box d={"flex"} mt={"ms-4"} flexDirection={{ base: "column", md: "row" }}>
              <Box d={"flex"} alignItems={"center"} alignContent={"center"}>
                <FileDocument color={"ms-blue.500"} boxSize={"24px"} />
                <Text
                  textDecoration={"underline"}
                  textStyle={"body2-md"}
                  ml={"ms-8"}
                  color="ms-blue.500"
                  onClick={() => {
                    sendTrackingEvent({
                      event:
                        type === "masaix" ? "mx_scholarship_details_MP" : "scholarship_details",
                      eventLabel,
                    });
                  }}
                >
                  <Link href={"https://masaischool.com/scholarship/"} isExternal>
                    View Scholarship Details
                  </Link>
                </Text>
              </Box>
            </Box>
          </Text>
        </Box> */}
      </Box>
      <ModalTemplate
        title=""
        isOpen={isVideoModalOpen}
        onClose={() => {
          clevertap.fireEvent(CleverTapEvents.click_close_modal, {
            screen_name: isMFP ? ScreenNames.mfpSuccess : ScreenNames.msatSuccess,
            modal_name: ModalNames.isaVideo,
          });
          setIsVideoModalOpen(false);
        }}
        marginX={2}
        maxWidth="552px"
        showCloseButtonIcon
      >
        <Box
          onClick={() => {
            clevertap.fireEvent(CleverTapEvents.click_play_video, {
              screen_name: isMFP ? ScreenNames.mfpSuccess : ScreenNames.msatSuccess,
              screen_position: "Watch a short video on PAP.",
            });
          }}
        >
          <YoutubeCard url="" heading="Watch a short video on PAP." height={"300px"} />
        </Box>
      </ModalTemplate>
      <TnCDrawer isOpen={isTnCDrawerOpen} setIsOpen={setIsTnCDrawerOpen} />
    </>
  );
};

export default ConsentCheck;
