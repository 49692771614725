import { Box, Heading, Text, Button } from "@chakra-ui/react";
import React from "react";
import Lottie from "react-lottie-player";
import successLottie from "../../assets/successLottie.json";
interface Props {
  setShowParticipateModal: (show: boolean) => void;
  contestName: string;
}
const ParticipateModalContent = ({ setShowParticipateModal, contestName }: Props) => {
  return (
    <Box textAlign={"center"} pt={"ms-24"}>
      <Box
        m={"auto"}
        mb={{ base: "20px", md: "ms-32" }}
        w={{ base: "97px", md: "112px" }}
        h={{ base: "104px", md: "120px" }}
      >
        <Lottie loop animationData={successLottie} play style={{ width: "100%" }} />
      </Box>
      <Heading mb={"ms-8"} as="h3">
        Registration Successful!
      </Heading>
      <Text textStyle="body1" color="ms-grey.700" mb={"ms-24"}>
        You have successfully registered for the {contestName}.
      </Text>

      <Button
        onClick={() => {
          setShowParticipateModal && setShowParticipateModal(false);
        }}
        size="md"
        variant="primary"
      >
        Close
      </Button>
    </Box>
  );
};

export default ParticipateModalContent;
