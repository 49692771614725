import { Box, Button, Container, Flex } from "@chakra-ui/react";
import React, { Component, Fragment, ReactNode } from "react";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, SwiperOptions, Mousewheel } from "swiper";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

import { AutoplayOptions, NavigationOptions, PaginationOptions } from "swiper/types";
import { ChevronLeft, ChevronRight } from "../../../../assets/icons/components";
import { sendTrackingEvent } from "../../utils/gtm";
import { useLocation } from "react-router-dom";

interface CarouselProps {
  pagination?: boolean;
  children: ReactNode | Component | ReactNode[] | Component[];
  breakpoints?: {
    [width: number]: SwiperOptions;
    [ratio: string]: SwiperOptions;
  };
  customButton?: ReactNode;
  isContainer?: boolean;
  hasButton?: boolean;
  gap?: { base: number; md: number };
  itemsPerRow?: {
    base: number | "data";
    md: number | "data";
    lg: number | "data";
  };
  numberOfRows?: {
    base: number | "data";
    md: number | "data";
    lg: number | "data";
  };
  px?: { base: string | number; md: string | number } | string;
  customPagination?: boolean | PaginationOptions | undefined;
  loop?: boolean;
  autoplay?: boolean | AutoplayOptions | undefined;
  customNavigation?: boolean | NavigationOptions | undefined;
  swiperProps?: SwiperProps;
  nextBtnClass?: string;
  prevBtnClass?: string;
}

const CustomSlider = ({
  pagination = true,
  children = [],
  breakpoints = {},
  hasButton = true,
  isContainer = true,
  customButton = null,
  px = { base: 0, md: 4 },
  customPagination,
  autoplay,
  loop = false,
  customNavigation,
  swiperProps,
  nextBtnClass,
  prevBtnClass,
}: CarouselProps) => {
  const childrens = children;
  const location = useLocation();
  return (
    <Container
      maxW={isContainer ? "container.xl" : "full"}
      px={px}
      overflowX="hidden"
      width="100%"
      sx={{
        ".swiper-button-lock": {
          display: "none",
        },
      }}
    >
      <Box
        display={{ base: "block", md: "block" }}
        sx={{
          ".swiper-pagination-bullet-active": {
            background: "ms-red.500",
          },
          ".swiper-slide": {
            height: "auto !important",
          },
        }}
      >
        <Swiper
          modules={pagination ? [Autoplay, Pagination, Navigation, Mousewheel] : [Autoplay, Navigation, Mousewheel]}
          pagination={
            customPagination
              ? customPagination
              : {
                  clickable: true,
                  dynamicBullets: true,
                  dynamicMainBullets: 4,
                }
          }
          spaceBetween={0}
          autoplay={autoplay ? autoplay : false}
          direction="horizontal"
          updateOnWindowResize={true}
          breakpoints={breakpoints}
          navigation={
            customNavigation
              ? customNavigation
              : {
                  nextEl: ".swiper-button-next1",
                  prevEl: ".swiper-button-prev1",
                }
          }
          mousewheel={{
            sensitivity: 1,
            forceToAxis: true,
          }}
          observer={true}
          observeParents={true}
          loop={loop}
          {...swiperProps}
        >
          {Array.isArray(childrens) ? (
            childrens.map((child, index: number) => (
              <Box pl={index === 0 ? 4 : 0} key={index}>
                <SwiperSlide style={{ height: "auto !important" }} key={index}>
                  {child}
                </SwiperSlide>
              </Box>
            ))
          ) : (
            <Box pl={4}>
              <SwiperSlide style={{ height: "auto !important" }}>{childrens}</SwiperSlide>
            </Box>
          )}
        </Swiper>
      </Box>
      <Box pt={{ base: 0, md: 4 }}>
        {hasButton ? (
          customButton ? (
            <Flex justifyContent={{ base: "flex-end", md: "center" }}>{customButton}</Flex>
          ) : (
            <Flex justifyContent="flex-end" gap="8px" p="16px">
              <div>
                <Button
                  onClick={() => {
                    sendTrackingEvent({
                      event: "d_courses_instructors_back",
                      eventLabel: location.pathname,
                    });
                  }}
                  className={prevBtnClass || "swiper-button-prev1"}
                  p="0px !important"
                  h="32px !important"
                  w="32px !important"
                  minW="0px !important"
                  variant="outline"
                  colorScheme="blue"
                  rounded="6px"
                >
                  <ChevronLeft boxSize="1.5em" />
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => {
                    sendTrackingEvent({
                      event: "d_courses_instructors_next",
                      eventLabel: location.pathname,
                    });
                  }}
                  className={nextBtnClass || "swiper-button-next1"}
                  p="0px !important"
                  h="32px !important"
                  w="32px !important"
                  minW="0px !important"
                  variant="outline"
                  colorScheme="blue"
                  rounded="6px"
                >
                  <ChevronRight boxSize="1.5em" />
                </Button>
              </div>
            </Flex>
          )
        ) : (
          <Fragment />
        )}
      </Box>
    </Container>
  );
};

export default CustomSlider;
