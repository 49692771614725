import { Box, Text } from "@chakra-ui/react";
import EmpowerHerVerifyUser from "./EmpowerHerVerifyUser";

const EmpowerHerVerifyUserInfo = () => {
  return (
    <Box>
      <Text as="h2" textStyle="h2">
        Verify User as EmpowerHer
      </Text>

      <EmpowerHerVerifyUser />
    </Box>
  );
};

export default EmpowerHerVerifyUserInfo;
