import {
  Badge,
  Box,
  Image,
  Heading,
  HStack,
  Button,
  Text,
  Spinner,
  Link as ChakraLink,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import PrepleafLogo from "../../../../assets/images/CoursePrograms/prepleaf-logo.svg";

import CourseCardTag from "../../CourseCardTag";
import { Link, useHistory, useLocation } from "react-router-dom";

import { AppContext } from "../../../../context/ApplicationContext";
import EmailVerifyModal from "../../../../components/common/general/EmailVerifyModal/EmailVerifyModal";
import useSnackBar from "../../../../components/common/general/SnackBar";
import { axiosErrorHandler } from "../../../../api/utils/error";
import { createApplication } from "../../../../api/utils/api";
import {
  ApplicationStatus,
  GetApplicationType,
  GetUnappliedBatch,
} from "../../../../api/schemas/schema";
import MasaiXCourseImage1 from "../../../../assets/images/CoursePrograms/MasaiXCourseImage1.svg";
import MasaiXCourseImage2 from "../../../../assets/images/CoursePrograms/MasaiXCourseImage2.svg";
import MasaiXCourseImage3 from "../../../../assets/images/CoursePrograms/MasaiXCourseImage3.svg";
import MasaiOneCourseImage1 from "../../../../assets/images/CoursePrograms/MasaiOneCourseImage1.svg";
import CAPCourseImage from "../../../../assets/images/CoursePrograms/CAPCourseImage.svg";
import CAPDataCourseImage from "../../../../assets/images/CoursePrograms/CAPDataCourseImage.svg";
import NewBadgeRightGif from "../../../../assets/images/CoursePrograms/NewBadgeRight.gif";

import MasaiOneCourseImage2 from "../../../../assets/images/CoursePrograms/MasaiOneCourseImage2.svg";
import MasaiOneCourseImage3 from "../../../../assets/images/CoursePrograms/MasaiOneCourseImage3.svg";
import ScholarCourseImage1 from "../../../../assets/images/CoursePrograms/ScholarCourseImage1.svg";
import ScholarCourseImage2 from "../../../../assets/images/CoursePrograms/ScholarCourseImage2.svg";
import ScholarCourseImage3 from "../../../../assets/images/CoursePrograms/ScholarCourseImage3.svg";
import {
  BlinkOuterGreen,
  // BriefcaseOutline,
  CalendarIcon,
  ClockOutline,
  // CardAccount,
  IndianRupee,
  // SchoolOutline,
  // TagOutline,
} from "../../../../assets/icons/components";
import dayjs from "dayjs";
import { ModalTemplate } from "../../../../components/common/general";
import CourseRestrictModalContent from "../../CourseRestrictModalContent";
import gtag_report_conversion from "../../../../components/common/utils/ads-tracking";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import PeopleSpeak from "../../../../assets/icons/components/Others/PeopleSpeak";
import { CheckIcon } from "@chakra-ui/icons";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { isCourseProgramsIntersectsProfilePrograms } from "../../../../components/common/utils/isProgramPresent";

interface Props {
  applicationID?: string;
  batchData: GetUnappliedBatch;
  applicationStatus?: ApplicationStatus;
  loading?: boolean;
  application?: GetApplicationType;
  fromHomePage?: boolean;
  autoApplyCourseApplication?: (autoApplyCourseId: number) => void;
}
const ScholarMasaiXCourseCard = ({
  applicationID,
  batchData,
  applicationStatus,
  loading,
  autoApplyCourseApplication,
  fromHomePage,
}: Props) => {
  // const { image, tag, badge1, badge2, badge3, header1, header2, points } = newCourse;
  const state = useContext(AppContext)[0];

  const startDate = dayjs(batchData?.start_date).format("DD MMM YYYY");

  const [showCourseRestrictModal, setShowCourseRestrictModal] = useState<boolean>(false);
  const [emailVerificationCourseId, setEmailVerificationCourseId] = useState<number | null>(null);
  const [message, setMessage] = useState<string>("");

  const programLevel: number = batchData?.course.level ?? 0;

  const [type, setType] = useState<string | "">("");
  const [finalApplicationStatus, setFinalApplicationStatus] = useState<ApplicationStatus | null>();

  useEffect(() => {
    if (!applicationStatus) {
      return;
    }
    if (
      applicationStatus === "APPLICATION_CLOSED" ||
      (dayjs().isAfter(batchData.application_end_date) &&
        ![
          "ONBOARDING_COMPLETE",
          "ONBOARDING_STARTED",
          "ONBOARDING_PENDING",
          "FELLOW",
          "FDN_FELLOW",
          "ISA_SUCCESSFUL",
          "ASYNC",
          "PAYMENT_SUCCESSFUL",
        ].includes(applicationStatus))
    ) {
      setFinalApplicationStatus("APPLICATION_CLOSED");
    } else {
      setFinalApplicationStatus(applicationStatus);
    }
  }, []);

  useEffect(() => {
    if (
      isCourseProgramsIntersectsProfilePrograms(batchData?.course?.course_programs, [
        {
          id: 1,
          name: "Scholar",
        },
      ])
    ) {
      setType("scholar");
    } else if (
      isCourseProgramsIntersectsProfilePrograms(batchData?.course?.course_programs, [
        {
          id: 2,
          name: "Masai_One",
        },
      ])
    ) {
      setType("masai_one");
    } else if (
      isCourseProgramsIntersectsProfilePrograms(batchData?.course?.course_programs, [
        {
          id: 3,
          name: "MasaiX",
        },
      ])
    ) {
      setType("masaix");
    } else if (
      isCourseProgramsIntersectsProfilePrograms(batchData?.course?.course_programs, [
        {
          id: 4,
          name: "CAP",
        },
      ])
    ) {
      setType("cap");
    }
  }, []);

  const course = batchData.course;

  const isIneligible =
    "ineligibility_reasons" in batchData &&
    batchData?.ineligibility_reasons &&
    Object.values(batchData.ineligibility_reasons).length > 0;
  // TODO : add program type logic here
  const tag =
    type === "scholar"
      ? {
          text: "Seats Filling Fast",
          color: "ms-red.500",
          bgColor: "ms-red.50",
        }
      : type === "masaix"
      ? {
          text: "zero upfront fee".toUpperCase(),
          color: "ms-purple.500",
          bgColor: "ms-purple.50",
        }
      : type === "cap"
      ? {
          text: "prepaid".toUpperCase(),
          color: "ms-purple.500",
          bgColor: "ms-purple.50",
        }
      : batchData?.tag;
  let badge1 = "";
  const badge2 = batchData?.course.course_type === "FULL_TIME" ? "Full Time" : "Part Time";
  let badge3 = "";
  // TODO : add program type logic here
  const header1 =
    type === "scholar" || type === "masaix"
      ? `Level ${programLevel}`
      : batchData?.course.name === "Software Development" && type === "masai_one"
      ? ""
      : ""; // course level
  let header2 = batchData?.course.name;
  // batchData?.course.name === "Software Development" && type === "masaix"
  //   ? "Software Development"
  //   : batchData?.course.name;

  switch (batchData?.course.name) {
    case "Software Development":
      header2 = type === "masaix" ? "Software Development" : batchData?.course.name;
      break;
    case "Career Accelerator Program":
      header2 = "CAP - Web Development";
      break;
    case "Career Accelerator Program - Data Science":
      header2 = "CAP - Data Science";
      break;
  }

  let image: string = ScholarCourseImage1;
  let ISA_CTC = "";
  // TODO : add program type logic here
  if (type === "scholar") {
    switch (batchData?.course.name) {
      case "Analytics 101":
        image = ScholarCourseImage1;
        badge1 = "1st year College";
        badge3 = "Requires No Background in Analytics";
        break;
      case "Programming 101":
        badge1 = "1st, 2nd & Pre-final year";
        badge3 = "Requires No Background in Coding";
        image = ScholarCourseImage2;
        break;
      case "Programming: Advanced":
        image = ScholarCourseImage3;
        badge1 = "1st, 2nd & Pre-final year";
        badge3 = "requires coding knowledge";
        break;
    }
  } else if (type === "masaix") {
    switch (batchData?.course.name) {
      case "Full Stack Development":
        badge1 = "working professionals";
        badge3 = "requires no coding experience";
        image = MasaiXCourseImage2;
        ISA_CTC = "5";
        break;
      case "Data Analytics":
        image = MasaiXCourseImage1;
        badge1 = "working professionals";
        badge3 = "requires No analytics experience";
        ISA_CTC = "5";
        break;
      case "Backend Development":
        image = MasaiXCourseImage3;
        badge1 = "working professionals";
        badge3 = "minimum 1 year of coding experience";
        ISA_CTC = "10";
        break;
    }
  } else if (type === "masai_one") {
    if (batchData?.course.course_designation === "SOFTWARE_DEVELOPMENT") {
      if (batchData?.course.course_type === "FULL_TIME") {
        badge1 = "Graduates";
        badge3 = "requires no coding experience";
        image = MasaiOneCourseImage1;
        ISA_CTC = "5";
      } else {
        image = MasaiOneCourseImage2;
        badge1 = "Final Year Students";
        badge3 = "requires no coding experience";
        ISA_CTC = "5";
      }
    } else {
      image = MasaiOneCourseImage3;
      badge1 = "Final Year Students";
      badge3 = "requires no analytics experience";
      ISA_CTC = "5";
    }
  } else if (type === "cap") {
    switch (batchData?.course.name) {
      case "Career Accelerator Program":
        badge1 = "open to all";
        // badge3 = "requires no coding experience";
        image = CAPCourseImage;
        break;
      case "Career Accelerator Program - Data Science":
        badge1 = "open to all";
        // badge3 = "requires no coding experience";
        image = CAPDataCourseImage;
        break;
    }
  }

  // const coursePrice = state.applicationData?.batch_campus.batch.course_price || 0;
  // TODO : add program type logic here
  const points =
    type === "scholar"
      ? [
          {
            icon: <CalendarIcon />,
            text:
              course.name !== "Programming: Advanced"
                ? `Course starts on ${startDate}`
                : "Course date will be announced soon",
          },
          {
            icon: <PeopleSpeak />,
            text: programLevel === 1 ? `Taught in Hinglish (Hindi, English)` : `Taught in English`,
          },
          {
            icon: <IndianRupee />,
            text:
              programLevel === 1
                ? `Pay ₹2,500 per month for 6 months`
                : `Pay ₹5000 per month for 6 months`,
          },
          {
            icon: <CheckIcon />,
            text: "Internship Opportunities",
          },
        ]
      : type === "cap"
      ? // TODO: For later use
        // ? fromHomePage
        //   ? [
        //       {
        //         icon: <CardAccount />,
        //         text: `Open to All`,
        //       },
        //       {
        //         icon: <SchoolOutline />,
        //         text: (
        //           <>
        //             <strong>12 Months</strong> Long Program
        //           </>
        //         ),
        //       },
        //       {
        //         icon: <BriefcaseOutline />,
        //         text: "Get Scholarship and Internship opportunity based on performance",
        //       },
        //       {
        //         icon: <CardAccount />,
        //         text: `Open to CAP Prep graduates`,
        //       },
        //       {
        //         icon: <SchoolOutline />,
        //         text: (
        //           <>
        //             <strong>6 Months</strong> Long Program
        //           </>
        //         ),
        //       },
        //       {
        //         icon: <BriefcaseOutline />,
        //         text: "Get a job of 5LPA after course completion",
        //       },
        //     ]
        // :
        [
          {
            icon: <BlinkOuterGreen />,
            iconProps: { color: "#8FE3B8" },
            text: `Course starts on ${startDate}`,
          },
          {
            icon: <ClockOutline />,
            text:
              batchData?.course.name === "Career Accelerator Program"
                ? "12 Months Long Program"
                : "11 Months Long Program",
          },
          {
            icon: <IndianRupee />,
            text: "Pay ₹5,000 to reserve your seat",
          },
        ]
      : [
          // { icon: <CalendarIcon />, text: `Course starts on ${startDate}` },

          { icon: <IndianRupee />, text: `Pay after placement of ${ISA_CTC} LPA or above` },
        ];

  const [showEmailVerifyModal, setShowEmailVerifyModal] = useState(false);
  const [isOTPModalOpen, setIsOTPModalOpen] = React.useState(false);
  const [showVerificationSuccessModal, setShowVerificationSuccessModal] = React.useState(false);
  const [showUpdateEmailModal, setShowUpdateEmailModal] = React.useState(false);
  const history = useHistory();
  const snackbar = useSnackBar();

  const handleApplyNow = async () => {
    // * for ads apply now - 14-2-22
    gtag_report_conversion("wwasCPzGnKEDEJLKzuMB");
    const batch_campus_id =
      batchData && batchData.batch_campuses && batchData?.batch_campuses.length >= 1
        ? batchData?.batch_campuses[0].id
        : null;
    if (!batch_campus_id) return;
    try {
      // create masaix or scholar application
      if (state.profileData?.slug) {
        await createApplication({
          profile_slug: state.profileData.slug,
          batch_campus_id: batch_campus_id, // TODO: change this to dynamic value
        }).then((res) => {
          // TODO : add program type logic here
          if (type === "scholar") {
            history.push(`/batch/${res.batch_campus.batch_id}/${res.slug}/instructions`); //TODO: change this to dynamic value
          }
          if (type === "cap") {
            history.push(`/batch/${res.batch_campus.batch_id}/${res.slug}/application-created`);
          } else {
            history.push(`/batch/${res.batch_campus.batch_id}/${res.slug}/applicant-details`); //TODO: change this to dynamic value
          }
          snackbar.success("Application created successfully!");
        });
      }
    } catch (err) {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        if (typeof e.message === "string" && e.message.includes("access-restricted,")) {
          setShowCourseRestrictModal(true);
          setMessage(e.message.split("access-restricted,")[1]);
        } else snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    }
  };
  const location = useLocation();
  const { trackEvent } = useMatomo();
  useEffect(() => {
    if (showVerificationSuccessModal && emailVerificationCourseId) {
      snackbar.success("Email verified successfully");
      setEmailVerificationCourseId(null);
      setShowVerificationSuccessModal(false);
      autoApplyCourseApplication && autoApplyCourseApplication(emailVerificationCourseId as number);
    }
  }, [showVerificationSuccessModal]);
  // const getUrlForRedirectionIfNeeded = (): string | null => {
  //   if (
  //     state.isScholar &&
  //     course.course_programs?.some((course_program) =>
  //       ["MasaiX", "Masai_One"].includes(course_program.program?.name || "")
  //     )
  //   ) {
  //     return (
  //       window.location.href.replace("prepleaf.com", "masaischool.com").split("?")[0] +
  //       `?autoApplyCourseId=${course.id}`
  //     );
  //   } else if (
  //     !state.isScholar &&
  //     course.course_programs?.some((course_program) =>
  //       ["Scholar", "CAP"].includes(course_program.program?.name || "")
  //     )
  //   ) {
  //     return (
  //       window.location.href.replace("masaischool.com", "prepleaf.com").split("#")[0] +
  //       `#/?autoApplyCourseId=${course.id}`
  //     );
  //   } else {
  //     return null;
  //   }
  // };

  return (
    <>
      <Box
        h="fit-content"
        height="100%"
        maxW="410px"
        w="full"
        d={"flex"}
        flexDirection={"column"}
        borderRadius="ms-24"
        border="ms-1"
        borderColor="ms-grey.200"
      >
        <Box position="relative" borderRadius="ms-16">
          <Image
            position={"absolute"}
            src={PrepleafLogo}
            w={"82px"}
            top={"ms-16"}
            right={"ms-16"}
            // TODO : add program type logic here
            d={type === "scholar" ? "block" : "none"}
          />
          <Image src={image} w={"100%"} />

          {type === "cap" && (
            <Image
              position="absolute"
              pointerEvents={"none"}
              w={"99px"}
              right={["-16px"]}
              top="-62px"
              src={NewBadgeRightGif}
              alt="badge"
            />
          )}

          {tag && (
            <Box position="absolute" top="-4px" left={["-24px"]}>
              <CourseCardTag
                text={typeof tag.text === "string" ? tag.text : "NA"}
                color={typeof tag.color === "string" ? tag.color : "ms-red.500"}
                bgColor={typeof tag.bgColor === "string" ? tag.bgColor : "ms-red.50"}
              />
            </Box>
          )}
        </Box>
        <Box h={"100%"} py="ms-20" px={"ms-16"} d={"flex"} flexDirection={"column"}>
          <Box d={"flex"} flexDirection={"column"}>
            <Box mb={"ms-4"}>
              {header1 && (
                <Heading color={"ms-grey.800"} mb={"ms-4"} as="h5">
                  {header1}
                </Heading>
              )}

              {header2 && (
                <Heading color={"ms-grey.900"} as="h3">
                  {header2}
                </Heading>
              )}
            </Box>

            <Box d="flex" flexWrap="wrap" mb="16px">
              {badge1 && (
                <Badge mr={"ms-8"} variant={"purple"} textStyle="h5" fontSize="18px">
                  {badge1}
                </Badge>
              )}
              {badge2 && (
                <Badge variant={"green"} textStyle="h5" fontSize="18px">
                  {" "}
                  {badge2}
                </Badge>
              )}
              {badge3 && (
                <Badge mt={"ms-8"} mr={"ms-8"} variant={"cyan"} fontSize="18px">
                  {badge3}
                </Badge>
              )}
            </Box>

            <hr />

            <Box my={"ms-16"} display={"grid"} rowGap={"ms-12"}>
              {points.map((point, index) => (
                <Box key={index} display={"flex"} alignItems={"center"}>
                  <Box alignSelf={"flex-start"}>
                    {point.icon &&
                      React.cloneElement(point.icon, {
                        boxSize: "20px",
                        color: "ms-grey.400",
                        ...point?.iconProps,
                      })}
                  </Box>
                  <Text ml={"ms-8"} textStyle="body2-md" color={"ms-grey.800"}>
                    {point.text}
                  </Text>
                </Box>
              ))}
            </Box>
          </Box>
          {/* TODO : add program type logic here */}
          <HStack justify="flex-end" spacing="ms-32" align="center" mt={"auto"}>
            <>
              {type === "masaix" && programLevel === 2 ? (
                <ChakraLink href={`https://masaischool.com/courses/backend-part-time/`} isExternal>
                  {finalApplicationStatus !== "APPLICATION_CLOSED" && (
                    <Button
                      variant="link"
                      size="md"
                      onClick={() => {
                        // dash-FTFSWD-view_details

                        const courseNameWords = course.name.split(" ");
                        let courseNameLetters = "";
                        courseNameWords.forEach((word) => (courseNameLetters += word[0]));
                        const courseType = course.course_type === "FULL_TIME" ? "FT" : "PT";
                        const eventName = `dash-${courseType}${courseNameLetters}-view_details`;
                        sendTrackingEvent({ event: eventName, eventLabel: location.pathname });
                      }}
                    >
                      view details
                    </Button>
                  )}
                </ChakraLink>
              ) : (
                <>
                  {batchData && (
                    <Link to={`/courses/${batchData.id}/details`}>
                      {finalApplicationStatus !== "APPLICATION_CLOSED" && (
                        <Button
                          variant="link"
                          size="md"
                          onClick={() => {
                            // dash-FTFSWD-view_details

                            const courseNameWords = course.name.split(" ");
                            let courseNameLetters = "";
                            courseNameWords.forEach((word) => (courseNameLetters += word[0]));
                            const courseType = course.course_type === "FULL_TIME" ? "FT" : "PT";
                            const eventName = `dash-${courseType}${courseNameLetters}-view_details`;
                            sendTrackingEvent({
                              event: eventName,
                              eventLabel: location.pathname,
                            });
                          }}
                        >
                          view details
                        </Button>
                      )}
                    </Link>
                  )}
                </>
              )}
            </>
            {finalApplicationStatus ? (
              <Box>
                <Link
                  to={
                    state.profileData?.email_verified
                      ? `/batch/${batchData?.id}/${applicationID}/instructions`
                      : "#"
                  }
                >
                  <Button
                    disabled={
                      isCourseProgramsIntersectsProfilePrograms(
                        course?.course_programs,
                        state.profileData?.programs
                      ) && finalApplicationStatus !== "APPLICATION_CLOSED"
                        ? false
                        : true
                    }
                    variant="primary"
                    size="md"
                    onClick={() => {
                      if (finalApplicationStatus === "APPLICATION_CLOSED") {
                        return;
                      }
                      // const urlForRedirection = getUrlForRedirectionIfNeeded();
                      // if (urlForRedirection) {
                      //   window.location.href = urlForRedirection;
                      // }
                      if (state.profileData?.email_verified) {
                        // TODO: send gtm event
                        const courseNameWords = course.name.split(" ");
                        let courseNameLetters = "";
                        courseNameWords.forEach((word) => (courseNameLetters += word[0]));
                        const courseType = course.course_type === "FULL_TIME" ? "FT" : "PT";
                        const eventName = `dash-${courseType}${courseNameLetters}-continue`;
                        sendTrackingEvent({ event: eventName, eventLabel: location.pathname });
                      } else {
                        if (state.profileData?.email) {
                          setShowEmailVerifyModal(true);
                        } else {
                          setShowUpdateEmailModal(true);
                        }
                        setEmailVerificationCourseId(course.id);
                      }
                    }}
                  >
                    {finalApplicationStatus === "APPLICATION_CLOSED"
                      ? "APPLICATION CLOSED"
                      : "continue"}
                  </Button>
                </Link>
              </Box>
            ) : (
              <Button
                position={loading ? "relative" : "static"}
                variant="primary"
                size="md"
                onClick={() => {
                  let source = undefined;
                  if (
                    isCourseProgramsIntersectsProfilePrograms(batchData?.course?.course_programs, [
                      {
                        id: 4,
                        name: "CAP",
                      },
                    ])
                  ) {
                    const isIneligiblePage = location.pathname.includes("ineligible");
                    const isApplicationClosedPage = location.pathname.includes(
                      "application-closed"
                    );
                    const isMsatFailedPage = location.pathname.includes("reject/msat");

                    source = isIneligiblePage
                      ? "ONWARD_INELIGIBLE_PAGE"
                      : isApplicationClosedPage
                      ? "ONWARD_APPLICATION_CLOSED_PAGE"
                      : isMsatFailedPage
                      ? "ONWARD_MSAT_FAILED_PAGE"
                      : undefined;
                  }
                  // const urlForRedirection = getUrlForRedirectionIfNeeded();
                  // if (urlForRedirection) {
                  //   window.location.href = urlForRedirection + (source ? `&source=${source}` : "");
                  // }
                  if (state.profileData?.email_verified) {
                    //TODO: send gtm event
                    const courseNameWords = course.name.split(" ");
                    let courseNameLetters = "";
                    courseNameWords.forEach((word) => (courseNameLetters += word[0]));
                    const courseType = course.course_type === "FULL_TIME" ? "FT" : "PT";
                    const eventName = `dash-${courseType}${courseNameLetters}-apply_now`;

                    trackEvent({
                      category: "Dashboard",
                      action: "Apply",
                      name: eventName,
                      value: 300,
                    });
                    sendTrackingEvent({ event: eventName, eventLabel: location.pathname });
                    handleApplyNow();
                  } else {
                    if (state.profileData?.email) {
                      setShowEmailVerifyModal(true);
                    } else {
                      setShowUpdateEmailModal(true);
                    }
                    setEmailVerificationCourseId(course.id);
                  }
                }}
                disabled={isIneligible || loading}
              >
                {isIneligible ? "ineligible" : type === "cap" ? "reserve seat" : "apply now"}
                {loading && (
                  <Spinner
                    speed={"0.5s"}
                    thickness={"2px"}
                    size={"md"}
                    position={"absolute"}
                    color={"ms-blue.500"}
                  />
                )}
              </Button>
            )}
          </HStack>
        </Box>
      </Box>
      {/* Course Restrict modal */}
      <ModalTemplate
        title=""
        isOpen={showCourseRestrictModal}
        onClose={() => {
          setShowCourseRestrictModal(false);
        }}
        marginX={2}
        maxWidth="484px"
        showCloseButtonIcon={true}
      >
        <CourseRestrictModalContent
          message={message}
          setShowCourseRestrictModal={setShowCourseRestrictModal}
        />
      </ModalTemplate>
    </>
  );
};

export default ScholarMasaiXCourseCard;

{
  /* TODO: Cap card new design For later use */
}
{
  /* {fromHomePage ? (
        <Box display="grid" gap="24px" gridTemplateColumns={{ base: "100%", xl: "50% 50%" }}>
          <Box
            bg="#FFFFFF"
            borderRadius="16px"
            position="relative"
            p={{ base: "16px 14px", md: "24px" }}
            flexBasis="100%"
            border="1px solid #D9D9D9"
            overflow="hidden"
            display="flex"
            w={{ base: "100%", lg: "552px" }}
            maxW="552px"
            flexDir="column"
            justifyContent="space-between"
            minW="343px"
          >
            <div>
              <Box style={{ borderRadius: "0px 0px 100px 100px" }}>
                <Image
                  loading="lazy"
                  className="h-full w-full scale-[7] pointer-events-none m-auto absolute top-[-6px]"
                  src="/img/homepage/hat_card.png"
                  alt="banner-image"
                  scale="7"
                  pointerEvents="none"
                  m="auto"
                  position="absolute"
                  top="-6px"
                  right="24px"
                />
              </Box>
              <Box display="flex" alignItems="center" gap="14px">
                <Heading
                  as="h3"
                  fontSize={{
                    base: "19px !important",
                    md: "24px !important",
                  }}
                  color="ms-brick.500"
                >
                  CAP Prep
                </Heading>
              </Box>

              {points.length > 0 &&
                points.slice(0, 3).map((info, infoIndex) => (
                  <Box
                    gap="8px"
                    display="flex"
                    key={infoIndex}
                    alignItems="start"
                    mt={infoIndex === 0 ? "10px" : "12px"}
                  >
                    {info.icon &&
                      React.cloneElement(info.icon, {
                        boxSize: "20px",
                        color: "#4358F6",
                        mt: "2px",
                      })}
                    <Text textStyle="body1-md" fontSize={{ base: "14px", md: "16px" }}>
                      {info.text}
                    </Text>
                  </Box>
                ))}

              <Text mt="12px" textStyle="body1" color="#544D4F">
                A comprehensive solution to be Job-ready. Build your Employability Quotient, and Ace
                the Interview to kick start your career.
              </Text>
            </div>

            {/* TODO : add program type logic here 
            <Box
              display="flex"
              flex="1"
              justify="flex-start"
              spacing="ms-32"
              align="flex-end"
              pt="16px"
            >
              <HStack justify="flex-start" spacing="ms-16" align="center" mt={"auto"}>
                <>
                  {type === "masaix" && programLevel === 2 ? (
                    <ChakraLink
                      href={`https://masaischool.com/courses/backend-part-time/`}
                      isExternal
                    >
                      {finalApplicationStatus !== "APPLICATION_CLOSED" && (
                        <Button
                          variant="primary"
                          size="md"
                          onClick={() => {
                            // dash-FTFSWD-view_details

                            const courseNameWords = course.name.split(" ");
                            let courseNameLetters = "";
                            courseNameWords.forEach((word) => (courseNameLetters += word[0]));
                            const courseType = course.course_type === "FULL_TIME" ? "FT" : "PT";
                            const eventName = `dash-${courseType}${courseNameLetters}-view_details`;
                            sendTrackingEvent({ event: eventName, eventLabel: location.pathname });
                          }}
                        >
                          Know More
                        </Button>
                      )}
                    </ChakraLink>
                  ) : (
                    <>
                      {batchData && (
                        <Link to={`/courses/${batchData.id}/details`}>
                          {finalApplicationStatus !== "APPLICATION_CLOSED" && (
                            <Button
                              variant="primary"
                              size="md"
                              onClick={() => {
                                // dash-FTFSWD-view_details

                                const courseNameWords = course.name.split(" ");
                                let courseNameLetters = "";
                                courseNameWords.forEach((word) => (courseNameLetters += word[0]));
                                const courseType = course.course_type === "FULL_TIME" ? "FT" : "PT";
                                const eventName = `dash-${courseType}${courseNameLetters}-view_details`;
                                sendTrackingEvent({
                                  event: eventName,
                                  eventLabel: location.pathname,
                                });
                              }}
                            >
                              Know More
                            </Button>
                          )}
                        </Link>
                      )}
                    </>
                  )}
                </>
                {finalApplicationStatus ? (
                  <Box>
                    <Link
                      to={
                        state.profileData?.email_verified
                          ? `/batch/${batchData?.id}/${applicationID}/instructions`
                          : "#"
                      }
                    >
                      <Button
                        disabled={
                          isCourseProgramsIntersectsProfilePrograms(
                            course?.course_programs,
                            state.profileData?.programs
                          ) && finalApplicationStatus !== "APPLICATION_CLOSED"
                            ? false
                            : true
                        }
                        variant="secondary"
                        size="md"
                        onClick={() => {
                          if (finalApplicationStatus === "APPLICATION_CLOSED") {
                            return;
                          }
                          // const urlForRedirection = getUrlForRedirectionIfNeeded();
                          // if (urlForRedirection) {
                          //   window.location.href = urlForRedirection;
                          // }
                          if (state.profileData?.email_verified) {
                            // TODO: send gtm event
                            const courseNameWords = course.name.split(" ");
                            let courseNameLetters = "";
                            courseNameWords.forEach((word) => (courseNameLetters += word[0]));
                            const courseType = course.course_type === "FULL_TIME" ? "FT" : "PT";
                            const eventName = `dash-${courseType}${courseNameLetters}-continue`;
                            sendTrackingEvent({ event: eventName, eventLabel: location.pathname });
                          } else {
                            if (state.profileData?.email) {
                              setShowEmailVerifyModal(true);
                            } else {
                              setShowUpdateEmailModal(true);
                            }
                            setEmailVerificationCourseId(course.id);
                          }
                        }}
                      >
                        {finalApplicationStatus === "APPLICATION_CLOSED"
                          ? "APPLICATION CLOSED"
                          : "continue"}
                      </Button>
                    </Link>
                  </Box>
                ) : (
                  <Button
                    position={loading ? "relative" : "static"}
                    variant="secondary"
                    size="md"
                    onClick={() => {
                      let source = undefined;
                      if (
                        isCourseProgramsIntersectsProfilePrograms(
                          batchData?.course?.course_programs,
                          [
                            {
                              id: 4,
                              name: "CAP",
                            },
                          ]
                        )
                      ) {
                        const isIneligiblePage = location.pathname.includes("ineligible");
                        const isApplicationClosedPage = location.pathname.includes(
                          "application-closed"
                        );
                        const isMsatFailedPage = location.pathname.includes("reject/msat");

                        source = isIneligiblePage
                          ? "ONWARD_INELIGIBLE_PAGE"
                          : isApplicationClosedPage
                          ? "ONWARD_APPLICATION_CLOSED_PAGE"
                          : isMsatFailedPage
                          ? "ONWARD_MSAT_FAILED_PAGE"
                          : undefined;
                      }
                      // const urlForRedirection = getUrlForRedirectionIfNeeded();
                      // if (urlForRedirection) {
                      //   window.location.href = urlForRedirection + (source ? `&source=${source}` : "");
                      // }
                      if (state.profileData?.email_verified) {
                        //TODO: send gtm event
                        const courseNameWords = course.name.split(" ");
                        let courseNameLetters = "";
                        courseNameWords.forEach((word) => (courseNameLetters += word[0]));
                        const courseType = course.course_type === "FULL_TIME" ? "FT" : "PT";
                        const eventName = `dash-${courseType}${courseNameLetters}-apply_now`;

                        trackEvent({
                          category: "Dashboard",
                          action: "Apply",
                          name: eventName,
                          value: 300,
                        });
                        sendTrackingEvent({ event: eventName, eventLabel: location.pathname });
                        handleApplyNow();
                      } else {
                        if (state.profileData?.email) {
                          setShowEmailVerifyModal(true);
                        } else {
                          setShowUpdateEmailModal(true);
                        }
                        setEmailVerificationCourseId(course.id);
                      }
                    }}
                    disabled={isIneligible || loading}
                  >
                    {isIneligible ? "ineligible" : type === "cap" ? "reserve seat" : "apply now"}
                    {loading && (
                      <Spinner
                        speed={"0.5s"}
                        thickness={"2px"}
                        size={"md"}
                        position={"absolute"}
                        color={"ms-blue.500"}
                      />
                    )}
                  </Button>
                )}
              </HStack>
            </Box>
          </Box>

          <Box
            bg="#FFFFFF"
            borderRadius="16px"
            position="relative"
            p={{ base: "16px 14px", md: "24px" }}
            flexBasis="100%"
            border="1px solid #D9D9D9"
            overflow="hidden"
            display="flex"
            w={{ base: "100%", lg: "552px" }}
            maxW="552px"
            flexDir="column"
            justifyContent="space-between"
            minW="343px"
          >
            <div>
              <Box style={{ borderRadius: "0px 0px 100px 100px" }}>
                <Image
                  loading="lazy"
                  className="h-full w-full scale-[7] pointer-events-none m-auto absolute top-[-6px]"
                  src="/img/homepage/rocket_card.svg"
                  alt="banner-image"
                  scale="7"
                  pointerEvents="none"
                  m="auto"
                  position="absolute"
                  top="-6px"
                  right="24px"
                />
              </Box>

              <Box display="flex" alignItems="center" gap="14px">
                <Heading
                  as="h3"
                  fontSize={{
                    base: "19px !important",
                    md: "24px !important",
                  }}
                  color="ms-cyan.500"
                >
                  CAP Ace
                </Heading>
              </Box>

              {points.length > 0 &&
                points.slice(3, 6).map((info, infoIndex) => (
                  <Box
                    gap="8px"
                    display="flex"
                    key={infoIndex}
                    alignItems="start"
                    mt={infoIndex === 0 ? "10px" : "12px"}
                  >
                    {info.icon &&
                      React.cloneElement(info.icon, {
                        boxSize: "20px",
                        color: "#4358F6",
                        mt: "2px",
                      })}
                    <Text textStyle="body1-md" fontSize={{ base: "14px", md: "16px" }}>
                      {info.text}
                    </Text>
                  </Box>
                ))}

              <Text mt="12px" textStyle="body1" color="#544D4F">
                No matter your academic background, the Career Accelerator Program is designed to
                train you to become a software developer from scratch. Get a job of 5LPA or more, or
                full fee refund.
              </Text>
            </div>

            {/* TODO : add program type logic here
            <Box
              display="flex"
              flex="1"
              justify="flex-start"
              spacing="ms-32"
              align="flex-end"
              pt="16px"
            >
              <HStack justify="flex-start" spacing="ms-16" align="center" mt={"auto"}>
                <>
                  {type === "masaix" && programLevel === 2 ? (
                    <ChakraLink
                      href={`https://masaischool.com/courses/backend-part-time/`}
                      isExternal
                    >
                      {finalApplicationStatus !== "APPLICATION_CLOSED" && (
                        <Button
                          variant="primary"
                          size="md"
                          onClick={() => {
                            // dash-FTFSWD-view_details

                            const courseNameWords = course.name.split(" ");
                            let courseNameLetters = "";
                            courseNameWords.forEach((word) => (courseNameLetters += word[0]));
                            const courseType = course.course_type === "FULL_TIME" ? "FT" : "PT";
                            const eventName = `dash-${courseType}${courseNameLetters}-view_details`;
                            sendTrackingEvent({ event: eventName, eventLabel: location.pathname });
                          }}
                        >
                          Know More
                        </Button>
                      )}
                    </ChakraLink>
                  ) : (
                    <>
                      {batchData && (
                        <Link to={`/courses/${batchData.id}/details`}>
                          {finalApplicationStatus !== "APPLICATION_CLOSED" && (
                            <Button
                              variant="primary"
                              size="md"
                              onClick={() => {
                                // dash-FTFSWD-view_details

                                const courseNameWords = course.name.split(" ");
                                let courseNameLetters = "";
                                courseNameWords.forEach((word) => (courseNameLetters += word[0]));
                                const courseType = course.course_type === "FULL_TIME" ? "FT" : "PT";
                                const eventName = `dash-${courseType}${courseNameLetters}-view_details`;
                                sendTrackingEvent({
                                  event: eventName,
                                  eventLabel: location.pathname,
                                });
                              }}
                            >
                              Know More
                            </Button>
                          )}
                        </Link>
                      )}
                    </>
                  )}
                </>
              </HStack>
            </Box>
          </Box>
        </Box>
      ) : (
      )} */
}
