import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Text } from "@chakra-ui/react";
import MSATSectionCards from "./MSATSectionCards";
import { getOrdinal } from "../../../utils/utils";
import { ProfileAssessment } from "../../../api/schemas/schema";

interface Props {
  bestAssessment?: ProfileAssessment;
  loadingbestAssessment?: boolean;
}
const MSATBothResults = ({ bestAssessment, loadingbestAssessment }: Props) => {
  return (
    <Box mt={{ base: "21px", md: "35px" }} px={{ base: "12px", xl: "28px" }} pb={{ base: "12px", md: "20px" }}>
      {bestAssessment && (
        <Accordion experimental_spaceY="16px" defaultIndex={[0]}>
          {!loadingbestAssessment &&
            bestAssessment?.all_results?.map((data, index) => {
              return (
                <AccordionItem key={index} rounded="12px" overflow="hidden" borderRadius={"8px"} border="1px solid #CCC" _expanded={{ bg: "red", border: "1px solid #3470E4 !important" }}>
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton
                        outline={"none"}
                        _focus={{
                          outline: "none",
                        }}
                        textAlign={"left"}
                        p="12px 12px"
                        display="flex"
                        justifyContent="space-between"
                        _hover={{ bg: isExpanded ? "#F2F6FF" : "white" }}
                        bg={isExpanded ? "#F2F6FF" : "inherit"}
                      >
                        <Text textAlign={"center"} textStyle={{ base: "body1-md", md: "h6" }} color="ms-grey.700">
                          {getOrdinal(index + 1)} Attempt Result
                        </Text>
                        <AccordionIcon />
                      </AccordionButton>

                      <AccordionPanel p="0px" pb={4} bg={isExpanded ? "#F2F6FF" : "inherit"}>
                        <Box borderRadius={"8px"}>
                          <Box p="12px" pt="0px">
                            {data?.result ? <MSATSectionCards result={data?.result} /> : null}
                          </Box>
                        </Box>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              );
            })}
        </Accordion>
      )}
    </Box>
  );
};

export default MSATBothResults;
