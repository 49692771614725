import React, { useContext, useState } from "react";
import { Box, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { InputCustom } from "../../../components/common/general";
import useCleverTap, { CleverTapEvents, ScreenNames } from "../../../clevertap/useCleverTap";
// import { ManualIdVerification } from "./ManualIdVerification";
import { getServerTime } from "../../../api/utils/api";
import dayjs from "dayjs";
import { AppContext } from "../../../context/ApplicationContext";
import AadhaarOcr from "./AadharOcr/AadhaarOcr";
import { AadhaarUploadedDataType } from "../../../pages/Application/msat/MSATSuccess";
import { AadhaarInfoType } from "../../../api/schemas/schema";
import { InformationOutline } from "../../../assets/icons/components";
import { ManualIdVerification } from "./ManualIdVerification";
interface Props {
  formData: {
    key: number;
    name: string;
    label: string;
    required: boolean;
    placeholder: string;
    type: "text" | "password" | "email" | "url" | "tel" | "file";
    maxLegnth?: number;
    minLegnth?: number;
    helperText?: string;
    disabled?: boolean;
    numberOnly?: boolean;
  }[];

  onFormValuesChange: (formValues: { [key: string]: string }) => void;
  heading: string;
  isConsent?: boolean;
  aadhaarUploadedData?: AadhaarUploadedDataType;
  setAadhaarUploadedData?: (value: AadhaarUploadedDataType) => void;
  aadhaarOCRDetails?: AadhaarInfoType;
  handleConfirmAadhaarDetails?: () => void;
  showfetchingInfoModal?: boolean;
  setShowFetchingInfoModal?: (value: boolean) => void;
  showConfirmAadhaarModal?: boolean;
  setShowConfirmAadhaarModal?: (value: boolean) => void;
  invalidAadhaarModal?: boolean;
  setInvalidAadhaarModal?: (value: boolean) => void;
  loading?: boolean;
  showOcrUi?: boolean;
}

const IdVerification = ({
  formData,
  onFormValuesChange,
  heading,
  isConsent,
  aadhaarUploadedData,
  setAadhaarUploadedData,
  aadhaarOCRDetails,
  handleConfirmAadhaarDetails,
  showfetchingInfoModal,
  setShowFetchingInfoModal,
  showConfirmAadhaarModal,
  setShowConfirmAadhaarModal,
  invalidAadhaarModal,
  setInvalidAadhaarModal,
  loading,
  showOcrUi,
}: Props) => {
  const [state, dispatch] = useContext(AppContext);
  const { applicationData } = state;
  const clevertap = useCleverTap()[0];
  const [formValues, setFormValues] = React.useState<{ [key: string]: string }>({});
  // Section to be shown 24hrs after application being moved to mettl_passed stage
  const [showManualSection, setshowManualSection] = useState(false);

  // This will handle any change in user input
  const handleFormValueChange: ((name: string, value: string) => void) | null = (name, value) => {
    setFormValues({ ...formValues, [name]: value });
  };

  React.useEffect(() => {
    // * see if this is course or batch
    if (applicationData) {
      getServerTime()
        .then((serverTime) => {
          const durationAfterMettlPassed = dayjs(serverTime).diff(
            applicationData?.funnel_entry_date,
            "h",
            true
          );
          if (durationAfterMettlPassed > 24 && applicationData.status === "METTL_PASSED") {
            setshowManualSection(true);
          } else {
            setshowManualSection(false);
          }
        })
        .catch((err) => {
          // manage error: toast
          console.log(err);
        });
    }
  }, [applicationData?.funnel_entry_date]);
  // creating a key value pair for each input element (with name as a key for each input element)
  React.useEffect(() => {
    const namesObj: { [key: string]: string } = {};
    formData.forEach((field) => {
      namesObj[field.name] = "";
    });
    setFormValues(namesObj);
  }, []);
  // sending the user form values to parent whenever user types any input
  React.useEffect(() => {
    onFormValuesChange({ ...formValues });
  }, [formValues]);
  const checkIsInvalid = (
    name: string,
    minLegnth?: number,
    maxLegnth?: number,
    numberOnly?: boolean
  ) => {
    const value = formValues[name];
    let isInvalid = false;
    if (!value) isInvalid = true;
    else if ((minLegnth && value.length < minLegnth) || (maxLegnth && value.length > maxLegnth)) {
      isInvalid = true;
    } else if (numberOnly) {
      const digitsOnly = new RegExp("^[0-9]+$");
      if (!digitsOnly.test(value)) isInvalid = true;
    }
    return isInvalid;
  };

  return (
    <Box>
      <Heading mb={{ base: "ms-16", md: "ms-8" }} as="h3">
        {heading}
      </Heading>
      <Box display={showOcrUi ? "flex" : "none"} alignItems={"center"}>
        <InformationOutline boxSize="24px" color={"ms-warning"} />
        <Text ml={"ms-8"} my={"ms-16"} color={"ms-warning"}>
          Please upload good quality images.
        </Text>
      </Box>
      <Box>
        {showOcrUi ? (
          <>
            <AadhaarOcr
              disabled={!isConsent || applicationData?.status === "METTL_DESCISION_PENDING"}
              aadhaarUploadedData={aadhaarUploadedData}
              setAadhaarUploadedData={setAadhaarUploadedData}
              handleConfirmAadhaarDetails={handleConfirmAadhaarDetails}
              aadhaarOCRDetails={aadhaarOCRDetails}
              showfetchingInfoModal={showfetchingInfoModal}
              setShowFetchingInfoModal={setShowFetchingInfoModal}
              showConfirmAadhaarModal={showConfirmAadhaarModal}
              setShowConfirmAadhaarModal={setShowConfirmAadhaarModal}
              invalidAadhaarModal={invalidAadhaarModal}
              setInvalidAadhaarModal={setInvalidAadhaarModal}
              loading={loading}
            />
          </>
        ) : (
          <SimpleGrid
            gridTemplateColumns={{ md: "repeat(2,1fr)" }}
            columnGap={"ms-24"}
            rowGap={"ms-16"}
          >
            {formData.map(
              (
                {
                  key,
                  label,
                  placeholder,
                  required,
                  type,
                  name,
                  disabled,
                  minLegnth,
                  maxLegnth,
                  helperText,
                  numberOnly,
                },
                idx
              ) => (
                <InputCustom
                  key={key}
                  label={required ? `${label} *` : label}
                  placeholder={placeholder}
                  type={type}
                  getValueCallback={(value) => handleFormValueChange(name, value)}
                  disabled={disabled || !isConsent}
                  isInvalid={
                    isConsent
                      ? disabled
                        ? false
                        : checkIsInvalid(name, minLegnth, maxLegnth, numberOnly)
                      : false
                  }
                  maxlength={maxLegnth}
                  helperText={helperText}
                  onClick={() => {
                    clevertap.fireEvent(CleverTapEvents.click_input_field, {
                      screen_name: ScreenNames.msatSuccess,
                      field_name: label,
                      field_type: "text",
                      field_sequence: `${idx + 1}`,
                    });
                  }}
                />
              )
            )}
          </SimpleGrid>
        )}
      </Box>
      {(showManualSection ||
        JSON.parse(
          localStorage.getItem("idVerifErrorOccured-" + state.applicationData?.slug) as string
        )) &&
        isConsent && <ManualIdVerification />}
      {/* {state.applicationData?.status === "METTL_PASSED" && <ManualIdVerification />} */}
    </Box>
  );
};

export default IdVerification;
