import { Box, Text } from "@chakra-ui/react";
import React from "react";
import UserCircle from "../../../assets/icons/components/UserCircle";
import BriefcaseV2 from "../../../assets/icons/components/BriefcaseV2";
import { IndianRupee } from "../../../assets/icons/components";

const AlumniStats = () => {
  const alumniStatsData = [
    {
      name: "Students Placed",
      stat: "6000+",
      color: "#6E71CC",
      bgColor: "linear-gradient(135deg, #FFF 0%, #FFE4E4 100%)",
      icon: <UserCircle />,
    },
    {
      name: "Highest Salary",
      stat: "₹36 LPA",
      color: "#CC926E",
      bgColor: " linear-gradient(135deg, #FFF 0%, #FFF8E4 100%)",
      icon: <BriefcaseV2 />,
    },
    {
      name: "Average CTC",
      stat: "₹6.1 LPA*",
      color: "#1A9FBD",
      bgColor: "linear-gradient(135deg, #FFF 0%, #E4FFFA 100%)",
      icon: <IndianRupee color="#3470E4" />,
    },
  ];
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      gap={{ base: "16px", md: "24px" }}
      justifyContent={{ base: "center", md: "left" }}
    >
      {alumniStatsData &&
        alumniStatsData.length > 0 &&
        alumniStatsData.map((item, index) => (
          <Box
            key={index}
            p={{ base: "8px 11px", md: "12px 24px" }}
            rounded="13px"
            display="flex"
            flexDir="column"
            bg={item.bgColor}
            textAlign="center"
          >
            <Box mx="auto" w={{ base: "24px", md: "40px" }} h={{ base: "24px", md: "40px" }}>
              {React.cloneElement(item.icon, { boxSize: { base: "24px", md: "40px" } })}
            </Box>
            <Text textStyle={{ base: "h5", md: "h3" }} color={item.color}>
              {item.stat}
            </Text>
            <Text textStyle={{ base: "caption", md: "body2" }} color="#5E5E5E">
              {item.name}
            </Text>
          </Box>
        ))}
    </Box>
  );
};

export default AlumniStats;
