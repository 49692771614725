import { Box, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { IITEMIOptions } from "../../../constants/IIt-course/iit-form-onboarding";

interface Props {
  setEmiOptionsData: ((data: IITEMIOptions) => void) | undefined;
  emiOptionsData: IITEMIOptions;
  isForIITRopar?: boolean;
  amountToBePaid?: number;
  isForIITGuwahati?: boolean;
  isForIITMandiV2?: boolean;
}

const EMIPlanOptions = ({ setEmiOptionsData, emiOptionsData, isForIITRopar, isForIITGuwahati, isForIITMandiV2 }: Props) => {
  const showEmiOptions = () => {
    if (isForIITRopar) {
      return (
        <>
          <Radio value="6_months">
            <Box>
              <Text fontSize={"16px"}>6 months</Text>
              <Text fontSize={"14px"}>(8,000*6)</Text>
            </Box>
          </Radio>
        </>
      );
    } else if (isForIITMandiV2) {
      return (
        <>
          <Radio value="6_months">
            <Box>
              <Text fontSize={"16px"}>6 months</Text>
              <Text fontSize={"14px"}>(9,500*6)</Text>
            </Box>
          </Radio>
        </>
      );
    } else if (isForIITGuwahati) {
      return (
        <>
          <Radio value="12_months">
            <Box>
              <Text fontSize={"16px"}>12 months</Text>
              <Text fontSize={"14px"}>(15,833*12)</Text>
            </Box>
          </Radio>
          <Radio value="18_months">
            <Box>
              <Text fontSize={"16px"}>18 months</Text>
              <Text fontSize={"14px"}>(10,555*18)</Text>
            </Box>
          </Radio>
          <Radio value="24_months">
            <Box>
              <Text fontSize={"16px"}>24 months</Text>
              <Text fontSize={"14px"}>(8,333*24)</Text>
            </Box>
          </Radio>
        </>
      );
    } else {
      return (
        <>
          <Radio value="12_months">
            <Box>
              <Text fontSize={"16px"}>12 months</Text>
              <Text fontSize={"14px"}>(15,833*12)</Text>
            </Box>
          </Radio>
          <Radio value="18_months">
            <Box>
              <Text fontSize={"16px"}>18 months</Text>
              <Text fontSize={"14px"}>(10,555*18)</Text>
            </Box>
          </Radio>
          <Radio value="24_months">
            <Box>
              <Text fontSize={"16px"}>24 months</Text>
              <Text fontSize={"14px"}>(8,333*24)</Text>
            </Box>
          </Radio>
        </>
      );
    }
  };

  const isCoApplicantChoosen =
    emiOptionsData?.selfPaid === "false" ||
    emiOptionsData?.selfPaidForRopar === "false" ||
    emiOptionsData?.selfPaidForMandiV2 === "false" ||
    emiOptionsData?.studentMonthlySalary === "below_15000" ||
    emiOptionsData?.studentMonthlySalaryForRopar === "below_15000" ||
    emiOptionsData?.studentMonthlySalaryForMandiV2 === "below_15000";

  return (
    <Box>
      <Text textStyle={{ base: "body2-md", md: "body1-md" }} color={"black"}>
        Please fill in the details below. Our Admission team will get in touch with you and assist with the further process.
      </Text>
      <Box mt={2} display={"flex"} flexDir={"column"} gap={4}>
        {/* EMI Options */}
        <Box display={"flex"} flexDir={"column"} gap={2}>
          <Text textStyle={"body2-md"} color={"black"}>
            1. Select your EMI option?*
          </Text>
          <RadioGroup
            color={"blackAlpha.900"}
            value={emiOptionsData?.emiOptionSelected || emiOptionsData?.emiOptionSelectedForRopar || emiOptionsData?.emiOptionSelectedForMandiV2}
            colorScheme="blue"
            onChange={(data) => {
              if (setEmiOptionsData) {
                setEmiOptionsData(
                  isForIITRopar
                    ? { ...emiOptionsData, emiOptionSelectedForRopar: data as string }
                    : isForIITMandiV2
                    ? { ...emiOptionsData, emiOptionSelectedForMandiV2: data as string }
                    : { ...emiOptionsData, emiOptionSelected: data as string }
                );
              }
            }}
          >
            <Stack spacing={{ base: 5, md: 10 }} direction={{ base: "column", md: "row" }}>
              {showEmiOptions()}
            </Stack>
          </RadioGroup>
        </Box>

        {isForIITGuwahati && <Text color={"#CC926E"}>*No cost emi is only upto 18 months</Text>}
        <Text border={"1px solid #D6E2FA"} bg={"#F2F6FF"} rounded={"6px"} color={"black"} textStyle={{ base: "caption", md: "body2" }} p={"8px"}>
          You must have a family member serve as a co-applicant to process your loan application if you are: <br />
          <span> a. Studying or currently not working </span>
          <br />
          <span> b. Earning less than 15000 per month</span>
        </Text>

        {/* Current Working Status */}
        <Box display={"flex"} flexDir={"column"} gap={2}>
          <Text textStyle={"body2-md"} color={"black"}>
            2.Please confirm your current status: *
          </Text>
          <RadioGroup
            color={"blackAlpha.900"}
            value={emiOptionsData?.currentWorkingStatus || emiOptionsData?.currentWorkingStatusForRopar || emiOptionsData?.currentWorkingStatusForMandiV2}
            colorScheme="blue"
            onChange={(data) => {
              if (setEmiOptionsData) {
                if (data === "currently_working") {
                  setEmiOptionsData(
                    isForIITRopar
                      ? {
                          ...emiOptionsData,
                          currentWorkingStatusForRopar: data,
                          studentMonthlySalaryForRopar: "",
                          selfPaidForRopar: "true",
                          employmentType: "",
                          coApplicantEmploymentType: "",
                          employmentTypeForRopar: "",
                          coApplicantEmploymentTypeForRopar: "",
                        }
                      : isForIITMandiV2
                      ? {
                          ...emiOptionsData,
                          currentWorkingStatusForMandiV2: data,
                          studentMonthlySalaryForMandiV2: "",
                          selfPaidForMandiV2: "true",
                          employmentType: "",
                          coApplicantEmploymentType: "",
                          employmentTypeForMandiV2: "",
                          coApplicantEmploymentTypeForMandiV2: "",
                        }
                      : {
                          ...emiOptionsData,
                          currentWorkingStatus: data,
                          studentMonthlySalary: "",
                          selfPaid: "true",
                          employmentType: "",
                          coApplicantEmploymentType: "",
                          employmentTypeForRopar: "",
                          coApplicantEmploymentTypeForRopar: "",
                        }
                  );
                } else {
                  setEmiOptionsData(
                    isForIITRopar
                      ? {
                          ...emiOptionsData,
                          currentWorkingStatusForRopar: data,
                          selfPaidForRopar: "false",
                          studentMonthlySalaryForRopar: "Not Applicable",
                          employmentType: "",
                          coApplicantEmploymentType: "",
                          employmentTypeForRopar: "",
                          coApplicantEmploymentTypeForRopar: "",
                        }
                      : isForIITMandiV2
                      ? {
                          ...emiOptionsData,
                          currentWorkingStatusForMandiV2: data,
                          selfPaidForMandiV2: "false",
                          studentMonthlySalaryForMandiV2: "Not Applicable",
                          employmentType: "",
                          coApplicantEmploymentType: "",
                          employmentTypeForMandiV2: "",
                          coApplicantEmploymentTypeForMandiV2: "",
                        }
                      : {
                          ...emiOptionsData,
                          currentWorkingStatus: data,
                          selfPaid: "false",
                          studentMonthlySalary: "Not Applicable",
                          employmentType: "",
                          coApplicantEmploymentType: "",
                          employmentTypeForRopar: "",
                          coApplicantEmploymentTypeForRopar: "",
                        }
                  );
                }
              }
            }}
          >
            <Stack spacing={3} direction="column">
              <Radio value="currently_working">Currently Working</Radio>
              <Radio value="currently_studying">Currently Studying</Radio>
              <Radio value="currently_not_working_nor_studying">Currently Not Working Nor Studying</Radio>
            </Stack>
          </RadioGroup>
        </Box>

        {/* If currently working ask for a salary */}
        {((emiOptionsData?.currentWorkingStatus && emiOptionsData.currentWorkingStatus === "currently_working") ||
          (emiOptionsData?.currentWorkingStatusForRopar && emiOptionsData.currentWorkingStatusForRopar === "currently_working") ||
          (emiOptionsData?.currentWorkingStatusForMandiV2 && emiOptionsData.currentWorkingStatusForMandiV2 === "currently_working")) && (
          <Box display={"flex"} flexDir={"column"} gap={2}>
            <Text textStyle={"body2-md"} color={"black"}>
              3. What is your monthly salary? *
            </Text>
            <RadioGroup
              value={emiOptionsData?.studentMonthlySalary || emiOptionsData?.studentMonthlySalaryForRopar || emiOptionsData?.studentMonthlySalaryForMandiV2}
              color={"blackAlpha.900"}
              colorScheme="blue"
              onChange={(data) => {
                if (setEmiOptionsData) {
                  if (data === "below_15000") {
                    setEmiOptionsData(
                      isForIITRopar
                        ? {
                            ...emiOptionsData,
                            studentMonthlySalaryForRopar: data,
                            selfPaidForRopar: "false",
                            coApplicant: "",
                            employmentType: "",
                            coApplicantEmploymentType: "",
                            coApplicantForRopar: "",
                            employmentTypeForRopar: "",
                            coApplicantEmploymentTypeForRopar: "",
                          }
                        : isForIITMandiV2
                        ? {
                            ...emiOptionsData,
                            studentMonthlySalaryForMandiV2: data,
                            selfPaidForMandiV2: "false",
                            coApplicant: "",
                            employmentType: "",
                            coApplicantEmploymentType: "",
                            coApplicantForMandiV2: "",
                            employmentTypeForMandiV2: "",
                            coApplicantEmploymentTypeForMandiV2: "",
                          }
                        : {
                            ...emiOptionsData,
                            studentMonthlySalary: data,
                            selfPaid: "false",
                            coApplicant: "",
                            coApplicantForRopar: "",
                            employmentType: "",
                            coApplicantEmploymentType: "",
                            employmentTypeForRopar: "",
                            coApplicantEmploymentTypeForRopar: "",
                          }
                    );
                  } else {
                    setEmiOptionsData(
                      isForIITRopar
                        ? {
                            ...emiOptionsData,
                            studentMonthlySalaryForRopar: data,
                            selfPaidForRopar: "true",
                            coApplicantForRopar: "Not Applicable",
                            employmentTypeForRopar: "",
                            coApplicantEmploymentTypeForRopar: "",
                            employmentType: "",
                            coApplicantEmploymentType: "",
                          }
                        : isForIITMandiV2
                        ? {
                            ...emiOptionsData,
                            studentMonthlySalaryForMandiV2: data,
                            selfPaidForMandiV2: "true",
                            coApplicantForMandiV2: "Not Applicable",
                            employmentTypeForMandiV2: "",
                            coApplicantEmploymentTypeForMandiV2: "",
                            employmentType: "",
                            coApplicantEmploymentType: "",
                          }
                        : {
                            ...emiOptionsData,
                            studentMonthlySalary: data,
                            selfPaid: "true",
                            coApplicant: "Not Applicable",
                            employmentTypeForRopar: "",
                            coApplicantEmploymentTypeForRopar: "",
                            employmentType: "",
                            coApplicantEmploymentType: "",
                          }
                    );
                  }
                }
              }}
            >
              <Stack spacing={3} direction="column">
                <Radio value="below_15000">
                  Co-Applicant <span style={{ fontSize: "13px" }}> (Earning {"<"} 15000/month) </span>
                </Radio>
                <Radio value="above_15000">
                  Self <span style={{ fontSize: "13px" }}> (Earning {">"} 15000/month)</span>
                </Radio>
              </Stack>
            </RadioGroup>
          </Box>
        )}

        {(emiOptionsData?.selfPaid && emiOptionsData.selfPaid === "false") ||
        (emiOptionsData?.selfPaidForRopar && emiOptionsData.selfPaidForRopar === "false") ||
        (emiOptionsData?.selfPaidForMandiV2 && emiOptionsData.selfPaidForMandiV2 === "false") ? (
          <>
            <Box display={"flex"} flexDir={"column"} gap={2}>
              <Text textStyle={"body2-md"} color={"black"}>
                {emiOptionsData?.currentWorkingStatus === "currently_working" ||
                emiOptionsData?.currentWorkingStatusForRopar === "currently_working" ||
                emiOptionsData?.currentWorkingStatusForMandiV2 === "currently_working"
                  ? 4
                  : 3}
                . Who is your Co-Applicant?*
              </Text>
              <RadioGroup
                color={"blackAlpha.900"}
                value={emiOptionsData?.coApplicant || emiOptionsData?.coApplicantForRopar || emiOptionsData?.coApplicantForMandiV2}
                colorScheme="blue"
                onChange={(data) => {
                  if (setEmiOptionsData) {
                    setEmiOptionsData(
                      isForIITRopar ? { ...emiOptionsData, coApplicantForRopar: data } : isForIITMandiV2 ? { ...emiOptionsData, coApplicantForMandiV2: data } : { ...emiOptionsData, coApplicant: data }
                    );
                  }
                }}
              >
                <Stack spacing={5} direction={{ base: "column", md: "row" }}>
                  <Radio value="Brother">Brother</Radio>
                  <Radio value="Sister">Sister</Radio>
                  <Radio value="Mother">Mother</Radio>
                  <Radio value="Father">Father</Radio>
                  <Radio value="Relative">Relative</Radio>
                </Stack>
              </RadioGroup>
            </Box>
            <Text border={"1px solid #D6E2FA"} bg={"#FFFAFB"} rounded={"6px"} color={"black"} textStyle={{ base: "caption", md: "body2" }} p={"8px"}>
              Note: A co-applicant is a family member, such as a parent or sibling, against whom a loan application can be created to facilitate the fee application for EMI process.
            </Text>
          </>
        ) : null}

        {emiOptionsData?.studentMonthlySalary || emiOptionsData?.studentMonthlySalaryForRopar || emiOptionsData?.studentMonthlySalaryForMandiV2 ? (
          <Box display={"flex"} flexDir={"column"} gap={2}>
            <Text textStyle={"body2-md"} color={"black"}>
              {(isCoApplicantChoosen && emiOptionsData?.currentWorkingStatus === "currently_working") ||
              emiOptionsData?.currentWorkingStatusForRopar === "currently_working" ||
              emiOptionsData?.currentWorkingStatusForMandiV2 === "currently_working"
                ? 5
                : 4}
              . What is {isCoApplicantChoosen ? "Co-Applicant" : "your"} employment type?*
            </Text>
            <RadioGroup
              color={"blackAlpha.900"}
              value={
                isForIITRopar
                  ? isCoApplicantChoosen
                    ? emiOptionsData?.coApplicantEmploymentTypeForRopar || ""
                    : emiOptionsData?.employmentTypeForRopar || ""
                  : isForIITMandiV2
                  ? isCoApplicantChoosen
                    ? emiOptionsData?.coApplicantEmploymentTypeForMandiV2 || ""
                    : emiOptionsData?.employmentTypeForMandiV2 || ""
                  : isCoApplicantChoosen
                  ? emiOptionsData?.coApplicantEmploymentType || ""
                  : emiOptionsData?.employmentType || ""
              }
              colorScheme="blue"
              onChange={(data) => {
                if (setEmiOptionsData) {
                  setEmiOptionsData(
                    isForIITRopar
                      ? isCoApplicantChoosen
                        ? { ...emiOptionsData, coApplicantEmploymentTypeForRopar: data }
                        : { ...emiOptionsData, employmentTypeForRopar: data }
                      : isForIITMandiV2
                      ? isCoApplicantChoosen
                        ? { ...emiOptionsData, coApplicantEmploymentTypeForMandiV2: data }
                        : { ...emiOptionsData, employmentTypeForMandiV2: data }
                      : isCoApplicantChoosen
                      ? { ...emiOptionsData, coApplicantEmploymentType: data }
                      : { ...emiOptionsData, employmentType: data }
                  );
                }
              }}
            >
              <Stack spacing={5} direction={{ base: "column", md: "row" }}>
                <Radio value="self_employed">Self Employed</Radio>
                <Radio value="salaried">Salaried</Radio>
              </Stack>
            </RadioGroup>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default EMIPlanOptions;
