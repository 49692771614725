import { ChevronDownIcon } from "@chakra-ui/icons";
import { useLocation, useHistory } from "react-router-dom";
import { HeaderMenuList } from "../../../constants/revamp/header";
import { Flex, Box, MenuList, MenuButton, MenuItem, Menu, Button } from "@chakra-ui/react";

interface Props {
  navbarLinks: HeaderMenuList[];
  hideLinks: boolean;
  isAdmin: boolean;
}

const HeaderMenuSection = ({ navbarLinks, hideLinks = false, isAdmin = false }: Props) => {
  const history = useHistory();
  const location = useLocation();

  if (hideLinks) return <div />;

  return (
    <Flex align={"center"} justify={"space-between"}>
      {/* Home, Jobs menu */}
      <Flex gap={4} align={"center"} display={{ base: "none", lg: "flex" }}>
        {isAdmin && (
          <Box
            textColor={"#3470E4"}
            fontSize={"16px"}
            fontWeight={"600"}
            rounded={"8px"}
            onClick={() => {
              history.push("/admin");
            }}
            cursor={"pointer"}
            p={"12px"}
            bg={"#F2F6FF"}
            _hover={{ bg: "#F2F6FF" }}
          >
            View Admin
          </Box>
        )}
        {navbarLinks.map((link, index) => {
          const isActive = location.pathname === link.url;
          return link.hasDropDown ? (
            <Box key={index}>
              <Menu>
                <MenuButton
                  border={"none"}
                  css={{
                    ":focus": {
                      outline: "none",
                      boxShadow: "none",
                    },
                  }}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  textColor={isActive ? "#3470E4" : "#544D4F"}
                  textTransform={"capitalize"}
                  fontSize={"16px"}
                  fontWeight={"600"}
                  bg={isActive ? "#F2F6FF" : "transparent"}
                >
                  {link.title}
                </MenuButton>
                <MenuList>
                  {link?.subMenuList?.map((subLink) => (
                    <MenuItem
                      key={subLink.id}
                      onClick={() => {
                        if (subLink.isExternal) {
                          window.open(subLink.url, "_blank");
                        } else {
                          history.push(subLink.url);
                        }
                      }}
                    >
                      {subLink.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Box>
          ) : (
            <Box
              key={index}
              textColor={isActive ? "#3470E4" : "#544D4F"}
              fontSize={"16px"}
              fontWeight={"600"}
              rounded={"8px"}
              onClick={() => {
                history.push(link?.url);
              }}
              cursor={"pointer"}
              p={"12px"}
              bg={isActive ? "#F2F6FF" : "transparent"}
              _hover={{ bg: "#F2F6FF" }}
            >
              {link.title}
            </Box>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default HeaderMenuSection;
