import { Box, Button, Heading, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import CSVFileUpload from "../CSVFileUpload";
import { useMutation } from "react-query";
import { axiosErrorHandler } from "../../../../api/utils/error";
import useSnackBar from "../../../common/general/SnackBar";
import { addCouponToUserBulk } from "../../../../api/utils/api/v2/coupon";

const AddCouponCodes = () => {
  const [csvData, setCSVData] = useState<string[]>([]); // Change the type as per your CSV structure
  const [directPaymentCoupon, setDirectPaymentCoupon] = useState<string>("");
  const [emiPaymentCoupon, setEmiPaymentCoupon] = useState<string>("");

  const snackbar = useSnackBar();
  const { mutate: addCouponToUserMutate, isLoading: isAddingCouponToUserLoading } = useMutation(addCouponToUserBulk, {
    onSuccess: () => {
      snackbar.success("Status updated successfully!");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const handleApplyCoupon = () => {
    //
    addCouponToUserMutate({
      emails: csvData,
      direct_payment_coupon_code: directPaymentCoupon,
      emi_payment_coupon_code: emiPaymentCoupon,
    });
  };

  return (
    <Box my="20px" border="1px solid" borderColor={"ms-grey.200"} p="20px" borderRadius={"12px"}>
      <Heading as="h3" mb="16px" color="ms-grey.700">
        Update dost request status in Bulk
        <Text as="span" textStyle={"body2-md"} ml="12px">
          (Upload csv file which should just have 1 column containing list of emails)
        </Text>
      </Heading>

      <CSVFileUpload csvData={csvData} setCsvData={setCSVData} />
      {csvData.length > 0 && (
        <Box bgColor={"ms-grey.100"} mt="12px" borderRadius={"12px"} border="1px solid" borderColor={"ms-grey.200"} height={"200px"} overflow={"scroll"} p="20px">
          {csvData.map((email, i) => {
            return (
              <Text key={i} textStyle={"body1-md"}>
                {email}
              </Text>
            );
          })}
        </Box>
      )}
      <Box display={"flex"} flexDirection={"column"} gap="10px" mt="40px" width={"400px"}>
        <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
          Enter coupon to be applied on direct payment *
        </label>
        <Input name="email" type="email" placeholder="Enter coupon code" value={directPaymentCoupon} onChange={(e) => setDirectPaymentCoupon(e.target.value)} />

        <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
          Enter coupon to be applied on emi payment *
        </label>
        <Input name="email" type="email" placeholder="Enter coupon code" value={emiPaymentCoupon} onChange={(e) => setEmiPaymentCoupon(e.target.value)} />
      </Box>
      <Button
        mt="4"
        isLoading={isAddingCouponToUserLoading}
        isDisabled={!directPaymentCoupon || !emiPaymentCoupon || !csvData || csvData.length === 0}
        width={"100px"}
        variant="primary"
        onClick={handleApplyCoupon}
        ml="auto"
      >
        Upload
      </Button>
    </Box>
  );
};

export default AddCouponCodes;
