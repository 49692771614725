import { Box, Text, keyframes } from "@chakra-ui/react";
import React, { ReactElement, useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";

interface props {
  text?: ReactElement | string;
  timer?: number;
  onDone?: (index: number) => void;
  index?: number;
  videoUrl?: string;
}

const dotTyping = keyframes({
  "0%": {
    "box-shadow": "9984px 0 0 0 #F3F2F2, 9999px 0 0 0 #F3F2F2, 10014px 0 0 0 #F3F2F2;",
  },
  "16.667%": {
    "box-shadow": "9984px -10px 0 0 #B3B3B3, 9999px 0 0 0 #F3F2F2, 10014px 0 0 0 #F3F2F2;",
  },
  "33.333%": {
    "box-shadow": "9984px 0 0 0 #F3F2F2, 9999px 0 0 0 #F3F2F2, 10014px 0 0 0 #F3F2F2;",
  },
  "50%": {
    "box-shadow": "9984px 0 0 0 #F3F2F2, 9999px -10px 0 0 #B3B3B3, 10014px 0 0 0 #F3F2F2;",
  },
  "66.667%": {
    "box-shadow": "9984px 0 0 0 #F3F2F2, 9999px 0 0 0 #F3F2F2, 10014px 0 0 0 #F3F2F2;",
  },
  "83.333%": {
    "box-shadow": "9984px 0 0 0 #F3F2F2, 9999px 0 0 0 #F3F2F2, 10014px -10px 0 0 #B3B3B3;",
  },
  "100%": {
    "box-shadow": "9984px 0 0 0 #F3F2F2, 9999px 0 0 0 #F3F2F2, 10014px 0 0 0 #F3F2F2;",
  },
});

const DostChatBubble = ({ text = "", timer = 2000, onDone, index, videoUrl }: props) => {
  let chatLoadingTimer: NodeJS.Timeout;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    chatLoadingTimer = setTimeout(() => {
      setIsLoading(false);
      if (onDone && index !== undefined) {
        onDone(index);
      }
      clearTimeout(chatLoadingTimer);
    }, timer);
  }, [timer]);

  return (
    <Box>
      <Box
        rounded="0px 16px 16px 16px"
        transition="max-width .3s ease-in-out"
        bg="#D6E2FA"
        maxW={isLoading ? "94px" : "304px"}
        w="fit-content"
      >
        {isLoading && (
          <Box
            minW="80px"
            h="40px"
            display="flex"
            alignItems="center"
            visibility={!isLoading ? "hidden" : "visible"}
            opacity={!isLoading ? 0 : 1}
            position={!isLoading ? "absolute" : "relative"}
            transition="opacity .8s ease-in-out"
          >
            <Box
              mx="auto"
              display="flex"
              alignItems="center"
              justifyContent="center"
              position="relative"
              left="-9999px"
              w="10px"
              h="10px"
              maxW="10px"
              maxH="10px"
              rounded="100px"
              bg="#B3B3B3"
              color=" #B3B3B3"
              shadow="9984px 0 0 0 #F3F2F2, 9999px 0 0 0 #F3F2F2, 10014px 0 0 0 #F3F2F2;"
              animation={`${dotTyping} 2s infinite linear;`}
            ></Box>
          </Box>
        )}

        <Text
          as="div"
          top="0px"
          w="max-content"
          rounded="0px 16px 16px 16px"
          p="8px 12px"
          overflow="hidden"
          textStyle="body2"
          visibility={isLoading ? "hidden" : "visible"}
          opacity={isLoading ? 0 : 1}
          position={isLoading ? "absolute" : "relative"}
          transition="opacity .8s ease-in-out"
          whiteSpace="pre-wrap"
          maxW="304px"
        >
          {text}
        </Text>
      </Box>
      <Box
        h="full"
        top="0px"
        rounded="16px"
        overflow="hidden"
        mt={videoUrl && "15px"}
        maxH={{ base: "345px" }}
        opacity={isLoading ? 0 : 1}
        maxW={{ base: "100%", sm: "614px" }}
        pt={videoUrl && { base: "56.25%", md: "0px" }}
        position={isLoading ? "absolute" : { base: "relative", md: "static" }}
        transition="opacity 1s ease-in-out"
      >
        {!isLoading && videoUrl && (
          <Box
            position={{ base: "absolute", md: "static" }}
            as={ReactPlayer}
            left="0"
            top="0"
            width="100% !important"
            height={{ base: "100% !important", md: "345px !important" }}
            url={videoUrl}
          />
        )}
      </Box>
    </Box>
  );
};

export default DostChatBubble;
