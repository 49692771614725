import React from "react";
import { Box, Flex, Heading, Button } from "@chakra-ui/react";
import { ConversationalForm } from "conversational-form";
import { launchFormDataFullTime } from "../new-launch-form/launchFormDataFullTime";
import { launchFormDataPartTime } from "../new-launch-form/launchFormDataPartTime";
import InputQuestion from "./InputQuestion";
import SelectQuestion from "./SelectQuestion";
import RadioQuestion from "./RadioQuestion";
import { useContext } from "react";
import { AppContext } from "../../../../context/ApplicationContext";
import useSnackBar from "../../../../components/common/general/SnackBar";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import { useLocation } from "react-router-dom";
interface FormValues {
  [key: string]: string;
}
export interface SingleQuestion {
  name: string;
  type: string;
  title: string;
  footerText?: string;
  isCompulsary?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: RegExp;
  placeholder?: string;
  radioOptions?: {
    key: string;
    value: string;
    subtext?: string;
    customSize?: "regular" | "large";
  }[];
  dropdownOptions?: string[];
}

const NewLaunchForm = ({
  onSubmit,
  applicantID,
}: {
  onSubmit: (formValues: FormValues[]) => void;
  applicantID?: string;
}) => {
  const location = useLocation();
  const state = useContext(AppContext)[0];
  const launchFormLocalStorageKey =
    "launchFormData-" + state.profileData?.slug + "-" + state.applicationData?.slug;
  const course_type = state.applicationData?.batch_campus.batch.course.course_type;
  const launchFormData =
    course_type === "FULL_TIME" ? launchFormDataFullTime : launchFormDataPartTime;
  const [questionsData, setQuestionsData] = React.useState<SingleQuestion[]>(launchFormData);
  const cfContextRef = React.useRef<HTMLDivElement | null>(null);
  const formRef = React.useRef<HTMLFormElement | null>(null);
  /**
  When component loads,the questions stored in localstorage form 
  will be skipped by removing those questions fromt hhe questions data
   */
  React.useEffect(() => {
    const persistentFormData = JSON.parse(
      localStorage.getItem(launchFormLocalStorageKey) as string
    );
    if (persistentFormData && Array.isArray(persistentFormData)) {
      setQuestionsData(launchFormData.slice(persistentFormData.length));
    }
  }, []);

  /** This methods gets called whenever user submit a answer. 
      Based on user's answer we can validate and decide whether to move user to next question
      or throw an error
   */
  const snackbar = useSnackBar();
  const flowCallback = function (
    dto: { [key: string]: string | { [key: string]: string } }, // it will contain the valueS like name key of our question,text entered by user
    success: () => void, // we can call this if text entered by user is valid and move to next question
    error: (err: string) => void // we can call this if text entered by user is invalid and show an error message
  ) {
    sendTrackingEvent({
      event: "launch_form_enter_details",
      eventLabel: location.pathname,
    });

    // Invalid cases
    if (typeof dto.tag !== "object" || typeof dto.tag.name.trim() !== "string") return;
    if (typeof dto.text !== "string") {
      error("Invalid Answer!");
      return;
    }

    const currentQuestionName = dto.tag.name.trim();

    const currentQuestion = questionsData.find((el) => el.name === currentQuestionName);
    if (currentQuestion?.pattern && !currentQuestion.pattern.test(dto.text.trim())) {
      error("Invalid Answer!");
      return;
    }

    // If user answer is valid then save it in localstorage and move to next question
    let persistentFormData = JSON.parse(localStorage.getItem(launchFormLocalStorageKey) as string);
    if (persistentFormData) {
      // remove existing object to avoid duplicate objects
      persistentFormData = persistentFormData.filter(
        (obj: { [key: string]: string }) => Object.keys(obj)[0] !== currentQuestionName
      );
      persistentFormData = [...persistentFormData, { [dto.tag.name.trim()]: dto.text.trim() }];
      localStorage.setItem(launchFormLocalStorageKey, JSON.stringify(persistentFormData));
    } else {
      persistentFormData = [{ [dto.tag.name.trim()]: dto.text.trim() }];
      localStorage.setItem(launchFormLocalStorageKey, JSON.stringify(persistentFormData));
    }
    try {
      success();
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    const conversationalForm = ConversationalForm.startTheConversation({
      formEl: formRef.current,
      context: cfContextRef.current,
      showProgressBar: true,
      submitCallback: function () {
        const persistentFormData = JSON.parse(
          localStorage.getItem(launchFormLocalStorageKey) as string
        );
        localStorage.removeItem(launchFormLocalStorageKey);
        onSubmit(persistentFormData);
        conversationalForm.addRobotChatResponse("Thanks! You have completed all questions!");
      },
      flowStepCallback: flowCallback,
    });
  }, [questionsData]);
  return (
    <>
      <Flex alignItems={"center"} mb={"1rem"}>
        <Heading as="h5">
          Launch Form ({course_type === "FULL_TIME" ? "Full Time Batch" : "Part Time Batch"})
        </Heading>
        <Button
          ml={"ms-8"}
          size={"sm"}
          variant={"primary"}
          onClick={() => {
            sendTrackingEvent({
              event: "reset_form",
              eventLabel: location.pathname,
            });
            setQuestionsData([...launchFormData]);
            localStorage.removeItem(launchFormLocalStorageKey);
          }}
        >
          Reset Form
        </Button>
      </Flex>
      <Box boxShadow={"rgba(149, 157, 165, 0.2) 0px 8px 24px"}>
        <form
          style={{ visibility: "hidden", position: "absolute" }}
          id="form"
          cf-form={"true"}
          ref={formRef}
        >
          {questionsData?.map((question: SingleQuestion) => {
            switch (question.type) {
              case "input":
                return (
                  <InputQuestion
                    key={question.name}
                    question={question}
                    course_type={course_type}
                  />
                );
              case "radio":
                return (
                  <RadioQuestion
                    key={question.name}
                    question={question}
                    course_type={course_type}
                  />
                );
              case "dropdown":
                return (
                  <SelectQuestion
                    key={question.name}
                    question={question}
                    course_type={course_type}
                  />
                );
            }
          })}
        </form>
        <div
          style={{
            height: "400px",
          }}
          id="cf-context"
          ref={cfContextRef}
          role="cf-context"
          cf-context={"true"}
        ></div>
      </Box>
    </>
  );
};

export default NewLaunchForm;
