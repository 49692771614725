import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";
import ZoomTelegramLinks from "./ZoomTelegramLinks";
import { ScreenNames } from "../../../../clevertap/useCleverTap";
import FacingAnyIssues from "./FacingAnyIssues";
import { useLocation } from "react-router-dom";

interface Props {
  zoom_link?: string;
  telegram_link?: string;
  slack_link?: string;
  screen_name: ScreenNames;
  onBannerLinksModalClose: () => void;
}
const BannerLinksModalContent = ({
  zoom_link,
  telegram_link,
  slack_link,
  screen_name,
  onBannerLinksModalClose,
}: Props) => {
  const location = useLocation();
  return (
    <Box>
      <Heading as="h3" mb={"ms-8"}>
        ALERT: You have unlocked your Day 1 resources for Masai! Excited?
      </Heading>
      <Text textStyle="body1-md" color="ms-grey.700" mb={{ base: "ms-32", md: "ms-48" }}>
        Joining the below links is mandatory.
      </Text>
      <Box mb={{ base: "ms-32", md: "ms-48" }}>
        <ZoomTelegramLinks
          fromModal={true}
          screen_name={screen_name}
          zoom_link={zoom_link}
          slack_link={slack_link}
          telegram_link={telegram_link}
          direction={["column", "row", "row", "row", "row"]}
          spacing={["40px", "46px", "46px", "46px", "46px"]}
        />
      </Box>
      <FacingAnyIssues isLineBreak={true} fromModal={true} />
    </Box>
  );
};

export default BannerLinksModalContent;
