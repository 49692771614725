import React from "react";
import { CheckboxGroup as CheckboxGrp, StackDirection, SimpleGrid } from "@chakra-ui/react";
import SingleCheckbox from "./SingleCheckbox";

interface Props {
  options: {
    value: string | number;
    label: string | number;
    supportLabel?: string | number;
  }[];
  handleChange: (values: (number | string)[]) => void;
  selectedValues: (string | number)[];
  spacingX?: string;
  spacingY?: string;
  direction?: StackDirection;
  columns?: number[] | number;
  justify?: string;
}

const CheckboxGroup = ({
  options,
  handleChange,
  selectedValues,
  spacingX,
  spacingY,
  columns,
  justify = "center",
}: Props) => {
  const onSingleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const checked = event.target.checked;
    if (checked) {
      if (!selectedValues.includes(value)) {
        selectedValues = [...selectedValues, value];
      }
    } else {
      selectedValues = selectedValues.filter((el) => el !== value);
    }
    handleChange([...selectedValues]);
  };

  return (
    <CheckboxGrp value={selectedValues}>
      <SimpleGrid
        justifyItems={{ base: "start", xl: justify }}
        columns={columns}
        spacingX={spacingX}
        spacingY={spacingY}
      >
        {options.map(
          (
            option: {
              value: string | number;
              label: string | number;
              supportLabel?: string | number;
            },
            i
          ) => {
            return (
              <SingleCheckbox
                key={i}
                size="md"
                color="ms-blue"
                option={option}
                checked={selectedValues.includes(option.value)}
                onChange={onSingleCheckboxChange}
              />
            );
          }
        )}
      </SimpleGrid>
    </CheckboxGrp>
  );
};

export default CheckboxGroup;
