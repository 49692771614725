import { Icon } from "@chakra-ui/react";
import React from "react";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Comma2 = (props: any) => {
  return (
    <Icon viewBox="0 0 27 24" fill="#6FCD9E" {...props}>
      <path
        d="M19.666 16.2005V13.3605C19.666 12.2672 19.471 11.2538 19.081 10.3205C18.661 9.38722 17.956 8.46722 16.966 7.56055L15.211 8.72055C15.871 9.36055 16.351 9.96055 16.651 10.5205C16.951 11.0538 17.131 11.5872 17.191 12.1205H15.076V16.2005H19.666ZM12.196 16.2005V13.3605C12.196 12.2672 12.0011 11.2538 11.611 10.3205C11.1911 9.38722 10.486 8.46722 9.49601 7.56055L7.74102 8.72055C8.40105 9.36055 8.88098 9.96055 9.18102 10.5205C9.48105 11.0538 9.66105 11.5872 9.72102 12.1205H7.60602V16.2005H12.196Z"
        fill="#6FCD9E"
      />
    </Icon>
  );
};

export default Comma2;
