import { Telegram, Zoom, Slack } from "../../../../assets/icons/components";
import { Stack, HStack, Button, Link, StackDirection } from "@chakra-ui/react";
import useCleverTap, { ScreenNames } from "../../../../clevertap/useCleverTap";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import { useLocation } from "react-router-dom";

interface Props {
  zoom_link?: string;
  telegram_link?: string;
  slack_link?: string;
  screen_name: ScreenNames;
  direction?: StackDirection;
  spacing?: string | string[];
  fromModal?: boolean;
}
const ZoomTelegramLinks = ({
  zoom_link,
  telegram_link,
  slack_link,
  screen_name,
  direction,
  spacing,
  fromModal,
}: Props) => {
  const clevertap = useCleverTap()[0];
  const location = useLocation();
  return (
    <Stack direction={direction} spacing={spacing} justifyContent={"center"}>
      {zoom_link && (
        <Link isExternal href={zoom_link}>
          <HStack spacing="ms-8">
            <Zoom boxSize="24px" />
            <Button
              backgroundColor="transparent"
              size="md"
              icon
              variant="link"
              onClick={() => {
                const eventName = fromModal ? "dialog_box_join_orientation" : "join_orientation";
                sendTrackingEvent({
                  event: eventName,
                  eventLabel: location.pathname,
                });
              }}
            >
              Join Orientation
            </Button>
          </HStack>
        </Link>
      )}
      {telegram_link && (
        <Link isExternal href={telegram_link}>
          <HStack spacing="ms-8">
            <Telegram boxSize="24px" />
            <Button
              backgroundColor="transparent"
              size="md"
              icon
              variant="link"
              onClick={() => {
                const eventName = fromModal ? "dialog_box_join_telegram" : "join_telegram";
                sendTrackingEvent({
                  event: eventName,
                  eventLabel: location.pathname,
                });
              }}
            >
              Join Telegram
            </Button>
          </HStack>
        </Link>
      )}
      {slack_link && (
        <Link isExternal href={slack_link}>
          <HStack spacing="ms-8">
            <Slack boxSize="24px" />
            <Button
              backgroundColor="transparent"
              size="md"
              icon
              variant="link"
              onClick={() => {
                const eventName = fromModal ? "dialog_box_join_slack" : "join_slack";
                sendTrackingEvent({
                  event: eventName,
                  eventLabel: location.pathname,
                });
              }}
            >
              Join Slack
            </Button>
          </HStack>
        </Link>
      )}
    </Stack>
  );
};

export default ZoomTelegramLinks;
