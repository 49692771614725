import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { CounsellingOverviewCard } from "../../../../components/cards/CounsellingOverViewCard";
import { OnboardingOverviewCard } from "../../../../components/cards/OnboardingOverviewCard";
import { UpcomingScheduleCard } from "../../../../components/cards/UpcomingScheduleCard";
import { AppContext, ApplicationActionType } from "../../../../context/ApplicationContext";
import InterviewBookingModal from "../../../Calendly/InterviewBookingModal";
import { OnboardingContext } from "../OnboardingProgressPage";
import useCleverTap, {
  CleverTapEvents,
  ScreenNames,
  ButtonTypes,
} from "../../../../clevertap/useCleverTap";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import { useLocation } from "react-router-dom";

const ScheduleDetails = {
  scheduledDate: "Wed, Oct 13, 2021",
  scheduledTime: "6:00 PM - 7:00 PM",
  title: "",
};

const InterviewSessionCards = () => {
  const [state, dispatch] = useContext(AppContext);
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<"onboarding" | "counselling" | null>(null);
  const onboardingState = useContext(OnboardingContext)[0];

  const hasCompletedLaunchForm = !!onboardingState.onboardingInfo?.launch_form;

  const counselling_sessions =
    state.applicationData?.counselling_sessions && state.applicationData?.counselling_sessions[0];
  const onboarding_sessions =
    state.applicationData?.onboarding_sessions && state.applicationData?.onboarding_sessions[0];

  const clevertap = useCleverTap()[0];
  const location = useLocation();
  const handleBookingSuccess = () => {
    // TODO: update application infromation
    dispatch({
      type: ApplicationActionType.FETCH_APPLICATION,
      payload: {},
    });
  };

  const handleSetInterviewModal = (type: "onboarding" | "counselling") => {
    setType(type);
    setOpen(true);
  };

  return (
    <>
      {open && type !== null && (
        <InterviewBookingModal
          onSuccess={handleBookingSuccess}
          open={open}
          onClose={() => setOpen(false)}
          type={type}
        />
      )}
      <Box mt="20px">
        <SimpleGrid gap={"ms-16"} templateColumns={{ base: "1fr", lg: "1fr 1fr" }}>
          {onboarding_sessions ? (
            <UpcomingScheduleCard
              scheduleDetails={onboarding_sessions}
              title={"Assisted onboarding appointment"}
              handleJoin={() => {
                clevertap.fireEvent(CleverTapEvents.click_join, {
                  screen_name: ScreenNames.onboarding,
                  button_text: "join",
                  button_type: ButtonTypes.secondary,
                  screen_position: "assisted_onboarding_appointment",
                });
              }}
            />
          ) : !hasCompletedLaunchForm ? (
            <OnboardingOverviewCard
              onClick={() => {
                clevertap.fireEvent(CleverTapEvents.click_book_now, {
                  screen_name: ScreenNames.onboarding,
                  button_text: "book_now",
                  button_type: ButtonTypes.secondary,
                  screen_position: "assisted_onboarding_launch_form",
                });
                handleSetInterviewModal("onboarding");
              }}
            />
          ) : null}
          {counselling_sessions ? (
            <UpcomingScheduleCard
              scheduleDetails={counselling_sessions}
              title={"Counselling Session"}
              handleJoin={() => {
                clevertap.fireEvent(CleverTapEvents.click_join, {
                  screen_name: ScreenNames.onboarding,
                  button_text: "join",
                  button_type: ButtonTypes.secondary,
                  screen_position: "counselling_session",
                });
              }}
            />
          ) : (
            <CounsellingOverviewCard
              onClick={() => {
                sendTrackingEvent({
                  event: "book_counselling_session",
                  eventLabel: location.pathname,
                });
                // clevertap.fireEvent(CleverTapEvents.click_book_now, {
                //   screen_name: ScreenNames.onboarding,
                //   button_text: "book_now",
                //   button_type: ButtonTypes.secondary,
                //   screen_position: "counselling_session_launch_form",
                // });
                handleSetInterviewModal("counselling");
              }}
            />
          )}
        </SimpleGrid>
      </Box>
    </>
  );
};

export default InterviewSessionCards;
