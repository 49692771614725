import { Box, Button, Heading, Input } from "@chakra-ui/react";
import { useState } from "react";
import { useMutation } from "react-query";
import { enableDirectPayment } from "../../../../api/utils/api/v2/admin";
import { axiosErrorHandler } from "../../../../api/utils/error";
import useSnackBar from "../../../common/general/SnackBar";
import CreateExternalPayment from "./CreateExternalPayment";

const AllPayments = () => {
  const snackbar = useSnackBar();
  const [email, setEmail] = useState("");

  const { mutate: enableDirectPaymentFn, isLoading: isEnablingDirectPayment } = useMutation(enableDirectPayment, {
    onSuccess: (data) => {
      snackbar.success(data.message);
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const handleDirectPaymentEnable = () => {
    enableDirectPaymentFn(email);
  };

  return (
    <Box>
      <Box display={"flex"} flexDirection={"column"} gap="10px" margin="10px">
        <Heading as="h3">Enable Direct Payment</Heading>
        <Box display={"flex"} flexDirection={"column"} gap="10px" mt="40px" width={"400px"}>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Enter user email below *
          </label>
          <Input name="email" type="email" placeholder="Enter user email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <Button isLoading={isEnablingDirectPayment} isDisabled={!email || isEnablingDirectPayment} width={"100px"} variant="primary" onClick={handleDirectPaymentEnable} ml="auto">
            Enable
          </Button>
        </Box>
      </Box>
      <CreateExternalPayment />
    </Box>
  );
};

export default AllPayments;
