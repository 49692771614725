import { Box } from "@chakra-ui/react";
import React from "react";
import Lottie from "react-lottie-player";
import greenSuccess from "../../../../assets/greenSuccess.json";

const ModalSuccessIcon = () => {
  return <Lottie loop animationData={greenSuccess} play style={{ width: "100%" }} />;
};

export default ModalSuccessIcon;
