import { useQuery } from "react-query";
import { getFounderWebinars, getEvents, getEventById } from "../api/v2/activity";
import { GetEventType } from "../../schemas/schema";

export function useFounderWebinars() {
  return useQuery({
    queryKey: "getEvents",
    queryFn: async () => (await getFounderWebinars()) as GetEventType[],
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,

    refetchOnWindowFocus: false,
    enabled: true,
  });
}

const oneHour = 60 * 60 * 1000;

export function useEvents(event_type?: string) {
  return useQuery({
    queryKey: ["getAllEvents", event_type],
    queryFn: async () => (await getEvents({ event_type })) as GetEventType[],
    staleTime: oneHour,
    retry: 2, // Number of times to retry a failed query
    retryOnMount: true,
    refetchOnWindowFocus: true,
    enabled: true,
  });
}

export function useEvent(event_id?: number, event_type?: string) {
  if (event_id) {
    return useQuery({
      queryKey: ["getEvent", event_id],
      queryFn: async () => (await getEventById({ event_id: event_id })) as GetEventType,
      staleTime: oneHour,
      retry: 2, // Number of times to retry a failed query
      retryOnMount: false,
      refetchOnWindowFocus: true,
      enabled: true,
    });
  } else if (event_type) {
    const events = useEvents(event_type);
    // return first event
    return {
      ...events,
      data: events.data?.[0],
    };
  } else {
    throw new Error("Either event_id or event_type should be provided");
  }
}
