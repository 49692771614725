import React from "react";
import { Box, Text } from "@chakra-ui/react";

interface PaymentNoteProps {
  courseStartDate: string;
}

const PaymentNote: React.FC<PaymentNoteProps> = ({ courseStartDate }) => {
  return (
    <Box p={2} mt={5} fontSize="sm" fontStyle="italic" lineHeight="6" bg="#FFF3CC" borderRadius="md" border="1px" borderColor="amber.200" color="stone.900" maxW="full">
      <Text as="span" fontStyle="italic">
        Note: First instalment to be paid before course start date{" "}
      </Text>
      <Text as="span" fontStyle="italic" fontWeight="bold">
        {courseStartDate}
      </Text>
      <Text as="span" fontStyle="italic">
        {" "}
        . Our NBFC team will connect for further instalments.
      </Text>
    </Box>
  );
};

export default PaymentNote;
