import { GetEventType, GetEventProfile } from "../../schemas/schema";
import api from "../axios";

export const getEvents = async (): Promise<GetEventType[]> => {
  const client = await api.client;
  const response = await client.list_events_events_get();
  return response.data;
};

export const getEventById = async (id: string): Promise<GetEventType> => {
  const client = await api.client;
  const response = await client.get_event_events__event_id__get(id);
  return response.data;
};

export const bookEvent = async (
  event_id: number,
  profile_slug: string
): Promise<GetEventProfile> => {
  const client = await api.client;
  const response = await client.register_event_events__event_id__register__profile_slug__post({
    event_id: event_id,
    profile_slug: profile_slug,
  });
  return response.data;
};
