import { Box, Button, Text, useBreakpointValue } from "@chakra-ui/react";
import Drawer from "react-drag-drawer";
import React, { ReactElement, useEffect, useState } from "react";
import DostChatBubble from "./DostChatBubble";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { InputCustom, ModalTemplate, ModalTemplateV2 } from "../../common/general";
import { useMutation } from "react-query";
import useSnackBar from "../../common/general/SnackBar";
import { axiosErrorHandler } from "../../../api/utils/error";
import { dostRequestCall } from "../../../api/utils/api/v2/activity";
import { useDost } from "../../../api/utils/hooks/useDost";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import { useHistory, useLocation } from "react-router-dom";
import { sendTrackingEvent } from "../../common/utils/gtm";
import GreenCheckCircle from "../../../assets/icons/components/GreenCheckCircle";

interface chatDataProps {
  text: string | ReactElement;
  timer: number;
  delay: number;
  videoUrl?: string;
}

const DostChatFlow = () => {
  const history = useHistory();
  const snackbar = useSnackBar();
  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentChatId, setCurrentChatId] = useState(0);
  const [studentQuery, setStudentQuery] = useState<string>("");
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const { data: profile, isLoading: profileLoading } = useProfile();
  const { data: dostData, isLoading: isDostDataLoading, refetch: refetchDostData } = useDost();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const data: chatDataProps[] = [
    {
      text: (
        <>
          <strong>Hey {profile?.name},</strong> <br />
          Welcome to Masai! ⚡️
        </>
      ),
      timer: 2000,
      delay: 1000,
    },
    {
      text: `I’m ${dostData?.name}, your Dost.`,
      timer: 1300,
      delay: 3000,
    },
    {
      text: "We at Masai are thrilled to see you a step closer to your dream career 🤩",
      timer: 2300,
      videoUrl: "",
      delay: 2000,
    },
    {
      text: "Here a small video to know me better",
      timer: 3000,
      videoUrl: "https://youtu.be/xw9jOaRZRa0",
      // videoUrl: "https://youtu.be/s7X_uPyPJvE",
      delay: 1000,
    },
    {
      text: "Have a question? Let’s connect.✨",
      timer: 3000,
      delay: 1200,
    },
  ];

  const modalProps = {
    open: openDrawer,
    modalElementClass: "bottomDrawerClass",
    onRequestClose: () => {
      setOpenDrawer(false);
      setShowSuccess(false);
    },
    // if user is at the top then only allow to close the drawer
    notifyWillClose: () => {
      setOpenDrawer(false);
      setShowSuccess(false);
    },
  };

  const { mutate: requestDost, isLoading: isRequestDostLoading } = useMutation(dostRequestCall, {
    onSuccess: () => {
      // setOpenModal(true);
      setShowSuccess(true);
      refetchDostData();
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  useEffect(() => {
    if (!isDostDataLoading && dostData?.me_registered) {
      history.push("/");
    }
  }, [isDostDataLoading, dostData]);
  const location = useLocation();
  return (
    <Box position="relative">
      <Box minH={"100vh"} pb="30px" px="16px" display="flex" gap="20px" flexDir="column">
        {data &&
          data.length > 0 &&
          data.map(
            (chat, index) =>
              currentChatId >= index && (
                <DostChatBubble
                  key={index}
                  {...chat}
                  index={index}
                  onDone={(index: number) => {
                    const chatDelayTimer: NodeJS.Timeout = setTimeout(() => {
                      setCurrentChatId(index + 1);
                      clearTimeout(chatDelayTimer);
                    }, chat.delay || 0);
                  }}
                />
              )
          )}
      </Box>

      <Box
        bg="white"
        boxShadow="0px -8px 32px 0px rgba(0, 0, 0, 0.10)"
        position="sticky"
        bottom="0px"
        p="8px 16px"
        w="full"
        display="flex"
        justifyContent="flex-end"
        zIndex="10"
      >
        <Button
          variant="primary"
          rightIcon={<CheckCircleIcon />}
          onClick={() => {
            sendTrackingEvent({ event: "d_dost_connect", eventLabel: location.pathname });
            setOpenDrawer(true);
          }}
        >
          let’s get connected
        </Button>
      </Box>

      <Box
        as={isMobile ? Drawer : ModalTemplate}
        title=""
        marginX={0}
        paddingX="0px"
        paddingY="0px"
        maxWidth="343px"
        onClose={() => {
          setOpenDrawer(false);
          setShowSuccess(false);
        }}
        isOpen={openDrawer}
        {...modalProps}
      >
        {showSuccess ? (
          <>
            <Box w="full" bg="white" pt="40px" maxW={{ base: "267px", md: "full" }} mx="auto">
              <Box mx="auto" h="56px" w="56px">
                <GreenCheckCircle boxSize="56px" />
              </Box>
              <Text
                mt="24px"
                w={{ base: "full", md: "319px" }}
                textStyle={{ base: "body1-md", md: "h4" }}
                textAlign="center"
              >
                We have received your queries, you will be receiving a reply from dost shortly.
              </Text>
            </Box>
            <Box textAlign="center">
              <Button
                onClick={() => {
                  setOpenDrawer(false);
                  setShowSuccess(false);
                }}
                variant="primary"
                w={{ base: "full", md: "fit-content" }}
                textStyle="btn-md"
                size="md"
                mt="46px"
              >
                continue
              </Button>
            </Box>
          </>
        ) : (
          <Box w={{ base: "full", md: "477px" }} bg="white">
            <Text mb="16px" textStyle={{ base: "h5", md: "h3" }} color="#0A0103" textAlign="center">
              Get in touch!
            </Text>
            <InputCustom
              p="8px"
              type="textarea"
              minHeight="133px"
              getValueCallback={(value) => {
                setStudentQuery(value.trim());
              }}
              placeholder="Mention your query..."
              label={`What would you like to ask Dost?`}
            />
            <Box textAlign={{ base: "center", md: "right" }}>
              <Button
                isLoading={isRequestDostLoading}
                onClick={() => {
                  requestDost({
                    student_query: studentQuery,
                  });
                }}
                variant="primary"
                w={{ base: "full", md: "fit-content" }}
                textStyle="btn-md"
                size="md"
                mt={{ base: "36px", md: "16px" }}
              >
                submit
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      {/* // <ModalTemplateV2
      //   autoCloseInSeconds={5}
      //   closeOnOverlayClick={true}
      //   isOpen={openModal}
      //   onClose={() => {
      //     console.log("hi");
      //   }}
      //   text="You will be receiving a call from your dost shortly."
      //   extra={
      //     <>
      //       <Button mt="28px" variant="secondary" onClick={() => history.push("/")}>
      //         back to dashboard
      //       </Button>
      //     </>
      //   }
      // /> */}
    </Box>
  );
};

export default DostChatFlow;
