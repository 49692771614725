import { Box, Heading } from "@chakra-ui/react";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import ProfileFields from "../../../components/V2/Profile/ProfileFields";
import { ProfileImage } from "../../../components/V2/Profile/ProfileImage";

const ProfileV2 = () => {
  const { data: profile } = useProfile();
  const isMasaiCoursePrefered = profile?.meta?.isMasaiCoursePrefered === "True";
  return (
    <Box minH="100vh" height={"100%"} p="24px" bgColor="ms-blue.50">
      <Heading textAlign={{ base: "center", md: "start" }} as="h3">
        My Profile
      </Heading>
      <Box
        justifyContent={{ base: "center", md: "start" }}
        mt="16px"
        display={"flex"}
        alignItems={"center"}
        gap="16px"
      >
        <ProfileImage />
        <Heading as="h5">{profile?.name}</Heading>
      </Box>

      <ProfileFields isMasaiCoursePrefered={isMasaiCoursePrefered} />
    </Box>
  );
};

export default ProfileV2;
