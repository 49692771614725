import {
  PatchProfileType,
  GenderType,
  ProfessionalStatus,
  UGDEGREE,
  PGDEGREE,
  GetProfileType,
} from "../../schemas/schema";
import api from "../axios";

export const sendOtpToUser = async ({
  profile_slug,
}: {
  profile_slug: string;
}): Promise<PatchProfileType> => {
  const client = await api.client;
  const response = await client.send_otp_profiles__profile_slug__send_otp_post({
    profile_slug,
  });
  return response.data;
};

export const verifyOTPUser = async ({
  profile_slug,
  otp_input,
}: {
  profile_slug: string;
  otp_input: number;
}): Promise<PatchProfileType> => {
  const client = await api.client;
  const response = await client.verify_profiles__profile_slug__verify_otp_post({
    profile_slug,
    otp_input,
  });
  return response.data;
};

export const updateProfile = async (
  profile_slug: string,
  {
    name,
    phone_number,
    twelfth_pass,
    graduation_year,
    graduation_month,
    date_of_birth,
    distance_learning,
    job_after_graduation,
    masai_newsletter,
    email_updates,
    whatsapp_updates,
    gender,
    referred_by,
    referral_code,
    ivr_consent,
    professional_status,
    tenth_percentage,
    twelfth_percentage,
    ug_percentage,
    pg_percentage,
    ug_degree,
    pg_degree,
    college_id,
    tech_experience,
    job_role,
    organization_name,
  }: {
    name?: string;
    phone_number?: string;
    twelfth_pass?: string;
    graduation_year?: string;
    graduation_month?: string;
    date_of_birth?: string;
    distance_learning?: boolean;
    job_after_graduation?: boolean;

    masai_newsletter?: boolean;
    email_updates?: boolean;
    whatsapp_updates?: boolean;
    gender?: GenderType;
    referred_by?: string;
    referral_code?: string;
    ivr_consent?: boolean;
    professional_status?: ProfessionalStatus;
    tenth_percentage?: number;
    twelfth_percentage?: number;
    ug_percentage?: number;
    pg_percentage?: number;
    ug_degree?: UGDEGREE;
    pg_degree?: PGDEGREE;
    college_id?: number;
    tech_experience?: number;
    job_role?: string;
    organization_name?: string;
  }
): Promise<GetProfileType> => {
  const client = await api.client;
  const response = await client.patch_profile_profiles__profile_slug__patch(
    { profile_slug },
    {
      name,
      phone_number,
      twelfth_pass,
      graduation_year,
      graduation_month,
      date_of_birth,
      distance_learning,
      job_after_graduation,

      masai_newsletter,
      email_updates,
      whatsapp_updates,
      gender,
      ivr_consent,
      professional_status,
      tenth_percentage,
      twelfth_percentage,
      ug_percentage,
      pg_percentage,
      ug_degree,
      pg_degree,
      college_id,
      tech_experience,
      job_role,
      organization_name,
      referral_code,
    }
  );
  return response.data;
};


export const updateProfileConsentValues = async (
  {
    ivr_consent,
    masai_newsletter,
    email_updates,
    whatsapp_updates,
  }: {
    ivr_consent: boolean;
    masai_newsletter: boolean;
    email_updates: boolean;
    whatsapp_updates: boolean;
  }
): Promise<GetProfileType> => {
  const client = await api.client;
  const response = await client.patch_profile_consent_values_profiles_update_consent_patch(
    {},
    {
      ivr_consent,
      masai_newsletter,
      email_updates,
      whatsapp_updates,
    }
  );
  return response.data;
};