import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const AccountTieVoice = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M16.75 4.35999C18.77 6.55999 18.77 9.60999 16.75 11.63L15.07 9.93999C15.91 8.75999 15.91 7.22999 15.07 6.04999L16.75 4.35999ZM20.06 0.99999C24 5.04999 23.96 11.11 20.06 15L18.43 13.37C21.2 10.19 21.2 5.64999 18.43 2.62999L20.06 0.99999ZM9.00002 3.99999C11.2 3.99999 13 5.78999 13 7.99999C13 10.21 11.2 12 9.00002 12C6.80002 12 5.00002 10.21 5.00002 7.99999C5.00002 5.78999 6.79002 3.99999 9.00002 3.99999ZM13 14.54C13 15.6 12.71 18.07 10.8 20.83L10 16L10.93 14.12C10.31 14.05 9.66002 14 9.00002 14C8.34002 14 7.67002 14.05 7.05002 14.12L8.00002 16L7.18002 20.83C5.27002 18.07 5.00002 15.6 5.00002 14.54C2.60002 15.24 0.994019 16.5 0.994019 18V22H17V18C17 16.5 15.39 15.24 13 14.54Z"
      fill="currentColor"
    />
  </Icon>
);

export default AccountTieVoice;
