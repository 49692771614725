import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const CalendarClock = (props: Props) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M12.5 10.8333H13.75V13.1833L15.7833 14.3583L15.1583 15.4416L12.5 13.9083V10.8333ZM15.8333 6.66658H4.16667V15.8333H8.05833C7.7 15.0749 7.5 14.2249 7.5 13.3333C7.5 11.7862 8.11458 10.3024 9.20854 9.20846C10.3025 8.1145 11.7862 7.49992 13.3333 7.49992C14.225 7.49992 15.075 7.69992 15.8333 8.05825V6.66658ZM4.16667 17.4999C3.24167 17.4999 2.5 16.7499 2.5 15.8333V4.16658C2.5 3.24159 3.24167 2.49992 4.16667 2.49992H5V0.833252H6.66667V2.49992H13.3333V0.833252H15V2.49992H15.8333C16.2754 2.49992 16.6993 2.67551 17.0118 2.98807C17.3244 3.30063 17.5 3.72456 17.5 4.16658V9.24992C18.5333 10.2999 19.1667 11.7416 19.1667 13.3333C19.1667 14.8803 18.5521 16.3641 17.4581 17.458C16.3642 18.552 14.8804 19.1666 13.3333 19.1666C11.7417 19.1666 10.3 18.5333 9.25 17.4999H4.16667ZM13.3333 9.29158C12.2614 9.29158 11.2334 9.7174 10.4754 10.4754C9.71748 11.2333 9.29167 12.2613 9.29167 13.3333C9.29167 15.5666 11.1 17.3749 13.3333 17.3749C13.8641 17.3749 14.3897 17.2704 14.88 17.0673C15.3704 16.8642 15.8159 16.5664 16.1912 16.1911C16.5665 15.8158 16.8642 15.3703 17.0673 14.8799C17.2705 14.3896 17.375 13.864 17.375 13.3333C17.375 11.0999 15.5667 9.29158 13.3333 9.29158Z"
      fill="currentColor"
    />
  </Icon>
);

export default CalendarClock;
