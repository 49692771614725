import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export function convertMsToTime(milliseconds: number) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  hours = hours % 24;
  // add hours min and sec to the string with padding
  return `${padTo2Digits(hours)}h : ${padTo2Digits(minutes)}m : ${padTo2Digits(seconds)}s`;
}

export function getFormattedDate(date: string) {
  const istDate = dayjs(date).utc().tz("Asia/Kolkata");
  const formattedDate = istDate.format("DD MMM YYYY");
  return formattedDate;
}
