import { Box, Text, Image } from "@chakra-ui/react";
import React from "react";
import { TriangeVector } from "../../assets/images";

interface Props {
  text?: string;
  bgColor?: string;
  color?: string;
}
const CourseCardTag = ({ text = "NA", color = "ms-red.500", bgColor = "ms-red.50" }: Props) => {
  return (
    <Box m={"ms-16"} d={"flex"} flexDirection={"column"}>
      <Box
        alignSelf={"flex-start"}
        bgColor={bgColor}
        px={"ms-8"}
        py={"ms-4"}
        borderRadius="8px 8px 8px 0px"
      >
        <Text color={color} fontWeight={700} fontSize={"14px"} lineHeight={"24px"}>
          {text}
        </Text>
      </Box>
      <Box>
        <Image src={TriangeVector} />
      </Box>
    </Box>
  );
};

export default CourseCardTag;
