import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";

const OnboardingCompletedScrren = () => {
  return (
    <Box>
      <Text textStyle={"h3"} display={"flex"} alignItems={"center"} gap={1}>
        {" "}
        <Image src="/img/iit-course/darkGreenTick.svg" />
        Onboarding Complete
      </Text>
      <Text mt={4} textStyle={"body1"}>
        Here&apos;s what&apos;s ahead! Our team will guide you through each step:
      </Text>
      <Box color={"#3B3435"} pl={3}>
        <Text textStyle={"body1"} py={"1px"}>
          1. Slack Onboarding{" "}
        </Text>
        <Text textStyle={"body1"} py={"1px"}>
          2. Course Platform Setup{" "}
        </Text>
        <Text textStyle={"body1"} py={"1px"}>
          3. Zoom Nolan Authentication
        </Text>
        <Text textStyle={"body1"} py={"1px"}>
          4. Orientation Event
        </Text>
      </Box>
    </Box>
  );
};

export default OnboardingCompletedScrren;
