import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

type Props = {
  title: string;
  description: string;
  ogTitle: string;
  ogDescription: string;
  ogImageUrl: string;
  ogUrl: string;
};

const SEO = ({ title, description, ogTitle, ogDescription, ogImageUrl, ogUrl }: Props) => {
  useEffect(() => {
    document.title = title;
    const descMeta = document.querySelector("meta[name='description']");
    if (descMeta) descMeta.setAttribute("content", description);

    const orUrlContent = document.querySelector("meta[property='og:url']");
    if (orUrlContent) orUrlContent.setAttribute("content", ogUrl);

    const ogTitleCOntent = document.querySelector("meta[property='og:title']");
    if (ogTitleCOntent) ogTitleCOntent.setAttribute("content", ogTitle);

    const ogDescContent = document.querySelector("meta[property='og:description']");
    if (ogDescContent) ogDescContent.setAttribute("content", ogDescription);

    const ogImageContent = document.querySelector("meta[property='og:image']");
    if (ogImageContent) ogImageContent.setAttribute("content", ogImageUrl);
  }, []);
  return (
    <Helmet>
      {/* ------ Primary Meta Tags ------ */}
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* ----------- Open Graph ---------- */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={ogImageUrl} />
    </Helmet>
  );
};

export default SEO;
