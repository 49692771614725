import React from "react";

import { Box } from "@chakra-ui/react";
import ProfileFieldCard from "./ProfileFieldCard";
import { useProfile } from "../../../api/utils/hooks/useProfile";

const MobileNumber = () => {
  const { data: profile } = useProfile();
  return (
    <Box>
      <ProfileFieldCard keyName="phone" title={profile?.phone_number} heading="Mobile Number" />
    </Box>
  );
};

export default MobileNumber;
