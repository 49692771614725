export enum ProgramProfileType {
  IIT_GUWAHATI = "IIT_GUWAHATI",
  IIT_MANDI = "IIT_MANDI",
  MASAI = "MASAI",
  PREPLEAF = "PREPLEAF",
  IIT_ROPAR = "IIT_ROPAR",
  IIT_MANDI_V2 = "IIT_MANDI_V2",
}

export enum CourseSelected {
  IIT_GUWAHATI = "IIT_GUWAHATI",
  IIT_MANDI = "IIT_MANDI",
  IIT_ROPAR = "IIT_ROPAR",
  IIT_MANDI_V2 = "IIT_MANDI_V2",
  MASAI = "MASAI",
}

export enum PaymentType {
  REGISTRATION_FEES = "REGISTRATION_FEES",
  SEMESTER_FEES = "SEMESTER_FEES",
  SEMESTER_FEES_EMI = "SEMESTER_FEES_EMI",
}

export enum BatchTypes {
  SD = "SD",
  DA = "DA",
  EMPOWER_HER = "EMPOWER_HER",
}
