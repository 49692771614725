import { Box, useBreakpointValue } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";

import { Constants } from "../../../../components/common/constants";
import { BottomStickyNavbar, ModalTemplate } from "../../../../components/common/general";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import { AppContext, ApplicationActionType } from "../../../../context/ApplicationContext";
import CalendlyConfirmModalContent from "./CalendlyConfirmModalContent";
import CurrentStage from "./CurrentStage";
import InterviewPassedModal from "./InterviewPassedModal";
import MasaiXInterviewBookingModal from "./MasaiXInterviewBookingModal";
import MasaiXInterviewCard from "./MasaiXInterviewCard";
import MasaiXInterviewRealodText from "./MasaiXInterviewRealodText";
import MasaiXInterviewTips from "./MasaiXInterviewTips";

export type MasaiXStageType =
  | "MSAT"
  | "CODING"
  | "AADHAAR_VERIFICATION"
  | "INTERVIEW"
  | "ONBOARDING";

interface Props {
  currentStage: MasaiXStageType;
  bookingStatus?: boolean;
}
const MasaiInterviewPending = ({ currentStage, bookingStatus }: Props) => {
  const [calendlyConfirmModal, setCalendlyConfirmModal] = useState(false);
  const [interviewPassedModal, setInterviewPassedModal] = useState(false);
  const [bookingDetails, setBookingDetails] = useState<{
    end_date_time: string;
    start_date_time: string;
    join_url: string;
  }>({
    end_date_time: "",
    start_date_time: "",
    join_url: "",
  });

  useEffect(() => {
    if (bookingStatus) {
      const interview_booking_details = state.applicationData?.interview_booking;
      if (interview_booking_details && interview_booking_details.length >= 1) {
        setBookingDetails({
          start_date_time: interview_booking_details[0].start_date_time,
          end_date_time: interview_booking_details[0].end_date_time,
          join_url: interview_booking_details[0].join_url,
        });
      }
    }
  }, [bookingStatus]);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const handleCalendlyBooking = () => {
    setOpen(true);
    setCalendlyConfirmModal(false);
  };
  // calendly flow start
  const [state, dispatch] = useContext(AppContext);
  const [open, setOpen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const handleBookingSuccess = () => {
    // TODO: update application infromation
    dispatch({
      type: ApplicationActionType.FETCH_APPLICATION,
      payload: {},
    });
  };

  // calendly flow end
  return (
    <>
      <Box mb={Constants.bottomNavbarHeight}>
        {open && (
          <MasaiXInterviewBookingModal
            onClose={() => setOpen(false)}
            onSuccess={handleBookingSuccess}
            open={open}
          />
        )}
        {/* Current Stage */}
        <CurrentStage
          customMasaiXStagesText={[
            "Coding Test",
            "Technical Interview",
            "Aadhaar Verification",
            "Onboarding",
          ]}
          currentStage={currentStage}
          customMasaiXStagesMargin={["-24px", "-54px", "-55px", "-24px"]}
        />

        <Box px={{ base: "ms-16", md: "ms-24" }} pb={"4rem"}>
          <Box>
            <MasaiXInterviewCard
              bookingStatus={bookingStatus}
              bookingDetails={bookingDetails}
              setCalendlyConfirmModal={setCalendlyConfirmModal}
            />
            <Box mt={"ms-16"} display={{ base: "block", md: "none" }}>
              <MasaiXInterviewRealodText bookingStatus={bookingStatus} />{" "}
            </Box>
          </Box>
          <Box mt={"ms-24"}>
            <MasaiXInterviewTips />
          </Box>
          <Box mt={"80px"} display={{ base: "none", md: "block" }}>
            <MasaiXInterviewRealodText bookingStatus={bookingStatus} />{" "}
          </Box>
        </Box>
      </Box>
      <ModalTemplate
        title=""
        isOpen={calendlyConfirmModal}
        onClose={() => {
          sendTrackingEvent({
            event: "mx_ibp_calendly_db_x",
            eventLabel: location.pathname,
          });
          setCalendlyConfirmModal(false);
        }}
        marginX={2}
        maxWidth="552px"
        showCloseButtonIcon={true}
      >
        <CalendlyConfirmModalContent
          handleCalendlyBooking={() => {
            sendTrackingEvent({
              event: "mx_ibp_calendly_db_continue",
              eventLabel: location.pathname,
            });
            handleCalendlyBooking();
          }}
        />
      </ModalTemplate>
      <ModalTemplate
        title=""
        isOpen={interviewPassedModal}
        onClose={() => {
          setInterviewPassedModal(false);
        }}
        marginX={2}
        maxWidth="552px"
        closeOnOverlayClick={false}
      >
        <InterviewPassedModal />
      </ModalTemplate>
      {isMobile && (
        <BottomStickyNavbar
          // loading={loading}
          btnPrimaryProps={{
            size: isMobile ? "sm" : "lg",
            onClick: bookingStatus
              ? () => {
                  window.open(bookingDetails.join_url);
                }
              : () => setCalendlyConfirmModal(true),
          }}
          btnPrimary={bookingStatus ? "join interview" : "schedule interview"}
        />
      )}
    </>
  );
};

export default MasaiInterviewPending;
