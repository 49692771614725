import { Box, Flex, Skeleton, Text } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { Steps } from "./types";
import Onboarding from "./Onboarding";
import { useParams } from "react-router-dom";
import useSnackBar from "../../../../components/common/general/SnackBar";
import { submitLaunchFormOnbaording } from "../../../../api/utils/api/onboarding";
import { OnboardingContext } from "../OnboardingProgressPage";
import { CheckCircle } from "../../../../assets/icons/components";
import { AppContext } from "../../../../context/ApplicationContext";
import dayjs from "dayjs";
import NewLaunchForm from "../new-launch-form/NewLaunchForm";
import { axiosErrorHandler } from "../../../../api/utils/error";
import "./mailgo.css";

import { BannerZoomTelegramLinks } from "./BannerZoomTelegramLinks";

const LaunchFormSuccessful = () => {
  return (
    <Flex gap="1rem">
      <Box>
        <CheckCircle boxSize="24px" color="ms-success" />
      </Box>
      <Box>
        <Text textStyle="body1">You have successfully submitted the launch form!</Text>
      </Box>
    </Flex>
  );
};

export const StepOne = ({ handleStepCick }: { handleStepCick: (step: Steps) => void }) => {
  // TODO: retreive selected value, and disable if step already completed
  const params = useParams() as { applicantID: string };
  const [loading, setLoading] = useState<boolean>(false);
  const snackbar = useSnackBar();
  const [state, dispatch] = useContext(OnboardingContext);
  const appState = useContext(AppContext)[0];
  const handleSubmit = async (formValues: { [key: string]: string }[]) => {
    try {
      setLoading(true);
      // update formValues Structure from array of obejcts to object
      const updatedFormValues: { [key: string]: string } = {};
      formValues.forEach((formValue) => {
        const key: string = Object.keys(formValue)[0];
        const value: string = Object.values(formValue)[0];
        updatedFormValues[key] = value;
      });
      const res = await submitLaunchFormOnbaording({
        application_slug: params.applicantID,
        launch_form: updatedFormValues, // TODO: convert to dict
      });
      dispatch({
        type: "REFRESH_ONBOARDING_STATUS",
        payload: {},
      });
      snackbar.success("Successfully sumbitted!");
    } catch (err) {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    }
    setLoading(false);
  };
  const start_date = appState.applicationData?.batch_campus.batch.start_date;
  return (
    <>
      <BannerZoomTelegramLinks />
      <Onboarding
        statusArray={["in-progress", "pending"]}
        subtitle={
          <>
            Just a few more steps and you are all set to start classes from{" "}
            <b>{start_date && dayjs(start_date).format("DD MMM YYYY")}</b>
          </>
        }
        handleStepCick={handleStepCick}
      >
        <Box mt={9}>
          <Skeleton isLoaded={!loading}>
            {state.onboardingInfo?.launch_form ? (
              <LaunchFormSuccessful />
            ) : (
              <NewLaunchForm applicantID={params.applicantID} onSubmit={handleSubmit} />
            )}
          </Skeleton>
        </Box>
      </Onboarding>
    </>
  );
};