import { Box, Button, Heading, Image, Spinner, Text, useBreakpointValue } from "@chakra-ui/react";
import { useCallback, useContext, useEffect, useState } from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  generateRazorpayOrderId,
  proceedToPaymentPending,
  razorpaySuccessReport,
} from "../../api/utils/api";
import { axiosErrorHandler } from "../../api/utils/error";
import { AppContext } from "../../context/ApplicationContext";
import useApplicationHook from "../../hooks/useApplicationHook";
import numberWithCommas from "../../pages/Courses/utils/numberWithCommas";
import { ModalTemplate } from "../common/general";
import useSnackBar from "../common/general/SnackBar";
import { CAPCouponVarifiy } from "./CAPCouponVerifiy";
import successImage from "../../assets/images/success.gif";
import { sendTrackingEvent } from "../common/utils/gtm";
interface Props {
  coursePrice?: number;
  display: "block" | "none";
  isModal?: boolean;
}

const CAPInstructionsPrice = ({ coursePrice, display, isModal }: Props) => {
  const history = useHistory();
  const snackbar = useSnackBar();
  const location = useLocation();
  const state = useContext(AppContext)[0];
  const [disCountPrecentage, setDisCountPrecentage] = useState(0);
  const { fetchApplicationData } = useApplicationHook()[1];
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams() as { applicantID: string };
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [changeDiscount, setChangeDiscount] = useState(true);

  const headingSize = useBreakpointValue<"h4" | "h3">({
    base: "h4",
    md: "h3",
  });
  const PriceSize = useBreakpointValue<
    { price: "h2"; cutPrice: "18px" } | { price: "h1"; cutPrice: "24px" }
  >({
    base: { price: "h2", cutPrice: "18px" },
    md: { price: "h1", cutPrice: "24px" },
  });
  const btnSize = useBreakpointValue<"md" | "lg">({
    base: "md",
    md: "lg",
  });
  const successModalStyle = useBreakpointValue<
    | { btnSize: "sm"; heading: "h4"; textStyle: "body2" }
    | { btnSize: "md"; heading: "h3"; textStyle: "body1" }
  >({
    base: { btnSize: "sm", heading: "h4", textStyle: "body2" },
    md: { btnSize: "md", heading: "h3", textStyle: "body1" },
  });

  // display razorpay modal once order id is available
  const loadScript = (src: string) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const handleProceedToPayment = async () => {
    setLoading(true);
    try {
      if (state.applicationData?.status != "PAYMENT_PENDING") {
        await proceedToPaymentPending({ application_slug: state.applicationData?.slug || "" });
      }

      await startRazorpayPayment("full-payment");
    } catch (err) {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    } finally {
      setLoading(false);
      fetchApplicationData && fetchApplicationData(params.applicantID);
    }
  };
  const startRazorpayPayment = async (paymentType: "full-payment" | "subscription-payment") => {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      snackbar.error("Payment Gateway failed to load. Please check internet connection!");
      return;
    }
    const courseName = state.applicationData?.batch_campus.batch.course.name;
    const appSlug = state.applicationData?.slug;
    if (!appSlug) return;
    try {
      const res = await generateRazorpayOrderId({
        application_slug: appSlug,
        paymentType,
        payment_consent: true,
        source: localStorage.getItem("source") || "ONWARD_CAP_PAYMENT_DETAILS_PAGE",
      });
      snackbar.success("Starting payment process!");
      const options = {
        key: process.env.REACT_APP_RAZORPAY_CLIENT_ID || "",
        order_id: res?.order_id,
        // order_id: paymentType === "full-payment" ? res?.order_id : undefined,
        // subscription_id: paymentType === "subscription-payment" ? res?.subscription_id : undefined,
        name: `${courseName}`,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        handler: (response: any) => {
          console.log("Razorpay response: ", response);
          if (
            response?.razorpay_payment_id &&
            (response?.razorpay_order_id || response?.razorpay_subscription_id) &&
            response?.razorpay_signature
          ) {
            setLoading(true);
            // send request to backend to verify payment
            razorpaySuccessReport({
              profile_slug: state.profileData?.slug,
              application_slug: state.applicationData?.slug,
              razorpay_payment_id: response?.razorpay_payment_id,
              razorpay_order_id: response?.razorpay_order_id,
              razorpay_subscription_id: response?.razorpay_subscription_id,
              razorpay_signature: response?.razorpay_signature,
            })
              .then((res) => {
                // snackbar.success("Payment Successful!");

                history.push(
                  `/batch/${state.applicationData?.batch_campus.batch_id}/${appSlug}/payment-success`
                );
              })
              .catch((err) => {
                const e = axiosErrorHandler(err);
                if (typeof e === "object" && "message" in e) {
                  snackbar.error(e.message);
                } else {
                  snackbar.error("something went wrong");
                }
                history.push(
                  `/batch/${state.applicationData?.batch_campus.batch_id}/${appSlug}/instructions`
                );
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            // move to payment failed page
            history.push(
              `/batch/${state.applicationData?.batch_campus.batch_id}/${appSlug}/instructions`
            );
          }
        },
        ondismiss: () => {
          console.log("razorpay payment cancelled");
        },
      };
      if (window && window.Razorpay) {
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }
    } catch (err) {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    }
  };

  const calculatedPrice = useCallback(() => {
    let price = coursePrice || 0;
    if (coursePrice && disCountPrecentage) {
      price = coursePrice - (coursePrice / 100) * disCountPrecentage;
    }
    return price;
  }, [coursePrice, disCountPrecentage]);

  useEffect(() => {
    if (!changeDiscount) {
      return;
    }
    const discountPercent = state.applicationData?.current_coupon?.discount_percentage || 0;
    setDisCountPrecentage((prev: number) => {
      if (prev < discountPercent) {
        return discountPercent;
      }
      return prev;
    });
  }, [state]);
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <>
      <Box display={display} maxW="699px">
        <Box
          textAlign={{ base: "center" }}
          borderRadius={"16px"}
          boxShadow="0px -2px 11px -2px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
          px={"ms-16"}
          pt={"ms-24"}
        >
          <Box
            display={{ base: "flex", md: "block" }}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Heading mt={{ base: "0", md: "ms-8" }} as={headingSize}>
              Hurry Up! Reserve your seat now {!isMobile && <br />}
              by paying
            </Heading>
          </Box>
          <Box mt={{ base: "ms-12", md: "ms-8" }}>
            <Heading d={"inline"} as={PriceSize?.price} color={"ms-blue.500"}>
              ₹{""}
              {numberWithCommas(calculatedPrice())}
            </Heading>
            <Text
              ml="10px"
              d={"inline-flex"}
              fontWeight={700}
              fontFamily="poppins"
              fontSize={disCountPrecentage ? PriceSize?.cutPrice : 0}
              lineHeight={0}
              transition="font-size .5s ease"
              textDecor="line-through"
              color={"ms-blue.500"}
            >
              ₹{coursePrice}
            </Text>
          </Box>

          <CAPCouponVarifiy
            onRemove={() => {
              sendTrackingEvent({
                event: "dash_reserve_seat_remove_coupon",
                eventLabel: location.pathname,
              });
              setDisCountPrecentage(0);
              setChangeDiscount(false);
            }}
            onCheck={() => {
              sendTrackingEvent({
                event: "dash_reserve_seat_apply_coupon",
                eventLabel: location.pathname,
              });
              setChangeDiscount(true);
            }}
            coursePrice={coursePrice}
            discountPercentage={disCountPrecentage}
          />

          <Button
            maxW="100%"
            w="100%"
            size={btnSize}
            variant="primary"
            mt={"ms-16"}
            textStyle={{ base: "btn-md", md: "btn-lg" }}
            onClick={() => {
              sendTrackingEvent({
                event: "dash_reserve_seat_button",
                eventLabel: location.pathname,
              });
              handleProceedToPayment();
            }}
            disabled={loading}
          >
            reserve your seat
            {loading && (
              <Spinner
                speed={"0.5s"}
                thickness={"2px"}
                size={"md"}
                position={"absolute"}
                color={"ms-blue.500"}
              />
            )}
          </Button>
          <Box p={"16px"} pt={{ base: "16px", md: "24px" }}>
            <Text textStyle={{ base: "body2-md", md: "body1-md" }} color={"ms-grey.600"}>
              🔥 Enroll today, limited seats available.
            </Text>
          </Box>
        </Box>
      </Box>

      <ModalTemplate
        title=""
        marginX={2}
        maxWidth="484px"
        onClose={() => {
          console.log("close");
        }}
        isOpen={openSuccessModal}
        closeOnOverlayClick={false}
        showCloseButtonIcon={false}
      >
        <Box textAlign={"center"}>
          <Box overflow={"hidden"}>
            <Image
              w="120px"
              m="auto"
              alt="Success"
              pointerEvents="none"
              transform="scale(2)"
              src={successImage}
            />
          </Box>
          <Heading as={successModalStyle?.heading}>Payment Successful</Heading>
          <Text mt="ms-8" textStyle={successModalStyle?.textStyle}>
            Payment successful, complete the onboarding procedures and you are all set!
          </Text>
          <Button
            mt="ms-24"
            size={successModalStyle?.btnSize}
            variant={"primary"}
            onClick={() => {
              sendTrackingEvent({
                event: "dash_after_reserve_seat_continue_btn",
                eventLabel: location.pathname,
              });
              history.push(
                `/batch/${state.applicationData?.batch_campus.batch_id}/${state.applicationData?.slug}/payment-success`
              );
            }}
          >
            Continue
          </Button>
        </Box>
      </ModalTemplate>
    </>
  );
};

export default CAPInstructionsPrice;
