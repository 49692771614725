<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
></svg>;
import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const InProgressStepperIcon = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <circle cx="12" cy="12" r="12" fill="currentColor" />
    <path
      d="M15.5333 8.46675C14.5583 7.49175 13.2833 7.00008 12 7.00008V12.0001L8.46663 15.5334C10.4166 17.4834 13.5833 17.4834 15.5416 15.5334C17.4916 13.5834 17.4916 10.4167 15.5333 8.46675ZM12 3.66675C7.39996 3.66675 3.66663 7.40008 3.66663 12.0001C3.66663 16.6001 7.39996 20.3334 12 20.3334C16.6 20.3334 20.3333 16.6001 20.3333 12.0001C20.3333 7.40008 16.6 3.66675 12 3.66675ZM12 18.6667C8.31663 18.6667 5.33329 15.6834 5.33329 12.0001C5.33329 8.31675 8.31663 5.33341 12 5.33341C15.6833 5.33341 18.6666 8.31675 18.6666 12.0001C18.6666 15.6834 15.6833 18.6667 12 18.6667Z"
      fill="white"
    />
  </Icon>
);

export default InProgressStepperIcon;
