import axios from "axios";
import api from "../../axios";

export const createApplication = async ({ course_id }: { course_id: string }) => {
  const client = await api.client;
  const response = await client.create_application_v2_applications_post(null, {
    course_id,
  });
  return response.data;
};

export const getActiveApplication = async () => {
  const client = await api.client;
  const response = await client.get_active_application_v2_applications_active_get();
  return response.data;
};

export const startOnboarding = async () => {
  const client = await api.client;
  const response = await client.start_onboarding_v2_applications_start_onboarding_get();
  return response.data;
};

export const getPresignedUrl = async () => {
  const client = await api.client;
  const response = await client.get_s3_presigned_url_v2_aadhaar_verification_s3_presigned_url_get();
  return response.data;
};

export const giveConsent = async () => {
  const client = await api.client;
  const response = await client.record_consent_v2_applications_record_consent_get();
  return response.data;
};
export const completeOnboarding = async () => {
  const client = await api.client;
  const response = await client.complete_onboarding_v2_applications_complete_onboarding_get();
  return response.data;
};
export const runAadhaarOCR = async ({ aadhaar_front_url, aadhaar_back_url }: { aadhaar_front_url: string; aadhaar_back_url: string }) => {
  const client = await api.client;
  const response = await client.run_ocr_v2_aadhaar_verification_run_ocr_post(null, {
    aadhaar_front_url,
    aadhaar_back_url,
  });
  return response.data;
};

export const syncAadhaarOCR = async () => {
  const client = await api.client;
  const response = await client.sync_aadhaar_v2_aadhaar_verification_sync_get();
  return response.data;
};

export const getLearnCards = async () => {
  const response = await axios.get(`${window.location.protocol}//${process.env.REACT_APP_LEARN_BACKEND_API}` + "/progress", {
    withCredentials: true,
    params: {
      withoutPreCourse: true,
    },
  });
  return response.data;
};

export const createApplicationV3 = async ({ course_id }: { course_id: string }) => {
  const client = await api.client;
  const response = await client.create_application_v3_applications_post(null, {
    course_id,
  });
  return response.data;
};
