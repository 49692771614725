import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { useActiveApplication } from "../../../api/utils/hooks/useApplication";
import { BatchType } from "../../../api/schemas/schema";
import { formatDateWithOrdinal } from "../../../utils/utils";

interface Props {
  activeBatch: BatchType;
}

const JoinZoomSlackCard = ({ activeBatch }: Props) => {
  const { data: activeApplication, isLoading } = useActiveApplication();
  const linksList = [
    // { iconurl: "/img/revamp/zoom.svg", text: "Masai Zoom Invitation", joinLink: "", btnText: "Join Zoom" },
    {
      iconurl: "/img/revamp/slack.svg",
      text: "Masai Slack Workspace",
      isDisabled: !!activeApplication?.slack_link, //   || !!state?.profileDataV2?.email_verified
      joinLink: activeApplication?.slack_link || "",
      btnText: "Join Slack",
    },
  ];
  return (
    <Box
      border={"1px solid #E5E5E5"}
      bg={"white"}
      boxShadow={"0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);"}
      rounded={"16px"}
      maxW={"640px"}
      w={"full"}
      px={{ base: "16px", md: "20px" }}
      py={{ base: "16px", md: "20px" }}
      display={"flex"}
      flexDir={"column"}
      minH={{ base: "auto", md: "372px" }}
    >
      <Text fontSize={{ base: "16px", md: "20px" }} fontFamily={"Poppins"} fontWeight={700} color={"#3B3435"}>
        Welcome to Data Analytics program
      </Text>
      <Text fontSize={{ base: "12px", md: "14px" }} fontWeight={600} color={"#544D4F"} mt={3}>
        Congratulations on your application. Course orientation starts on
        <Text as={"span"} fontWeight={700} fontFamily={"inter"}>
          {" "}
          {formatDateWithOrdinal(activeBatch.start_date)}. <br /> Join the links below to get started and interact with others.
        </Text>
      </Text>
      <Flex bg={"#F2F6FF"} px={{ base: "10px", md: "12px" }} direction={"column"} gap={5} py={"10px"} mt={{ base: "40px" }} maxW={"397px"} w={"full"} rounded={"8px"}>
        {linksList.map((item, index) => {
          return (
            <Flex align={"center"} justify={"space-between"} w={"full"} gap={2} key={index}>
              <Flex gap={2} align={"center"}>
                <Image src={item.iconurl} objectFit={"contain"} w={{ base: "36px", md: "52px" }} h={{ base: "36px", md: "48px" }} rounded={"8px"} />
                <Text fontSize={{ base: "10px", md: "14px" }} fontWeight={600}>
                  {item.text}
                </Text>
              </Flex>
              <Button
                isDisabled={isLoading}
                size={"sm"}
                bg={"#FFFFFF"}
                variant={"solid"}
                color={"#3470E4"}
                onClick={() => {
                  window.open(item.joinLink, "_blank");
                }}
              >
                {item.btnText}
              </Button>
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
};

export default JoinZoomSlackCard;
