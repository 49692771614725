import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import DostRequestsList from "./DostRequestsList";

import DostBulkUpload from "./DostBulkUpload";
import Pagination from "../../../../pages/Profile/referrals/Pagination";
import { useLocation } from "react-router-dom";
import { useAllDostRequests } from "../../../../api/utils/hooks/useAdmin";

const Dost = () => {
  const search = useLocation().search;
  const page = new URLSearchParams(search).get("page");
  const currentOffset = page === null ? 0 : (Number(page) - 1) * 6;
  const [offset, setOffset] = useState<number>(currentOffset);
  const limit = 10;
  const getNextPage = () => {
    setOffset(offset + limit);
  };
  const getPrevPage = () => {
    setOffset(offset - limit);
  };

  useEffect(() => {
    const currentPage = offset / limit + 1;
    // pass offset and limit to refetch function
    refetchAllCounsellingRequests();
    //  history.push({
    //    pathname,
    //    search: `page=${currentPage}`,
    //  });
  }, [offset, limit]);

  const {
    data: allCounsellingRequests,
    refetch: refetchAllCounsellingRequests,
    isLoading: allCounsellingRequestsLoading,
  } = useAllDostRequests(offset, limit);

  return (
    <Box px="12px" my="40px">
      <DostBulkUpload offset={offset} limit={limit} />
      <DostRequestsList offset={offset} limit={limit} />
      <Box>
        {allCounsellingRequests && allCounsellingRequests.total > 0 && (
          <Pagination
            total={allCounsellingRequests.total}
            limit={limit}
            getNextPage={getNextPage}
            getPrevPage={getPrevPage}
            offset={offset}
            mb={"0x"}
            mt={"24px"}
          />
        )}{" "}
      </Box>
    </Box>
  );
};

export default Dost;
