import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Box,
  ModalContentProps,
  ModalOverlayProps,
} from "@chakra-ui/react";
import { ButtonVariants } from "../../../theme/components/Button";
import { sendTrackingEvent } from "../utils/gtm";
import { useLocation } from "react-router-dom";
interface ModalTemplateProps {
  /** Title of the modal template  */
  title: string | JSX.Element;
  /** Subtitle of the modal template */
  subtitle?: string;
  /** If the modal submit button is disabled or not */
  buttonDisabled?: boolean;
  /** Any child to be rendered by the modal */
  children: JSX.Element | JSX.Element[];
  /** Show Close button icon */
  showCloseButtonIcon?: boolean;
  /** Show top right Close button */
  showCloseButton?: boolean;
  /** Callback for  when the save button of the modal is clicked */
  saveOnClick?: () => void;
  /** Callback for  when the close button of the modal is clicked */
  onClose: () => void;
  /** boolean to toggle modal open and close */
  isOpen: boolean;
  /** Any content to be rendered within the button (button display "Save" if this value is not defined) */
  footerContent?: JSX.Element | JSX.Element[];
  showFooterContent?: boolean;
  /** button text, will not be shown  */
  buttonText?: string;
  /** Show button */
  showSaveButton?: boolean;
  /** Button Loading */
  buttonLoading?: boolean;
  /** Any content Below the button */
  contentBelowFooter?: JSX.Element | JSX.Element[];
  /** custom button size */
  buttonVariant?: ButtonVariants;
  /** marginX: left and right(actual-px/4)*/
  marginX?: number;
  /** msx widht in px */
  maxWidth?: string;
  maxHeight?: string;
  paddingX?: string;
  paddingY?: string;
  /** allow modal to close on hover */
  closeOnOverlayClick?: boolean;
  scrollBehavior?: "inside" | "outside";
  // modal blue
  backdropFilter?: string;
  closeOnEsc?: boolean;
  isCentered?: boolean;
  borderRadius?: string | number;
  bgColor?: string;
  modalContentProps?: ModalContentProps;
  modalOverlayProps?: ModalOverlayProps;
  closeIconGtmEvent?: string;
}

const ModalTemplate = ({
  title,
  subtitle,
  buttonDisabled,
  children,
  showSaveButton,
  showCloseButton,
  showCloseButtonIcon,
  saveOnClick,
  onClose,
  isOpen,
  footerContent,
  showFooterContent,
  buttonText,
  buttonLoading,
  contentBelowFooter,
  buttonVariant,
  marginX,
  maxWidth,
  maxHeight,
  scrollBehavior,
  closeOnOverlayClick = true,
  paddingX,
  paddingY,
  backdropFilter,
  closeOnEsc,
  isCentered = true,
  borderRadius = "ms-16",
  bgColor,
  modalContentProps,
  modalOverlayProps,
  closeIconGtmEvent,
}: ModalTemplateProps) => {
  const location = useLocation();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isCentered}
      closeOnOverlayClick={closeOnOverlayClick}
      scrollBehavior={scrollBehavior}
      closeOnEsc={closeOnEsc}
    >
      <ModalOverlay backdropFilter={backdropFilter} {...modalOverlayProps} />
      <ModalContent
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        mx={marginX}
        borderRadius={borderRadius}
        px={paddingX || "ms-24"}
        py={paddingY || "ms-24"}
        maxW="fit-content"
        bgColor={bgColor}
        {...modalContentProps}
      >
        <ModalHeader p="none" mr="ms-32">
          <Heading as="h4" color="ms-red.900" maxW="304px">
            {title}
          </Heading>
        </ModalHeader>
        {showCloseButtonIcon && (
          <ModalCloseButton
            color="ms-grey.700"
            top="ms-24"
            right="ms-24"
            onClick={() => {
              sendTrackingEvent({
                event: closeIconGtmEvent || "",
                eventLabel: location.pathname,
              });
              onClose();
            }}
            boxSize="24px"
            _focus={{}}
          />
        )}
        <ModalBody p="none">{children}</ModalBody>
        <ModalFooter p="none">
          {showFooterContent ? (
            footerContent ? (
              footerContent
            ) : (
              <Box mt="ms-32">
                {showCloseButton && (
                  <Button colorScheme="blue" onClick={onClose}>
                    Close
                  </Button>
                )}
                <Button
                  onClick={saveOnClick}
                  variant={buttonVariant}
                  isLoading={buttonLoading}
                  disabled={buttonDisabled}
                >
                  {buttonText ? buttonText : "Continue"}
                </Button>
              </Box>
            )
          ) : null}
        </ModalFooter>
        {contentBelowFooter}
      </ModalContent>
    </Modal>
  );
};

export default ModalTemplate;
