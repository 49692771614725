import { Box, Heading, useBreakpointValue } from "@chakra-ui/react";
import { TestCard1, TestCard2 } from "../../../../../assets/images";
import { sendTrackingEvent } from "../../../../../components/common/utils/gtm";
import YoutubeCard from "../../../../../components/msat/msat-instructions/YoutubeCard";
import AboutMSATCards from "./AboutMSATCards";
import useSnackBar from "../../../../../components/common/general/SnackBar";
import { useContext, useState } from "react";
import { AppContext } from "../../../../../context/ApplicationContext";
import { createMettlAssessment } from "../../../../../api/utils/api";
import { axiosErrorHandler } from "../../../../../api/utils/error";
interface Props {
  startPracticeTest: () => void;
  batch_id?: number;
  isScholarhipTest?: boolean;
  disablePracticeTest?: boolean;
}
const AboutMSAT = ({
  disablePracticeTest,
  startPracticeTest,
  batch_id,
  isScholarhipTest,
}: Props) => {
  const testCardsData = [
    {
      key: 1,
      heading: "Practice Test",
      text: `Try out the practice test for practice to get yourself ready for the ${
        isScholarhipTest ? "Scholarship Test" : "MSAT"
      }.`,
      btnText: "Practice Test",
      image: TestCard1,
      disableBtn: disablePracticeTest,
      handleClick: () => {
        handleStartTest({ is_practice: true });
        sendTrackingEvent({
          event: "practice_test_MTBT",
          eventLabel: `/batch/${batch_id}/msat-instructions`,
        });
      },
    },
    {
      key: 2,
      heading: "Test Syllabus",
      text: "Know the syllabus for the test to help yourself prepare.",
      btnText: "View Syllabus",
      image: TestCard2,
      handleClick: () => {
        sendTrackingEvent({
          event: "view_curriculam_MTBT",
          eventLabel: `/batch/${batch_id}/msat-instructions`,
        });
      },
      isExternal: true,
    },
  ];
  const snackbar = useSnackBar();
  const [loading, setLoading] = useState<boolean>(false);
  const state = useContext(AppContext)[0];
  const profileData = state.profileData;
  const applicantID = state.applicationData?.slug;
  const is_mobile = useBreakpointValue({ base: true, sm: false });
  const isNewMsat = true;
  const handleStartTest = async ({ is_practice }: { is_practice: boolean }) => {
    try {
      setLoading(true);
      if (profileData && profileData?.slug && applicantID && is_mobile !== undefined) {
        const mettl = await createMettlAssessment({
          profile_slug: profileData.slug,
          application_slug: applicantID,
          is_practice,
          is_mobile,
          isScholarhipTest,
        });

        if (mettl.url) {
          if (!isNewMsat) {
            setTimeout(() => {
              window.open(mettl.url, "_blank");
            });
          } else {
            window.location.href = mettl.url;
          }
          // window.open(mettl.url);
        } else throw new Error(`Something went wrong ${JSON.stringify(mettl)}`);
      } else {
        snackbar.error("Something went wrong. Please try again later.");
      }

      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong while creating the test. Please try again later.");
      }
    }
  };
  return (
    <Box>
      <Box>
        <Heading d={{ base: "block", xl: "none" }} as="h5">
          Watch to know more about {isScholarhipTest ? "Scholarship Test" : "MSAT"}
        </Heading>
        <Heading d={{ base: "none", xl: "block" }} as="h5">
          Watch to know more about {isScholarhipTest ? "Scholarship Test" : "MSAT Test"}
        </Heading>
      </Box>
      <Box
        d={"flex"}
        mt={{ base: "ms-12", xl: "ms-16" }}
        flexDirection={{ base: "column", xl: "row" }}
      >
        <Box>
          <YoutubeCard
            padding={"0px"}
            width={{ base: "100%", xl: "772px" }}
            baseHeight={"192px"}
            mdHeight={"352px"}
            url="https://youtu.be/TgZP2pFLpdg"
            eventName="how_to_take_MSAT_video_MTBT"
            eventLabel={`/batch/${batch_id}/msat-instructions`}
          />
        </Box>
        <Box ml={{ xl: "ms-24" }} mt={{ base: "ms-24", xl: "0" }} alignSelf={"center"}>
          <AboutMSATCards isScholarhipTest={isScholarhipTest} testCardsData={testCardsData} />
        </Box>
      </Box>
    </Box>
  );
};

export default AboutMSAT;
