import { Box, Accordion, AccordionItem, AccordionButton, AccordionPanel, Text, Image, useBreakpointValue, Skeleton, Link } from "@chakra-ui/react";
import MSATResultBanner from "./MSATResultBanner";
import MSATSectionCards from "./MSATSectionCards";
import ArrowUp from "../../../assets/images/arrow-up.svg";
import ArrowDown from "../../../assets/images/arrow-down.svg";
import MSATBannerV2 from "./MSATBannerV2";
import MSATBothResults from "./MSATBothResults";
import { ProfileAssessment } from "../../../api/schemas/schema";
import { useEffect } from "react";
interface Props {
  isMasaiCoursePrefered?: boolean;
  bestAssessment?: ProfileAssessment;
  loadingbestAssessment?: boolean;
  refetch?: () => void;
}
const MSATResultCard = ({ isMasaiCoursePrefered, bestAssessment, loadingbestAssessment, refetch }: Props) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    refetch && refetch();
  }, []);
  return (
    <Skeleton h="full" isLoaded={!loadingbestAssessment}>
      {!loadingbestAssessment && bestAssessment && bestAssessment?.attempt_count && bestAssessment?.attempt_count > 1 && typeof isMobile === "boolean" ? (
        <Accordion h="full" allowToggle defaultIndex={isMobile ? [-1] : [0]}>
          <AccordionItem h="full" overflow="hidden" boxShadow={"base"} border="none" borderRadius={"8px"}>
            {({ isExpanded }) => (
              <>
                <Box h="full" bg="white" position="relative" isolation="isolate" overflow="hidden">
                  <AccordionPanel p="0px" pb={4}>
                    <MSATBannerV2 isMasaiCoursePrefered={isMasaiCoursePrefered} bestAssessment={bestAssessment} />

                    <MSATBothResults bestAssessment={bestAssessment} loadingbestAssessment={loadingbestAssessment} />

                    <Link
                      href="https://masaischool.com/msat/syllabus"
                      display={"flex"}
                      justifyContent={"center"}
                      mt={2}
                      pb={4}
                      fontSize="sm"
                      fontWeight="semibold"
                      lineHeight="short"
                      color="#3470E4"
                      textDecoration="underline"
                      alignSelf="center"
                      target="_blank"
                    >
                      View MSAT Syllabus
                    </Link>
                  </AccordionPanel>

                  <AccordionButton
                    display={{
                      base: "flex",
                      md: "none",
                    }}
                    p="0px"
                    outline={"none"}
                    _focus={{
                      outline: "none",
                    }}
                    textAlign={"left"}
                    _hover={{ bg: "white" }}
                  >
                    <Box width={"100%"}>
                      {!isExpanded && (
                        <Box pb="16px">
                          <MSATBannerV2 isMasaiCoursePrefered={isMasaiCoursePrefered} bestAssessment={bestAssessment} />
                        </Box>
                      )}
                      <Box borderRadius={"8px"} px="16px" py="8px" bgColor={"ms-blue.50"} display={"flex"} justifyContent={"center"}>
                        <Text textAlign={"center"} textStyle="btn-md" color="ms-blue.500">
                          {isExpanded ? "hide detailed report" : "view detailed report"}
                        </Text>
                        <Image ml="8px" src={isExpanded ? ArrowUp : ArrowDown} alt="msat result" />
                      </Box>
                    </Box>
                  </AccordionButton>
                </Box>
              </>
            )}
          </AccordionItem>
        </Accordion>
      ) : (
        typeof isMobile === "boolean" && (
          <Accordion allowToggle defaultIndex={isMobile ? [-1] : [0]}>
            <AccordionItem boxShadow={"base"} border="none" borderRadius={"8px"}>
              {({ isExpanded }) => (
                <>
                  <AccordionPanel p="0px" pb={4}>
                    <Box bgColor={"#fff"} borderRadius={"8px"}>
                      <MSATResultBanner bestAssessment={bestAssessment} isLoading={loadingbestAssessment || false} isMasaiCoursePrefered={isMasaiCoursePrefered} />
                      <Box p="16px">
                        <MSATSectionCards result={bestAssessment && bestAssessment?.result ? bestAssessment?.result : [{}]} />
                      </Box>
                    </Box>
                  </AccordionPanel>
                  <AccordionButton
                    display={{
                      base: "flex",
                      md: "none",
                    }}
                    p="0px"
                    outline={"none"}
                    _focus={{
                      outline: "none",
                    }}
                    textAlign={"left"}
                  >
                    <Box width={"100%"}>
                      {!isExpanded && (
                        <Box>
                          <MSATResultBanner bestAssessment={bestAssessment} isLoading={loadingbestAssessment || false} />
                        </Box>
                      )}
                      <Box borderRadius={"8px"} px="16px" py="8px" bgColor={"ms-blue.50"} display={"flex"} justifyContent={"center"}>
                        <Text textAlign={"center"} textStyle="btn-md" color="ms-blue.500">
                          {isExpanded ? "hide detailed report" : "view detailed report"}
                        </Text>
                        <Image ml="8px" src={isExpanded ? ArrowUp : ArrowDown} alt="msat result" />
                      </Box>
                    </Box>
                  </AccordionButton>
                  <Link
                    href="https://masaischool.com/msat/syllabus"
                    display={"flex"}
                    justifyContent={"center"}
                    mt={2}
                    pb={4}
                    fontSize="sm"
                    fontWeight="semibold"
                    lineHeight="short"
                    color="#3470E4"
                    textDecoration="underline"
                    alignSelf="center"
                    target="_blank"
                  >
                    View MSAT Syllabus
                  </Link>
                </>
              )}
            </AccordionItem>
          </Accordion>
        )
      )}
    </Skeleton>
  );
};

export default MSATResultCard;
