import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Text,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import IITGResultBanner from "./IITGResultBanner";
import IITSectionCards from "./IITSectionCards";
import ArrowUp from "../../assets/images/arrow-up.svg";
import ArrowDown from "../../assets/images/arrow-down.svg";
import { ProfileAssessment } from "../../api/schemas/schema";

type Props = {
  lastAssessment: ProfileAssessment;
  loadinglastAssessment: boolean;
};

const IITResultCard = ({ lastAssessment, loadinglastAssessment }: Props) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      {!loadinglastAssessment &&
      lastAssessment &&
      ["ENDED", "GRADED"].includes(lastAssessment?.status?.toUpperCase() || "") &&
      lastAssessment?.attempt_count &&
      lastAssessment?.attempt_count >= 1 &&
      typeof isMobile === "boolean" ? (
        <Accordion h="full" allowToggle defaultIndex={isMobile ? [-1] : [0]}>
          <AccordionItem
            h="full"
            overflow="hidden"
            boxShadow={"base"}
            border="none"
            borderRadius={"8px"}
          >
            {({ isExpanded }) => (
              <>
                <Box h="full" bg="white" position="relative" isolation="isolate" overflow="hidden">
                  <AccordionPanel p="0px" pb={4}>
                    <IITGResultBanner isMasaiCoursePrefered={true} />
                    <Box p="24px">
                      <IITSectionCards iitResult={lastAssessment as ProfileAssessment} />
                    </Box>
                  </AccordionPanel>

                  <AccordionButton
                    display={{
                      base: "flex",
                      md: "none",
                    }}
                    p="0px"
                    outline={"none"}
                    _focus={{
                      outline: "none",
                    }}
                    textAlign={"left"}
                    _hover={{ bg: "white" }}
                  >
                    <Box width={"100%"}>
                      {!isExpanded && (
                        <Box pb="16px">
                          <IITGResultBanner isMasaiCoursePrefered={true} />
                        </Box>
                      )}
                      <Box
                        borderRadius={"8px"}
                        px="16px"
                        py="8px"
                        bgColor={"ms-blue.50"}
                        display={"flex"}
                        justifyContent={"center"}
                      >
                        <Text textAlign={"center"} textStyle="btn-md" color="ms-blue.500">
                          {isExpanded ? "hide detailed report" : "view detailed report"}
                        </Text>
                        <Image ml="8px" src={isExpanded ? ArrowUp : ArrowDown} alt="msat result" />
                      </Box>
                    </Box>
                  </AccordionButton>
                </Box>
              </>
            )}
          </AccordionItem>
        </Accordion>
      ) : null}
    </>
  );
};

export default IITResultCard;
