import { GetApplicationType, GetOnboardingProgress } from "../../schemas/schema";
import api from "../axios";

export const getOnboardingStatus = async ({
  application_slug,
}: {
  application_slug: string;
}): Promise<GetOnboardingProgress> => {
  const client = await api.client;
  const response = await client.get_onboarding_progress_onboarding_progress__application_slug__get(
    application_slug
  );
  return response.data;
};

export const startOnboarding = async ({
  application_slug,
}: {
  application_slug: string;
}): Promise<GetOnboardingProgress> => {
  const client = await api.client;
  const response = await client.start_onboarding_onboarding_start_post(null, {
    application_slug,
  });
  return response.data;
};

export const submitLaunchFormOnbaording = async ({
  application_slug,
  launch_form,
}: {
  application_slug: string;
  launch_form: { [key: string]: string };
}): Promise<GetOnboardingProgress> => {
  const client = await api.client;
  const response = await client.update_launch_form_onboarding_progress_launch_form_post(null, {
    application_slug,
    launch_form,
  });
  return response.data;
};

export const createOneTimeLinkOnbaordingSession = async ({
  application_slug,
}: {
  application_slug: string;
}): Promise<GetApplicationType> => {
  const client = await api.client;
  const response = await client.create_onboarding_session_one_time_link_onboarding_one_time_link_post(
    null,
    {
      application_slug,
    }
  );
  return response.data;
};

export const createOnboardingSession = async ({
  application_slug,
  calendly_event_uri,
}: {
  application_slug: string;
  calendly_event_uri: string;
}): Promise<GetApplicationType> => {
  const client = await api.client;
  const response = await client.create_onboarding_session_onboarding_session_post(null, {
    application_slug,
    calendly_event_uri,
  });
  return response.data;
};

export const createOneTimeLinkCounsellingSession = async ({
  application_slug,
}: {
  application_slug: string;
}): Promise<GetApplicationType> => {
  const client = await api.client;
  const response = await client.create_counselling_session_one_time_link_counselling_one_time_link_post(
    null,
    {
      application_slug,
    }
  );
  return response.data;
};
export const createOneTimeLinkMasaiXInterviewSession = async ({
  application_slug,
}: {
  application_slug: string;
}): Promise<GetApplicationType> => {
  const client = await api.client;
  const response = await client.create_interview_booking_one_time_link_interview_booking_one_time_link_post(
    null,
    {
      application_slug,
    }
  );
  return response.data;
};

export const createCounsellingSession = async ({
  application_slug,
  calendly_event_uri,
}: {
  application_slug: string;
  calendly_event_uri: string;
}): Promise<GetApplicationType> => {
  const client = await api.client;
  const response = await client.create_counselling_session_counselling_post(null, {
    application_slug,
    calendly_event_uri,
  });
  return response.data;
};
export const createMasaiXInterviewEntry = async ({
  application_slug,
  calendly_event_uri,
}: {
  application_slug: string;
  calendly_event_uri: string;
}): Promise<GetApplicationType> => {
  const client = await api.client;
  const response = await client.create_interview_entry_create_interview_entry_post(null, {
    application_slug,
    calendly_event_uri,
  });
  return response.data;
};
