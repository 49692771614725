import { useQuery } from "react-query";
import { BatchType } from "../../schemas/schema";
import { getActiveBatch } from "../api/v2/batch";

export function useActiveBatch(course_id: string) {
  return useQuery({
    queryKey: ["getActiveBatch", course_id],
    queryFn: async () => (await getActiveBatch(course_id)) as BatchType,
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,

    refetchOnWindowFocus: false,
    enabled: true,
  });
}
