import { EmpowerHerDetailsType, EmpowerHerVerifyUserType, IITEMIOptions, IITOboardingFormInputInterface, PanCardImageUrlInterface } from "../../../../constants/IIt-course/iit-form-onboarding";
import { CourseSelected } from "../../../../enums/ProgramType.enum";
import {
  BannedUserType,
  CounsellingRequestStatusType,
  CreateEventType,
  CreatePaymentAdminType,
  DostRequestStatusType,
  ErrorResponseModelType,
  GetAdminCounsellingRequestResponseType,
  GetAdminCounsellingRequestType,
  GetAdminDostRequestResponseType,
  GetAdminEventType,
  GetDostRequestType,
  GetSpeakerType,
  ProgramProfileType,
  TriggerType,
  UnbannedUserType,
  UpdateBatchDetailsType,
  UpdateEventType,
  UpdateProfileByAdmin,
} from "../../../schemas/schema";
import api from "../../axios";

/* ------------------------------------ Dost Flow ------------------------------------ */

export const getAllDostRequests = async (offset: number, limit: number): Promise<GetAdminDostRequestResponseType> => {
  const client = await api.client;
  const response = await client.get_dost_requests_admin_dost_request_all_get({
    limit: limit,
    offset: offset,
  });
  return response.data;
};

export const updateDostRequestStatus = async ({ request_id, new_status }: { request_id: number; new_status: DostRequestStatusType }): Promise<GetDostRequestType> => {
  const client = await api.client;
  const response = await client.change_request_status_admin_dost_request__request_id__change_status_patch(
    {
      request_id,
    },
    {
      new_status,
    }
  );
  return response.data;
};
export const updateDostRequestStatusBulk = async ({ emails, new_status }: { emails: string[]; new_status: DostRequestStatusType }): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.change_request_status_admin_dost_request_change_bulk_status_patch(null, {
    new_status,
    emails,
  });
  return response.data;
};

/* ------------------------------------ Counselling Flow ------------------------------------ */
export const getAllUsersCounsellingRequests = async (offset: number, limit: number): Promise<GetAdminCounsellingRequestResponseType> => {
  const client = await api.client;
  console.log("limit:---", limit, offset);
  const response = await client.get_counselling_requests_admin_counselling_request_all_post({
    limit: limit,
    offset: offset,
  });
  return response.data;
};

export const updateCounsellingRequestStatus = async ({ request_id, new_status }: { request_id: number; new_status: CounsellingRequestStatusType }): Promise<GetAdminCounsellingRequestType> => {
  const client = await api.client;
  const response = await client.change_request_status_admin_counselling_request__request_id__change_status_patch(
    {
      request_id,
    },
    {
      new_status,
    }
  );
  return response.data;
};

export const updateCounsllingRequestStatusBulk = async ({
  emails,
  new_status,
  request_type,
}: {
  emails: string[];
  new_status: DostRequestStatusType;
  request_type: string;
}): Promise<ErrorResponseModelType> => {
  const client = await api.client;

  const response = await client.change_request_status_admin_counselling_request_change_bulk_status_patch(null, {
    new_status,
    emails,
    request_type,
  });

  return response.data;
};

/* ------------------------------------ Event Flow ------------------------------------ */

export const getAdminEvents = async (): Promise<GetAdminEventType[]> => {
  const client = await api.client;
  const response = await client.list_events_admin_events_get();
  return response.data;
};

export const addEvent = async (event: CreateEventType): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.create_event_admin_event_post(null, {
    name: event.name,
    event_type: event.event_type,
    event_location: event.event_location,
    cost: event.cost,
    details: event.details,
    event_end_data: event.event_end_data,
    event_start_date: event.event_start_date,
    is_recording_present: event.is_recording_present,
    event_visible_from: event.event_visible_from,
  });
  return response.data;
};

export const updateEvent = async ({ event, eventId }: { event: UpdateEventType; eventId: number }): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.update_event_admin_event__id__patch(
    {
      id: eventId,
    },
    {
      name: event.name,
      event_type: event.event_type,
      event_location: event.event_location,
      cost: event.cost,
      details: event.details,
      event_end_data: event.event_end_data,
      event_start_date: event.event_start_date,
      event_visible_from: event.event_visible_from,
      is_recording_present: event.is_recording_present,
      is_published: event.is_published,
    }
  );
  return response.data;
};

export const sendEventTrigger = async ({
  eventId,
  trigger_type,
  email,
  phone_number,
}: {
  eventId: number;
  trigger_type: TriggerType;
  email?: string;
  phone_number?: string;
}): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.send_trigger_event_admin_event__id__send_trigger_post(
    {
      id: eventId,
    },
    {
      trigger_type,
      email,
      phone_number,
    }
  );
  return response.data;
};

export const getSpeakers = async (): Promise<GetSpeakerType[]> => {
  const client = await api.client;
  const response = await client.get_speakers_get_speakers_get();
  return response.data;
};

export const banUsersApi = async (data: BannedUserType[]): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.ban_users_admin_ban_users_post({}, data);
  return response.data;
};

export const unbanUsersApi = async (data: UnbannedUserType[]): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.unban_users_admin_unban_users_post({}, data);
  return response.data;
};

export const verifyAdmin = async (): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.verify_admin_admin_verify_admin_get();
  return response.data;
};

export const getProfileByEmail = async (email: string) => {
  const client = await api.client;
  const response = await client.get_profile_admin_get_user_by_email_get({ email }, {});
  return response.data;
};

export const updateProfileByEmail = async ({
  date_of_birth,
  graduation_year,
  graduation_month,
  is_working,
  dp_url,
  program_profile_type,
  other_address,
  meta,
  email,
}: {
  email: string;
  date_of_birth?: string;
  graduation_year?: number;
  graduation_month?: number;
  is_working?: boolean;
  dp_url?: string;
  program_profile_type?: ProgramProfileType;
  other_address?: { [key: string]: unknown };
  meta?: IITOboardingFormInputInterface | PanCardImageUrlInterface | IITEMIOptions | EmpowerHerDetailsType | EmpowerHerVerifyUserType;
}) => {
  const client = await api.client;
  const response = await client.update_profile_admin_update_profile_patch(
    { email },
    {
      date_of_birth,
      graduation_year,
      graduation_month,
      is_working,
      dp_url,
      other_address,
      meta,
      program_profile_type,
    }
  );
  return response.data;
};

export const updateUserProfilePersonalInfoByEmailAndPhone = async ({ data, email, phone_number }: { data: UpdateProfileByAdmin; email: string; phone_number: string }) => {
  const client = await api.client;
  const response = await client.update_user_profile_details_admin_update_user_profile_details_patch(
    { email, phone_number },
    {
      ...data,
    }
  );
  return response.data;
};

export const updateUserBatch = async ({ email, new_batch, curr_batch }: { email: string; new_batch: CourseSelected; curr_batch: CourseSelected }) => {
  const client = await api.client;
  const response = await client.update_user_profile_details_admin_update_batch_patch({ email, new_batch, curr_batch }, {});
  return response.data;
};

/* ------------------------------------ AWS ------------------------------------ */
export const startPipeline = async ({ pipelineName }: { pipelineName: string }): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.start_pipeline_admin_aws_start_pipeline_get(
    {
      pipeline_name: pipelineName,
    },
    {}
  );
  return response.data;
};

/* ------------------------------------ Direct Payment ------------------------------------ */

export const enableDirectPayment = async (email: string): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.enable_direct_payment_admin_enable_direct_payment_post({ email }, {});
  return response.data;
};

export const createExternalPayment = async (data: CreatePaymentAdminType): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.create_payment_admin_create_payment_post({}, { ...data });
  return response.data;
};


export const updateBatchDetails = async (data: UpdateBatchDetailsType): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.update_batch_details_admin_update_batch_details_post({}, { ...data });
  return response.data;
};
