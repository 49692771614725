import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const SchoolOutlineV2 = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      opacity="0.2"
      d="M20.25 10.5996V15.589C20.2504 15.7723 20.1837 15.9493 20.0625 16.0868C19.0031 17.2671 16.4156 19.4993 12 19.4993C7.58438 19.4993 4.99875 17.2671 3.9375 16.0868C3.81628 15.9493 3.74958 15.7723 3.75 15.589V10.5996L12 14.9993L20.25 10.5996Z"
      fill="currentColor"
    />
    <path
      d="M23.6023 8.338L12.3523 2.338C12.2438 2.28022 12.1227 2.25 11.9998 2.25C11.8768 2.25 11.7558 2.28022 11.6473 2.338L0.397266 8.338C0.27727 8.40195 0.176916 8.49732 0.106941 8.6139C0.036965 8.73049 0 8.8639 0 8.99988C0 9.13585 0.036965 9.26926 0.106941 9.38585C0.176916 9.50243 0.27727 9.5978 0.397266 9.66175L2.99977 11.0502V15.5896C2.99899 15.958 3.13457 16.3136 3.38039 16.588C4.60852 17.9558 7.36008 20.2499 11.9998 20.2499C13.5382 20.2626 15.065 19.9839 16.4998 19.4286V22.4999C16.4998 22.6988 16.5788 22.8896 16.7194 23.0302C16.8601 23.1709 17.0509 23.2499 17.2498 23.2499C17.4487 23.2499 17.6394 23.1709 17.7801 23.0302C17.9207 22.8896 17.9998 22.6988 17.9998 22.4999V18.7039C18.9778 18.1394 19.8616 17.4254 20.6191 16.588C20.865 16.3136 21.0005 15.958 20.9998 15.5896V11.0502L23.6023 9.66175C23.7223 9.5978 23.8226 9.50243 23.8926 9.38585C23.9626 9.26926 23.9995 9.13585 23.9995 8.99988C23.9995 8.8639 23.9626 8.73049 23.8926 8.6139C23.8226 8.49732 23.7223 8.40195 23.6023 8.338ZM11.9998 18.7499C7.9432 18.7499 5.55727 16.768 4.49977 15.5896V11.8499L11.6473 15.6618C11.7558 15.7195 11.8768 15.7498 11.9998 15.7498C12.1227 15.7498 12.2438 15.7195 12.3523 15.6618L16.4998 13.4502V17.7946C15.3185 18.3458 13.8298 18.7499 11.9998 18.7499ZM19.4998 15.5858C19.0502 16.0847 18.5472 16.5327 17.9998 16.9218V12.6496L19.4998 11.8499V15.5858ZM17.6248 11.1505L17.6041 11.1383L12.3541 8.338C12.179 8.24853 11.9757 8.23145 11.7881 8.29044C11.6004 8.34943 11.4435 8.47976 11.351 8.65337C11.2586 8.82697 11.238 9.02994 11.2938 9.21855C11.3495 9.40717 11.4771 9.56633 11.6491 9.66175L16.031 11.9999L11.9998 14.1496L2.34352 8.99988L11.9998 3.85019L21.656 8.99988L17.6248 11.1505Z"
      fill="currentColor"
    />
  </Icon>
);

export default SchoolOutlineV2;
