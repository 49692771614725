import { Box, Button } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { verifyCoupon } from "../../../../../api/utils/api";
import { axiosErrorHandler } from "../../../../../api/utils/error";
import { InputCustom } from "../../../../../components/common/general";
import useSnackBar from "../../../../../components/common/general/SnackBar";
import { AppContext, ApplicationActionType } from "../../../../../context/ApplicationContext";
import { Banner } from "../../../Banner";
// import { Banner } from "../../../Banner";
interface Props {
  paymentType: "full-payment" | "subscription-payment";
  couponInDb: {
    discount_percentage: number;
    code: string;
  } | null;
}
export const ScholarCouponCode = ({ paymentType, couponInDb }: Props) => {
  const snackbar = useSnackBar();
  const state = useContext(AppContext)[0];
  const dispatch = useContext(AppContext)[1];
  const [error, setError] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();
  const [couponCode, setCouponCode] = useState<string>("");

  // message based on payment type
  let message = "";
  if (paymentType === "full-payment") {
    message = "Kindly complete the payment to avail the discount";
  } else if (paymentType === "subscription-payment" && couponInDb) {
    if (couponInDb.discount_percentage === 10) {
      message = `The discount will be applicable in the last installment of the plan`;
    } else {
      message = "The discount will be applicable in the last 2 installments of the subscription plan";
    }
  }
  const handleVerifyCoupon = async () => {
    // Verify if coupon code is valid
    if (!state.applicationData?.slug) {
      return;
    }
    setLoading(true);

    try {
      const response = await verifyCoupon({
        coupon_code: couponCode,
        course_id: 0,
        is_partial_payment: false,
        paymentType: "OTHERS",
      });

      setError(false);
    } catch (err) {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
      setError(true);
    } finally {
      dispatch({
        type: ApplicationActionType.FETCH_APPLICATION,
        payload: {},
      });
      setLoading(false);
    }
  };
  return (
    <>
      <Box display={"flex"} mt={"ms-24"}>
        <InputCustom
          getValueCallback={(value) => {
            setCouponCode(value.trim());
          }}
          type="text"
          placeholder="Enter coupon code"
          onClick={() => {
            console.log("hey");
          }}
          isInvalid={error}
          errorMessage="Invalid code or code expired"
          preFilledValue={couponInDb?.code}
        />

        <Button
          color="#3470E4"
          variant="secondary"
          size="lg"
          ml={"ms-24"}
          onClick={() => {
            handleVerifyCoupon();
          }}
          disabled={couponCode === ""}
        >
          CHECK
        </Button>
      </Box>
      {/* Banner */}
      {couponInDb && (
        <Box mt={"ms-12"}>
          <Banner
            type="success"
            title="Yayy!!🤩"
            text={`You have received a discount of flat ${couponInDb.discount_percentage}% on the course fee. ${message}`}
            hideShareButton={true}
            showMobileDesign={true}
            customWidth={["80%", "70%"]}
          />
        </Box>
      )}
    </>
  );
};
