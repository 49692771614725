import { Box, Stack, Heading, Text, Link } from "@chakra-ui/react";

import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { proceedToPaymentPending } from "../../../../api/utils/api";
import { axiosErrorHandler } from "../../../../api/utils/error";
import { InformationOutline } from "../../../../assets/icons/components";
import { BottomStickyNavbar, ModalTemplate } from "../../../../components/common/general";
import useSnackBar from "../../../../components/common/general/SnackBar";
import { AppContext } from "../../../../context/ApplicationContext";
import useApplicationHook from "../../../../hooks/useApplicationHook";

import { handleNewMsatScore } from "../../../Application/msat/HandleNewMsatScore";
import { Banner } from "../../Banner";
import ScoreDetails from "../ScoreDetails";

const ScholarMSFTFailed = () => {
  const [state, dispatch] = useContext(AppContext);
  const [isMalPracticeDetectedModal, setIsMalPracticeDetectedModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const scores = state.applicationData?.scholar_result;
  const isMalPracticeDetected = state.applicationData?.scholar_result?.isMalPracticeDetected;
  let rejectReason: string[] = ["Better luck next time"];
  const params = useParams() as { applicantID: string };
  try {
    rejectReason =
      typeof state.applicationData?.reject_reason === "object"
        ? Object.values(state.applicationData.reject_reason)
        : ["Better luck next time"];
  } catch (err) {
    // ignore
  }

  useEffect(() => {
    if (isMalPracticeDetected) {
      setIsMalPracticeDetectedModal(true);
    }
  }, [isMalPracticeDetected]);
  const { fetchApplicationData } = useApplicationHook()[1];
  const snackbar = useSnackBar();
  const handleProceedToPayment = async () => {
    if (!state.applicationData?.slug) return;
    try {
      setLoading(true);
      await proceedToPaymentPending({ application_slug: state.applicationData?.slug });
    } catch (err) {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    } finally {
      setLoading(false);
      fetchApplicationData && fetchApplicationData(params.applicantID);
    }
  };
  return (
    <>
      <Stack px={{ base: "ms-16", md: "ms-24" }} pt={"ms-24"} spacing={"6"}>
        <Box>
          <Banner
            type="error"
            text={`You could not clear the Scholarship Test! While you are not eligible for the Scholarship, you can still enroll for the Scholar Program. Complete your payment to block your spot!`}
            title={"Oh no! Missed it!"}
          />
        </Box>
        {scores && <ScoreDetails scoreDetailsData={handleNewMsatScore(scores, "msft")} />}
      </Stack>
      <ModalTemplate
        title=""
        isOpen={isMalPracticeDetectedModal}
        onClose={() => {
          //
        }}
        marginX={2}
        maxWidth="552px"
        showCloseButtonIcon={false}
        closeOnOverlayClick={false}
        backdropFilter="blur(10px) hue-rotate(90deg)"
      >
        <Box textAlign={"center"}>
          <InformationOutline boxSize="80px" color={"ms-error"} />
          <Heading mt={"ms-24"} as="h3">
            {" "}
            Plagiarism Detected!
          </Heading>
          <Text color={"ms-grey.700"} mt={"ms-16"} textStyle={"body1"}>
            Plagiarsim has been detected, you will not be eligible for any courses offered by Masai.
            Your access to the platform will be restricted . Kindly reach out to us at{" "}
            <Text display={"inline"} color={"ms-blue.500"} mt={"ms-16"} textStyle={"body1-md"}>
              <Link
                cursor={"pointer"}
                isExternal={true}
                href="mailto:admissions@masaischool.com"
                color="ms-blue.500"
                textDecoration={"underline"}
              >
                admissions@masaischool.com
              </Link>{" "}
            </Text>
            for any queries.
          </Text>
        </Box>
      </ModalTemplate>
      <BottomStickyNavbar
        btnPrimaryProps={{
          size: "lg",
          onClick: () => {
            handleProceedToPayment();
          },
        }}
        btnPrimary="Proceed to payment"
      />
    </>
  );
};

export default ScholarMSFTFailed;
