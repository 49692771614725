import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { InformationOutline } from "../../assets/icons/components";

interface Props {
  setShowCourseRestrictModal: (showCourseRestrictModal: boolean) => void;
  message?: string;
}

const CourseRestrictModalContent = ({ setShowCourseRestrictModal, message }: Props) => {
  return (
    <Box>
      <InformationOutline boxSize="56px" color="ms-alert" />
      <Heading as={"h4"} mt={"ms-16"}>
        Course Application Restricted
      </Heading>
      <Text mt={"ms-8"} textStyle={"body2"} color={"ms-grey.800"}>
        {message}
      </Text>
      <Box d={"flex"} mt={"ms-24"}>
        <Button
          variant="primary"
          size="sm"
          ml={"auto"}
          onClick={() => {
            setShowCourseRestrictModal(false);
          }}
        >
          close
        </Button>
      </Box>
    </Box>
  );
};

export default CourseRestrictModalContent;
