import { useQuery } from "react-query";
import { verifyAdmin } from "../api/v2/admin";

export function useVerifyAdmin() {
  return useQuery({
    queryKey: "verifyAdmin",
    queryFn: async () => (await verifyAdmin()) as { message: string; isAdmin: boolean },
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,

    refetchOnWindowFocus: false,
    enabled: true,
  });
}
