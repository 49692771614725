import dayjs from "dayjs";
import { useEffect, useState } from "react";
import ActivityCardV2 from "./ActivityCardV2";
import { Box, HStack, Text } from "@chakra-ui/react";
import { useEvents } from "../../../api/utils/hooks/useEvents";
import { GetEventType } from "../../../api/schemas/schema";

const EventsForYou = () => {
  const { data: events, refetch } = useEvents();
  const [upcomingEvents, setUpcomingEvents] = useState<GetEventType[] | null>(null);

  useEffect(() => {
    if (events) {
      const upComingEvents = events.filter((event) => event?.event_start_date && dayjs().isAfter(event?.event_start_date) && dayjs().isBefore(event.event_end_data));

      if (upComingEvents && upComingEvents.length > 0) {
        setUpcomingEvents(upComingEvents);
      }
    }
  }, [events]);

  return (
    <Box w={"full"} px={{ base: "16px", xl: "80px" }} display={!upcomingEvents || upcomingEvents.length === 0 ? "none" : "block"}>
      <Text fontSize={{ base: "20px", md: "24px" }} fontWeight={700} fontFamily={"inter"}>
        Events for you
      </Text>

      <HStack
        w="full"
        py="10px"
        maxW={"full"}
        overflowX="auto"
        alignItems="stretch"
        justifyContent={{ base: "start", md: "start" }}
        spacing={5}
        style={{ scrollSnapType: "x mandatory" }}
        position="relative"
        sx={{
          "&": {
            "-ms-overflow-style": "none",
            scrollbarWidth: "none",
          },
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {upcomingEvents?.map((event, index) => (
          <Box
            key={index}
            style={{
              scrollSnapAlign: "center",
            }}
            flexShrink={0}
            minW={{ base: upcomingEvents?.length > 1 ? "90%" : "100%", sm: "380px", md: "auto" }}
          >
            <ActivityCardV2 onRegister={refetch} height="full" isDashboard={true} event={event} />
          </Box>
        ))}
      </HStack>
    </Box>
  );
};

export default EventsForYou;
