import { Box, Text, useBreakpointValue } from "@chakra-ui/react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { TestCard1 } from "../../../../assets/images";

import { BottomStickyNavbar, ModalTemplate } from "../../../../components/common/general";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import AboutMSATCards from "../../../Application/msat/new-msat/first-tab/AboutMSATCards";
import NewMSATModalContent from "../../../Application/msat/new-msat/NewMSATModalContent";
import CodingInstructionsList from "../CodingInstructionsList";
import InstructionsWelcome from "../InstructionsWelcome";

import ProceedToContestModalContent from "../ProceedToContestModalContent";
import ApplyForFellowship from "./ScholarInstructions/ApplyForFellowship";

interface Props {
  contestModal: boolean;
  setContestModal: (contestModal: boolean) => void;
  handleProceedToMSAT: (isPractice: boolean) => void;
  status?: string;
  loading?: boolean;
  setLoading: (value: boolean) => void;
}
const ScholarCodingInstructions = ({
  contestModal,
  setContestModal,
  handleProceedToMSAT,
  status,
  loading,
  setLoading,
}: Props) => {
  const location = useLocation();
  const getBoldText = (text: string) => {
    return (
      <Text display={"inline"} textStyle={{ base: "body2-md", md: "body1-md" }}>
        {text}
      </Text>
    );
  };
  const [whatNextModal, setWhatNextModal] = useState<boolean>(true);
  const handleModalProceed = () => {
    setWhatNextModal(false);
  };
  // useEffect(() => {
  //   setWhatNextModal(false); //TODO:
  // }, []);
  const { trackEvent } = useMatomo();
  const getListItems = () => {
    return [
      <Text textStyle={{ base: "body2", md: "body1" }} key={0}>
        You will only have {getBoldText(`1 attempt`)} at coding test.
      </Text>,
      <Text key={1}>
        You will be attempting the Coding Test, where your technical skills will be put to test.
      </Text>,
      <Text key={2}>
        You will have a total of {getBoldText("60 minutes")} to complete and submit the coding test.
      </Text>,
      <Text key={3}>
        Once the timer runs out, your {getBoldText(`response will be saved automatically.`)}{" "}
      </Text>,
      <Text key={4}>
        {getBoldText(`On completing and clearing the Coding Test, `)}you will proceed to the
        technical interview with our Admissions Team.
      </Text>,
      <Text key={5}>
        If you are {getBoldText(`unable to clear the Coding Test, `)} you can still apply to our
        other Fast Paced Learning courses in Software Development & Data Analytics.
      </Text>,
    ];
  };

  const isMobile = useBreakpointValue({ base: true, md: false });
  const testCardsData = [
    {
      key: 1,
      heading: "Practice Coding Test",
      text: `Try out the coding test platform for practice to get yourself ready.`,
      btnText: "Practice Test",
      image: TestCard1,
      handleClick: () => {
        handleProceedToMSAT(true);
        sendTrackingEvent({
          event: "sp_ctbt_practice_coding_test",
          eventLabel: location.pathname,
        });
      },
    },
  ];
  return (
    <>
      <Box p={{ base: "ms-16", md: "ms-24" }}>
        <InstructionsWelcome type="scholar" />
        <Box
          mt={{ base: "16px", md: "0px" }}
          display={"flex"}
          flexDirection={{ base: "column", md: "row" }}
          sm={"30em"}
        >
          <CodingInstructionsList list={getListItems()} />
          <Box mt={{ base: "24px", md: "0px" }}>
            <AboutMSATCards
              isScholarhipTest={true}
              testCardsData={testCardsData}
              loading={loading}
            />
          </Box>
        </Box>

        <Box mt={"ms-24"}>
          <ApplyForFellowship
            handleConfirm={() => {
              //
            }}
            hideButtons={true}
            textElement={
              <Text mt={"ms-8"} textStyle={{ base: "caption", md: "body1" }}>
                We offer high aptitude candidates a path where studying with us doesn’t become a
                financial burden! Based on your performance in the coding test you can avail upto{" "}
                <Text
                  display={"inline"}
                  color={"ms-blue.500"}
                  textStyle={{ base: "caption", md: "body1-md" }}
                  fontWeight={{ base: "600", md: "700" }}
                >
                  75% scholarship
                </Text>{" "}
                on the course fee of Scholar Program.
              </Text>
            }
          />
        </Box>
      </Box>
      <BottomStickyNavbar
        // loading={loading}
        btnPrimary={
          status === "CODING_TEST_STARTED" ? "continute coding test" : "attempt coding test"
        }
        btnPrimaryProps={{
          size: isMobile ? "sm" : "lg",
          onClick: () => {
            // dashboard_coding_test_scholar_l2
            trackEvent({
              category: "Dashboard",
              action: "Attempt_Coding_Test",
              name: "dashboard_coding_test_scholar_l2",
              value: 300,
            });
            sendTrackingEvent({
              event: "sp_ctbt_attempt_coding_test",
              eventLabel: location.pathname,
            });
            setContestModal(true);
          },
        }}
      ></BottomStickyNavbar>
      <ModalTemplate
        title=""
        isOpen={contestModal}
        onClose={() => {
          sendTrackingEvent({
            event: "sp_ctbt_db_x",
            eventLabel: location.pathname,
          });
          setContestModal(false);
        }}
        marginX={2}
        maxWidth="552px"
        showCloseButtonIcon={true}
      >
        <ProceedToContestModalContent
          handleProceedToMSAT={() => {
            sendTrackingEvent({
              event: "sp_ctbt_db_proceed",
              eventLabel: location.pathname,
            });
            handleProceedToMSAT(false);
          }}
          loading={loading}
        />
      </ModalTemplate>
      <ModalTemplate
        title={""}
        isOpen={whatNextModal}
        onClose={() => {
          sendTrackingEvent({
            event: "sp_db_test_steps_x",
            eventLabel: location.pathname,
          });
          setWhatNextModal(false);
        }}
        showCloseButtonIcon={true}
        maxWidth={isMobile ? "" : "712px"}
        marginX={2}
      >
        <NewMSATModalContent
          setIsOpen={setWhatNextModal}
          handleClose={() => {
            setWhatNextModal(false);
          }}
          type="coding"
        />
      </ModalTemplate>
    </>
  );
};

export default ScholarCodingInstructions;
