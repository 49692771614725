import { CourseDataInterface } from "../../constants";
import { CourseType } from "./SelectCoursePreferencePage";
import { Box, Flex, Image, Text, Button, HStack } from "@chakra-ui/react";
import { GetProfileTypeV2, ProgramProfileType } from "../../api/schemas/schema";
import { useMutation } from "react-query";
import { updateProfile } from "../../api/utils/api/v2/profile";
import { axiosErrorHandler } from "../../api/utils/error";
import useSnackBar from "../common/general/SnackBar";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { AppContext, ApplicationActionType } from "../../context/ApplicationContext";
import { ModalTemplateV2 } from "../common/general";

interface Props {
  courseList: CourseDataInterface[];
  activeTab: CourseType;
  selectedCourse?: CourseType;
  isDashboard: boolean;
}

const CoursesListCard = ({ courseList, activeTab, selectedCourse }: Props) => {
  const snackbar = useSnackBar();
  const history = useHistory();
  const [state, dispatch] = useContext(AppContext);

  const { mutate: updateProfileMutate, isLoading: updateProfileLoading } = useMutation(updateProfile, {
    onSuccess: (data) => {
      dispatch({
        type: ApplicationActionType.SET_PROFILE_V2,
        payload: {
          profileDataV2: data as GetProfileTypeV2,
        },
      });
      dispatch({
        type: ApplicationActionType.SET_COURSE_PREFERENCE,
        payload: {
          coursePreference: data?.program_profile_type as ProgramProfileType,
        },
      });
      const courseLinkUrl = localStorage.getItem("courseLinkUrl");
      if (courseLinkUrl) {
        history.push(courseLinkUrl);
      }
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const handleCourseClick = (courseType: ProgramProfileType, courseLinkUrl: string) => {
    // set course details in local storage
    localStorage.setItem("courseLinkUrl", courseLinkUrl);
    if (courseType === "MASAI") {
      if (state && state.profileDataV2?.program_profile_type === "MASAI") {
        history.push(courseLinkUrl);
      } else {
        updateProfileMutate({
          program_profile_type: courseType as ProgramProfileType,
        });
      }
    } else {
      if (courseType === state.profileDataV2?.program_profile_type) {
        history.push(courseLinkUrl);
      } else {
        updateProfileMutate({
          program_profile_type: courseType as ProgramProfileType,
        });
      }
    }
  };

  return (
    <>
      <HStack
        w="full"
        maxW="1440px"
        mx="auto"
        pt={{ base: "16px", md: "30px" }}
        pb={{ base: "30px", md: "30px" }}
        px={{ base: "16px", xl: "80px" }}
        overflowX="auto"
        alignItems="stretch"
        justifyContent={{ base: "start", md: selectedCourse ? "start" : "center" }}
        spacing={5}
        style={{ scrollSnapType: "x mandatory" }}
        position="relative"
        sx={{
          "&": {
            "-ms-overflow-style": "none",
            scrollbarWidth: "none",
          },
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {courseList.map((item, index) => (
          <Box
            key={index + item.title}
            maxW={{ base: courseList.length === 1 ? "full" : "320px", md: "410px" }}
            style={{
              scrollSnapAlign: "center",
            }}
            w="full"
            px="17px"
            gap={0}
            py="27px"
            minW={{ base: "90%", sm: "380px", md: "auto" }}
            bg="white"
            rounded="9.951px"
            display="flex"
            flexDir="column"
            justifyContent="start"
            border="0.876px solid #E7E6E6"
            boxShadow="0px 5.258px 29.797px 0px rgba(215, 216, 222, 0.41)"
          >
            <Flex gap={3} align="center">
              <Image src={item.icon} w={{ base: "39.982px", md: "56px" }} h={{ base: "39.982px", md: "48px" }} objectFit="contain" />
              {(selectedCourse === CourseType.IIT || activeTab === CourseType.IIT) && (
                <Text fontSize={{ base: "16.39px", md: "24px" }} color="#192335" fontWeight={700} fontFamily="Poppins">
                  {item.title}
                </Text>
              )}
            </Flex>
            <Text fontSize={{ base: "13.659px", md: "20px" }} color="#192335" fontWeight={700} mt="8px" fontFamily="Poppins">
              {selectedCourse === CourseType.IIT || activeTab === CourseType.IIT ? item.description : item.title}
            </Text>
            <Box border="0.4px solid #CECCCD" my={3} w="full" />
            <Flex flexDir="column" gap={3} mt={2}>
              {item.pointers.map((pointer, pointerIndex) => (
                <Flex key={pointerIndex} gap={2} alignItems="center">
                  <Image src="/img/iit-course/checkbox.svg" alt="check" w="21px" h="21px" objectFit="contain" />
                  <Text fontSize="14px" color="#21191B" fontWeight={pointer.isBold ? 700 : 600} fontFamily="Open Sans">
                    {pointer.text}
                  </Text>
                </Flex>
              ))}
            </Flex>

            <Box mt={"auto"} w="full" pt={{ base: "20px", md: "40px" }}>
              <Button
                size="md"
                variant="outline"
                colorScheme={selectedCourse === CourseType.IIT || activeTab === CourseType.IIT ? "blue" : "red"}
                w="full"
                onClick={() => {
                  handleCourseClick(item.courseType, item.courseLinkUrl);
                }}
                isDisabled={updateProfileLoading}
              >
                View details
              </Button>
            </Box>
          </Box>
        ))}
      </HStack>
      <ModalTemplateV2
        closeOnOverlayClick={false}
        isOpen={updateProfileLoading}
        closeOnEsc={false}
        hideIconWithConfetti={true}
        showConfetti={false}
        onClose={() => {
          //
        }}
        waiting={true}
        text={`Please wait as we enhance your experience.`}
      />
    </>
  );
};

export default CoursesListCard;
