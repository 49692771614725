import { Box, Heading, Text, Button } from "@chakra-ui/react";
import React from "react";
import { InformationOutline } from "../../../assets/icons/components";
import CurrentStage from "./MasaiXProgram/CurrentStage";
interface Props {
  handleModalProceed: () => void;
}
const WhatNextModal = ({ handleModalProceed }: Props) => {
  return (
    <Box>
      <InformationOutline color={"ms-alert"} boxSize={"56px"} />
      <Heading mt={"ms-16"} as="h4">
        Here’s what’s next!
      </Heading>
      <Text mt={"ms-8"} textStyle="body2" color={"ms-grey.800"}>
        The admission process at Masai has 4 steps starting with the Masai School Coding Test. On
        clearing the Coding Test, you will have technical test followed by an interview with our
        Admissions Team. Ready?
      </Text>
      <Box>
        <CurrentStage
          customMasaiXStagesText={[
            "Coding Test",
            "Interview",
            "Aadhaar Verification",
            "Onboarding",
          ]}
          currentStage={"CODING"}
          insideModal={true}
        />
      </Box>
      <Box mt={"-24px"} display={"flex"}>
        <Button onClick={handleModalProceed} ml={"auto"} mt={"ms-24"} variant={"primary"}>
          Proceed
        </Button>
      </Box>
    </Box>
  );
};

export default WhatNextModal;
