import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { Check } from "../../../../../assets/icons/components";
import ChecklistItem from "./ChecklistItem";
import { TriggerType } from "../../../../../api/schemas/schema";

export enum ChecklistItemType {
  ONE_HOUR_EMAIL = "ONE_HOUR_EMAIL",
  FIFTEEN_MINUTES_EMAIL = "FIFTEEN_MINUTES_EMAIL",
  LIVE_EMAIL = "LIVE_EMAIL",
  FIFTEEN_MINUTES_WHATSAPP = "FIFTEEN_MINUTES_WHATSAPP",
}

const ChecklistForm = ({ eventId }: { eventId: number }) => {
  const trigger_types: TriggerType[] = [
    "ONE_HOUR_EMAIL",
    "FIFTEEN_MINUTES_EMAIL",
    "LIVE_EMAIL",
    "FIFTEEN_MINUTES_WHATSAPP",
  ];
  return (
    <Box>
      <Text textStyle={"body1-md"} mb="16px" textAlign={"center"} color="ms-blue.500">
        You must complete the checklist before publishing the event
      </Text>
      <Box display={"grid"} gap="16px">
        {trigger_types.map((trigger_type, index) => (
          <ChecklistItem eventId={eventId} key={index} index={index} trigger_type={trigger_type} />
        ))}
      </Box>
    </Box>
  );
};

export default ChecklistForm;
