import { useState } from "react";
import { Box, Button, Input, Skeleton, Text } from "@chakra-ui/react";
import UpdateUserPersonalInfo from "./UpdatePersonalInfo";

const GetUserToUpdatePersonalInfo = () => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [profileToBeFetched, setProfileToBeFetched] = useState({
    email: "",
    phone: "",
  });

  return (
    <Skeleton p={{ base: "16px", md: "24px" }} h={"100vh"} isLoaded={true}>
      <Text as="h2" textStyle="h2">
        Update Users Personal Details
      </Text>

      <Box display={"flex"} gap="10px">
        <Box>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Enter users current email below to fetch the profile
          </label>
          <Input name="email" type="email" placeholder="Enter user email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Box>
        <Box>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Enter users current phone_number below to fetch the profile
          </label>
          <Input name="phone" type="phone" placeholder="Enter user phone +919876543210" value={phone} onChange={(e) => setPhone(e.target.value)} />
        </Box>
        <Button
          mt="30px"
          disabled={!email || !phone}
          size="md"
          textStyle="btn-md"
          variant="primary"
          onClick={() => {
            setProfileToBeFetched({
              email: email,
              phone: phone,
            });
          }}
        >
          Get Profile
        </Button>
      </Box>

      {profileToBeFetched.email && <UpdateUserPersonalInfo email={email} phone={phone} />}
    </Skeleton>
  );
};

export default GetUserToUpdatePersonalInfo;
