import React from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { RadioGroup } from "@chakra-ui/react";
import RadioButton from "./RadioButton";
interface Props {
  options: {
    key: string;
    value: string;
    subtext?: string;
    customSize?: "regular" | "large";
  }[];
  onChange: (key: string) => void;
  selectedValue: string;
  size?: "regular" | "large";
  stackDirection?: "row" | "column";
  spacing?: string;
  disabled?: boolean;
  gridTemplateComlumns?: string | { base?: string; md?: string; lg?: string; xl?: string };
  columnGap?: string | string[];
  rowGap?: string | string[];
}
const RadioButtonGroup = ({
  options,
  onChange,
  selectedValue,
  size,
  disabled,
  gridTemplateComlumns,
  rowGap,
  columnGap,
}: Props) => {
  return (
    <>
      <Box>
        <RadioGroup onChange={onChange} value={selectedValue} isDisabled={disabled}>
          <SimpleGrid
            gridTemplateColumns={gridTemplateComlumns}
            rowGap={rowGap}
            columnGap={columnGap}
          >
            {options.map(
              (
                option: {
                  key: string;
                  value: string;
                  subtext?: string;
                  customSize?: "regular" | "large";
                },
                i: number
              ) => {
                const finalSize: "regular" | "large" | undefined = option.customSize
                  ? option.customSize
                  : size;
                return <RadioButton key={option.key} option={option} size={finalSize} />;
              }
            )}
          </SimpleGrid>
        </RadioGroup>
      </Box>
    </>
  );
};
export default RadioButtonGroup;
