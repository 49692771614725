import { convertUrlToCDNUrl } from "../../../utils/utils";

export const testimonialData = [
  {
    image: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Najmush_Saaquib_01f602fb0b.jpg",
    name: "Najmush Saaquib",
    role: "Software Engineer, NoBroker",
    company: "https://masai-website-images.s3.ap-south-1.amazonaws.com/nb_logo_new_trans_7b57a675c9.svg",
    feedback:
      'Made a few friends who turned out to be some of the most interesting people I have met till now. I gained a lot from Masai, one thing, in particular, is the "Don\'t Stop, Keep Going" attitude.',
  },
  {
    image: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Ramendra_Vikram_Singh_Parihar_48adbe1efc.jpeg",
    name: "Ramendra Parihar",
    role: "Associate Consultant, Capgemini",
    company: "https://masai-website-images.s3.ap-south-1.amazonaws.com/logo_511c6f6f19.svg",
    feedback: "Going through the 11-11-6 curriculum was a breathtaking experience. Every new unit came with great enthusiasm and a new challenge.",
  },
  {
    image: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IMG_20230522_WA_0000_2441f24aa7.jpg",
    name: "Salman Shaikh",
    role: "SDE-I, JIO AI-COE",
    company: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Jio_91af6a3f97.png",
    feedback: "I learned a lot from Masai i.e discipline, time management, and professionalism",
  },
  {
    image: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IMG_20220307_011531_bad369b8b8.png",
    name: "Prem Sagar Gupta",
    role: "SDE-I, Airtel Payments Bank",
    company: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Airtel_Payments_Bank_logo_1ec59021eb.png",
    feedback: "Choosing Masai was the best decision and getting JAVA was even better. I learned JAVA, Spring Boot and also performed well. ",
  },
  {
    image: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Harshit_Tripathi_cfaa1f571e.jpg",
    name: "Harshit Tripathi",
    role: "Backend Developer, Airtel Payemnts Bank",
    company: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Airtel_Payments_Bank_logo_1ec59021eb.png",
    feedback: "I liked that everything is taught in a structured manner, and there is no spoon-feeding. That helps us learn by ourselves when we do. ",
  },
  {
    image: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Nikhil_C_N_5c367a0ab7.jpg",
    name: "Nikhil C N",
    role: "Consultant, Capgemini",
    company: "https://masai-website-images.s3.ap-south-1.amazonaws.com/logo_511c6f6f19.svg",
    feedback: "The best thing I received from this course is to face the problem instead of running away from it. ",
  },
  {
    image: "https://masai-website-images.s3.ap-south-1.amazonaws.com/1624331335454_1a2afb8b07.jpeg",
    name: "Chiranjeev Thapliyal",
    role: "SDE-I, Coinswitch Kuber",
    company: "https://masai-website-images.s3.ap-south-1.amazonaws.com/coinswitch_09dcde37a9.png",
    feedback: "Personally feel that Isha(Soft Skill Development Instructor) has taught me how to put my thoughts in a well-structured form and in a humble way.",
  },
  {
    image: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Vaishnavi_Kawthankar_32a5ca3a14.jpg",
    name: "Vaishnavi Kawthankar",
    role: "SDE-I, Coinswitch Kuber",
    company: "https://masai-website-images.s3.ap-south-1.amazonaws.com/coinswitch_09dcde37a9.png",
    feedback:
      "Masai has very good student support systems in the form of Instructors, IAs, and Experience Champions. All of them encouraged us to reach out to them & made doubts clearing & the placement process smooth.",
  },
  {
    image: "https://masai-website-images.s3.ap-south-1.amazonaws.com/prottay_Ray_b306b5b540.jpg",
    name: "Prottay Ray",
    role: "SDE-I, NoBroker",
    company: "https://masai-website-images.s3.ap-south-1.amazonaws.com/nb_logo_new_trans_7b57a675c9.svg",
    feedback: "We were taught by exceptional teachers, most of whom had been tech leads, and everyone worked with the most prestigious companies in the tech ecosystem. ",
  },
  {
    image: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IMG_20230518102905_fb307003ac.jpg",
    name: "Saurabh Kumar Singh",
    role: "SDE, X-Tention Crew Technologies",
    company: "https://masai-website-images.s3.ap-south-1.amazonaws.com/sharechat_5b20e6d4a5.png",
    feedback: "Masai has been my gateway to a world of possibilities. With a family earlier rooted in farming and now in private jobs, I cherish every moment I spent at Masai.",
  },
  {
    image: "https://masai-website-images.s3.ap-south-1.amazonaws.com/V_L_KAVYA_1a7ef5bdbc.JPG",
    name: "V L Kavya",
    role: "SDE-I, Zolo",
    company: "https://masai-website-images.s3.ap-south-1.amazonaws.com/h7sxy1yuiquynkkbscig_443fc0f335.webp",
    feedback: "From zero industry exposure to empowered professional, I'm a living testament to their magic. Grateful for the game-changing journey",
  },
  {
    image: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Ayush_Kumar_3f62de779c.jpeg",
    name: "Ayush Kumar",
    role: "Software Engineer, Pine Labs",
    company: "https://masai-website-images.s3.ap-south-1.amazonaws.com/pine_labs_d538abbe58.png",
    feedback: "Masai transformed me from a math enthusiast to a confident software engineer. With exceptional instructors and a structured curriculum, I soared to new heights at Pine Labs. ",
  },
  {
    image: "https://masai-website-images.s3.ap-south-1.amazonaws.com/SAURABH_PAGRUT_7a7b7c1ba0.jpeg",
    name: "Saurabh Pagrut",
    role: "Software Engineer, Pine Labs",
    company: "https://masai-website-images.s3.ap-south-1.amazonaws.com/pine_labs_d538abbe58.png",
    feedback: "Professional insights, teamwork, logic mastery—I'm now thriving as a software engineer at Pine Labs. Masai is the path for driven minds.",
  },
  {
    image: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Aman1_0cea39c182.jpg",
    name: "Aman Kumar",
    role: "Development Engineer, Prospecta",
    company: "https://masai-website-images.s3.ap-south-1.amazonaws.com/Prospecta_logo_small_new_0bf961c6b7.png",
    feedback:
      "Dropping out of the BCA course in the second year was a leap of faith, but it led me to discover my true potential. Masai empowered me to surpass expectations and set new benchmarks. It's been an unforgettable journey of growth and accomplishment.",
  },
];

export const IITMandicurriculum = [
  { title: "Total trimesters", description: "3 (including pre-trimester)" },
  { title: "Total Course Fee", description: "₹ 1,95,000" },
  { title: "Commitment", description: "Mon-Fri (8pm-9pm) | Sat (11am-5pm) *" },
];

export const iitMandiTrimesterDetails = [
  {
    tabValue: "Trimester 1",
    fee: "₹ 60,000/-",
    duration: "6 months",
    trimesters: [
      {
        heading: "Pre Trimester",
        pointers: ["Algebra & functions.", "Discrete mathematics.", "Applications to Data Science."],
      },
      {
        heading: "Trimester 1",
        pointers: ["Mathematics for Data Science", "Programming for Data Engineering"],
      },
    ],
    trimestersLabel: [
      {
        logo: "/img/iit-mandi/curriculum-overview/star.svg",
        text: "earn 8 credits",
        css: "bg-[#EEFFF7] border border-[#B7E6CF] text-[#049402]",
      },
      {
        logo: "/img/iit-mandi/curriculum-overview/certificate.svg",
        text: "trimester certificate",
        css: "bg-[#F7F7FF] border border-[#B7B8E5] text-[#6E71CC]",
      },
    ],
    toolsList: [
      { logo: "/img/iit-mandi/curriculum-overview/pythonLogo.svg", text: "Python" },
      { logo: "/img/iit-mandi/curriculum-overview/matplotlibLogo.svg", text: "Matplotlib" },
      { logo: "/img/iit-mandi/curriculum-overview/numPyLogo.svg", text: "Numpy" },
      { logo: "/img/iit-mandi/curriculum-overview/plotyLogo.svg", text: "Plotly" },
    ],
    instructorList: [
      {
        imageUrl: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iitm_indu_5e02569827.webp",
        name: "Dr. Indu Joshi ",
        designation: "Assistant Professor @ IIT Mandi",
        field: "School of Computing and Electrical Engineering",
        profileUrl: "https://in.linkedin.com/in/dr-indu-joshi-83b9172a6",
      },
      {
        imageUrl: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iitm_indu_5e02569827.webp",
        name: "Dr. Indu Joshi ",
        designation: "Assistant Professor @ IIT Mandi",
        field: "School of Computing and Electrical Engineering",
        profileUrl: "https://in.linkedin.com/in/dr-indu-joshi-83b9172a6",
      },
    ],
  },
  {
    tabValue: "Trimester 2",
    fee: "₹ 60,000/-",
    duration: "3 months",
    trimesters: [
      {
        heading: "Pre Trimester 2",
        pointers: ["Algebra & functions.", "Discrete mathematics.", "Applications to Data Science."],
      },
    ],
    trimestersLabel: [
      {
        logo: "/img/iit-mandi/curriculum-overview/star.svg",
        text: "earn 8 credits",
        css: "bg-[#EEFFF7] border border-[#B7E6CF] text-[#049402]",
      },
      {
        logo: "/img/iit-mandi/curriculum-overview/certificate.svg",
        text: "trimester certificate",
        css: "bg-[#F7F7FF] border border-[#B7B8E5] text-[#6E71CC]",
      },
    ],
    toolsList: [
      { logo: "/img/iit-mandi/curriculum-overview/pythonLogo.svg", text: "Python" },
      { logo: "/img/iit-mandi/curriculum-overview/matplotlibLogo.svg", text: "Matplotlib" },
      { logo: "/img/iit-mandi/curriculum-overview/numPyLogo.svg", text: "Numpy" },
      { logo: "/img/iit-mandi/curriculum-overview/plotyLogo.svg", text: "Plotly" },
    ],
    instructorList: [
      {
        imageUrl: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iitm_indu_5e02569827.webp",
        name: "Dr. Indu Joshi ",
        designation: "Assistant Professor @ IIT Mandi",
        field: "School of Computing and Electrical Engineering",
        profileUrl: "https://in.linkedin.com/in/dr-indu-joshi-83b9172a6",
      },
      {
        imageUrl: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iitm_indu_5e02569827.webp",
        name: "Dr. Indu Joshi ",
        designation: "Assistant Professor @ IIT Mandi",
        field: "School of Computing and Electrical Engineering",
        profileUrl: "https://in.linkedin.com/in/dr-indu-joshi-83b9172a6",
      },
    ],
  },
  {
    tabValue: "Trimester 3",
    fee: "₹ 70,000/-",
    duration: "3 months",
    trimesters: [
      {
        heading: "Pre Trimester 3",
        pointers: ["Algebra & functions.", "Discrete mathematics.", "Applications to Data Science."],
      },
    ],
    trimestersLabel: [
      {
        logo: "/img/iit-mandi/curriculum-overview/star.svg",
        text: "earn 8 credits",
        css: "bg-[#EEFFF7] border border-[#B7E6CF] text-[#049402]",
      },
      {
        logo: "/img/iit-mandi/curriculum-overview/certificate.svg",
        text: "trimester certificate",
        css: "bg-[#F7F7FF] border border-[#B7B8E5] text-[#6E71CC]",
      },
    ],
    toolsList: [
      { logo: "/img/iit-mandi/curriculum-overview/pythonLogo.svg", text: "Python" },
      { logo: "/img/iit-mandi/curriculum-overview/matplotlibLogo.svg", text: "Matplotlib" },
      { logo: "/img/iit-mandi/curriculum-overview/numPyLogo.svg", text: "Numpy" },
      { logo: "/img/iit-mandi/curriculum-overview/plotyLogo.svg", text: "Plotly" },
    ],
    instructorList: [
      {
        imageUrl: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iitm_indu_5e02569827.webp",
        name: "Dr. Indu Joshi ",
        designation: "Assistant Professor @ IIT Mandi",
        field: "School of Computing and Electrical Engineering",
        profileUrl: "https://in.linkedin.com/in/dr-indu-joshi-83b9172a6",
      },
      {
        imageUrl: "https://masai-website-images.s3.ap-south-1.amazonaws.com/iitm_indu_5e02569827.webp",
        name: "Dr. Indu Joshi ",
        designation: "Assistant Professor @ IIT Mandi",
        field: "School of Computing and Electrical Engineering",
        profileUrl: "https://in.linkedin.com/in/dr-indu-joshi-83b9172a6",
      },
    ],
  },
];

export const IITGuwahatiStepsList = [
  { name: "Pay registration fee", text: "Step 1", isDone: false, isActive: true },
  {
    name: "Watch orientation videos by IIT faculty",
    text: "Step 2",
    isDone: false,
    isActive: false,
  },
  {
    name: "Complete payment of remaning course fee",
    text: "Step 3",
    isDone: false,
    isActive: false,
  },
  {
    name: "Complete Profile details",
    text: "Step 4",
    isDone: false,
    isActive: false,
  },
  {
    name: "Complete the Benchmarking test",
    text: "Step 5",
    isDone: false,
    isActive: false,
  },
];

export const IITMandiStepsList = [
  { name: "Pay registration fee", text: "Step 1", isDone: false, isActive: true },
  {
    name: "Watch orientation videos by IIT faculty",
    text: "Step 2",
    isDone: false,
    isActive: false,
  },
  {
    name: "Complete payment of remaning course fee",
    text: "Step 3",
    isDone: false,
    isActive: false,
  },
  {
    name: "Complete Profile details",
    text: "Step 4",
    isDone: false,
    isActive: false,
  },
  {
    name: "Complete the Benchmarking test",
    text: "Step 5",
    isDone: false,
    isActive: false,
  },
];

export const IITRoparStepsList = [
  { name: "Pay registration fee", text: "Step 1", isDone: false, isActive: true },
  {
    name: "Watch orientation videos by IIT faculty",
    text: "Step 2",
    isDone: false,
    isActive: false,
  },
  {
    name: "Complete remaining course fees",
    text: "Step 3",
    isDone: false,
    isActive: false,
  },
  {
    name: "Complete Profile details",
    text: "Step 4",
    isDone: false,
    isActive: false,
  },
];

export const IITMandiNewStepsList = [
  { name: "Pay registration fee", text: "Step 1", isDone: false, isActive: true },
  {
    name: "Start Onboarding",
    text: "Step 2",
    isDone: false,
    isActive: false,
  },
  {
    name: "Complete remaining course fees",
    text: "Step 3",
    isDone: false,
    isActive: false,
  },
  {
    name: "Complete Profile details",
    text: "Step 4",
    isDone: false,
    isActive: false,
  },
];

export const IITGuwahatiOrientationVideoList = [
  {
    id: 1,
    videoUrl: "https://cdn.masaischool.com/masai-website/IITG_Gaurav_with_Caption_1843a953fb.mp4",
    heading: "About this Course",
    name: "Gaurav Trivedi",
    designation: "Associate Professor, IIT Guwahati",
    linkedinUrl: "https://www.linkedin.com/in/gaurav-trivedi-4671a33/",
  },
  {
    id: 2,
    videoUrl: "https://cdn.masaischool.com/masai-website/IITG_Manish_with_Caption_1_57fbb0754c.mp4",
    heading: "Importance of this Course",
    name: "Manish Upadhyay",
    designation: "Consultant, NSDC",
    linkedinUrl: "https://www.linkedin.com/in/manishupadhyay1/",
  },
  {
    id: 3,
    videoUrl: "https://masai-website-images.s3.ap-south-1.amazonaws.com/IITG_Zoom_Yogesh_with_sub_7b18aae97b.mp4",
    heading: "Course Details",
    name: "Yogesh Bhat",
    designation: "Co-Founder, Masai",
    linkedinUrl: "https://www.linkedin.com/in/yogeshbhatedutech/",
  },
];

export const IITRoparOrientationVideoList = [
  {
    id: 1,
    videoUrl: "https://youtu.be/oXpZzbJE7DA",
    heading: "In campus orientation - First batch",
    name: "IIT Ropar • 24 Aug 2024",
    designation: "",
    linkedinUrl: "",
    isIITRopar: true,
  },
];

export const IITMandiOrientationVideoList = [
  {
    id: 1,
    videoUrl: "https://youtu.be/QmXpQRA4L9Y",
    heading: "About this Course",
    name: "Prof. Laxmidhar Behera",
    designation: "Director, IIT MANDI",
    linkedinUrl: "",
  },
  {
    id: 2,
    heading: "Importance of this Course",
    videoUrl: "https://youtu.be/vNQh305bxXo",
    name: "Dr. Indu Joshi ",
    designation: "Assistant Professor, IIT Mandi",
    linkedinUrl: "https://in.linkedin.com/in/dr-indu-joshi-83b9172a6",
  },
];
