import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useBreakpointValue,
  Heading,
  Box,
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import CameraIcon from "../../../../../assets/icons/components/CameraIcon";
import AfterCameraAccess from "./AfterCameraAccess";
import BeforeCameraAccess from "./BeforeCameraAccess";
import Webcam from "react-webcam";
import useSnackBar from "../../../../common/general/SnackBar";
interface Props {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  handleCapturedPhoto: (currentPictureType: "front" | "back" | null, photo?: string | null) => void;
  currentPictureType: "front" | "back" | null;
  setCurrentPictureType: (value: "front" | "back" | null) => void;
  aadhaarFrontCaptured?: string;
  aadhaarBackCaptured?: string;
  setAadhaarFrontCaptured: (value: string) => void;
  setAadhaarBackCaptured: (value: string) => void;
}

const CameraCaptureDrawer = ({
  isOpen,
  setIsOpen,
  handleCapturedPhoto,
  currentPictureType,
  setCurrentPictureType,
  aadhaarFrontCaptured,
  aadhaarBackCaptured,
  setAadhaarFrontCaptured,
  setAadhaarBackCaptured,
}: Props) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [hasCameraAccess, setHasCameraAccess] = useState<boolean>(false);
  const [loadingCamera, setLoadingCamera] = useState<boolean>(false);
  const webcamRef = useRef<Webcam>(null);
  useEffect(() => {
    if (currentPictureType && !hasCameraAccess) {
      setLoadingCamera(true);
    }
  }, [currentPictureType]);

  const [imageSrc, setImageSrc] = useState<string | null>();
  const capturePhoto = useCallback(() => {
    let imageSrc;
    if (webcamRef && webcamRef.current) imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
  }, [webcamRef]);

  useEffect(() => {
    if (!imageSrc) return;
    handleCapturedPhoto(currentPictureType, imageSrc);
  }, [imageSrc]);

  const videoConstraints: boolean | MediaTrackConstraints | undefined = {
    facingMode: "environment",
    width: isMobile ? 343 : 686,
    height: isMobile ? 430 : 386,
  };
  const snackbar = useSnackBar();
  const onUserMedia = () => {
    setHasCameraAccess(true);
    setLoadingCamera(false);
  };

  const onUserMediaError = () => {
    setHasCameraAccess(false);
    setLoadingCamera(false);
    snackbar.error("Camera access denied!");
  };
  const handleClose = () => {
    setCurrentPictureType(null);
    setIsOpen(false);
  };
  return (
    <>
      <Drawer isOpen={isOpen} placement={"bottom"} onClose={handleClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Box d={"flex"} alignContent={"center"}>
              <Box mt={"-4px"}>
                <CameraIcon boxSize="6" color={"ms-grey.800"} />
              </Box>
              <Heading ml={"ms-8"} as="h4">
                Camera
              </Heading>
            </Box>
          </DrawerHeader>
          <DrawerBody mt={{ base: "-12px", md: "0px" }} p={{ base: "16px", md: "24px" }}>
            <Box>
              <Box>
                {hasCameraAccess ? (
                  <AfterCameraAccess
                    videoConstraints={videoConstraints}
                    onUserMedia={onUserMedia}
                    onUserMediaError={onUserMediaError}
                    webcamRef={webcamRef}
                    loadingCamera={loadingCamera}
                    capturePhoto={capturePhoto}
                    aadhaarFrontCaptured={aadhaarFrontCaptured}
                    aadhaarBackCaptured={aadhaarBackCaptured}
                    currentPictureType={currentPictureType}
                    setAadhaarFrontCaptured={setAadhaarFrontCaptured}
                    setAadhaarBackCaptured={setAadhaarBackCaptured}
                    handleClose={handleClose}
                  />
                ) : (
                  <BeforeCameraAccess />
                )}
              </Box>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {isOpen && (
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          onUserMedia={onUserMedia}
          onUserMediaError={onUserMediaError}
          style={{ visibility: "hidden", width: 0, height: 0 }}
        />
      )}
    </>
  );
};

export default CameraCaptureDrawer;
