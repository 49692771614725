import { Box, Button, Heading, Skeleton, Text, useBreakpointValue } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useActiveApplication } from "../../api/utils/hooks/useApplication";
import { useContext, useEffect, useState } from "react";
import { ModalTemplate, ModalTemplateV2 } from "../common/general";
import CounsellingDrawerContent from "../V2/ModalContent/CounsellingDrawerContent";
import { sendTrackingEvent } from "../common/utils/gtm";
import { AppContext } from "../../context/ApplicationContext";
import { useAllCounsellingStatus } from "../../api/utils/hooks/useCounsellingStatus";
import { isRequestCallbackDoneFn } from "../../utils/user";
import { ApplicationType, COUNSELLING_TYPE, GetPaymentStatusType, ProfileAssessment, ProfileType } from "../../api/schemas/schema";
import { useGetPaymentStatus } from "../../api/utils/hooks/usePayment";
import BottomDrawer from "../common/V2/BottomDrawer";
import IITResultCard from "./IITResultCard";
import { useLastAssessment } from "../../api/utils/hooks/useAssessment";
import { ProgramProfileType } from "../../enums/ProgramType.enum";
import { useProfile } from "../../api/utils/hooks/useProfile";
import IITCourseTimeLine from "./IITCourseTimeLine";
import ShareModal from "../../pages/Courses/ShareModal";

const IITCourseResultsPage = () => {
  const history = useHistory();
  const { data: activeApplication, isFetched, refetch } = useActiveApplication();
  const { data: profile } = useProfile();
  const { data: lastAssessment, isLoading: loadinglastAssessment } = useLastAssessment();
  const hasUserCompletedOnboarding = activeApplication && activeApplication?.status === "ONBOARDING_COMPLETE" ? true : false;
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [isRequestCallbackDone, setIsRequestCallbackDone] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isAdmitCardModalOpen, setIsAdmitCardModalOpen] = useState<boolean>(false);
  const [openCounsellingSuccessModal, setOpenCounsellingSuccessModal] = useState(false);
  const [shouldOpenModal, setShouldOpenModal] = useState(true);
  const [showMSATResultPendingModal, setShowMSATResultPendingModal] = useState(false);
  const state = useContext(AppContext)[0];
  const { data: paymentData, refetch: refetchPayment, isFetched: isPaymentFetched } = useGetPaymentStatus("REGISTRATION_FEES");

  const { data: semesterPaymentData } = useGetPaymentStatus("SEMESTER_FEES");

  const { data: allCounsellingStatus, isLoading: allCounsellingStatusLoading } = useAllCounsellingStatus();

  useEffect(() => {
    const isIITGDashboard = state.coursePreference === ProgramProfileType.IIT_GUWAHATI;
    const isIITMandiDashboard = state.coursePreference === ProgramProfileType.IIT_MANDI;
    // get counselling status with source as request callback
    if (allCounsellingStatus && allCounsellingStatus.length > 0) {
      const counsellingStatus = isRequestCallbackDoneFn(allCounsellingStatus, isIITGDashboard, isIITMandiDashboard);
      if (counsellingStatus) {
        setIsRequestCallbackDone(true);
      }
    }
  }, [allCounsellingStatus, state]);

  useEffect(() => {
    if (isPaymentFetched && (paymentData?.status !== "PAID" || !paymentData)) {
      history.push("/registration-fees");
    }
  }, [paymentData, isPaymentFetched]);

  useEffect(() => {
    if (isFetched) {
      const isForIITG = profile?.program_profile_type === "IIT_GUWAHATI";
      const hasPaidForIITGuwahati = paymentData?.status === "PAID" && paymentData.is_iit_guwahati_course ? true : false;

      const hasPaidForIITMandi = paymentData?.status === "PAID" && paymentData.is_iit_mandi_course ? true : false;

      if (isForIITG && hasPaidForIITMandi) {
        history.push("/mc-iitg-cs");
      }

      if (!isForIITG && hasPaidForIITGuwahati) {
        history.push("/iit-mandi-ai-ml");
      }
    }
  }, [isFetched, profile]);

  useEffect(() => {
    refetch();
    refetchPayment();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShouldOpenModal(false);
      localStorage.setItem("isBenchmarkingTestCleared", "true");
    }, 5000);
  }, []);

  const isForIITG = profile?.program_profile_type === "IIT_GUWAHATI";
  const iitGuwahatiId = (isFetched && activeApplication?.iit_guwahati_id) || "--------";
  const iitMandiId = (isFetched && String(activeApplication?.meta_data?.iit_mandi_id)) || "--------";
  const hasShownBenchmarkingTestModal = localStorage.getItem("isBenchmarkingTestCleared") === "true";

  const idToShow = isForIITG ? iitGuwahatiId : iitMandiId;
  const isBenchmarkingDone = lastAssessment && ["ENDED", "GRADED"].includes(lastAssessment?.status?.toUpperCase());

  useEffect(() => {
    if (lastAssessment && lastAssessment?.status === "ENDED" && !showMSATResultPendingModal) {
      setShowMSATResultPendingModal(true);
    } else {
      setShowMSATResultPendingModal(false);
    }
  }, [lastAssessment]);
  return (
    <Skeleton h={"full"} isLoaded={!loadinglastAssessment || isFetched}>
      <>
        <Box
          p="16px"
          bg="#F2F6FF"
          minH="100vh"
          h={"100%"}
          my={"auto"}
          alignContent={"center"}
          display="flex"
          w={"full"}
          alignItems="center"
          justifyContent="space-evenly"
          flexDir={{ base: "column", md: "row" }}
          pb={24}
          gap={"24px"}
        >
          {lastAssessment && lastAssessment?.attempt_count && lastAssessment?.attempt_count >= 1 && (
            <Box maxW={"800px"} w={"full"}>
              <IITResultCard lastAssessment={lastAssessment as ProfileAssessment} loadinglastAssessment={loadinglastAssessment} />
            </Box>
          )}

          <Box
            maxW="550px"
            rounded={"16px"}
            overflow="hidden"
            position="relative"
            shadow="base"
            border="1px solid #E5E5E5"
            bg="white"
            px={{ base: "0px", md: "30px" }}
            pt={{ base: "12px", md: "30px" }}
            pb={{ md: "15px" }}
            mb={4}
          >
            <Text maxW="100%" px={{ base: "12px", md: "0px" }} textStyle="h5" mb={{ md: "32px" }}>
              You&apos;re officially part of the IIT {isForIITG ? "Guwahati" : "Mandi"} family now - congratulations!
            </Text>

            <Box rounded={{ md: "12px" }} shadow={{ md: "base" }} border={{ md: "1px solid #D9D9D9" }} pt="16px">
              <Box px="12px">
                <IITCourseTimeLine
                  profile={profile as ProfileType}
                  lastAssessment={lastAssessment as ProfileAssessment}
                  activeApplication={activeApplication as ApplicationType}
                  paymentData={paymentData as GetPaymentStatusType}
                  semesterPaymentData={semesterPaymentData as GetPaymentStatusType}
                />
              </Box>

              <Box roundedBottom={"12px"} bg="#FFF3CC" px="12px" py="16px">
                <Text textStyle="caption" color="#544D4F">
                  Your IIT {isForIITG ? "Guwahati" : "Mandi"} ID No.
                </Text>
                <Text textStyle="h6">{idToShow ? idToShow : "-------"}</Text>
              </Box>
            </Box>

            <Box maxW="550px" mx="auto" display={{ base: "none", md: "block" }} mt={{ sm: "5px", md: "20px" }} w="full" pb={{ sm: 20, md: 0 }}>
              <Button
                textStyle="btn-md"
                variant="primary"
                w="full"
                onClick={() => {
                  if (isBenchmarkingDone) {
                    setIsAdmitCardModalOpen(true);
                  } else {
                    history.push(`${!isForIITG ? "/iit-onboarding" : "/iitg-onboarding"}`);
                  }
                }}
              >
                {isBenchmarkingDone ? "Show Admit Card" : !hasUserCompletedOnboarding ? "Complete Onboarding" : " Onboarding Completed"}
              </Button>
            </Box>
          </Box>

          <Box maxW="550px" mx="auto" display={{ md: "none" }} mt={{ sm: "25px", md: "20px" }} w="full" mb={{ sm: 40, md: 0 }}>
            <Button
              textStyle="btn-md"
              variant="primary"
              w="full"
              onClick={() => {
                if (isBenchmarkingDone) {
                  setIsAdmitCardModalOpen(true);
                } else {
                  history.push(`${!isForIITG ? "/iit-onboarding" : "/iitg-onboarding"}`);
                }
              }}
            >
              {isBenchmarkingDone ? "Show Admit Card" : !hasUserCompletedOnboarding ? "Complete Onboarding" : " Onboarding Completed"}
            </Button>
          </Box>

          <Box
            display="flex"
            py={{ base: "10px", md: "16px" }}
            px={{ base: "12px", md: "24px" }}
            justifyContent="flex-end"
            w="full"
            borderTop="1px solid #D9D9D9"
            position="fixed"
            bottom="0px"
            bg="white"
          >
            <Box display="flex" alignItems="center" gap={{ base: "10px", md: "32px" }}>
              <Text textStyle="body1" fontSize={{ base: "14px", md: "18px" }} color="#544D4F">
                Don’t want to proceed further?{" "}
              </Text>
              <Button
                variant="secondary"
                size={"md"}
                onClick={() => {
                  sendTrackingEvent({
                    event: "d_header_callback_iitg",
                    eventLabel: window.location.pathname,
                  });
                  setOpenDrawer(true);
                }}
                isDisabled={isRequestCallbackDone || allCounsellingStatusLoading}
              >
                REQUEST CALLBACK
              </Button>
            </Box>
          </Box>
        </Box>

        {/* councelling details modal */}
        <BottomDrawer
          setOpenCounsellingSuccessModal={setOpenCounsellingSuccessModal}
          source={isForIITG ? COUNSELLING_TYPE.REQUEST_CALLBACK_IITG : COUNSELLING_TYPE.REQUEST_CALLBACK_IIT_MANDI}
          openDrawer={openDrawer && isMobile ? true : false}
          setOpenDrawer={setOpenDrawer}
        />
        <ModalTemplate
          title=""
          isOpen={openDrawer && !isMobile ? true : false}
          onClose={() => {
            setOpenDrawer(false);
          }}
          showCloseButtonIcon={false}
        >
          <CounsellingDrawerContent
            setOpenCounsellingSuccessModal={setOpenCounsellingSuccessModal}
            source={isForIITG ? COUNSELLING_TYPE.REQUEST_CALLBACK_IITG : COUNSELLING_TYPE.REQUEST_CALLBACK_IIT_MANDI}
            setOpenDrawer={setOpenDrawer}
          />
        </ModalTemplate>
        <ModalTemplateV2
          autoCloseInSeconds={5}
          closeOnOverlayClick={true}
          isOpen={openCounsellingSuccessModal}
          onClose={() => {
            setOpenCounsellingSuccessModal(false);
            history.push("/");
          }}
          text="We have received your details, you will be receiving a call from our team shortly."
          extra={
            <>
              <Button
                onClick={() => {
                  setOpenCounsellingSuccessModal(false);
                  history.push("/");
                }}
                mt="28px"
                variant="secondary"
              >
                Close
              </Button>
            </>
          }
        />

        <ModalTemplateV2
          closeOnOverlayClick={false}
          isOpen={shouldOpenModal && !hasShownBenchmarkingTestModal}
          closeOnEsc={false}
          hideIconWithConfetti={true}
          showConfetti={false}
          onClose={() => {
            //
          }}
          waiting={true}
          text={`Congratulations! You have cleared the Benchmarking Test!`}
        />

        {/* Show Admit Card Modal */}
        <ModalTemplate
          title=""
          isOpen={isAdmitCardModalOpen}
          onClose={() => {
            setIsAdmitCardModalOpen(false);
          }}
          showCloseButtonIcon={true}
          marginX={2}
          maxWidth="484px"
        >
          <ShareModal
            gtmEvents={{
              facebook: "d_onboarding_admit_fb",
              copyUrl: "d_onboarding_admit_cp",
              linkedin: "d_onboarding_admit_in",
              twitter: "d_onboarding_admit_x",
            }}
            certificateUrl={activeApplication?.admit_card_link || ""}
            name={profile?.name}
            isIITGuwahatiDashboard={isForIITG}
            isIITMandiDashboard={!isForIITG}
            setIsAdmitCardModalOpen={setIsAdmitCardModalOpen}
          />
        </ModalTemplate>

        {showMSATResultPendingModal && (
          <ModalTemplateV2
            closeOnOverlayClick={false}
            isOpen={showMSATResultPendingModal}
            hideIconWithConfetti={true}
            waiting={true}
            onClose={() => {
              setShowMSATResultPendingModal(false);
            }}
            text={
              <Box>
                <Heading as="h5" color="#fff">
                  Thank you for attempting Benchmarking Test!
                </Heading>
                <Text mt="4px" textStyle="body1" color="#fff">
                  Please wait while we are getting your scores.
                </Text>
              </Box>
            }
            extra={
              <>
                <Button
                  as="a"
                  onClick={() => {
                    window.location.reload();
                  }}
                  mt="28px"
                  variant="secondary"
                >
                  Refresh Result Status
                </Button>
              </>
            }
          />
        )}
      </>
    </Skeleton>
  );
};

export default IITCourseResultsPage;
