import { Box, Button, Input, Text } from "@chakra-ui/react";
import { InputCustom } from "../../../../common/general";
import { useState } from "react";
import { ChecklistItemType } from "./ChecklistForm";
import { useMutation } from "react-query";
import { sendEventTrigger } from "../../../../../api/utils/api/v2/admin";
import useSnackBar from "../../../../common/general/SnackBar";
import { axiosErrorHandler } from "../../../../../api/utils/error";
import { TriggerType } from "../../../../../api/schemas/schema";
import { useProfile } from "../../../../../api/utils/hooks/useProfile";
import GreenCheckCircle from "../../../../../assets/icons/components/GreenCheckCircle";

const ChecklistItem = ({
  trigger_type,
  index,
  eventId,
}: {
  trigger_type: TriggerType;
  index: number;
  eventId: number;
}) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneRegExp = /^[6-9]\d{9}$/;
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const snackbar = useSnackBar();
  const { data: profile } = useProfile();

  const [isDoneOnce, setIsDoneOnce] = useState<boolean>(
    localStorage.getItem(`profile-${profile?.slug}-event-${eventId}-trigger-${trigger_type}`) ===
      "true"
  );
  console.log("---", isDoneOnce);
  const { mutate: sendEventTriggerMutate, isLoading: sendEventTriggerLoading } = useMutation(
    sendEventTrigger,
    {
      onSuccess: () => {
        const localStorageKey = `profile-${profile?.slug}-event-${eventId}-trigger-${trigger_type}`;
        setIsDoneOnce(true);
        localStorage.setItem(localStorageKey, "true");
        snackbar.success("Trigger sent successfully");
      },
      onError: (error) => {
        const e = axiosErrorHandler(error);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("Something went wrong");
        }
      },
    }
  );

  return (
    <>
      {trigger_type === "FIFTEEN_MINUTES_WHATSAPP" ? (
        <Box>
          <Text textStyle={"body2-md"} color={"ms-grey.700"} mb="4px">
            {index + 1}. Enter phone number to receive {trigger_type} trigger
          </Text>
          <Box width={"400px"}>
            <InputCustom
              required
              type="tel"
              isInvalid={phone !== "" && !phoneRegExp.test(phone)}
              getValueCallback={(value) => {
                setPhone(value);
              }}
              errorMessage={"Please enter a 10 digit phone number"}
              placeholder={`type number here...`}
            />
          </Box>
          <Box mt="16px" display={"flex"} alignItems={"center"}>
            <Button
              onClick={() => {
                sendEventTriggerMutate({
                  eventId,
                  trigger_type,
                  phone_number: "+91" + phone,
                });
              }}
              disabled={phone === "" || !phoneRegExp.test(phone)}
              variant="secondary"
              size="sm"
            >
              Send whatsapp message
            </Button>
            {isDoneOnce && <GreenCheckCircle boxSize="24px" />}
          </Box>
        </Box>
      ) : (
        <Box>
          <Box width={"400px"}>
            <Text textStyle={"body2-md"} color={"ms-grey.700"} mb="4px">
              {index + 1}. Enter email to receive {trigger_type} trigger
            </Text>
            <InputCustom
              required
              type="email"
              isInvalid={email !== "" && !emailRegex.test(email)}
              getValueCallback={(value) => {
                setEmail(value);
              }}
              errorMessage={"Please enter a valid email"}
              placeholder={`type email here...`}
            />
          </Box>
          <Box mt="16px" display={"flex"} alignItems={"center"}>
            <Button
              onClick={() => {
                sendEventTriggerMutate({
                  eventId,
                  trigger_type,
                  email,
                });
              }}
              disabled={email === "" || !emailRegex.test(email)}
              variant="secondary"
              size="sm"
            >
              Send email
            </Button>
            {isDoneOnce && <GreenCheckCircle boxSize="24px" />}
          </Box>
        </Box>
      )}
    </>
  );
};
export default ChecklistItem;
