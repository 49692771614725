import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import DateSelect from "../../common/DateSelect";
import dayjs from "dayjs";

import { InputCustom, ModalTemplate, RadioButtonGroup } from "../../common/general";
import { useMutation } from "react-query";
import { sendEmailOtpV2 } from "../../../api/utils/api";
import useSnackBar from "../../common/general/SnackBar";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import { axiosErrorHandler } from "../../../api/utils/error";
import EmailVerifyModalV2 from "../../common/V2/EmailVerifyModalV2";
import { CheckCircle } from "../../../assets/icons/components";
import { sendTrackingEvent } from "../../common/utils/gtm";

export type OnboardingFormType = {
  dob: string | null;
  graduationMonth: number | null;
  graduationYear: number | null;
  currentlyWorking?: boolean;
  hideHeaderText?: boolean;
  queryType?: string;
  howDidYouHearAboutUs?: string;
  areYouComfortableVisitingCampus?: string;
  preferredTiming?: string;
};

interface Props {
  formValues: OnboardingFormType;
  setFormValues: React.Dispatch<React.SetStateAction<OnboardingFormType>>;
  gridTemplateColumns?: string;
  hideHeaderText?: boolean;
  isGraduationCompleted?: boolean;
  setIsGraduationCompleted?: (param: boolean) => void;
  selectedWorkingOption?: string;
  setSelectedWorkingOption?: (param: string) => void;
  hideEmailVerifyInput?: boolean;
}

const OnboardingForm = ({
  hideHeaderText,
  formValues,
  setFormValues,
  gridTemplateColumns,
  isGraduationCompleted,
  setIsGraduationCompleted,
  selectedWorkingOption,
  setSelectedWorkingOption,
  hideEmailVerifyInput = false,
}: Props) => {
  const snackbar = useSnackBar();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { data: profile, refetch: refetchProfile } = useProfile();

  const [updatedEmail, setUpdatedEmail] = useState<string>("");
  const [showEmailOtpModal, setShowEmailOtpModal] = useState(false);
  const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(false);

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFormValuesChange = (key: string, value?: any) => {
    if (key === "monthYearOfGraduation") {
      const parsedDate = dayjs(value);
      const year = parsedDate.year();
      const month = parsedDate.month() + 1;
      setFormValues((prev) => ({
        ...prev,
        graduationMonth: month,
        graduationYear: year,
      }));
    } else if (key === "dob") {
      //convert date in this format 2000-07-05
      setFormValues((prev) => ({
        ...prev,
        [key]: value.format("YYYY-MM-DD"),
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const { mutate: sendEmailOtpMutate, isLoading: sendEmailOtpLoading } = useMutation(
    sendEmailOtpV2,
    {
      onSuccess: () => {
        snackbar.success("Otp sent on email!");
        if (!showEmailOtpModal) {
          setShowEmailOtpModal(true);
        }
        if (showUpdateEmailModal) {
          setShowUpdateEmailModal(false);
        }
      },
      onError: (error) => {
        const e = axiosErrorHandler(error);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("Something went wrong");
        }
      },
    }
  );

  const isEmailVerified = profile?.email_verified || hideEmailVerifyInput;

  useEffect(() => {
    refetchProfile();
  }, []);

  return (
    <>
      <Box>
        {hideHeaderText ? null : (
          <Text
            mb="16px"
            color="ms-grey.900"
            textStyle={"body2"}
            fontSize={"14px"}
            fontWeight={700}
            lineHeight={"24px"}
          >
            Please fill this form to start your onboarding.{" "}
          </Text>
        )}

        {/* Email verification */}
        {!hideEmailVerifyInput && (
          <Box display={"grid"} mt={"ms-16"}>
            <Text mb={{ base: "ms-2", md: "2px" }} textStyle="body2-md" color="ms-red.900">
              1 . Kindly Verify your Email address*
            </Text>

            <Box display={"grid"} mt={"ms-16"}>
              <InputGroup w={["100%", "50%", "40%", "40%"]}>
                <Input
                  value={profile && profile.email}
                  placeholder="Enter your email"
                  disabled={true}
                />
                <InputRightElement
                  pr={1}
                  w={profile?.email_verified ? "120px" : "100px"}
                  pt={2}
                  pb={2}
                >
                  {profile?.email && profile?.email_verified ? (
                    <Button
                      colorScheme="green"
                      variant="solid"
                      size={isMobile ? "sm" : "md"}
                      textStyle="btn-md"
                      my={"0px"}
                      cursor={"auto"}
                      disabled={true}
                      _disabled={{
                        _hover: { backgroundColor: "rgba(111, 205, 158, 1) !important" },
                        background: "rgba(111, 205, 158, 1)",
                      }}
                      rightIcon={<CheckCircle boxSize={isMobile ? "16px" : "24px"} />}
                    >
                      verified
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        sendTrackingEvent({
                          event: "d_profile_email_verify",
                          eventLabel: location.pathname,
                        });
                        sendEmailOtpMutate(profile?.email || "");
                      }}
                      variant="primary"
                      my={"0px"}
                      size={isMobile ? "sm" : "md"}
                      isLoading={sendEmailOtpLoading}
                    >
                      Verify
                    </Button>
                  )}
                </InputRightElement>
              </InputGroup>
            </Box>
          </Box>
        )}

        {!isEmailVerified && !hideEmailVerifyInput && (
          <Text textStyle={{ base: "h4", md: "h3" }} mt={"ms-24"} mb={"ms-16"}>
            Please verify your email address to continue with the rest of the steps
          </Text>
        )}

        <Box
          display={"grid"}
          gridTemplateColumns={gridTemplateColumns ? gridTemplateColumns : { md: "repeat(1,1fr)" }}
          gap={{ md: "24px" }}
          mt={"ms-24"}
        >
          {/* Date of birth */}
          <Box display={"grid"}>
            <Text mb={{ base: "ms-8", md: "8px" }} textStyle="body2-md" color="ms-red.900">
              {hideEmailVerifyInput ? 1 : 2} Enter your date of birth *
            </Text>
            <DateSelect
              showRelativeDate={true}
              onChange={(value) => {
                handleFormValuesChange("dob", value);
              }}
              value={formValues.dob ? dayjs(formValues.dob) : null}
              isInvalid={false}
              errorMessage="Please add your date of birth."
              onClickCallback={() => {
                //
              }}
              disabled={false || !isEmailVerified}
              w={["100%", "30%", "40%", "40%"]}
            />
          </Box>

          {/* currently working */}
          <Box mt={"ms-16"}>
            <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
              {hideEmailVerifyInput ? 2 : 3} What is your current status?*
            </Text>
            <RadioButtonGroup
              options={[
                { key: "cw", value: "currently working" },
                { key: "cs", value: "currently studying (in college)" },
                { key: "nwns", value: "Neither working nor studying" },
              ]}
              selectedValue={
                selectedWorkingOption === "cw"
                  ? "cw"
                  : selectedWorkingOption === "cs"
                  ? "cs"
                  : selectedWorkingOption === "nwns"
                  ? "nwns"
                  : ""
              }
              onChange={(key) => {
                if (setSelectedWorkingOption) {
                  setSelectedWorkingOption(key);
                }
                handleFormValuesChange("currentlyWorking", key === "cw" ? true : false);
              }}
              gridTemplateComlumns={{ md: "repeat(3,1fr)" }}
              columnGap={"ms-4"}
              rowGap={"ms-24"}
              disabled={false || !isEmailVerified}
            />
          </Box>

          {/* Graduation Month Year */}
          {selectedWorkingOption === "nwns" ? (
            <Box mt={{ base: "ms-16", md: "0px" }}>
              <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
                {hideEmailVerifyInput ? 3 : 4} Have you finished your graduation?
              </Text>
              <RadioButtonGroup
                options={[
                  { key: "yes", value: "Yes" },
                  { key: "no", value: "No" },
                ]}
                selectedValue={!isGraduationCompleted ? "no" : "yes"}
                onChange={(key) => {
                  if (setIsGraduationCompleted) {
                    setIsGraduationCompleted(key === "yes" ? true : false);
                  }
                  if (key === "no") {
                    setFormValues((prev) => ({
                      ...prev,
                      graduationMonth: null,
                      graduationYear: null,
                    }));
                  }
                }}
                gridTemplateComlumns={{ md: "repeat(3,1fr)" }}
                columnGap={"ms-4"}
                rowGap={"ms-24"}
                disabled={false || !isEmailVerified}
              />
            </Box>
          ) : null}

          {selectedWorkingOption === "cs" ||
          (isGraduationCompleted && selectedWorkingOption === "nwns") ? (
            <Box mt={{ base: "ms-16", md: "0px" }}>
              <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
                {isGraduationCompleted && selectedWorkingOption === "nwns" ? "5" : "4"}. Select your
                graduation month & year *
              </Text>
              <DateSelect
                showRelativeDate={false}
                onChange={(value) => {
                  handleFormValuesChange("monthYearOfGraduation", value);
                }}
                value={
                  formValues.graduationMonth && formValues.graduationYear
                    ? dayjs()
                        .month(formValues.graduationMonth - 1)
                        .year(formValues.graduationYear)
                    : null
                }
                isInvalid={false}
                errorMessage="Please add your month and year of graduation."
                onClickCallback={() => {
                  //
                }}
                yearAndMonthOnly={true}
                disabled={false || !isEmailVerified}
                w={["100%", "50%", "40%", "40%"]}
                selectedOption={selectedWorkingOption}
              />
            </Box>
          ) : null}
        </Box>
      </Box>

      {/* Email Verify */}
      <EmailVerifyModalV2
        showEmailOtpModal={showEmailOtpModal}
        setShowEmailOtpModal={setShowEmailOtpModal}
        updatedEmail={updatedEmail}
        setShowUpdateEmailModal={setShowUpdateEmailModal}
      />

      {/* Email update modal */}
      <ModalTemplate
        title=""
        isOpen={showUpdateEmailModal ? showUpdateEmailModal : false}
        onClose={() => {
          refetchProfile();
          setShowUpdateEmailModal && setShowUpdateEmailModal(false);
        }}
        marginX={2}
        maxWidth="464px"
        showCloseButtonIcon={true}
      >
        {
          <Box>
            <Heading as="h4">{profile?.email ? "Update your email" : "Add your email"}</Heading>
            {profile?.email && (
              <>
                <Text mt={"ms-16"} textStyle="body2" color="ms-grey.700">
                  Your current email address:
                </Text>
                <Text mb={"ms-8"} mt={"ms-4"} textStyle="body2-md" color="ms-grey.700">
                  {profile?.email}
                </Text>
              </>
            )}
            <Text mt={"ms-24"} mb={"ms-8"} textStyle="body2-md" color="ms-grey.800">
              {profile?.email
                ? "Please enter your new email address"
                : "Please enter your email address"}
            </Text>
            <InputCustom
              required
              type="email"
              isInvalid={updatedEmail !== "" && !emailRegex.test(updatedEmail)}
              getValueCallback={(value) => {
                setUpdatedEmail(value);
              }}
              errorMessage={"Please enter a valid email"}
              placeholder="Enter your new email address"
              onClick={() => {
                //
              }}
            />
            <Flex>
              <Button
                mt={updatedEmail !== "" && !emailRegex.test(updatedEmail) ? "14px" : "46px"}
                onClick={() => {
                  sendEmailOtpMutate(updatedEmail || profile?.email);
                }}
                size="md"
                variant="primary"
                ml={"auto"}
                disabled={!updatedEmail || sendEmailOtpLoading || !emailRegex.test(updatedEmail)}
                position={sendEmailOtpLoading ? "relative" : "static"}
              >
                update & verify
                {sendEmailOtpLoading && (
                  <Spinner
                    speed={"0.5s"}
                    thickness={"2px"}
                    size={"md"}
                    position={"absolute"}
                    color={"ms-blue.500"}
                  />
                )}
              </Button>
            </Flex>
          </Box>
        }
      </ModalTemplate>
    </>
  );
};

export default OnboardingForm;
