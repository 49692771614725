import { Box, Button, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

interface Props {
  isBtnDisabled: boolean;
  isLoading: boolean;
  handleSubmit: () => void;
  hideButton: boolean;
  msatStepBtnText: string;
  currentOnbaordingStep: number;
}

const DAFixedButton = ({ isBtnDisabled, isLoading, handleSubmit, hideButton, msatStepBtnText, currentOnbaordingStep }: Props) => {
  const history = useHistory();
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box
      bottom={0}
      w={"full"}
      pos={"fixed"}
      textAlign={"end"}
      display={hideButton ? "none" : "block"}
      bg={"white"}
      right={{ base: 0 }}
      py={{ base: 2, md: 4 }}
      px={{ base: "16px", md: "24px" }}
      h={{ md: "72px" }}
      boxShadow={"0px -1px 0px 0px #D9D9D9"}
      zIndex={{ base: 10, lg: 1 }}
    >
      <Flex align={"center"} gap={2} justify={"space-between"} maxW={1120} mx={"auto"}>
        <Button
          variant="solid"
          color={"#3470E4"}
          size={isMobile ? "sm" : "md"}
          onClick={() => {
            history.push("/");
          }}
        >
          Back To Dashboard
        </Button>
        <Button variant="primary" isDisabled={isBtnDisabled} isLoading={isLoading} onClick={handleSubmit}>
          <Text
            pos={"absolute"}
            bg={"#FFE799"}
            rounded={"32px"}
            h={"20px"}
            px={"4px"}
            top={"-12px"}
            left={"6px"}
            color={"#6C6768"}
            fontSize={"10px"}
            textTransform={"none"}
            display={msatStepBtnText === "Retake MSAT" ? "flex" : "none"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            Only 1 attempt left!
          </Text>
          {currentOnbaordingStep === 1 ? msatStepBtnText : "Submit"}
        </Button>
      </Flex>
    </Box>
  );
};

export default DAFixedButton;
