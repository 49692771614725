import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const Refferals = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M21.71 8.70999C22.96 7.45999 22.39 5.99999 21.71 5.28999L18.71 2.28999C17.45 1.03999 16 1.60999 15.29 2.28999L13.59 3.99999H11C9.09999 3.99999 7.99999 4.99999 7.43999 6.14999L2.99999 10.59V14.59L2.28999 15.29C1.03999 16.55 1.60999 18 2.28999 18.71L5.28999 21.71C5.82999 22.25 6.40999 22.45 6.95999 22.45C7.66999 22.45 8.31999 22.1 8.70999 21.71L11.41 19H15C16.7 19 17.56 17.94 17.87 16.9C19 16.6 19.62 15.74 19.87 14.9C21.42 14.5 22 13.03 22 12V8.99999H21.41L21.71 8.70999ZM20 12C20 12.45 19.81 13 19 13H18V14C18 14.45 17.81 15 17 15H16V16C16 16.45 15.81 17 15 17H10.59L7.30999 20.28C6.99999 20.57 6.81999 20.4 6.70999 20.29L3.71999 17.31C3.42999 17 3.59999 16.82 3.70999 16.71L4.99999 15.41V11.41L6.99999 9.40999V11C6.99999 12.21 7.79999 14 9.99999 14C12.2 14 13 12.21 13 11H20V12ZM20.29 7.28999L18.59 8.99999H11V11C11 11.45 10.81 12 9.99999 12C9.18999 12 8.99999 11.45 8.99999 11V7.99999C8.99999 7.53999 9.16999 5.99999 11 5.99999H14.41L16.69 3.71999C17 3.42999 17.18 3.59999 17.29 3.70999L20.28 6.68999C20.57 6.99999 20.4 7.17999 20.29 7.28999Z"
      fill="currentColor"
    />
  </Icon>
);

export default Refferals;
