import { Box, Heading, Stack } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import useCleverTap, { ScreenNames } from "../../../../clevertap/useCleverTap";
import { AppContext } from "../../../../context/ApplicationContext";
import GeneralBanner from "./GeneralBanner";
import { ModalTemplate } from "../../../../components/common/general";
import BannerLinksModalContent from "./BannerLinksModalContent";
import ZoomTelegramLinks from "./ZoomTelegramLinks";
import FacingAnyIssues from "./FacingAnyIssues";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import { useLocation } from "react-router-dom";
export const BannerZoomTelegramLinks = ({
  screen_name = ScreenNames.onboarding,
}: {
  screen_name?: ScreenNames;
}) => {
  const state = useContext(AppContext)[0];
  const is_mfp = state.applicationData?.masai_foundation_program;
  const batch = state.applicationData?.batch_campus.batch;
  // retrieve zoom and telegram links
  const zoom_link = is_mfp ? batch?.mfp_zoom_webinar_link : batch?.zoom_webinar_link;
  const telegram_link = is_mfp ? batch?.mfp_telegram_link : batch?.telegram_link;
  const slack_link = is_mfp
    ? state.applicationData?.batch_campus?.mfp_slack_link
    : state.applicationData?.batch_campus?.slack_link;
  const [isBannerLinksModalOpen, setIsBannerLinksModalOpen] = useState(false);
  const clevertap = useCleverTap()[0];
  const location = useLocation();
  const onBannerLinksModalClose = () => {
    setIsBannerLinksModalOpen(false);
    sendTrackingEvent({ event: "dialog_box_X", eventLabel: location.pathname });
    // clevertap.fireEvent(CleverTapEvents.click_close_modal, {
    //   screen_name: screen_name,
    //   modal_name: ModalNames.onboardingLinks,
    // });
  };
  useEffect(() => {
    const onboardingLinksModalVisited = JSON.parse(
      localStorage.getItem("onboardingLinksModalVisited-" + state.applicationData?.slug) as string
    );
    if (!onboardingLinksModalVisited) {
      // show modal and set visited to true in localstorage
      setIsBannerLinksModalOpen(true);
      localStorage.setItem(
        "onboardingLinksModalVisited-" + state.applicationData?.slug,
        JSON.stringify(true)
      );
    }
  }, []);
  return (
    <>
      <GeneralBanner
        id={`banner-zoom-telegram-links-${state.applicationData?.slug}-${batch?.id}`}
        name="zoom-telegram-links"
        color="ms-yellow.50"
        // only being used in onboarding stages
        screen_name={ScreenNames.onboarding}
        content={
          <>
            <Stack
              direction={["column", "column", "column", "column", "row"]}
              w="full"
              justifyContent="space-between"
              pr="54px"
              align={["none", "none", "none", "none", "center"]}
              spacing={{ base: "1.5rem" }}
            >
              <Box>
                <Stack gap={{ base: "ms-10", md: "ms-4" }}>
                  <Heading as="h6">Please note, joining these two links are mandatory!</Heading>
                  <Box d={{ base: "none", md: "block" }}>
                    <FacingAnyIssues />
                  </Box>
                </Stack>
              </Box>
              <ZoomTelegramLinks
                screen_name={screen_name}
                zoom_link={zoom_link}
                slack_link={slack_link}
                telegram_link={telegram_link}
                direction={["column", "column", "column", "column", "row"]}
                spacing={["12px", "12px", "12px", "12px", "46px"]}
              />
              <Box d={{ base: "block", md: "none" }}>
                <FacingAnyIssues isLineBreak={true} />
              </Box>
            </Stack>
          </>
        }
        onClick={() => {
          //
        }}
      />
      <ModalTemplate
        title=""
        isOpen={isBannerLinksModalOpen}
        onClose={onBannerLinksModalClose}
        marginX={2}
        maxWidth="582px"
        showCloseButtonIcon
      >
        <BannerLinksModalContent
          screen_name={screen_name}
          zoom_link={zoom_link}
          slack_link={slack_link}
          telegram_link={telegram_link}
          onBannerLinksModalClose={onBannerLinksModalClose}
        />
      </ModalTemplate>
    </>
  );
};

export default GeneralBanner;
