import clevertap from "clevertap-web-sdk";
import React from "react";

clevertap.spa = true;
if (process.env.REACT_APP_CLEVERTAP_ID) {
  clevertap.init("6XL-S15-SL8A"); // Replace with values applicable to you. Refer below
}
clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
clevertap.privacy.push({ useIP: false }); // Set the flag to true, if the user agrees to share their IP data
if (process.env.REACT_APP_CLEVERTAP_LOG) {
  clevertap.setLogLevel(Number(process.env.REACT_APP_CLEVERTAP_LOG));
}

export const CleverTapContext = React.createContext([clevertap]);

const CleverTapContextProvider: React.FunctionComponent<{
  children: JSX.Element | (string | JSX.Element)[];
}> = ({ children }) => {
  console.log(clevertap.event);
  return <CleverTapContext.Provider value={[clevertap]}>{children}</CleverTapContext.Provider>;
};
export default CleverTapContextProvider;
