import { Box, Flex, Link, Text } from "@chakra-ui/react";
import { TryArrow } from "../../../assets/icons/components";
import DragAndDrop from "../../msat/success-fail/AadharOcr/DragAndDrop";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { getPresignedUrl } from "../../../api/utils/api/v2/application";
import { GetS3PresignedUrlType } from "../../../api/schemas/schema";
import { axiosErrorHandler } from "../../../api/utils/error";
import useSnackBar from "../../common/general/SnackBar";

type Props = {
  disabled: boolean;
  setFileUploadData: (data: File | undefined) => void;
  fileUploadData: File;
  acceptedFileTypes?: string[];
  toastMessage: string;
  handleDelete: () => void;
  handleFileUpload: (args: GetS3PresignedUrlType) => void;
};

const CustomUploadFileInput = ({ disabled, setFileUploadData, fileUploadData, acceptedFileTypes, toastMessage, handleDelete, handleFileUpload }: Props) => {
  const snackbar = useSnackBar();
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  const { mutate: presignedS3UrlsMutate } = useMutation(getPresignedUrl, {
    onSuccess: async (presignedUrls: GetS3PresignedUrlType) => {
      setIsFileUploading(true);
      handleFileUpload(presignedUrls);

      setIsFileUploading(false);
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
      setIsFileUploading(false);
    },
  });

  useEffect(() => {
    if (fileUploadData) {
      presignedS3UrlsMutate();
    }
  }, [fileUploadData]);

  const disabledBox = () => (
    <Box>
      <Box w={"492px"}>
        <Flex borderRadius={"ms-8"} border="1px dashed #B3B3B3" bgColor={"#FBFBFB"} alignItems={"center"} justifyContent={"center"} h={"48px"}>
          <TryArrow color="ms-green.400" boxSize={"6"} />
          <Text ml={"ms-10"} textStyle={"caption"} color="ms-grey.500">
            Drag & drop here or{" "}
            <Link>
              <Text d={"inline"} textStyle={"caption"} color="ms-grey.500">
                Browse
              </Text>
            </Link>
          </Text>
        </Flex>
      </Box>
    </Box>
  );
  return (
    <Box>
      <Box>
        {/* Drag and drop Flow */}
        <Box display={"flex"} alignItems={"center"} mt={2}>
          {disabled || isFileUploading ? (
            disabledBox()
          ) : (
            <DragAndDrop
              handleImageDelete={() => {
                handleDelete();
                setFileUploadData(undefined);
              }}
              file={fileUploadData}
              setFile={setFileUploadData}
              acceptedFileTypes={acceptedFileTypes}
              toastMessage={toastMessage}
              type="front"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomUploadFileInput;
