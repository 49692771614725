import OpenAPIClientAxios from "openapi-client-axios";
import { Client as AppClient } from "../generated/client";
import { createStandaloneToast } from "@chakra-ui/react";
import axios from "axios";
import { axiosErrorHandler } from "./error";
const toast = createStandaloneToast();

// * defaults out to prod endpoints
const serverUrl = window.location.hostname.includes("prepleaf.com") ? process.env.REACT_APP_SERVER_URL_PREPLEAF : process.env.REACT_APP_SERVER_URL;
const authServerUrl = window.location.hostname.includes("prepleaf.com") ? process.env.REACT_APP_AUTH_SERVER_URL_PREPLEAF : process.env.REACT_APP_AUTH_SERVER_URL;

const server = serverUrl ?? "api.masai-one.masaischool.com";
const authServer = authServerUrl ?? "api.sso.masaischool.com";

const ssoUrl = process.env.REACT_APP_SSO_URL ?? "sso.masaischool.com";

if (process.env.NODE_ENV === "development") {
  console.log(`
  Server: ${server}
  Auth Server: ${authServer}
  SSO: ${ssoUrl}
  protocol: ${window.location.protocol}
  definition: ${window.location.protocol}//${server}/openapi.json}
`);
}

class API<T> {
  api;
  Client;
  constructor() {
    this.api = new OpenAPIClientAxios({
      // fixed
      definition: `${window.location.protocol}//${server}/openapi.json`,
      axiosConfigDefaults: {
        // baseUrl
        baseURL: `${window.location.protocol}//${server}/`,
        withCredentials: true,
      },
    });

    this.api.init();
    this.Client = this.api.getClient<T>();
  }

  get client() {
    const __client = async () => {
      return await this.Client;
    };
    return __client();
  }
}

const api = new API<AppClient>();

api.Client.then((client) => {
  client.interceptors.response.use(
    (config) => {
      // Success
      return config;
    },
    (e) => {
      // Error
      const mes = axiosErrorHandler(e);
      if (axios.isAxiosError(e) && e.response?.status === 401) {
        userSignout({});
      }
      return Promise.reject(e);
    }
  );
});

interface Props {
  redirectTrue?: boolean;
  viaButtonClick?: boolean;
  isRoparCoursePrefered?: boolean;
  isMandiV2CoursePrefered?: boolean;
}

export const userSignout = ({ redirectTrue = true, viaButtonClick, isRoparCoursePrefered, isMandiV2CoursePrefered }: Props) => {
  const masaiWebsiteUrl = `${window.location.protocol}//${process.env.REACT_APP_MASAI_WEBSITE_URL}`;

  // clear localstorage with key "hide_switch"  and isBenchmarkingTestCleared
  localStorage.removeItem("isBenchmarkingTestCleared");
  localStorage.removeItem("hide_switch");
  localStorage.removeItem("course_modal_closed");

  return fetch(`${window.location.protocol}//${authServer}/signout`, {
    credentials: "include",
    method: "POST",
    // mode: "no-cors",
  })
    .then(async (res) => {
      if (res.status === 200) {
        await res.json();
        if (viaButtonClick) {
          //redirect to masai website
          const roparWebsite = "https://iitrpr.ac.in/iit-ropar-ai";
          const mandiWebsite = "https://cce.iitmandi.ac.in/minor-in-cse";
          const redirectUrl = isRoparCoursePrefered ? roparWebsite : isMandiV2CoursePrefered ? mandiWebsite : masaiWebsiteUrl;
          window.location.replace(redirectUrl);
        } else {
          // window.location.replace(response.url);
          window.location.replace(`${window.location.protocol}//${ssoUrl}/?returnTo=` + window.location.href);
        }
      } else if (res.status === 302) {
        // clear browser history and redirect to sso
        window.location.replace(`${window.location.protocol}//${ssoUrl}/?returnTo=` + window.location.href);
      } else if (res.status === 500) {
        toast({
          title: "Error",
          description: "Internal Server Error",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Signing out!",
          status: "warning",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        setTimeout(() => {
          if (redirectTrue) {
            window.location.replace(`${window.location.protocol}//${ssoUrl}/?returnTo=` + window.location.href);
          } else {
            window.location.replace(`${window.location.protocol}//${ssoUrl}/`);
          }
        }, 0);
      }
      return res.json();
    })
    .catch(() => {
      //
    });
};

export default api;
