import { GetOnboardingProgress } from "../../../../api/schemas/schema";
import { Steps } from "./types";

export const getCurrentStep = (onboardingStatus: GetOnboardingProgress): Steps => {
  if (!onboardingStatus.launch_form) {
    return Steps.STEP_ONE;
  } else if (!onboardingStatus.learnworlds_completed) {
    return Steps.STEP_TWO;
  } else return Steps.STEP_THREE;
};
