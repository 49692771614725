/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Text } from "@chakra-ui/react";
import moment from "moment";
import { convertDays } from "./utils";
import { DateSlotCard } from "./DateSlotCard";
import { useEffect } from "react";

type Props = {
  allSlots: any; //DayTimeSlot[];
  selectedDaySlot?: any; //DayTimeSlot;
  setSelectedDaySlot: (value: any) => void;
};

export const DateSelection = ({ allSlots, selectedDaySlot, setSelectedDaySlot }: Props) => {
  useEffect(() => {
    if (allSlots && allSlots.length > 0) {
      const firstAvailableDay = allSlots.find((ds: any) => ds.slotTimes.length > 0);
      setSelectedDaySlot(firstAvailableDay);
    }
  }, []);

  return (
    <Box mb="0" w="full">
      <Text color="dark" fontWeight={500} fontSize="15px">
        Select a date
      </Text>
      <Box p="2" pl="0" overflowX="auto" maxW={{ base: "full", lg: "450px" }}>
        <Box display="flex" gap="4" py="2">
          {allSlots?.map((availability: any, ind: number) => {
            const isSelected = selectedDaySlot?.date === availability.date;
            return (
              <DateSlotCard
                key={ind}
                day={convertDays(availability.day)}
                date={moment(availability.date).format("DD MMM")}
                slotAvailable={availability.totalSlots}
                isSelected={isSelected}
                startTime={availability.date}
                setSelectedDaySlot={setSelectedDaySlot}
                availability={availability}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
