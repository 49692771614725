import dayjs from "dayjs";
import {
  IITMandiOnwardLeftNavigationOptions,
  IITMandiOnwardTabsLabelsList,
  IITOnwardLeftNavigationOptions,
  IITOnwardTabsLabelsList,
  IITRoparOnwardLeftNavigationOptions,
  IITRoparOnwardTabsLabelsList,
  masaiOnwardLeftNavigationOption,
  masaiOnwardTabsLabelsList,
  adminUserLeftNavigationOptions,
} from "../constants/left-navigation-option";
import { ProgramProfileType } from "../enums/ProgramType.enum";
import { AddressType } from "../types/onboarding.types";
import { IITEMIOptions, IITGuwahatiProfileDetailInputInterface } from "../constants/IIt-course/iit-form-onboarding";

export function isNewLineOrTabOr4ExtraSpacePresent(inputString: string) {
  // Check if the string contains a new line, tab, or four consecutive spaces
  const invalidPattern = /(\n|\t| {4,})/;
  // If the string matches the pattern, it's invalid
  return invalidPattern.test(inputString);
}

export function getOrdinal(number: number) {
  if (typeof number !== "number" || isNaN(number)) {
    return "Invalid input";
  }

  let suffix = "th";
  const lastDigit = number % 10;
  const secondToLastDigit = Math.floor((number % 100) / 10);

  if (lastDigit === 1 && secondToLastDigit !== 1) {
    suffix = "st";
  } else if (lastDigit === 2 && secondToLastDigit !== 1) {
    suffix = "nd";
  } else if (lastDigit === 3 && secondToLastDigit !== 1) {
    suffix = "rd";
  }

  return number + suffix;
}

export function getLeftNeavigationList(coursePreference: ProgramProfileType, isAdmin: boolean) {
  return isAdmin
    ? adminUserLeftNavigationOptions
    : coursePreference === ProgramProfileType.MASAI
    ? masaiOnwardLeftNavigationOption
    : coursePreference === ProgramProfileType.IIT_GUWAHATI
    ? IITOnwardLeftNavigationOptions
    : coursePreference === ProgramProfileType.IIT_ROPAR
    ? IITRoparOnwardLeftNavigationOptions
    : IITMandiOnwardLeftNavigationOptions;
}

export function getTabsList(coursePreference: ProgramProfileType) {
  return coursePreference === ProgramProfileType.MASAI
    ? masaiOnwardTabsLabelsList
    : coursePreference === ProgramProfileType.IIT_GUWAHATI
    ? IITOnwardTabsLabelsList
    : coursePreference === ProgramProfileType.IIT_ROPAR
    ? IITRoparOnwardTabsLabelsList
    : IITMandiOnwardTabsLabelsList;
}

export function getIITGuwahatiOnboardingDates(registrationDate: string, batchStartDate: string): { date: string; overdue: boolean }[] {
  const today = dayjs();
  const D = dayjs(registrationDate);
  const batchStart = dayjs(batchStartDate);

  const timelines: { date: string; overdue: boolean }[] = [
    // registrationFee:
    {
      date: D.format("DD MMM"),
      overdue: D.isBefore(today),
    },
    // orientationVideoTimeline:
    {
      date: D.add(2, "day").format("DD MMM"),
      overdue: D.add(2, "day").isBefore(today),
    },
    // trimesterFeeTimeline:
    {
      date: D.add(5, "day").format("DD MMM"),
      overdue: D.add(5, "day").isBefore(today),
    },
    // profileDetailTimeline:
    {
      date: D.add(7, "day").format("DD MMM"),
      overdue: D.add(7, "day").isBefore(today),
    },
    // benchmarkingTestTimeline:
    {
      date: (D.add(7, "day").isAfter(batchStart.subtract(1, "day")) ? D.add(7, "day") : batchStart.subtract(1, "day")).format("DD MMM"),
      overdue: (D.add(7, "day").isAfter(batchStart.subtract(1, "day")) ? D.add(7, "day") : batchStart.subtract(1, "day")).isBefore(today),
    },
  ];

  return timelines;
}

export const dayLeftFor1TrimesterFee = (registrationDate: string) => {
  const today = dayjs();
  const D = dayjs(registrationDate);

  return {
    date: D.add(5, "day"),
    overdue: D.add(5, "day").isBefore(today),
  };
};

export function convertUrlToCDNUrl(url: string): string {
  const urls = url.split("masai-website-images.s3.ap-south-1.amazonaws.com/");
  const fileName = urls[urls.length - 1];
  const CDN_URL = "https://cdn.masaischool.com/masai-website/" + fileName;
  return CDN_URL;
}

// check if any of the address field is empty or not filled
export const isAddressEmpty = (address: AddressType): boolean => {
  const keys = Object.keys(address).filter((el) => el === "apartment" || el === "street" || el === "city" || el === "state" || el === "pincode" || el === "passportSizePic") as (keyof AddressType)[];

  if (keys.length === 0) {
    return true;
  }
  const isAddressEmpty = keys.some((key) => {
    return !address[key];
  });
  return isAddressEmpty;
};

export const removeInvalidKeyValues = (profileDetailForm: IITGuwahatiProfileDetailInputInterface): IITGuwahatiProfileDetailInputInterface => {
  const cleanedForm: Partial<IITGuwahatiProfileDetailInputInterface> = {};

  Object.entries(profileDetailForm).forEach(([key, value]) => {
    if (value !== undefined && value !== null && value !== "") {
      cleanedForm[key as keyof IITGuwahatiProfileDetailInputInterface] = value;
    }
  });

  return cleanedForm as IITGuwahatiProfileDetailInputInterface;
};

export const removeInvalidKeyValueFromEMIForm = (emiOptionsData: IITEMIOptions): IITEMIOptions => {
  const cleanedForm: Partial<IITEMIOptions> = {};

  Object.entries(emiOptionsData).forEach(([key, value]) => {
    if (value !== undefined && value !== null && value !== "") {
      cleanedForm[key as keyof IITEMIOptions] = value;
    }
  });

  return cleanedForm as IITEMIOptions;
};

const isEmailFromDomain = (email: string) => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
};

export function isIITRoparEmailValid(email: string, domain = "iitrpr.ac.in") {
  if (isEmailFromDomain(email)) {
    const emailDomain = email.split("@").pop();
    return emailDomain === domain;
  }
  return false;
}

export const formatDateWithOrdinal = (date: string) => {
  const day = dayjs(date).format("D");
  const monthYear = dayjs(date).format("MMMM YYYY");
  const suffix = dayjs(date).format("Do").replace(day, "");
  return `${day}${suffix} ${monthYear}`;
};

export const scrollToElement = (scrollToHere: string | HTMLElement, headerOffset = 100, intervelTime = 100) => {
  const timer = setTimeout(() => {
    const element = typeof scrollToHere === "string" ? document.querySelector(scrollToHere) : scrollToHere;
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, intervelTime);
};
