import { useContext, useEffect } from "react";
import { Skeleton } from "@chakra-ui/react";
import { useBestAssessment } from "../../api/utils/hooks/useBestAssessment";
import { AppContext } from "../../context/ApplicationContext";
import { useHistory } from "react-router-dom";
import { ProgramProfileType } from "../../enums/ProgramType.enum";
import { useLastAssessment } from "../../api/utils/hooks/useAssessment";

function MSAT() {
  const { data: bestAssessment, isLoading: bestAssessmentLoading } = useBestAssessment();
  const { data: lastAssessment, isLoading: lastAssessmentLoading } = useLastAssessment();
  const state = useContext(AppContext)[0];
  const history = useHistory();

  const isMasaiCoursePrefered = state.coursePreference === ProgramProfileType.MASAI ? true : false;
  const isIITGCoursePrefered = state.coursePreference === ProgramProfileType.IIT_GUWAHATI ? true : false;
  const isIITMandiCoursePrefered = state.coursePreference === ProgramProfileType.IIT_MANDI ? true : false;

  const masaiWebsiteUrl = `${window.location.protocol}//${process.env.REACT_APP_MASAI_WEBSITE_URL}`;

  useEffect(() => {
    window.addEventListener("message", (e) => {
      const data = e.data;
      console.log("data", data);
      if (data.type === "takeEntranceTest") {
        if (isIITGCoursePrefered) {
          history.push("/mc-iitg-cs?startTest=true");
        } else if (isIITMandiCoursePrefered) {
          history.push("/iit-mandi-ai-ml?startTest=true");
        } else {
          window.location.href = `/?startMsat=true`;
        }
      }
    });
  }, []);

  if (bestAssessmentLoading || lastAssessmentLoading) {
    return <Skeleton w={"100%"} h={"100vh"} />;
  }

  return (
    <iframe
      src={`${masaiWebsiteUrl}/${
        isIITGCoursePrefered ? "mc-iitg-cs/admission-and-fees" : isMasaiCoursePrefered ? "msat" : "iit-mandi-ai-ml/admission-and-fees"
      }?hideFooter=true&hideNavbar=true&032572f9208635a253425c7290509f2d=true&a6a8938aca39901ca9f57c7b1fdaaa8d=${
        isMasaiCoursePrefered ? bestAssessment && bestAssessment.attempt_count : lastAssessment && lastAssessment.attempt_count
      }`}
      style={{ width: "100%", height: isMasaiCoursePrefered ? "100vh" : "93vh" }}
    ></iframe>
  );
}

export default MSAT;
