import { Box, Text, useBreakpointValue } from "@chakra-ui/react";
import Lottie from "react-lottie-player";
import successLottie from "../../../assets/successLottie.json";
import { Banner } from "../../../pages/Courses/Banner";
import { ProfileAssessment } from "../../../api/schemas/schema";

interface Props {
  isMasaiCoursePrefered?: boolean;
  bestAssessment?: ProfileAssessment;
}

const MSATBannerV2 = ({ isMasaiCoursePrefered, bestAssessment }: Props) => {
  const isTablet = useBreakpointValue({ base: true, xl: false });

  return (bestAssessment?.eligible_courses?.length && !isMasaiCoursePrefered) || (isMasaiCoursePrefered && bestAssessment?.eligible_courses?.includes(14)) ? (
    <>
      <Box
        position="absolute"
        isolation="isolate"
        top={{ base: "-70.829px", xl: "-80.829px" }}
        left={{ xl: "-80.829px" }}
        right={{ base: "-70.829px", xl: "0px" }}
        bg="#3470E4"
        h={{ base: "162px", xl: "209.829px" }}
        w={{ base: "162px", xl: "209.829px" }}
        rounded="100%"
        display="flex"
        justifyContent={{ xl: "end" }}
        alignItems="end"
        p="16px"
      >
        <Box bg="#0B2352" w="50%" h="50%" position="absolute" rounded="100%" top="24%" left="24%" opacity="0.2"></Box>

        <Lottie loop animationData={successLottie} play style={{ width: "100%", maxWidth: isTablet ? "68px" : "110px" }} />
      </Box>

      <Box maxW={{ base: "80%", xl: "100%" }} textAlign={{ base: "left", xl: "center" }} pt={{ base: "16px", xl: "51px" }} px={{ base: "12px" }}>
        <Text textStyle={{ base: "h6", md: "h3" }} color="#3470E4">
          {isMasaiCoursePrefered ? "MSAT Result" : "Entrance Test Result"}
        </Text>
        <Text mt="8px" textStyle={{ base: "body1", md: "h6" }}>
          {"Congratulations! You have cleared the MSAT!"}
        </Text>
      </Box>
    </>
  ) : (
    <Banner
      border="none"
      borderRadius="8px 8px 0px 0px"
      hideShareButton={true}
      text={<>{bestAssessment?.attempt_count === 1 ? `You could not clear the 1st attempt of ${"MSAT"}.` : `You could not clear the 2nd attempt of ${"MSAT"}.!`}</>}
      type="error"
      title={"MSAT Not Clear"}
    />
  );
};

export default MSATBannerV2;
