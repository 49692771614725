import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const SpellCheck = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M14.1 22L9.85 17.75L11.25 16.35L14.1 19.2L19.75 13.55L21.15 14.95L14.1 22ZM3 16L7.85 3H10.2L15.05 16H12.75L11.6 12.7H6.35L5.2 16H3ZM7.05 10.8H10.95L9.05 5.4H8.95L7.05 10.8Z"
      fill="currentColor"
    />
  </Icon>
);

export default SpellCheck;
