import React, { ReactElement } from "react";
import { EmptyStateImage } from "../../../assets/images";
import { Flex, Box, Heading, Image } from "@chakra-ui/react";
import { Constants } from "../constants";
interface Props {
  text: string;
  horizontalCenter?: boolean;
  verticalCenter?: boolean;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;
  height?: string;
  customIcon?: string;
}
const EmptyState = ({ text, horizontalCenter, verticalCenter, pt, pb, pl, pr, height, customIcon }: Props) => {
  return (
    <Flex
      h={height ? height : `calc(100vh - ${Constants.headerHeight})`}
      pt={pt}
      pb={pb}
      pl={pl}
      pr={pr}
    >
      <Box
        mx={horizontalCenter === false ? "" : "auto"}
        my={verticalCenter === false ? "" : "auto"}
      >
        <Image m={"auto"} src={customIcon || EmptyStateImage} alt="empty state image" />
        <Heading mt={"ms-32"} as="h4" color="ms-grey.600">
          {text}
        </Heading>
      </Box>
    </Flex>
  );
};

export default EmptyState;
