import { Badge, Box, Button, Text, Image, useBreakpointValue, keyframes, BoxProps } from "@chakra-ui/react";
import { GetEventType } from "../../../api/schemas/schema";
import { convertGetEventToActivity, Activity, getEventTypeText } from "../../activities/Activity";
import { useMemo, useRef } from "react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import { joinEvent, registerForEvent } from "../../../api/utils/api/v2/activity";
import useSnackBar from "../../common/general/SnackBar";
import { axiosErrorHandler } from "../../../api/utils/error";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import durationPlugin from "dayjs/plugin/duration";
import { useCurrentTime } from "../../activities/useCountDown";
import { useLocation } from "react-router-dom";
import { sendTrackingEvent } from "../../common/utils/gtm";
import { ClockOutline } from "../../../assets/icons/components";
import { PiCalendarBlank } from "react-icons/pi";
import { SlLocationPin } from "react-icons/sl";

dayjs.extend(relativeTime);
dayjs.extend(durationPlugin);

const ping = keyframes`
    75%, 100% {
      transform: scale(2);
      opacity: 0;
  }
`;

export function CountDown({ startTime, onFinish }: { startTime: string; onFinish: () => void }) {
  const intervalRef = useRef<NodeJS.Timeout>();
  const { currentTime } = useCurrentTime();
  const timeLeftText = useMemo(() => {
    if (currentTime === null) return "";
    const startTimeDayjs = dayjs(startTime);
    const diff = startTimeDayjs.diff(currentTime, "second");
    if (diff <= 0) {
      onFinish();
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
    const daySeconds = 24 * 60 * 60;
    const hourSeconds = 60 * 60;
    const timeLeftText =
      diff > daySeconds
        ? dayjs.duration(diff, "second").format("D[day] H[hr] m[min]")
        : diff > hourSeconds
        ? dayjs.duration(diff, "second").format("H[hr] m[min] s[sec]")
        : diff > 60
        ? dayjs.duration(diff, "second").format("m[min] s[sec]")
        : dayjs.duration(diff, "second").format("s[sec]");
    return timeLeftText;
  }, [startTime, currentTime]);
  return <>{timeLeftText}</>;
}

function DetailsCTA({ activity }: { activity: Activity }) {
  const location = useLocation();
  return (
    <Box display={"flex"} justifyContent={"space-between"} gap="8px" alignItems={"center"}>
      <Button
        as={Link}
        size="sm"
        colorScheme="blue"
        to={`/activities/${activity.id}/details`}
        textTransform={"uppercase"}
        onClick={() => {
          sendTrackingEvent({
            event: "d_webinar_details",
            eventLabel: location.pathname,
          });
        }}
      >
        View Details
      </Button>
      <Text fontWeight={600} fontSize="12px" color="#3EB278" display="flex" alignItems="center" gap="2px">
        <CheckCircleIcon /> Registered
      </Text>
    </Box>
  );
}

export function RegisteredDashboardCTA({ activity, isDashboard, customCta }: { activity: Activity; isDashboard?: boolean; customCta?: React.ReactElement }) {
  const { currentTime } = useCurrentTime();
  const timeNow = useMemo(() => (currentTime === null ? dayjs() : dayjs(currentTime)), [currentTime]);
  const hasEnded = useMemo(() => {
    const now = timeNow;
    const endTime = dayjs(activity.endTime);
    return now.isAfter(endTime);
  }, [activity.endTime, timeNow]);
  const isLive = useMemo(() => {
    const now = timeNow;
    const startTime = dayjs(activity.startTime);
    const endTime = dayjs(activity.endTime);
    return now.isAfter(startTime) && now.isBefore(endTime);
  }, [activity.startTime, activity.endTime, timeNow]);

  const { mutate: joinEventMutation, isLoading: isJoiningEvent } = useMutation(joinEvent, {
    onSuccess: () => {
      console.log("joined");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      console.log("e:", e);
    },
  });

  return isLive ? (
    <Button
      as="a"
      w="full"
      size="sm"
      variant="outline"
      colorScheme="blue"
      textTransform={"uppercase"}
      href={`/activities/${activity.id}/details/live`}
      onClick={() => {
        joinEventMutation({ event_id: activity.id });
      }}
    >
      Join now
    </Button>
  ) : isDashboard ? (
    <Button size="sm" variant="outline" w="full" as={Link} to={`/activities/${activity.id}/details`} colorScheme="blue">
      {"Starts in "}
      <CountDown
        startTime={activity.startTime}
        onFinish={() => {
          console.log("Time up");
        }}
      />
    </Button>
  ) : customCta ? (
    customCta
  ) : (
    <DetailsCTA activity={activity} />
  );
}

export function RegisterNowCTA({ activity, onRegister, isRegisteringForEventLoading }: { activity: Activity; onRegister: () => void; isRegisteringForEventLoading?: boolean }) {
  const snackbar = useSnackBar();
  const { mutate: foudnerWebinarMuatate, isLoading: isFounderWebinarLoading } = useMutation(registerForEvent, {
    onSuccess: () => {
      onRegister();
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });
  const handleRegisterNow = () => {
    foudnerWebinarMuatate({ event_id: activity.id });
  };

  return (
    <Button
      w="full"
      size="sm"
      isLoading={isFounderWebinarLoading || isRegisteringForEventLoading}
      onClick={() => {
        sendTrackingEvent({
          event: "d_webinar_register",
          eventLabel: location.pathname,
        });
        handleRegisterNow();
      }}
      variant="outline"
      colorScheme="blue"
      isFullWidth
      textTransform={"uppercase"}
    >
      Register Now
    </Button>
  );
}

function ActivityCardV2(props: {
  isRegisteringForEventLoading?: boolean;
  event: GetEventType;
  maxWidth?: BoxProps["maxW"];
  height?: BoxProps["h"];
  isDashboard?: boolean;
  isForIITMandiCounselling?: boolean;
  onRegister: () => void;
}) {
  const { currentTime } = useCurrentTime();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const activity = convertGetEventToActivity(props.event);
  const lowerCaseType = useMemo(() => getEventTypeText(activity.type), [activity.type]);
  const hasRegistered = activity.isRegistered;
  const timeNow = useMemo(() => (currentTime === null ? dayjs() : dayjs(currentTime)), [currentTime]);
  const isPast = useMemo(() => {
    return dayjs(activity.endTime).isBefore(timeNow);
  }, [activity.endTime, timeNow]);

  const isLive = useMemo(() => {
    const now = timeNow;
    const startTime = dayjs(activity.startTime);
    const endTime = dayjs(activity.endTime);
    return now.isAfter(startTime) && now.isBefore(endTime);
  }, [activity.startTime, activity.endTime, timeNow]);

  return (
    <>
      <Box
        w="full"
        h={props?.height ?? "min-content"}
        maxW={props?.maxWidth ? props?.maxWidth : "390px"}
        rounded={"12px 12px 8px 8px"}
        border="1px solid #E5E5E5"
        bg={"white"}
        p="0px"
        overflow={"hidden"}
        style={{
          backgroundColor: "white",
        }}
        display="flex"
        flexDir="column"
      >
        <Image display={{ md: "block", base: "none" }} style={{ width: "100%" }} src={activity.thumbnail} />
        <Image display={{ md: "none", base: "block" }} style={{ width: "100%" }} src={activity.mobileThumbnail} />
        <Box h="full" display={"flex"} flexDirection={"column"} p={3} gap={2}>
          <Box display="flex" alignItems="center" gap="3px">
            {isLive ? (
              <span>
                <Badge textTransform={"capitalize"} backgroundColor={"#EEFFF7"} color="#049402" textStyle="body2-md" h="32px" display="flex" alignItems="center" gap="4px" p={"4px 8px"}>
                  <Box as="span" h="16px" w="16px" maxH="16px" maxW="16px" display="flex" alignItems="center" justifyContent="center">
                    <Text as="span" position="relative" display="flex" h="2" w="2">
                      <Text as="span" position="absolute" display="inline-flex" h="full" w="full" rounded="full" animation={`${ping} 1s cubic-bezier(0, 0, 0.2, 1) infinite`} bg="ms-green.300" />
                      <Text as="span" position="relative" display="inline-flex" rounded="full" h="2" w="2" bg="ms-green.300" />
                    </Text>
                  </Box>
                  <span>Live</span>
                </Badge>
              </span>
            ) : null}
            {!props.isDashboard ? (
              <span>
                <Badge textTransform={"capitalize"} backgroundColor={"#F2F6FF"} color="#3470E4" textStyle="body2-md" h="32px" p={"4px 8px"}>
                  {lowerCaseType}
                </Badge>
              </span>
            ) : null}
          </Box>

          <Text textStyle="h4">
            {activity.title}
            {props.isDashboard
              ? false && (
                  <Text as="span" textColor={"blue.500"}>
                    {" "}
                    {lowerCaseType}
                  </Text>
                )
              : null}
          </Text>

          <Box h="full" display="flex" justifyContent="flex-end" flexDir="column">
            {/* {props.isDashboard && isMobile ? null : <ActivityTimeline activity={activity} />} */}
            <Box alignItems={{ base: "flex-start", md: "center" }} flexWrap={"wrap"} display="flex" gap={"3px"}>
              <Box display={"flex"} alignItems={"center"} gap={"3px"}>
                <PiCalendarBlank size={"20px"} color={"#B3B3B3"} />
                <Text textStyle="body2-md" color="#B3B3B3">
                  {dayjs(activity.startTime).format("DD MMM, YYYY [at] hh:mma")}
                </Text>
              </Box>

              <Box display={"flex"} alignItems={"center"} gap={"3px"}>
                <SlLocationPin color="#B3B3B3" size="18px" />
                <Text textStyle="body2-md" color="#B3B3B3">
                  {activity?.eventLocation}
                </Text>
              </Box>
            </Box>

            <Box display="flex" alignItems="center" mt="20px" flexWrap="wrap" gap="4px" justifyContent="space-between">
              <Box>
                {activity.videoUrl ? (
                  <>
                    <Button
                      w="full"
                      size="sm"
                      variant="outline"
                      colorScheme="blue"
                      as={Link}
                      to={`/activities/${activity.id}/details`}
                      textTransform={"uppercase"}
                      onClick={() => {
                        sendTrackingEvent({
                          event: "d_activities_recording",
                          eventLabel: location.pathname,
                        });
                      }}
                    >
                      Watch Recording
                    </Button>
                  </>
                ) : !hasRegistered ? (
                  <RegisterNowCTA
                    activity={activity}
                    isRegisteringForEventLoading={props?.isRegisteringForEventLoading}
                    onRegister={
                      props?.isForIITMandiCounselling
                        ? () => {
                            window.location.href = `/activities/${activity.id}/details`;
                          }
                        : props.onRegister
                    }
                  />
                ) : (
                  <RegisteredDashboardCTA activity={activity} isDashboard={props.isDashboard} />
                )}
              </Box>

              {activity.registrationCount < 10 || (isMobile && props.isDashboard) ? null : (
                <Box gap={1} alignItems={"center"} display={"flex"} justifyContent={"center"} textAlign={"center"} textStyle="body2" fontSize="10.816px">
                  <img style={{ display: "inline" }} src="/fire-icon-free.png" width={20} height={20} />
                  <span style={{ color: "#ED0331", fontWeight: 700 }}>{activity.registrationCount}</span>
                  {isPast ? " participated" : " already registered"}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ActivityCardV2;
