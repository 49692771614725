export type StatusTypes = "in-progress" | "completed" | "pending";

export interface StepsProgressProps {
  status: StatusTypes;
  stepState?: Steps;
  stepNumber: number;
  description: string;
  handleStepCick?: (stepNo: number, state: Steps) => void;
  active?: boolean;
  hideStepper?: boolean;
}

export enum Steps {
  STEP_ONE = 1,
  STEP_TWO,
  STEP_THREE,
  // * since there is no in-progress
  STEP_FOUR,
}

export type StepsInfomration = {
  description: string;
  status: StatusTypes;
  stepNo: number;
  stepState: Steps;
};

export interface CircleProgressProps extends StepsProgressProps {
  next: string;
  total: number;
  data: StepsInfomration[];
}
