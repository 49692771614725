import React, { Dispatch, useEffect } from "react";
import { getProfileDetails } from "../api/utils/api";
import { getProfile } from "../api/utils/api/v2/profile";
import { BreadCrumbsKeysType } from "../hooks/useBreadcrumb";
import { GetApplicationType, GetEventType, GetProfileType, GetProfileTypeV2, ProgramProfileType } from "../api/schemas/schema";

export interface ApplicationState {
  applicationData?: GetApplicationType | null;
  profileData?: GetProfileType | null;
  profileDataV2?: GetProfileTypeV2 | null;
  breadcrumb?: BreadCrumbsKeysType[] | null;
  fetchApplicationData?: boolean;
  fetchProfileData?: boolean;
  fetchProfileDataV2?: boolean;
  showGuidedTour?: boolean;
  isScholar?: boolean;
  showConsentModal?: boolean;
  founderWebinar?: GetEventType | null;
  hideLeftSidebar?: boolean;
  hideRightSidebar?: boolean;
  selectedCourse?: string;
  coursePreference?: ProgramProfileType | null;
  isSwitchingToOtherCourseLoading?: boolean;
  lastAssessment?: string | null;
  isSidebarLoading?: boolean;
  couponCode?: string;
  shouldBookForIITMandiCounsellingEvent?: boolean;
  shouldBookForIITGuwahatiCounsellingEvent?: boolean;
}
const initState: ApplicationState = {
  applicationData: null,
  profileData: null,
  profileDataV2: null,
  breadcrumb: null,
  fetchApplicationData: true,
  fetchProfileData: false,
  fetchProfileDataV2: false,
  showGuidedTour: false,
  isScholar: undefined,
  showConsentModal: false,
  founderWebinar: null,
  hideLeftSidebar: false,
  hideRightSidebar: false,
  coursePreference: null,
  isSwitchingToOtherCourseLoading: false,
  lastAssessment: null,
  isSidebarLoading: true,
  shouldBookForIITMandiCounsellingEvent: false,
  shouldBookForIITGuwahatiCounsellingEvent: false,
};

export enum ApplicationActionType {
  SET_BREADCRUMBS,
  SET_PROFILE,
  SET_PROFILE_V2,
  SET_APPLICATION,
  FETCH_APPLICATION,
  FETCH_PROFILE_V2,
  FETCH_PROFILE,
  SET_GUIDED_TOUR,
  SET_SCHOLAR_STATUS,
  SET_SHOW_CONSENT_MODAL,
  SET_FOUNDER_WEBINAR,
  SET_HIDE_LEFT_SIDEBAR,
  SET_HIDE_RIGHT_SIDEBAR,
  SET_SELECTED_COURSE,
  SET_COURSE_PREFERENCE,
  SET_IS_SWITCHING_SCREEN_LOADING,
  SET_LAST_ASSESSMENT,
  SET_IS_SIDEBAR_LOADING,
  SET_COUPON_CODE,
  SET_IITG_COUNSELLING_BOOK_EVENT,
  SET_IIT_MANDI_COUNSELLING_BOOK_EVENT,
  SET_HIRES_OF_THE_WEEK,
}
export interface AppContextActions {
  type: ApplicationActionType;
  payload: ApplicationState;
}

const reducer = (state: ApplicationState, action: AppContextActions) => {
  switch (action.type) {
    case ApplicationActionType.SET_BREADCRUMBS: {
      return {
        ...state,
        breadcrumb: action.payload.breadcrumb,
      };
    }
    case ApplicationActionType.SET_PROFILE: {
      return {
        ...state,
        profileData: action.payload.profileData,
        fetchProfileData: false,
      };
    }
    case ApplicationActionType.SET_PROFILE_V2: {
      return {
        ...state,
        profileDataV2: action.payload.profileDataV2,
        fetchProfileDataV2: false,
      };
    }
    case ApplicationActionType.SET_APPLICATION: {
      return {
        ...state,
        applicationData: action.payload.applicationData,
        fetchApplicationData: false,
      };
    }
    case ApplicationActionType.FETCH_APPLICATION: {
      return {
        ...state,
        fetchApplicationData: true,
      };
    }
    case ApplicationActionType.FETCH_PROFILE: {
      return {
        ...state,
        fetchProfileData: true,
      };
    }
    case ApplicationActionType.FETCH_PROFILE_V2: {
      return {
        ...state,
        fetchProfileDataV2: true,
      };
    }
    case ApplicationActionType.SET_GUIDED_TOUR: {
      return {
        ...state,
        showGuidedTour: action.payload.showGuidedTour,
      };
    }
    case ApplicationActionType.SET_SCHOLAR_STATUS: {
      return {
        ...state,
        isScholar: action.payload.isScholar,
      };
    }
    case ApplicationActionType.SET_SHOW_CONSENT_MODAL: {
      return {
        ...state,
        showConsentModal: action.payload.showConsentModal,
      };
    }
    //set founderWebinar
    case ApplicationActionType.SET_FOUNDER_WEBINAR: {
      return {
        ...state,
        founderWebinar: action.payload.founderWebinar,
      };
    }
    case ApplicationActionType.SET_HIDE_LEFT_SIDEBAR: {
      return {
        ...state,
        hideLeftSidebar: action.payload.hideLeftSidebar,
      };
    }
    case ApplicationActionType.SET_HIDE_RIGHT_SIDEBAR: {
      return {
        ...state,
        hideRightSidebar: action.payload.hideRightSidebar,
      };
    }
    case ApplicationActionType.SET_SELECTED_COURSE: {
      return {
        ...state,
        selectedCourse: action.payload.selectedCourse,
      };
    }
    case ApplicationActionType.SET_COURSE_PREFERENCE: {
      return {
        ...state,
        coursePreference: action.payload.coursePreference,
      };
    }
    case ApplicationActionType.SET_IS_SWITCHING_SCREEN_LOADING: {
      return {
        ...state,
        isSwitchingToOtherCourseLoading: action.payload.isSwitchingToOtherCourseLoading,
      };
    }
    case ApplicationActionType.SET_LAST_ASSESSMENT: {
      return {
        ...state,
        lastAssessment: action.payload.lastAssessment,
      };
    }
    case ApplicationActionType.SET_IS_SIDEBAR_LOADING: {
      return {
        ...state,
        isSidebarLoading: action.payload.isSidebarLoading,
      };
    }
    case ApplicationActionType.SET_COUPON_CODE: {
      return {
        ...state,
        couponCode: action.payload.couponCode,
      };
    }
    case ApplicationActionType.SET_IITG_COUNSELLING_BOOK_EVENT: {
      return {
        ...state,
        shouldBookForIITGuwahatiCounsellingEvent: action.payload.shouldBookForIITGuwahatiCounsellingEvent,
      };
    }
    case ApplicationActionType.SET_IIT_MANDI_COUNSELLING_BOOK_EVENT: {
      return {
        ...state,
        shouldBookForIITMandiCounsellingEvent: action.payload.shouldBookForIITMandiCounsellingEvent,
      };
    }

    default:
      return state;
  }
};

export const AppContext = React.createContext<[ApplicationState, Dispatch<AppContextActions>]>([initState, () => null]);

interface Props {
  children: JSX.Element | JSX.Element[];
}

const ApplicationContextProvider = (props: Props) => {
  const [state, dispatch] = React.useReducer(reducer, initState);

  const fetchProfile = React.useCallback(() => {
    getProfileDetails()
      .then((res) => {
        dispatch({
          type: ApplicationActionType.SET_PROFILE,
          payload: {
            profileData: res,
          },
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  const fetchProfileV2 = React.useCallback(() => {
    getProfile()
      .then((res) => {
        dispatch({
          type: ApplicationActionType.SET_PROFILE_V2,
          payload: {
            profileDataV2: res,
          },
        });
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  useEffect(() => {
    fetchProfile();
    fetchProfileV2();
    dispatch({ type: ApplicationActionType.SET_COUPON_CODE, payload: { couponCode: undefined } });
  }, [state.selectedCourse]);

  useEffect(() => {
    if (state.fetchProfileDataV2) {
      fetchProfileV2();
    }
  }, [state.fetchProfileDataV2]);

  return <AppContext.Provider value={[state, dispatch]}>{props.children}</AppContext.Provider>;
};

export default ApplicationContextProvider;
