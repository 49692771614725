import { useQuery } from "react-query";
import { GetCounsellingRequestStatusType } from "../../schemas/schema";

import { getAllCounsellingSessionStatus, getCounsellingSessionStatus } from "../api/v2/activity";

export function useCounsellingStatus(source: string) {
  return useQuery({
    queryKey: "getCounsellingSessionStatus",
    queryFn: async () =>
      (await getCounsellingSessionStatus({ source })) as GetCounsellingRequestStatusType,
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,
    refetchOnWindowFocus: false,
    enabled: true,
  });
}

export function useAllCounsellingStatus() {
  return useQuery({
    queryKey: "getAllCounsellingSessionStatus",
    queryFn: async () =>
      (await getAllCounsellingSessionStatus()) as GetCounsellingRequestStatusType[],
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
