import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { MasaiXStageType } from "./MasaiXInterviewPending";

interface Props {
  masaiXStagesText: string[];
  currentStage: MasaiXStageType;
}
const CurrentStageModalMobile = ({ masaiXStagesText, currentStage }: Props) => {
  return (
    <Box>
      {masaiXStagesText.map((stage, i) => (
        <Box key={stage} d={"flex"}>
          <Box w={"fit-content"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Box
              width={"fit-content"}
              px={"ms-12"}
              py={"ms-6"}
              borderRadius={"50%"}
              bgColor={"ms-cyan.500"}
            >
              <Text
                width={"fit-content"}
                fontSize={"18px"}
                color={"#fff"}
                fontWeight={"600"}
                lineHeight={"ms-24"}
              >
                {i + 1}
              </Text>
            </Box>
            {/* line */}
            <Box
              display={i === masaiXStagesText.length - 1 ? "none" : "block"}
              h={"12px"}
              border="1px solid #3470E4"
              w={"0px"}
            ></Box>
          </Box>
          <Text ml={"ms-8"} mt={"ms-4"} textStyle={"body2-md"} color={"ms-red.900"}>
            {stage}
          </Text>
        </Box>
      ))}
    </Box>
  );
};

export default CurrentStageModalMobile;
