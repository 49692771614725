import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const Php = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10.5" fill="#8892BF" />
      <path
        d="M10.8294 7.5H12.0894L11.7333 9.39316H12.8655C13.4864 9.40644 13.949 9.54521 14.2534 9.80946C14.5638 10.0737 14.6551 10.576 14.5273 11.3161L13.9156 14.6167H12.6373L13.2216 11.4647C13.2824 11.1343 13.2642 10.8998 13.1668 10.761C13.0695 10.6222 12.8595 10.5529 12.5368 10.5529L11.5233 10.5429L10.7746 14.6167H9.51457L10.8294 7.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.05569 9.3932H7.50271C8.22094 9.39975 8.74139 9.62454 9.06406 10.0672C9.38674 10.5099 9.4932 11.1145 9.38364 11.8811C9.34108 12.2314 9.24668 12.5749 9.10058 12.9119C8.96052 13.2489 8.76585 13.5528 8.51622 13.8238C8.21181 14.1673 7.8862 14.3854 7.53924 14.478C7.19227 14.5706 6.83307 14.6167 6.46182 14.6167H5.36613L5.01917 16.5H3.75L5.05569 9.3932ZM5.57614 13.4967L6.12215 10.5331H6.30659C6.37361 10.5331 6.44355 10.5297 6.5166 10.5232C7.00363 10.5166 7.40831 10.5662 7.73098 10.6718C8.05969 10.7775 8.16925 11.1773 8.05969 11.8712C7.92583 12.6972 7.66396 13.1795 7.27445 13.3183C6.88493 13.4505 6.3979 13.5132 5.81354 13.5066H5.68571C5.64918 13.5066 5.61266 13.5033 5.57614 13.4967Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3274 9.3932H15.8804L14.5747 16.5H15.8439L16.1908 14.6167H17.2865C17.6578 14.6167 18.017 14.5706 18.3639 14.478C18.7109 14.3854 19.0365 14.1673 19.3409 13.8238C19.5905 13.5528 19.7852 13.2489 19.9253 12.9119C20.0714 12.5749 20.1658 12.2314 20.2083 11.8811C20.3179 11.1145 20.2114 10.5099 19.8888 10.0672C19.5661 9.62454 19.0456 9.39975 18.3274 9.3932ZM16.9468 10.5331L16.4008 13.4967C16.4374 13.5033 16.4739 13.5066 16.5104 13.5066H16.6382C17.2226 13.5132 17.7096 13.4505 18.0991 13.3183C18.4887 13.1795 18.7505 12.6972 18.8844 11.8712C18.9939 11.1773 18.8844 10.7775 18.5557 10.6718C18.233 10.5662 17.8283 10.5166 17.3413 10.5232C17.2682 10.5297 17.1983 10.5331 17.1313 10.5331H16.9468Z"
        fill="white"
      />
    </svg>
  </Icon>
);

export default Php;
