import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { dayLeftFor1TrimesterFee } from "../../../../utils/utils";

interface CountdownTimerProps {
  endDateForPayment?: { date: string; overdue: boolean };
  registerationPaymentDate?: string;
  timeLeft: {
    days: number;
    hours: number;
    minutes: number;
  };
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ registerationPaymentDate, endDateForPayment, timeLeft }) => {
  return (
    <Box display={"flex"} justifyContent={"center"} flexDir={"column"}>
      <Text textStyle={"h5"} display={"inline"} gap={"4px"}>
        Complete fee payment by{" "}
        <Text textColor={"#3470E4"} fontStyle={"italic"} display={"inline"}>
          {dayLeftFor1TrimesterFee(registerationPaymentDate || "").date.format("DD MMM YYYY")}
        </Text>
      </Text>
      {!endDateForPayment?.overdue ? (
        <Box
          // base: "none",
          display={{ md: "flex" }}
          bg={"#D6E2FA"}
          mt={2}
          rounded={"16px"}
          flexDir={"column"}
          gap={1}
          w={"max-content"}
        >
          <Box rounded={"4px"} py={"4px"} px={"10px"} fontSize={"13.3px"} color={"#3470E4"} textAlign={"center"}>
            {timeLeft.days} days : {timeLeft.hours} hours : {timeLeft.minutes} mins
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default CountdownTimer;
