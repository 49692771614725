import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const YouTube = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1300_2289)">
        <path
          d="M23.522 6.18547C23.3863 5.67489 23.1189 5.20889 22.7465 4.83413C22.3741 4.45936 21.9099 4.18897 21.4002 4.05002C19.5238 3.54547 12.0238 3.54547 12.0238 3.54547C12.0238 3.54547 4.5238 3.54547 2.64744 4.05002C2.13773 4.18897 1.67346 4.45936 1.30109 4.83413C0.92872 5.20889 0.661309 5.67489 0.525622 6.18547C0.0238038 8.07002 0.0238037 12 0.0238037 12C0.0238037 12 0.0238038 15.93 0.525622 17.8146C0.661309 18.3251 0.92872 18.7911 1.30109 19.1659C1.67346 19.5407 2.13773 19.8111 2.64744 19.95C4.5238 20.4546 12.0238 20.4546 12.0238 20.4546C12.0238 20.4546 19.5238 20.4546 21.4002 19.95C21.9099 19.8111 22.3741 19.5407 22.7465 19.1659C23.1189 18.7911 23.3863 18.3251 23.522 17.8146C24.0238 15.93 24.0238 12 24.0238 12C24.0238 12 24.0238 8.07002 23.522 6.18547Z"
          fill="#FF0302"
        />
        <path d="M9.56934 15.5687V8.4314L15.8421 12L9.56934 15.5687Z" fill="#FEFEFE" />
      </g>
      <defs>
        <clipPath id="clip0_1300_2289">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Icon>
);

export default YouTube;
