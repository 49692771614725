import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { PaymentStatusType } from "../../../../api/schemas/schema";
import { Banner } from "../../Banner";
interface Props {
  registrationPaymentStatus?: PaymentStatusType;
}
const MasaiOnePaymentBanner = ({ registrationPaymentStatus }: Props) => {
  const getBanner = () => {
    if (registrationPaymentStatus === "FAILED") {
      return (
        <Banner
          textZIndex={0}
          minHeight="auto"
          type="error"
          title="Payment Failed!"
          text={
            <Text textStyle="body1">
              Payment failed, retry the payment to take the Masai School Admission Test (MSAT). If
              amount has been <br /> debited, it will be refunded in 4 to 5 business days.
            </Text>
          }
          hideShareButton={true}
          customWidth={["80%", "80%"]}
        />
      );
    } else if (registrationPaymentStatus === "PENDING") {
      return (
        <Banner
          textZIndex={0}
          minHeight="auto"
          type="pending"
          title="Payment in Progress!"
          text={
            <Text textStyle="body1">
              Please wait while we process your payment. This may take a few moments. Do not refresh
              or close the page <br />
              until the payment process is complete.
            </Text>
          }
          hideShareButton={true}
          customWidth={["80%", "80%"]}
        />
      );
    } else {
      return (
        <Banner
          textZIndex={0}
          minHeight="auto"
          type="success"
          title="Congratulations! You're Eligible for this Course"
          text={
            <Text textStyle="body1">
              We are thrilled to inform you that you have met the eligibility criteria for our
              course. The next step would be <br /> to clear the
              <Text textStyle="body1-md" d="inline">
                {" "}
                Masai School Admission Test (MSAT).
              </Text>
            </Text>
          }
          hideShareButton={true}
          customWidth={["80%", "80%"]}
        />
      );
    }
  };
  return <Box>{getBanner()}</Box>;
};

export default MasaiOnePaymentBanner;
