import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}

export const CameraIcon = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M11.9995 17.5C13.2495 17.5 14.3122 17.0627 15.1875 16.188C16.0622 15.3127 16.4995 14.25 16.4995 13C16.4995 11.75 16.0622 10.6873 15.1875 9.812C14.3122 8.93733 13.2495 8.5 11.9995 8.5C10.7495 8.5 9.68685 8.93733 8.81151 9.812C7.93685 10.6873 7.49951 11.75 7.49951 13C7.49951 14.25 7.93685 15.3127 8.81151 16.188C9.68685 17.0627 10.7495 17.5 11.9995 17.5ZM11.9995 15.5C11.2995 15.5 10.7078 15.2583 10.2245 14.775C9.74118 14.2917 9.49951 13.7 9.49951 13C9.49951 12.3 9.74118 11.7083 10.2245 11.225C10.7078 10.7417 11.2995 10.5 11.9995 10.5C12.6995 10.5 13.2912 10.7417 13.7745 11.225C14.2578 11.7083 14.4995 12.3 14.4995 13C14.4995 13.7 14.2578 14.2917 13.7745 14.775C13.2912 15.2583 12.6995 15.5 11.9995 15.5ZM3.99951 21C3.44951 21 2.97885 20.8043 2.58751 20.413C2.19551 20.021 1.99951 19.55 1.99951 19V7C1.99951 6.45 2.19551 5.97933 2.58751 5.588C2.97885 5.196 3.44951 5 3.99951 5H7.14951L8.99951 3H14.9995L16.8495 5H19.9995C20.5495 5 21.0205 5.196 21.4125 5.588C21.8038 5.97933 21.9995 6.45 21.9995 7V19C21.9995 19.55 21.8038 20.021 21.4125 20.413C21.0205 20.8043 20.5495 21 19.9995 21H3.99951ZM19.9995 19V7H15.9495L14.1245 5H9.87451L8.04951 7H3.99951V19H19.9995Z"
      fill="currentColor"
    />
  </Icon>
);

export default CameraIcon;
