import { Box, Text, Image, Button } from "@chakra-ui/react";
import React from "react";
import { useMutation } from "react-query";
import { completeOnboarding, syncAadhaarOCR } from "../../../api/utils/api/v2/application";
import { axiosErrorHandler } from "../../../api/utils/error";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import { useActiveApplication } from "../../../api/utils/hooks/useApplication";
import useSnackBar from "../../common/general/SnackBar";

interface Props {
  s3FrontImageUrl?: string;
  aadhaarErrorMessage?: string;
  setAadhaarMismatchModalContent?: (value: boolean) => void;
  isMismatch?: boolean;
  setAadhaarIneligibleModalContent?: (value: boolean) => void;
  setCurrentOnboardingStep?: React.Dispatch<React.SetStateAction<number>>;
  setAadhaarErrorMessage?: React.Dispatch<React.SetStateAction<string>>;
  isIITGuwahatiDashboard?: boolean;
  setOnboardingCompleteModalOpen?: (value: boolean) => void;
  setIsAdmitCardModalOpen?: (value: boolean) => void;
}

const AadhaarIneligibleModalContent = ({
  setAadhaarMismatchModalContent,
  setAadhaarIneligibleModalContent,
  setCurrentOnboardingStep,
  s3FrontImageUrl,
  aadhaarErrorMessage,
  isMismatch,
  setAadhaarErrorMessage,
  isIITGuwahatiDashboard,
  setOnboardingCompleteModalOpen,
  setIsAdmitCardModalOpen,
}: Props) => {
  const { refetch: refetchProfile } = useProfile();
  const { refetch: refetchActiveApplication } = useActiveApplication();

  const { mutate: completeOnboardingMutate } = useMutation(completeOnboarding, {
    onSuccess: () => {
      setOnboardingCompleteModalOpen && setOnboardingCompleteModalOpen(true);
      setIsAdmitCardModalOpen && setIsAdmitCardModalOpen(true);
      setCurrentOnboardingStep && setCurrentOnboardingStep(isIITGuwahatiDashboard ? 5 : 3);
      refetchActiveApplication();
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: syncAadhaarOCRMutate, isLoading: syncAadhaarOCRLoading } = useMutation(
    syncAadhaarOCR,
    {
      onSuccess: () => {
        refetchActiveApplication();
        refetchProfile();
        if (!isIITGuwahatiDashboard) {
          setCurrentOnboardingStep && setCurrentOnboardingStep(3);
          setAadhaarMismatchModalContent && setAadhaarMismatchModalContent(false);
        }
        if (isIITGuwahatiDashboard && isMismatch) {
          completeOnboardingMutate();
          setAadhaarMismatchModalContent && setAadhaarMismatchModalContent(false);
        }
        if (isIITGuwahatiDashboard && !isMismatch) {
          setAadhaarIneligibleModalContent && setAadhaarIneligibleModalContent(false);
        }
      },
      onError: (error) => {
        const e = axiosErrorHandler(error);
        if (typeof e === "object" && "message" in e) {
          setAadhaarIneligibleModalContent && setAadhaarIneligibleModalContent(true);
          setAadhaarErrorMessage && setAadhaarErrorMessage(e.message);
        } else {
          snackbar.error("Something went wrong");
        }
      },
    }
  );
  const snackbar = useSnackBar();
  return (
    <Box mt="32px">
      {isMismatch ? (
        <Text textAlign={"center"} textStyle="body1-md">
          We have found that your Aadhaar details do not match with the details you have shared.
        </Text>
      ) : (
        <Text textAlign={"center"} textStyle="body1-md">
          {aadhaarErrorMessage}
        </Text>
      )}
      <Box mt="24px">
        <Image
          borderRadius={"4px"}
          mx="auto"
          maxH="300px"
          src={s3FrontImageUrl}
          alt="aadhaar front"
        />
      </Box>

      {isMismatch ? (
        <Box borderTop="1px solid #D0D0D0" mt="16px" mx="-24px">
          <Text color="#000" textStyle={"body2"} textAlign={"center"} mt="16px">
            Would you like to continue with your Aadhaar details?
          </Text>
          <Box mt="16px" display={"flex"} justifyContent={"center"} gap="16px">
            <Button
              bgColor="#fff"
              onClick={() => {
                setAadhaarMismatchModalContent && setAadhaarMismatchModalContent(false);
              }}
              variant="secondary"
              border="1px solid #CCCCCC"
              color="#544D4F"
            >
              Reupload
            </Button>
            <Button
              isLoading={syncAadhaarOCRLoading}
              onClick={() => {
                syncAadhaarOCRMutate();
              }}
              variant="primary"
            >
              Continue
            </Button>
          </Box>
        </Box>
      ) : (
        <Button
          onClick={() =>
            setAadhaarIneligibleModalContent && setAadhaarIneligibleModalContent(false)
          }
          mt="16px"
          display={"flex"}
          mx="auto"
          variant="primary"
        >
          Reupload
        </Button>
      )}
    </Box>
  );
};

export default AadhaarIneligibleModalContent;
