import RegisterationPaymentCompleteBanner from "./RegisterationPaymentCompleteBanner";
import CustomAccordianVideo from "./CustomAccordianVideo";
import { Box, Button, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";
import { IITGuwahatiOrientationVideoList, IITMandiOrientationVideoList, IITRoparOrientationVideoList } from "../Dashboard/constant";

interface Props {
  isIITRopar?: boolean;
  isIITMandi?: boolean;
  handleSubmit?: () => void;
  updateProfileLoading?: boolean;
  slug?: string;
  slackLink?: string;
  linkedInContent?: string;
  setHasChoosenFoundationClassess?: (value: { programmingFoundationClass?: string; englishFoundationClass?: string }) => void;
  hasChoosenFoundationClassess?: { programmingFoundationClass?: string; englishFoundationClass?: string };
}

const OrientationStep = ({ isIITRopar = false, isIITMandi, updateProfileLoading, slug, slackLink, linkedInContent, hasChoosenFoundationClassess, setHasChoosenFoundationClassess }: Props) => {
  const videoList = isIITRopar ? IITRoparOrientationVideoList : isIITMandi ? IITMandiOrientationVideoList : IITGuwahatiOrientationVideoList;
  const handleJoinSalck = (joinSlack: boolean, joinLinkedIn: boolean) => {
    if (joinSlack) window.open(slackLink ? slackLink : "https://join.slack.com/t/iitmcse241001/shared_invite/zt-2p962mrdt-JywhgrRt1WFH87HfjO5N1g", "_blank");
    if (joinLinkedIn) {
      let linkedInContentPrased = `Exciting%20News%21%0AI%E2%80%99m%20excited%20to%20share%20that%20I%E2%80%99ve%20been%20accepted%20into%20the%20Minor%20in%20Computer%20Science%20%26%20Engineering%20program%20at%20IIT%20Mandi%21%0AI%20can%E2%80%99t%20wait%20to%20learn%20from%20the%20best%20IIT%20professors%2C%20work%20on%20hands-on%20projects%2C%20and%20enhance%20my%20skills%20in%20programming%2C%20system%20design%2C%20and%20much%20more.%20This%20is%20a%20fantastic%20opportunity%20to%20grow%20and%20make%20a%20mark%20in%20the%20tech%20world.%20Ready%20to%20dive%20in%21%0A.%0A.%0A%23IITMandi%20%23TechEducation%20%23ComputerScience%20%23NewBeginnings%0Ahttps%3A%2F%2Fmasaischool.com%2Fminor-in-cse`;

      if (linkedInContent && linkedInContent.length > 0) linkedInContentPrased = linkedInContent;

      let linkedInUrl = `https://www.linkedin.com/shareArticle/?mini=true&url=https://masaischool.com/minor-in-cse&text=${linkedInContentPrased}`;
      if (slug) {
        linkedInUrl = `https://www.linkedin.com/shareArticle/?mini=true&url=https://certificates.masaischool.com/admissions/${slug}/iit-kit&title=YourTitle&summary=YourSummary&source=YourSource&text=${linkedInContentPrased}`;
      }
      window.open(linkedInUrl, "_blank");
    }
  };

  return (
    <Box display={"flex"} flexDir={"column"} gap={4}>
      {isIITMandi ? (
        <Box>
          <Text>
            Joining Slack is mandatory because it serves as the primary platform for all program-related communication, ensuring students receive real-time updates, announcements, and support from IIT
            Mandi Professors and peers. This fosters collaboration and helps students stay connected throughout the course.
          </Text>
          <Button variant="primary" size="md" mr={4} mt={2} onClick={() => handleJoinSalck(true, false)} isLoading={updateProfileLoading}>
            Join Slack
          </Button>
          <Text mt="2">
            Sharing your enrollment on LinkedIn helps build your professional network by showcasing your commitment to advancing your tech skills with a prestigious program. It also increases your
            visibility to potential employers and peers in the industry.
          </Text>
          <Button variant="primary" size="md" mr={4} mt={2} onClick={() => handleJoinSalck(false, true)} isLoading={updateProfileLoading}>
            Share on LinkedIn
          </Button>
        </Box>
      ) : (
        <>
          <RegisterationPaymentCompleteBanner isIITRopar={isIITRopar} isIITMandi={!!isIITMandi} />
          {videoList.map((item, index) => (
            <CustomAccordianVideo item={item} key={index} />
          ))}{" "}
          {isIITRopar && (
            <Box display={"flex"} flexDir={"column"} gap={2} mt={"8"}>
              <Text textStyle={"body2-md"} color={"black"}>
                1. I want to enroll for the Programming Foundation Classess starting this Sunday.
              </Text>
              <RadioGroup
                color={"blackAlpha.900"}
                value={hasChoosenFoundationClassess?.programmingFoundationClass}
                colorScheme="blue"
                onChange={(data) => {
                  if (data) {
                    setHasChoosenFoundationClassess &&
                      setHasChoosenFoundationClassess({
                        ...hasChoosenFoundationClassess,
                        programmingFoundationClass: data,
                      });
                  }
                }}
              >
                <Stack spacing={8} direction="row">
                  <Radio value="Yes">
                    <Box display={"flex"} flexDirection={"row"} gap="4" padding={"10px"}>
                      <Box>
                        <Text fontSize={"16px"}>Yes</Text>
                      </Box>
                    </Box>
                  </Radio>
                  <Radio value="No">
                    <Box display={"flex"} flexDirection={"row"} gap="4" padding={"10px"}>
                      <Box>
                        <Text fontSize={"16px"}>No</Text>
                      </Box>
                    </Box>
                  </Radio>
                </Stack>
              </RadioGroup>

              <Text textStyle={"body2-md"} color={"black"}>
                2. I want to enroll for english Foundation Classess starting this Sunday.
              </Text>
              <RadioGroup
                color={"blackAlpha.900"}
                value={hasChoosenFoundationClassess?.englishFoundationClass}
                colorScheme="blue"
                onChange={(data) => {
                  if (data) {
                    setHasChoosenFoundationClassess &&
                      setHasChoosenFoundationClassess({
                        ...hasChoosenFoundationClassess,
                        englishFoundationClass: data,
                      });
                  }
                }}
              >
                <Stack spacing={8} direction="row">
                  <Radio value="Yes">
                    <Box display={"flex"} flexDirection={"row"} gap="4" padding={"10px"}>
                      <Box>
                        <Text fontSize={"16px"}>Yes</Text>
                      </Box>
                    </Box>
                  </Radio>
                  <Radio value="No">
                    <Box display={"flex"} flexDirection={"row"} gap="4" padding={"10px"}>
                      <Box>
                        <Text fontSize={"16px"}>No</Text>
                      </Box>
                    </Box>
                  </Radio>
                </Stack>
              </RadioGroup>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default OrientationStep;
