import { Box, Heading } from "@chakra-ui/react";
import React from "react";
interface Props {
  stepData: {
    id: number;
    text1: React.ReactNode;
    text2: React.ReactNode;
  };
}
const StepsCard = ({ stepData }: Props) => {
  const { id, text1, text2 } = stepData;

  return (
    <Box border="1px solid #D9D9D9" borderRadius={"16px"} p={"8px"} display={"flex"}>
      <Box
        px={{ base: "8px", md: "14px" }}
        py={{ base: "30px", md: "42px" }}
        borderRadius={"16px"}
        bgColor={"#F2F6FF"}
        mr={{ base: "8px", md: "24px" }}
        minW={{ base: "84px", md: "94px" }}
        minH={{ base: "88px", md: "112px" }}
      >
        <Heading textAlign={"center"} color={"ms-grey.800"} as="h4">
          Step {id}
        </Heading>
      </Box>
      <Box my={"auto"} w={"100%"}>
        <Box>
          {text1}
          <Box borderBottom="1px solid #D9D9D9" my={{ base: "8px", md: "12px" }}></Box>
          {text2}
        </Box>
      </Box>
    </Box>
  );
};

export default StepsCard;
