import axios from "axios";
import { useState } from "react";
import { Box, Image, Text, Link } from "@chakra-ui/react";
import { GetS3PresignedUrlType } from "../../api/schemas/schema";
import CustomUploadFileInput from "../V2/IITGOnboarding/CustomUploadFileInput";
import { IITGuwahatiProfileDetailInputInterface, NewAadhaarPANCardUploadedDataType } from "../../constants/IIt-course/iit-form-onboarding";

interface Props {
  panCardUploadedData?: NewAadhaarPANCardUploadedDataType;
  setPanCardUploadedData?: (value: NewAadhaarPANCardUploadedDataType) => void;
  isIITGOnbaording?: boolean;
  profileDetailForm?: IITGuwahatiProfileDetailInputInterface;
  setProfileDetailForm?: (data: IITGuwahatiProfileDetailInputInterface) => void;
}

const PANOcr = ({ panCardUploadedData, setPanCardUploadedData, isIITGOnbaording, profileDetailForm, setProfileDetailForm }: Props) => {
  const [panCardBack, setPanCardBack] = useState<File>();
  const [panCardFront, setPanCardFront] = useState<File>();

  const handleOnSuccess = async (presignedUrl: string, imageFile: File): Promise<string> => {
    const imageData = imageFile;
    const fileType = imageData as File;
    try {
      await axios.put(presignedUrl, imageData, {
        headers: {
          "Content-Type": fileType.type || "image/png",
        },
      });
      const imageUrl = presignedUrl.split("?")[0];
      return imageUrl;
    } catch (error) {
      console.error("Error uploading image:", error);
      return "";
    }
  };

  return (
    <Box display={"flex"} flexDir={{ base: "column", md: "row" }} gap={{ base: "14px", md: "64px" }}>
      {/* Upload front Pan Card */}
      <Box>
        {/* Header Text */}
        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <Box display={"flex"} alignItems={"center"}>
            <Text display={"inline"} mr={"ms-4"} mb={"ms-8"} textStyle={"body2-md"}>
              Upload the front side of your PAN for verification *
            </Text>
          </Box>
          {profileDetailForm && profileDetailForm?.panCardFront && (
            <Text
              display={"inline"}
              textStyle={"body2"}
              color={"ms-blue.500"}
              cursor={"pointer"}
              _hover={{ textDecoration: "underline" }}
              onClick={() => {
                window.open(profileDetailForm?.panCardFront, "_blank");
              }}
            >
              Preview
            </Text>
          )}
        </Box>

        {/* Drag and drop Flow */}
        <CustomUploadFileInput
          disabled={false}
          toastMessage="PAN Card Front Image has been uploaded"
          fileUploadData={panCardFront as File}
          setFileUploadData={setPanCardFront}
          handleDelete={() => {
            if (setProfileDetailForm && profileDetailForm) {
              setProfileDetailForm({ ...profileDetailForm, panCardFront: undefined });
            } else if (panCardUploadedData && setPanCardUploadedData) {
              setPanCardUploadedData({ ...panCardUploadedData, panCardFront: undefined });
            }
          }}
          handleFileUpload={async (presignedUrls: GetS3PresignedUrlType) => {
            const imageUrl = await handleOnSuccess(presignedUrls.presigned_url_for_pan_front, panCardFront as File);
            if (setProfileDetailForm && profileDetailForm) {
              setProfileDetailForm({ ...profileDetailForm, panCardFront: imageUrl });
            } else if (panCardUploadedData && setPanCardUploadedData) {
              setPanCardUploadedData({ ...panCardUploadedData, panCardFront: imageUrl });
            }
          }}
        />
      </Box>
      {/* Upload back Pan Card */}

      <Box>
        {/* Header Text */}
        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <Box display={"flex"} alignItems={"center"}>
            <Text mr={"ms-4"} mb={"ms-8"} textStyle={"body2-md"} display={"inline"}>
              Upload the back side of your PAN for verification{" "}
              {!isIITGOnbaording ? (
                "*"
              ) : (
                <Text fontSize={"11px"} display={"inline"} color={"gray.600"} pl={1}>
                  (Optional)
                </Text>
              )}
            </Text>
          </Box>
          {profileDetailForm && profileDetailForm?.panCardBack && (
            <Text
              display={"inline"}
              textStyle={"body2"}
              color={"ms-blue.500"}
              cursor={"pointer"}
              _hover={{ textDecoration: "underline" }}
              onClick={() => {
                window.open(profileDetailForm?.panCardBack, "_blank");
              }}
            >
              Preview
            </Text>
          )}
        </Box>
        {/* Drag and drop Flow */}
        <CustomUploadFileInput
          disabled={false}
          toastMessage="PAN Card Back Image has been uploaded"
          fileUploadData={panCardBack as File}
          setFileUploadData={setPanCardBack}
          handleDelete={() => {
            if (setProfileDetailForm && profileDetailForm) {
              setProfileDetailForm({ ...profileDetailForm, panCardBack: undefined });
            } else if (panCardUploadedData && setPanCardUploadedData) {
              setPanCardUploadedData({ ...panCardUploadedData, panCardBack: undefined });
            }
          }}
          handleFileUpload={async (presignedUrls: GetS3PresignedUrlType) => {
            const imageUrl = await handleOnSuccess(presignedUrls.presigned_url_for_pan_front, panCardBack as File);
            if (setProfileDetailForm && profileDetailForm) {
              setProfileDetailForm({ ...profileDetailForm, panCardBack: imageUrl });
            } else if (panCardUploadedData && setPanCardUploadedData) {
              setPanCardUploadedData({ ...panCardUploadedData, panCardBack: imageUrl });
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default PANOcr;
