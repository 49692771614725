import { Box, Flex, Image, Text, Link } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AadhaarInfoType } from "../../../../api/schemas/schema";
import { TryArrow } from "../../../../assets/icons/components";
import { DisabledCamera, GreenCheckIcon } from "../../../../assets/images";
import CameraImage from "../../../../assets/images/camera.svg";
import { AadhaarUploadedDataType } from "../../../../pages/Application/msat/MSATSuccess";
import { ModalTemplate } from "../../../common/general";
import { sendTrackingEvent } from "../../../common/utils/gtm";
import CameraCaptureDrawer from "./CameraCaptureFlow/CameraCaptureDrawer";
import ConfirmAadhaarDetailsModalContent from "./ConfirmAadhaarDetailsModalContent";
import DragAndDrop from "./DragAndDrop";
import DragAndDropSkeleton from "./DragAndDropSkeleton";
import FetchingInformationModalContent from "./FetchingInformationModalContent";
import InvalidAadhaarModalContent from "./InvalidAadhaarModalContent";
interface Props {
  aadhaarUploadedData?: AadhaarUploadedDataType;
  setAadhaarUploadedData?: (value: AadhaarUploadedDataType) => void;
  disabled?: boolean;
  aadhaarOCRDetails?: AadhaarInfoType;
  handleConfirmAadhaarDetails?: () => void;
  showfetchingInfoModal?: boolean;
  setShowFetchingInfoModal?: (value: boolean) => void;
  showConfirmAadhaarModal?: boolean;
  setShowConfirmAadhaarModal?: (value: boolean) => void;
  invalidAadhaarModal?: boolean;
  setInvalidAadhaarModal?: (value: boolean) => void;
  loading?: boolean;
}
const AadhaarOcr = ({
  aadhaarUploadedData,
  setAadhaarUploadedData,
  disabled,
  handleConfirmAadhaarDetails,
  aadhaarOCRDetails,
  showfetchingInfoModal,
  setShowFetchingInfoModal,
  showConfirmAadhaarModal,
  setShowConfirmAadhaarModal,
  invalidAadhaarModal,
  setInvalidAadhaarModal,
  loading,
}: Props) => {
  const location = useLocation();
  const [showCameraCaptureDrawer, setShowCameraCaptureDrawer] = useState<boolean>(false);
  const [currentPictureType, setCurrentPictureType] = useState<"front" | "back" | null>(null);

  // aadhaar image states we get from drag and drop or upload
  const [aadhaarFront, setAadhaarFront] = useState<File>();
  const [aadhaarBack, setAadhaarBack] = useState<File>();
  // aadhaar image states we get from camera capture
  const [aadhaarFrontCaptured, setAadhaarFrontCaptured] = useState<string>();
  const [aadhaarBackCaptured, setAadhaarBackCaptured] = useState<string>();

  const convertFileToBase64 = (file: File, callback: (base64data: string) => void) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      const base64data = reader.result;
      if (base64data && typeof base64data === "string") {
        callback(base64data);
      }
    };
  };

  useEffect(() => {
    if (aadhaarFront) {
      setAadhaarFrontCaptured(undefined);
      convertFileToBase64(aadhaarFront, (base64data: string) => {
        setAadhaarUploadedData &&
          setAadhaarUploadedData({
            ...aadhaarUploadedData,
            aadhaarFront: base64data,
            aadhaarFrontCaptured: undefined,
          });
      });
    } else {
      setAadhaarUploadedData &&
        setAadhaarUploadedData({
          ...aadhaarUploadedData,
          aadhaarFront: undefined,
        });
    }
  }, [aadhaarFront]);

  useEffect(() => {
    if (aadhaarBack) {
      setAadhaarBackCaptured(undefined);
      convertFileToBase64(aadhaarBack, (base64data: string) => {
        setAadhaarUploadedData &&
          setAadhaarUploadedData({
            ...aadhaarUploadedData,
            aadhaarBack: base64data,
            aadhaarBackCaptured: undefined,
          });
      });
    } else {
      setAadhaarUploadedData &&
        setAadhaarUploadedData({
          ...aadhaarUploadedData,
          aadhaarBack: undefined,
        });
    }
  }, [aadhaarBack]);
  useEffect(() => {
    if (aadhaarFrontCaptured) {
      setAadhaarFront(undefined);
      setAadhaarUploadedData &&
        setAadhaarUploadedData({
          ...aadhaarUploadedData,
          aadhaarFrontCaptured: aadhaarFrontCaptured,
          aadhaarFront: undefined,
        });
    }
  }, [aadhaarFrontCaptured]);

  useEffect(() => {
    if (aadhaarBackCaptured) {
      setAadhaarBack(undefined);
      setAadhaarUploadedData &&
        setAadhaarUploadedData({
          ...aadhaarUploadedData,
          aadhaarBackCaptured: aadhaarBackCaptured,
          aadhaarBack: undefined,
        });
    }
  }, [aadhaarBackCaptured]);

  useEffect(() => {
    if (currentPictureType) setShowCameraCaptureDrawer(true);
    else setShowCameraCaptureDrawer(false);
  }, [currentPictureType]);

  const handleCapturedPhoto = (
    currentPictureType: "front" | "back" | null,
    imageSrc?: string | null
  ) => {
    if (!imageSrc) return;
    if (currentPictureType === "front") {
      setAadhaarFrontCaptured(imageSrc);
    } else if (currentPictureType === "back") {
      setAadhaarBackCaptured(imageSrc);
    }
  };
  const disabledBox = () => (
    <Box>
      <Box w={"492px"}>
        <Flex
          borderRadius={"ms-8"}
          border="1px dashed #B3B3B3"
          bgColor={"#FBFBFB"}
          alignItems={"center"}
          justifyContent={"center"}
          h={"48px"}
        >
          <TryArrow color="ms-green.400" boxSize={"6"} />
          <Text ml={"ms-10"} textStyle={"caption"} color="ms-grey.500">
            Drag & drop here or{" "}
            <Link>
              <Text d={"inline"} textStyle={"caption"} color="ms-grey.500">
                Browse
              </Text>
            </Link>
          </Text>
        </Flex>
      </Box>
    </Box>
  );
  return (
    <>
      <Box>
        {/* Upload front Aadhaar */}
        <Box>
          {/* Header Text */}
          <Box d={"flex"} alignItems={"center"}>
            <Text d={"inline"} mr={"ms-4"} mb={"ms-8"} textStyle={"body2-md"}>
              Upload the front side of your Aadhaar *
              {(aadhaarFront || aadhaarFrontCaptured) && (
                <Image
                  ml={"ms-8"}
                  d={{ base: "inline", md: "none" }}
                  src={GreenCheckIcon}
                  alt="uploaded"
                />
              )}
            </Text>
            {(aadhaarFront || aadhaarFrontCaptured) && (
              <Image
                d={{ base: "none", md: "block" }}
                mb={"ms-8"}
                src={GreenCheckIcon}
                alt="uploaded"
              />
            )}
          </Box>

          {/* Drag and drop Flow */}
          <Box d={"flex"} alignItems={"center"}>
            {disabled ? (
              disabledBox()
            ) : aadhaarFrontCaptured ? (
              <DragAndDropSkeleton
                text="Aadhaar Front Image"
                onClick={() => {
                  setAadhaarFrontCaptured(undefined);
                }}
              />
            ) : (
              <DragAndDrop
                handleImageDelete={() => setAadhaarFront(undefined)}
                file={aadhaarFront}
                setFile={setAadhaarFront}
                type="front"
              />
            )}
            {aadhaarFront || aadhaarFrontCaptured || disabled ? (
              <Image ml={"ms-12"} src={DisabledCamera} alt={"camera"} />
            ) : (
              <Image
                onClick={() => setCurrentPictureType("front")}
                cursor={"pointer"}
                ml={"ms-12"}
                src={CameraImage}
                alt={"camera"}
                mb={"6px"}
              />
            )}
          </Box>
        </Box>
        {/* Upload back Aadhaar */}
        <Box mt={"ms-16"}>
          {/* Header Text */}
          <Box d={"flex"} alignItems={"center"}>
            <Text mr={"ms-4"} mb={"ms-8"} textStyle={"body2-md"}>
              Upload the back side of your Aadhaar *
              {(aadhaarBack || aadhaarBackCaptured) && (
                <Image
                  ml={"ms-8"}
                  d={{ base: "inline", md: "none" }}
                  src={GreenCheckIcon}
                  alt="uploaded"
                />
              )}
            </Text>
            {(aadhaarBack || aadhaarBackCaptured) && (
              <Image
                d={{ base: "none", md: "block" }}
                mb={"ms-8"}
                src={GreenCheckIcon}
                alt="uploaded"
              />
            )}
          </Box>
          {/* Drag and drop Flow */}
          <Box d={"flex"} alignItems={"center"}>
            {disabled ? (
              disabledBox()
            ) : aadhaarBackCaptured ? (
              <DragAndDropSkeleton
                text="Aadhaar Back Image"
                onClick={() => {
                  setAadhaarBackCaptured(undefined);
                }}
              />
            ) : (
              <DragAndDrop
                handleImageDelete={() => setAadhaarBack(undefined)}
                file={aadhaarBack}
                setFile={setAadhaarBack}
                type="back"
              />
            )}

            {aadhaarBack || aadhaarBackCaptured || disabled ? (
              <Image ml={"ms-12"} src={DisabledCamera} alt={"camera"} />
            ) : (
              <Image
                onClick={() => setCurrentPictureType("back")}
                cursor={"pointer"}
                ml={"ms-12"}
                src={CameraImage}
                alt={"camera"}
                mb={"6px"}
              />
            )}
          </Box>
        </Box>
      </Box>
      <ModalTemplate
        title=""
        isOpen={showfetchingInfoModal || false}
        onClose={() => setShowFetchingInfoModal && setShowFetchingInfoModal(false)}
        marginX={2}
        maxWidth="552px"
        closeOnOverlayClick={false}
      >
        <FetchingInformationModalContent />
      </ModalTemplate>
      <ModalTemplate
        title=""
        isOpen={showConfirmAadhaarModal || false}
        onClose={() => {
          sendTrackingEvent({
            event: "db_aadhaar_x",
            eventLabel: location.pathname,
          });
          setShowConfirmAadhaarModal && setShowConfirmAadhaarModal(false);
        }}
        marginX={2}
        maxWidth="552px"
        showCloseButtonIcon={true}
      >
        <ConfirmAadhaarDetailsModalContent
          handleConfirmAadhaarDetails={handleConfirmAadhaarDetails}
          aadhaarOCRDetails={aadhaarOCRDetails}
          loading={loading}
          setShowConfirmAadhaarModal={setShowConfirmAadhaarModal}
        />
      </ModalTemplate>
      <ModalTemplate
        title=""
        isOpen={invalidAadhaarModal || false}
        onClose={() => setInvalidAadhaarModal && setInvalidAadhaarModal(false)}
        marginX={2}
        maxWidth="484px"
        showCloseButtonIcon={true}
      >
        <InvalidAadhaarModalContent />
      </ModalTemplate>

      <CameraCaptureDrawer
        isOpen={showCameraCaptureDrawer}
        setIsOpen={setShowCameraCaptureDrawer}
        handleCapturedPhoto={handleCapturedPhoto}
        currentPictureType={currentPictureType}
        setCurrentPictureType={setCurrentPictureType}
        aadhaarFrontCaptured={aadhaarFrontCaptured}
        aadhaarBackCaptured={aadhaarBackCaptured}
        setAadhaarFrontCaptured={setAadhaarFrontCaptured}
        setAadhaarBackCaptured={setAadhaarBackCaptured}
      />
    </>
  );
};

export default AadhaarOcr;
