import { GetSelfLearningCourseType, GetCourseEnrollmentType } from "../../schemas/schema";
import api from "../axios";

export const getSelfLearningCourses = async (): Promise<GetSelfLearningCourseType[]> => {
  const client = await api.client;
  const response = await client.list_self_learning_courses_self_learning_courses_get();
  return response.data;
};

export const enrollSelfLearningCourse = async ({
    self_learning_course_id
}: {
    self_learning_course_id: string
}): Promise<GetCourseEnrollmentType> => {
  const client = await api.client;
  const response = await client.enrol_in_self_learning_course_self_learning_courses_enroll_post(null,{
    self_learning_course_id
  });
  return response.data;
};

