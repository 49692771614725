import React from "react";
// import { AssistantOnboarding } from "../../assets/images/"

import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import OnboardingIcon from "../../assets/icons/components/Others/OnboardingIcon";
import { OverviewCardLeft } from "./OverviewCardLeft";
import { OverviewCardFooter } from "./OverviewCardFooter";

export const OnboardingOverviewCard = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <Box border={"0.5px solid #D9D9D9"} borderRadius={"ms-16"} w="full">
        <Flex>
          <OverviewCardLeft icon={<OnboardingIcon boxSize="20" />} bg="ms-yellow.50" />
          <Flex w="full" direction={"column"} padding="ms-16">
            <Heading as="h6">Assisted Onboarding</Heading>
            <Box minHeight={"96px"}>
              <Text textStyle={"body2"} mt={{ base: "ms-4", md: "ms-8" }}>
                Book a one on one session with our team to get help during the onboarding process.
              </Text>
            </Box>
            <OverviewCardFooter handleBooking={onClick} />
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
