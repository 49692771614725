import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { coursePointers } from "../../../constants/revamp/papOnbaording";

const CourseStructureRightSection = () => {
  return (
    <Flex
      maxW="480px"
      flexShrink={0}
      border="1px solid #E5E5E5"
      rounded="16px"
      bg="#FFFFFF"
      py={{ base: "20px", md: "30px" }}
      px={{ base: "20px", md: "32px" }}
      direction="column"
      boxShadow="0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)"
    >
      <Text fontSize={{ base: "17px", md: "24px" }} fontWeight={600} fontFamily="Poppins">
        Course structure{" "}
        <Text as="span" fontStyle="italic" fontSize={{ base: "11.6px", md: "16px" }} fontFamily="Poppins" fontWeight={500}>
          {" "}
          (Trial week)
        </Text>
      </Text>

      <Flex mt="20px" direction="column" gap={5} px={{ base: 0, md: "16px" }} pos="relative">
        {coursePointers.map((pointer, index) => (
          <Flex key={index} gap={2}>
            <Image src="/img/revamp/blue-checkbox.svg" pos="relative" zIndex={1} w="24px" h="24px" objectFit="contain" />
            <Flex direction="column" gap={{ md: 1 }}>
              <Text fontSize={{ base: "13.6px", md: "16px" }} fontWeight={600} fontFamily="inter" color="#544D4F">
                {pointer.title}
              </Text>
              <Text fontSize={{ base: "11.15px", md: "14px" }} fontFamily="inter" color="#6C6768" fontWeight={400}>
                {pointer.subtitle}
              </Text>
            </Flex>
          </Flex>
        ))}

        <Box pos="absolute" top={0} bottom={0} left={{ base: "10px", md: "27px" }} h="90%" border="1px dotted black" w="1px" />
      </Flex>
    </Flex>
  );
};

export default CourseStructureRightSection;
