import React from "react";
import { SingleQuestion } from "./NewLaunchForm";
interface Props {
  question: SingleQuestion;
  course_type?: string;
}
const SelectQuestion = ({ question, course_type }: Props) => {
  return course_type === "FULL_TIME" ? (
    <select
      key={question.name}
      required
      name={question.name}
      cf-questions={question.title}
      cf-conditional-highest_education_degree={
        (question.name.includes("highest_education_qualification_UG_section") && "Graduation") ||
        (question.name.includes("highest_education_qualification_PG_section") &&
          "Post Graduation") ||
        (question.name.includes("highest_education_qualification_Diploma_section") && "Diploma")
      }
    >
      {question.dropdownOptions?.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  ) : (
    <select
      key={question.name}
      required
      name={question.name}
      cf-questions={question.title}
      cf-conditional-highest_education_degree={
        (question.name.includes("highest_education_degree_section_bachelors") && "Bachelors") ||
        (question.name.includes("highest_education_degree_section_masters") && "Masters")
      }
      cf-conditional-past_coding_data_analytics_experience={
        question.name.includes("past_coding_data_analytics_experience_section") && "Yes"
      }
    >
      {question.dropdownOptions?.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export default SelectQuestion;
