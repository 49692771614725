import { Box, Button, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import SDCourseCard from "../da-onbaording/SDCourseCard";
import EligiblityForm from "../sd-oboarding/EligiblityForm";
import { ACTIVITY_TYPE } from "../../../pages/V2/Dashboard";
import TakeMSAT from "../../V2/empowerHer-onboarding/TakeMSAT";
import MSATResultCard from "../../V2/MSATResult/MSATResultCard";
import JoinZoomSlackCard from "../da-onbaording/JoinZoomSlackCard";
import ActivityCardContainerV2 from "../activitiesV2/ActivityCardContainerV2";
import InEligibleCard from "../sd-oboarding/InEligibleCard";
import { BatchType, ProfileAssessment } from "../../../api/schemas/schema";
import { ModalTemplateV2 } from "../../common/general";
import { useEffect, useState } from "react";

interface Props {
  currentOnboardingStep: number;
  isResultCardVisible: boolean;
  isBothMSATFailed: boolean;
  setIsEligible: (value: boolean) => void;
  isInEligible: boolean;
  inEligibleReason: string | null;
  bestAssessment: ProfileAssessment;
  activeBatch: BatchType;
}

const DAOnbaordingComponent = ({ currentOnboardingStep, isResultCardVisible, isBothMSATFailed, setIsEligible, isInEligible, inEligibleReason, bestAssessment, activeBatch }: Props) => {
  const [showMSATResultPendingModal, setShowMSATResultPendingModal] = useState(false);

  useEffect(() => {
    if (bestAssessment && bestAssessment?.status === "ENDED" && !showMSATResultPendingModal) {
      setShowMSATResultPendingModal(true);
    } else {
      setShowMSATResultPendingModal(false);
    }
  }, [bestAssessment]);
  return (
    <>
      <Box w={"full"} px={{ base: "16px", md: "24px" }}>
        <Grid templateColumns={{ base: "auto", md: currentOnboardingStep === 3 ? "1fr 480px" : isBothMSATFailed ? "1fr 390px" : "1fr" }} gap={"34px"} justifyContent={"space-between"} w={"full"}>
          <GridItem w={"full"}>
            {currentOnboardingStep === 1 ? (
              isResultCardVisible ? (
                <MSATResultCard isMasaiCoursePrefered={true} bestAssessment={bestAssessment as ProfileAssessment} />
              ) : (
                <TakeMSAT />
              )
            ) : currentOnboardingStep === 2 ? (
              !isInEligible ? (
                <EligiblityForm />
              ) : (
                <InEligibleCard setIsEligible={setIsEligible} inEligibleReason={inEligibleReason} />
              )
            ) : currentOnboardingStep === 3 ? (
              <JoinZoomSlackCard activeBatch={activeBatch as BatchType} />
            ) : (
              <></>
            )}
          </GridItem>
          <GridItem w={"full"} mx={"auto"} display={currentOnboardingStep !== 3 && !isBothMSATFailed ? "none" : "grid"}>
            {isBothMSATFailed ? (
              <SDCourseCard />
            ) : (
              <ActivityCardContainerV2
                onRegister={() => {
                  console.log("test");
                }}
                isDashboard
                maxWidth={"full"}
                eventType={ACTIVITY_TYPE.FOUNDER_WEBINAR}
              />
            )}
          </GridItem>
        </Grid>
      </Box>

      {showMSATResultPendingModal && (
        <ModalTemplateV2
          closeOnOverlayClick={false}
          isOpen={showMSATResultPendingModal}
          hideIconWithConfetti={true}
          waiting={true}
          onClose={() => {
            setShowMSATResultPendingModal(false);
          }}
          text={
            <Box>
              <Heading as="h5" color="#fff">
                Thank you for attempting MSAT!
              </Heading>
              <Text mt="4px" textStyle="body1" color="#fff">
                Please wait while we are getting your scores.
              </Text>
            </Box>
          }
          extra={
            <>
              <Button
                as="a"
                onClick={() => {
                  window.location.reload();
                }}
                mt="28px"
                variant="secondary"
              >
                Refresh Result Status
              </Button>
            </>
          }
        />
      )}
    </>
  );
};
export default DAOnbaordingComponent;
