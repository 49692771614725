import { Box, Flex, Stack, Badge, Circle, keyframes, Heading } from "@chakra-ui/react";
import React from "react";

import { getColors } from "./getColors";
import { StepsProgressProps } from "./types";

const blink = keyframes`
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  `;

const StepProgress = (props: StepsProgressProps) => {
  const styles = getColors(props.status);

  if (props.active) {
    styles.descriptionColor = "ms-grey.900";
  }

  const handleClick = () => {
    if (props.status !== "pending") {
      props.stepState &&
        props.handleStepCick &&
        props.handleStepCick(props.stepNumber, props.stepState);
    }
  };

  return (
    <Box w="full" maxW="296px">
      <Stack spacingY="0.5rem" direction="column">
        <Flex onClick={handleClick} cursor={styles.cursor} align="center" gap="8px">
          <Box position="relative">
            {React.createElement(styles.icon, {
              color: styles.iconColor,
              boxSize: "24px",
              fillColor: "white",
            })}
          </Box>
          <Box
            hidden={props.hideStepper}
            w="full"
            h="4px"
            bgColor={styles.stepperColor}
            borderRadius="8px"
          ></Box>
        </Flex>
        <Flex d="flex" align="center" gap="0.5rem">
          {props.active && (
            <Circle animation={`${blink} infinite 2s linear`} size="0.5rem" bg="ms-brick.500" />
          )}
          <Heading as="h6" color={styles.descriptionColor}>
            {props.description}
          </Heading>
        </Flex>
        <Box>
          <Badge variant={styles.badge} label={`styles.badgeText`}>
            {styles.badgeText}
          </Badge>
        </Box>
      </Stack>
    </Box>
  );
};

export default StepProgress;
