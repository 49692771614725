const Avatar = {
  sizes: {
    profile: {
      container: {
        width: "5rem",
        height: "5rem",
        fontSize: "40px",
      },
    },
  },
};

export default Avatar;
