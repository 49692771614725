import React from "react";
import { Box, Heading, Text, Button } from "@chakra-ui/react";
import successLottie from "../../../assets/successLottie.json";
import Lottie from "react-lottie-player";
interface Props {
  onContinue: () => void;
  btnText?: string;
  text?: string;
}
const VerificationSuccessModal = ({ onContinue, btnText, text }: Props) => {
  return (
    <Box textAlign={"center"} pb={"ms-24"} pt={"ms-24"}>
      <Box
        m={"auto"}
        mb={"ms-32"}
        w={{ base: "97px", md: "112px" }}
        h={{ base: "104px", md: "120px" }}
      >
        <Lottie loop animationData={successLottie} play style={{ width: "100%" }} />
      </Box>
      <Heading mb={"ms-8"} as="h3">
        Verification Done!
      </Heading>
      <Text mb={"ms-24"} textStyle="body1">
        {text || `Your documentation is verified successfully.`}
      </Text>
      <Button onClick={onContinue} size="md" variant="primary">
        {btnText || `Continue to onboarding`}
      </Button>
    </Box>
  );
};

export default VerificationSuccessModal;
