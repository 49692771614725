import React from "react";
import EventDetail from "./EventDetail";
import { SimpleGrid } from "@chakra-ui/react";
interface Props {
  eventDetail: { name: string; icon: React.ReactElement; text: string }[];
}
const EventDetailsSection = ({ eventDetail }: Props) => {
  return (
    <SimpleGrid
      gridTemplateColumns={"repeat(2,1fr)"}
      rowGap={"ms-16"}
      columnGap={{ base: "12px", md: "28px" }}
    >
      {eventDetail.map((singleEventDetail) => (
        <EventDetail key={singleEventDetail.name} eventDetail={singleEventDetail} />
      ))}
    </SimpleGrid>
  );
};

export default EventDetailsSection;
