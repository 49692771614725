/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Input, InputGroup, Text, Select } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import DateSelect from "../../common/DateSelect";
import dayjs from "dayjs";

import { RadioButtonGroup } from "../../common/general";
import { useMutation } from "react-query";
import useSnackBar from "../../common/general/SnackBar";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import { axiosErrorHandler } from "../../../api/utils/error";
import { sendTrackingEvent } from "../../common/utils/gtm";
import { updateProfile } from "../../../api/utils/api/v2/profile";
import { useLocation } from "react-router-dom";

export type GetUserDetailsForIITGFormType = {
  graduationMonth: number | null;
  graduationYear: number | null;
  currentlyWorking?: string;
  collegeName?: string | null;
  stream?: string;
  salaryRange?: string;
  companyName?: string;
  designation?: string;
  workExperience?: string;
  currentlyStudyingIn?: string;
  currentWorkingDomain?: string;
  haveYouWorkedBefore?: string;
  hasSchoolingCompleted?: string;
  hasGraduationCompleted?: string;
  currentLocation?: string;
  highestQualification?: string;
};

const streamOptions = ["B.Tech CS/IT", "B.E CS/IT", "B.Tech others", "B.E others", "BA", "B.Sc", "B.Com", "BCA", "B.Sc CS/IT", "M.Tech", "MCA", "M.Sc CS/IT", "MA", "M.Sc", "M.Com", "Others"];

const highestQualificationOptions = [
  "Completed 12th",
  "B.Tech CS/IT",
  "B.E CS/IT",
  "B.Tech others",
  "B.E others",
  "BA",
  "B.Sc",
  "B.Com",
  "BCA",
  "B.Sc CS/IT",
  "M.Tech",
  "MCA",
  "M.Sc CS/IT",
  "MA",
  "M.Sc",
  "M.Com",
  "Others",
];

const GetUserDetailsForIITG = () => {
  const snackbar = useSnackBar();

  const [formValues, setFormValues] = useState<GetUserDetailsForIITGFormType>({
    graduationMonth: null,
    graduationYear: null,
    currentlyWorking: "",
    collegeName: "",
    stream: "",
    salaryRange: "",
    companyName: "",
    designation: "",
    currentlyStudyingIn: "school",
    haveYouWorkedBefore: "no",
    currentWorkingDomain: "",
    hasSchoolingCompleted: "no",
    hasGraduationCompleted: "no",
    currentLocation: "",
    highestQualification: "",
  });

  const [dateOfBirth, setDateOfBirth] = useState<string>("");
  const [selectedWorkingOption, setSelectedWorkingOption] = useState<string>("");
  const [isSchoolingCompleted, setIsSchoolingCompleted] = useState<boolean>(false);
  const [isGraduationCompleted, setIsGraduationCompleted] = useState<boolean>(false);
  const [selectedEducationOption, setSelectedEducationOption] = useState<boolean>(true);
  const location = useLocation();

  const { mutate: updateProfileMutate, isLoading: updateProfileLoading } = useMutation(updateProfile, {
    onSuccess: () => {
      refetchProfile();
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { data: profile, refetch: refetchProfile } = useProfile();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFormValuesChange = (key: string, value?: any) => {
    if (key === "monthYearOfGraduation") {
      const parsedDate = dayjs(value);
      const year = parsedDate.year();
      const month = parsedDate.month() + 1;
      setFormValues((prev) => ({
        ...prev,
        graduationMonth: month,
        graduationYear: year,
      }));
    } else if (key === "dob") {
      //convert date in this format 2000-07-05
      setFormValues((prev) => ({
        ...prev,
        [key]: value.format("YYYY-MM-DD"),
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const getIsDisabledValue = () => {
    if (!dateOfBirth) return true;
    if (!selectedWorkingOption) {
      return true;
    } else if (selectedWorkingOption === "cw") {
      return !formValues.highestQualification || !formValues.currentWorkingDomain || !formValues.workExperience;
    } else if (selectedWorkingOption === "cs") {
      if (formValues.currentlyStudyingIn === "college") {
        return !formValues.highestQualification || !formValues.collegeName || !formValues.graduationMonth || !formValues.graduationYear || !formValues.currentLocation;
      }
    } else if (selectedWorkingOption === "nwns") {
      if (formValues.highestQualification === "Completed 12th") {
        return !formValues.stream || !formValues.currentLocation || !formValues.haveYouWorkedBefore;
      } else {
        return (
          !formValues.currentLocation || !formValues.highestQualification || !formValues.graduationMonth || !formValues.graduationYear || !formValues.collegeName || !formValues.haveYouWorkedBefore
        );
      }
    }
  };

  useEffect(() => {
    if (profile && profile?.date_of_birth) {
      setDateOfBirth(profile.date_of_birth);
    }
  }, [profile]);

  const gridTemplateColumns = "repeat(1,1fr)";
  return (
    <>
      <Box
        display={"grid"}
        gridTemplateColumns={gridTemplateColumns ? gridTemplateColumns : { md: "repeat(1,1fr)" }}
        gap={{ md: "24px" }}
        padding={{ md: "10" }}
        overflowY={"auto"}
        css={{
          "&::-webkit-scrollbar": {
            width: "0px",
            display: "none",
          },
          "&::-webkit-scrollbar-track": {
            width: "0px",
            display: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "0px",
            display: "none",
          },
        }}
        position={"relative"}
      >
        {!profile?.aadhaar_verified && (
          <Button
            position={"absolute"}
            right={0}
            mt="10px"
            mr={"5px"}
            onClick={() => {
              updateProfileMutate({
                meta: {
                  hasSkippedLaunchForm: true,
                },
              });
            }}
          >
            Skip For Now
          </Button>
        )}
        <Text color="ms-grey.900" as="h4" fontSize={"14px"} mt={{ md: "10px", base: "65px" }} fontWeight={700} lineHeight={"24px"}>
          Before you continue, answer the following questions.{" "}
        </Text>

        <Box display={"grid"} gridTemplateColumns={gridTemplateColumns ? gridTemplateColumns : { md: "repeat(1,1fr)" }} gap={{ md: "24px" }}>
          {/* Date Of Birth */}
          <Box mt={{ base: "ms-16", md: "0px" }}>
            <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
              1 . What is your DOB?*
            </Text>
            <DateSelect
              showRelativeDate={false}
              onChange={(value) => {
                setDateOfBirth(value.format("YYYY-MM-DD"));
              }}
              value={dateOfBirth ? dayjs(dateOfBirth) : null}
              isInvalid={false}
              errorMessage="Please add your Date of birth."
              yearAndMonthOnly={false}
              disabled={!!profile?.date_of_birth}
              w={["100%", "50%", "40%", "40%"]}
            />
          </Box>

          <Box mt={{ base: "ms-16", md: "0px" }}>
            <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
              2 . Your current location?*
            </Text>
            <InputGroup w={["100%", "50%", "40%", "40%"]}>
              <Input value={formValues.currentLocation || ""} onChange={(e) => handleFormValuesChange("currentLocation", e.target.value.trim())} placeholder="Enter your current location" />
            </InputGroup>
          </Box>

          {/* currently working */}
          <Box mt={{ base: "ms-16", md: "0px" }}>
            <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
              2 . What is your current status?*
            </Text>
            <RadioButtonGroup
              options={[
                { key: "cs", value: "Studying In College" },
                { key: "cw", value: "Currently Working" },
                { key: "nwns", value: "Neither Working Nor Studying" },
              ]}
              selectedValue={selectedWorkingOption === "cw" ? "cw" : selectedWorkingOption === "cs" ? "cs" : selectedWorkingOption === "nwns" ? "nwns" : ""}
              onChange={(key) => {
                if (setSelectedWorkingOption) {
                  setSelectedWorkingOption(key);
                }
                if (key === "cw" || key === "nwns") {
                  setFormValues({
                    ...formValues,
                    graduationMonth: null,
                    graduationYear: null,
                    currentlyWorking: key === "cw" ? "Currently Working" : "Neither Working nor Studying",
                    collegeName: "",
                    stream: "",
                    salaryRange: "",
                    companyName: "",
                    designation: "",
                    haveYouWorkedBefore: "no",
                    currentWorkingDomain: "",
                    currentlyStudyingIn: "",
                    hasSchoolingCompleted: "no",
                    hasGraduationCompleted: "no",
                    highestQualification: "",
                  });
                } else {
                  setFormValues({
                    ...formValues,
                    graduationMonth: null,
                    graduationYear: null,
                    currentlyWorking: "Currently Studying",
                    collegeName: "",
                    stream: "",
                    salaryRange: "",
                    companyName: "",
                    designation: "",
                    currentlyStudyingIn: "college",
                    haveYouWorkedBefore: "no",
                    currentWorkingDomain: "",
                    hasSchoolingCompleted: "no",
                    hasGraduationCompleted: "no",
                    highestQualification: "",
                  });
                }
                handleFormValuesChange("currentlyWorking", key === "cw" ? "Currenlty Working" : key === "cs" ? "Currently Studying" : "Neither Working nor Studying");
              }}
              gridTemplateComlumns={{ md: "repeat(4,1fr)" }}
              columnGap={"ms-4"}
              rowGap={"ms-24"}
              disabled={false}
            />
          </Box>

          {/* if cw*/}
          {selectedWorkingOption === "cw" && (
            <Box display={"grid"} gridTemplateColumns={gridTemplateColumns ? gridTemplateColumns : { md: "repeat(1,1fr)" }} gap={{ md: "24px" }} mt={"ms-24"}>
              {/* Educational qualification */}
              <Box mt={{ base: "ms-16", md: "0px" }}>
                <Text mb={{ base: "ms-2", md: "2px" }} textStyle="body2-md" color="ms-red.900">
                  3 . What is your highest qualification?*
                </Text>

                <Select
                  w={["100%", "50%", "40%", "40%"]}
                  name="highestQualification"
                  value={formValues.highestQualification || ""}
                  onChange={(event) => {
                    setFormValues({ ...formValues, stream: "", graduationMonth: null, graduationYear: null, collegeName: null });
                    handleFormValuesChange("highestQualification", event.target.value);
                  }}
                >
                  <option value="" disabled selected>
                    Select your qualification
                  </option>
                  {highestQualificationOptions.map((qualification) => {
                    return (
                      <option key={qualification} value={qualification}>
                        {qualification}
                      </option>
                    );
                  })}
                </Select>
              </Box>
              {/* salary range dropdown * */}
              {/* <Box mt={"ms-16"}>
                <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
                  4 . What is your monthly salary? *
                </Text>
                <InputGroup w={["100%", "50%", "40%", "40%"]}>
                  <Input type="number" value={formValues.salaryRange || ""} onChange={(e) => handleFormValuesChange("salaryRange", e.target.value)} placeholder="Enter your salary range" />
                </InputGroup>
              </Box> */}

              {/* Current Domain */}
              <Box mt={{ base: "ms-16", md: "0px" }}>
                <Text mb={{ base: "ms-2", md: "2px" }} textStyle="body2-md" color="ms-red.900">
                  4 . Which domain are you working in?*
                </Text>

                <Box mt={{ base: "ms-16", md: "0px" }}>
                  <RadioButtonGroup
                    options={[
                      { key: "tech", value: "Tech" },
                      { key: " non-tech", value: "Non-Tech" },
                    ]}
                    selectedValue={formValues.currentWorkingDomain || ""}
                    onChange={(key) => {
                      handleFormValuesChange("currentWorkingDomain", key);
                    }}
                    gridTemplateComlumns={{ md: "repeat(4,1fr)" }}
                    columnGap={"ms-4"}
                    rowGap={"ms-24"}
                    disabled={false}
                  />
                </Box>
              </Box>

              {/* designation name */}
              <Box mt={{ base: "ms-16", md: "0px" }}>
                <Text mb={{ base: "ms-2", md: "2px" }} textStyle="body2-md" color="ms-red.900">
                  5 . What is your work experience?*
                </Text>

                <Box mt={{ base: "ms-16", md: "0px" }}>
                  <InputGroup w={["100%", "50%", "40%", "40%"]}>
                    <Input value={formValues.workExperience || ""} onChange={(e) => handleFormValuesChange("workExperience", e.target.value.trim())} placeholder="Enter your work experience" />
                  </InputGroup>
                </Box>
              </Box>

              {/* company name */}
              {/* <Box mt={{ base: "ms-16", md: "0px" }}>
                <Text mb={{ base: "ms-2", md: "2px" }} textStyle="body2-md" color="ms-red.900">
                  6 . What is the name of your company*
                </Text>

                <Box mt={{ base: "ms-16", md: "0px" }}>
                  <InputGroup w={["100%", "50%", "40%", "40%"]}>
                    <Input value={formValues.companyName || ""} onChange={(e) => handleFormValuesChange("companyName", e.target.value)} placeholder="Enter your company name" />
                  </InputGroup>
                </Box>
              </Box> */}
            </Box>
          )}
          {/* Schooling Completed */}
          {/* {selectedWorkingOption === "nwns" ? (
            <Box mt={{ base: "ms-16", md: "0px" }}>
              <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
                4 . Have you completed your school?
              </Text>
              <RadioButtonGroup
                options={[
                  { key: "yes", value: "Yes" },
                  { key: "no", value: "No" },
                ]}
                selectedValue={!isSchoolingCompleted ? "no" : "yes"}
                onChange={(key) => {
                  if (setIsSchoolingCompleted) {
                    setIsSchoolingCompleted(key === "yes" ? true : false);
                  }
                  handleFormValuesChange("hasSchoolingCompleted", key);
                }}
                gridTemplateComlumns={{ md: "repeat(4,1fr)" }}
                columnGap={"ms-4"}
                rowGap={"ms-24"}
                disabled={false}
              />
            </Box>
          ) : null} */}

          {/* Have you finished your graduation? */}
          {/* {selectedWorkingOption === "nwns" ? (
            <Box mt={{ base: "ms-16", md: "0px" }}>
              <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
                4 . Have you finished your graduation?
              </Text>
              <RadioButtonGroup
                options={[
                  { key: "yes", value: "Yes" },
                  { key: "no", value: "No" },
                ]}
                selectedValue={!isGraduationCompleted ? "no" : "yes"}
                onChange={(key) => {
                  if (setIsGraduationCompleted) {
                    setIsGraduationCompleted(key === "yes" ? true : false);
                  }
                  if (key === "no") {
                    setFormValues({
                      ...formValues,
                      graduationMonth: null,
                      graduationYear: null,
                      currentlyWorking: "Neither Working nor Studying",
                      collegeName: "",
                      stream: "",
                      salaryRange: "",
                      companyName: "",
                      designation: "",
                      currentlyStudyingIn: "",
                    });
                  }
                  handleFormValuesChange("hasGraduationCompleted", key);
                }}
                gridTemplateComlumns={{ md: "repeat(4,1fr)" }}
                columnGap={"ms-4"}
                rowGap={"ms-24"}
                disabled={false}
              />
            </Box>
          ) : null} */}

          {/* Have you worked before */}
          {selectedWorkingOption === "nwns" ? (
            <Box mt={{ base: "ms-16", md: "0px" }}>
              <Box mt={{ base: "ms-16", md: "0px" }}>
                <Text mb={{ base: "ms-2", md: "2px" }} textStyle="body2-md" color="ms-red.900">
                  3 . What is your highest qualification?*
                </Text>

                <Select
                  w={["100%", "50%", "40%", "40%"]}
                  name="highestQualification"
                  value={formValues.highestQualification || ""}
                  onChange={(event) => {
                    setFormValues({ ...formValues, stream: "", graduationMonth: null, graduationYear: null, collegeName: null });
                    handleFormValuesChange("highestQualification", event.target.value);
                  }}
                >
                  <option value="" disabled selected>
                    Select your qualification
                  </option>
                  {highestQualificationOptions.map((qualification) => {
                    return (
                      <option key={qualification} value={qualification}>
                        {qualification}
                      </option>
                    );
                  })}
                </Select>
              </Box>
              {formValues.highestQualification && formValues.highestQualification === "Completed 12th" ? (
                <Box mt={{ base: "ms-16", md: "5px" }}>
                  <Text mb={{ base: "ms-2", md: "2px" }} textStyle="body2-md" color="ms-red.900">
                    4 . Select your stream?*
                  </Text>

                  <Select w={["100%", "50%", "40%", "40%"]} name="stream" value={formValues.stream || ""} onChange={(event) => handleFormValuesChange("stream", event.target.value)}>
                    <option value="" disabled selected>
                      Select your stream
                    </option>
                    {["PCM", "PCB", "Commerce", "Arts"].map((stream) => {
                      return (
                        <option key={stream} value={stream}>
                          {stream}
                        </option>
                      );
                    })}
                  </Select>
                </Box>
              ) : formValues.highestQualification ? (
                <Box mt={{ base: "ms-16", md: "5px" }}>
                  {/* college name */}
                  <Box mt={{ base: "ms-16", md: "5px" }}>
                    <Text mb={{ base: "ms-2", md: "2px" }} textStyle="body2-md" color="ms-red.900">
                      4 . What is the name of your college?*
                    </Text>

                    <Box mt={{ base: "ms-16", md: "5px" }}>
                      <InputGroup w={["100%", "50%", "40%", "40%"]}>
                        <Input value={formValues.collegeName || ""} type="text" onChange={(e) => handleFormValuesChange("collegeName", e.target.value.trim())} placeholder="Enter your college name" />
                      </InputGroup>
                    </Box>
                  </Box>

                  {/* grad year and month */}
                  <Box mt={{ base: "ms-16", md: "5px" }}>
                    <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
                      5 . Select your graduation month & year *
                    </Text>
                    <DateSelect
                      showRelativeDate={false}
                      onChange={(value) => {
                        handleFormValuesChange("monthYearOfGraduation", value);
                      }}
                      value={
                        formValues.graduationMonth && formValues.graduationYear
                          ? dayjs()
                              .month(formValues.graduationMonth - 1)
                              .year(formValues.graduationYear)
                          : null
                      }
                      isInvalid={false}
                      errorMessage="Please add your month and year of graduation."
                      onClickCallback={() => {
                        //
                      }}
                      yearAndMonthOnly={true}
                      disabled={false}
                      w={["100%", "50%", "40%", "40%"]}
                      selectedOption={selectedWorkingOption}
                    />
                  </Box>
                </Box>
              ) : null}

              <Box mt={{ base: "ms-16", md: "5px" }}>
                <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
                  {formValues.highestQualification === "Completed 12th" ? 4 : 6} . Have you worked before?*
                </Text>
                <RadioButtonGroup
                  options={[
                    { key: "yes", value: "Yes" },
                    { key: "no", value: "No" },
                  ]}
                  selectedValue={formValues.haveYouWorkedBefore || ""}
                  onChange={(key) => {
                    if (key === "no") {
                      setFormValues({
                        ...formValues,
                        graduationMonth: null,
                        graduationYear: null,
                        currentlyWorking: "Neither Working nor Studying",
                        collegeName: "",
                        // stream: "",
                        salaryRange: "",
                        companyName: "",
                        designation: "",
                        currentlyStudyingIn: "",
                      });
                    }
                    handleFormValuesChange("haveYouWorkedBefore", key);
                  }}
                  gridTemplateComlumns={{ md: "repeat(4,1fr)" }}
                  columnGap={"ms-4"}
                  rowGap={"ms-24"}
                  disabled={false}
                />
              </Box>
            </Box>
          ) : null}

          {selectedWorkingOption === "nwns" && formValues.haveYouWorkedBefore === "yes" ? (
            <Box display={"none"} gridTemplateColumns={gridTemplateColumns ? gridTemplateColumns : { md: "repeat(1,1fr)" }} gap={{ md: "24px" }}>
              {/* Current Domain */}
              {/* <Box mt={{ base: "ms-16", md: "0px" }}>
                <Text mb={{ base: "ms-2", md: "2px" }} textStyle="body2-md" color="ms-red.900">
                  4 . Which domain were you working in?*
                </Text>

                <Select
                  w={["100%", "50%", "40%", "40%"]}
                  name="currentWorkingDomain"
                  value={formValues.currentWorkingDomain || ""}
                  onChange={(event) => handleFormValuesChange("currentWorkingDomain", event.target.value)}
                >
                  <option value="" disabled selected>
                    Select your domain
                  </option>
                  {["Tech", "Non-Tech"].map((stream) => {
                    return (
                      <option key={stream} value={stream}>
                        {stream}
                      </option>
                    );
                  })}
                </Select>
              </Box> */}
              {/* salary range dropdown * */}
              {/* <Box mt={"ms-16"}>
                <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
                  6 . What was your monthly salary? *
                </Text>
                <InputGroup w={["100%", "50%", "40%", "40%"]}>
                  <Input type="number" value={formValues.salaryRange || ""} onChange={(e) => handleFormValuesChange("salaryRange", e.target.value)} placeholder="Enter your salary range" />
                </InputGroup>
              </Box> */}

              {/* Current Domain */}
              {/* <Box mt={{ base: "ms-16", md: "0px" }}>
                <Text mb={{ base: "ms-2", md: "2px" }} textStyle="body2-md" color="ms-red.900">
                  7 . Which domain were you working in?*
                </Text>

                <Select
                  w={["100%", "50%", "40%", "40%"]}
                  name="currentWorkingDomain"
                  value={formValues.currentWorkingDomain || ""}
                  onChange={(event) => handleFormValuesChange("currentWorkingDomain", event.target.value)}
                >
                  <option value="" disabled selected>
                    Select your domain
                  </option>
                  {["Tech", "Non-Tech"].map((stream) => {
                    return (
                      <option key={stream} value={stream}>
                        {stream}
                      </option>
                    );
                  })}
                </Select>
              </Box> */}

              {/* designation name */}
              {/* <Box mt={{ base: "ms-16", md: "0px" }}>
                <Text mb={{ base: "ms-2", md: "2px" }} textStyle="body2-md" color="ms-red.900">
                  8. What was your designation?*
                </Text>

                <Box mt={{ base: "ms-16", md: "0px" }}>
                  <InputGroup w={["100%", "50%", "40%", "40%"]}>
                    <Input type="text" value={formValues.designation || ""} onChange={(e) => handleFormValuesChange("designation", e.target.value)} placeholder="Enter your designation" />
                  </InputGroup>
                </Box>
              </Box> */}

              {/* company name */}
              {/* <Box mt={{ base: "ms-16", md: "0px" }}>
                <Text mb={{ base: "ms-2", md: "2px" }} textStyle="body2-md" color="ms-red.900">
                  9 . What was the name of your company?*
                </Text>

                <Box mt={{ base: "ms-16", md: "0px" }}>
                  <InputGroup w={["100%", "50%", "40%", "40%"]}>
                    <Input type="text" value={formValues.companyName || ""} onChange={(e) => handleFormValuesChange("companyName", e.target.value)} placeholder="Enter your company name" />
                  </InputGroup>
                </Box>
              </Box> */}
            </Box>
          ) : null}

          {isGraduationCompleted && selectedWorkingOption === "nwns" ? (
            <Box display={"grid"} gridTemplateColumns={gridTemplateColumns ? gridTemplateColumns : { md: "repeat(1,1fr)" }} gap={{ md: "24px" }} mt={{ base: "ms-16", md: "5px" }}>
              {/* grad year and month */}
              <Box mt={{ base: "ms-16", md: "0px" }}>
                <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
                  {formValues.haveYouWorkedBefore === "yes" ? 10 : 6}. Select your graduation month & year *
                </Text>
                <DateSelect
                  showRelativeDate={false}
                  onChange={(value) => {
                    handleFormValuesChange("monthYearOfGraduation", value);
                  }}
                  value={
                    formValues.graduationMonth && formValues.graduationYear
                      ? dayjs()
                          .month(formValues.graduationMonth - 1)
                          .year(formValues.graduationYear)
                      : null
                  }
                  isInvalid={false}
                  errorMessage="Please add your month and year of graduation."
                  onClickCallback={() => {
                    //
                  }}
                  yearAndMonthOnly={true}
                  disabled={false}
                  w={["100%", "50%", "40%", "40%"]}
                  selectedOption={selectedWorkingOption}
                />
              </Box>

              {selectedWorkingOption === "nwns" && (
                <Box display={"grid"} gridTemplateColumns={gridTemplateColumns ? gridTemplateColumns : { md: "repeat(1,1fr)" }} gap={{ md: "24px" }}>
                  {/* stream dropdown * */}
                  <Box mt={"ms-16"}>
                    <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
                      {formValues.haveYouWorkedBefore === "yes" ? 11 : 7} What is your stream? *
                    </Text>
                    <Select w={["100%", "50%", "40%", "40%"]} name="stream" value={formValues.stream} onChange={(event) => handleFormValuesChange(event.target.name, event.target.value)}>
                      <option value="" disabled selected>
                        Select your stream
                      </option>
                      {streamOptions.map((stream) => {
                        return (
                          <option key={stream} value={stream}>
                            {stream}
                          </option>
                        );
                      })}
                    </Select>
                  </Box>
                  {/* college name */}
                  <Box mt={{ base: "ms-16", md: "0px" }}>
                    <Text mb={{ base: "ms-2", md: "2px" }} textStyle="body2-md" color="ms-red.900">
                      {formValues.haveYouWorkedBefore === "yes" ? 12 : 8} . What is the name of your college?*
                    </Text>

                    <Box mt={{ base: "ms-16", md: "0px" }}>
                      <InputGroup w={["100%", "50%", "40%", "40%"]}>
                        <Input type="text" value={formValues.collegeName || ""} onChange={(e) => handleFormValuesChange("collegeName", e.target.value.trim())} placeholder="Enter your college name" />
                      </InputGroup>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          ) : null}
          {selectedWorkingOption === "cs" ? (
            <Box display={"grid"} gridTemplateColumns={gridTemplateColumns ? gridTemplateColumns : { md: "repeat(1,1fr)" }} gap={{ md: "24px" }} mt={"ms-24"}>
              {/* currently studying in */}
              {/* <Box mt={{ base: "ms-16", md: "0px" }}>
                <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
                  4 . You are currently studying in?
                </Text>
                <RadioButtonGroup
                  options={[{ key: "college", value: "College" }]}
                  selectedValue={selectedEducationOption ? "school" : "college"}
                  onChange={(key) => {
                    if (setSelectedEducationOption) {
                      setSelectedEducationOption(key === "school" ? true : false);
                    }
                    setFormValues({
                      ...formValues,
                      graduationMonth: null,
                      graduationYear: null,
                      collegeName: "",
                      stream: "",
                      salaryRange: "",
                      companyName: "",
                      designation: "",
                      currentlyStudyingIn: "college",
                    });
                    handleFormValuesChange("currentlyStudyingIn", key);
                  }}
                  gridTemplateComlumns={{ md: "repeat(4,1fr)" }}
                  columnGap={"ms-4"}
                  rowGap={"ms-24"}
                  disabled={false}
                />
              </Box> */}

              {formValues.currentlyStudyingIn === "college" && (
                <Box display={"grid"} gridTemplateColumns={gridTemplateColumns ? gridTemplateColumns : { md: "repeat(1,1fr)" }} gap={{ md: "24px" }}>
                  {/* grad year and month */}
                  <Box mt={{ base: "ms-16", md: "0px" }}>
                    <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
                      4 . Select your graduation month & year *
                    </Text>
                    <DateSelect
                      showRelativeDate={false}
                      onChange={(value) => {
                        handleFormValuesChange("monthYearOfGraduation", value);
                      }}
                      value={
                        formValues.graduationMonth && formValues.graduationYear
                          ? dayjs()
                              .month(formValues.graduationMonth - 1)
                              .year(formValues.graduationYear)
                          : null
                      }
                      isInvalid={false}
                      errorMessage="Please add your month and year of graduation."
                      onClickCallback={() => {
                        //
                      }}
                      yearAndMonthOnly={true}
                      disabled={false}
                      w={["100%", "50%", "40%", "40%"]}
                      selectedOption={selectedWorkingOption}
                    />
                  </Box>
                  {/* stream dropdown * */}
                  <Box mt={"ms-16"}>
                    <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
                      5 . What is your stream? *
                    </Text>
                    <Select
                      w={["100%", "50%", "40%", "40%"]}
                      name="highestQualification"
                      value={formValues.highestQualification}
                      onChange={(event) => handleFormValuesChange(event.target.name, event.target.value)}
                    >
                      <option value="" disabled selected>
                        Select your stream
                      </option>
                      {streamOptions.map((highestQualification) => {
                        return (
                          <option key={highestQualification} value={highestQualification}>
                            {highestQualification}
                          </option>
                        );
                      })}
                    </Select>
                  </Box>

                  {/* college name */}
                  <Box mt={{ base: "ms-16", md: "0px" }}>
                    <Text mb={{ base: "ms-2", md: "2px" }} textStyle="body2-md" color="ms-red.900">
                      6 . What is the name of your college?*
                    </Text>

                    <Box mt={{ base: "ms-16", md: "0px" }}>
                      <InputGroup w={["100%", "50%", "40%", "40%"]}>
                        <Input value={formValues.collegeName || ""} type="text" onChange={(e) => handleFormValuesChange("collegeName", e.target.value.trim())} placeholder="Enter your college name" />
                      </InputGroup>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          ) : null}

          {/* {selectedWorkingOption === "cw" ? ( */}

          {/* <Box display={"grid"} gridTemplateColumns={gridTemplateColumns ? gridTemplateColumns : { md: "repeat(1,1fr)" }} gap={{ md: "24px" }}> */}
          {/* college name */}
          {/* <Box mt={{ base: "ms-16", md: "0px" }}>
                <Text mb={{ base: "ms-2", md: "2px" }} textStyle="body2-md" color="ms-red.900">
                  7 . What is the name of your college?*
                </Text>

                <Box mt={{ base: "ms-16", md: "0px" }}>
                  <InputGroup w={["100%", "50%", "40%", "40%"]}>
                    <Input value={formValues.collegeName || ""} type="text" onChange={(e) => handleFormValuesChange("collegeName", e.target.value)} placeholder="Enter your college name" />
                  </InputGroup>
                </Box>
              </Box> */}

          {/* grad year and month */}
          {/* <Box mt={{ base: "ms-16", md: "0px" }}>
                <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
                  8 . Select your graduation month & year *
                </Text>
                <DateSelect
                  showRelativeDate={false}
                  onChange={(value) => {
                    handleFormValuesChange("monthYearOfGraduation", value);
                  }}
                  value={
                    formValues.graduationMonth && formValues.graduationYear
                      ? dayjs()
                          .month(formValues.graduationMonth - 1)
                          .year(formValues.graduationYear)
                      : null
                  }
                  isInvalid={false}
                  errorMessage="Please add your month and year of graduation."
                  onClickCallback={() => {
                    //
                  }}
                  yearAndMonthOnly={true}
                  disabled={false}
                  w={["100%", "50%", "40%", "40%"]}
                  selectedOption={selectedWorkingOption}
                />
              </Box> */}
          {/* </Box> */}

          {/* ) : null} */}
        </Box>

        <Box boxShadow={"0px -8px 32px 0px rgba(0, 0, 0, 0.10);"} display={"flex"} margin={"auto"} width={"50%"} mt={"12"}>
          <Button
            isLoading={updateProfileLoading}
            width={"100%"}
            variant="primary"
            onClick={() => {
              sendTrackingEvent({
                event: "d_counselling_submit",
                eventLabel: location.pathname,
              });

              updateProfileMutate({
                other_address: {
                  ...profile?.other_address,
                  ...formValues,
                },
                date_of_birth: dateOfBirth,
                meta: {
                  hasSkippedLaunchForm: false,
                  hasFilledLaunchFormDetails: true,
                },
              });
            }}
            isDisabled={getIsDisabledValue()}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default GetUserDetailsForIITG;
