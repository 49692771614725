import Lottie from "react-lottie-player";
import { convertUrlToCDNUrl, formatDateWithOrdinal } from "../../../utils/utils";
import greenSuccess from "../../../assets/greenSuccess.json";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { ApplicationType, BatchType } from "../../../api/schemas/schema";

interface Props {
  activeBatch: BatchType;
  activeApplication: ApplicationType;
}

const JoinWhatsappCard = ({ activeBatch, activeApplication }: Props) => {
  return (
    <Box
      border={"1px solid #E5E5E5"}
      bg={"white"}
      boxShadow={"0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);"}
      rounded={"16px"}
      maxW={"602px"}
      w={"full"}
      px={{ base: "20px", md: "58px" }}
      py={{ base: "20px", md: "40px" }}
      display={"flex"}
      alignItems={"center"}
      flexDir={"column"}
      justifyContent={"center"}
    >
      <Box w={"65px"} h={"65px"}>
        <Lottie loop animationData={greenSuccess} play style={{ width: "100%" }} />
      </Box>
      <Text fontSize={{ base: "20px", md: "24px" }} mt={5} fontFamily={"Poppins"} fontWeight={700} color={"#3470E4"}>
        Congratulations!
      </Text>
      <Text fontSize={{ base: "14px", md: "16px" }} textAlign={"center"} fontFamily={"Poppins"} fontWeight={"500"} color={"#544D4F"} mt={2}>
        You&apos;re on the path to becoming a software developer.
        <br /> Join the WhatsApp group. <br /> The batch starts on{" "}
        <Text as={"span"} fontFamily={"Poppins"} fontWeight={700}>
          {" "}
          {formatDateWithOrdinal(activeBatch.start_date)}{" "}
        </Text>
        .
      </Text>
      <Flex
        bg={"#F2F6FF"}
        align={"center"}
        px={{ base: "10px", md: "18px" }}
        py={"10px"}
        justify={"center"}
        mt={{ base: "30px", md: "48px" }}
        maxW={"397px"}
        w={"full"}
        rounded={"8px"}
        gap={2}
        onClick={() => {
          window.open(activeApplication.slack_link, "_blank");
        }}
      >
        <Image
          src={convertUrlToCDNUrl("https://masai-website-images.s3.ap-south-1.amazonaws.com/image_132_e821567e9b.webp")}
          objectFit={"contain"}
          w={{ base: "45px", md: "52px" }}
          h={{ base: "42px", md: "48px" }}
          rounded={"8px"}
        />
        <Text fontSize={{ base: "12px", md: "14px" }} fontWeight={600}>
          Masai WhatsApp Invitation
        </Text>
        <Button size={"sm"} bg={"#FFFFFF"} variant={"solid"} color={"#3470E4"} ml={{ md: 2 }}>
          join Now
        </Button>
      </Flex>
    </Box>
  );
};

export default JoinWhatsappCard;
