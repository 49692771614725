import React from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import TestimonialCard from "./TestimonialCard";
import { CustomSlider } from "../../common/general";
interface Props {
  testimonialData: {
    image: string;
    feedback: string;
    name: string;
    role: string;
    company: string;
  }[];
  gridTemplateColumns?: string | { base?: string; md?: string; lg?: string; xl?: string };
  rightSidebar?: boolean;
}
const Testimonials = ({ rightSidebar, testimonialData, gridTemplateColumns }: Props) => {
  return (
    <Box mx={rightSidebar ? "0px" : "-16px"}>
      {rightSidebar ? (
        <Box>
          <SimpleGrid
            gridTemplateColumns={
              gridTemplateColumns ? gridTemplateColumns : { base: "1fr", xl: "1fr" }
            }
            rowGap={"ms-16"}
            columnGap={"ms-24"}
            mt={4}
          >
            {testimonialData.map((data, idx) => (
              <TestimonialCard {...data} key={idx} />
            ))}
          </SimpleGrid>
        </Box>
      ) : (
        <CustomSlider
          px="0px"
          hasButton={false}
          pagination={false}
          isContainer={false}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 12,
            },
            //Mobile
            320: {
              slidesPerView: 1.3,
              spaceBetween: 12,
            },
            468: {
              slidesPerView: 1.5,
              spaceBetween: 12,
            },
            //Tablet
            768: {
              spaceBetween: 22,
              slidesPerView: 2,
            },
            1124: {
              spaceBetween: 32,
              slidesPerView: 2.5,
            },
            1324: {
              spaceBetween: 32,
              slidesPerView: 3.2,
            },
            1524: {
              spaceBetween: 32,
              slidesPerView: 4,
            },
          }}
          swiperProps={{
            style: {
              padding: "8px 16px",
              maxWidth: "1440px",
              marginLeft: "0px",
              overflow: "visible",
            },
          }}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {testimonialData.map((data, idx) => (
            <TestimonialCard {...data} key={idx} />
          ))}
        </CustomSlider>
      )}
    </Box>
  );
};

export default Testimonials;
