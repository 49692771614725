import { CalendarIcon } from "@chakra-ui/icons";
import { Box, Button, Heading, Text, useBreakpointValue } from "@chakra-ui/react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";

import VideoOutline from "../../../../assets/icons/components/Others/VideoOutline";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import { AppContext } from "../../../../context/ApplicationContext";
interface Props {
  bookingStatus?: boolean;
  bookingDetails: {
    end_date_time: string;
    start_date_time: string;
    join_url: string;
  };
  setCalendlyConfirmModal: (value: boolean) => void;
}
const MasaiXInterviewCard = ({ bookingStatus, bookingDetails, setCalendlyConfirmModal }: Props) => {
  const state = useContext(AppContext)[0];
  dayjs.extend(advancedFormat);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const location = useLocation();
  return (
    <Box>
      <Heading as="h4">Schedule Interview</Heading>
      <Box
        p={{ base: "ms-16", md: "ms-24" }}
        bgColor={"ms-purple.50"}
        borderRadius={"ms-16"}
        mt={"ms-16"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Box p={"20px"} bgColor={"#fff"} borderRadius={"ms-16"}>
            <CalendarIcon boxSize={"40px"} color={"ms-purple.500"} />
          </Box>
          <Box ml={"ms-16"}>
            {bookingStatus && bookingDetails ? (
              <>
                <Text textStyle="caption">Technical Interview</Text>
                <Heading as="h4" mt={"ms-8"}>
                  {dayjs(bookingDetails.start_date_time).format("MMM Do, YYYY")}
                </Heading>
                <Text mt={"ms-4"} textStyle="body2-md" color={"ms-success"}>
                  {dayjs(bookingDetails.start_date_time).format("HH:mm A")} -{" "}
                  {dayjs(bookingDetails.end_date_time).format("HH:mm A")}
                </Text>
              </>
            ) : (
              <>
                <Heading as="h4">Technical Interview</Heading>
                <Text mt={"ms-4"} textStyle={{ base: "caption", md: "body2" }}>
                  Schedule the interview in order to complete your admission process.
                </Text>
              </>
            )}
          </Box>
        </Box>
        {!isMobile && (
          <>
            {bookingStatus ? (
              <Button
                onClick={() => {
                  sendTrackingEvent({
                    event: "mx_ib_join_interview",
                    eventLabel: location.pathname,
                  });
                  window.open(bookingDetails.join_url);
                }}
                size="lg"
                leftIcon={<VideoOutline boxSize="24px" />}
                variant={"primary"}
              >
                join interview
              </Button>
            ) : (
              <Button
                onClick={() => {
                  sendTrackingEvent({
                    event: "mx_ibp_schedule_interview",
                    eventLabel: location.pathname,
                  });
                  setCalendlyConfirmModal(true);
                }}
                size="lg"
                leftIcon={<CalendarIcon boxSize="24px" />}
                variant={"primary"}
              >
                schedule interview
              </Button>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default MasaiXInterviewCard;
