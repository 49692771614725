import React from "react";

import { Box } from "@chakra-ui/react";
import ProfileFieldCard from "./ProfileFieldCard";
import { useProfile } from "../../../api/utils/hooks/useProfile";

const Name = ({ fromOnboarding = false }: { fromOnboarding?: boolean }) => {
  const { data: profile } = useProfile();
  return (
    <Box>
      <ProfileFieldCard
        keyName="email"
        title={profile?.email || ""}
        heading="Email Address"
        fromOnboarding={fromOnboarding}
      />
    </Box>
  );
};

export default Name;
