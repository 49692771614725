import { Box, Flex, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { AppContext } from "../../context/ApplicationContext";

interface Props {
  hideText?: boolean;
}

const WelcomeBannerV2 = ({ hideText = false }: Props) => {
  const state = useContext(AppContext)[0];

  return (
    <>
      <Flex maxW="1440px" mx="auto" px={{ base: "16px", xl: "80px" }} justifyContent={"space-between"}>
        <Box>
          <Flex mb={{ base: "8px", md: "6px" }}>
            <Text fontSize={{ base: "24px", md: "32px" }} color="ms-red.900" fontWeight={600} fontFamily="inter">
              Welcome {state?.profileData?.name}!
            </Text>
          </Flex>
          {hideText ? null : (
            <Box>
              <Text textStyle={["caption", "body1"]} color="ms-grey.700">
                Take the first step by selecting a course.
              </Text>
            </Box>
          )}
        </Box>
      </Flex>

      <Box maxW="1440px" w="full" mx="auto" mt="20px" px={{ base: "16px", xl: "80px" }}>
        <Box h="1px" bg="#E5E5E5"></Box>
      </Box>
    </>
  );
};

export default WelcomeBannerV2;
