import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const Link = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M10.59 13.41C11 13.8 11 14.44 10.59 14.83C10.2 15.22 9.56001 15.22 9.17001 14.83C7.22001 12.88 7.22001 9.71 9.17001 7.76L12.71 4.22C14.66 2.27 17.83 2.27 19.78 4.22C21.73 6.17 21.73 9.34 19.78 11.29L18.29 12.78C18.3 11.96 18.17 11.14 17.89 10.36L18.36 9.88C19.54 8.71 19.54 6.81 18.36 5.64C17.19 4.46 15.29 4.46 14.12 5.64L10.59 9.17C9.41001 10.34 9.41001 12.24 10.59 13.41ZM13.41 9.17C13.8 8.78 14.44 8.78 14.83 9.17C16.78 11.12 16.78 14.29 14.83 16.24L11.29 19.78C9.34001 21.73 6.17001 21.73 4.22001 19.78C2.27001 17.83 2.27001 14.66 4.22001 12.71L5.71001 11.22C5.70001 12.04 5.83001 12.86 6.11001 13.65L5.64001 14.12C4.46001 15.29 4.46001 17.19 5.64001 18.36C6.81001 19.54 8.71001 19.54 9.88001 18.36L13.41 14.83C14.59 13.66 14.59 11.76 13.41 10.59C13 10.2 13 9.56 13.41 9.17Z"
      fill="#3470E4"
    />
  </Icon>
);

export default Link;
