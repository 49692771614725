import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Image, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import YoutubeCard from "../../msat/msat-instructions/YoutubeCard";

interface Props {
  item: {
    id: number;
    videoUrl: string;
    heading: string;
    name: string;
    designation: string;
    linkedinUrl: string;
    isIITRopar?: boolean;
  };
}

const CustomAccordianVideo = ({ item: { id, videoUrl, heading, name, designation, linkedinUrl, isIITRopar } }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [accordionIndex, setAccordionIndex] = useState<number>(0);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
    let timer: ReturnType<typeof setTimeout>;
    return (...args: Parameters<F>) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  const delayedSetAccordionIndex = debounce((index: number) => {
    setAccordionIndex(index);
  }, 200);

  return (
    <Box display="flex" position="relative" maxW={"600px"} key={id}>
      <Text
        border="6px solid"
        borderColor={"ms-purple.200"}
        zIndex={"1"}
        bgColor={"ms-purple.500"}
        mr={4}
        position="absolute"
        top="0px"
        left="-16px"
        borderRadius={"50%"}
        width={"36px"}
        height={"36px"}
        textStyle={"btn-md"}
        color={"#fff"}
        textAlign={"center"}
      >
        {id}
      </Text>

      {id !== 3 && <Box position="absolute" top="2%" left="1px" height="calc(100% + 20px)" z borderLeft="2px dashed #BBB" zIndex="0"></Box>}

      <Box pl="30px" width={"100%"}>
        <Accordion index={accordionIndex} allowToggle>
          <AccordionItem
            boxShadow={"0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)"}
            bgColor={isOpen ? "ms-purple.500" : "#fff"}
            borderRadius={"8px"}
            _focus={{
              bgColor: isOpen ? "ms-purple.500" : "#fff",
            }}
          >
            {({ isExpanded }) => {
              setIsExpanded(isExpanded);
              return (
                <>
                  <AccordionButton
                    _focus={{
                      outline: "none",
                    }}
                    onClick={() => delayedSetAccordionIndex(accordionIndex === 0 ? -1 : 0)}
                  >
                    <Box flex="1" textAlign="left" pt={1}>
                      <Text textStyle={"h5"} color={"#fff"}>
                        {heading}
                      </Text>
                      <Text textStyle={"body1"} fontWeight={700} color={"#fff"} display={"flex"} alignItems={"center"} gap={"7px"}>
                        {!isIITRopar ? "By Prof." : ""} {name}
                        {linkedinUrl && (
                          <Image
                            src="/img/iit-course/link.svg"
                            color={"#FFDB66"}
                            onClick={() => {
                              window.open(linkedinUrl, "_blank");
                            }}
                          />
                        )}
                      </Text>
                      {designation && (
                        <Text color={"#fff"} fontSize={"14px"}>
                          ({designation})
                        </Text>
                      )}
                    </Box>
                    <AccordionIcon color={isOpen ? "#fff" : "#000"} />
                  </AccordionButton>
                  <AccordionPanel px={"16px"}>
                    <Box>
                      <YoutubeCard baseHeight="208px" mdHeight={"320px"} url={videoUrl} padding="0px" eventName="d_msat_video" controls={true} />
                    </Box>
                  </AccordionPanel>
                </>
              );
            }}
          </AccordionItem>
        </Accordion>
      </Box>
    </Box>
  );
};

export default CustomAccordianVideo;
