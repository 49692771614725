import { Box, Button, Heading, Text, Flex } from "@chakra-ui/react";
import React from "react";
import useCleverTap, { CleverTapEvents, ModalNames } from "../../../clevertap/useCleverTap";
import { InputCustom } from "../general";

interface Props {
  onReferralModalClose: (btnType: "skip" | "submit", referral_code?: string) => void;
  referralErrorMessage: string | null;
  setReferralErrorMessage: (message: string) => void;
}
const ReferralCodeModalContent = ({
  onReferralModalClose,
  referralErrorMessage,
  setReferralErrorMessage,
}: Props) => {
  const [referralCode, setReferralCode] = React.useState<string | undefined>();
  const [isSubmitClicked, setIsSubmitClicked] = React.useState(false);
  const clevertap = useCleverTap()[0];
  React.useEffect(() => {
    setReferralErrorMessage("");
  }, [referralCode]);
  React.useEffect((): void => {
    clevertap.fireEvent(CleverTapEvents.viewed_modal, {
      modal_name: ModalNames.haveReferralCode,
    });
  }, []);

  return (
    <Box pt={"ms-16"} px={"ms-8"}>
      <Box textAlign={"center"}>
        <Heading as="h3">Congratulations!</Heading>
        <Text mt={"ms-8"} textStyle="body2">
          You have taken the first step towards
          <Box d={{ base: "inline", md: "none" }}>{<br />}</Box> building your career
        </Text>
      </Box>

      <Box mt={"30px"}>
        <InputCustom
          label="Have a referral code?"
          placeholder="Enter referral code here"
          getValueCallback={(value) => {
            setReferralCode(value);
          }}
          onClick={() => {
            clevertap.fireEvent(CleverTapEvents.click_input_field, {
              modal_name: ModalNames.haveReferralCode,
              field_name: "referral_code",
              field_type: "text",
              button_type: "primary",
            });
          }}
        />
        {referralErrorMessage && (
          <Text mt={"ms-8"} textStyle="body2" color="ms-error">
            {referralErrorMessage}
          </Text>
        )}
      </Box>
      <Flex justifyContent={"flex-end"} mt={"30px"}>
        <Button
          size={"lg"}
          variant="secondary"
          onClick={() => {
            clevertap.fireEvent(CleverTapEvents.click_skip, {
              modal_name: ModalNames.haveReferralCode,
              button_text: "skip",
              button_type: "secondary",
            });
            onReferralModalClose("skip");
          }}
        >
          Skip
        </Button>
        <Button
          isDisabled={!referralCode}
          ml={"ms-16"}
          size={"lg"}
          variant="primary"
          onClick={() => {
            if (!referralCode) {
              return;
            }
            clevertap.fireEvent(CleverTapEvents.click_submit, {
              modal_name: ModalNames.haveReferralCode,
              button_text: "submit",
              button_type: "primary",
            });
            setIsSubmitClicked(true);
            onReferralModalClose("submit", referralCode);
          }}
        >
          Submit
        </Button>
      </Flex>
    </Box>
  );
};

export default ReferralCodeModalContent;
