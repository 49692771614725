import { Flex, Box } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { Close } from "../../../../assets/icons/components";
import useCleverTap, { CleverTapEvents, ScreenNames } from "../../../../clevertap/useCleverTap";

interface Props {
  onClick: () => void;
  name: string;
  content: JSX.Element | JSX.Element[];
  color: string;
  screen_name: ScreenNames;
  id: string;
  showClose?: boolean;
}

const GeneralBanner = ({ name, color, content, id, screen_name, showClose = false }: Props) => {
  const [hide, setHide] = useState<boolean>(true);
  const clevertap = useCleverTap()[0];

  useEffect(() => {
    setHide(JSON.parse(localStorage.getItem(id) as string));
  }, []);

  const handleClose = () => {
    clevertap.fireEvent(CleverTapEvents.click_close_banner, {
      screen_name: screen_name,
      banner_type: name,
    });
    setHide(true);
    localStorage.setItem(id, JSON.stringify(true));
  };

  return (
    <>
      <Flex
        backgroundColor={color}
        d={hide ? "none" : "flex"}
        px={"ms-24"}
        py={"ms-16"}
        w="full"
        justifyContent={"space-between"}
      >
        <Box w="full">{content}</Box>
        {showClose && (
          <Box as="button" onClick={handleClose} cursor="pointer">
            <Close boxSize="24px" color="ms-grey.600" />
          </Box>
        )}
      </Flex>
    </>
  );
};

export default GeneralBanner;
