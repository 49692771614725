import { useQuery } from "react-query";

import { getActiveApplication } from "../api/v2/application";
import { ApplicationType } from "../../schemas/schema";

export function useActiveApplication(courseId?: string) {
  return useQuery({
    queryKey: ["getActiveApplication", courseId],
    queryFn: async () => (await getActiveApplication()) as ApplicationType,
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,

    refetchOnWindowFocus: false,
    enabled: true,
  });
}
