import { Box, Image, Text } from "@chakra-ui/react";
import { OnboardingGreenCheck } from "../../../assets/images";

const RegisterationPaymentCompleteBanner = ({ isIITRopar, isIITMandi }: { isIITRopar: boolean; isIITMandi: boolean }) => {
  return (
    <Box rounded={"16px"} bg={"#fff"} w={"full"} p={4} display={"flex"} gap={3}>
      <Box flexShrink={0} width={"22px"} height={"22px"}>
        <Image width={"22px"} height={"22px"} src={OnboardingGreenCheck} mt={1} />
      </Box>
      <Box display={"flex"} flexDir={"column"} gap={2}>
        <Text textStyle={"h5"} maxW={"700px"}>
          Registration Payment Complete! You&apos;re now officially part of the esteemed IIT
          {isIITRopar ? " Ropar" : isIITMandi ? " Mandi" : " Guwahati"} family.
        </Text>
        <Text textStyle={"body1-md"} color={"#6C6768"}>
          {isIITRopar ? "Watch orientation video" : "Clear your doubts by exploring our videos."}
        </Text>
      </Box>
    </Box>
  );
};

export default RegisterationPaymentCompleteBanner;
