import { Box, Heading } from "@chakra-ui/react";
import React from "react";

interface Props {
  title: string;
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

// TODO: See if Box hr will work or you need to provide a border to the design

const TitleScreen = ({ title, as = "h3" }: Props) => {
  return (
    <Box px={["ms-16", "ms-24"]} pb={["ms-8", "ms-24"]}>
      <Heading as={as}>{title}</Heading>
    </Box>
  );
};

export default TitleScreen;
