import { ProfileAssessment } from "../api/schemas/schema";
import { OnbaordingState } from "../context/OnbaordingContext";
import { PAPCourses } from "../pages/V2/Onboarding";

interface StructuredCourseData {
  iconUrl: string;
  courseName: string;
  applicationDeadline?: string;
  currentStepName: string;
  currentStepNumber: number;
  totalSteps: number;
}

const courseDataMap: Record<string, Omit<StructuredCourseData, "applicationDeadline" | "currentStepName">> = {
  "Software Development": {
    iconUrl: "/img/revamp/courses/sd.svg",
    courseName: "Software Development",
    currentStepNumber: 1,
    totalSteps: 2,
  },
  "Data Analytics": {
    iconUrl: "/img/revamp/courses/da.svg",
    courseName: "Data Analytics",
    currentStepNumber: 1,
    totalSteps: 3,
  },
  "IIT Guwahti": {
    iconUrl: "/img/revamp/courses/iitg.svg",
    courseName: "IIT Guwahti",
    currentStepNumber: 1,
    totalSteps: 4,
  },
  "IIT Mandi": {
    iconUrl: "/img/revamp/courses/iitm.svg",
    courseName: "IIT Mandi",
    currentStepNumber: 1,
    totalSteps: 4,
  },
  "IIT Ropar": {
    iconUrl: "/img/revamp/courses/iitr.svg",
    courseName: "IIT Ropar",
    currentStepNumber: 1,
    totalSteps: 4,
  },
};

export const getCourseStructuredData = (courseName: string, deadline: string, currentStepName: string): StructuredCourseData => {
  const courseData = courseDataMap[courseName] || courseDataMap["Software Development"];

  return {
    ...courseData,
    applicationDeadline: deadline,
    currentStepName: currentStepName,
  };
};

export const handleBtnDisabled = (selectedCourse: PAPCourses, currentStep: number, data: OnbaordingState): boolean => {
  if (selectedCourse === PAPCourses.SD) {
    switch (currentStep) {
      case 1: {
        if (!data.eligibilityData.dateOfBirth || !data.eligibilityData.hasGoodInternet || !data.eligibilityData.highestQualification) {
          return true;
        }
        if (typeof data.eligibilityData.is_working !== "boolean" && !data.eligibilityData.current_occupation_status) {
          return true;
        }
        return false;
      }
      default: {
        return false;
      }
    }
  } else if (selectedCourse === PAPCourses.DA) {
    switch (currentStep) {
      case 2: {
        if (!data.eligibilityData.dateOfBirth || !data.eligibilityData.hasGoodInternet || !data.eligibilityData.highestQualification) {
          return true;
        }
        if (typeof data.eligibilityData.is_working !== "boolean" && !data.eligibilityData.current_occupation_status) {
          return true;
        }
        return false;
      }
    }
  }

  return false;
};

interface CreateAssFuntion {
  for_practice: boolean;
  course_preference: number;
}

export const handleDAStartMSAT = (bestAssessment: ProfileAssessment, createAssessmentMutate: (args: CreateAssFuntion) => void, createApplicationMutate: (args: { course_id: string }) => void) => {
  const isEligibleForSecondMSAT =
    bestAssessment &&
    bestAssessment?.attempt_count === 1 &&
    bestAssessment?.course_preference === 14 &&
    ["ENDED", "GRADED"].includes(bestAssessment?.status?.toUpperCase()) &&
    !bestAssessment?.eligible_courses?.includes(14);

  if (!bestAssessment || isEligibleForSecondMSAT) {
    createAssessmentMutate({
      for_practice: false,
      course_preference: 14,
    });
  } else if (bestAssessment && !["ENDED", "GRADED"].includes(bestAssessment?.status?.toUpperCase())) {
    window.location.href = bestAssessment.test_url;
  } else {
    createApplicationMutate({ course_id: "14" });
  }
};

export const shouldShowAlumniConnectBanner = (currentStep: number, selectedCourse: PAPCourses): boolean => {
  if (selectedCourse === PAPCourses.SD) {
    switch (currentStep) {
      case 1: {
        return false;
      }
    }
    return true;
  } else if (selectedCourse === PAPCourses.DA) {
    switch (currentStep) {
      case 1: {
        return true;
      }
    }
    return false;
  }
  return false;
};
