import React from "react";
import PANOcr from "./PANOcr";
import { Box } from "@chakra-ui/react";
import AadhaarOcr from "../V2/Onboarding/AadhaarOcr";
import { ProfileType } from "../../api/schemas/schema";
import { IITGuwahatiProfileDetailInputInterface } from "../../constants/IIt-course/iit-form-onboarding";

interface Props {
  isIITGOnbaording?: boolean;
  isCoApplicantInfo?: boolean;
  profile?: ProfileType;
  isAadhaarVerified?: boolean;
  profileDetailForm: IITGuwahatiProfileDetailInputInterface;
  setProfileDetailForm: (data: IITGuwahatiProfileDetailInputInterface) => void;
}

const IITOnboardingAadharPanForm = ({ isIITGOnbaording, isCoApplicantInfo, profile, isAadhaarVerified, profileDetailForm, setProfileDetailForm }: Props) => {
  const showPanCard = !profile?.aadhaar_verified;
  return (
    <Box>
      {!isAadhaarVerified ? (
        <Box mb={isIITGOnbaording ? "4px" : "34px"} display={"flex"} flexDir={"column"} gap={2} justifyContent={"center"}>
          <AadhaarOcr setProfileDetailForm={setProfileDetailForm} parentFlexDir="row" profileDetailForm={profileDetailForm as IITGuwahatiProfileDetailInputInterface} />
        </Box>
      ) : null}
      {showPanCard ? (
        <Box pt={{ md: isIITGOnbaording ? "12px" : "28px" }} borderTop={{ md: isIITGOnbaording ? "" : "1px solid #D9D9D9" }} display={"flex"} flexDir={"column"} gap={2} justifyContent={"center"}>
          <PANOcr setProfileDetailForm={setProfileDetailForm} isIITGOnbaording={isIITGOnbaording} profileDetailForm={profileDetailForm as IITGuwahatiProfileDetailInputInterface} />
        </Box>
      ) : null}
    </Box>
  );
};

export default IITOnboardingAadharPanForm;
