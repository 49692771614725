import { Box, Text, Flex, Image } from "@chakra-ui/react";
import { coursesList, iitCoursesList } from "../../../constants";
import useSnackBar from "../general/SnackBar";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { AppContext, ApplicationActionType } from "../../../context/ApplicationContext";
import { useMutation } from "react-query";
import { updateProfile } from "../../../api/utils/api/v2/profile";
import { GetProfileTypeV2, ProgramProfileType } from "../../../api/schemas/schema";
import { axiosErrorHandler } from "../../../api/utils/error";
import { ModalTemplateV2 } from "../general";

const CourseDropDown = () => {
  const snackbar = useSnackBar();
  const history = useHistory();
  const [state, dispatch] = useContext(AppContext);

  const { mutate: updateProfileMutate, isLoading: updateProfileLoading } = useMutation(updateProfile, {
    onSuccess: (data) => {
      dispatch({
        type: ApplicationActionType.SET_PROFILE_V2,
        payload: {
          profileDataV2: data as GetProfileTypeV2,
        },
      });
      dispatch({
        type: ApplicationActionType.SET_COURSE_PREFERENCE,
        payload: {
          coursePreference: data?.program_profile_type as ProgramProfileType,
        },
      });
      const courseLinkUrl = localStorage.getItem("courseLinkUrl");
      if (courseLinkUrl) {
        history.push(courseLinkUrl);
      }
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const handleCourseRedirection = (courseType: string, courseLinkUrl: string) => {
    localStorage.setItem("courseLinkUrl", courseLinkUrl);
    if (courseType === "MASAI") {
      if (state && state.profileDataV2?.program_profile_type === "MASAI") {
        history.push(courseLinkUrl);
      } else {
        updateProfileMutate({
          program_profile_type: courseType as ProgramProfileType,
        });
      }
    } else {
      if (courseType === state.profileDataV2?.program_profile_type) {
        history.push(courseLinkUrl);
      } else {
        updateProfileMutate({
          program_profile_type: courseType as ProgramProfileType,
        });
      }
    }
  };

  return (
    <>
      <Flex gap={{ base: "30px", md: "80px" }} flexDir={{ base: "column", md: "row" }} px={{ base: "4px", md: "36px" }} py={{ base: "6px", md: "16px" }}>
        <Flex direction="column" gap={{ base: 3, md: 5 }}>
          <Box>
            <Text fontWeight={600} fontSize="20px">
              Masai Courses
            </Text>
          </Box>
          <Box w="full" h="2px" bg="linear-gradient(90deg, #BE3A47 0%, #FFF 100%)" />
          <Flex gap={{ base: 2, md: 5 }} direction="column">
            {coursesList.map((course, index) => (
              <Flex
                onClick={() => handleCourseRedirection(course.courseType, course.courseLink)}
                gap={2}
                key={index}
                align="center"
                minW={{ base: "auto", md: "300px" }}
                _hover={{ bg: "whitesmoke" }}
                px={"10px"}
                py={2}
                rounded={"8px"}
              >
                <Flex align="center" justify="center" bg="#F7F7FF" w="64px" h="64px" p={2} rounded="110px" border="1px solid #F3F2F2">
                  <Image src={course.iconUrl} objectFit="contain" w="full" h="full" />
                </Flex>
                <Text fontWeight={600} fontSize={{ base: "14px", md: "18px" }} color="#0A0103">
                  {course.title}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>

        <Flex direction="column" gap={{ base: 3, md: 5 }}>
          <Box>
            <Text fontWeight={600} fontSize="20px">
              IIT for All Courses
            </Text>
          </Box>
          <Box w="full" h="2px" bg="linear-gradient(90deg, #6361DF 1.45%, rgba(254, 255, 254, 0.31) 100.09%, rgba(114, 97, 223, 0.31) 100.1%)" />
          <Flex gap={{ base: 2, md: 6 }} direction="column">
            {iitCoursesList.map((course, index) => (
              <Flex
                onClick={() => handleCourseRedirection(course.courseType, course.courseLink)}
                gap={3}
                key={index}
                align="center"
                minW={{ base: "auto", md: "300px" }}
                _hover={{ bg: "whitesmoke" }}
                px={"10px"}
                py={2}
                rounded={"8px"}
              >
                <Flex align="center" justify="center">
                  <Image src={course.iconUrl} objectFit="contain" w="64px" h="64px" />
                </Flex>
                <Box>
                  <Text fontWeight={600} fontSize={{ base: "14px", md: "18px" }} color="#0A0103">
                    {course.title}
                  </Text>
                  <Text fontWeight={400} fontSize="12px" color="#544D4F" maxW={{ base: "310px", md: "auto" }} wordBreak={"break-word"}>
                    {course.description}
                  </Text>
                </Box>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>

      <ModalTemplateV2
        closeOnOverlayClick={false}
        isOpen={updateProfileLoading}
        closeOnEsc={false}
        hideIconWithConfetti={true}
        showConfetti={false}
        onClose={() => {
          //
        }}
        waiting={true}
        text={`Please wait as we enhance your experience.`}
      />
    </>
  );
};

export default CourseDropDown;
