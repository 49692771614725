import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
  backgroundColor?: string;
  borderRadius?: string;
}
export const AccountOutline = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M12 4.00002C13.0609 4.00002 14.0783 4.42145 14.8284 5.17159C15.5786 5.92174 16 6.93916 16 8.00002C16 9.06089 15.5786 10.0783 14.8284 10.8284C14.0783 11.5786 13.0609 12 12 12C10.9391 12 9.92172 11.5786 9.17157 10.8284C8.42143 10.0783 8 9.06089 8 8.00002C8 6.93916 8.42143 5.92174 9.17157 5.17159C9.92172 4.42145 10.9391 4.00002 12 4.00002ZM12 6.00002C11.4696 6.00002 10.9609 6.21073 10.5858 6.58581C10.2107 6.96088 10 7.46959 10 8.00002C10 8.53045 10.2107 9.03916 10.5858 9.41423C10.9609 9.78931 11.4696 10 12 10C12.5304 10 13.0391 9.78931 13.4142 9.41423C13.7893 9.03916 14 8.53045 14 8.00002C14 7.46959 13.7893 6.96088 13.4142 6.58581C13.0391 6.21073 12.5304 6.00002 12 6.00002ZM12 13C14.67 13 20 14.33 20 17V20H4V17C4 14.33 9.33 13 12 13ZM12 14.9C9.03 14.9 5.9 16.36 5.9 17V18.1H18.1V17C18.1 16.36 14.97 14.9 12 14.9Z"
      fill="currentColor"
    />
  </Icon>
);

export default AccountOutline;
