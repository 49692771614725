import { Spinner, Box, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useGetHiresOfTheWeek } from "../../api/utils/hooks/levelup/useGetHiresOfTheWeek";
import { GetHiresOfTheWeekAPIResponse } from "../../api/schemas/schema";

const HiresOfTheWeek = () => {
  const [data, setData] = useState<GetHiresOfTheWeekAPIResponse["hires"]>([]);
  const { isLoading } = useGetHiresOfTheWeek((userData: GetHiresOfTheWeekAPIResponse) => {
    setData(userData?.hires);
  });

  const stringAvatar = (name: string): string => {
    if (!name) return "";
    const strgs = name.split(" ");
    return `${strgs.length > 1 ? strgs[0][0] + strgs[1][0] : strgs[0][0]}`;
  };

  useEffect(() => {
    if (isLoading || data?.length === 0) {
      return;
    }
    const interval = setInterval(() => {
      const newData = [...data?.slice(1, data.length), data[0]];
      setTimeout(() => {
        setData(newData);
      }, 300);
    }, 5000);
    return () => clearInterval(interval);
  }, [data]);

  if (isLoading) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <Spinner />
      </Flex>
    );
  }

  return data?.length > 1 ? (
    <Box w="full" position="relative" maxW={{ md: "330px" }} isolation="isolate">
      <Text color={useColorModeValue("#1F3343", "#CDF")} fontSize="20px" fontWeight="600" lineHeight="24px" fontFamily="Inter">
        Hires of the Week
      </Text>

      <Box display="flex" position="relative" py={5} d="flex" flexDirection="column" minH="163px">
        {data?.map((hire, index) => (
          <Box
            key={hire.id}
            zIndex={-index}
            mt={`${12 * index}px`}
            transform={`scale(${1 - index / 10})`}
            visibility={index > 3 ? "hidden" : "visible"}
            w="full"
            style={{
              transitionProperty: "all",
              transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
              transitionDuration: "150ms",
            }}
            position={index === 0 ? "relative" : "absolute"}
            rounded="12px"
            p={6}
            bg={useColorModeValue("#FFF", "#1F2A37")}
            border="1px solid"
            borderColor={useColorModeValue(index === 0 ? "#EBEBF0" : "#8F90A6", "#555770")}
            boxShadow="0px 4px 20px 10px rgba(0, 0, 0, 0.25)"
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Flex gap="10px" alignItems="center">
                <Box w="48px" h="48px" minW="48px" rounded="full" overflow="hidden" mx="auto" bg="gray.600" d="flex" alignItems="center" justifyContent="center">
                  {hire.profileImageUrl ? (
                    <Image src={hire.profileImageUrl} alt="User Profile" rounded="full" h="full" w="full" objectFit="cover" />
                  ) : (
                    <Text textAlign="center" mx="auto" fontSize="xl" color="white" w="full" h="full">
                      {stringAvatar(hire.name?.trim().toUpperCase() || "")}
                    </Text>
                  )}
                </Box>
                <Text color={useColorModeValue("#1F3343", "#EBEBF0")} fontSize="base" fontWeight="400">
                  {hire.name} got hired at{" "}
                  <Box w="20px" h="20px" rounded="full" overflow="hidden" mx="auto" d="inline-flex" alignItems="center" justifyContent="center" bg={useColorModeValue("gray.600", "gray.600")}>
                    {hire.companyImageUrl ? (
                      <Image alt="Company Logo" src={hire.companyImageUrl} rounded="full" h="full" w="full" objectFit="cover" />
                    ) : (
                      <Text textAlign="center" mx="auto" fontSize="base" color="white">
                        {stringAvatar(hire.companyName?.trim().toUpperCase() || "")}
                      </Text>
                    )}
                  </Box>{" "}
                  {hire.companyName}
                </Text>
              </Flex>
              <Image src="/img/revamp/handClapping.svg" h="32px" w="32px" />
            </Flex>
          </Box>
        ))}
      </Box>
    </Box>
  ) : null;
};

export default HiresOfTheWeek;
