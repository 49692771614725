import { Avatar, Box, Flex, Text, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import useSnackBar from "../../common/general/SnackBar";
import { useLocation } from "react-router-dom";
import { getProfileImagePresignedUrl, updateProfile } from "../../../api/utils/api/v2/profile";
import { useMutation } from "react-query";
import { GetS3PresignedUrlToUploadDPType } from "../../../api/schemas/schema";
import { axiosErrorHandler } from "../../../api/utils/error";
import axios from "axios";
import { AccountOutline } from "../../../assets/icons/components";
import { useProfile } from "../../../api/utils/hooks/useProfile";

export const ProfileImage = () => {
  const fileTypes = ["JPEG", "PNG", "JPG"];
  const [errorMessage, setErrorMessage] = useState("");
  const { data: profile, refetch: refetchProfile } = useProfile();
  const snackbar = useSnackBar();
  const onTypeError = () => {
    setErrorMessage("Please upload only image files of type JPEG, PNG or JPG");
  };
  const onSizeError = () => {
    setErrorMessage("Image size should be less than equal to 50MB");
  };
  const location = useLocation();
  const [profilImage, setProfileImage] = useState<File>();
  const [profileImageUrl, setProfileImageUrl] = useState<string>();
  const [uploadingImage, setUploadingImage] = useState<boolean>(false);

  const { mutate: updateProfileMutate, isLoading: updateProfileLoading } = useMutation(
    updateProfile,
    {
      onSuccess: (data) => {
        refetchProfile();
      },
      onError: (error) => {
        const e = axiosErrorHandler(error);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("Something went wrong");
        }
      },
    }
  );

  const { mutate: presignedUrlMutate, isLoading: setPresignedUrlLoading } = useMutation(
    getProfileImagePresignedUrl,
    {
      onSuccess: async (data: GetS3PresignedUrlToUploadDPType) => {
        const imageData = profilImage;
        setUploadingImage(true);
        const presigned_url = data.presigned_url;
        console.log("imageData-----", imageData);
        try {
          await axios.put(presigned_url, imageData, {
            headers: {
              "Content-Type": "image/png",
            },
          });
          const imageUrl = presigned_url.split("?")[0];
          setProfileImageUrl(imageUrl);
          // update patch profile
          updateProfileMutate({ dp_url: imageUrl });
          setUploadingImage(false);
        } catch (error) {
          setUploadingImage(false);
          console.error("Error uploading image:", error);
        }
      },
      onError: (error) => {
        const e = axiosErrorHandler(error);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("Something went wrong");
        }
      },
    }
  );

  useEffect(() => {
    if (profilImage) {
      presignedUrlMutate();
    }
  }, [profilImage]);
  return (
    <Box>
      <FileUploader
        handleChange={(file: File) => {
          setProfileImage(file);
          setErrorMessage("");
          console.log("file", file);
          setProfileImage(file);
        }}
        name="file"
        types={fileTypes}
        maxSize={50}
        onTypeError={onTypeError}
        onSizeError={onSizeError}
        position="relative"
      >
        <Box cursor="pointer">
          {profileImageUrl || profile?.dp_url ? (
            <Image boxSize={"100px"} borderRadius="50%" src={profileImageUrl || profile?.dp_url} />
          ) : (
            <Box>
              <Avatar
                size="profile"
                bg="ms-purple.500"
                color="white"
                name={profile?.name}
                fontWeight="bold"
                // src={imgSrc}
                icon={<AccountOutline boxSize="6" />}
              />
            </Box>
          )}
          <Image
            boxSize={"28px"}
            bottom="0"
            right="0"
            position={"absolute"}
            src="/img/profile-image.svg"
          />
        </Box>
        <Text mt={"ms-8"} textStyle={"body2"} color={"ms-error"}>
          {errorMessage}
        </Text>
      </FileUploader>
    </Box>
  );
};
