import {
  Box,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  Skeleton,
  SkeletonText,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce/lib";
import { axiosErrorHandler } from "../../api/utils/error";
import useSnackBar from "../../components/common/general/SnackBar";
import { AppContext } from "../../context/ApplicationContext";
// import useApplicationHook from "../../hooks/useApplicationHook";
import { useCalendly } from "../../hooks/useCalendly";
import useCleverTap, {
  CleverTapEvents,
  ScreenNames,
  ModalNames,
} from "../../clevertap/useCleverTap";

const InterviewBookingModal = ({
  type,
  onClose,
  open,
  onSuccess,
}: {
  type: "onboarding" | "counselling";
  onClose: () => void;
  onSuccess: () => void;
  open: boolean;
}) => {
  const [calendlyURL, setCalendlyURL] = useState<string | null>();
  const calendly = useCalendly();
  const snackbar = useSnackBar();
  const [state] = useContext(AppContext);
  const [listenerAdded, setListenerAdded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const isMounted = useRef<boolean>(false);
  const clevertap = useCleverTap()[0];

  const generateOneTimeLink = async () => {
    try {
      const url =
        type === "onboarding"
          ? await calendly.generateOTLOnboarding()
          : await calendly.generateOTLCounselling();
      console.log(`found ${url}`);
      if (!url) throw new Error("Invalid URL");
      return url;
    } catch (err) {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
      onClose();
    }
  };

  useEffect((): void => {
    clevertap.fireEvent(CleverTapEvents.viewed_modal, {
      modal_name: ModalNames[`${type}Calendly`],
    });
  }, []);

  /** Injects the message listener for the iframe*/
  useEffect((): (() => void) => {
    const listener = (e: MessageEvent) => {
      if (e.data.event && e.data.event.indexOf("calendly") === 0 && e.data.payload.event) {
        createInterviewBooking(e.data.payload.event.uri);
      }
    };
    if (!listenerAdded) {
      window.addEventListener("message", listener);
      setListenerAdded(true);
    }
    return () => {
      // window.removeEventListener("message",listener)
    };
  }, [listenerAdded]);

  const createInterviewBooking = useDebouncedCallback(async (uri: string) => {
    try {
      console.log(`called`);
      const sessions =
        type === "onboarding"
          ? await calendly.createOnboardingInterview(uri)
          : await calendly.createCounsellingInterview(uri);
      if (!sessions) {
        snackbar.error("Something went wrong! Try again later");
        onClose();
        onSuccess();
      } else {
        snackbar.success("Session booked successfully");
        onClose();
        onSuccess();
      }
    } catch (err) {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    }
  }, 200);

  useEffect(() => {
    let head: HTMLHeadElement | null;
    let script: HTMLScriptElement;
    const date = Date.now().toString();
    (async (): Promise<void> => {
      if (!calendlyURL && open) {
        await setCalendlyURL(await generateOneTimeLink());
        head = document.querySelector("head");
        script = document.createElement("script");
        script.setAttribute("id", `${date}`);
        script.setAttribute("src", "https://assets.calendly.com/assets/external/widget.js");
        if (head) {
          head.appendChild(script);
        }
        script.addEventListener("load", () => {
          setTimeout(() => setLoading(false), 1500);
        });
      }
    })();
    return () => {
      document.getElementById(date)?.remove();
    };
  }, [calendlyURL, open]);
  const name = state.profileData?.name;
  const email = state.profileData?.email;

  return (
    <Modal
      isOpen={open}
      onClose={() => {
        onClose();
        clevertap.fireEvent(CleverTapEvents.click_close_modal, {
          screen_name: ScreenNames.onboarding,
          modal_name: ModalNames[`${type}Calendly`],
        });
      }}
    >
      <ModalOverlay />
      <ModalContent m="1rem" mt="2rem" borderRadius="ms-16" p="1rem" maxW="fit-content" h="auto">
        <Heading as="h3">Need Assistance? Schedule a call with our Admissions Expert.</Heading>
        <Heading my="0.5rem" as="h6">
          Select a Date & Time
        </Heading>
        <Box overflow="scroll" h={{ base: "25rem", md: "50rem" }}>
          {loading && (
            <>
              <Skeleton h="5rem" my="1rem" isLoaded={!loading} />
              <Skeleton h="5rem" my="1rem" isLoaded={!loading} />
              <SkeletonText my="1rem" isLoaded={!loading} />
              <Skeleton my="1rem" h="1rem" isLoaded={!loading} />
            </>
          )}
          <Box
            h="full"
            w="full"
            as="div"
            className="calendly-inline-widget"
            data-url={calendlyURL + `?name=${name}&email=${email}`}
          ></Box>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default InterviewBookingModal;
