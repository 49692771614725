import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { ScoreCard } from "../../../components/cards/ScoreCard";

interface Props {
  scoreDetailsData?: {
    id: number;
    title?: string;
    status?: string;
    icon?: JSX.Element;
    marksObtained?: string;
    totalMarks?: number;
  }[];
  subtext?: string;
  showAccordian?: boolean;
}
const ScoreDetails = ({ scoreDetailsData, subtext, showAccordian }: Props) => {
  return (
    <>
      {showAccordian ? (
        <Accordion ml={"-16px"} allowMultiple>
          <AccordionItem border={"none"}>
            <Box mt={"ms-24"}>
              <AccordionButton>
                <Box>
                  <Heading as="h4">Score Details</Heading>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <Box>
                  <Text mt={"ms-4"} textStyle={"body2"}>
                    {subtext || `Here are your score details`}
                  </Text>
                  <Box
                    mt={"ms-24"}
                    display={"grid"}
                    gridTemplateColumns={{
                      base: "repeat(1,1fr)",
                      md: "repeat(2,1fr)",
                      xl: "repeat(3,1fr)",
                    }}
                    rowGap={"ms-16"}
                    columnGap={"ms-16"}
                  >
                    {scoreDetailsData &&
                      scoreDetailsData.map((scoreDetail, i) => (
                        <Box key={i} mr={{ base: "0", xl: "34px" }}>
                          <ScoreCard isNewMsat={true} scoreDetail={scoreDetail} />
                        </Box>
                      ))}
                  </Box>
                </Box>
              </AccordionPanel>
            </Box>
          </AccordionItem>
        </Accordion>
      ) : (
        <Box mt={"ms-24"}>
          <Heading as="h4">Score Details</Heading>
          <Box>
            <Text mt={"ms-4"} textStyle={"body2"}>
              {subtext || `Here are your score details`}
            </Text>
            <Box
              mt={"ms-24"}
              display={"grid"}
              rowGap={"ms-16"}
              gridTemplateColumns={{ base: "repeat(1,1fr)", md: "repeat(3,1fr)" }}
              columnGap={"ms-16"}
            >
              {scoreDetailsData &&
                scoreDetailsData.map((scoreDetail, i) => (
                  <Box key={i} mr={{ base: "0", xl: "34px" }}>
                    <ScoreCard isNewMsat={true} scoreDetail={scoreDetail} />
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ScoreDetails;
