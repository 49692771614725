import { useContext } from "react";
import { isCourseProgramsIntersectsProfilePrograms } from "../../../components/common/utils/isProgramPresent";
import { AppContext } from "../../../context/ApplicationContext";
import CapPaymentSuccess from "./CapProgram/CapPaymentSuccess";
import ScholarPaymentSuccess from "./ScholarProgram/ScholarPaymentSuccess";

export default function PaymentSuccess() {
  const state = useContext(AppContext)[0];
  return (
    <>
      {state.isScholar &&
      isCourseProgramsIntersectsProfilePrograms(
        state.applicationData?.batch_campus.batch?.course?.course_programs,
        [
          {
            id: 1,
            name: "Scholar",
          },
        ]
      ) ? (
        <ScholarPaymentSuccess />
      ) : (
        <CapPaymentSuccess />
      )}
    </>
  );
}
