import JoinCommunity from "./JoinCommunity";
import { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import HiresOfTheWeek from "./HiresOfTheWeek";
import { useHistory } from "react-router-dom";
import WelcomeBannerV2 from "./WelcomeBannerV2";
import ConnectWithAlumni from "./ConnectWithAlumni";
import EventsForYou from "./activitiesV2/EventsForYou";
import { PAPCourses, ScholorShipCourses } from "../../pages/V2/Onboarding";
import { ACTIVITY_TYPE } from "../../pages/V2/Dashboard";
import ApplicationProgressCard from "./ApplicationProgressCard";
import ScholarshipsComponent from "./scholarships/ScholarshipsComponent";
import ActivityCardContainerV2 from "./activitiesV2/ActivityCardContainerV2";
import { CourseDataInterface, daCourseData, sdCourseData } from "../../constants";
import SelectCoursePreferencePage, { CourseType } from "./SelectCoursePreferencePage";
import { ApplicationType, ProfileAssessment, ProfileType } from "../../api/schemas/schema";
import { ApplicationProgressData, showApplicationProgressCard } from "../../utils/dashboard";

interface Props {
  bestAssessment: ProfileAssessment;
  activeApplication: ApplicationType;
  profile: ProfileType;
  empowerHerBestAssessmentFor: ProfileAssessment;
  hasPaidRegistrationFees: boolean;
}

const PapDashboard = ({ activeApplication, bestAssessment, profile, empowerHerBestAssessmentFor, hasPaidRegistrationFees }: Props) => {
  const history = useHistory();
  const [hideCoursesCard, setHideCoursesCard] = useState<boolean>(false);
  const [isProgressCardVisible, setIsProgressCardVisible] = useState<boolean>(false);
  const [isApplicationCompleted, setIsApplicationCompleted] = useState<boolean>(false);
  const [courseCardToShow, setCourseCardToShow] = useState<CourseDataInterface[] | null>(null);
  const [progressCardData, setProgressCardData] = useState<ApplicationProgressData | null>(null);

  useEffect(() => {
    if (profile) {
      const result = showApplicationProgressCard(bestAssessment, activeApplication, profile, history, empowerHerBestAssessmentFor, hasPaidRegistrationFees);
      setProgressCardData(result.data);
      setIsProgressCardVisible(result.showProgress);
      if (result.data) {
        // Show rest of the course card based on the current course selected by the user
        setCourseCardToShow(
          [ScholorShipCourses.EMPOWER_HER, ScholorShipCourses.SONU_SOOD].includes(result.data?.courseName as ScholorShipCourses)
            ? null
            : result.data?.courseName === PAPCourses.SD
            ? daCourseData
            : sdCourseData
        );
      } else {
        setCourseCardToShow(null);
      }
    }
  }, [bestAssessment, activeApplication, profile]);

  useEffect(() => {
    if (activeApplication && activeApplication?.course_id && [12, 14].includes(activeApplication?.course_id)) {
      setHideCoursesCard(true);
    }
  }, [activeApplication]);

  useEffect(() => {
    if (activeApplication) {
      setIsApplicationCompleted(activeApplication?.status === "ONBOARDING_COMPLETE" ? true : false);
    }
  }, [activeApplication]);
  return (
    <Box pt={"20px"}>
      <WelcomeBannerV2 hideText={isApplicationCompleted} />
      <Flex mt="24px" pr={{ xl: "80px" }} justify="space-between" maxW="1440px" mx="auto" flexDir={{ base: "column", xl: "row" }}>
        <Box w={"full"} maxW={{ xl: `calc(1440px - 330px)` }}>
          {isProgressCardVisible && progressCardData && <ApplicationProgressCard data={progressCardData} isApplicationCompleted={isApplicationCompleted} />}
          {!hideCoursesCard && <SelectCoursePreferencePage selectedCourse={CourseType.PAP} isDashboard={true} coursesList={courseCardToShow} />}
          <ScholarshipsComponent />
          <Box w={"full"} mt={{ base: "24px", md: "auto" }}>
            <EventsForYou />
          </Box>
        </Box>

        <Box
          flexShrink={0}
          maxW={{ xl: "330px" }}
          px={{ base: "16px", xl: "0px" }}
          w="full"
          display={{ base: "flex", xl: "block" }}
          experimental_spaceY={{ base: 0, xl: 4 }}
          flexWrap="wrap"
          gap={{ base: "24px", md: "10px" }}
        >
          <ActivityCardContainerV2
            onRegister={() => {
              console.log("test");
            }}
            isDashboard
            eventType={ACTIVITY_TYPE.FOUNDER_WEBINAR}
          />
          <ConnectWithAlumni />
          <HiresOfTheWeek />
          <JoinCommunity />
        </Box>
      </Flex>
    </Box>
  );
};

export default PapDashboard;
