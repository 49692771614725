/* eslint-disable @typescript-eslint/no-explicit-any */
export enum WeekDay {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

export interface DayTimeSlot {
  day: WeekDay;
  date: Date;
  slotTimes: SlotTime[];
  duration: number;
  totalSlots: number;
}

export interface SlotTime {
  time: Date;
  count: number;
}

export const convertDays = (day: WeekDay) => {
  return day !== WeekDay.Thursday
    ? day.substring(0, 3).toUpperCase()
    : day.substring(0, 4).toUpperCase();
};

export const getUsersToDisable = (sessionDetails: any) => {
  // given an array of objects in below format,
  console.log("sessionDetails", sessionDetails);
  return sessionDetails.reduce(
    (
      acc: { [x: string]: boolean },
      curr: { sessionStatus: string; host: string | number | symbol }
    ) => {
      if (curr.sessionStatus === "Scheduled") {
        acc[curr.host as keyof typeof acc] = false;
      } else {
        acc[curr.host as keyof typeof acc] = true;
      }
      return acc;
    },
    {} as Record<string, boolean>
  );
};
