import { Box, Grid, GridItem } from "@chakra-ui/react";
import EligibleCard from "../sd-oboarding/EligibleCard";
import EligiblityForm from "../sd-oboarding/EligiblityForm";
import InEligibleCard from "../sd-oboarding/InEligibleCard";
import JoinWhatsappCard from "../sd-oboarding/JoinWhatsappCard";
import CourseStructureRightSection from "./CourseStructureRightSection";
import { OnboardingStepInterface } from "../../../constants/onboarding";
import { ApplicationType, BatchType } from "../../../api/schemas/schema";

interface Props {
  currentOnboardingStep: number;
  setCurrentOnboardingStep: (value: number) => void;
  setIsEligible: (value: boolean) => void;
  isInEligible: boolean;
  inEligibleReason: string | null;
  setOnboardingStepList: (args: OnboardingStepInterface[]) => void;
  activeBatch: BatchType;
  activeApplication: ApplicationType;
}

const SDOnbaordingComponent = ({ currentOnboardingStep, setCurrentOnboardingStep, setIsEligible, isInEligible, inEligibleReason, setOnboardingStepList, activeBatch, activeApplication }: Props) => {
  return (
    <Box w={"full"} px={{ base: "16px", md: "24px" }}>
      <Grid templateColumns={{ base: "auto", md: isInEligible && currentOnboardingStep === 1 ? "1fr" : "1fr 480px" }} gap={"34px"} justifyContent={"space-between"} w={"full"}>
        <GridItem w={"full"}>
          {currentOnboardingStep === 1 ? (
            !isInEligible ? (
              <EligiblityForm />
            ) : (
              <InEligibleCard setIsEligible={setIsEligible} inEligibleReason={inEligibleReason} />
            )
          ) : currentOnboardingStep === 2 ? (
            <EligibleCard setCurrentOnboardingStep={setCurrentOnboardingStep} setOnboardingStepList={setOnboardingStepList} activeApplication={activeApplication as ApplicationType} />
          ) : (
            <JoinWhatsappCard activeBatch={activeBatch as BatchType} activeApplication={activeApplication as ApplicationType} />
          )}
        </GridItem>
        <GridItem w={"full"} mx={"auto"} display={isInEligible && currentOnboardingStep === 1 ? "none" : "grid"}>
          <CourseStructureRightSection />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default SDOnbaordingComponent;
