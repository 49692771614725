import { Box, useBreakpointValue } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { createMettlAssessment } from "../../../api/utils/api";
import { Constants } from "../../../components/common/constants";
import useSnackBar from "../../../components/common/general/SnackBar";
import { isCourseProgramsIntersectsProfilePrograms } from "../../../components/common/utils/isProgramPresent";
import { AppContext } from "../../../context/ApplicationContext";
import MasaiXInstructions from "./MasaiXProgram/MasaiXCodingInstructions";
import ScholarCodingInstructions from "./ScholarProgram/ScholarCodingInstructions";

const CodingInstructions = () => {
  const [contestModal, setContestModal] = useState(false);
  const state = useContext(AppContext)[0];
  const [showEmailVerifyModal, setShowEmailVerifyModal] = useState(false);
  const isNewMsat = true;
  const snackbar = useSnackBar();
  const { applicantID } = useParams() as { applicantID: string };

  const [loading, setLoading] = useState<boolean>(false);
  const is_mobile = useBreakpointValue({ base: true, sm: false });
  const handleProceedToMSAT = (isPractice: boolean) => {
    if (state.profileData?.email_verified) {
      handleStartTest({
        is_practice: isPractice,
      });
      //TODO: GTM event
    } else {
      setShowEmailVerifyModal(true);
    }
  };
  const handleStartTest = async ({ is_practice }: { is_practice: boolean }) => {
    try {
      setLoading(true);
      if (state.profileData && state.profileData?.slug && applicantID && is_mobile !== undefined) {
        const mettl = await createMettlAssessment({
          profile_slug: state.profileData?.slug,
          application_slug: applicantID,
          is_practice,
          is_mobile,
          isCodingTest: true,
        });

        if (mettl.url) {
          setContestModal(false);
          if (!isNewMsat) {
            setTimeout(() => {
              window.open(mettl.url, "_blank");
            });
          } else {
            window.location.href = mettl.url;
          }
          // window.open(mettl.url);
        } else throw new Error(`Something went wrong ${JSON.stringify(mettl)}`);
      } else {
        snackbar.error("Something went wrong. Please try again later.");
      }

      setLoading(false);
    } catch (err) {
      snackbar.error("Something went wrong while creating the test. Please try again later.");
      console.error(err);
      setLoading(false);
    }
  };
  return (
    <Box mb={`${Constants.bottomNavbarHeight}`}>
      {isCourseProgramsIntersectsProfilePrograms(
        state.applicationData?.batch_campus.batch?.course?.course_programs,
        [
          {
            id: 1,
            name: "Scholar",
          },
        ]
      ) ? (
        <ScholarCodingInstructions
          contestModal={contestModal}
          setContestModal={setContestModal}
          handleProceedToMSAT={handleProceedToMSAT}
          status={state.applicationData?.status}
          loading={loading}
          setLoading={setLoading}
        />
      ) : isCourseProgramsIntersectsProfilePrograms(
          state.applicationData?.batch_campus.batch?.course?.course_programs,
          [
            {
              id: 3,
              name: "MasaiX",
            },
          ]
        ) ? (
        <MasaiXInstructions
          currentStage={"CODING"}
          contestModal={contestModal}
          setContestModal={setContestModal}
          handleProceedToMSAT={handleProceedToMSAT}
          loading={loading}
          setLoading={setLoading}
        />
      ) : null}
    </Box>
  );
};

export default CodingInstructions;
