import { Box, Heading, Image, Text } from "@chakra-ui/react";

import React from "react";
import NeedHelp from "./NeedHelp";

interface Props {
  whatYouWillLearnData: { icon: string; key: string; value: string }[];
}
const ScholarInstructionsDetails = ({ whatYouWillLearnData }: Props) => {
  return (
    <Box>
      <Heading as="h4">What you’ll learn</Heading>
      <Box
        mt={"ms-12"}
        display={"grid"}
        gridTemplateColumns={{ base: "repeat(2,1fr)", md: "repeat(3,1fr)" }}
        justifyContent={"space-between"}
        columnGap={"ms-16"}
        rowGap={"ms-16"}
      >
        {whatYouWillLearnData.map((item) => (
          <Box
            w={"100%"}
            p={{ base: "ms-4", md: "ms-12" }}
            py="ms-12"
            border="1px solid #D9D9D9"
            borderRadius={"16px"}
            textAlign={"center"}
            key={item.key}
          >
            <Image m={"auto"} src={item.icon} />
            <Text mt={{ base: "ms-12", md: "ms-8" }} textStyle={{ base: "caption", md: "body2" }}>
              {item.key}
            </Text>
            <Text mt={"ms-8"} textStyle={"body2-md"}>
              {item.value}
            </Text>
          </Box>
        ))}
      </Box>
      <Box d={{ base: "none", md: "block" }} mt={"ms-24"}>
        <NeedHelp />
      </Box>
    </Box>
  );
};

export default ScholarInstructionsDetails;
