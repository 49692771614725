import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}

export const ReplayIcon = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g mask="url(#mask0_9070_120103)">
      <path
        d="M12.5 22C11.25 22 10.0793 21.7627 8.988 21.288C7.896 20.8127 6.946 20.1707 6.138 19.362C5.32933 18.554 4.68733 17.604 4.212 16.512C3.73733 15.4207 3.5 14.25 3.5 13H5.5C5.5 14.95 6.17933 16.604 7.538 17.962C8.896 19.3207 10.55 20 12.5 20C14.45 20 16.104 19.3207 17.462 17.962C18.8207 16.604 19.5 14.95 19.5 13C19.5 11.05 18.8207 9.39567 17.462 8.037C16.104 6.679 14.45 6 12.5 6H12.35L13.9 7.55L12.5 9L8.5 5L12.5 1L13.9 2.45L12.35 4H12.5C13.75 4 14.921 4.23767 16.013 4.713C17.1043 5.18767 18.0543 5.829 18.863 6.637C19.671 7.44567 20.3127 8.39567 20.788 9.487C21.2627 10.579 21.5 11.75 21.5 13C21.5 14.25 21.2627 15.4207 20.788 16.512C20.3127 17.604 19.671 18.554 18.863 19.362C18.0543 20.1707 17.1043 20.8127 16.013 21.288C14.921 21.7627 13.75 22 12.5 22Z"
        fill="currentColor"
      />
    </g>
  </Icon>
);

export default ReplayIcon;
