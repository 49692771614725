import api from "../../axios";

export const getCourses = async () => {
  const client = await api.client;
  const response = await client.get_courses_v2_courses_get();
  return response.data;
};

export const getCourseById = async ({ course_id }: { course_id: string }) => {
  const client = await api.client;
  const response = await client.get_course_courses__course_id__get({ course_id });
  return response.data;
};
