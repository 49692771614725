import { Box, Text } from "@chakra-ui/react";

type Props = {
  heading: string;
  subHeading?: string;
  url?: string;
};

const CustomFormHeading = ({ heading, subHeading, url }: Props) => {
  return (
    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} w={"full"}>
      <Text textStyle={"body2-md"} color={"black"}>
        {heading}
        {subHeading && <span style={{ fontSize: "10px" }}>{subHeading}</span>}
      </Text>
      {url && (
        <Text
          display={"inline"}
          textStyle={"body2"}
          color={"ms-blue.500"}
          cursor={"pointer"}
          _hover={{ textDecoration: "underline" }}
          onClick={() => {
            window.open(url, "_blank");
          }}
        >
          Preview
        </Text>
      )}
    </Box>
  );
};

export default CustomFormHeading;
