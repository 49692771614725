import { Flex, Image, Text } from "@chakra-ui/react";
import PhoneCalingIcon from "../../../../assets/images/phone-outline.svg";

interface Props {
  hideText: boolean;
  hideBtn: boolean;
  isIITRoparPage: boolean;
  handleConsuellingRequest: () => void;
  isRequestCallbackDone: boolean;
  isSubmitCounsellingRequestLoading: boolean;
}

const HeaderRequestCallbackBtn = ({ hideBtn, isIITRoparPage, hideText, isRequestCallbackDone, handleConsuellingRequest, isSubmitCounsellingRequestLoading }: Props) => {
  if (hideBtn) return <div />;
  return (
    <Flex
      rounded={"32px"}
      _hover={{ bg: isRequestCallbackDone ? "" : "#D6E2FA" }}
      cursor={isRequestCallbackDone ? "not-allowed" : "pointer"}
      opacity={isRequestCallbackDone ? 0.5 : 1}
      border={"1px solid #5D8DE9"}
      align={"center"}
      display={{ base: isIITRoparPage ? "flex" : "none", md: "flex" }}
      bg={"#F2F6FF"}
      flexShrink={0}
      onClick={() => {
        if (isSubmitCounsellingRequestLoading || isRequestCallbackDone) return;
        handleConsuellingRequest();
      }}
      isDisabled={isRequestCallbackDone || isSubmitCounsellingRequestLoading}
    >
      <Image h="full" alt="call" loading="lazy" src={PhoneCalingIcon} w={"26px"} height={"26px"} objectFit={"contain"} />
      <Text p={{ base: "0px !important", md: "0px 6px !important" }} fontSize={"14px"} fontWeight={600} display={{ base: "none", md: hideText ? "none" : "block" }}>
        {isRequestCallbackDone ? "Callback Requested" : "Request Callback"}
      </Text>
    </Flex>
  );
};

export default HeaderRequestCallbackBtn;
