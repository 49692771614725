import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const GPaySmall = (props: Props) => (
  <Icon viewBox="0 0 34 24" {...props}>
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H30C32.2091 0 34 1.79086 34 4V20C34 22.2091 32.2091 24 30 24H4C1.79086 24 0 22.2091 0 20V4Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0579 15.528V12.5933H17.5725C18.1931 12.5933 18.717 12.3854 19.1441 11.9752L19.2466 11.8712C20.0266 11.022 19.9754 9.69913 19.1441 8.91347C18.7284 8.49754 18.159 8.27224 17.5725 8.28379H15.1411V15.528H16.0579ZM16.058 11.7036V9.17338H17.5956C17.9259 9.17338 18.2391 9.30047 18.4726 9.53154C18.9681 10.0168 18.9795 10.8256 18.5011 11.3281C18.2676 11.5766 17.9373 11.7152 17.5956 11.7036H16.058ZM23.5228 10.9585C23.1299 10.5945 22.5947 10.4097 21.9171 10.4097C21.0459 10.4097 20.3911 10.7332 19.9583 11.3744L20.7669 11.8885C21.063 11.4495 21.4673 11.23 21.9797 11.23C22.3043 11.23 22.6175 11.3513 22.8623 11.5708C23.1015 11.7788 23.2381 12.0792 23.2381 12.3969V12.6106C22.8851 12.4142 22.441 12.3103 21.8943 12.3103C21.2566 12.3103 20.7441 12.4604 20.3626 12.7666C19.9811 13.0728 19.7875 13.4772 19.7875 13.9913C19.7761 14.4592 19.9754 14.9041 20.3285 15.2045C20.6872 15.528 21.1427 15.6897 21.6779 15.6897C22.31 15.6897 22.8111 15.4067 23.1926 14.8405H23.2324V15.528H24.1093V12.472C24.1093 11.8308 23.9157 11.3224 23.5228 10.9585ZM21.0347 14.6152C20.8467 14.4766 20.7328 14.2513 20.7328 14.0087C20.7328 13.7372 20.8581 13.5119 21.103 13.3328C21.3536 13.1537 21.6668 13.0613 22.037 13.0613C22.5495 13.0555 22.9482 13.171 23.2329 13.4021C23.2329 13.7949 23.0792 14.1358 22.7773 14.4246C22.504 14.7019 22.1338 14.8579 21.7465 14.8579C21.4903 14.8636 21.2397 14.777 21.0347 14.6152ZM26.0795 17.7058L29.1429 10.5714H28.1464L26.7286 14.13H26.7115L25.2595 10.5714H24.2631L26.2731 15.2102L25.1343 17.7058H26.0795Z"
      fill="#3C4043"
    />
    <path
      d="M12.8929 11.9579C12.8929 11.6748 12.8702 11.3918 12.8246 11.1145H8.95831V12.7147H11.1733C11.0822 13.2288 10.7861 13.691 10.3534 13.9798V15.0196H11.6744C12.4488 14.2975 12.8929 13.2288 12.8929 11.9579Z"
      fill="#4285F4"
    />
    <path
      d="M8.95848 16.0248C10.0631 16.0248 10.997 15.6551 11.6746 15.0196L10.3535 13.9798C9.98341 14.234 9.5108 14.3784 8.95848 14.3784C7.88799 14.3784 6.98263 13.6448 6.65807 12.6627H5.29718V13.7372C5.99186 15.141 7.40969 16.0248 8.95848 16.0248Z"
      fill="#34A853"
    />
    <path
      d="M6.65822 12.6627C6.48737 12.1486 6.48737 11.5882 6.65822 11.0683V9.99956H5.29712C4.71053 11.1723 4.71053 12.5587 5.29712 13.7314L6.65822 12.6627Z"
      fill="#FBBC04"
    />
    <path
      d="M8.95848 9.35253C9.54497 9.34098 10.1087 9.56628 10.53 9.97643L11.703 8.7864C10.9571 8.08162 9.97772 7.69457 8.95848 7.70612C7.40969 7.70612 5.99186 8.59576 5.29718 9.99954L6.65807 11.074C6.98263 10.0862 7.88799 9.35253 8.95848 9.35253Z"
      fill="#EA4335"
    />
    <path
      d="M4 1H30V-1H4V1ZM33 4V20H35V4H33ZM30 23H4V25H30V23ZM1 20V4H-1V20H1ZM4 23C2.34315 23 1 21.6569 1 20H-1C-1 22.7614 1.23858 25 4 25V23ZM33 20C33 21.6569 31.6569 23 30 23V25C32.7614 25 35 22.7614 35 20H33ZM30 1C31.6569 1 33 2.34315 33 4H35C35 1.23858 32.7614 -1 30 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z"
      fill="#F3F2F2"
    />
  </Icon>
);

export default GPaySmall;
