import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const Swift = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M16.6017 18.9536C14.1262 20.3775 10.7225 20.5238 7.29809 19.0624C4.5254 17.8878 2.22479 15.8318 0.75 13.4826C1.4579 14.0699 2.28377 14.5399 3.16863 14.9508C6.70507 16.6013 10.2408 16.4882 12.729 14.955C9.18948 12.253 6.1772 8.72448 3.93562 5.8466C3.46349 5.37666 3.10954 4.78925 2.75559 4.26063C5.46919 6.7276 9.77575 9.84051 11.3092 10.722C8.06514 7.3149 5.17432 3.08603 5.29208 3.20328C10.4245 8.37226 15.2031 11.3093 15.2031 11.3093C15.3611 11.398 15.4831 11.472 15.5812 11.538C15.6847 11.276 15.7754 11.004 15.8518 10.722C16.6776 7.72633 15.734 4.31925 13.6691 1.5C18.4471 4.37814 21.2791 9.78188 20.0989 14.3049C20.0681 14.4269 20.0347 14.5474 19.9986 14.6657C22.358 17.6027 21.7506 20.7663 21.4557 20.179C20.1758 17.6851 17.8063 18.4477 16.6017 18.9536Z"
      fill="url(#paint0_linear_1430_6036)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1430_6036"
        x1="11.2577"
        y1="1.5"
        x2="11.2577"
        y2="20.251"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F88A36" />
        <stop offset="1" stopColor="#FD2020" />
      </linearGradient>
    </defs>
  </Icon>
);

export default Swift;
