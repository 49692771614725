import { Box, Image, Heading, Text, Button } from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import { AadhaarInfoType } from "../../../../api/schemas/schema";
import { PersonFilled } from "../../../../assets/icons/components";

import { AadhaarLogo } from "../../../../assets/images";
import { sendTrackingEvent } from "../../../common/utils/gtm";
interface Props {
  handleConfirmAadhaarDetails?: () => void;
  aadhaarOCRDetails?: AadhaarInfoType;
  loading?: boolean;
  setShowConfirmAadhaarModal?: (value: boolean) => void;
}
const ConfirmAadhaarDetailsModalContent = ({
  handleConfirmAadhaarDetails,
  aadhaarOCRDetails,
  loading,
  setShowConfirmAadhaarModal,
}: Props) => {
  type FieldType = { key: string; value?: string; icon: React.ReactElement };
  const aadhaarData: FieldType[] = [
    { key: "Name", value: aadhaarOCRDetails?.name, icon: <PersonFilled /> },
    { key: "DOB", value: aadhaarOCRDetails?.date_of_birth, icon: <PersonFilled /> },
    {
      key: "Address",
      value: aadhaarOCRDetails?.address,
      icon: <PersonFilled />,
    },
  ];
  const location = useLocation();
  return (
    <Box>
      <Box mb={"ms-12"} d={"flex"} alignItems={"center"}>
        <Image mr={"ms-8"} src={AadhaarLogo} alt="aadhaar" />
        <Heading as="h3">Confirm your Aadhaar Details</Heading>
      </Box>
      <Text textStyle={"body1"} color={"ms-grey.700"}>
        Please confirm your Aadhaar details to complete the verification.{" "}
      </Text>

      <Box mt={"ms-16"}>
        {aadhaarData.map((data) => (
          <Box mb={"ms-8"} d={"flex"} key={data.key} alignItems={"flex-start"}>
            {React.cloneElement(data.icon, { boxSize: 6, color: "ms-red.900" })}
            <Box d={"flex"} mt={"ms-4"}>
              <Text ml={"ms-4"} mr={"ms-8"} textStyle={"body2"}>
                {data.key}:
              </Text>
              <Text textStyle={"body2-md"}>{data.value}</Text>
            </Box>
          </Box>
        ))}
      </Box>
      <Box mt={"ms-12"} d={"flex"} justifyContent={"right"}>
        <Button
          onClick={() => {
            sendTrackingEvent({
              event: "db_aadhaar_cancel",
              eventLabel: location.pathname,
            });
            setShowConfirmAadhaarModal && setShowConfirmAadhaarModal(false);
          }}
          mr={"ms-8"}
          variant={"secondary"}
        >
          Cancel
        </Button>
        <Button
          isDisabled={loading}
          position={loading ? "relative" : "static"}
          variant={"primary"}
          onClick={() => {
            sendTrackingEvent({
              event: "db_aadhaar_confirm",
              eventLabel: location.pathname,
            });
            handleConfirmAadhaarDetails && handleConfirmAadhaarDetails();
          }}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  );
};

export default ConfirmAadhaarDetailsModalContent;
