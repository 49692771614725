import { Box, Text, Link, Flex } from "@chakra-ui/react";

interface Props {
  email?: string;
  handleEmailEditClick: () => void;
}

const EmailEdit = ({ email, handleEmailEditClick }: Props) => {
  return (
    <Box>
      <Text textStyle="body2" color="ms-grey.700">
        The OTP has been sent to your email address
      </Text>
      <Flex alignItems={"center"}>
        <Text mb={"ms-8"} mt={"ms-4"} textStyle="body2-md" color="ms-grey.700">
          {email}
        </Text>
        <Link
          _hover={{ textDecoration: "none" }}
          onClick={() => {
            //
          }}
        >
          <Text
            onClick={handleEmailEditClick}
            mb={"ms-2"}
            ml={"ms-8"}
            color="ms-blue.500"
            textStyle="body2-md"
          >
            Edit
          </Text>
        </Link>
      </Flex>
    </Box>
  );
};

export default EmailEdit;
