import { Box, Button, Text } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { verifyCoupon } from "../../api/utils/api";
import { axiosErrorHandler } from "../../api/utils/error";
import { AppContext, ApplicationActionType } from "../../context/ApplicationContext";
import { Banner } from "../../pages/Courses/Banner";
import { InputCustom } from "../common/general";
import useSnackBar from "../common/general/SnackBar";

type Props = {
  coursePrice?: number;
  discountPercentage?: number;
  onRemove: () => void;
  onCheck: () => void;
};

export const CAPCouponVarifiy = ({ coursePrice, discountPercentage, onRemove, onCheck }: Props) => {
  const snackbar = useSnackBar();
  const state = useContext(AppContext)[0];
  const dispatch = useContext(AppContext)[1];
  const [error, setError] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();
  const [couponCode, setCouponCode] = useState<string>("");

  const handleVerifyCoupon = async (showError = true, couponCode: string) => {
    // Verify if coupon code is valid
    if (!state.applicationData?.slug) {
      return;
    }
    setLoading(true);

    try {
      const response = await verifyCoupon({
        coupon_code: couponCode,
        course_id: 0,
        is_partial_payment: false,
        paymentType: "OTHERS",
      });

      setError(false);
    } catch (err) {
      if (!showError) {
        return;
      }
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
      setError(true);
    } finally {
      dispatch({
        type: ApplicationActionType.FETCH_APPLICATION,
        payload: {},
      });
      setLoading(false);
    }
  };
  return (
    <>
      <Box display={"flex"} mt={{ base: "ms-12", md: "ms-24" }}>
        <InputCustom
          getValueCallback={(value) => {
            setCouponCode(value.trim());
          }}
          type="text"
          placeholder="Enter coupon code"
          onClick={() => {
            console.log("hey");
          }}
          isInvalid={error}
          disabled={state.applicationData?.current_coupon?.code ? true : false}
          errorMessage="Invalid code or code expired"
          preFilledValue={state.applicationData?.current_coupon?.code}
        />

        <Button
          color="#3470E4"
          variant="secondary"
          size="lg"
          ml={{ base: "ms-16", md: "ms-8" }}
          onClick={() => {
            if (state.applicationData?.current_coupon?.code) {
              handleVerifyCoupon(false, "");
              onRemove();
            } else {
              handleVerifyCoupon(true, couponCode);
              onCheck();
            }
          }}
          disabled={couponCode === "" && !state.applicationData?.current_coupon?.code ? true : false}
        >
          {state.applicationData?.current_coupon?.code ? "REMOVE" : "APPLY"}
        </Button>
      </Box>
      {/* Banner */}
      <Box mt={"ms-8"} textAlign="start">
        {state.applicationData?.current_coupon?.code && coursePrice && discountPercentage && (
          <Banner
            minHeight="auto"
            type="success"
            title="Yayy!!🤩"
            text={
              <Text textStyle="caption">
                You have received a discount of{" "}
                <Text as="span" color="ms-blue.500" fontWeight="700">
                  ₹{(coursePrice / 100) * discountPercentage}
                </Text>{" "}
                on the course fee. Kindly complete the payment within the next 30mins to avail the discount.
              </Text>
            }
            hideShareButton={true}
            showMobileDesign={true}
            customWidth={["80%", "80%"]}
          />
        )}
      </Box>
    </>
  );
};
