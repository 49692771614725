import { Box, Image, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { ScanningDocument } from "../../../../assets/images";

const FetchingInformationModalContent = () => {
  return (
    <Box>
      <Image mb={"ms-4"} mx={"auto"} src={ScanningDocument} alt="scanning document" />
      <Box textAlign={"center"}>
        <Heading mb={"ms-8"} as="h3">
          Retrieving Information
        </Heading>
        <Text color={"ms-grey.700"} textStyle="body1">
          Please wait while we retrieve information from your Aadhaar
        </Text>
      </Box>
    </Box>
  );
};

export default FetchingInformationModalContent;
