import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import { CourseType } from "./SelectCoursePreferencePage";

interface Props {
  activeTab: CourseType;
  setActiveTab: (tab: CourseType) => void;
}

const SelectCourseTab = ({ activeTab, setActiveTab }: Props) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Flex
      justifyContent={"space-between"}
      maxW={{ base: "fit-content", md: "420px" }}
      w={"full"}
      mx={"auto"}
      mt={{ base: "50px", md: "60px" }}
      alignItems={"center"}
      bg={activeTab === CourseType.PAP ? "#FEDFE5" : "#D6E2FA"}
      rounded={"56px"}
      p={"5px"}
    >
      <Box
        h={"full"}
        rounded={"42px"}
        w={"max-content"}
        cursor={"pointer"}
        px={4}
        py={1}
        bg={activeTab === CourseType.PAP ? "#BE3A47" : "#D6E2FA"}
        color={activeTab === CourseType.PAP ? "white" : "black"}
        onClick={() => {
          setActiveTab(CourseType.PAP);
        }}
      >
        <Text fontWeight={{ md: 600 }} fontSize={{ base: "16px", md: "18px" }}>
          {isMobile ? "PAP Courses" : "Pay-After-Placement Courses"}
        </Text>
      </Box>
      <Box
        h={"full"}
        rounded={"42px"}
        w={"max-content"}
        cursor={"pointer"}
        px={{ base: 2, md: 4 }}
        py={1}
        bg={activeTab === CourseType.IIT ? "#3470E4" : "#FEDFE5"}
        color={activeTab === CourseType.IIT ? "white" : "black"}
        onClick={() => {
          setActiveTab(CourseType.IIT);
        }}
      >
        <Text fontWeight={{ md: 600 }} fontSize={{ base: "16px", md: "18px" }}>
          IIT Courses
        </Text>
      </Box>
    </Flex>
  );
};

export default SelectCourseTab;
