import { Box, Text, Image } from "@chakra-ui/react";
import React from "react";
import { GetDostType } from "../../../api/schemas/schema";

const DostProfile = ({ dostData }: { dostData?: GetDostType }) => {
  return (
    <Box
      bg="white"
      display="flex"
      p="6px 12px"
      maxW={{ md: "fit-content" }}
      gap="12px"
      rounded="12px"
      border="1px solid #D6E2FA"
    >
      <Box
        minW="41px"
        w="41px"
        minH="41px"
        h="41px"
        rounded="100px"
        overflow="hidden"
        border="2px solid #C8C9EC"
      >
        <Image src={dostData?.dp_url || "/img/homepage/dost-image.png"} alt="img" />
      </Box>
      <Box>
        <Text textStyle="body2-md">{dostData?.name}</Text>
        <Text textStyle="caption">{dostData?.designation}</Text>
      </Box>
    </Box>
  );
};

export default DostProfile;
