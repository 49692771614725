import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

interface PaymentOptionProps {
  amount: number;
}

const PaymentOption: React.FC<PaymentOptionProps> = ({ amount }) => {
  return (
    <Flex gap={2} align="flex-start" mt={6}>
      <Box display="flex" alignItems="center" justifyContent="center" p={1}>
        <Box display="flex" alignItems="center" justifyContent="center" p={1} borderRadius="full" border="1px" borderColor="blue.600">
          <Box w={2} h={2} bg="blue.600" borderRadius="full" />
        </Box>
      </Box>
      <Flex flexDirection="column" fontWeight="semibold">
        <Text fontSize="base" color="neutral.600" lineHeight="6">
          First instalment
        </Text>
        <Text fontSize="lg" color="stone.900" lineHeight="6" mt={1}>
          ₹ {amount.toLocaleString()}/-
        </Text>
      </Flex>
    </Flex>
  );
};

export default PaymentOption;
