import { HStack, Button, useBreakpointValue, Spinner, Box, StackProps } from "@chakra-ui/react";
import { Constants } from "../../common/constants";

type BtnProps = {
  onClick?: () => void;
  isDisabled?: boolean;
  size?: "sm" | "md" | "lg";
};

interface Props {
  children?: JSX.Element[] | JSX.Element;
  btnPrimary?: string;
  btnPrimaryProps?: BtnProps;
  btnTertiary?: string;
  btnTertiaryProps?: BtnProps;
  btnSecondary?: string;
  btnSecondaryProps?: BtnProps;
  loading?: boolean;
  bg?: string;
  HStackProps?: StackProps;
}

const BottomStickyNavbar = ({
  children,
  btnPrimary,
  btnPrimaryProps,
  btnTertiary,
  btnTertiaryProps,
  btnSecondary,
  btnSecondaryProps,
  loading,
  bg = "White",
  HStackProps
}: Props) => {
  const size = useBreakpointValue({ base: "sm", md: "md" });

  return (
    <HStack
      position="fixed"
      bottom={{
        base: location.pathname == "/onboarding" ? "0rem" : `${Constants.bottomNavbarHeight}`,
        md: "0",
      }}
      w={{ base: "100%", md: `calc(100% - ${Constants.sidePanelWidth})` }}
      px={["ms-28", "ms-24"]}
      py={["ms-8", "ms-16"]}
      justify="flex-end"
      boxShadow="0px -1px 0px #D9D9D9"
      bg={bg}
      zIndex="1"
      {...HStackProps}
    >
      {children}
      {(btnTertiary || btnSecondary || btnPrimary) && (
        <Box d={"flex"}>
          {btnTertiary && (
            <Button
              ml={{ base: "ms-8", md: "ms-24" }}
              size={size}
              variant="tertiary"
              {...btnTertiaryProps}
            >
              {btnTertiary}
            </Button>
          )}
          {btnSecondary && (
            <Button
              size={size}
              ml={{ base: "ms-8", md: "ms-24" }}
              variant="secondary"
              {...btnSecondaryProps}
            >
              {btnSecondary}
            </Button>
          )}
          {btnPrimary && (
            <Button
              size={size}
              variant="primary"
              {...btnPrimaryProps}
              isDisabled={loading || btnPrimaryProps?.isDisabled}
              position={loading ? "relative" : "static"}
              ml={{ base: "ms-8", md: "ms-24" }}
            >
              {btnPrimary}
              {loading && (
                <Spinner
                  speed={"0.5s"}
                  thickness={"2px"}
                  size={"md"}
                  position={"absolute"}
                  color={"ms-blue.500"}
                />
              )}
            </Button>
          )}
        </Box>
      )}
    </HStack>
  );
};

export default BottomStickyNavbar;
