import { Icon } from "@chakra-ui/react";
import React from "react";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Comma1 = (props:any) => {
  return (
    <Icon viewBox="0 0 27 24" fill="#6FCD9E" {...props}>
      <path d="M7.33398 16.2005V13.3605C7.33398 12.2672 7.52898 11.2538 7.91898 10.3205C8.33898 9.38722 9.04398 8.46722 10.034 7.56055L11.789 8.72055C11.129 9.36055 10.649 9.96055 10.349 10.5205C10.049 11.0538 9.86898 11.5872 9.80898 12.1205H11.924V16.2005H7.33398ZM14.804 16.2005V13.3605C14.804 12.2672 14.9989 11.2538 15.389 10.3205C15.8089 9.38722 16.514 8.46722 17.504 7.56055L19.259 8.72055C18.5989 9.36055 18.119 9.96055 17.819 10.5205C17.5189 11.0538 17.3389 11.5872 17.279 12.1205H19.394V16.2005H14.804Z" />
    </Icon>
  );
};

export default Comma1;
