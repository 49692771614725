import {
  Box,
  Text,
  Checkbox,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Input,
  Skeleton,
} from "@chakra-ui/react";
import React, { useState } from "react";
import SingleCheckbox from "../../../common/general/SingleCheckbox";
import { useAllDostRequests } from "../../../../api/utils/hooks/useAdmin";
import { useMutation } from "react-query";
import { updateDostRequestStatus } from "../../../../api/utils/api/v2/admin";
import useSnackBar from "../../../common/general/SnackBar";
import { axiosErrorHandler } from "../../../../api/utils/error";

const DostList = ({ offset, limit }: { offset: number; limit: number }) => {
  const [emailFilter, setEmailFilter] = useState("");
  const [dostNameFilter, setDostNameFilter] = useState("");
  const {
    data: allDostRequests,
    refetch: refetchAllDostRequests,
    isLoading: allDostRequestsLoading,
  } = useAllDostRequests(offset, limit);
  const filteredDostData =
    allDostRequests?.dost_requests?.filter((dost) => {
      return (
        dost.profile.email?.toLowerCase().includes(emailFilter.toLowerCase()) &&
        dost.dost.name.toLowerCase().includes(dostNameFilter.toLowerCase())
      );
    }) || [];
  const snackbar = useSnackBar();
  const {
    mutate: updateDostRequestStatusMutate,
    isLoading: updateDostRequestStatusLoading,
  } = useMutation(updateDostRequestStatus, {
    onSuccess: () => {
      refetchAllDostRequests();
      snackbar.success("SUCCESS");
    },
    onError: (error) => {
      refetchAllDostRequests();
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });
  return (
    <Skeleton isLoaded={!(updateDostRequestStatusLoading || allDostRequestsLoading)}>
      <Box>
        {/* <Box mb={4} display={"flex"} gap="16px">
          <Input
            width={"fit-content"}
            placeholder="Filter by User Email"
            value={emailFilter}
            onChange={(e) => setEmailFilter(e.target.value)}
          />
          <Input
            width={"fit-content"}
            placeholder="Filter by Dost Name"
            value={dostNameFilter}
            onChange={(e) => setDostNameFilter(e.target.value)}
          />
        </Box> */}
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Status</Th>
                <Th>Request Id</Th>
                <Th>Dost Id</Th>
                <Th>User Email</Th>
                <Th>Profile Slug</Th>
                <Th>Dost Name</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredDostData.map((dost) => (
                <Tr key={dost.id}>
                  <Td>{dost.status}</Td>
                  <Td>
                    <Box>{dost.id}</Box>
                  </Td>
                  <Td>
                    <Box>{dost.dost.id}</Box>
                  </Td>
                  <Td>
                    <Box>{dost.profile.email}</Box>
                  </Td>
                  <Td>
                    <Box>{dost.profile.slug}</Box>
                  </Td>
                  <Td>
                    <Box>{dost.dost.name}</Box>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Skeleton>
  );
};

export default DostList;
