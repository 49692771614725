import { OnboardingStepInterface } from "../onboarding";

export const coursePointers = [
  {
    title: "Core Classes on Software Development",
    subtitle: "10 comprehensive classes on Coding and DSA",
  },
  {
    title: "Employability skills",
    subtitle: "2 comprehensive classes on employability skills",
  },
  {
    title: "Sessions by Alumni",
    subtitle: "Insights from alumni on their experience",
  },
  {
    title: "Sessions by Industry Experts",
    subtitle: "Expert insights and knowledge sharing",
  },
];

export const softwareDevelopmentOnbaordingStepList: OnboardingStepInterface[] = [
  { stepName: "Eligibility", additionalText: "", isCurrentStep: true, isStepCompleted: false },
  { stepName: "Trial Period", additionalText: "Starts on 1st August", isCurrentStep: false, isStepCompleted: false },
];

export const dataAnalystStepList: OnboardingStepInterface[] = [
  { stepName: "Clear MSAT", additionalText: "", isCurrentStep: true, isStepCompleted: false },
  { stepName: "Eligibility Form", additionalText: "", isCurrentStep: false, isStepCompleted: false },
  { stepName: "Orientation", additionalText: "Course starts on 1st August", isCurrentStep: false, isStepCompleted: false },
];
