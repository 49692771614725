export const iitList: { text: string; highlitedText?: string }[] = [
  { text: "Live Lectures & Evaluations by IIT Professors" },
  { text: "Join Daksh Gurukul - IIT Guwahati Alumni Community" },
  { text: "Access to IIT Guwahati Campus and Labs" },
  { text: "24 program credits - equivalent to Minor degree in CSE" },
];

export const masaiList: { text: string; highlitedText?: string }[] = [
  { text: "Learn from Web development, s/w testing and data analytics." },
  { text: "Become job ready in 25-30 weeks." },
  { text: "Pay After Placement of", highlitedText: "3.5 LPA or Above." },
];

export const iitMandiList: { text: string; highlitedText?: string }[] = [
  { text: "Unlock Top Tech Roles at MAANG" },
  { text: "Live Lectures & Evaluations by IIT Professors" },
  { text: "24 Program Credits - Equivalent to a Minor Degree" },
];
