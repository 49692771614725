import { Box, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";

interface Props {
  list: JSX.Element[];
}

const CodingInstructionsList = ({ list }: Props) => {
  return (
    <Box>
      <Heading as="h4">Coding Test Instructions</Heading>
      <UnorderedList mt={"ms-12"}>
        {list.map((item, i) => (
          <ListItem mb={"2px"} key={i}>
            <Text color={"ms-grey.800"} textStyle={{ base: "body2", md: "body1" }}>
              {item}
            </Text>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
};

export default CodingInstructionsList;
