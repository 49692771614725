import Activity from "../assets/icons/components/Others/BottomTabActivity";
import BottomTabCourses from "../assets/icons/components/Others/BottomTabCourses";
import SelfLearning from "../assets/icons/components/Others/BottomTabSelfLearning";
import Home from "../assets/icons/components/Others/Home";
import { PiStudent } from "react-icons/pi";
import { MdOutlineAutoStories } from "react-icons/md";
import { Badge } from "../components/common/general";
import IItGCourse from "../assets/icons/components/Others/IItGCourse";
import { MobileHamburgerList, tabsLabelList, topLabelsListProps } from "../interfaces/SidebarOption.interface";
import CurriculumTab from "../assets/icons/components/Others/CurriculumTab";
import MediaTab from "../assets/icons/components/Others/MediaTab";
import BriefcaseV2 from "../assets/icons/components/BriefcaseV2";
import { AccountTie, IndianRupee } from "../assets/icons/components";
import Scholarship from "../assets/icons/components/Others/Scholarship";

export const masaiOnwardLeftNavigationOption: topLabelsListProps[] = [
  {
    key: 1,
    type: "primary",
    path: "/",
    title: "Home",
    iconForPrimary: <Home />,
    event: "d_ham_home",
    isActive: false,
    isExternal: false,
  },
  {
    key: 2,
    type: "primary",
    path: "/courses",
    title: "Courses",
    iconForPrimary: <BottomTabCourses />,
    event: "d_ham_courses",
    isActive: false,
    isExternal: false,
  },
  {
    key: 3,
    type: "primary",
    path: `${window.location.protocol}//${process.env.REACT_APP_LEARN_DOMAIN}/learn`,
    title: "Self Learning",
    iconForPrimary: <SelfLearning />,
    event: "d_ham_self_learning",
    isActive: false,
    isExternal: true,
  },
  {
    key: 4,
    type: "primary",
    path: "/sonu-sood-scholarship",
    title: "Scholarship",
    iconForPrimary: <Scholarship />,
    event: "d_ham_Scholarship",
    isActive: false,
    isExternal: false,
    badgeComponent: <Badge variant="brick" size="sm" label="NEW" />,
  },
  {
    key: 5,
    type: "primary",
    path: "/activities",
    title: "Events",
    iconForPrimary: <Activity />,
    event: "d_ham_activities",
    isActive: false,
    isExternal: false,
    badgeComponent: <Badge variant="brick" size="sm" label="NEW" />,
  },
  {
    key: 6,
    title: "MSAT",
    type: "primary",
    iconForPrimary: <PiStudent size={"24px"} />,
    event: "d_ham_msat",
    isActive: false,
    isExternal: false,
    path: `/msat`,
  },
  {
    key: 7,
    title: "Empower Her",
    type: "primary",
    iconForPrimary: <MdOutlineAutoStories size={"24px"} />,
    event: "d_ham_empower_her",
    isActive: false,
    isExternal: false,
    path: `/empower-her`,
  },
  {
    key: 8,
    title: "Jobs",
    type: "primary",
    iconForPrimary: <BriefcaseV2 boxSize={"24px"} />,
    description: "(Direct Masai Placement)",
    event: "d_ham_jobs",
    isActive: false,
    isExternal: false,
    path: `/jobs`,
    badgeComponent: <Badge variant="brick" size="sm" label="NEW" />,
  },

  {
    key: 9,
    title: "Alumni Connect",
    type: "primary",
    iconForPrimary: <PiStudent size={"24px"} />,
    event: "d_ham_alumni_connect",
    isActive: false,
    isExternal: false,
    path: `/alumni`,
    badgeComponent: <Badge variant="brick" size="sm" label="NEW" />,
  },
];

export const IITOnwardLeftNavigationOptions: topLabelsListProps[] = [
  {
    key: 1,
    type: "primary",
    path: "/mc-iitg-cs",
    title: "IIT Guwahati - CS",
    iconForPrimary: <IItGCourse />,
    event: "d_ham_courses",
    isActive: false,
    isExternal: false,
  },
  {
    key: 2,
    type: "primary",
    path: `${window.location.protocol}//${process.env.REACT_APP_LEARN_DOMAIN}/learn/tutorials/prep-course`,
    title: "Introductory Course",
    iconForPrimary: <SelfLearning />,
    event: "d_ham_foundation_courses",
    isActive: false,
    isExternal: true,
  },
  {
    key: 3,
    type: "primary",
    path: "/curriculum",
    title: "Curriculum",
    iconForPrimary: <CurriculumTab />,
    event: "d_ham_curriculum",
    isActive: false,
    isExternal: false,
  },
  //   {
  //     key: 4,
  //     type: "primary",
  //     path: "/life-at-guwahati",
  //     title: "Life at Guwahati",
  //     iconForPrimary: <LifeAtGuwahatiTab />,
  //     event: "d_ham_life_at_guwahati",
  //     isActive: false,
  //     isExternal: false,
  //   },
  {
    key: 5,
    type: "primary",
    path: "/videos",
    title: "Videos",
    iconForPrimary: <SelfLearning />,
    event: "d_ham_videos",
    isActive: false,
    isExternal: false,
  },
  {
    key: 6,
    type: "primary",
    path: "/media",
    title: "Media",
    iconForPrimary: <MediaTab />,
    event: "d_ham_media",
    isActive: false,
    isExternal: false,
  },
];

export const IITMandiOnwardLeftNavigationOptions: topLabelsListProps[] = [
  {
    key: 1,
    type: "primary",
    path: "/iit-mandi-ai-ml",
    title: "IIT Mandi - ML",
    iconForPrimary: <IItGCourse />,
    event: "d_ham_courses",
    isActive: false,
    isExternal: false,
  },
  {
    key: 2,
    type: "primary",
    path: `${window.location.protocol}//${process.env.REACT_APP_LEARN_DOMAIN}/learn/tutorials/prep-course`,
    title: "Introductory Course",
    iconForPrimary: <SelfLearning />,
    event: "d_ham_foundation_courses",
    isActive: false,
    isExternal: true,
  },
  {
    key: 3,
    type: "primary",
    path: "/curriculum",
    title: "Curriculum",
    iconForPrimary: <CurriculumTab />,
    event: "d_ham_curriculum",
    isActive: false,
    isExternal: false,
  },
];

export const masaiOnwardTabsLabelsList: tabsLabelList[] = [
  {
    key: 1,
    title: "Home",
    type: "tab",
    iconForTab: <Home />,
    path: "/",
    event: "d_ham_home",
  },
  {
    key: 2,
    title: "Courses",
    type: "tab",
    iconForTab: <BottomTabCourses />,
    path: "/courses",
    event: "d_ham_courses",
  },
  {
    key: 3,
    title: "Alumni",
    type: "tab",
    iconForTab: <PiStudent size={"24px"} />,
    path: `/alumni`,
    event: "d_ham_alumni_connect",
  },
  {
    key: 4,
    title: "Jobs",
    type: "tab",
    iconForTab: <BriefcaseV2 boxSize={"24px"} />,
    path: "/jobs",
    event: "d_ham_jobs",
    // badgeComponent: <Badge variant="brick" size="sm" label="NEW" />,
  },
  {
    key: 5,
    title: "MSAT",
    type: "tab",
    iconForTab: <PiStudent size={"24px"} />,
    event: "d_ham_msat",
    path: `/msat`,
  },
];

export const IITOnwardTabsLabelsList: tabsLabelList[] = [
  {
    key: 1,
    type: "tab",
    path: "/mc-iitg-cs",
    title: "IIT Guwahati",
    iconForTab: <IItGCourse />,
    event: "iitg_d_courses",
  },
  {
    key: 2,
    type: "tab",
    path: `${window.location.protocol}//${process.env.REACT_APP_LEARN_DOMAIN}/learn/tutorials/prep-course`,
    title: "Introductory Course",
    iconForTab: <SelfLearning />,
    event: "iitg_d_prep_course",
  },
  {
    key: 3,
    type: "tab",
    path: "/admission-and-fees",
    title: "Entrance Test",
    iconForTab: <PiStudent size={"24px"} />,
    event: "iitg_d_entrance_test",
  },
  {
    key: 3,
    type: "primary",
    path: "/curriculum",
    title: "Curriculum",
    event: "d_ham_curriculum",
    iconForTab: <CurriculumTab />,
  },
  //   {
  //     key: 4,
  //     type: "primary",
  //     path: "/life-at-guwahati",
  //     title: "Life at Guwahati",
  //     event: "d_ham_life_at_guwahati",
  //     iconForTab: <LifeAtGuwahatiTab />,
  //   },
  {
    key: 5,
    type: "primary",
    path: "/videos",
    title: "Videos",
    event: "d_ham_videos",
    iconForTab: <SelfLearning />,
  },
  {
    key: 6,
    type: "primary",
    path: "/media",
    title: "Media",
    event: "d_ham_media",
    iconForTab: <MediaTab />,
  },
];

export const IITMandiOnwardTabsLabelsList: tabsLabelList[] = [
  {
    key: 1,
    type: "tab",
    path: "/iit-mandi-ai-ml",
    title: "IIT Mandi",
    iconForTab: <IItGCourse />,
    event: "iitm_d_courses",
  },
  {
    key: 2,
    type: "tab",
    path: `${window.location.protocol}//${process.env.REACT_APP_LEARN_DOMAIN}/learn/tutorials/prep-course`,
    title: "Introductory Course",
    iconForTab: <SelfLearning />,
    event: "iitg_d_prep_course",
  },
  {
    key: 3,
    type: "primary",
    path: "/curriculum",
    title: "Curriculum",
    event: "d_ham_curriculum",
    iconForTab: <CurriculumTab />,
  },
];

export const iitMobileScreenHamburgerOptions: MobileHamburgerList[] = [
  {
    key: 1,
    title: "IIT Guwahati",
    type: "hamburger-label",
    active: true,
    path: "/mc-iitg-cs",
    badgeComponent: <Badge variant="red" label="New" />,
    event: "iitg_d_ham_courses",
  },
  {
    key: 2,
    title: "Introductory Course",
    type: "hamburger-label",
    active: true,
    path: `${window.location.protocol}//${process.env.REACT_APP_LEARN_DOMAIN}/learn/tutorials/prep-course`,
    isExternal: false,
    event: "iitg_d_ham_prep_course",
  },
  {
    key: 3,
    type: "primary",
    path: "/curriculum",
    title: "Curriculum",
    event: "d_ham_curriculum",
    active: false,
    isExternal: false,
  },
  //   {
  //     key: 4,
  //     type: "primary",
  //     path: "/life-at-guwahati",
  //     title: "Life at Guwahati",
  //     event: "d_ham_life_at_guwahati",
  //     active: false,
  //     isExternal: false,
  //   },
  {
    key: 5,
    type: "primary",
    path: "/videos",
    title: "Videos",
    event: "d_ham_videos",
    active: false,
    isExternal: false,
  },
  {
    key: 6,
    type: "primary",
    path: "/media",
    title: "Media",
    event: "d_ham_media",
    active: false,
    isExternal: false,
  },
];

export const iitMandiMobileScreenHamburgerOptions: MobileHamburgerList[] = [
  {
    key: 1,
    type: "tab",
    path: "/iit-mandi-ai-ml",
    title: "IIT Mandi",
    active: true,
    event: "iitm_d_courses",
    badgeComponent: <Badge variant="red" label="New" />,
  },
  {
    key: 2,
    type: "tab",
    path: `${window.location.protocol}//${process.env.REACT_APP_LEARN_DOMAIN}/learn/tutorials/prep-course`,
    title: "Introductory Course",
    event: "iitg_d_prep_course",
    active: false,
  },
  {
    key: 3,
    type: "tab",
    path: "/admission-and-fees",
    title: "Entrance Test",
    active: false,
    event: "iitg_d_entrance_test",
  },
];

export const IITRoparOnwardTabsLabelsList: tabsLabelList[] = [
  {
    key: 1,
    type: "tab",
    path: "/iit-ropar-ai",
    title: "IIT Ropar",
    iconForTab: <IItGCourse />,
    event: "iit_ropar_d_courses",
  },
  // {
  //   key: 2,
  //   type: "primary",
  //   path: "/curriculum",
  //   title: "Curriculum",
  //   event: "d_ham_curriculum",
  //   iconForTab: <CurriculumTab />,
  // },
];

export const IITRoparOnwardLeftNavigationOptions: topLabelsListProps[] = [
  {
    key: 1,
    type: "primary",
    path: "/iit-ropar-ai",
    title: "IIT Ropar - AI",
    iconForPrimary: <Home />,
    event: "iit_ropar_d_ham_courses",
    isActive: false,
    isExternal: false,
  },
  //   {
  //     key: 5,
  //     type: "primary",
  //     path: "/videos",
  //     title: "Videos",
  //     iconForPrimary: <SelfLearning />,
  //     event: "d_ham_videos",
  //     isActive: false,
  //     isExternal: false,
  //   },
  //   {
  //     key: 6,
  //     type: "primary",
  //     path: "/media",
  //     title: "Media",
  //     iconForPrimary: <MediaTab />,
  //     event: "d_ham_media",
  //     isActive: false,
  //     isExternal: false,
  //   },
];

export const adminUserLeftNavigationOptions: topLabelsListProps[] = [
  {
    key: 0,
    type: "primary",
    path: "/",
    title: "Home",
    iconForPrimary: <Home />,
    event: "home_courses",
    isActive: false,
    isExternal: false,
  },
  {
    key: 1,
    type: "primary",
    path: "/admin/events/all",
    title: "Events",
    iconForPrimary: <Home />,
    event: "d_ham_events",
    isActive: false,
    isExternal: false,
  },
  {
    key: 2,
    type: "primary",
    path: "/admin/payments/all",
    title: "Payment Details",
    iconForPrimary: <IndianRupee />,
    event: "d_ham_media",
    isActive: false,
    isExternal: false,
  },
  {
    key: 3,
    type: "primary",
    path: "/admin/users",
    title: "Users",
    iconForPrimary: <AccountTie />,
    event: "d_ham_media",
    isActive: false,
    isExternal: false,
  },
];
