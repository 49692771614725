import { Box, Heading, Text, Link } from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import { sendTrackingEvent } from "../../../../../components/common/utils/gtm";
import ScholarshipPoints from "./ScholarshipPoints";

const NeedHelp = () => {
  const location = useLocation();
  return (
    <Box>
      <ScholarshipPoints />
      <Box mt={"ms-24"}>
        <Heading as="h4">Need Help?</Heading>
        <Text mt={"12px"} textStyle={{ base: "body2", md: "body1" }}>
          Reach out to{" "}
          <Text
            display={"inline"}
            textStyle={{ base: "body2-md", md: "body1-md" }}
            color={"ms-blue.500"}
          >
            <Link
              cursor={"pointer"}
              isExternal={true}
              href="mailto:admissions@masaischool.com"
              color="ms-blue.500"
              textDecoration={"underline"}
              onClick={() => {
                sendTrackingEvent({
                  event: "sp_pp_admission_mail_id",
                  eventLabel: location.pathname,
                });
              }}
            >
              admissions@masaischool.com
            </Link>{" "}
          </Text>{" "}
          for any queries.
        </Text>
      </Box>
    </Box>
  );
};

export default NeedHelp;
