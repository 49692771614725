import React, { useContext } from "react";
import { Image } from "@chakra-ui/react";
import logo from "../../../assets/masailogo.svg";
import PrepleafLogo from "../../../assets/images/CoursePrograms/prepleaf-logo.svg";
import { AppContext } from "../../../context/ApplicationContext";

const Logo: React.FC = () => {
  const state = useContext(AppContext)[0];
  return <Image src={state.isScholar ? PrepleafLogo : logo} alt="masai-logo" w={{ base: "82px", md: "89px" }} h={{ base: "40", md: "40px" }} />;
};

export default Logo;
