import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Checkbox,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Input,
  Skeleton,
} from "@chakra-ui/react";
import { useAllCounsellingStatus } from "../../../../api/utils/hooks/useCounsellingStatus";
import { useAllUsersCounsellingRequests } from "../../../../api/utils/hooks/useAdmin";
import { useMutation } from "react-query";
import useSnackBar from "../../../common/general/SnackBar";
import { updateCounsellingRequestStatus } from "../../../../api/utils/api/v2/admin";
import { axiosErrorHandler } from "../../../../api/utils/error";
import Pagination from "../../../../pages/Profile/referrals/Pagination";
import { useLocation } from "react-router-dom";

const CounsellingRequestList = ({ offset, limit }: { offset: number; limit: number }) => {
  const {
    data: allUsersCounsellingRequests,
    refetch: refetchallUsersCounsellingRequests,
    isLoading: allUsersCounsellingRequestsLoading,
  } = useAllUsersCounsellingRequests(offset, limit);
  const snackbar = useSnackBar();
  const {
    mutate: updateCounsellingRequestStatusMutate,
    isLoading: updateCounsellingRequestStatusLoading,
  } = useMutation(updateCounsellingRequestStatus, {
    onSuccess: () => {
      refetchallUsersCounsellingRequests();
      snackbar.success("SUCCESS");
    },
    onError: (error) => {
      refetchallUsersCounsellingRequests();
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const [emailFilter, setEmailFilter] = useState("");
  const [sourceFilter, setSourceFilter] = useState("");

  const filteredCounsellingData = allUsersCounsellingRequests?.counselling_requests?.filter(
    (counselling) => {
      return (
        counselling?.profile?.email?.toLowerCase().includes(emailFilter.toLowerCase()) &&
        counselling?.source?.toLowerCase().includes(sourceFilter.toLowerCase())
      );
    }
  );

  return (
    <Skeleton
      isLoaded={!(updateCounsellingRequestStatusLoading || allUsersCounsellingRequestsLoading)}
    >
      <Box>
        {/* <Box mb={4} display={"flex"} gap="16px">
          <Input
            width={"fit-content"}
            placeholder="Filter by User Email"
            value={emailFilter}
            onChange={(e) => setEmailFilter(e.target.value)}
          />
          <Input
            width={"fit-content"}
            placeholder="Filter by Source"
            value={sourceFilter}
            onChange={(e) => setSourceFilter(e.target.value)}
          />
        </Box> */}
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Status</Th>
                <Th>Counselling Request Id</Th>
                <Th>User Email</Th>
                <Th>Profile Slug</Th>
                <Th>Source</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredCounsellingData?.map((counselling) => (
                <Tr key={counselling.id}>
                  <Td>{counselling.status}</Td>
                  <Td>
                    <Box>{counselling?.id}</Box>
                  </Td>
                  <Td>
                    <Box>{counselling?.profile?.email}</Box>
                  </Td>
                  <Td>
                    <Box>{counselling?.profile?.slug}</Box>
                  </Td>
                  <Td>
                    <Box>{counselling?.source}</Box>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Skeleton>
  );
};

export default CounsellingRequestList;
