import { useHistory } from "react-router-dom";
import { convertUrlToCDNUrl } from "../../../utils/utils";
import { OnboardingStepInterface } from "../../../constants/onboarding";
import { OnboardingLineDesktop, OnboardingGreenCheck } from "../../../assets/images";
import { Box, Button, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";

interface Props {
  shouldShowMasaiConnectBanner?: boolean;
  onboardingStepList: OnboardingStepInterface[];
}

const OnboardingStepsTracker = ({ onboardingStepList, shouldShowMasaiConnectBanner = false }: Props) => {
  const history = useHistory();
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex
      overflowX={"auto"}
      px={{ base: "0px", md: "24px" }}
      sx={{
        "&": {
          "-ms-overflow-style": "none",
          scrollbarWidth: "none",
        },
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Flex
        align={"center"}
        bg={"#F2F6FF"}
        justify={"space-between"}
        border={"1px solid #E5E5E5"}
        overflow={{ md: "hidden" }}
        marginX={{ base: "16px", md: "0px" }}
        rounded={"12px"}
        w={{ base: "fit-content", lg: "full" }}
      >
        <Flex align={"center"} bg={"#F2F6FF"} px={"24px"} py={"18px"}>
          {onboardingStepList.map((step, index) => (
            <Flex key={step.stepName} minW={180} maxW={240} gap={2} flexDir={"column"} minH={"89px"}>
              <Flex>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  rounded={"full"}
                  px={step.isStepCompleted ? "0px" : "12px"}
                  w={"32px"}
                  h={"32px"}
                  bg={step.isCurrentStep ? "#3470E4" : "white"}
                  color={step.isCurrentStep ? "white" : "black"}
                  fontSize={"14px"}
                  flexShrink={0}
                  border={"1px solid #848081"}
                  fontWeight={600}
                >
                  {step.isStepCompleted ? <Image width={"32px"} height={"32px"} objectFit={"contain"} src={OnboardingGreenCheck} /> : index + 1}
                </Box>
                <Image minW={"100%"} display={index === onboardingStepList.length - 1 ? "none" : "block"} height={{ base: "", sm: "", md: "28px" }} src={OnboardingLineDesktop} alt="onboaridng step" />
              </Flex>
              <Text color={step.isCurrentStep ? "#3470E4" : "#544D4F"} fontFamily={"inter"} fontWeight={600} fontSize={16}>
                {step.stepName}
              </Text>

              <Text color={"#3EB278"} fontWeight={400} fontSize={"12px"}>
                {step.isStepCompleted ? "Completed" : onboardingStepList.length - 1 ? step.additionalText : ""}
              </Text>
            </Flex>
          ))}
        </Flex>
        <Box
          display={shouldShowMasaiConnectBanner ? "block" : "none"}
          overflow={"hidden"}
          roundedTopRight={"12px"}
          roundedBottomRight={"12px"}
          p={{ base: 3, md: 4 }}
          h={"full"}
          bg={"#CFD0EE"}
          pos={"relative"}
          minW={{ base: "300px", md: "330px" }}
        >
          <Text fontSize={"14px"} fontFamily={"inter"} fontWeight={700} maxW={"261px"} color={"#21191B"}>
            Confused? Get it resolved by <br style={isMobile ? { display: "block" } : { display: "none" }} /> talking to our Alumni.
          </Text>

          <Button
            size="sm"
            onClick={() => {
              history.push("/alumni");
            }}
            bg={"white"}
            borderColor={"#3470E4"}
            color={"#3470E4"}
            mt={5}
          >
            Connect with ALUMNI
          </Button>
          <Image
            src={convertUrlToCDNUrl("https://masai-website-images.s3.ap-south-1.amazonaws.com/Image_nm_218c34d7da.webp")}
            objectFit={"contain"}
            pos={"absolute"}
            bottom={"10px"}
            right={"-12px"}
            w={"100px"}
            h={"110px"}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default OnboardingStepsTracker;
