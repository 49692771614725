import { Box, Image, Text, Heading, useBreakpointValue } from "@chakra-ui/react";
import StepsCard from "./StepsCard";
import CapDownArrow from "../../../assets/images/CapDownArrow.svg";
import CapDownArrowMobile from "../../../assets/images/CapDownArrowMobile.svg";
const CAPStepsInstructions = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const headingSize = useBreakpointValue<"h5" | "h6">({
    base: "h6",
    md: "h5",
  });
  const capStepsData = [
    {
      id: 1,
      text1: (
        <Heading as={headingSize} color={"ms-blue.500"}>
          Reserve{" "}
          <Text
            color={"ms-grey.800"}
            textStyle={{ base: "body2-md", md: "body1-md" }}
            display={"inline"}
          >
            your seat for ₹5,000
          </Text>
        </Heading>
      ),
      text2: (
        <Text color={"ms-grey.700"} textStyle={{ base: "body2-md", md: "body1-md" }}>
          Full refund if you wish to discontinue within 15 days free trial
        </Text>
      ),
    },
    {
      id: 2,
      text1: (
        <Text
          color={"ms-grey.800"}
          textStyle={{ base: "body2-md", md: "body1-md" }}
          display={"inline"}
        >
          Get guaranteed{" "}
          <Heading as={headingSize} display={"inline"} color={"ms-blue.500"}>
            Internship
          </Heading>
        </Text>
      ),
      text2: (
        <Text color={"ms-grey.700"} textStyle={{ base: "body2-md", md: "body1-md" }}>
          with stipend upto ₹40,000 after completion of 2 semesters
        </Text>
      ),
    },
    {
      id: 3,
      text1: (
        <Text
          color={"ms-grey.800"}
          textStyle={{ base: "body2-md", md: "body1-md" }}
          display={"inline"}
        >
          Get Job assurance of CTC{" "}
          <Heading as={headingSize} display={"inline"} color={"ms-blue.500"}>
            5 LPA
          </Heading>
        </Text>
      ),
      text2: (
        <Text color={"ms-grey.700"} textStyle={{ base: "body2-md", md: "body1-md" }}>
          or above after completion of 4 semesters
        </Text>
      ),
    },
  ];
  return (
    <Box>
      {capStepsData.map((item) => {
        return (
          <Box
            mx={{ base: "-8px", md: "0px" }}
            key={item.id}
            display={"flex"}
            flexDirection={"column"}
          >
            <StepsCard stepData={item} />
            <Image
              display={item.id === 3 ? "none" : "block"}
              h={{ base: "32px", md: "53px" }}
              src={isMobile ? CapDownArrowMobile : CapDownArrow}
              alt="cap steps"
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default CAPStepsInstructions;
