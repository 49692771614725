import { Box } from "@chakra-ui/react";
import Name from "./Name";
import DateOfBirth from "./DateOfBirth";
import EmailAddress from "./EmailAddress";
import MobileNumber from "./MobileNumber";
import ReferralCode from "./ReferralCode";
import MonthYearGraduation from "./MonthYearGraduation";
import AreYouWorking from "./AreYouWorking";

interface Props {
  isMasaiCoursePrefered: boolean;
}

const ProfileFields = ({ isMasaiCoursePrefered }: Props) => {
  return (
    <Box mt="16px" display={"grid"} gridTemplateColumns={{ md: "repeat(2,1fr)" }} gap="16px">
      <Name />
      <DateOfBirth />
      <MobileNumber />
      <EmailAddress />
      {isMasaiCoursePrefered ? <ReferralCode /> : null}
      <MonthYearGraduation />
      <AreYouWorking />
    </Box>
  );
};

export default ProfileFields;
