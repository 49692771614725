import { ProgramProfileType, CourseSelected, PaymentType, BatchTypes } from "../enums/ProgramType.enum";

export const OfferedProgramList = [
  { value: ProgramProfileType.MASAI, label: "Masai" },
  { value: ProgramProfileType.IIT_GUWAHATI, label: "IIT Guwahati" },
  { value: ProgramProfileType.IIT_MANDI, label: "IIT Mandi" },
  { value: CourseSelected.IIT_MANDI_V2, label: "IIT Mandi Next Gen" },
];

export const OfferedCourseList = [
  { value: CourseSelected.IIT_GUWAHATI, label: "IIT Guwahati" },
  { value: CourseSelected.IIT_MANDI, label: "IIT Mandi" },
  { value: CourseSelected.IIT_ROPAR, label: "IIT Ropar" },
  { value: CourseSelected.IIT_MANDI_V2, label: "IIT Mandi Next Gen" },
  //   { value: CourseSelected.MASAI, label: "EmpowerHer" },
];

export const OfferedCourseListForBatchChange = [
  { value: CourseSelected.IIT_GUWAHATI, label: "IIT Guwahati" },
  { value: CourseSelected.IIT_MANDI, label: "IIT Mandi" },
];

export const PaymentTypeList = [
  { value: PaymentType.REGISTRATION_FEES, label: "REGISTRATION_FEES" },
  { value: PaymentType.SEMESTER_FEES, label: "SEMESTER_FEES" },
  { value: PaymentType.SEMESTER_FEES_EMI, label: "SEMESTER_FEES_EMI" },
];

export const ActiveBatchList = [
  { value: BatchTypes.SD, label: "Software Development" },
  { value: BatchTypes.DA, label: "Data Analytics" },
  { value: BatchTypes.EMPOWER_HER, label: "EmpowerHer" },
];
