import { formatDateWithOrdinal } from "../../../utils/utils";
import { useActiveBatch } from "../../../api/utils/hooks/useBatch";
import { Box, Button, Flex, Image, Skeleton, Text } from "@chakra-ui/react";

const SDCourseCard = () => {
  const { data: activeBatch, isLoading: isActiveBatchLoading } = useActiveBatch("12");
  const course = {
    icon: "/img/revamp/courses/sd.svg",
    title: "Software Development",
    description: "",
    pointers: [
      { isBold: false, text: "Become job-ready in 30-35 weeks." },
      { isBold: true, text: "Full Stack Developer, Backend Developer, Software Engineer & much more." },
    ],
    courseLinkUrl: "/courses/12/details",
    courseType: "MASAI",
  };

  if (isActiveBatchLoading) return <Skeleton isLoaded={false} rounded="9.951px" maxW={{ base: "full", md: "390px" }} minW={{ base: "90%", sm: "380px", md: "auto" }} h={"369px"} w="full" px="17px" />;

  if (!activeBatch) return null;

  return (
    <Box
      maxW={{ base: "full", md: "390px" }}
      w="full"
      px="17px"
      h={"369px"}
      gap={0}
      py="27px"
      minW={{ base: "90%", sm: "380px", md: "auto" }}
      bg="white"
      rounded="9.951px"
      display="flex"
      flexDir="column"
      justifyContent="start"
      border="0.876px solid #E7E6E6"
      boxShadow="0px 5.258px 29.797px 0px rgba(215, 216, 222, 0.41)"
      position={"relative"}
      overflow={"hidden"}
    >
      <Flex display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
        <Box position={"absolute"} top={4} left={0} px={4} py={2} fontSize="16px" lineHeight="short" borderRadius="2xl" color="#0A0103">
          <Text as="span" fontWeight="bold">
            You can explore the
          </Text>
        </Box>
        <Box
          position={"absolute"}
          top={0}
          right={-1}
          px={{ base: 3, md: 4 }}
          py={{ base: 1, md: 2 }}
          fontSize="xs"
          lineHeight="short"
          bg="#FFE799"
          borderRadius="2xl"
          color="#6C6768"
          borderRight={"0px"}
          borderBottomRightRadius={0}
        >
          Starts:{" "}
          <Text as="span" fontWeight="bold">
            {formatDateWithOrdinal(activeBatch.start_date)}
          </Text>
        </Box>
      </Flex>

      <Flex gap={3} align="center" mt={8}>
        <Image src={course.icon} w={{ base: "39.982px", md: "56px" }} h={{ base: "39.982px", md: "48px" }} objectFit="contain" />
      </Flex>

      <Text fontSize={{ base: "13.659px", md: "20px" }} color="#192335" fontWeight={700} mt="8px" fontFamily="Poppins">
        {course.title}
      </Text>
      <Box border="0.4px solid #CECCCD" my={3} w="full" />
      <Flex flexDir="column" gap={3} mt={2}>
        {course.pointers.map((pointer, pointerIndex) => (
          <Flex key={pointerIndex} gap={2} alignItems="center">
            <Image src="/img/iit-course/checkbox.svg" alt="check" w="21px" h="21px" objectFit="contain" />
            <Text fontSize="14px" color="#21191B" fontWeight={pointer.isBold ? 700 : 600} fontFamily="Open Sans">
              {pointer.text}
            </Text>
          </Flex>
        ))}
      </Flex>

      <Box mt={"auto"} w="full" pt={{ base: "20px", md: "40px" }}>
        <Button size="md" variant="outline" as="a" href={course?.courseLinkUrl} colorScheme={"red"} w="full">
          View details
        </Button>
      </Box>
    </Box>
  );
};

export default SDCourseCard;
