import {
  Box,
  Heading,
  useRadio,
  useRadioGroup,
  Image,
  Text,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CheckedRadioImage from "../../../../assets/images/CheckedRadioImage.svg";
import UncheckedRadioImage from "../../../../assets/images/UncheckedRadioImage.svg";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import { AppContext } from "../../../../context/ApplicationContext";
import numberWithCommas from "../../utils/numberWithCommas";
import { ScholarCouponCode } from "./ScholarInstructions/ScholarCouponCode";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RadioCard = (props: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();
  const location = useLocation();
  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="ms-16"
        _checked={{
          bg: "ms-blue.50",
        }}
        p={{ base: "ms-12", md: "ms-16" }}
        border="1px solid #D9D9D9"
        h={"100%"}
      >
        {props.children}
      </Box>
    </Box>
  );
};
interface Props {
  startRazorpayPayment: (paymentType: "full-payment" | "subscription-payment") => void;
  setScholarPaymentPlanModalOpen?: (value: boolean) => void;
}
const ScholarPaymentPlanModal = ({
  startRazorpayPayment,
  setScholarPaymentPlanModalOpen,
}: Props) => {
  const options = ["full-payment", "subscription-payment"];
  const { getRadioProps } = useRadioGroup({
    name: "payment plan options",
    defaultValue: "full-payment",
    onChange: console.log,
  });

  const [state, dispatch] = useContext(AppContext);
  const coursePrice = state.applicationData?.batch_campus.batch.course_price || 0;
  const courseLevel = state.applicationData?.batch_campus?.batch?.course?.level;
  const fullPayment = getRadioProps({ value: "full-payment" }).isChecked;
  const subscriptionPayment = getRadioProps({ value: "subscription-payment" }).isChecked;
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [couponInDb, setCouponInDb] = useState<{
    discount_percentage: number;
    code: string;
  } | null>(null);
  const discount_percentage = state.applicationData?.discount_percentage || 0;
  useEffect(() => {
    setCouponInDb(state.applicationData?.current_coupon || null);
  }, [state.applicationData?.current_coupon]);

  // Math.floor(coursePrice - (coursePrice * discount_percentage) / 100
  const getDiscountedPrice = () => {
    let finalDiscountedPrice = 0;
    const priceAfterScholarDiscount = Math.floor(
      coursePrice - (coursePrice * discount_percentage) / 100
    );
    if (couponInDb?.discount_percentage) {
      finalDiscountedPrice = Math.floor(
        priceAfterScholarDiscount -
          (priceAfterScholarDiscount * couponInDb?.discount_percentage) / 100
      );
    } else {
      finalDiscountedPrice = priceAfterScholarDiscount;
    }
    return finalDiscountedPrice;
  };
  const { trackEvent } = useMatomo();
  return (
    <Box mx={{ base: "-8px", md: "0px" }}>
      <Heading as="h4">Select a payment plan</Heading>
      <Box
        mt={{ base: "16px", md: "24px" }}
        // gridTemplateColumns={"repeat(2,1fr)"}
        gridTemplateColumns={"repeat(2,1fr)"}
        gridAutoRows="1fr"
        display={"grid"}
        columnGap={{ base: "ms-8", md: "ms-12" }}
      >
        {options.map((value) => {
          const radio = getRadioProps({ value });
          return (
            <Box key={value}>
              <RadioCard {...radio}>
                <Box d={"flex"} flexDirection={"column"} h={"100%"}>
                  <Box display={"flex"}>
                    <Image
                      src={radio.isChecked ? CheckedRadioImage : UncheckedRadioImage}
                      alt="checked"
                      mr={{ base: "ms-4", md: "ms-8" }}
                      alignSelf={"flex-start"}
                    />
                    <Text
                      textStyle={
                        radio.isChecked
                          ? { base: "body2-md", md: "body1-md" }
                          : { base: "body2", md: "body1" }
                      }
                    >
                      {radio.value === "full-payment"
                        ? "Pay in Full"
                        : isMobile
                        ? "Pay monthly"
                        : "Pay monthly for 6 months"}
                    </Text>
                  </Box>
                  <Box mt={"auto"}>
                    <Text
                      fontSize={{ base: "20px", md: "ms-24" }}
                      lineHeight={{ base: "28px", md: "ms-32" }}
                      fontWeight={"700"}
                      color={"ms-blue.500"}
                      fontFamily={"poppins"}
                      mt={{ base: "ms-16", md: "ms-24" }}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      ₹{" "}
                      {radio.value === "full-payment"
                        ? numberWithCommas(getDiscountedPrice())
                        : `${numberWithCommas(Math.floor(coursePrice / 6))}`}
                      {radio.value === "full-payment" &&
                        (discount_percentage > 0 || couponInDb?.discount_percentage) && (
                          <Text
                            ml={"ms-8"}
                            as="s"
                            color={"ms-brick.500"}
                            textStyle={{ base: "caption", lg: "body1" }}
                          >
                            ₹{numberWithCommas(coursePrice)}
                          </Text>
                        )}
                      {radio.value === "subscription-payment" && (
                        <Text
                          ml={"ms-8"}
                          d={{ base: "none", md: "inline" }}
                          color={"ms-grey.800"}
                          textStyle={"caption"}
                        >
                          per month
                        </Text>
                      )}
                    </Text>
                    {radio.value === "subscription-payment" ? (
                      <Text
                        d={{ base: "block", md: "none" }}
                        mt={"ms-4"}
                        color={"ms-grey.800"}
                        textStyle={"caption"}
                      >
                        per month for 6 months
                      </Text>
                    ) : (
                      <Text
                        d={{ base: "block", md: "none" }}
                        mt={"ms-4"}
                        visibility={"hidden"}
                        color={"ms-grey.800"}
                        textStyle={"caption"}
                      >
                        NA
                      </Text>
                    )}
                    {discount_percentage > 0 && (
                      <Text textStyle={"caption"} mt={"ms-4"} color={"ms-brick.500"}>
                        {radio.value === "full-payment"
                          ? "Scholarship Added!"
                          : `Scholarship not applicable for 
                        monthly payments.`}
                      </Text>
                    )}
                  </Box>
                  <Box mt={"auto"}>
                    <Text textStyle={"caption"} mt={"ms-12"}>
                      Avail a 2 week free trial. Full refund if you wish to discontinue within 2
                      weeks.
                    </Text>{" "}
                  </Box>
                </Box>
              </RadioCard>
            </Box>
          );
        })}
      </Box>
      {/* <Text color={"ms-grey.700"} textStyle={"body2"}>
        * Terms and conditions apply.
      </Text> */}
      <ScholarCouponCode
        couponInDb={couponInDb}
        paymentType={fullPayment ? "full-payment" : "subscription-payment"}
      />
      <Box
        onClick={() => {
          trackEvent({
            category: "Dashboard",
            action: "Continue_Payment",
            name:
              courseLevel == 1
                ? discount_percentage > 0
                  ? "continue_to_payment_with_scholarship_scholar_l1"
                  : "continue_to_payment_without_scholarship_scholar_l1"
                : discount_percentage > 0
                ? "continue_to_payment_with_scholarship_scholar_l2"
                : "continue_to_payment_without_scholarship_scholar_l2",
            value: discount_percentage > 0 ? 500 : 600,
          });
          sendTrackingEvent({
            event: "sp_pp_db_continue_to_payment",
            eventLabel: location.pathname,
          });

          setScholarPaymentPlanModalOpen && setScholarPaymentPlanModalOpen(false);
          startRazorpayPayment(fullPayment ? "full-payment" : "subscription-payment");
        }}
        mt={"ms-24"}
        display={"flex"}
        justifyContent={"flex-end"}
      >
        <Button variant={"primary"}>continue to payment</Button>
      </Box>
    </Box>
  );
};

export default ScholarPaymentPlanModal;
