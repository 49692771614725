import React from "react";

import { Box } from "@chakra-ui/react";
import ProfileFieldCard from "./ProfileFieldCard";
import { useProfile } from "../../../api/utils/hooks/useProfile";

const Name = () => {
  const { data: profile } = useProfile();
  return (
    <Box>
      <ProfileFieldCard heading="Name" title={profile?.name} />
    </Box>
  );
};

export default Name;
