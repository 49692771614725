import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Text,
  useBreakpointValue,
  useClipboard,
} from "@chakra-ui/react";
import React, {useState } from "react";
import ModalTemplateV2 from "../../common/general/ModalTemplateV2";
import CopyCodeModalContent from "../Referrals/Invite/CopyCodeModalContent";
import { InputCustom, ModalTemplate, RadioButtonGroup } from "../../common/general";
import DateSelect from "../../common/DateSelect";
import dayjs from "dayjs";
import { useMutation } from "react-query";

import useSnackBar from "../../common/general/SnackBar";
import { sendPhoneOtpV2 } from "../../../api/utils/api/v2/profile";
import { axiosErrorHandler } from "../../../api/utils/error";
import { useProfile } from "../../../api/utils/hooks/useProfile";

import EmailVerifyModalV2 from "../../common/V2/EmailVerifyModalV2";
import { sendEmailOtpV2 } from "../../../api/utils/api";
import OTPVerifyModalV2 from "../../common/V2/OTPVerifyModalV2";
import UpdatePhoneModalContent from "./UpdatePhoneModalContent";
import { sendTrackingEvent } from "../../common/utils/gtm";
import { useLocation } from "react-router-dom";
import { CheckCircle } from "../../../assets/icons/components";
export const phoneRegExp = /^[6-9]\d{9}$/;
interface Props {
  title?: string;
  heading?: string;
  keyName?: string;
  fromOnboarding?: boolean;
}

const ProfileFieldCard = ({ keyName, title, heading, fromOnboarding }: Props) => {
  const [showCopyCodeModal, setShowCopyCodeModal] = React.useState(false);
  const { hasCopied, onCopy } = useClipboard(title || "");
  const [showEditUi, setShowEditUi] = React.useState<string | null>(null);
  const isMobile = useBreakpointValue({ base: true, md: false });

  React.useEffect(() => {
    if (hasCopied) setShowCopyCodeModal(true);
  }, [hasCopied]);
  const [graduationMonth, setGraduationMonth] = React.useState<number | null>(null);
  const [graduationYear, setGraduationYear] = React.useState<number | null>(null);
  const [currentlyWorking, setCurrentlyWorking] = React.useState<boolean | null>(null);

  const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(false);
  const [showEmailOtpModal, setShowEmailOtpModal] = useState(false);

  const [showUpdatePhoneModal, setShowUpdatePhoneModal] = useState(false);
  const [showPhoneOtpModal, setShowPhoneOtpModal] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMonthYearOfGraduationChange = (value: any) => {
    const parsedDate = dayjs(value);
    const year = parsedDate.year();

    const month = parsedDate.month() + 1;
    setGraduationMonth(month);
    setGraduationYear(year);
  };
  const snackbar = useSnackBar();
  const { data: profile, refetch: refetchProfile } = useProfile();

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const { mutate: sendEmailOtpMutate, isLoading: sendEmailOtpLoading } = useMutation(
    sendEmailOtpV2,
    {
      onSuccess: () => {
        snackbar.success("Otp sent on email!");
        if (!showEmailOtpModal) {
          setShowEmailOtpModal(true);
        }
        if (showUpdateEmailModal) {
          setShowUpdateEmailModal(false);
        }
      },
      onError: (error) => {
        const e = axiosErrorHandler(error);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("Something went wrong");
        }
      },
    }
  );

  const [updatedEmail, setUpdatedEmail] = useState<string>("");
  const [updatedPhone, setUpdatedPhone] = useState<string>("");

  const { mutate: sendPhoneOtpMutate, isLoading: sendPhoneOtpLoading } = useMutation(
    sendPhoneOtpV2,
    {
      onSuccess: (data) => {
        snackbar.success("Otp sent on mobile!");
        if (!showPhoneOtpModal) {
          setShowPhoneOtpModal(true);
        }
      },
      onError: (error) => {
        const e = axiosErrorHandler(error);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("Something went wrong");
        }
      },
    }
  );
  const location = useLocation();
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        borderRadius={"16px"}
        bgColor={"#fff"}
        border="1px solid #E5E5E5"
        p="16px"
        flexWrap="wrap"
        gap="5px 0px"
      >
        <Box>
          {heading && (
            <Text textStyle={"body2-md"} color="ms-red.900">
              {heading}
            </Text>
          )}
          {showEditUi ? (
            <Box mt="8px">
              {keyName === "month-year-graduation" && (
                <Box>
                  <DateSelect
                    bgColor={"#fff"}
                    showRelativeDate={false}
                    onChange={(value) => {
                      handleMonthYearOfGraduationChange(value);
                    }}
                    value={
                      graduationMonth && graduationYear
                        ? dayjs()
                            .month(graduationMonth - 1)
                            .year(graduationYear)
                        : null
                    }
                    isInvalid={false}
                    errorMessage="Please add your month and year of graduation."
                    onClickCallback={() => {
                      //
                    }}
                    yearAndMonthOnly={true}
                    disabled={false}
                  />
                </Box>
              )}
              {keyName === "are-you-working" && (
                <Box>
                  <RadioButtonGroup
                    options={[
                      { key: "yes", value: "Yes" },
                      { key: "no", value: "No" },
                    ]}
                    selectedValue={
                      currentlyWorking === true ? "yes" : currentlyWorking === false ? "no" : ""
                    }
                    onChange={(key) => {
                      setCurrentlyWorking(key === "yes" ? true : false);
                    }}
                    gridTemplateComlumns={{ md: "repeat(2,1fr)" }}
                    columnGap={"ms-4"}
                    rowGap={"ms-24"}
                  />
                </Box>
              )}
            </Box>
          ) : (
            <>
              {title && (
                <Text mt="8px" textStyle={"body2"} color="#3B3435)">
                  {title}
                </Text>
              )}
            </>
          )}
        </Box>

        <Box display="flex" w={{ base: "full", md: "fit-content" }} justifyContent="end">
          {keyName === "referral-code" && (
            <Box>
              <Button
                onClick={() => {
                  onCopy();
                }}
                size="sm"
                variant="primary"
              >
                REFER NOW
              </Button>
            </Box>
          )}
          {/* Phone Verification Button */}
          {keyName === "phone" && (
            <>
              {profile?.otp_verified && profile?.phone_number ? null : (
                <Button
                  onClick={() => {
                    sendPhoneOtpMutate(profile?.phone_number || "");
                  }}
                  variant="primary"
                  size="sm"
                  isLoading={sendPhoneOtpLoading}
                >
                  Verify
                </Button>
              )}
            </>
          )}
          {/* Email Verification Button */}
          {keyName === "email" && (
            <>
              {profile && !profile.email ? (
                <Button
                  onClick={() => {
                    setShowUpdateEmailModal(true);
                  }}
                  variant="tertiary"
                  size="sm"
                  isLoading={sendEmailOtpLoading}
                >
                  Add Email
                </Button>
              ) : profile?.email && profile?.email_verified ? (
                <Button
                  colorScheme="green"
                  variant="solid"
                  size={fromOnboarding ? (isMobile ? "sm" : "md") : "sm"}
                  textStyle="btn-md"
                  my={fromOnboarding ? "auto" : "0px"}
                  cursor={"auto"}
                  disabled={true}
                  _disabled={{
                    _hover: { backgroundColor: "rgba(111, 205, 158, 1) !important" },
                    background: "rgba(111, 205, 158, 1)",
                  }}
                  rightIcon={<CheckCircle boxSize={isMobile ? "16px" : "24px"} />}
                >
                  verified
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    sendTrackingEvent({
                      event: "d_profile_email_verify",
                      eventLabel: location.pathname,
                    });
                    sendEmailOtpMutate(profile?.email || "");
                  }}
                  variant="primary"
                  my={fromOnboarding ? "auto" : "0px"}
                  size={fromOnboarding ? (isMobile ? "sm" : "md") : "sm"}
                  isLoading={sendEmailOtpLoading}
                >
                  Verify
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
      <ModalTemplateV2
        isOpen={showCopyCodeModal}
        onClose={() => {
          setShowCopyCodeModal(false);
        }}
        showCloseButtonIcon={false}
        marginX={2}
        maxWidth="472px"
        showConfetti={true}
        hideIconWithConfetti={true}
        bgColor={"#FFFAFB"}
      >
        <CopyCodeModalContent setShowCopyCodeModal={setShowCopyCodeModal} />
      </ModalTemplateV2>

      {/* -------------------------------------- Phone verify Modal flow----------------------------------------- */}
      <OTPVerifyModalV2
        showPhoneOtpModal={showPhoneOtpModal}
        setShowPhoneOtpModal={setShowPhoneOtpModal}
        updatedPhone={updatedPhone}
        setShowUpdatePhoneModal={setShowUpdatePhoneModal}
        isClosable={true}
      />
      {/* Phone update modal */}
      <ModalTemplate
        title=""
        isOpen={showUpdatePhoneModal ? showUpdatePhoneModal : false}
        onClose={() => {
          // console.log("clearing timers due to update Phone modal closed!");
          refetchProfile();
          setShowUpdatePhoneModal && setShowUpdatePhoneModal(false);
        }}
        marginX={2}
        maxWidth="464px"
        showCloseButtonIcon={true}
      >
        <UpdatePhoneModalContent
          showPhoneOtpModal={showPhoneOtpModal}
          setShowPhoneOtpModal={setShowPhoneOtpModal}
          updatedPhone={updatedPhone}
          setUpdatedPhone={setUpdatedPhone}
        />
      </ModalTemplate>
      {/* -------------------------------------- Email verify Modal flow----------------------------------------- */}
      <EmailVerifyModalV2
        showEmailOtpModal={showEmailOtpModal}
        setShowEmailOtpModal={setShowEmailOtpModal}
        updatedEmail={updatedEmail}
        setShowUpdateEmailModal={setShowUpdateEmailModal}
      />

      {/* Email update modal */}
      <ModalTemplate
        title=""
        isOpen={showUpdateEmailModal ? showUpdateEmailModal : false}
        onClose={() => {
          // console.log("clearing timers due to update email modal closed!");
          refetchProfile();
          setShowUpdateEmailModal && setShowUpdateEmailModal(false);
        }}
        marginX={2}
        maxWidth="464px"
        showCloseButtonIcon={true}
      >
        {
          <Box>
            <Heading as="h4">{profile?.email ? "Update your email" : "Add your email"}</Heading>
            {profile?.email && (
              <>
                <Text mt={"ms-16"} textStyle="body2" color="ms-grey.700">
                  Your current email address:
                </Text>
                <Text mb={"ms-8"} mt={"ms-4"} textStyle="body2-md" color="ms-grey.700">
                  {profile?.email}
                </Text>
              </>
            )}
            <Text mt={"ms-24"} mb={"ms-8"} textStyle="body2-md" color="ms-grey.800">
              {profile?.email
                ? "Please enter your new email address"
                : "Please enter your email address"}
            </Text>
            <InputCustom
              required
              type="email"
              isInvalid={updatedEmail !== "" && !emailRegex.test(updatedEmail)}
              getValueCallback={(value) => {
                setUpdatedEmail(value);
              }}
              errorMessage={"Please enter a valid email"}
              placeholder="Enter your new email address"
              onClick={() => {
                //
              }}
            />
            <Flex>
              <Button
                mt={updatedEmail !== "" && !emailRegex.test(updatedEmail) ? "14px" : "46px"}
                onClick={() => {
                  sendEmailOtpMutate(updatedEmail || profile?.email);
                }}
                size="md"
                variant="primary"
                ml={"auto"}
                disabled={!updatedEmail || sendEmailOtpLoading || !emailRegex.test(updatedEmail)}
                position={sendEmailOtpLoading ? "relative" : "static"}
              >
                update & verify
                {sendEmailOtpLoading && (
                  <Spinner
                    speed={"0.5s"}
                    thickness={"2px"}
                    size={"md"}
                    position={"absolute"}
                    color={"ms-blue.500"}
                  />
                )}
              </Button>
            </Flex>
          </Box>
        }
      </ModalTemplate>
    </>
  );
};

export default ProfileFieldCard;
