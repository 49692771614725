import React, { useContext, useEffect, useState } from "react";
import { Box, Image, Heading, Flex, Stack, Button, HStack, useBreakpointValue, Badge, Link as ChakraLink, Spacer, Spinner } from "@chakra-ui/react";

import { Link, useLocation, useParams } from "react-router-dom";
import EventDetailsSection from "./EventDetailsSection";
import { GetEventType } from "../../api/schemas/schema";
import IconWithLabel from "../common/general/IconWithLabel";
import { CalenderRange, ClockOutline, CurrencyINR, Laptop, InformationOutline } from "../../assets/icons/components";
import dayjs from "dayjs";
import useCleverTap, { ButtonTypes, CleverTapEvents, ModalNames, ScreenNames } from "../../clevertap/useCleverTap";
import { AppContext } from "../../context/ApplicationContext";
import { bookEvent, getEventById } from "../../api/utils/api/events";
import useSnackBar from "../common/general/SnackBar";
import { ModalTemplate } from "../common/general";

import EventRegisterModal from "./book-event/EventRegisterModal";
import { axiosErrorHandler } from "../../api/utils/error";
import { sendTrackingEvent } from "../common/utils/gtm";

interface Props {
  event: GetEventType;
  autoRegisterThisEvent?: boolean;
  setAutoRegisterEventId: (id: number | null) => void;
}

export const getEventDetails = ({
  cost,
  event_start_date,
  event_end_date,
  event_location,
}: {
  cost: number;
  event_start_date: string;
  event_end_date: string;
  event_location: string;
}): {
  icon: React.ReactElement;
  text: string;
  name: string;
}[] => {
  return [
    {
      name: "calendar",
      icon: <CalenderRange boxSize="5" color="ms-grey.600" />,
      text: dayjs(event_start_date).format("ddd, MMM DD,YYYY"),
    },
    {
      name: "clock",
      icon: <ClockOutline boxSize="5" color="ms-grey.600" />,
      text: `${dayjs(event_start_date).format("hh:mm A")} - ${dayjs(event_end_date).format("hh:mm A")}`,
    },
    {
      name: "laptop",
      icon: <Laptop boxSize="5" color="ms-grey.600" />,
      text: `${event_location}`,
    },
    {
      name: "currency",
      icon: <CurrencyINR boxSize="5" color="ms-grey.600" />,
      text: cost === 0 ? "Free" : cost.toString(),
    },
  ];
};

const EventCard = ({ event: eventProp, autoRegisterThisEvent, setAutoRegisterEventId }: Props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [event, setEvent] = useState<GetEventType>(eventProp);
  const params = useParams() as { eventID: string };
  const state = useContext(AppContext)[0];
  const snackbar = useSnackBar();
  const meeting_url = event.details?.links?.find((item) => item.name == "meeting_url")?.content;
  const event_video_url = event.details?.links?.find((item) => item.name == "event_video_url")?.content;
  const [isRegisterModalOpen, setIsRegisterModalOpen] = React.useState(false);
  const breakpointValues = useBreakpointValue({
    base: { badgeSize: undefined, btnVariant: "secondary", btnSize: "sm" },
    md: { badgeSize: "lg", btnVariant: "tertiary", btnSize: "md" },
  });
  const clevertap = useCleverTap()[0];
  const eventDetails = getEventDetails({
    cost: Number(event?.cost),
    event_start_date: event.event_start_date,
    event_end_date: event.event_end_data,
    event_location: event.event_location,
  });
  const location = useLocation();

  useEffect(() => {
    if (autoRegisterThisEvent) {
      // event is bootcamp, techtonic shift or crack the code
      if (["BOOTCAMP", "TECHTONIC_SHIFT", "CRACK_THE_CODE"].includes(event.event_type)) {
        if (event?.registered) {
          setAutoRegisterEventId(null);
          const link = event.event_type === "BOOTCAMP" || event.event_type === "CRACK_THE_CODE" ? event_video_url : meeting_url;
          window.location.href = link || "";
        } else {
          handleBookEvent();
        }
      } else {
        // event is AMA
        if (!event?.registered) {
          handleBookEvent();
        }
        setAutoRegisterEventId(null);
        window.location.href = meeting_url || "";
        // window.open(meeting_url);
      }
    }
  }, [autoRegisterThisEvent]);
  // clever tap event
  const onClickDetailsTrack = () => {
    sendTrackingEvent({ event: "dash-events-view_details", eventLabel: location.pathname });
    clevertap.fireEvent(CleverTapEvents.click_view_details, {
      screen_name: ScreenNames.dashboardEvents,
      button_text: "view details",
      button_type: ButtonTypes.secondary,
      event_name: event.name,
      event_start_date: event.event_start_date,
      event_type: event.event_type,
    });
  };
  const onRegisterTrack = (buttonText = "Register") => {
    if (buttonText === "Register") {
      sendTrackingEvent({ event: "dash-events-Register", eventLabel: location.pathname });
    } else if (buttonText === "Join Event") {
      sendTrackingEvent({ event: "dash-events-Join", eventLabel: location.pathname });
    }

    clevertap.fireEvent(CleverTapEvents.click_register, {
      screen_name: ScreenNames.dashboardEvents,
      button_text: buttonText,
      button_type: ButtonTypes.primary,
      event_name: event.name,
      event_start_date: event.event_start_date,
      event_type: event.event_type,
    });
  };
  const onModalDiscordBtnTrack = () => {
    clevertap.fireEvent(CleverTapEvents.click_join_discord, {
      screen_name: ScreenNames.dashboardEvents,
      button_text: "Continue with discord",
      button_type: ButtonTypes.secondary,
      modal_name: ModalNames.bootcampRegisteredSuccessfully,
    });
  };
  const fetchData = (eventId: number) => {
    return getEventById(eventId.toString())
      .then((res) => {
        setEvent(res);
      })
      .catch((err) => {
        // manage error
        const e = axiosErrorHandler(err);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("something went wrong");
        }
        setLoading(false);
      });
  };
  const handleBookEvent = async () => {
    try {
      if (!event.id || !state.profileData?.slug) return;
      setLoading(true);
      const response = await bookEvent(Number(event.id), state.profileData.slug);
      if (response) {
        if (event.event_type == "CRACK_THE_CODE") {
          snackbar.success("Redirecting you to registration page!");
        } else {
          snackbar.success("Booked your event!");
        }
      }
      await fetchData(event.id);
      setLoading(false);
      // Show success modal only for Bootcamp event
      if (event.event_type === "BOOTCAMP") setIsRegisterModalOpen(true);
      if (event.event_type === "TECHTONIC_SHIFT" && meeting_url) window.open(meeting_url, "_blank");
      if (event.event_type === "CRACK_THE_CODE" && event_video_url) window.open(event_video_url, "_blank");
    } catch (err) {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
      setLoading(false);
    }
  };
  const onRegisterModalClose = () => {
    setIsRegisterModalOpen(false);
    clevertap.fireEvent(CleverTapEvents.click_close_modal, {
      screen_name: ScreenNames.dashboardEvents,
      modal_name: ModalNames.bootcampRegisteredSuccessfully,
    });
  };

  return (
    <>
      <Box minW={{ base: "full", md: "360px" }} py="ms-16" border={"1px solid #E7E6E6"} borderRadius={"ms-16"} d={"flex"} flexDirection={"column"}>
        <Box px={{ base: "0.75rem", md: "1rem" }} h={"100%"} d={"flex"} flexDirection={"column"}>
          <Image src={event?.details?.links?.find((item) => item?.name == "event_image_url")?.content} alt="event" borderRadius={"ms-16"} w="full" />
          <Box pt={"ms-12"} pb="ms-8" mt={"auto"}>
            <HStack>
              <Badge variant={event?.details?.details?.find((item) => item.name == "badge_color")?.content} size={breakpointValues?.badgeSize}>
                {event.event_type.replaceAll("_", " ")}
              </Badge>
              <Spacer />
              <Box mt="ms-8">
                {event.event_type == "BOOTCAMP" ||
                  (event.event_type == "CRACK_THE_CODE" && (
                    <IconWithLabel color="ms-grey.600" textStyle={"caption"} icon={<InformationOutline boxSize="4" color="#B2B2B2" />} label="Laptop/deskop required" />
                  ))}
              </Box>
            </HStack>
            <Box minH={"48px"} mt={"ms-8"} mb={"ms-16"}>
              <Heading color="ms-red.900" as="h5">
                {event.name}
              </Heading>
            </Box>
            <Stack mb={"ms-32"} spacing={"ms-16"}>
              <EventDetailsSection eventDetail={eventDetails} />
            </Stack>
          </Box>
        </Box>
        <Box mt={"auto"}>
          <Flex justifyContent="flex-end" px="14px" gap="ms-24">
            <Link to={`/events/${event.id}/book`}>
              <Button size={breakpointValues?.btnSize} variant={breakpointValues?.btnVariant} onClick={onClickDetailsTrack}>
                View Details
              </Button>
            </Link>

            {["BOOTCAMP", "TECHTONIC_SHIFT", "CRACK_THE_CODE"].includes(event.event_type) ? (
              event?.registered ? (
                // Case 1: Already Registered in Bootcamp (or TECHTONIC_SHIFT : same flow as Bootcamp ): Just redirect to Discord
                <ChakraLink isExternal href={event.event_type === "BOOTCAMP" || event.event_type === "CRACK_THE_CODE" ? event_video_url : meeting_url} _hover={{ textDecoration: "none" }}>
                  <Button
                    size={breakpointValues?.btnSize}
                    variant={"primary"}
                    onClick={() => {
                      onRegisterTrack("Join Event");
                    }}
                  >
                    {event.event_type === "CRACK_THE_CODE" ? "Register" : "Join Event"}
                  </Button>
                </ChakraLink>
              ) : (
                // Case 2: Not yet Registered in Bootcamp (or TECHTONIC_SHIFT : same flow as Bootcamp ): Hit register api and show Success Modal
                <Button
                  size={breakpointValues?.btnSize}
                  variant={"primary"}
                  onClick={() => {
                    onRegisterTrack();
                    handleBookEvent();
                  }}
                  disabled={loading}
                  position={loading ? "relative" : "static"}
                >
                  Register
                  {loading && <Spinner speed={"0.5s"} thickness={"2px"} size={"md"} position={"absolute"} color={"ms-blue.500"} />}
                </Button>
              )
            ) : event?.registered ? (
              // Case 3: Registered in AMA: Just redirect to zoom webinar.
              <ChakraLink isExternal href={meeting_url} _hover={{ textDecoration: "none" }}>
                <Button
                  size={breakpointValues?.btnSize}
                  variant={"primary"}
                  onClick={() => {
                    onRegisterTrack();
                  }}
                >
                  Register
                </Button>
              </ChakraLink>
            ) : (
              // Case 4: Not yet Registered in AMA: Hit event register API, and just redirect to zoom webinar.
              <ChakraLink isExternal href={meeting_url} _hover={{ textDecoration: "none" }}>
                <Button
                  size={breakpointValues?.btnSize}
                  variant={"primary"}
                  onClick={() => {
                    onRegisterTrack();
                    handleBookEvent();
                  }}
                  disabled={loading}
                  position={loading ? "relative" : "static"}
                >
                  Register
                  {loading && <Spinner speed={"0.5s"} thickness={"2px"} size={"md"} position={"absolute"} color={"ms-blue.500"} />}
                </Button>
              </ChakraLink>
            )}
          </Flex>
        </Box>
      </Box>
      <ModalTemplate title="" isOpen={isRegisterModalOpen} onClose={onRegisterModalClose} marginX={2} maxWidth="552px" showCloseButtonIcon={true}>
        <EventRegisterModal url={meeting_url} onModalDiscordBtnTrack={onModalDiscordBtnTrack} />
      </ModalTemplate>
    </>
  );
};

export default EventCard;
