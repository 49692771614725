import { Box, Image, Heading, Text, Link } from "@chakra-ui/react";
import React from "react";
import { InvalidAadhaar } from "../../../../assets/images";
const InvalidAadhaarModalContent = () => {
  return (
    <Box textAlign={"center"}>
      <Image
        mx={"auto"}
        mb={"ms-24"}
        width={"56px"}
        height={"56px"}
        src={InvalidAadhaar}
        alt="InvalidAadhaar"
      />
      <Heading as="h4">Could not recognize Aadhaar</Heading>
      <Text color={"ms-hrey.800"} mt={"ms-8"} textStyle={"body2"}>
        The file that you uploaded was incorrect and we’re unable to detect your Aadhaar
        information. Please make sure the file that you’re uploading is correct and try again. Do
        reach out to us at{" "}
        <Text mt={"ms-8"} textStyle={"body2-md"} d={"inline"}>
          <Link
            cursor={"pointer"}
            isExternal={true}
            href="mailto:admissions@masaischool.com"
            color="ms-blue.500"
            textStyle={"body2-md"}
          >
            admissions@masaischool.com
          </Link>{" "}
        </Text>{" "}
        for any queries.
      </Text>
    </Box>
  );
};

export default InvalidAadhaarModalContent;
