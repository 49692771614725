import React from "react";
import { SingleQuestion } from "./NewLaunchForm";
interface Props {
  question: SingleQuestion;
  course_type?: string;
}
const InputQuestion = ({ question, course_type }: Props) => {
  return course_type === "FULL_TIME" ? (
    <input
      key={question.name}
      required
      minLength={question.minLength}
      maxLength={question.maxLength}
      type="text"
      name={question.name}
      cf-questions={question.title}
      cf-error={"Invalid answer!"}
      //  checking whether to display current question or not based on current_status answer of user
      cf-conditional-current_status={
        question.name.includes("current_status_section") && "Working||Quit Job to join Masai"
      }
    />
  ) : (
    <input
      key={question.name}
      required
      minLength={question.minLength}
      maxLength={question.maxLength}
      type="text"
      name={question.name}
      cf-questions={question.title}
      cf-error={"Invalid answer!"}
      //  checking whether to display current question or not based on current_status answer of user
      cf-conditional-current_status={
        question.name.includes("current_status_section") &&
        "I am currently working||I am taking a break from work||I just quit my job"
      }
      cf-conditional-how_did_you_hear_about_us={
        question.name.includes("how_did_you_hear_about_us_section") && "Other"
      }
    />
  );
};

export default InputQuestion;
