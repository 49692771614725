import React from "react";
import { Text, Badge as ChakraBadge } from "@chakra-ui/react";
interface Props {
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  variant: string;
  label?: string;
  size?: string;
  customTextColor?: string;
}
const Badge = ({ customTextColor, leftIcon, rightIcon, variant, label, size }: Props) => {
  return (
    <>
      <ChakraBadge variant={variant} d={"flex"} alignItems={"center"} size={size}>
        {leftIcon && React.cloneElement(leftIcon, { boxSize: 4 })}
        {label ? ( // this will be rendered only if label prop is passed otherwise not.
          <Text
            ml={leftIcon ? 1 : 0}
            mr={rightIcon ? 1 : 0}
            textStyle="btn-sm"
            textTransform={"initial"}
            color={customTextColor}
          >
            {label}
          </Text>
        ) : null}
        {rightIcon && React.cloneElement(rightIcon, { boxSize: 4 })}
      </ChakraBadge>
    </>
  );
};

export default Badge;
