import { createContext, Dispatch, useReducer } from "react";

export enum OnboardingActionType {
  SET_ELIGIBLITY_FORM,
}

export interface EligibilityFormInterface {
  dateOfBirth: string;
  hasGoodInternet: string;
  is_working: boolean;
  highestQualification: string;
  current_occupation_status: string;
}

export interface OnbaordingState {
  eligibilityData: EligibilityFormInterface;
}

const initState: OnbaordingState = {
  eligibilityData: { dateOfBirth: "", hasGoodInternet: "", highestQualification: "", is_working: false, current_occupation_status: "" },
};

export interface OnboardingContextActions {
  type: OnboardingActionType;
  payload: OnbaordingState;
}

const reducer = (state: OnbaordingState, action: OnboardingContextActions) => {
  switch (action.type) {
    case OnboardingActionType.SET_ELIGIBLITY_FORM: {
      return {
        ...state,
        eligibilityData: action.payload.eligibilityData,
      };
    }

    default:
      return state;
  }
};

export const OnboardingContext = createContext<[OnbaordingState, Dispatch<OnboardingContextActions>]>([initState, () => null]);

interface Props {
  children: JSX.Element | JSX.Element[];
}

const OnboardingContextProvider = (props: Props) => {
  const [state, dispatch] = useReducer(reducer, initState);

  return <OnboardingContext.Provider value={[state, dispatch]}>{props.children}</OnboardingContext.Provider>;
};

export default OnboardingContextProvider;
