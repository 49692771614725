// import { sampleEvent } from "../../../../components/activities/Activity";
import { ACTIVITY_TYPE } from "../../../../pages/V2/Dashboard";
import {
  ErrorResponseModelType,
  GetDostType,
  GetEventType,
  GetAlumniType,
  GetCounsellingRequestStatusType,
} from "../../../schemas/schema";
import api from "../../axios";

export const getEvents = async ({
  event_type,
}: {
  event_type?: string;
}): Promise<GetEventType[]> => {
  const client = await api.client;
  const response = await client.list_events_v2_events_get(null, null, {
    params: {
      event_type,
    },
  });
  return response.data;
};

export const getFounderWebinars = async (): Promise<GetEventType[]> => {
  const client = await api.client;
  const response = await client.list_events_v2_events_get(null, null, {
    params: {
      event_type: ACTIVITY_TYPE.FOUNDER_WEBINAR,
    },
  });
  return response.data;
};

export const getDost = async (): Promise<GetDostType> => {
  const client = await api.client;
  const response = await client.get_dost_v2_dost_get();
  return response.data;
};

export const dostRequestCall = async ({
  student_query = "",
}: {
  student_query?: string;
}): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.request_call_v2_dost_request_call_post({}, { student_query });
  return response.data;
};

export const getAlumnis = async (): Promise<GetAlumniType[]> => {
  const client = await api.client;
  const response = await client.get_alumnis_v2_alumni_all_get();
  return response.data;
};

export const alumniGetInTouch = async ({
  id,
  student_query = "",
}: {
  id: number;
  student_query?: string;
}): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.get_in_touch_v2_alumni__id__get_in_touch_post(
    { id: id },
    {
      student_query: student_query,
    }
  );
  return response.data;
};

export const registerForEvent = async ({
  event_id,
}: {
  event_id: number;
}): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.register_event_v2_events__id_register_get(null, null, {
    params: {
      id: event_id,
    },
  });
  return response.data;
};

export const joinEvent = async ({
  event_id,
}: {
  event_id: number;
}): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.join_event_v2_events__id__join_get(event_id);
  return response.data;
};

export const getEventById = async ({ event_id }: { event_id: number }): Promise<GetEventType> => {
  const client = await api.client;
  const response = await client.get_event_v2_events__id_get(null, null, {
    params: {
      id: event_id,
    },
  });
  return response.data;
};

export const submitCounsellingRequest = async ({
  source,
  meta,
}: {
  source: string;
  meta?: Record<string, string>;
}): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.submit_couselling_request_v2_counselling_request_post(null, meta, {
    params: {
      source: source,
    },
  });
  return response.data;
};

export const getCounsellingSessionStatus = async ({
  source,
}: {
  source: string;
}): Promise<GetCounsellingRequestStatusType> => {
  const client = await api.client;
  const response = await client.get_counselling_request_status_v2_counselling_request_status_get(
    null,
    null,
    {
      params: {
        source: source,
      },
    }
  );
  return response.data;
};

export const getAllCounsellingSessionStatus = async (): Promise<
  GetCounsellingRequestStatusType[]
> => {
  const client = await api.client;
  const response = await client.get_all_counselling_requests_status_v2_counselling_request_all_get();
  return response.data;
};
