import { useState, useEffect } from "react";
import { Box, Button, Heading, Image, Text } from "@chakra-ui/react";
import { ModalTemplate, ModalTemplateV2 } from "../common/general";
import { sendTrackingEvent } from "../common/utils/gtm";
import { PaymentMethod } from "../../constants/IIt-course/iit-form-onboarding";
import { PaymentType, SemesterPaymentType } from "../../api/schemas/schema";

interface CouponModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  discountAmount: number;
  selectedCoursePrice: number;
  isPaymentLoading?: boolean;
  isPaymentApiLoading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  startRazorPay?: any;
  paymentType?: PaymentType;
  selectedCourseId?: string;
  activeTab?: PaymentMethod;
  semesterPaymentOptionsData?: SemesterPaymentType;
  handlePayment?: () => void;
}
function CouponModal({ title, isOpen, onClose, discountAmount, selectedCoursePrice, isPaymentApiLoading, activeTab, handlePayment }: CouponModalProps) {
  useEffect(() => {
    if (!isPaymentApiLoading) onClose();
  }, [isPaymentApiLoading]);

  return (
    <>
      <ModalTemplate paddingY="5" maxWidth="22.75rem" title={title} isOpen={isOpen} onClose={onClose}>
        <Box>
          <Image position={"absolute"} top={"-6.5%"} left={"44%"} src={"/img/coupon-applied.svg"} alt={"coupon"} />
          <Text mt={8} textAlign={"center"} p={"body1-md"}>
            Coupon applied successfully
          </Text>
          <Heading mt={5} mb={3} textAlign={"center"} as={"h5"}>
            <Text color={"#7261DF"} as={"span"}>
              Wohoo!
            </Text>
            You have received a flat ₹{discountAmount} discount!
          </Heading>
          <Box mt={5} mb={4} justifyContent={"center"} display={"flex"}>
            <Box>
              <Heading color={"#7261DF"}>₹ {selectedCoursePrice - discountAmount}</Heading>
            </Box>
            <Box alignSelf={"flex-end"}>
              <Text color={"#B3B3B3"} ml={2} textDecoration={"line-through"} textStyle={"body1-md"}>
                {selectedCoursePrice}
              </Text>
            </Box>
          </Box>
          <Text textAlign={"center"} p={"body1"}>
            Finish payment to reserve your seat.
          </Text>
          <Button
            isLoading={isPaymentApiLoading}
            onClick={() => {
              if (activeTab === PaymentMethod.EMI_PAYMENT) {
                onClose();
              } else {
                sendTrackingEvent({
                  event: "p_pay_continue",
                  eventLabel: window.location.href,
                  p_amount: (selectedCoursePrice - discountAmount).toString(),
                });
                handlePayment && handlePayment();
              }
            }}
            mt={3}
            w={"full"}
            size="md"
            variant="primary"
          >
            CONTINUE
          </Button>
        </Box>
      </ModalTemplate>
    </>
  );
}

export default CouponModal;
