import {
  Text,
  Button,
  VStack,
  PinInput,
  PinInputField,
  HStack,
  Box,
  Flex,
  useBreakpointValue,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ModalTemplate from "../general/ModalTemplate";
import useCountDown from "react-countdown-hook";
import useCleverTap, { CleverTapEvents, ModalNames } from "../../../clevertap/useCleverTap";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  otpLength?: number;
  timeGap: number;
  primaryText?: string;
  secondaryText?: string | React.ReactElement;
  onComplete: (value: string) => void;
  onResendCallback: () => Promise<void>;
  loading?: boolean;
  title?: string;
  isClosable?: boolean;
}

// format time to 00:00 format: ASSUMING timeGap is less than 60
const formatTime = (time: number): string => {
  time = time / 1000;
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
};

const OTPModal = ({
  isOpen,
  onClose,
  otpLength = 4,
  timeGap,
  primaryText,
  secondaryText,
  onComplete,
  onResendCallback,
  loading,
  title,
  isClosable,
}: Props) => {
  const [state, setState] = useState<string>("");
  const [timeLeft, { start, pause }] = useCountDown(timeGap * 1000, 1000);
  const pinInputSize = useBreakpointValue({ base: "mobile", md: "desktop" });
  const clevertap = useCleverTap()[0];

  const handleResendClick = async () => {
    setState("");
    await onResendCallback();
    start();
  };
  const handleChange = (value: string) => {
    setState(value);
  };
  useEffect(() => {
    start();
    return pause;
  }, []);

  useEffect((): void => {
    clevertap.fireEvent(CleverTapEvents.viewed_modal, {
      modal_name: ModalNames.otpVerification,
    });
  }, []);

  return (
    <ModalTemplate
      title={title ? title : "OTP Verification"}
      isOpen={isOpen}
      onClose={onClose}
      showCloseButtonIcon={isClosable}
      closeOnEsc={isClosable}
      closeOnOverlayClick={isClosable}
    >
      <VStack align="flex-start" mt="ms-8">
        <Text textStyle="body2-md" color="ms-grey.800" mb="ms-32">
          {primaryText}
        </Text>
        {typeof secondaryText === "string" ? (
          <Text textStyle="body2" color="ms-grey.700" pb="ms-40">
            {secondaryText}
          </Text>
        ) : (
          secondaryText
        )}

        <HStack spacing="ms-16">
          <PinInput onChange={handleChange} value={state} type="number" size={pinInputSize} otp>
            {new Array(otpLength).fill(0).map((_, i) => (
              <PinInputField isRequired key={i} textStyle="subtitle2" />
            ))}
          </PinInput>
        </HStack>
        <Flex justifyContent={"end"} w="full" pb="ms-16">
          <Box>
            <Text color="ms-red.400" textStyle="body2-md">
              {formatTime(timeLeft)}
            </Text>
          </Box>
        </Flex>
        <HStack justify="space-between" w="full">
          <HStack align="center">
            <Text textStyle="body2-md">Didn&apos;t receive OTP?</Text>
            <Button disabled={timeLeft !== 0} onClick={handleResendClick} variant="link">
              <Text textStyle="btn-sm" color="ms-blue.500">
                RESEND OTP
              </Text>
            </Button>
          </HStack>
          <Button
            disabled={state.length !== otpLength || loading}
            position={loading ? "relative" : "static"}
            onClick={() => onComplete(state)}
            variant="primary"
            size="md"
          >
            VERIFY
            {loading && (
              <Spinner
                speed={"0.5s"}
                thickness={"2px"}
                size={"md"}
                position={"absolute"}
                color={"ms-blue.500"}
              />
            )}
          </Button>
        </HStack>
      </VStack>
    </ModalTemplate>
  );
};

export default OTPModal;
