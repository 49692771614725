import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { ChevronLeft, ChevronRight } from "../../../assets/icons/components";
import useCleverTap, { CleverTapEvents, ScreenNames } from "../../../clevertap/useCleverTap";
import { Constants } from "../../../components/common/constants";

interface Props {
  total: number;
  limit: number;
  offset: number;
  getNextPage: () => void;
  getPrevPage: () => void;
  mb?: string;
  mt?: string;
}

const Pagination = ({ total, limit, offset, getNextPage, getPrevPage, mb, mt }: Props) => {
  const currentPage = Math.floor(offset / limit) + 1;
  const totalPages = Math.ceil(total / limit);
  const clevertap = useCleverTap()[0];
  return (
    <Box
      mt={mt ? mt : { base: "20px", md: "56px" }}
      d={"flex"}
      alignItems={"center"}
      mb={mb ? mb : Constants.bottomNavbarHeight}
    >
      <Box mr={"ms-16"}>
        <Text textStyle={"body1-md"}>{`${currentPage} of ${totalPages}`}</Text>
      </Box>
      <Box d={"flex"}>
        <Box
          pl={"2px"}
          pt={"2px"}
          mr={"ms-8"}
          w={"32px"}
          h={"32px"}
          borderRadius={"6px"}
          border={currentPage === 1 ? "1px solid #E5E5E5" : "1px solid #AEC6F4"}
          cursor={currentPage === 1 ? "normal" : "pointer"}
          onClick={() => {
            if (currentPage == 1) return;

            getPrevPage();
          }}
        >
          <ChevronLeft color={currentPage === 1 ? "ms-grey.200" : "ms-blue.500"} boxSize="6" />
        </Box>
        <Box
          pl={"3px"}
          pt={"2px"}
          w={"32px"}
          h={"32px"}
          borderRadius={"6px"}
          border={currentPage === totalPages ? "1px solid #E5E5E5" : "1px solid #AEC6F4"}
          cursor={currentPage === totalPages ? "normal" : "pointer"}
          onClick={() => {
            if (currentPage == totalPages) return;
            clevertap.fireEvent(CleverTapEvents.click_chevron, {
              screen_name: ScreenNames.myReferral,
              screen_position: "footer",
              page_no: currentPage + 1,
            });
            getNextPage();
          }}
        >
          <ChevronRight
            color={currentPage === totalPages ? "ms-grey.200" : "ms-blue.500"}
            boxSize="6"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Pagination;
