/* eslint-disable @typescript-eslint/no-explicit-any */

import { FeedbackType, GetHiresOfTheWeekAPIResponse, GetLevelupAlumniType, GetUserDetailsForAIInterview, ScheduleAlumniSessionType, StartTest } from "../../../schemas/schema";
import api from "../../axios";

export const getLevelupAlumnis = async (): Promise<GetLevelupAlumniType[]> => {
  const client = await api.client;
  const response = await client.get_levelup_alumnis_v2_levelup_getAlumniConnectUsers_get();
  return response.data;
};

export const getLevelupAlumnisAvailableSlots = async (userId: string): Promise<any[]> => {
  if (userId === "-1") return [];
  const client = await api.client;
  const response = await client.get_levelup_alumnis_available_slots_v2_levelup_availableSlots__userId__get(userId);
  return response.data;
};

export const scheduleAlumniSession = async (data: ScheduleAlumniSessionType): Promise<any> => {
  const client = await api.client;
  const response = await client.schedule_alumni_session_v2_levelup_schedule_post(null, {
    ...data,
  });
  return response.data;
};

export const getParticipantSessions = async (profileSlug: string): Promise<any[]> => {
  const client = await api.client;
  const response = await client.get_participant_sessions_v2_levelup_participant__profileSlug__get(profileSlug);
  return response.data;
};

export const submitFeedback = async (data: FeedbackType): Promise<any> => {
  const client = await api.client;
  const response = await client.submit_feedback_v2_levelup_submitParticipantFeedback_post(null, {
    ...data,
  });
  return response.data;
};
export const startAITest = async (data: StartTest): Promise<any> => {
  const client = await api.client;
  const response = await client.start_ai_test_v2_levelup_take_interview_post(null, {
    ...data,
  });
  return response.data;
};

export const getUserDetailsForAIInterview = async (profileSlug: string): Promise<GetUserDetailsForAIInterview> => {
  const client = await api.client;
  const response = await client.get_user_details_for_ai_interview_v2_levelup_user_test_details__profileSlug__get(profileSlug);
  return response.data;
};

export const getHiresOfTheWeek = async (): Promise<GetHiresOfTheWeekAPIResponse> => {
  const client = await api.client;
  const response = await client.get_hires_of_the_week_v2_levelup_hires_of_the_week_get();
  return response.data;
};
