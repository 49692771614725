import { Box, Button, Flex } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

interface Props {
  isBtnDisabled: boolean;
  isLoading: boolean;
  handleSubmit: () => void;
  hideButton: boolean;
}

const SDFixedButton = ({ isBtnDisabled, isLoading, handleSubmit, hideButton }: Props) => {
  const history = useHistory();
  return (
    <Box
      bottom={0}
      w={"full"}
      pos={"fixed"}
      textAlign={"end"}
      display={hideButton ? "none" : "block"}
      bg={"white"}
      right={{ base: 0 }}
      py={{ base: 2, md: 4 }}
      px={{ base: "16px", md: "24px" }}
      h={"72px"}
      boxShadow={"0px -1px 0px 0px #D9D9D9"}
      zIndex={{ base: 10, lg: 1 }}
    >
      <Flex align={"center"} justify={"space-between"} maxW={1120} mx={"auto"}>
        <Button
          variant="solid"
          color={"#3470E4"}
          onClick={() => {
            history.push("/");
          }}
        >
          Back To Dashboard
        </Button>
        <Button variant="primary" isDisabled={isBtnDisabled} isLoading={isLoading} onClick={handleSubmit}>
          Submit
        </Button>
      </Flex>
    </Box>
  );
};

export default SDFixedButton;
