import dayjs from "dayjs";
import { useContext, useEffect } from "react";
import DateSelect from "../../common/DateSelect";
import { AppContext } from "../../../context/ApplicationContext";
import { OnboardingActionType, OnboardingContext } from "../../../context/OnbaordingContext";
import { Box, Flex, FormControl, FormLabel, Text, Select, RadioGroup, Stack, Radio } from "@chakra-ui/react";

const EligiblityForm = () => {
  const appState = useContext(AppContext)[0];
  const [state, dispatch] = useContext(OnboardingContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFormValuesChange = (key: string, value?: any) => {
    const formData = () => {
      switch (key) {
        case "monthYearOfGraduation": {
          const parsedDate = dayjs(value);
          return {
            ...state.eligibilityData,
            graduationMonth: parsedDate.month() + 1,
            graduationYear: parsedDate.year(),
          };
        }
        case "dateOfBirth": {
          return {
            ...state.eligibilityData,
            [key]: value.format("YYYY-MM-DD"),
          };
        }
        case "is_working": {
          if (value === "currently_working") {
            return {
              ...state.eligibilityData,
              is_working: true,
              current_occupation_status: "currently_working",
            };
          } else {
            return {
              ...state.eligibilityData,
              is_working: false,
              current_occupation_status: value,
            };
          }
        }
        default: {
          return {
            ...state.eligibilityData,
            [key]: value,
          };
        }
      }
    };

    dispatch({
      type: OnboardingActionType.SET_ELIGIBLITY_FORM,
      payload: { ...state, eligibilityData: formData() },
    });
  };

  useEffect(() => {
    dispatch({
      type: OnboardingActionType.SET_ELIGIBLITY_FORM,
      payload: {
        ...state,
        eligibilityData: {
          dateOfBirth: appState.profileDataV2?.date_of_birth || "",
          highestQualification: appState.profileDataV2?.meta?.highest_qualification || "",
          is_working: appState.profileDataV2?.is_working || false,
          hasGoodInternet: appState.profileDataV2?.meta?.hasGoodInternet || "",
          current_occupation_status: appState.profileDataV2?.meta?.current_occupation_status || "",
        },
      },
    });
  }, [appState.profileDataV2]);

  return (
    <Flex direction="column" gap={4}>
      <Text fontSize="20px" fontFamily="Poppins" fontWeight={700}>
        Eligibility form details
      </Text>
      <Box maxW="490px">
        <form>
          {/* Date Of Birth Field */}
          <FormControl isRequired>
            <FormLabel color="#21191B" fontWeight={600} fontSize="14px">
              1. Date of Birth (As per Aadhaar)
            </FormLabel>
            <DateSelect
              showRelativeDate={false}
              onChange={(value) => handleFormValuesChange("dateOfBirth", value)}
              value={state.eligibilityData.dateOfBirth ? dayjs(state.eligibilityData.dateOfBirth) : null}
              isInvalid={false}
              errorMessage="Please add your date of birth."
              disabled={false}
              w="100%"
            />
          </FormControl>

          {/* Highest Qualification */}
          <FormControl isRequired mt={5}>
            <FormLabel color="#21191B" fontWeight={600} fontSize="14px">
              2. Select your highest qualification
            </FormLabel>
            <Select
              placeholder="Please Select"
              bg="white"
              value={state.eligibilityData.highestQualification}
              borderColor="#E5E5E5"
              h="48px"
              onChange={(e) => {
                handleFormValuesChange("highestQualification", e.target.value);
              }}
            >
              <option value="10th_completed">10th completed</option>
              <option value="12th/Diploma_completed">12th/Diploma completed</option>
              <option value="graduation_completed">Graduation completed</option>
              <option value="post_graduation_completed">Post graduation completed</option>
            </Select>
          </FormControl>

          {/* Current Occupation */}
          <FormControl isRequired mt={5}>
            <FormLabel color="#21191B" fontWeight={600} fontSize="14px">
              3. Current occupation status
            </FormLabel>
            <Select
              value={state.eligibilityData.current_occupation_status}
              placeholder="Please Select"
              bg="white"
              borderColor="#E5E5E5"
              h="48px"
              onChange={(e) => {
                handleFormValuesChange("is_working", e.target.value);
              }}
            >
              <option value="currently_working">Currently Working</option>
              <option value="currently_studying_final_year">Currently Studying (Final Year)</option>
              <option value="currently_studying">Currently Studying</option>
              <option value="neither_studying_now_working">Neither Studying Nor Working </option>
            </Select>
          </FormControl>

          {/* Good Internet */}
          <FormControl isRequired mt={5}>
            <FormLabel color="#21191B" fontWeight={600} fontSize="14px">
              4. Do you have laptop/ desktop with a good internet connection?
            </FormLabel>
            <RadioGroup
              value={state.eligibilityData.hasGoodInternet}
              name="hasGoodInternet"
              onChange={(e) => {
                handleFormValuesChange("hasGoodInternet", e);
              }}
            >
              <Stack spacing={5} direction="row">
                <Radio colorScheme="blue" value="yes">
                  Yes
                </Radio>
                <Radio colorScheme="blue" value="no">
                  No
                </Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
        </form>
      </Box>
    </Flex>
  );
};

export default EligiblityForm;
