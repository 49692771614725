import { useHistory } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { convertUrlToCDNUrl } from "../../../utils/utils";
import { Box, Button, Image, Text, useBreakpointValue } from "@chakra-ui/react";

const SonuSoodScholarshipBanner = () => {
  const history = useHistory();
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box>
      <Box bg={"#D6E2FA"} my={"20px"} rounded={"8px"} w={"full"} minH={"138px"} py={"20px"} px={{ base: "16px", md: "32px" }} pos={"relative"}>
        <Text color={"#3470E4"} fontSize={{ base: "18px", md: "20px" }} fontWeight={700} fontFamily={"inter"}>
          Masai Sonu Sood <br style={isMobile ? { display: "block" } : { display: "none" }} /> Scholarship
        </Text>
        <Text fontSize={{ base: "12px", md: "16px" }} fontWeight={500} mt={"6px"} fontFamily={"inter"} color={"#3B3435"}>
          Empowering the next <br style={isMobile ? { display: "block" } : { display: "none" }} /> generation of tech leaders
        </Text>
        <Button
          bg={"white"}
          border={"1px"}
          size={isMobile ? "sm" : "md"}
          borderColor={"#6E71CC"}
          mt={"16px"}
          color={"#3470E4"}
          onClick={() => {
            history.push("/sonu-sood-scholarship");
          }}
        >
          Know More <FaArrowRightLong color="#3470E4" />
        </Button>
        <Image
          src={convertUrlToCDNUrl(
            isMobile
              ? "https://masai-website-images.s3.ap-south-1.amazonaws.com/Group_1707486178_ed650a4ab4.webp"
              : "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317855_8924003352.webp"
          )}
          loading="lazy"
          rounded="inherit"
          h={{ base: "full", md: "182px" }}
          objectFit={"contain"}
          pos={"absolute"}
          bottom={0}
          right={0}
        />
      </Box>
    </Box>
  );
};

export default SonuSoodScholarshipBanner;
