import React, { useEffect, useState } from "react";
import Papa from "papaparse";

const CSVFileUpload = ({
  csvData,
  setCsvData,
}: {
  csvData: string[];
  setCsvData: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const [error, setError] = useState<string | null>(null);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      parseCSVFile(file);
    }
  };

  const parseCSVFile = (file: File) => {
    Papa.parse(file, {
      header: false, // Assumes the first row of the CSV file contains headers
      dynamicTyping: true, // Automatically convert numeric values
      skipEmptyLines: true, // Skip empty lines in the CSV file
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      complete: (result: any) => {
        if (result.data.length > 0) {
          // read the first column of each row into an array
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const column1Array = result.data.map((row: any) => row[0]);
          setCsvData(column1Array);
          setError(null);
        } else {
          setCsvData([]);
          setError("CSV file is empty.");
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: (error: any) => {
        console.error(error);
        setCsvData([]);
        setError("Error parsing the CSV file. Please check the format.");
      },
    });
  };

  return (
    <div>
      <input type="file" accept=".csv" onChange={handleFileUpload} />
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default CSVFileUpload;
