declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag: (a: any, b: any, c: any) => void; //
  }
}

function gtag_report_conversion(id: string, url?: (string | Location) & Location) {
  const callback = function () {
    if (url !== undefined) {
      window.location = url;
    }
  };
  if (
    "gtag" in window &&
    (window.location.hostname === "dashboard.masaischool.com" ||
      process.env.NODE_ENV === "development")
  ) {
    window.gtag("event", "conversion", {
      //   can move this id to a variable later
      send_to: `AW-477340946/${id}`,
      event_callback: callback,
    });
    return false;
  }
}

export default gtag_report_conversion;
