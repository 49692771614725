import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import BottomDrawer from "./BottomDrawer";
import { ModalTemplate } from "../general";
import useSnackBar from "../general/SnackBar";
import { sendTrackingEvent } from "../utils/gtm";
import ModalTemplateV2 from "../general/ModalTemplateV2";
import { FreeCounselling } from "../../../assets/images";
import { useHistory, useLocation } from "react-router-dom";
import { axiosErrorHandler } from "../../../api/utils/error";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import { submitCounsellingRequest } from "../../../api/utils/api/v2/activity";
import CounsellingDrawerContent from "../../V2/ModalContent/CounsellingDrawerContent";
import { useAllCounsellingStatus } from "../../../api/utils/hooks/useCounsellingStatus";
import ModalSuccessIcon from "../../../assets/icons/components/Others/ModalSuccessIcon";
import { Box, Button, Heading, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import PrepleafLogo from "../../../assets/images/CoursePrograms/prepleaf-logo2-white.svg";
import { COUNSELLING_TYPE } from "../../../api/schemas/schema";

interface Props {
  boxShadow?: string;
  borderRadius?: string;
  source: string;
  showMobileStyle?: boolean;
  inDashboardPage?: boolean;
}

const CounsellingBookNow = ({
  source,
  borderRadius,
  boxShadow,
  showMobileStyle,
  inDashboardPage,
}: Props) => {
  const {
    data: allCounsellingStatus,
    refetch: refetchAllCounsellingStatus,
  } = useAllCounsellingStatus();
  const { data: profile } = useProfile();
  const history = useHistory();
  const snackbar = useSnackBar();
  const location = useLocation();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [bookedAlready, setBookedAlready] = React.useState(false);
  const [variation, setVariation] = useState<string>("variation-1");
  const [openCounsellingSuccessModal, setOpenCounsellingSuccessModal] = useState<boolean>(false);

  useEffect(() => {
    // check if an element with source = source exists in allCounsellingStatus
    if (!allCounsellingStatus) return;
    const allCounsellingStatusArr = Object.values(COUNSELLING_TYPE);
    const counsellingStatus = allCounsellingStatus.find(
      (c) =>
        (allCounsellingStatusArr.includes(c?.source as COUNSELLING_TYPE) &&
          c.status !== "COMPLETED") ||
        ((c.source === COUNSELLING_TYPE.REQUEST_CALLBACK ||
          c.source === COUNSELLING_TYPE.REQUEST_CALLBACK_IITG ||
          c.source === COUNSELLING_TYPE.REQUEST_CALLBACK_IIT_MANDI) &&
          c.status !== "COMPLETED")
    );
    if (counsellingStatus) {
      setBookedAlready(true);
    } else {
      setBookedAlready(false);
    }
  }, [allCounsellingStatus]);

  const variationsData: Record<
    string,
    {
      textComponent: JSX.Element;
    }
  > = {
    "variation-1": {
      textComponent: (
        <Box>
          <Text color="#fff" mt="6px" textStyle="body1">
            {isMobile
              ? "You have unlocked a free 1-1 counselling call with our team"
              : "Not sure about your career?"}
          </Text>
          <Heading color="#fff" mt="6px" as="h6">
            Book a{" "}
            <Heading color="ms-yellow.500" mt="6px" as="h6" display={"inline"}>
              Free Counselling Session{" "}
            </Heading>
            with our Team.
          </Heading>
        </Box>
      ),
    },
    "variation-2": {
      textComponent: (
        <Box>
          <Heading as="h6" color="#fff" mt="6px">
            You have unlocked a free 1-1 counselling call with our team
          </Heading>
        </Box>
      ),
    },
  };

  const {
    mutate: submitCounsellingRequestMutate,
    isLoading: submitCounsellingRequestLoading,
  } = useMutation(submitCounsellingRequest, {
    onSuccess: () => {
      // snackbar.success("Counselling request submitted successfully!");
      setOpenCounsellingSuccessModal(true);
      setOpenDrawer(false);
      refetchAllCounsellingStatus();
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  useEffect(() => {
    switch (source) {
      case COUNSELLING_TYPE.MSAT_CANCEL || COUNSELLING_TYPE.MSAT_CANCEL_IITG: // on dashboard
        setVariation("variation-1");
        break;
      case COUNSELLING_TYPE.MSAT_FAIL_1ST_ATTEMPT || COUNSELLING_TYPE.MSAT_FAIL_1ST_ATTEMPT_IITG: // On dashboard
        setVariation("variation-1");
        break;
      case COUNSELLING_TYPE.MSAT_FAIL_2ND_ATTEMPT || COUNSELLING_TYPE.MSAT_FAIL_2ND_ATTEMPT_IITG: // on result page
        setVariation("variation-2");
        break;
      case COUNSELLING_TYPE.PROFILE_INELIGIBLE || COUNSELLING_TYPE.PROFILE_INELIGIBLE_IITG: // on profile ineligible modal
        setVariation("variation-1");
        break;
      case COUNSELLING_TYPE.FAILS_AT_AADHAAR || COUNSELLING_TYPE.FAILS_AT_AADHAAR_IITG: // on fails at aadhaar
        setVariation("variation-1");
        break;

      case COUNSELLING_TYPE.SKIP_COURSE_SELECTION_MSAT_PASSED ||
        COUNSELLING_TYPE.SKIP_COURSE_SELECTION_MSAT_PASSED_IITG ||
        COUNSELLING_TYPE.SKIP_COURSE_SELECTION_MSAT_PASSED_IIT_MANDI: // on dashboard
        setVariation("variation-1");
        //on dashboard: user has eligible courses but no active application then then show this on dashboard
        break;
    }
  }, [source]);

  return (
    <>
      {bookedAlready ? (
        source === COUNSELLING_TYPE.PROFILE_INELIGIBLE ||
        source === COUNSELLING_TYPE.PROFILE_INELIGIBLE_IITG ? (
          <Box
            display={"flex"}
            gap={{ base: "8px", md: "15px" }}
            bgColor={"#3470E4"}
            borderRadius={{ md: "16px" }}
            border={{ md: "1px solid #6FCD9E" }}
            m={{ md: "16px" }}
            padding="16px"
            position="relative"
            overflow="hidden"
            isolation="isolate"
            // width={"fit-content"}
            flexDir="column"
          >
            {!showMobileStyle ? (
              <Image
                src="/img/book-now-vector2.svg"
                w="full"
                top="0px"
                left="0px"
                zIndex={"-1"}
                position={"absolute"}
                borderRadius={"16px"}
                display={{ base: "none", md: "block" }}
              />
            ) : (
              <></>
            )}
            <Box
              display="flex"
              flexDir={{ base: "column", md: showMobileStyle ? "column" : "row" }}
              textAlign={{ base: "center", md: showMobileStyle ? "center" : "left" }}
              gap={{ base: "8px", md: "24px", lg: showMobileStyle ? "8px" : "34px" }}
              alignItems={"center"}
            >
              <Image src={PrepleafLogo} alt="logo" />
              <Text textStyle={{ base: "h4", md: showMobileStyle ? "h4" : "h3" }} color="white">
                Session has been scheduled successfully!
              </Text>
            </Box>
            <Box bg="#FFE799" rounded="12px" px="16px" py="16px" textAlign={"center"}>
              <Text textStyle={"body1-md"} color="#0A0103">
                You are not eligible for our courses at Masai right now.
              </Text>
              <Heading
                mt="8px"
                as={inDashboardPage ? "h4" : "h5"}
                color="ms-grey.800"
                px={inDashboardPage ? 62 : 0}
              >
                Our Prepleaf team will be reaching out to you soon which offers{" "}
                <Text as="span" color="#3470E4">
                  Data Science {inDashboardPage ? <br /> : null}
                </Text>{" "}
                &{" "}
                <Text as="span" color="#3470E4">
                  Web Development
                </Text>{" "}
                courses for college students & working professionals.
              </Heading>
            </Box>
          </Box>
        ) : (
          <Box
            display={"flex"}
            gap={{ base: "10px", md: "24px" }}
            bgColor={"#F2FDFF"}
            borderRadius={{ md: "16px" }}
            border={{ md: "1px solid #6FCD9E" }}
            m={{ md: "16px" }}
            overflow="hidden"
            // width={"fit-content"}
          >
            <Box boxSize={{ base: "96px", md: "78px" }} position="relative">
              <Box
                bg="#1A9FBD"
                position="absolute"
                top="-27px"
                left="-23px"
                rounded="100px"
                p="27px 23px 6px 11px"
              >
                <Box position="relative" right="-10px">
                  <ModalSuccessIcon />
                </Box>
              </Box>
            </Box>
            {/* <Box boxSize={"100px"}>
            <BookNowSuccessIcon />
          </Box> */}

            <Box py="24px" pr="8px" textAlign={"left"}>
              <Text textStyle={"body1-md"}>Session scheduled successfully!</Text>
              <Heading mt="8px" as={"h6"} color="ms-cyan.500">
                {" "}
                Someone from our team will reach out to you soon.
              </Heading>
            </Box>
          </Box>
        )
      ) : (
        <Box
          m={{ md: "16px" }}
          borderRadius={{ base: borderRadius, md: "12px" }}
          boxShadow={boxShadow}
          textAlign={"center"}
          bgColor={"ms-cyan.500"}
          p="16px"
          pt="0px"
          display={{ base: "grid", md: "flex" }}
          flexDirection={{ md: "row-reverse" }}
          justifyContent={{ md: "space-between" }}
          alignItems={{ md: "flex-end" }}
          position={"relative"}
          zIndex={"1"}
          minH={"134px"}
        >
          <Image
            src="/img/book-now-vector.svg"
            top="0px"
            right="-0.5px"
            zIndex={"-1"}
            position={"absolute"}
            borderRightRadius={"12px"}
            display={{ base: "none", md: "block" }}
          />
          <Image display={{ md: "none" }} mx="auto" src={FreeCounselling} alt="free counselling" />
          <Image
            display={{ base: "none", md: "block" }}
            src={"/img/book-now-people.svg"}
            alt="free counselling"
            mb="-16px"
          />

          <Box>
            <Box textAlign={{ md: "left" }}>{variationsData[variation].textComponent}</Box>
            <Button
              display={{ md: "flex" }}
              onClick={() => {
                sendTrackingEvent({
                  event: "d_book_now",
                  eventLabel: location.pathname,
                });
                if (
                  profile &&
                  profile.date_of_birth &&
                  profile.graduation_month &&
                  profile.graduation_year
                ) {
                  submitCounsellingRequestMutate({ source });
                } else {
                  setOpenDrawer(true);
                }
              }}
              mt="16px"
              variant="secondary"
              size="sm"
              width={{ base: "100%", md: "150px" }}
              isLoading={submitCounsellingRequestLoading}
            >
              Book now
            </Button>
          </Box>
        </Box>
      )}

      <BottomDrawer
        setOpenCounsellingSuccessModal={setOpenCounsellingSuccessModal}
        source={source}
        openDrawer={openDrawer && isMobile ? true : false}
        setOpenDrawer={setOpenDrawer}
      />
      <ModalTemplate
        title=""
        isOpen={openDrawer && !isMobile ? true : false}
        onClose={() => {
          setOpenDrawer(false);
        }}
        showCloseButtonIcon={false}
      >
        <CounsellingDrawerContent
          setOpenCounsellingSuccessModal={setOpenCounsellingSuccessModal}
          source={source}
          setOpenDrawer={setOpenDrawer}
        />
      </ModalTemplate>
      <ModalTemplateV2
        autoCloseInSeconds={5}
        closeOnOverlayClick={true}
        isOpen={openCounsellingSuccessModal}
        onClose={() => {
          setOpenCounsellingSuccessModal(false);
          history.push("/");
        }}
        text="We have received your details, you will be receiving a call from our team shortly."
        extra={
          <>
            <Button
              onClick={() => {
                setOpenCounsellingSuccessModal(false);
                history.push("/");
              }}
              mt="28px"
              variant="secondary"
            >
              Close
            </Button>
          </>
        }
      />
    </>
  );
};

export default CounsellingBookNow;
