import { Skeleton } from "@chakra-ui/react";
import React, { Dispatch, useContext, useEffect, useState, useReducer } from "react";
import { GetOnboardingProgress } from "../../../api/schemas/schema";
import { getOnboardingStatus } from "../../../api/utils/api/onboarding";
import useSnackBar from "../../../components/common/general/SnackBar";
import { AppContext } from "../../../context/ApplicationContext";
import { getCurrentStep } from "./components/getCurrentStep";
import { Steps } from "./components/types";
import { StepTwo } from "./components/StepTwo";
import { StepOne } from "./components/StepOne";
import { axiosErrorHandler } from "../../../api/utils/error";

type OnboardingContextState = {
  oboardingStatus?: Steps | null;
  onboardingInfo?: GetOnboardingProgress | null;
  refreshOnboarding?: boolean;
};

type OnboardingProgressAction = {
  type: "SET_ONBOARDING_STATUS" | "REFRESH_ONBOARDING_STATUS";
  payload: OnboardingContextState;
};

const initState: OnboardingContextState = {
  oboardingStatus: null,
  onboardingInfo: null,
  refreshOnboarding: true,
};
export const OnboardingContext = React.createContext<
  [OnboardingContextState, Dispatch<OnboardingProgressAction>]
>([initState, () => null]);

const OnboardingReducer = (state: OnboardingContextState, action: OnboardingProgressAction) => {
  switch (action.type) {
    case "SET_ONBOARDING_STATUS": {
      return {
        ...state,
        oboardingStatus: action.payload.oboardingStatus,
        onboardingInfo: action.payload.onboardingInfo,
        refreshOnboarding: false,
      };
    }
    case "REFRESH_ONBOARDING_STATUS": {
      return {
        ...state,
        refreshOnboarding: true,
      };
    }
    default:
      return state;
  }
};

const OnboardingContextProvider = () => {
  const [state, dispatch] = useReducer(OnboardingReducer, initState);
  return (
    <OnboardingContext.Provider value={[state, dispatch]}>
      <OnboardingProgressPage />
    </OnboardingContext.Provider>
  );
};

const OnboardingProgressPage: React.FC = () => {
  const state = useContext(AppContext)[0];
  const [onboardingState, dispatch] = useContext(OnboardingContext);
  const snackbar = useSnackBar();
  const [activeStep, setActiveStep] = useState<Steps | null>(null);

  const initialize = () => {
    if (state.applicationData?.slug && onboardingState.refreshOnboarding)
      getOnboardingStatus({
        application_slug: state?.applicationData?.slug,
      })
        .then((res) => {
          dispatch({
            type: "SET_ONBOARDING_STATUS",
            payload: {
              oboardingStatus: getCurrentStep(res),
              onboardingInfo: res,
            },
          });
          setActiveStep(getCurrentStep(res));
        })
        .catch((err) => {
          // manage error
          const e = axiosErrorHandler(err);
          if (typeof e === "object" && "message" in e) {
            snackbar.error(e.message);
          } else {
            snackbar.error("something went wrong");
          }
        });
  };
  useEffect(() => {
    initialize();
  }, [state.applicationData?.slug, onboardingState.refreshOnboarding]);

  useEffect(() => {
    onboardingState.onboardingInfo && setActiveStep(getCurrentStep(onboardingState.onboardingInfo));
  }, [onboardingState.onboardingInfo]);

  return (
    <Skeleton isLoaded={activeStep !== null}>
      {(() => {
        // * REWRITE THIS WHOLE THING LATER, WHEN YOU GET TIME, COMPLEXITY AROSE DUE TO MORE COMPLICATED DESIGN
        if (activeStep === Steps.STEP_ONE) {
          return <StepOne handleStepCick={setActiveStep} />;
        } else if (activeStep === Steps.STEP_TWO) {
          return <StepTwo handleStepCick={setActiveStep} />;
        }
        return <StepTwo handleStepCick={setActiveStep} />;
      })()}
    </Skeleton>
  );
};

export default OnboardingContextProvider;
