import { Box, useBreakpointValue } from "@chakra-ui/react";
import React, { useContext, useState } from "react";

import CAPInstructionsHeader from "../../../../components/cap-program/CAPInstructionsHeader";
import CAPInstructionImage1 from "../../../../assets/images/CoursePrograms/CAPInstructionImage1.svg";
import CAPInstructionImage2 from "../../../../assets/images/CoursePrograms/CAPInstructionImage2.svg";
import CAPInstructionImage3 from "../../../../assets/images/CoursePrograms/CAPInstructionImage3.svg";
import { BottomStickyNavbar, ModalTemplate } from "../../../../components/common/general";
import CAPInstructionsPrice from "../../../../components/cap-program/CAPInstructionsPrice";
import RequestCallback from "../../../../components/cap-program/RequestCallback/RequestCallback";
import BookADemoModal from "../../../../components/cap-program/BookADemoModal/DemoModal";
import dayjs from "dayjs";
import { AppContext } from "../../../../context/ApplicationContext";
import CAPStepsInstructions from "../../../../components/cap-program/CapSteps/CAPStepsInstructions";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import { useLocation } from "react-router-dom";

const CapPricingAndPaymentDetails = () => {
  const location = useLocation();
  const [isReserveSeatModalOpen, setIsReserveSeatModalOpen] = useState<boolean>(false);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const state = useContext(AppContext)[0];
  const course = state.applicationData?.batch_campus.batch.course;
  const courseStartDate = dayjs(state.applicationData?.batch_campus.batch.start_date).format(
    "DD MMM YYYY"
  );
  const [openCalendlyModal, setOpenCalendlyModal] = useState<boolean>(false);
  const buttonStyle = useBreakpointValue({
    base: { size: "sm", variant: "primary" },
    md: { size: "md", variant: "secondary" },
  });
  const bottomSpace = useBreakpointValue({
    base: "40px",
    md: "65px",
  });
  const whatYouWillLearnData: { icon: string; key: string }[] = [
    {
      icon: CAPInstructionImage1,
      key: "Live Classes",
    },
    { icon: CAPInstructionImage2, key: "Certification & Placement" },
    {
      icon: CAPInstructionImage3,
      key: `English Fluency Marathon`,
    },
  ];

  let heading = "";
  let bookdemoSource = "";
  let courseSubheading = "";

  switch (course?.name) {
    case "Career Accelerator Program":
      heading = "CAP - Web Development";
      bookdemoSource = "dashboard_view_details_cap_web";
      courseSubheading = "Become a Full Stack Developer with Specialization in latest technology";
      break;
    case "Career Accelerator Program - Data Science":
      heading = "CAP - Data Science";
      bookdemoSource = "dashboard_view_details_cap_data";
      courseSubheading = "Become a Data Analysts with Specialization in latest technology";
      break;
  }

  return (
    <>
      <Box p={{ base: "16px 16px 150px 16px", lg: "24px 24px 110px 24px" }}>
        <CAPInstructionsHeader
          courseName={heading || "NA"}
          startDate={courseStartDate}
          courseSubheading={courseSubheading}
        />
        <Box
          mt={"24px"}
          display={"grid"}
          gridTemplateColumns={{ base: "100%", xl: "52% 44%" }}
          gap="32px"
        >
          <CAPStepsInstructions />
          <CAPInstructionsPrice display={isMobile ? "none" : "block"} coursePrice={5000} />
        </Box>
      </Box>
      <RequestCallback
        source="ONWARD_PAYMENT_DETAILS_PAGE"
        mobilePopupPorps={{
          bottom: { base: "120px", md: "60px" },
          right: { base: "12px", md: "32px" },
        }}
        styleProps={{ bottom: bottomSpace }}
      />
      <BookADemoModal
        setShowModal={setOpenCalendlyModal}
        showModal={openCalendlyModal}
        phone={null}
        startingStep={1}
        source={bookdemoSource}
      />

      {isMobile && (
        <BottomStickyNavbar
          btnPrimaryProps={{
            onClick: () => {
              sendTrackingEvent({
                event: "dash_reserve_seat_bottom_nav_mobile",
                eventLabel: location.pathname,
              });
              setIsReserveSeatModalOpen(true);
            },
          }}
          btnPrimary={"reserve your seat"}
        />
      )}
      {/* Reserve Seat Modal*/}
      <ModalTemplate
        title=""
        isOpen={isReserveSeatModalOpen}
        onClose={() => {
          setIsReserveSeatModalOpen(false);
        }}
        marginX={2}
        maxWidth="552px"
      >
        <Box m={"-24px"}>
          <CAPInstructionsPrice isModal={true} display={"block"} coursePrice={5000} />
        </Box>
      </ModalTemplate>
    </>
  );
};

export default CapPricingAndPaymentDetails;
