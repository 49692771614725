import EventCard from "../../components/events/EventCard";
import { SimpleGrid, Heading, Box, HStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Skeleton } from "@chakra-ui/react";
import { GetContestType, GetEventType } from "../../api/schemas/schema";
import { getEvents } from "../../api/utils/api/events";
import EmptyState from "../../components/common/general/EmptyState";
import useCleverTap from "../../clevertap/useCleverTap";
import { useHistory, useLocation } from "react-router-dom";

import dayjs from "dayjs";
import { getContests, getServerTime } from "../../api/utils/api";
import utc from "dayjs/plugin/utc";

import NewContestCard from "../Contests/NewContestCard";
import { axiosErrorHandler } from "../../api/utils/error";
import useSnackBar from "../../components/common/general/SnackBar";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { sendTrackingEvent } from "../../components/common/utils/gtm";
interface Props {
  hideEmptyState?: boolean;
  showOnlyEvents?: boolean;
}
const Events = ({ hideEmptyState, showOnlyEvents }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [events, setEvents] = useState<GetEventType[]>([]);
  const clevertap = useCleverTap()[0];
  const [serverTime, setServerTime] = useState<string>("");
  const query = new URLSearchParams(useLocation().search).get("type");
  const { trackPageView } = useMatomo();
  useEffect(() => {
    !showOnlyEvents && trackPageView();
    getAllContests();
    setLoading(true);
    getEvents()
      .then(async (res) => {
        const serverTime = await getServerTime();
        setServerTime(serverTime);
        const unarchivedEvents = res.filter((event) => {
          const isArchived = dayjs(event?.event_end_data).isBefore(dayjs(serverTime));
          return !isArchived;
        });
        setEvents(unarchivedEvents);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  dayjs.extend(utc);

  const renderEvents = () => {
    const eventsToRender = events.map((event) => (
      <EventCard
        autoRegisterThisEvent={autoRegisterEventId === event.id}
        setAutoRegisterEventId={setAutoRegisterEventId}
        key={event.id}
        event={event}
      />
    ));
    if (eventsToRender.length > 0 && serverTime) {
      return (
        <SimpleGrid
          gap={"ms-24"}
          mt={{ base: "ms-8", md: "ms-16" }}
          gridTemplateColumns={{ lg: "repeat(2,1fr)", xl: "repeat(3,1fr)" }}
        >
          {eventsToRender.length > 0 ? eventsToRender : null}
        </SimpleGrid>
      );
    } else {
      return (
        <Box p={{ base: "ms-16", md: "ms-24" }} m={"auto"}>
          <EmptyState height="500px" text="Sorry, no event found" />
        </Box>
      );
    }
  };
  const renderContests = () => {
    if (contests?.length && contests.length > 0) {
      return (
        <SimpleGrid
          gap={"ms-24"}
          mt={{ base: "ms-8", md: "ms-16" }}
          gridTemplateColumns={{ lg: "repeat(2,1fr)", xl: "repeat(3,1fr)" }}
        >
          {contests?.map((contest) => (
            <NewContestCard
              key={contest.id}
              contest={contest}
              getAllContests={getAllContests}
              serverTime={serverTime}
              autoRegisterThisContest={autoRegisterContestId === contest.id}
              setAutoRegisterContestId={setAutoRegisterContestId}
            />
          ))}
        </SimpleGrid>
      );
    } else {
      return (
        <Box p={{ base: "ms-16", md: "ms-24" }} m={"auto"}>
          <EmptyState height="500px" text="Sorry, no contest found" />
        </Box>
      );
    }
  };
  // Contest data
  const location = useLocation();
  const search = location.search;
  const currentEventType = new URLSearchParams(search).get("type");
  const [selectedTab, setSelectedTab] = useState<string>(currentEventType?.trim() || "All");
  const [contests, setContests] = useState<GetContestType[] | null>(null);

  const snackbar = useSnackBar();
  const history = useHistory();

  const [autoRegisterEventId, setAutoRegisterEventId] = useState<number | null>(null);
  const [autoRegisterContestId, setAutoRegisterContestId] = useState<number | null>(null);

  useEffect(() => {
    if (showOnlyEvents) return;
    const autoRegisterEventId = new URLSearchParams(search).get("autoRegisterEventId");
    const autoRegisterContestId = new URLSearchParams(search).get("autoRegisterContestId");

    if (autoRegisterEventId) {
      setAutoRegisterEventId(parseInt(autoRegisterEventId));
    }
    if (autoRegisterContestId) {
      setAutoRegisterContestId(parseInt(autoRegisterContestId));
    }
    history.push({
      pathname: `/events`,
      search: `type=${selectedTab}`,
    });
    if (selectedTab === "Contests" || selectedTab === "All") {
      getAllContests();
    }
  }, [selectedTab]);

  const getAllContests = () => {
    setLoading(true);
    getContests({})
      .then((res) => {
        getServerTime().then((serverTime) => {
          setServerTime(serverTime);
          // sort contests by start_time
          res.sort((a, b) => {
            const aDate = dayjs(a.start_time).utc(true);
            const bDate = dayjs(b.start_time).utc(true);
            return bDate.diff(aDate);
          });

          setContests(res);
          setLoading(false);
        });
      })
      .catch((err) => {
        setLoading(false);
        const e = axiosErrorHandler(err);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("something went wrong");
        }
      });
  };

  return (
    <Skeleton isLoaded={!loading}>
      {showOnlyEvents ? (
        <Box px={{ base: "ms-16", md: "ms-24" }}>
          <Box>
            <Heading as="h4">Events</Heading>
          </Box>
          {renderEvents()}
        </Box>
      ) : (
        <Box>
          <Box
            px={{ base: "ms-16", md: "ms-24" }}
            my={{ base: "ms-16", md: "ms-24" }}
            textAlign={"center"}
          >
            <Box px={{ base: "ms-16", md: "ms-24" }}>
              <HStack mt={"ms-24"} justifyContent={"center"}>
                {["All", "Events", "Contests"].map((item, index) => {
                  return (
                    <Heading
                      key={index}
                      as="h4"
                      onClick={() => {
                        sendTrackingEvent({
                          event: "dash_events_all" + item.toLocaleLowerCase(),
                          eventLabel: location.pathname,
                        });
                        setSelectedTab(item);
                      }}
                      cursor={"pointer"}
                      py={"ms-12"}
                      px={"ms-16"}
                      bgColor={
                        selectedTab.toLowerCase() === item.toLowerCase() ? "ms-blue.50" : "none"
                      }
                      color={
                        selectedTab.toLowerCase() === item.toLowerCase()
                          ? "ms-blue.500"
                          : "ms-grey.700"
                      }
                      borderRadius={"100px"}
                    >
                      {item}
                    </Heading>
                  );
                })}
              </HStack>
            </Box>
          </Box>
          {selectedTab === "All" ? (
            <Box>
              <Box>
                {contests && contests?.length > 0 && serverTime && (
                  <Box px={{ base: "ms-16", md: "ms-24" }}>
                    <Box>
                      <Heading as="h4">Contests</Heading>
                    </Box>
                    {renderContests()}
                  </Box>
                )}
              </Box>
              <Box mt={"ms-24"}>
                {events.length > 0 && serverTime && (
                  <Box px={{ base: "ms-16", md: "ms-24" }}>
                    <Box>
                      <Heading as="h4">Events</Heading>
                    </Box>
                    {renderEvents()}
                  </Box>
                )}{" "}
              </Box>
            </Box>
          ) : selectedTab === "Events" ? (
            <Box>
              {events.length > 0 && serverTime && (
                <Box px={{ base: "ms-16", md: "ms-24" }}>{renderEvents()}</Box>
              )}{" "}
            </Box>
          ) : selectedTab === "Contests" ? (
            <Box>
              {contests && contests?.length > 0 && serverTime && (
                <Box px={{ base: "ms-16", md: "ms-24" }}>{renderContests()}</Box>
              )}{" "}
            </Box>
          ) : null}
        </Box>
      )}
    </Skeleton>
  );
};

export default Events;
