import { useMutation } from "react-query";
import { Box, Flex, Skeleton } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { axiosErrorHandler } from "../../../api/utils/error";
import { ApplicationType, BatchType, GetProfileTypeV2, ProfileAssessment } from "../../../api/schemas/schema";
import { Constants } from "../../../components/common/constants";
import { AppContext, ApplicationActionType } from "../../../context/ApplicationContext";
import { ModalTemplateV2 } from "../../../components/common/general";
import useSnackBar from "../../../components/common/general/SnackBar";
import { OnboardingContext } from "../../../context/OnbaordingContext";
import { createAssessment } from "../../../api/utils/api/v2/assessment";
import { OnboardingStepInterface } from "../../../constants/onboarding";
import { createApplicationV3 } from "../../../api/utils/api/v2/application";
import { useBestAssessment } from "../../../api/utils/hooks/useBestAssessment";
import { useActiveApplication } from "../../../api/utils/hooks/useApplication";
import SDFixedButton from "../../../components/revamp/pap-onboarding/SDFixedButton";
import DAFixedButton from "../../../components/revamp/pap-onboarding/DAFixedButton";
import { updateProfile, validateEligibility } from "../../../api/utils/api/v2/profile";
import OnboardingTopSection from "../../../components/common/onboarding/OnboardingTopSection";
import OnboardingStepsTracker from "../../../components/common/onboarding/OnboardingStepsTracker";
import SDOnbaordingComponent from "../../../components/revamp/pap-onboarding/SDOnbaordingComponent";
import DAOnbaordingComponent from "../../../components/revamp/pap-onboarding/DAOnbaordingComponent";
import { handleBtnDisabled, handleDAStartMSAT, shouldShowAlumniConnectBanner } from "../../../utils/course";
import { dataAnalystStepList, softwareDevelopmentOnbaordingStepList } from "../../../constants/revamp/papOnbaording";
import { useActiveBatch } from "../../../api/utils/hooks/useBatch";
import { formatDateWithOrdinal } from "../../../utils/utils";
import { ProgramProfileType } from "../../../enums/ProgramType.enum";

export enum PAPCourses {
  SD = "Software Development",
  DA = "Data Analytics",
}

export enum ScholorShipCourses {
  SONU_SOOD = "Sonu Sood",
  EMPOWER_HER = "Empower Her",
}

const OnboardingRevamp = () => {
  const history = useHistory();
  const snackbar = useSnackBar();
  const [appState, dispatch] = useContext(AppContext);
  const [state] = useContext(OnboardingContext);
  const { courseId } = useParams<{ courseId: string }>();
  const [isInEligible, setIsInEligible] = useState<boolean>(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);
  const [selectedCourse, setSelectedCourse] = useState<PAPCourses | null>(null);
  const [currentOnboardingStep, setCurrentOnboardingStep] = useState<number>(1);
  const [inEligibleReason, setInEligibleReason] = useState<string | null>(null);
  const [isEligibleForDAForm, setIsEligibleForDAForm] = useState<boolean>(false);
  const [isEligibilityLoading, setIsEligibilityLoading] = useState<boolean>(false);
  const [onboardingStepList, setOnboardingStepList] = useState<OnboardingStepInterface[]>(softwareDevelopmentOnbaordingStepList);

  // These states are for MSAT and DA only
  const [isBothMSATFailed, setIsBothMSATFailed] = useState<boolean>(false);
  const [openRedirectModal, setOpenRedirectModal] = useState<boolean>(false);
  const [msatStepBtnText, setMsatStepBtnText] = useState<string>("Take MSAT");
  const [isResultCardVisible, setIsResultCardVisible] = useState<boolean>(false);

  // Update onboarding steps for SD course
  const updateOnboardingStepsForSD = (step: number) => {
    onboardingStepList[0].isStepCompleted = true;
    onboardingStepList[0].isCurrentStep = false;
    onboardingStepList[1].isCurrentStep = true;
    setOnboardingStepList([...onboardingStepList]);
    setCurrentOnboardingStep(step);
  };

  // Update onboarding steps for DA course
  const updateOnboardingStepsForDA = (step: number) => {
    onboardingStepList[0].isStepCompleted = true;
    onboardingStepList[0].isCurrentStep = false;
    onboardingStepList[1].isCurrentStep = false;
    onboardingStepList[1].isStepCompleted = true;
    onboardingStepList[2].isCurrentStep = true;
    setOnboardingStepList([...onboardingStepList]);
    setCurrentOnboardingStep(step);
  };

  // Handle errors and display snackbar messages
  const handleError = (error: unknown, context: string) => {
    setIsEligibilityLoading(false);
    const e = axiosErrorHandler(error);
    if (typeof e === "object" && "message" in e) {
      snackbar.error(e.message);
    } else {
      snackbar.error(`Something went wrong during ${context}`);
    }
  };

  const { data: activeApplication, isLoading: isActiveApplicationLoading } = useActiveApplication();
  const { data: bestAssessment, isLoading: isBestAssessmentLoading, refetch: refetchBestAssessment } = useBestAssessment();
  const { data: activeBatch, isLoading: isActiveBatchLoading } = useActiveBatch(courseId as string);

  // Mutation for validating eligibility
  const { mutate: validateEligibilityMutation, isLoading: isValidateEligibilityLoading } = useMutation(validateEligibility, {
    onSuccess: (data) => {
      setIsEligibilityLoading(false);

      if (data && data.reject_reason) {
        if (data.reject_reason[0] !== "eligibility form is not filled" && !data.isEligible) {
          setInEligibleReason(data.reject_reason[0]);
          setIsInEligible(true);
        } else if (data.reject_reason[0] === "eligibility form is not filled" && !data.isEligible) {
          setIsInEligible(false);
          setInEligibleReason(null);
        }
      }
      if (data && data.isEligible) {
        if (selectedCourse === PAPCourses.SD) {
          if (activeApplication && activeApplication?.course_id === 12) updateOnboardingStepsForSD(3);
          else updateOnboardingStepsForSD(2);
        } else if (selectedCourse === PAPCourses.DA) {
          updateOnboardingStepsForDA(3);
          setIsEligibleForDAForm(true);
        }
      } else if (!data) {
        if (selectedCourse === PAPCourses.DA) {
          onboardingStepList[0].isCurrentStep = false;
          onboardingStepList[0].isStepCompleted = true;
          onboardingStepList[1].isCurrentStep = true;
          setOnboardingStepList([...onboardingStepList]);
        } else if (selectedCourse === PAPCourses.SD) {
          onboardingStepList[0].isCurrentStep = true;
          setOnboardingStepList([...onboardingStepList]);
        }
      }
    },
    onError: (error) => handleError(error, "validate eligibility"),
  });

  // Mutation for creating assessment
  const { mutate: createAssessmentMutate, isLoading: isCreateAssessmentLoading } = useMutation(createAssessment, {
    onSuccess: (data) => {
      window.location.href = data.test_url;
      refetchBestAssessment();
      setOpenRedirectModal(true);
    },
    onError: (error) => handleError(error, "create assessment"),
  });

  // Mutation for updating profile
  const { mutate: updateProfileMutate, isLoading: updateProfileLoading } = useMutation(updateProfile, {
    onSuccess: () => validateEligibilityMutation(courseId || ""),
    onError: (error) => handleError(error, "update profile"),
  });

  // Mutation for updating program type
  const { mutate: updateProgramProfileMutate, isLoading: isProgramProfileUpdatnng } = useMutation(updateProfile, {
    onSuccess: (data) => {
      dispatch({
        type: ApplicationActionType.SET_PROFILE_V2,
        payload: {
          profileDataV2: data as GetProfileTypeV2,
        },
      });
      dispatch({
        type: ApplicationActionType.SET_COURSE_PREFERENCE,
        payload: {
          coursePreference: data?.program_profile_type as ProgramProfileType,
        },
      });
    },
    onError: (error) => handleError(error, "update profile"),
  });

  // Mutation for creating application
  const { mutate: createApplicationMutate, isLoading: isCreateApplicationLoading } = useMutation(createApplicationV3, {
    onSuccess: () => {
      onboardingStepList[0].isStepCompleted = true;
      onboardingStepList[0].isCurrentStep = false;
      onboardingStepList[1].isStepCompleted = false;
      onboardingStepList[1].isCurrentStep = true;
      setOnboardingStepList([...onboardingStepList]);
      setCurrentOnboardingStep(2);
    },
    onError: (error) => handleError(error, "create application"),
  });

  // Logic for handling SD Submit
  const handleSDSubmit = () => {
    switch (currentOnboardingStep) {
      case 1: {
        updateProfileMutate({
          date_of_birth: state.eligibilityData.dateOfBirth,
          is_working: state.eligibilityData.is_working,
          meta: {
            highest_qualification: state.eligibilityData.highestQualification,
            hasGoodInternet: state.eligibilityData.hasGoodInternet,
            current_occupation_status: state.eligibilityData.current_occupation_status || "currently_working",
          },
        });

        break;
      }
    }
  };

  // Logic for handling DA Submit
  const handleDASubmit = () => {
    switch (currentOnboardingStep) {
      case 1: {
        handleDAStartMSAT(bestAssessment as ProfileAssessment, createAssessmentMutate, createApplicationMutate);
        break;
      }
      case 2: {
        updateProfileMutate({
          date_of_birth: state.eligibilityData.dateOfBirth,
          is_working: state.eligibilityData.is_working,
          meta: {
            highest_qualification: state.eligibilityData.highestQualification,
            hasGoodInternet: state.eligibilityData.hasGoodInternet,
            current_occupation_status: state.eligibilityData.current_occupation_status || "currently_working",
          },
        });
        break;
      }
    }
  };

  useEffect(() => {
    const batchStartDate = formatDateWithOrdinal(activeBatch?.start_date as string);
    switch (Number(courseId)) {
      case 12:
        setSelectedCourse(PAPCourses.SD);
        softwareDevelopmentOnbaordingStepList[1].additionalText = `Starts on ${batchStartDate}`;
        setOnboardingStepList(softwareDevelopmentOnbaordingStepList);
        break;
      case 14:
        setSelectedCourse(PAPCourses.DA);
        dataAnalystStepList[2].additionalText = `Starts on ${batchStartDate}`;
        setOnboardingStepList(dataAnalystStepList);
        break;
      default:
        history.push("/");
    }
  }, [courseId, history, activeBatch]);

  useEffect(() => {
    if (selectedCourse) {
      setIsBtnDisabled(handleBtnDisabled(selectedCourse, currentOnboardingStep, state));
    }
  }, [currentOnboardingStep, selectedCourse, state]);

  useEffect(() => {
    if ((courseId && selectedCourse === PAPCourses.SD && currentOnboardingStep === 1) || (courseId && selectedCourse === PAPCourses.DA && currentOnboardingStep === 2)) {
      setIsEligibilityLoading(courseId && selectedCourse === PAPCourses.DA && currentOnboardingStep === 2 ? false : true);
      validateEligibilityMutation(courseId || "");
    }
  }, [courseId, selectedCourse, currentOnboardingStep]);

  // Update onboarding steps based on the active application and selected course
  useEffect(() => {
    if (activeApplication && activeApplication?.course_id === 12 && selectedCourse === PAPCourses.SD) {
      updateOnboardingStepsForSD(3);
    }
  }, [activeApplication, selectedCourse, currentOnboardingStep]);

  // Logic for handling MSAT Btn text and MSAT screen
  useEffect(() => {
    if (!bestAssessment) return;

    const status = bestAssessment.status?.toUpperCase();
    const isEndedOrGraded = ["ENDED", "GRADED"].includes(status);
    const attemptCount = bestAssessment?.attempt_count;
    const eligibleCourses = bestAssessment?.eligible_courses || [];

    const shouldContinueMsat = !isEndedOrGraded;
    const shouldRetakeMsat = isEndedOrGraded && attemptCount === 1 && !eligibleCourses.includes(14);
    const shouldShowResultCard = isEndedOrGraded || (attemptCount && attemptCount > 1);
    const shouldShowNextButton = isEndedOrGraded && attemptCount && attemptCount <= 2 && eligibleCourses.includes(14);
    const failedInBothMSAT = isEndedOrGraded && attemptCount && attemptCount === 2 && !eligibleCourses.includes(14);

    if (shouldContinueMsat) {
      setMsatStepBtnText("Continue MSAT");
    } else if (shouldShowNextButton) {
      setMsatStepBtnText("Next");
    } else if (shouldRetakeMsat) {
      setMsatStepBtnText("Retake MSAT");
    }

    if (shouldShowResultCard) {
      setIsResultCardVisible(true);
    }
    if (failedInBothMSAT) {
      setIsBothMSATFailed(true);
    }
  }, [bestAssessment]);

  // Logic For Blocking user to access onboarding page if user already applied in other course
  useEffect(() => {
    if (activeApplication && activeApplication.course_id !== Number(courseId)) {
      history.push("/");
    }
  }, [activeApplication]);

  // Logic for DA Onboarding Steps
  useEffect(() => {
    if (selectedCourse === PAPCourses.SD) return;
    if (activeApplication && activeApplication.course_id === Number(courseId) && !isValidateEligibilityLoading && !isEligibleForDAForm) {
      onboardingStepList[0].isCurrentStep = false;
      onboardingStepList[0].isStepCompleted = true;
      onboardingStepList[1].isCurrentStep = true;
      setCurrentOnboardingStep(2);
    } else if (activeApplication && activeApplication.course_id === Number(courseId) && !isValidateEligibilityLoading && isEligibleForDAForm) {
      onboardingStepList[0].isCurrentStep = false;
      onboardingStepList[0].isStepCompleted = true;
      onboardingStepList[1].isCurrentStep = false;
      onboardingStepList[1].isStepCompleted = true;
      onboardingStepList[2].isCurrentStep = true;
      setCurrentOnboardingStep(3);
    }
  }, [activeApplication, isValidateEligibilityLoading, isEligibleForDAForm, selectedCourse]);

  // Logic for SD Onboarding Steps
  useEffect(() => {
    if (activeApplication && activeApplication?.course_id === 12) {
      onboardingStepList[0].isCurrentStep = false;
      onboardingStepList[0].isStepCompleted = true;
      onboardingStepList[1].isStepCompleted = true;
      setCurrentOnboardingStep(3);
    }
  }, [activeApplication, activeApplication?.course_id]);

  // Updating Profile program type if it's null
  useEffect(() => {
    if (appState && appState.profileDataV2 && appState.profileDataV2.program_profile_type === null) {
      updateProgramProfileMutate({ program_profile_type: "MASAI" });
    } else if (
      appState &&
      appState.profileDataV2 &&
      appState.profileDataV2.program_profile_type &&
      (appState.profileDataV2.program_profile_type == "IIT_GUWAHATI" || appState.profileDataV2.program_profile_type == "IIT_MANDI")
    ) {
      history.push("/");
    }
  }, [appState, appState.profileDataV2]);

  if (isEligibilityLoading || appState.profileDataV2 === null || isActiveApplicationLoading || isBestAssessmentLoading || isActiveBatchLoading || isProgramProfileUpdatnng) {
    return <Skeleton w={"full"} h={"100vh"} mr={4} rounded={"8px"} isLoaded={false} display={"inline-block"} />;
  }

  return (
    <>
      <Box bg="#FBFBFB" w="full" pos="relative">
        <Flex
          maxW={1120}
          direction="column"
          gap={{ base: "24px", md: "30px" }}
          py={{ base: "18px", md: "24px" }}
          pb={{ base: "80px", md: "110px" }}
          mx="auto"
          minH={`calc(100vh - ${Constants.headerHeight})`}
          pos="relative"
        >
          <OnboardingTopSection imageUrl={selectedCourse === PAPCourses.SD ? "/img/revamp/courses/sd.svg" : "/img/revamp/courses/da.svg"} boldText={selectedCourse || ""} />
          <OnboardingStepsTracker onboardingStepList={onboardingStepList} shouldShowMasaiConnectBanner={shouldShowAlumniConnectBanner(currentOnboardingStep, selectedCourse as PAPCourses)} />
          <Skeleton isLoaded={!isValidateEligibilityLoading}>
            {selectedCourse === PAPCourses.SD ? (
              <SDOnbaordingComponent
                isInEligible={isInEligible}
                setIsEligible={setIsInEligible}
                inEligibleReason={inEligibleReason}
                currentOnboardingStep={currentOnboardingStep}
                setCurrentOnboardingStep={setCurrentOnboardingStep}
                setOnboardingStepList={setOnboardingStepList}
                activeBatch={activeBatch as BatchType}
                activeApplication={activeApplication as ApplicationType}
              />
            ) : (
              <DAOnbaordingComponent
                isBothMSATFailed={isBothMSATFailed}
                isInEligible={isInEligible}
                inEligibleReason={inEligibleReason}
                setIsEligible={setIsInEligible}
                isResultCardVisible={isResultCardVisible}
                currentOnboardingStep={currentOnboardingStep}
                bestAssessment={bestAssessment as ProfileAssessment}
                activeBatch={activeBatch as BatchType}
              />
            )}
          </Skeleton>
        </Flex>
        <SDFixedButton
          handleSubmit={handleSDSubmit}
          isLoading={updateProfileLoading || isValidateEligibilityLoading}
          isBtnDisabled={isBtnDisabled || updateProfileLoading || isValidateEligibilityLoading}
          hideButton={(isInEligible && currentOnboardingStep === 1) || currentOnboardingStep > 1 || selectedCourse === PAPCourses.DA || isValidateEligibilityLoading}
        />
        <DAFixedButton
          handleSubmit={handleDASubmit}
          msatStepBtnText={msatStepBtnText}
          currentOnbaordingStep={currentOnboardingStep}
          hideButton={isInEligible || selectedCourse === PAPCourses.SD || currentOnboardingStep > 2 || isValidateEligibilityLoading}
          isLoading={updateProfileLoading || isValidateEligibilityLoading || isCreateAssessmentLoading || isCreateApplicationLoading}
          isBtnDisabled={isBtnDisabled || updateProfileLoading || isValidateEligibilityLoading || isCreateAssessmentLoading || isCreateApplicationLoading || isBothMSATFailed}
        />
      </Box>
      <ModalTemplateV2
        closeOnEsc={false}
        showConfetti={false}
        isOpen={openRedirectModal}
        closeOnOverlayClick={false}
        hideIconWithConfetti={true}
        onClose={() => {
          //
        }}
        waiting={true}
        text={"You are being redirected to our MSAT Platform."}
      />
    </>
  );
};

export default OnboardingRevamp;
