import React from "react";
import { Box } from "@chakra-ui/react";

interface Props {
  children: JSX.Element;
}
export const ScoreCardWrapper = ({ children }: Props) => {
  return (
    <Box
      borderRadius={"ms-16"}
      bg="ms-primary"
      p={{ base: "ms-8", md: "ms-16" }}
      minHeight={{ base: "6.375rem", md: "9.75rem" }}
      height={"full"}
      border="1px solid rgba(207, 208, 238, 1)"
    >
      {children}
    </Box>
  );
};
