import React, { useEffect, useState } from "react";
import { Box, Button, Skeleton, Text } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { axiosErrorHandler } from "../../../../api/utils/error";
import useSnackBar from "../../../common/general/SnackBar";
import { banUsersApi, unbanUsersApi } from "../../../../api/utils/api/v2/admin";
import { FileUploader } from "react-drag-drop-files";
import Papa from "papaparse";
import { BannedUserType, UnbannedUserType } from "../../../../api/schemas/schema";
import { useVerifyAdmin } from "../../../../api/utils/hooks/useVerifyAdmin";
import { useHistory } from "react-router-dom";
import EmpowerHerVerifyUserInfo from "./EmpowerHerUserToUpdate";

const UsersAdmin = () => {
  const history = useHistory();
  const snackbar = useSnackBar();
  const [bannedUsersData, setBannedUsersData] = useState<BannedUserType[]>();
  const [unbannedUsersData, setUnbannedUsersData] = useState<UnbannedUserType[]>();

  const { data: verifiedAdmin, isLoading: isVerifyAdminLoading } = useVerifyAdmin();

  useEffect(() => {
    if (!verifiedAdmin?.isAdmin && !isVerifyAdminLoading) {
      history.push("/");
    }
  }, [verifiedAdmin]);

  const { mutate: banUsers, isLoading: isBanUsersApiLoading } = useMutation(banUsersApi, {
    onSuccess: (res) => {
      console.log("res:", res);
      snackbar.success("Users banned Successfully");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: unbanUsers, isLoading: isUnbanUsersApiLoading } = useMutation(unbanUsersApi, {
    onSuccess: (res) => {
      console.log("res:", res);
      snackbar.success("Users unbanned Successfully");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  return (
    <Skeleton p={{ base: "16px", md: "24px" }} isLoaded={!isVerifyAdminLoading} h={isVerifyAdminLoading ? "100vh" : "auto"}>
      <Text as="h2" textStyle="h2">
        Users
      </Text>
      <Box display={"flex"} mt="10px" gap="10px" flexWrap={"wrap"} maxWidth={"full"}>
        <Button variant="primary" onClick={() => history.push("/admin/users/update-kyc-details")}>
          Update Users KYC Details
        </Button>

        <Button variant="primary" onClick={() => history.push("/admin/users/update-personal-details")}>
          Update Users Personal Details
        </Button>

        <Button variant="primary" onClick={() => history.push("/admin/users/update-user-batch")}>
          Update Users Batch (Only For IIT Students)
        </Button>
      </Box>

      <Box mt="20px" display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" border="1px solid grey" rounded="12px" p="12px" gap="8px">
        <EmpowerHerVerifyUserInfo />
      </Box>
      <Box mt="20px" display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" border="1px solid grey" rounded="12px" p="12px" gap="8px">
        <Box>
          <Text textStyle="h4">Ban users</Text>
          <Text textStyle="body1" mt="8px">
            Please select a csv file containing user emails and reason for the ban
          </Text>
        </Box>
        <Box display="flex" alignItems="center" gap="8px" flexWrap="wrap">
          <CSVSelector
            disabled={isBanUsersApiLoading}
            onChange={(data) => {
              const dataArray = data.map((row) => {
                const [email, reason] = row;
                return { email, reason };
              });
              setBannedUsersData(dataArray);
            }}
          />
          <Button
            isLoading={isBanUsersApiLoading}
            size="md"
            textStyle="btn-md"
            variant="primary"
            onClick={() => {
              if (bannedUsersData) {
                banUsers(bannedUsersData);
              }
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <Box mt="20px" display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" border="1px solid grey" rounded="12px" p="12px" gap="8px">
        <Box>
          <Text textStyle="h4">Unban users</Text>
          <Text textStyle="body1" mt="8px">
            Please select a csv file containing user emails
          </Text>
        </Box>
        <Box display="flex" alignItems="center" gap="8px" flexWrap="wrap">
          <CSVSelector
            disabled={isUnbanUsersApiLoading}
            onChange={(data) => {
              const dataArray = data.map((row) => {
                const [email] = row;
                return { email };
              });
              setUnbannedUsersData(dataArray);
            }}
          />
          <Button
            isLoading={isUnbanUsersApiLoading}
            size="md"
            textStyle="btn-md"
            variant="primary"
            onClick={() => {
              if (unbannedUsersData) {
                unbanUsers(unbannedUsersData);
              }
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Skeleton>
  );
};

export default UsersAdmin;

type Props = {
  onChange(data: string[][]): void;
  loading?: boolean;
  disabled?: boolean;
};

const CSVSelector = ({ onChange, loading = false, disabled = false }: Props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [file, setFile] = useState<File>();
  const handleFileChange = async (file: File) => {
    if (file) {
      try {
        Papa.parse(file, {
          worker: true, // use a web worker so that the page doesn't hang up
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          complete({ data }: any) {
            const filteredData = data.filter((e: string[], i: number) => i !== 0);
            onChange(filteredData);
          },
        });
      } catch (error) {
        console.error(error);
      }
    }
  };
  const fileTypes = ["CSV"];
  const onTypeError = () => {
    setErrorMessage("Please upload only files of type CSV");
  };
  const onSizeError = () => {
    setErrorMessage("Image size should be less than equal to 100MB");
  };
  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" gap="8px">
      {file && (
        <Text textStyle="body2" color="ms-red.500">
          {file?.name}
        </Text>
      )}
      {errorMessage && (
        <Text textStyle={"body2"} color={"ms-error"}>
          {errorMessage}
        </Text>
      )}

      <Box
        as={FileUploader}
        handleChange={(file: File) => {
          setErrorMessage("");
          setFile(file);
          handleFileChange(file);
        }}
        name="file"
        types={fileTypes}
        maxSize={100}
        onTypeError={onTypeError}
        onSizeError={onSizeError}
      >
        <Button isLoading={loading} disabled={disabled} size="md" textStyle="btn-md">
          {file ? "Change file" : "Select file"}
        </Button>
      </Box>
    </Box>
  );
};
