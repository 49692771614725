import { Icon } from "@chakra-ui/react";

interface Props {
  color?: string;
  boxSize?: string;
  isActive?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BriefcaseV2 = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    {props?.isActive ? (
      <>
        <path
          opacity="0.2"
          d="M21 11.0916V18.75C21 18.9489 20.921 19.1397 20.7803 19.2803C20.6397 19.421 20.4489 19.5 20.25 19.5H3.75C3.55109 19.5 3.36032 19.421 3.21967 19.2803C3.07902 19.1397 3 18.9489 3 18.75V11.0916C5.73518 12.6738 8.84012 13.5047 12 13.5C15.1599 13.505 18.2649 12.6741 21 11.0916Z"
          fill="#3470E4"
        />
        <path
          d="M9.75 10.5C9.75 10.3011 9.82902 10.1103 9.96967 9.96967C10.1103 9.82902 10.3011 9.75 10.5 9.75H13.5C13.6989 9.75 13.8897 9.82902 14.0303 9.96967C14.171 10.1103 14.25 10.3011 14.25 10.5C14.25 10.6989 14.171 10.8897 14.0303 11.0303C13.8897 11.171 13.6989 11.25 13.5 11.25H10.5C10.3011 11.25 10.1103 11.171 9.96967 11.0303C9.82902 10.8897 9.75 10.6989 9.75 10.5ZM21.75 6.75V18.75C21.75 19.1478 21.592 19.5294 21.3107 19.8107C21.0294 20.092 20.6478 20.25 20.25 20.25H3.75C3.35218 20.25 2.97064 20.092 2.68934 19.8107C2.40804 19.5294 2.25 19.1478 2.25 18.75L2.25 6.75C2.25 6.35218 2.40804 5.97064 2.68934 5.68934C2.97064 5.40804 3.35218 5.25 3.75 5.25H7.5V4.5C7.5 3.90326 7.73705 3.33097 8.15901 2.90901C8.58097 2.48705 9.15326 2.25 9.75 2.25L14.25 2.25C14.8467 2.25 15.419 2.48705 15.841 2.90901C16.2629 3.33097 16.5 3.90326 16.5 4.5V5.25H20.25C20.6478 5.25 21.0294 5.40804 21.3107 5.68934C21.592 5.97064 21.75 6.35218 21.75 6.75ZM9 5.25H15V4.5C15 4.30109 14.921 4.11032 14.7803 3.96967C14.6397 3.82902 14.4489 3.75 14.25 3.75L9.75 3.75C9.55109 3.75 9.36032 3.82902 9.21967 3.96967C9.07902 4.11032 9 4.30109 9 4.5V5.25ZM3.75 6.75V10.6519C6.28165 12.0292 9.11794 12.7505 12 12.75C14.8822 12.7505 17.7185 12.0289 20.25 10.6509V6.75L3.75 6.75ZM20.25 18.75V12.3403C17.6813 13.5969 14.8596 14.2501 12 14.25C9.1405 14.2506 6.31868 13.5977 3.75 12.3412L3.75 18.75H20.25Z"
          fill="#3470E4"
        />
      </>
    ) : (
      <>
        <path
          opacity="0.2"
          d="M21 11.0916V18.75C21 18.9489 20.921 19.1397 20.7803 19.2803C20.6397 19.421 20.4489 19.5 20.25 19.5H3.75C3.55109 19.5 3.36032 19.421 3.21967 19.2803C3.07902 19.1397 3 18.9489 3 18.75V11.0916C5.73518 12.6738 8.84012 13.5047 12 13.5C15.1599 13.505 18.2649 12.6741 21 11.0916Z"
          fill="currentColor"
        />
        <path
          d="M9.75 10.5C9.75 10.3011 9.82902 10.1103 9.96967 9.96967C10.1103 9.82902 10.3011 9.75 10.5 9.75H13.5C13.6989 9.75 13.8897 9.82902 14.0303 9.96967C14.171 10.1103 14.25 10.3011 14.25 10.5C14.25 10.6989 14.171 10.8897 14.0303 11.0303C13.8897 11.171 13.6989 11.25 13.5 11.25H10.5C10.3011 11.25 10.1103 11.171 9.96967 11.0303C9.82902 10.8897 9.75 10.6989 9.75 10.5ZM21.75 6.75V18.75C21.75 19.1478 21.592 19.5294 21.3107 19.8107C21.0294 20.092 20.6478 20.25 20.25 20.25H3.75C3.35218 20.25 2.97064 20.092 2.68934 19.8107C2.40804 19.5294 2.25 19.1478 2.25 18.75L2.25 6.75C2.25 6.35218 2.40804 5.97064 2.68934 5.68934C2.97064 5.40804 3.35218 5.25 3.75 5.25H7.5V4.5C7.5 3.90326 7.73705 3.33097 8.15901 2.90901C8.58097 2.48705 9.15326 2.25 9.75 2.25L14.25 2.25C14.8467 2.25 15.419 2.48705 15.841 2.90901C16.2629 3.33097 16.5 3.90326 16.5 4.5V5.25H20.25C20.6478 5.25 21.0294 5.40804 21.3107 5.68934C21.592 5.97064 21.75 6.35218 21.75 6.75ZM9 5.25H15V4.5C15 4.30109 14.921 4.11032 14.7803 3.96967C14.6397 3.82902 14.4489 3.75 14.25 3.75L9.75 3.75C9.55109 3.75 9.36032 3.82902 9.21967 3.96967C9.07902 4.11032 9 4.30109 9 4.5V5.25ZM3.75 6.75V10.6519C6.28165 12.0292 9.11794 12.7505 12 12.75C14.8822 12.7505 17.7185 12.0289 20.25 10.6509V6.75L3.75 6.75ZM20.25 18.75V12.3403C17.6813 13.5969 14.8596 14.2501 12 14.25C9.1405 14.2506 6.31868 13.5977 3.75 12.3412L3.75 18.75H20.25Z"
          fill="currentColor"
        />
      </>
    )}
  </Icon>
);

export default BriefcaseV2;
