import React from "react";

import { Box } from "@chakra-ui/react";
import ProfileFieldCard from "./ProfileFieldCard";
import { useProfile } from "../../../api/utils/hooks/useProfile";

const ReferralCode = () => {
  const { data: profile } = useProfile();
  return (
    <Box>
      <ProfileFieldCard
        keyName="referral-code"
        title={profile?.referral_code}
        heading="Referral Code"
      />
    </Box>
  );
};

export default ReferralCode;
