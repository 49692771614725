import React, { useEffect } from "react";
import { Formik, Field, ErrorMessage, FieldArray } from "formik";
import { Box, Heading, Input, Button, Flex, Checkbox, Select, Text, Textarea } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import { addEvent, updateEvent } from "../../../../api/utils/api/v2/admin";
import useSnackBar from "../../../common/general/SnackBar";
import { axiosErrorHandler } from "../../../../api/utils/error";
import { useEvents } from "../../../../api/utils/hooks/useEvents";
import { useAllAdminEvents, useAllSpeakers } from "../../../../api/utils/hooks/useAdmin";
import { useProfile } from "../../../../api/utils/hooks/useProfile";
import { getFormattedEvent } from "./AllEvents";
import { ModalTemplate } from "../../../common/general";
import SpeakerListModal from "./SpeakerListModal";
import { GetSpeakerType } from "../../../../api/schemas/schema";
import { isNewLineOrTabOr4ExtraSpacePresent } from "../../../../utils/utils";
export enum EventType {
  AMA = "AMA",
  BOOTCAMP = "BOOTCAMP",
  TECHTONIC_SHIFT = "TECHTONIC_SHIFT",
  CRACK_THE_CODE = "CRACK_THE_CODE",
  FOUNDER_WEBINAR = "FOUNDER_WEBINAR",
  MASTERCLASS = "MASTERCLASS",
  WEBINAR = "WEBINAR",
  ONBOARDING = "ONBOARDING",
  IIT_COUNSELING = "IIT_COUNSELING",
  IIT_MANDI_COUNSELING = "IIT_MANDI_COUNSELING",
  IIT_ROPAR_COUNSELING = "IIT_ROPAR_COUNSELING",
}

export enum EventLocation {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
}

export enum RecordingPresent {
  YES = "YES",
  NO = "NO",
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  event_type: Yup.string().required("Event type is required"),
  event_location: Yup.string().required("Event location is required"),
  cost: Yup.string().required("Cost is required"),
  event_end_date: Yup.date().required("Event end date is required"),
  event_start_date: Yup.date().required("Event start date is required"),
});
export type EventFormValues = {
  name: string;
  event_type: EventType;
  event_location: EventLocation;
  cost: string;
  details: {
    onward: { key: string; value: string }[];
    website: { key: string; value: string }[];
  };
  event_end_date: string;
  event_start_date: string;
  event_visible_from?: string;
  is_recording_present: RecordingPresent;
};

interface Props {
  isEdit?: boolean;
  event?: EventFormValues;
  eventId?: number;
}
const AddNewEvent = ({ isEdit, event, eventId }: Props) => {
  const { data: events, refetch: refetchEvents, isLoading } = useAllAdminEvents();
  const [selectedSpeakers, setSelectedSpeakers] = React.useState<GetSpeakerType[]>([]);
  const [eventData, setEventData] = React.useState<EventFormValues | undefined>(event);
  // get search param clone
  const search = useLocation().search;
  const isClone: string | number | null = new URLSearchParams(search).get("clone");
  const { data: profile } = useProfile();
  const [isCloneCheckDone, setIsCloneCheckDone] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState<EventFormValues | null>(null);
  const getHintForAKeyOnward = (key: string) => {
    switch (key) {
      case "title":
        return "Title appears on the Event Detail page next to the Description of the event";
      case "bgImage":
        return "Image of the speaker with the semicircle for desktop. This will appear on Onwards Events Page";
      case "heading":
        return "Heading to be used on the Events Listing Page (both on the website & onwards)";
      case "description":
        return "Event Description which will appear on Events Detail Page (both website & onwards)";
      case "liveUrl":
        return "";
      case "headline":
        return "Short line that you want to use in emailers & whatsapps";
      case "videoUrl":
        return "Vimeo Video url for each event";
      case "meetingID":
        return "Zoom Meeting/Webinar ID";
      case "thumbnail":
        return "Event creative that will appear on Onwards Events Page on Desktop";
      case "bgHexColor":
        return "Color you want to use for the background on Onwards Event Detail Page in the main banner space";
      case "meetingPass":
        return "Zoom Meeting/Webinar Password, enter 123456 if password not applicable";
      case "speakerName":
        return "Speaker Name that will appear on Events Detail page in the About the speaker section";
      case "keyTakeaways":
        return "Short description that you want to use in emailers & whatsapps";
      case "speakerAbout":
        return "About the Speaker description that appears on the Events Detail Page in the About the Speaker section";
      case "speakerImage":
        return "Black n white speaker image that appears on the Event Detail Page in the About the Speaker section";
      case "whatsAppLink":
        return "Whatsapp Community link to be used for the event";
      case "googleEventId":
        return "";
      case "mobileBgImage":
        return "Image of the speaker with the semicircle for mobile. This will appear on Onwards Events Page";
      case "googleEventLink":
        return "";
      case "speakerShortAbout":
        return "Speaker Name, Designation, that appears on Events Detail Page in the top main banner space";
      case "speakerLinkedInUrl":
        return "Speakers Linkedin URL which will appear on the Events Detail Page";
    }
    return "";
  };
  const getHintForAKeyWebsite = (key: string) => {
    switch (key) {
      case "thumbnail":
        return "Event creative that will appear on Website Events Page on Desktop";
      case "bgHexColor":
        return "Color you want to use for the background on Website's Event Listing Page for every thumbnail";
      case "mobileThumbnail":
        return "Event creative that will appear on Onwards Events Page on mobile";
    }
    return "";
  };
  const onwardDetailsDefault = [
    {
      key: "title",
      value: "",
    },
    {
      key: "bgImage",
      value: "",
    },
    {
      key: "heading",
      value: "",
    },
    {
      key: "description",
      value: "",
    },
    { key: "liveUrl", value: "" },
    { key: "headline", value: "" },
    { key: "videoUrl", value: "" },
    { key: "meetingID", value: "" },
    {
      key: "thumbnail",
      value: "",
    },
    {
      key: "bgHexColor",
      value: "",
    },
    {
      key: "meetingPass",
      value: "",
    },
    {
      key: "speakerName",
      value: "",
    },
    {
      key: "keyTakeaways",
      value: "",
    },
    {
      key: "speakerAbout",
      value: "",
    },
    {
      key: "speakerImage",
      value: "",
    },
    { key: "whatsAppLink", value: "" },
    { key: "googleEventId", value: "" },
    {
      key: "mobileBgImage",
      value: "",
    },
    { key: "googleEventLink", value: "" },
    {
      key: "speakerShortAbout",
      value: "",
    },
    {
      key: "speakerLinkedInUrl",
      value: "",
    },
  ];
  const websiteDetailsDefault = [
    {
      key: "thumbnail",
      value: "",
    },
    {
      key: "bgHexColor",
      value: "",
    },
    {
      key: "mobileThumbnail",
      value: "Event creative that will appear on Onwards Events Page on mobile",
    },
  ];

  useEffect(() => {
    if (!profile?.slug) return;

    const localstorageEvent = localStorage.getItem(`cloned-event-${profile?.slug}`);

    if (isClone && localstorageEvent) {
      const parsedEvent = JSON.parse(localstorageEvent);
      const formattedEvent = getFormattedEvent(parsedEvent);

      setInitialValues({
        name: formattedEvent?.name || "",
        event_type: formattedEvent?.event_type || EventType.AMA,
        event_location: formattedEvent?.event_location || EventLocation.ONLINE,
        cost: formattedEvent?.cost || "",
        details: {
          onward: formattedEvent?.details?.onward || onwardDetailsDefault,
          website: formattedEvent?.details?.website || websiteDetailsDefault,
        },
        event_end_date: formattedEvent?.event_end_date || "",
        event_start_date: formattedEvent?.event_start_date || "",
        event_visible_from: formattedEvent?.event_visible_from || "",
        is_recording_present: formattedEvent?.is_recording_present || RecordingPresent.NO,
      });
      setEventData(formattedEvent);
    } else {
      setInitialValues({
        name: event?.name || "",
        event_type: event?.event_type || EventType.AMA,
        event_location: event?.event_location || EventLocation.ONLINE,
        cost: event?.cost || "",
        details: {
          onward: event?.details?.onward || onwardDetailsDefault,
          website: event?.details?.website || websiteDetailsDefault,
        },
        event_end_date: event?.event_end_date || "",
        event_start_date: event?.event_start_date || "",
        event_visible_from: event?.event_visible_from || "",
        is_recording_present: event?.is_recording_present || RecordingPresent.NO,
      });
    }
    setIsCloneCheckDone(true);
  }, [isClone, profile?.slug, event]);

  const snackbar = useSnackBar();
  const { mutate: addEventMutate, isLoading: addEventLoading } = useMutation(addEvent, {
    onSuccess: () => {
      refetchEvents();
      snackbar.success("Event Added Successfully");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });
  const { mutate: updateEventMutate, isLoading: updateEventLoading } = useMutation(updateEvent, {
    onSuccess: () => {
      refetchEvents();
      snackbar.success("Event Updated Successfully");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });
  const [openSpeakerModal, setOpenSpeakerModal] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateData = (onwardDetailsObject: any, websiteDetailsObject: any) => {
    // validate headline
    const headline = (onwardDetailsObject as {
      headline: string;
    })["headline"];

    if (isNewLineOrTabOr4ExtraSpacePresent(headline)) {
      snackbar.error("Headline should not contain newline or tab or 4 extra spaces");
      return;
    }
    // validate keyTakeaways
    const keyTakeaways = (onwardDetailsObject as {
      keyTakeaways: string;
    })["keyTakeaways"];
    if (isNewLineOrTabOr4ExtraSpacePresent(keyTakeaways)) {
      snackbar.error("Key Takeaways should not contain newline or tab or 4 extra spaces");
      return;
    }
    // validate meetingID
    let meetingID = (onwardDetailsObject as {
      meetingID: string;
    })["meetingID"];
    meetingID = meetingID.trim();
    onwardDetailsObject = {
      ...onwardDetailsObject,
      meetingID,
    };
    if (isNewLineOrTabOr4ExtraSpacePresent(meetingID)) {
      snackbar.error("Key Takeaways should not contain newline or tab or 4 extra spaces");
      return;
    }
    // validate meetingPass
    let meetingPass = (onwardDetailsObject as {
      meetingPass: string;
    })["meetingPass"];
    meetingPass = meetingPass.trim();
    onwardDetailsObject = {
      ...onwardDetailsObject,
      meetingPass,
    };
    if (isNewLineOrTabOr4ExtraSpacePresent(meetingPass)) {
      snackbar.error("Key Takeaways should not contain newline or tab or 4 extra spaces");
      return;
    }
    return { onwardDetailsObject, websiteDetailsObject };
  };
  return (
    <>
      <Box width={"100%"} p="24px">
        <Box>
          <Button
            onClick={() => {
              setOpenSpeakerModal(true);
            }}
            my="16px"
            variant="primary"
            size="sm"
          >
            Add/Remove Speaker
          </Button>
          <Box my="16px" display={"flex"} gap="16px">
            {selectedSpeakers.map((speaker, index) => (
              <Box border="1px solid gray" p="16px" borderRadius={"16px"} key={index}>
                <Text>{speaker.name}</Text>
              </Box>
            ))}
          </Box>
        </Box>
        {initialValues && isCloneCheckDone && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              let onwardDetailsObject = values.details.onward.reduce((acc, curr) => {
                return {
                  ...acc,
                  [curr.key]: curr.value,
                };
              }, {});
              let websiteDetailsObject = values.details.website.reduce((acc, curr) => {
                return {
                  ...acc,
                  [curr.key]: curr.value,
                };
              }, {});
              const result = validateData(onwardDetailsObject, websiteDetailsObject);
              onwardDetailsObject = result?.onwardDetailsObject;
              websiteDetailsObject = result?.websiteDetailsObject;

              if (selectedSpeakers.length > 0) {
                onwardDetailsObject = {
                  ...onwardDetailsObject,
                  speakerName: selectedSpeakers[0].name,
                  speakerAbout: selectedSpeakers[0].about,
                  speakerImage: selectedSpeakers[0].image,
                  speakerShortAbout: selectedSpeakers[0].short_about,
                  speakerLinkedInUrl: selectedSpeakers[0].linkedin_url,
                };
              }
              if (selectedSpeakers.length > 1) {
                onwardDetailsObject = {
                  ...onwardDetailsObject,
                  speakerName2: selectedSpeakers[1].name,
                  speakerAbout2: selectedSpeakers[1].about,
                  speakerImage2: selectedSpeakers[1].image,
                  speakerShortAbout2: selectedSpeakers[1].short_about,
                  speakerLinkedInUrl2: selectedSpeakers[1].linkedin_url,
                };
              }

              const payload = {
                name: values.name,
                event_type: values.event_type,
                event_location: values.event_location,
                details: {
                  onward: [onwardDetailsObject],
                  website: [websiteDetailsObject],
                },
                cost: values.cost,
                event_end_data: values.event_end_date,
                event_start_date: values.event_start_date,
                event_visible_from: values.event_visible_from,
                is_recording_present: values.is_recording_present === RecordingPresent.YES ? true : false,
              };

              if (isEdit) {
                if (eventId) {
                  updateEventMutate({
                    eventId: eventId || -1,
                    event: payload,
                  });
                } else {
                  snackbar.error("Event Id is not present");
                }
              } else {
                console.log("payload:", payload);
                addEventMutate(payload);
              }
            }}
          >
            {({ values, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Box display={"grid"} gridTemplateColumns={"repeat(2,1fr)"} gap="16px">
                  <Box mb="16px">
                    <Text mb="8px" textStyle="body2-md">
                      Event Name
                    </Text>
                    <Field as={Input} maxWidth="400px" type="text" name="name" placeholder="Name" />
                    <ErrorMessage name="name" component="div" className="error" />
                  </Box>

                  <Box mb="16px">
                    <Text mb="8px" textStyle="body2-md">
                      Event Type
                    </Text>
                    <Field maxWidth="400px" as={Select} name="event_type">
                      {Object.values(EventType).map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="event_type" component="div" className="error" />
                  </Box>

                  <Box mb="16px">
                    <Text mb="8px" textStyle="body2-md">
                      Event Location
                    </Text>
                    <Field maxWidth="400px" as={Select} name="event_location">
                      {Object.values(EventLocation).map((location) => (
                        <option key={location} value={location}>
                          {location}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="event_location" component="div" className="error" />
                  </Box>

                  <Box mb="16px">
                    <Text mb="8px" textStyle="body2-md">
                      Cost
                    </Text>
                    <Field maxWidth="400px" as={Input} type="text" name="cost" placeholder="Cost" />
                    <ErrorMessage name="cost" component="div" className="error" />
                  </Box>
                  <Box mb="16px">
                    <Text mb="8px" textStyle="body2-md">
                      Event Start Date
                    </Text>
                    <Field maxWidth="400px" as={Input} type="datetime-local" name="event_start_date" />
                    <ErrorMessage name="event_start_date" component="div" className="error" />
                  </Box>
                  <Box mb="16px">
                    <Text mb="8px" textStyle="body2-md">
                      Event End Date
                    </Text>
                    <Field maxWidth="400px" as={Input} type="datetime-local" name="event_end_date" />
                    <ErrorMessage name="event_end_date" component="div" className="error" />
                  </Box>

                  <Box mb="16px">
                    <Text mb="8px" textStyle="body2-md">
                      Event Visible From
                    </Text>
                    <Field maxWidth="400px" as={Input} type="datetime-local" name="event_visible_from" />
                    <ErrorMessage name="event_visible_from" component="div" className="error" />
                  </Box>

                  <Box mb="16px">
                    <Text mb="8px" textStyle="body2-md">
                      Is Recording Present
                    </Text>
                    <Field maxWidth="400px" as={Select} name="is_recording_present">
                      {Object.values(RecordingPresent).map((isRecordingPresent) => (
                        <option key={isRecordingPresent} value={isRecordingPresent}>
                          {isRecordingPresent}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="event_location" component="div" className="error" />
                  </Box>
                </Box>
                <Box display={"grid"} gridTemplateColumns={"repeat(2,1fr)"} gap="16px">
                  <Box mb="16px">
                    <Text mb="8px" textStyle="body2-md">
                      Details - Onward
                    </Text>
                    <FieldArray name="details.onward">
                      {({ push, remove }) => (
                        <>
                          {values.details.onward.map((_, index) => (
                            <>
                              <Box mt="16px">
                                <Text textStyle={"body2-md"} color={"ms-grey.400"}>
                                  {getHintForAKeyOnward(_.key) && "* " + getHintForAKeyOnward(_.key)}
                                </Text>
                              </Box>
                              <Flex key={index} mb="8px" gap="8px">
                                <Field as={Input} type="text" name={`details.onward[${index}].key`} placeholder="type key here..." maxWidth="200px" />
                                <Field
                                  as={Textarea}
                                  // type="text"
                                  name={`details.onward[${index}].value`}
                                  placeholder="type value here..."
                                  maxWidth="400px"
                                />
                                <Box cursor={"pointer"} onClick={() => remove(index)}>
                                  <CloseIcon boxSize={"12px"} color={"ms-error"} />
                                </Box>
                              </Flex>
                            </>
                          ))}
                          <Button size="sm" variant="secondary" onClick={() => push({ key: "", value: "" })}>
                            Add New
                          </Button>
                        </>
                      )}
                    </FieldArray>
                  </Box>

                  <Box mb="16px">
                    <Text mb="8px" textStyle="body2-md">
                      Details - Website
                    </Text>
                    <FieldArray name="details.website">
                      {({ push, remove }) => (
                        <>
                          {values.details.website.map((_, index) => (
                            <>
                              <Box mt="16px">
                                <Text textStyle={"body2-md"} color={"ms-grey.400"}>
                                  {getHintForAKeyWebsite(_.key) && "* " + getHintForAKeyWebsite(_.key)}
                                </Text>
                              </Box>
                              <Flex key={index} mb="8px" gap="8px">
                                <Field as={Input} type="text" name={`details.website[${index}].key`} placeholder="type key here..." maxWidth="200px" />
                                <Field
                                  as={Textarea}
                                  // type="text"
                                  name={`details.website[${index}].value`}
                                  placeholder="type value here..."
                                  maxWidth="400px"
                                />
                                <Box cursor={"pointer"} onClick={() => remove(index)}>
                                  <CloseIcon boxSize={"12px"} color={"ms-error"} />
                                </Box>
                              </Flex>
                            </>
                          ))}
                          <Button size="sm" variant="secondary" onClick={() => push({ key: "", value: "" })}>
                            Add New
                          </Button>
                        </>
                      )}
                    </FieldArray>
                  </Box>
                </Box>
                <Button isLoading={isEdit ? updateEventLoading : addEventLoading} variant="primary" type="submit">
                  {isEdit ? "Update Event" : "Save Event"}
                </Button>
              </form>
            )}
          </Formik>
        )}
      </Box>
      <ModalTemplate
        title=""
        isOpen={openSpeakerModal}
        onClose={() => {
          setOpenSpeakerModal(false);
        }}
        showCloseButtonIcon={true}
        marginX={2}
        maxWidth="472px"
      >
        <SpeakerListModal setSelectedSpeakers={setSelectedSpeakers} selectedSpeakers={selectedSpeakers} />
      </ModalTemplate>
    </>
  );
};

export default AddNewEvent;
