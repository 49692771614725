import { Box, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import React from "react";

const ScholarshipPoints = () => {
  const list: JSX.Element[] = [
    <Text key={1}>
      We offer high aptitude candidates a path where studying with us doesn’t become a financial
      burden!
    </Text>,
    <Text key={2}>Apply for the scholarship in the next page and attempt the test.</Text>,
    <Text key={3} d={"inline"}>
      Based on your perfomance in the scholarship test, you will receive scholarship of upto{" "}
      <Text d={"inline"} textStyle={{ base: "body2-md", md: "body1-md" }} color={"ms-blue.500"}>
        75%
      </Text>{" "}
      on the course fee.
    </Text>,
  ];
  return (
    <Box>
      <Heading as="h4">Prepleaf Scholarship</Heading>
      <UnorderedList mt={"ms-12"}>
        {list.map((item, i) => (
          <ListItem mb={"2px"} key={i}>
            <Text color={"ms-grey.800"} textStyle={{ base: "body2", md: "body1" }}>
              {item}
            </Text>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
};

export default ScholarshipPoints;
