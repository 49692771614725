import React from "react";
import dayjs from "dayjs";
import { Box, Flex, Heading, Text, Button, Link } from "@chakra-ui/react";
import { BellOutline, CalenderRange, ClockOutline } from "../../assets/icons/components";
import { ScheduleCardBody } from "./ScheduleCardBody";
interface Props {
  scheduleDetails: {
    start_date_time: string;
    end_date_time: string;
    join_url: string;
  };
  title: string;
  handleJoin: () => void;
}

export const UpcomingScheduleCard = ({ scheduleDetails, title, handleJoin }: Props) => {
  const date = dayjs(scheduleDetails.start_date_time).format("ddd, MMM DD, YYYY");
  const duration = `${dayjs(scheduleDetails.start_date_time).format("HH:mm A")} - ${dayjs(
    scheduleDetails.end_date_time
  ).format("HH:mm A")}`;

  if (dayjs(date).add(1, "day").isBefore(dayjs())) {
    return null;
  }
  return (
    <>
      <Box padding={"ms-16"} border={"0.5px solid #D9D9D9"} borderRadius={"ms-16"} w="full">
        <Flex>
          <BellOutline boxSize="4" color="ms-red.500" />
          <Text textStyle={"btn-sm"} color={"ms-red.500"} ml="ms-4">
            UPCOMING
          </Text>
        </Flex>
        <Heading as="h6" mt="ms-8">
          {title}
        </Heading>
        <Flex mt="ms-16" align="center" justifyContent={"space-between"}>
          <ScheduleCardBody
            schedule={date}
            icon={<CalenderRange color="ms-grey.500" boxSize={"5"} />}
          />
          <ScheduleCardBody
            schedule={duration}
            icon={<ClockOutline color="ms-grey.500" boxSize={"5"} />}
          />
        </Flex>
        <Box>
          <Flex justifyContent={"flex-end"} mt="ms-24">
            <Link href={scheduleDetails.join_url} isExternal>
              <Button size={"sm"} variant={"secondary"} color={"ms-blue.500"} onClick={handleJoin}>
                JOIN
              </Button>
            </Link>
          </Flex>
        </Box>
      </Box>
    </>
  );
};
