import { Box, Button, Heading, Spinner, Text, useBreakpointValue } from "@chakra-ui/react";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import React from "react";
import { InformationOutline } from "../../../assets/icons/components";

interface Props {
  handleProceedToMSAT: () => void;
  loading?: boolean;
}
const ProceedToContestModalContent = ({ handleProceedToMSAT, loading }: Props) => {
  const { trackEvent } = useMatomo();
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box>
      <InformationOutline color={"ms-alert"} boxSize={"56px"} />
      <Heading mt={"ms-16"} as="h4">
        {isMobile ? `Attempt coding test on a computer device only.` : `Brace yourself!`}
      </Heading>
      <Text mt={"ms-8"} textStyle="body2" color={"ms-grey.800"}>
        {isMobile
          ? `Please note, you can attempt the coding test on a computer device only. Kindly login from a computer device in order to take the 
coding test.`
          : `The Coding Test is about to start. Make sure you are in a peaceful surrounding and are well
        prepared.`}
      </Text>
      <Box display={{ base: "none", md: "flex" }}>
        <Button
          disabled={loading}
          onClick={() => {
            trackEvent({
              category: "Dashboard",
              action: "Proceed",
              name: "dashboard_entry_proceeed",
              value: 200,
            });
            handleProceedToMSAT();
          }}
          ml={"auto"}
          mt={"ms-24"}
          variant={"primary"}
          position={loading ? "relative" : "static"}
        >
          Proceed
          {loading && (
            <Spinner
              speed={"0.5s"}
              thickness={"2px"}
              size={"md"}
              position={"absolute"}
              color={"ms-blue.500"}
            />
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default ProceedToContestModalContent;
