import { Box, Button, Text } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import OnboardingForm, { OnboardingFormType } from "../Onboarding/OnboardingForm";
import { useMutation } from "react-query";
import useSnackBar from "../../common/general/SnackBar";
import { submitCounsellingRequest } from "../../../api/utils/api/v2/activity";
import { axiosErrorHandler } from "../../../api/utils/error";
import { sendBorucherOnEmail, updateProfile } from "../../../api/utils/api/v2/profile";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import { useLocation } from "react-router-dom";
import { useAllCounsellingStatus } from "../../../api/utils/hooks/useCounsellingStatus";
import { sendTrackingEvent } from "../../common/utils/gtm";
import { AppContext } from "../../../context/ApplicationContext";
import IITGRequestCallbackForm from "../RequestCallback";
import { ProgramProfileType } from "../../../enums/ProgramType.enum";

interface Props {
  setOpenDrawer: (openDrawer: boolean) => void;
  source: string;
  setOpenCounsellingSuccessModal?: (openCounsellingSuccessModal: boolean) => void;
}

const CounsellingDrawerContent = ({
  source,
  setOpenDrawer,
  setOpenCounsellingSuccessModal,
}: Props) => {
  const state = useContext(AppContext)[0];
  const [formValues, setFormValues] = useState<OnboardingFormType>({
    dob: null,
    graduationMonth: null,
    graduationYear: null,
    currentlyWorking: undefined,
    areYouComfortableVisitingCampus: "Yes",
    howDidYouHearAboutUs: "",
    queryType: "",
    preferredTiming: "11 AM - 4 PM",
  });
  const [selectedWorkingOption, setSelectedWorkingOption] = useState<string>("");
  const [isGraduationCompleted, setIsGraduationCompleted] = useState<boolean>(true);
  const { refetch: refetchProfile } = useProfile();
  const snackbar = useSnackBar();
  const location = useLocation();
  const { refetch: refetchAllCounsellingStatus } = useAllCounsellingStatus();
  const { mutate: updateProfileMutate, isLoading: updateProfileLoading } = useMutation(
    updateProfile,
    {
      onSuccess: () => {
        const metaData = {
          queryType: "",
          areYouComfortableVisitingCampus: "",
          howDidYouHearAboutUs: "",
          preferredTiming: "",
        };
        if (
          formValues.queryType === "IITG" ||
          formValues.areYouComfortableVisitingCampus ||
          formValues.howDidYouHearAboutUs ||
          formValues.preferredTiming
        ) {
          metaData.queryType = formValues.queryType || metaData.queryType;
          metaData.areYouComfortableVisitingCampus =
            formValues.areYouComfortableVisitingCampus || metaData.areYouComfortableVisitingCampus;
          metaData.howDidYouHearAboutUs =
            formValues.howDidYouHearAboutUs || metaData.howDidYouHearAboutUs;
          metaData.preferredTiming = formValues.preferredTiming || metaData.preferredTiming;
        }
        refetchProfile();
        submitCounsellingRequestMutate({
          source,
          meta: metaData,
        });
      },
      onError: (error) => {
        const e = axiosErrorHandler(error);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("Something went wrong");
        }
      },
    }
  );

  const { mutate: sendBorucher } = useMutation(sendBorucherOnEmail, {
    onSuccess: () => {
      //
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const {
    mutate: submitCounsellingRequestMutate,
    isLoading: submitCounsellingRequestLoading,
  } = useMutation(submitCounsellingRequest, {
    onSuccess: () => {
      setOpenDrawer(false);
      setOpenCounsellingSuccessModal && setOpenCounsellingSuccessModal(true);
      refetchAllCounsellingStatus();
      if (formValues.queryType) {
        sendBorucher({
          request_callback_immediate: true,
        });
      }
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const getIsDisabledValue = () => {
    return (
      formValues.dob == null ||
      formValues.currentlyWorking == undefined ||
      (!formValues.currentlyWorking && selectedWorkingOption === "cw") ||
      (selectedWorkingOption === "cs" &&
        (formValues.graduationMonth == null || formValues.graduationYear == null)) ||
      (isGraduationCompleted &&
        selectedWorkingOption === "nwns" &&
        (formValues.graduationMonth == null || formValues.graduationYear == null))
    );
  };

  const getIsDisabledValueForIITGCallback = () => {
    return (
      formValues.dob == null ||
      formValues.queryType === "" ||
      formValues.howDidYouHearAboutUs === "" ||
      formValues.areYouComfortableVisitingCampus === undefined
    );
  };
  const isIITGDashboard = state.coursePreference === ProgramProfileType.IIT_GUWAHATI;
  const isIITMandiDashboard = state.coursePreference === ProgramProfileType.IIT_MANDI;
  return (
    <Box py="8px" px="16px">
      <Box display={{ md: "none" }} border="1px solid #595959" width={"30%"} mx="auto"></Box>
      <Text
        textAlign={"center"}
        fontSize={"16px"}
        fontWeight={600}
        lineHeight={"24px"}
        mt="24px"
        color="#0A0103"
        maxW={"80%"}
        mx="auto"
      >
        Book a Free Counselling Session with our Team.
      </Text>
      <Box mt="16px">
        {isIITGDashboard || isIITMandiDashboard ? (
          <>
            <IITGRequestCallbackForm
              gridTemplateColumns={"repeat(1,1fr)"}
              formValues={formValues}
              setFormValues={setFormValues}
              hideHeaderText={true}
            />
            <Box
              boxShadow={"0px -8px 32px 0px rgba(0, 0, 0, 0.10);"}
              mt="24px"
              py="12px"
              px="16px"
              p="12px"
              mx="-32px"
              mb="-24px"
            >
              <Button
                isLoading={updateProfileLoading || submitCounsellingRequestLoading}
                width={"100%"}
                variant="primary"
                onClick={() => {
                  sendTrackingEvent({
                    event: "d_counselling_submit_iitg",
                    eventLabel: location.pathname,
                  });
                  updateProfileMutate({
                    date_of_birth: formValues.dob || "",
                  });
                }}
                isDisabled={getIsDisabledValueForIITGCallback()}
              >
                Submit
              </Button>
            </Box>
          </>
        ) : (
          <>
            <OnboardingForm
              gridTemplateColumns={"repeat(1,1fr)"}
              formValues={formValues}
              setFormValues={setFormValues}
              hideEmailVerifyInput={state.coursePreference === ProgramProfileType.IIT_GUWAHATI}
              hideHeaderText={true}
              isGraduationCompleted={isGraduationCompleted}
              setIsGraduationCompleted={setIsGraduationCompleted}
              selectedWorkingOption={selectedWorkingOption}
              setSelectedWorkingOption={setSelectedWorkingOption}
            />
            <Box
              boxShadow={"0px -8px 32px 0px rgba(0, 0, 0, 0.10);"}
              mt="24px"
              py="12px"
              px="16px"
              p="12px"
              mx="-32px"
              mb="-24px"
            >
              <Button
                isLoading={updateProfileLoading || submitCounsellingRequestLoading}
                width={"100%"}
                variant="primary"
                onClick={() => {
                  sendTrackingEvent({
                    event: "d_counselling_submit",
                    eventLabel: location.pathname,
                  });
                  updateProfileMutate({
                    date_of_birth: formValues.dob || "",
                    graduation_year: formValues.graduationYear || -1,
                    graduation_month: formValues.graduationMonth || -1,
                    is_working: formValues.currentlyWorking || false,
                  });
                }}
                isDisabled={getIsDisabledValue()}
              >
                Submit
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default CounsellingDrawerContent;
