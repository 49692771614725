import { Box, Button, Flex, HStack, Icon, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { useTimeLeft } from "../../activities/useCountDown";
interface CountdownProps {
  contestStartTime: string;
}

interface TimeLeft {
  isRunning: boolean;
  timeInString: string;
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
  onlyHours: string;
  onlyMinutes: string;
  onlySeconds: string;
}

const NavbarTimerBanner = ({ showTimer = true }: { showTimer?: boolean }) => {
  const till = "2023-12-16 24:00:00"; // Set this to the contest start time
  const bannerHideDate = dayjs(till).add(7, "day"); // Set this to the contest start time
  const dateNow = new Date();

  const padTo2Digits = (num: number): string => {
    return num.toString().padStart(2, "0");
  };

  const { timeLeft, isLoading } = useTimeLeft(till);
  const daysLeft = useMemo(() => {
    if (!timeLeft) return "--";
    return Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  }, [timeLeft]);
  const hoursLeft = useMemo(() => {
    if (!timeLeft) return "--";
    return Math.floor(timeLeft / (1000 * 60 * 60));
  }, [timeLeft]);
  const minutesLeft = useMemo(() => {
    if (!timeLeft) return "--";
    return Math.floor((timeLeft / (1000 * 60)) % 60);
  }, [timeLeft]);
  const secondsLeft = useMemo(() => {
    if (!timeLeft) return "--";
    return Math.floor((timeLeft / 1000) % 60);
  }, [timeLeft]);
  const toShow = useMemo(() => {
    if (isLoading)
      return [
        ["--", "Days"],
        ["--", "Hrs"],
        ["--", "Mins"],
        ["--", "Sec"],
      ];

    if (daysLeft) {
      return [
        [daysLeft, "Days"],
        [hoursLeft, "Hrs"],
        [minutesLeft, "Mins"],
        [secondsLeft, "Sec"],
      ];
    }

    return [
      [hoursLeft, "Hrs"],
      [minutesLeft, "Mins"],
      [secondsLeft, "Sec"],
    ];
  }, [isLoading, hoursLeft, minutesLeft, secondsLeft]);

  return showTimer ? (
    !dayjs(dateNow).isAfter(bannerHideDate) ? (
      <Box
        w="full"
        textAlign={"center"}
        alignItems={"center"}
        display={"flex"}
        flexWrap={"nowrap"}
        gap={{ base: 2, md: 4 }}
      >
        <Box bg="#7261DF" w="full" h={{ md: "36px" }}>
          {dayjs(dateNow).isBefore(till) ? (
            <Flex
              maxW="1440px"
              mx="auto"
              justifyContent="center"
              alignItems="center"
              flexDir={{ base: "column", md: "row" }}
              textAlign={{ base: "center", md: "left" }}
              py="6px"
              px="10px"
            >
              {/* <Text textStyle={{ base: "body2", md: "body1" }} color="#FFFFFF" mr="8px">
                Hurry up! Application closes on{" "}
                <Text as="span" textStyle="h6" color="white">
                  6 November, 2023
                </Text>
                .
              </Text> */}
              <Text textAlign="center" textStyle={{ base: "body2", md: "body1" }} textColor="white">
                Admissions for the <strong>18th Dec</strong> Batch will close on{" "}
                <strong>16th Dec at 11:59 PM</strong>. Hurry!
              </Text>
              {/* <HStack spacing="0px" alignItems="center" mt={{ base: "5px", md: "0" }}>
                {toShow.map(([value, unit], index) => (
                  <>
                    <Flex alignItems="center">
                      {index !== 0 ? (
                        <Icon viewBox="0 0 4 14" w="4px" h="14px" fill="#AEC6F4" mx="8px">
                          <circle cx="2" cy="2" r="2" fill="#AEC6F4" />
                          <circle cx="2" cy="12" r="2" fill="#AEC6F4" />
                        </Icon>
                      ) : null}
                      <Text as="h6" display="flex" textStyle="h6" minW="20px" color="#FFDB66">
                        {value === "--" ? value : padTo2Digits(+value)}
                      </Text>
                      <Text textStyle="body2-md" color="#FFFFFF" ml="4px">
                        {unit}
                      </Text>
                    </Flex>
                  </>
                ))}
              </HStack> */}
            </Flex>
          ) : (
            // <Flex justifyContent="center" py="6px" alignItems="center" h="full">
            //   <Text textAlign="center" textStyle={{ base: "body2", md: "body1" }} textColor="white">
            //     You are now applying for the <strong>18th December</strong> batch.
            //   </Text>
            // </Flex>
            <Box bg="#7261DF" w="full" h={{ md: "36px" }}>
              <Flex justifyContent="center" py="6px" alignItems="center" h="full">
                <Text
                  textAlign="center"
                  textStyle={{ base: "body2", md: "body1" }}
                  textColor="white"
                >
                  You are now applying for our <strong>26th February</strong> batch.
                </Text>
              </Flex>
            </Box>
          )}
        </Box>
      </Box>
    ) : (
      <></>
    )
  ) : dayjs(dateNow).isBefore(bannerHideDate) && dayjs(dateNow).isAfter(till) ? (
    // <Box bg="#7261DF" w="full" h={{ md: "36px" }}>
    //   <Flex justifyContent="center" py="6px" alignItems="center" h="full">
    //     <Text textAlign="center" textStyle={{ base: "body2", md: "body1" }} textColor="white">
    //       You are now applying for the <strong>18th December</strong> batch.
    //     </Text>
    //   </Flex>
    // </Box>
    <Box bg="#7261DF" w="full" h={{ md: "36px" }}>
      <Flex justifyContent="center" py="6px" alignItems="center" h="full">
        <Text textAlign="center" textStyle={{ base: "body2", md: "body1" }} textColor="white">
          You are now applying for our <strong>26th February</strong> batch.
        </Text>
      </Flex>
    </Box>
  ) : (
    // <Box bg="#7261DF" w="full" h={{ md: "36px" }}>
    //   <Flex justifyContent="center" py="6px" alignItems="center" h="full">
    //     <Text textAlign="center" textStyle={{ base: "body2", md: "body1" }} textColor="white">
    //       Admissions for the <strong>18th Dec</strong> Batch will close on{" "}
    //       <strong>16th Dec at 11:59 PM</strong>. Hurry!
    //     </Text>
    //   </Flex>
    // </Box>
    <></>
  );
};

export default NavbarTimerBanner;
