import { Box, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { TestCard1 } from "../../../../assets/images";
import { BottomStickyNavbar, ModalTemplate } from "../../../../components/common/general";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import { AppContext } from "../../../../context/ApplicationContext";
import AboutMSATCards from "../../../Application/msat/new-msat/first-tab/AboutMSATCards";
import CodingInstructionsList from "../CodingInstructionsList";
import InstructionsWelcome from "../InstructionsWelcome";
import ProceedToContestModalContent from "../ProceedToContestModalContent";
import WhatNextModal from "../WhatNextModal";
import CurrentStage from "./CurrentStage";

export type MasaiXStageType =
  | "MSAT"
  | "CODING"
  | "AADHAAR_VERIFICATION"
  | "INTERVIEW"
  | "ONBOARDING";

interface Props {
  currentStage: MasaiXStageType;
  contestModal: boolean;
  setContestModal: (contestModal: boolean) => void;
  handleProceedToMSAT: (isPractice: boolean) => void;
  loading?: boolean;
  setLoading: (loading: boolean) => void;
}
const MasaiXInstructions = ({
  currentStage,
  contestModal,
  setContestModal,
  handleProceedToMSAT,
  loading,
  setLoading,
}: Props) => {
  const location = useLocation();
  const getBoldText = (text: string) => {
    return (
      <Text display={"inline"} textStyle={{ base: "body2-md", md: "body1-md" }}>
        {text}
      </Text>
    );
  };
  const getListItems = () => {
    return [
      <Text textStyle={{ base: "body2", md: "body1" }} key={0}>
        You will only have {getBoldText(`2 attempt`)} at coding test.
      </Text>,
      <Text key={1}>
        You will be attempting the Coding Test, where your technical skills will be put to test.
      </Text>,
      <Text key={2}>
        You will have a total of {getBoldText("60 minutes")} to complete and submit the coding test.
      </Text>,
      <Text key={3}>
        Once the timer runs out, your {getBoldText(`response will be saved automatically.`)}{" "}
      </Text>,
      <Text key={4}>
        {getBoldText(`On completing and clearing the Coding Test, `)}you will proceed to the
        technical interview with our Admissions Team.
      </Text>,
      <Text key={5}>
        If you are {getBoldText(`unable to clear the Coding Test, `)} you can still apply to our
        other Fast Paced Learning courses in Software Development & Data Analytics.
      </Text>,
    ];
  };

  const [whatNextModal, setWhatNextModal] = useState<boolean>(false);
  const state = useContext(AppContext)[0];
  useEffect(() => {
    if (currentStage === "CODING") {
      setWhatNextModal(true);
    }
  }, []);
  const handleModalProceed = () => {
    setWhatNextModal(false);
  };
  const testCardsData = [
    {
      key: 1,
      heading: "Practice Coding Test",
      text: `Try out the coding test platform for practice to get yourself ready.`,
      btnText: "Practice Test",
      image: TestCard1,
      handleClick: () => {
        handleProceedToMSAT(true);
        sendTrackingEvent({
          event: "mx_ctbt_practice_coding_test",
          eventLabel: location.pathname,
        });
      },
    },
  ];

  return (
    <>
      <Box>
        {/* Current Stage */}
        <CurrentStage
          customMasaiXStagesText={[
            "Coding Test",
            "Technical Interview",
            "Aadhaar Verification",
            "Onboarding",
          ]}
          customMasaiXStagesMargin={["-24px", "-44px", "-55px", "-24px"]}
          currentStage={currentStage}
        />
        <Box p={"ms-24"}>
          <InstructionsWelcome type="masaix" />

          <Box
            mt={{ base: "16px", md: "0px" }}
            display={"flex"}
            flexDirection={{ base: "column", md: "row" }}
            sm={"30em"}
          >
            <CodingInstructionsList list={getListItems()} />
            <Box mt={{ base: "24px", md: "0px" }}>
              <AboutMSATCards
                isScholarhipTest={false}
                testCardsData={testCardsData}
                loading={loading}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <BottomStickyNavbar
        // loading={loading}
        btnPrimary={"attempt coding test"}
        btnPrimaryProps={{
          size: "lg",
          onClick: () => {
            sendTrackingEvent({
              event: "mx_ctbt_test_attempt",
              eventLabel: location.pathname,
            });
            setContestModal(true);
          },
        }}
      ></BottomStickyNavbar>
      <ModalTemplate
        title=""
        isOpen={contestModal}
        onClose={() => {
          sendTrackingEvent({
            event: "mx_ctbt_test_attempt_x",
            eventLabel: location.pathname,
          });
          setContestModal(false);
        }}
        marginX={2}
        maxWidth="552px"
        showCloseButtonIcon={true}
      >
        <ProceedToContestModalContent
          loading={loading}
          handleProceedToMSAT={() => {
            sendTrackingEvent({
              event: "mx_ctbt_test_attempt_proceed",
              eventLabel: location.pathname,
            });
            handleProceedToMSAT(false);
          }}
        />
      </ModalTemplate>
      <ModalTemplate
        title=""
        isOpen={whatNextModal}
        onClose={() => {
          sendTrackingEvent({
            event: "mx-ctbt-steps-db-x",
            eventLabel: location.pathname,
          });
          setWhatNextModal(false);
        }}
        marginX={2}
        maxWidth="600px"
        showCloseButtonIcon={true}
        scrollBehavior="inside"
      >
        <WhatNextModal
          handleModalProceed={() => {
            sendTrackingEvent({
              event: "mx-ctbt-steps-db-proceed",
              eventLabel: location.pathname,
            });
            handleModalProceed();
          }}
        />
      </ModalTemplate>
    </>
  );
};

export default MasaiXInstructions;
