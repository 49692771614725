import { Box, Flex, Text, Link as ChakraLink } from "@chakra-ui/react";
import { ArrowRight } from "../../../assets/icons/components";

const ManualIdVerification = () => {
  return (
    <Box mt={{ base: "ms-16", md: "ms-24" }}>
      <Flex gap={"ms-6"} direction={{ base: "column", md: "column" }}>
        {/* <Text textStyle={"body2"} color="ms-grey.700">
          We are facing issues with adhaar portal for document verification. We request you to fill
          this form.
        </Text> */}
        <ChakraLink
          color={"ms-blue.500"}
          isExternal
          href="https://docs.google.com/forms/d/e/1FAIpQLScL4a618OmQ2tLYYfs7FWrxpyAbF-peS9fMUE3ulvUWDEoXIQ/viewform"
        >
          <Flex gap="ms-8" alignItems={"center"}>
            <Text textStyle={"btn-sm"}>Apply for Manual Aadhaar Verification</Text>
            <ArrowRight boxSize="4" />
          </Flex>
        </ChakraLink>
      </Flex>
    </Box>
  );
};
export { ManualIdVerification };
