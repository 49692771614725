import { Box, Heading, Text, Image, keyframes } from "@chakra-ui/react";

import { BlinkInnerGreen, BlinkOuterGreen } from "../../../../../assets/icons/components";
import PrepleafLogo from "../../../../../assets/images/CoursePrograms/prepleaf-logo.svg";

const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

interface Props {
  courseName: string;
  level: number;
  startDate: string;
}
const ScholarInstructionsHeader = ({ courseName, level, startDate }: Props) => {
  return (
    <Box>
      <Heading as="h3">
        {courseName}{" "}
        <Heading display={"inline"} as="h3" color={"ms-grey.700"}>
          {`(Level ${level})`}
        </Heading>
      </Heading>
      <Box display={"flex"} alignItems={"flex-end"}>
        <Text mr={"ms-4"} mt={{ base: "ms-8", md: "ms-12" }} textStyle={"body1"}>
          {`Programming course specially curated for students in 1st, 2nd or Pre-Final Year of College by`}
          <Image
            mb={"-8px"}
            ml={"ms-8"}
            w={"62px"}
            d={{ base: "inline", md: "none" }}
            src={PrepleafLogo}
          />
        </Text>
        <Image d={{ base: "none", md: "block" }} src={PrepleafLogo} />
      </Box>
      {courseName !== "Programming: Advanced" && (
        <Box mt={"ms-16"} display={"flex"}>
          <Box mr={"ms-8"} position="relative">
            <BlinkInnerGreen color="ms-green.500" boxSize="1.5rem" />
            <Box animation={`${blink} 1s infinite`} position="absolute" top="0">
              <BlinkOuterGreen color="ms-green.500" boxSize="1.5rem" />
            </Box>
          </Box>
          <Text mr={"ms-8"} color="ms-red.900" textStyle={{ base: "body2-md", md: "body1-md" }}>
            Course Starts On:
          </Text>
          <Text color="ms-red.900" textStyle={{ base: "body2", md: "body1" }}>
            {startDate}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default ScholarInstructionsHeader;
