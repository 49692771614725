export const launchFormDataPartTime = [
  {
    type: "input",
    name: "pan",
    title: "What is your PAN Card Number ?",
    isCompulsary: true,
    placeholder: "Your answer",
    pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
  },
  {
    type: "input",
    name: "pincode",
    title: "What is your pincode?",
    isCompulsary: true,
    placeholder: "Your answer",
    pattern: /^([0-9]){6}?$/,
  },
  {
    type: "radio",
    name: "annual_income",
    title: "What is your annual family income ?",
    isCompulsary: true,
    radioOptions: [
      {
        key: "0 - 2.5 Lakhs",
        value: "0 - 2.5 Lakhs",
      },
      {
        key: "2.5 - 5 Lakhs",
        value: "2.5 - 5 Lakhs",
      },
      {
        key: "5 - 7.5 Lakhs",
        value: "5 - 7.5 Lakhs",
      },
      {
        key: "7.5 - 10 Lakhs",
        value: "7.5 - 10 Lakhs",
      },
      {
        key: "10 Lakhs and Above",
        value: "10 Lakhs and Above",
      },
    ],
  },
  {
    type: "input",
    name: "parent_name",
    title: "What is your Father or your Mother's name ?",
    isCompulsary: true,
    placeholder: "Your answer",
  },
  {
    type: "input",
    name: "parent_mobile_number",
    title:
      "Please share your Father or your Mother's phone number. (enter 10 digit, should be different from registered number, we may reach out to them at some stage in the curriculum, whenever needed)",
    isCompulsary: true,
    placeholder: "Your answer",
    pattern: /^([0-9]){10}?$/,
  },
  {
    type: "input",
    name: "parent_residential_address",
    title:
      "Please share your Parent's residential Address. ( This may be used for some communication)",
    isCompulsary: true,
    placeholder: "Your answer",
  },
  {
    type: "input",
    name: "reference_mobile_number",
    title: "Please share your reference phone number. (Enter the 10 digit mobile number)",
    isCompulsary: true,
    placeholder: "Your answer",
    pattern: /^([0-9]){10}?$/,
  },
  {
    type: "input",
    name: "reference_relation ",
    title: "What's your relation with the reference?",
    isCompulsary: true,
    placeholder: "Your answer",
  },
  {
    name: "how_did_you_hear_about_us",
    type: "radio",
    title: "How did you come to know about Masai ?",
    isCompulsary: true,
    radioOptions: [
      {
        key: "Youtube",
        value: "Youtube",
      },
      {
        key: "Google search",
        value: "Google search",
      },
      {
        key: "Facebook",
        value: "Facebook",
      },
      {
        key: "Instagram",
        value: "Instagram",
      },
      {
        key: "Ad on a Website Not Listed Here",
        value: "Ad on a Website Not Listed Here",
      },
      {
        key: "Friend or Family",
        value: "Friend or Family",
      },
      {
        key: "Came Through Referral Code",
        value: "Came Through Referral Code",
      },
      {
        key: "Masai Alumni",
        value: "Masai Alumni",
      },
      {
        key: "Masai Coding Classroom",
        value: "Masai Coding Classroom",
      },
      {
        key: "Other",
        value: "Other",
      },
    ],
  },
  //start- conditional question when selecting-value "Other"
  {
    type: "input",
    name: "how_did_you_hear_about_us_section_how_did_you_hear_about_us_other_option",
    title: "Where did you come to know about Masai ?",
    isCompulsary: true,
    placeholder: "Your answer",
  },
  //end
  {
    name: "highest_education_degree",
    type: "radio",
    title: "What's your highest educational degree?",
    isCompulsary: true,
    radioOptions: [
      {
        key: "Class 12th Pass",
        value: "Class 12th Pass",
      },
      {
        key: "Bachelors",
        value: "Bachelors",
      },
      {
        key: "Masters",
        value: "Masters",
      },
      {
        key: "Doctorate",
        value: "Doctorate",
      },
    ],
  },
  //start- conditional question if value = Bachelors
  {
    type: "dropdown",
    name: "highest_education_degree_section_bachelors_specialization",
    title: "Bachelors Specialization",
    isCompulsary: true,
    dropdownOptions: [
      "Bachelors in Arts",
      "Bachelors in Science: Core Sciences",
      "Bachelors in Science: Computer Science",
      "B.Tech/BE, Non-CS/IT",
      "B. Tech/BE, CS/IT",
      "Bachelors of Management Studies/ Business Administration",
      "Bachelor of Legislative Law(LLB)",
      "Bachelor of Computer Administration",
      "Others",
    ],
  },
  //end
  //start- conditional question if value = Masters
  {
    type: "dropdown",
    name: "highest_education_degree_section_masters_specialization",
    title: "Masters Specialization",
    isCompulsary: true,
    dropdownOptions: [
      "Masters in Arts",
      "Masters in Science: Core Sciences",
      "Masters in Science: Computer Science",
      "M. Tech/ME, Non-CS/IT",
      "M. Tech/ME, CS/IT",
      "Masters of Management Studies/Business Administration",
      "Masters of Legislative Law(LLM)",
      "Masters of Computer Administration",
      "Others",
    ],
  },
  //end
  {
    type: "input",
    name: "final_year_grades",
    title:
      "Final Year grades (CGPA / Percentage) in highest qualification [In case of drop out prior to final year, mention the final exam grades.]",
    isCompulsary: true,
    placeholder: "Your answer",
    footerText:
      "Please enter a value between 0 and 10. Kindly divide percentage marks by 10 if GPA is not provided.",
  },
  // {
  //   type: "input",
  //   name: "college_name",
  //   title:
  //     "College Name (Please share full college name, if not applicable write NA)\n\nex - correct : graphic era university , incorrect : GEU",
  //   isCompulsary: true,
  //   placeholder: "Your answer",
  // },
  // {
  //   type: "input",
  //   name: "college_pincode",
  //   title: "College Pin Code",
  //   isCompulsary: true,
  //   placeholder: "Your answer",
  //   pattern: /^([0-9]){6}?$/,
  // },
  {
    name: "current_status",
    type: "radio",
    title: "Which of these statements describes you the best",
    isCompulsary: true,
    radioOptions: [
      {
        key: "I am currently working",
        value: "I am currently working",
      },
      {
        key: "I am currently looking for work",
        value: "I am currently looking for work",
      },
      {
        key: "I am currently studying in college",
        value: "I am currently studying in college",
      },
      {
        key: "I am taking a break from work",
        value: "I am taking a break from work",
      },
      {
        key: "I just quit my job",
        value: "I just quit my job",
      },
      {
        key: "I just completed college and not looking for a job",
        value: "I just completed college and not looking for a job",
      },
      {
        key: "I am doing an internship",
        value: "I am doing an internship",
      },
    ],
  },
  //start based on values = I am currently working, I am taking a break from work, I just quit my job
  {
    type: "input",
    name: "current_status_section_current_company",
    title: "Name of company you are/were working with ?",
    isCompulsary: true,
    placeholder: "Your answer",
  },
  {
    type: "input",
    name: "current_status_section_work_experience",
    title: "What is your total work experience (in months) ?",
    isCompulsary: true,
    placeholder: "Your answer",
  },
  {
    type: "input",
    name: "current_status_section_current_designation",
    title: "What is your current/most recent designation in the company ?",
    isCompulsary: true,
    placeholder: "Your answer",
  },
  {
    type: "input",
    name: "current_status_section_current_ctc",
    title: "Enter your current/most recent or last CTC? ( Lakhs per annum) ",
    isCompulsary: true,
    placeholder: "Your answer",
  },
  //end
  {
    name: "past_coding_data_analytics_experience",
    type: "radio",
    title: "Do you have some coding/data analysis background ?",
    isCompulsary: true,
    radioOptions: [
      {
        key: "No",
        value: "No",
      },
      {
        key: "Yes",
        value: "Yes",
      },
    ],
  },
  //start - value line Yes
  {
    type: "dropdown",
    name: "past_coding_data_analytics_experience_section_yes",
    title: "Where did you learn",
    isCompulsary: true,
    dropdownOptions: [
      "Online Courses",
      "Youtube Videos",
      "Learnt in College Course",
      "Learnt from a friend",
    ],
  },
  //end
  {
    name: "other_products_you_have_enquired_about",
    type: "radio",
    title: "Have you attended any other bootcamp/online coding classes",
    isCompulsary: true,
    radioOptions: [
      {
        key: "Yes",
        value: "Yes",
      },
      {
        key: "No",
        value: "No",
      },
    ],
  },
  {
    name: "current_os",
    type: "radio",
    title: " Which Operating System do you currently use?",
    isCompulsary: true,
    radioOptions: [
      {
        key: "Windows",
        value: "Windows",
      },
      {
        key: "Linux/Ubuntu",
        value: "Linux/Ubuntu",
      },
      {
        key: "iOS",
        value: "iOS",
      },
      {
        key: "Other",
        value: "Other",
      },
    ],
  },
  {
    type: "radio",
    name: "current_ram",
    title: "What is the RAM size installed on your system ?",
    isCompulsary: true,
    radioOptions: [
      {
        key: "Less than 2GB",
        value: "Less than 2GB",
      },
      {
        key: "4-8 GB",
        value: "4-8 GB",
      },
      {
        key: "More than 8 GB",
        value: "More than 8 GB",
      },
    ],
  },
  {
    name: "webcam_condition",
    type: "radio",
    title: "Do you have a laptop camera / webcam in working condition ?",
    isCompulsary: true,
    radioOptions: [
      {
        key: "Yes",
        value: "Yes",
      },
      {
        key: "No",
        value: "No",
      },
    ],
  },
  {
    name: "network_infrastructure",
    type: "dropdown",
    title: "What Internet Connection do you intend to use for the course opted at Masai ?",
    isCompulsary: true,
    dropdownOptions: [
      "Broadband",
      "Wifi Dongle",
      "Mobile Hotspot / Tethering",
      "Fibre to Home (FTH)",
    ],
  },
  {
    type: "radio",
    name: "commitments",
    title:
      "Do you have any of the listed commitments that might affect your active participation in the program enrolled ?",
    isCompulsary: true,
    radioOptions: [
      {
        key: "Health issues",
        value: "Health issues",
      },
      {
        key: "Semester Exams",
        value: "Semester Exams",
      },
      {
        key: "Family obligations",
        value: "Family obligations",
      },
      {
        key: "Bad Internet connection",
        value: "Bad Internet connection",
      },
      {
        key: "No personal computer/private space to learn",
        value: "No personal computer/private space to learn",
      },
      {
        key: "None",
        value: "None",
      },
      {
        key: "Others",
        value: "Others",
      },
    ],
  },
  {
    name: "bank_account_details",
    type: "radio",
    title: "Bank Account Details",
    isCompulsary: true,
    radioOptions: [
      {
        key: "Personal Bank Account with Internet Banking",
        value: "Personal Bank Account with Internet Banking",
      },
      {
        key: "Personal Bank Account without Internet Banking",
        value: "Personal Bank Account without Internet Banking",
      },
      {
        key: "I don't have a personal bank account",
        value: "I don't have a personal bank account",
      },
    ],
  },
  {
    name: "mobile_number_bank_account",
    type: "radio",
    title:
      "Is your mobile number registered with your bank account ? (Note the mobile number registered with your Bank account should be the same that you used for course registration) ",
    isCompulsary: true,
    radioOptions: [
      {
        key: "Yes",
        value: "Yes",
      },
      {
        key: "No",
        value: "No",
      },
    ],
  },
  {
    name: "aadhaar_bank_link",
    type: "radio",
    title: "Is your Aadhaar linked with your bank account ? ",
    isCompulsary: true,
    radioOptions: [
      {
        key: "Yes",
        value: "Yes",
      },
      {
        key: "No",
        value: "No",
      },
    ],
  },
  {
    type: "input",
    name: "cibil_score",
    title:
      'Credit Score (CIBIL score). Use the link to check your credit score <br/> \n <a target="_blank"  href="https://homeloans.sbi/getcibil">https://homeloans.sbi/getcibil</a> or \n\n <a target="_blank" href="https://consumer.experian.in/ECV-OLN/signIn?_ga=2.47060775.1094535989.1624278871-1376078730.1624278871">https://consumer.experian.in</a> \n \n (If you are not able to generate the score , enter the message displayed in the report generated)',
    isCompulsary: true,
    placeholder: "Your answer",
    footerText:
      "1.https://homeloans.sbi/getcibil  or 2.https://consumer.experian.in/ECV-OLN/signIn",
  },
];
