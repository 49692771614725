import {
  Accordion,
  AccordionButton,
  // AccordionIcon,
  AccordionItem,
  // AccordionPanel,
  // AccordionPanel,
  Box,
  Button,
  Heading,
  Image,
  Link,
  // ListItem,
  Radio,
  RadioGroup,
  Text,
  // UnorderedList,
  useBreakpointValue,
} from "@chakra-ui/react";
import FeebackForm from "../../../../components/cap-program/FeedbackFrom/FeebackForm";
import { ModalTemplate } from "../../../../components/common/general";

import { GreenCheckIcon } from "../../../../assets/images";

import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import {
  hasEntityRated,
  rateEntity,
  submitOnboardingDetailsForCap,
} from "../../../../api/utils/api";
import { axiosErrorHandler } from "../../../../api/utils/error";
import { Slack, Zoom } from "../../../../assets/icons/components";
import useSnackBar from "../../../../components/common/general/SnackBar";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import { AppContext } from "../../../../context/ApplicationContext";
import { Banner } from "../../Banner";
import RequestCallback from "../../../../components/cap-program/RequestCallback/RequestCallback";
const paymentMethodIdWithFeaturesMapping = {
  1: {
    name: "Plan A",
    amount: "5555",
    months: "36",
  },
  2: {
    name: "Plan A",
    amount: "5555",
    months: "36",
  },
};
const CapPaymentSuccess = () => {
  const state = useContext(AppContext)[0];
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const [hasProcessRated, setHasProcessRated] = useState<boolean | undefined>(undefined);
  const start_date = state.applicationData?.batch_campus.batch.start_date;
  const [isPaymentMethodSelected, setPaymentMethodSelected] = useState<boolean>(false);
  const [paymentMethodId, setPaymentMethodId] = useState<number | undefined>(undefined);
  const batch = state.applicationData?.batch_campus.batch;
  const zoom_link = batch?.zoom_webinar_link;
  const snackbar = useSnackBar();
  const bottomSpace = useBreakpointValue({
    base: "40px",
    md: "24px",
  });

  const course = state.applicationData?.batch_campus?.batch?.course;

  let isCapData = false;

  switch (course?.name) {
    case "Career Accelerator Program":
      isCapData = false;
      break;
    case "Career Accelerator Program - Data Science":
      isCapData = true;

      break;
  }

  useEffect(() => {
    const func = async () => {
      const isRated = await handleCheckHasEntityRated();
      setHasProcessRated(isRated);
    };
    func();
  }, []);
  useEffect(() => {
    if (state.applicationData?.status === "ONBOARDING_COMPLETE") {
      setPaymentMethodSelected(true);
      // const paymentMethodId: number = +state.applicationData?.launch_form?.payment_method_id;
      const paymentMethodId: number | undefined = state.applicationData?.payment_record?.find(
        (paymentRecord) =>
          paymentRecord.status === "PAID" && Number(paymentRecord?.payment_method_id_for_cap)
      )?.payment_method_id_for_cap;
      setPaymentMethodId(paymentMethodId);
    }
  }, [state]);
  useEffect(() => {
    if (!hasProcessRated && typeof hasProcessRated === "boolean" && isPaymentMethodSelected) {
      setIsRatingModalOpen(true);
    } else {
      setIsRatingModalOpen(false);
    }
  }, [hasProcessRated]);
  const slack_link = state.applicationData?.batch_campus?.slack_link;
  const handleSubmit = async (payment_method_id: string) => {
    try {
      await submitOnboardingDetailsForCap({
        application_slug: state.applicationData?.slug || "",
        payment_method_id,
      });
      setPaymentMethodId(+payment_method_id);
      snackbar.success("Onboarding completed successfully");
      setPaymentMethodSelected(true);
      if (!hasProcessRated) {
        setTimeout(() => {
          setIsRatingModalOpen(true);
        }, 2000);
      }
    } catch (err) {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    }
  };
  const handleSubmitRating = async (rating: number, description: string) => {
    if (hasProcessRated || (await handleCheckHasEntityRated())) return;
    const appId: number = state.applicationData?.id || 0;
    try {
      await rateEntity({
        points: rating,
        comment: description,
        entity_type: "APPLICATION",
        entity_id: appId,
      });
    } catch (err) {
      // const e = axiosErrorHandler(err);
      // if (typeof e === "object" && "message" in e) {
      //   snackbar.error(e.message);
      // } else {
      //   snackbar.error("something went wrong");
      // }
      throw new Error("Something went wrong");
    }
  };
  const handleCheckHasEntityRated = async () => {
    if (hasProcessRated) return true;
    const appId: number = state.applicationData?.id || 0;
    try {
      const res = await hasEntityRated({ entity_type: "APPLICATION", entity_id: appId });
      return res.is_rated;
    } catch (err) {
      return false;
    }
  };
  const paymentPlanDetails =
    paymentMethodId === 1
      ? "Pay ₹ 5,555 per month for 36 months after the commencement of the program"
      : "Pay ₹ 3,000 per month for 24 months after the commencement of the program and INR 2,00,000 after the time of course completion.";
  return (
    <Box p={{ base: "ms-16", md: "ms-24" }}>
      <Banner
        type="success"
        textZIndex={1}
        text={
          "Your Payment is successful. Please complete the onboarding procedures and you are all set!"
        }
        title={"Woohoo!!🤩"}
        hideShareButton={true}
      />
      <RequestCallback
        source="ONWARD_ONBOARDING_PAGE"
        mobilePopupPorps={{
          bottom: { base: "60px", md: "24px" },
          right: { base: "12px", md: "24px" },
        }}
        styleProps={{ bottom: bottomSpace }}
      />
      <Heading as="h3" mt={"24px"}>
        Onboarding
      </Heading>
      <Text d={"inline"} mt={{ base: "ms-12", md: "ms-8" }} textStyle={"body1"}>
        {`Just few more steps and you're ready to start your classes from`}{" "}
        <Text d={"inline"} textStyle={"body1-md"}>
          {" "}
          {dayjs(start_date).format("DD MMM YYYY")}.
        </Text>
      </Text>
      <Box color={"#544D4F"} display={"flex"} alignItems={"center"} mt={"32px"}>
        <Box mr={"5px"}>
          <Image src={GreenCheckIcon} />
        </Box>
        <Box>
          <Heading as={"h5"} display={"inline"}>
            Step 1 :
          </Heading>
          <Text display={"inline"}> Your seat has been reserved successfully.</Text>
        </Box>
      </Box>
      <Box color={"#0A0103"} display={"flex"} alignItems={"center"} mt={"24px"}>
        {/* <Box mr={"5px"}>
            <Image src={GreenCheckIcon} />
          </Box> */}
        <Box>
          <Heading as={"h5"} display={"inline"}>
            Step 2 :{" "}
          </Heading>
          <Text display={"inline"}>
            Your 15 day free trial will begin from{" "}
            <Text d={"inline"}> {dayjs(start_date).format("DD MMM YYYY")}.</Text>
            Kindly join the links to access the trial.
          </Text>
        </Box>
      </Box>
      <Box mt={"ms-12"} display={"flex"}>
        {["zoom", "slack"].map((item, ind) => (
          <BoxForZoomAndSlackLink
            key={ind}
            item={item}
            zoom_link={zoom_link}
            slack_link={slack_link}
          />
        ))}
      </Box>
      <Box color={"#0A0103"} display={"flex"} mt={"32px"}>
        {isPaymentMethodSelected && (
          <Box mr={"5px"}>
            <Image src={GreenCheckIcon} />
          </Box>
        )}
        <Box>
          <Heading as={"h5"} display={"inline"}>
            Step 3 :
          </Heading>
          {!isPaymentMethodSelected ? (
            <Text display={"inline"}>
              Select any one of the payment methods by which you will be making your payments in the
              future.
            </Text>
          ) : paymentMethodId ? (
            <Text display={"inline"}>
              You have choosen {paymentMethodId === 1 ? "Plan A" : "Plan B"} ( {paymentPlanDetails}{" "}
              ) as your payment method.
            </Text>
          ) : (
            <Text display={"inline"}>
              Something went wrong while fetching your payment plan, do not worry please write a
              email to admissions@masaischool.com
            </Text>
          )}
        </Box>
      </Box>
      {!isPaymentMethodSelected && <PaymentMethods onSubmit={handleSubmit} isCapData={isCapData} />}
      <ModalTemplate
        title=""
        isOpen={isRatingModalOpen}
        onClose={async () => {
          try {
            await handleSubmitRating(-1, "");
            setIsRatingModalOpen(false);
          } catch (err) {
            console.log();
          }
        }}
        marginX={2}
        paddingX={"20px"}
        maxWidth="552px"
        showCloseButtonIcon={true}
        closeOnOverlayClick={true}
      >
        <FeebackForm onSubmit={handleSubmitRating} />
      </ModalTemplate>
    </Box>
  );
};

const BoxForZoomAndSlackLink = ({
  item,
  slack_link,
  zoom_link,
}: {
  item: string;
  slack_link: string | undefined;
  zoom_link: string | undefined;
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      key={item}
      mr={item === "zoom" ? { base: "ms-12", md: "ms-16" } : "0px"}
      borderRadius={"ms-16"}
      p={{ base: "ms-8", md: "ms-24" }}
      border="1px solid #D9D9D9"
      d={"flex"}
      flexDirection={"column"}
      justifyItems={"center"}
      minW={{ base: "166px", md: "264px" }}
      minH={"148px"}
      justifyContent={"center"}
    >
      <Box textAlign={"center"} mb={{ base: "28px", md: "20px" }}>
        {item === "zoom" ? <Zoom boxSize="40px" /> : <Slack boxSize="40px" />}
      </Box>
      <Box textAlign={"center"}>
        <Link isExternal={true} href={item === "slack" ? slack_link : zoom_link}>
          <Button
            onClick={() => {
              sendTrackingEvent({
                event: item === "slack" ? "sp_op_join_slack" : "sp_op_join_orientation",
                eventLabel: location.pathname,
              });
            }}
            size={isMobile ? "sm" : "md"}
            variant={"secondary"}
          >
            {item === "slack" ? "join slack" : `join orientation`}
          </Button>
        </Link>
      </Box>
    </Box>
  );
};
const PaymentMethods = ({
  onSubmit,
  isCapData,
}: {
  onSubmit: (value: string) => void;
  isCapData: boolean;
}) => {
  const [value, setValue] = React.useState("0");
  const handleSubmit = () => {
    onSubmit(value);
  };
  return (
    <Box border={"1px solid #D9D9D9"} borderRadius={"16px"} maxWidth={"676px"} mt={"16px"}>
      <RadioGroup onChange={setValue} value={value}>
        <Accordion>
          <AccordionItem borderTopRadius={"16px"}>
            <AccordionButton borderTopRadius={"16px"}>
              <Box
                py="10px"
                width={"full"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box display={"flex"} gap={"5"}>
                  <Radio value="1" />
                  <Box textAlign={"left"}>
                    <Heading as={"h5"}>{isCapData ? "Pay ₹ 43,000" : "Pay ₹ 50,000"}</Heading>
                    <Text textStyle="body1-md">
                      if you choose to pay{" "}
                      <Text as="span" color="#4358F6">
                        upfront
                      </Text>
                      .
                    </Text>
                  </Box>
                </Box>

                {/* <AccordionIcon /> */}
              </Box>
            </AccordionButton>

            {/* <AccordionPanel pb={6} pl={"12"}>
              <UnorderedList fontSize={"14px"} color={"#544D4F"} fontWeight={"600"}>
                <ListItem>
                  2 Lakh fee in 36 EMIs <Text color={"#6E71CC"}> (INR 5555 per month) </Text>
                </ListItem>
                <ListItem>100% refund if Job CTC &#60; 5 LPA</ListItem>
              </UnorderedList>
            </AccordionPanel> */}
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box
                py="10px"
                width={"full"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box display={"flex"} gap={"5"}>
                  <Radio value="2" />

                  <Box textAlign={"left"}>
                    <Heading as={"h5"}>
                      {isCapData ? "Pay ₹ 4,000 * 12 months" : "Pay ₹ 4,000 * 15 months"}
                    </Heading>
                    <Text textStyle="body1-md">
                      if you choose to pay in{" "}
                      <Text as="span" color="#4358F6">
                        EMI
                      </Text>
                      .
                    </Text>
                  </Box>
                </Box>
                {/* <AccordionIcon /> */}
              </Box>
            </AccordionButton>
            {/* <AccordionPanel pb={6} pl={"12"}>
              <UnorderedList fontSize={"14px"} color={"#544D4F"} fontWeight={"600"}>
                <ListItem>INR 3000 for 24 months</ListItem>
                <ListItem>INR 2 Lakh after getting placed</ListItem>
                <ListItem>100% refund if Job CTC &#60; 5 LPA</ListItem>
              </UnorderedList>
            </AccordionPanel> */}
          </AccordionItem>
        </Accordion>
      </RadioGroup>
      <Box
        textAlign={"center"}
        bg={"#FFF9E5"}
        p={"4px 24px"}
        m={"8px 12px"}
        borderRadius={"8px"}
        color={"#544D4F"}
        fontWeight={"600"}
        fontSize={"16px"}
      >
        Not sure which plan to choose? No worries you can change the payment plan later on once the
        course starts.
      </Box>
      <Box m={"auto"} width={"fit-content"} my={"16px"} onClick={handleSubmit}>
        <Button variant={"primary"} disabled={value === "0"}>
          SUBMIT
        </Button>
      </Box>
    </Box>
  );
};

export default CapPaymentSuccess;
