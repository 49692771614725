import {
  // AccordionPanel,
  Box,
  Button,
  Heading,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

import dayjs from "dayjs";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Slack, Zoom } from "../../../../assets/icons/components";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import { AppContext } from "../../../../context/ApplicationContext";

const ScholarPaymentSuccess = () => {
  const state = useContext(AppContext)[0];
  const start_date = state.applicationData?.batch_campus.batch.start_date;
  const isMobile = useBreakpointValue({ base: true, md: false });
  const location = useLocation();
  const batch = state.applicationData?.batch_campus.batch;
  const zoom_link = batch?.zoom_webinar_link;
  const slack_link = state.applicationData?.batch_campus?.slack_link;
  const course = state.applicationData?.batch_campus.batch.course;
  return (
    <Box p={{ base: "ms-16", md: "ms-24" }}>
      <Heading as="h3">Onboarding</Heading>
      {course?.name !== "Programming: Advanced" ? (
        <Text d={"inline"} mt={{ base: "ms-12", md: "ms-8" }} textStyle={"body1"}>
          Your payment is successful. Just a few more steps and you are all set to start classes
          from{" "}
          <Text d={"inline"} textStyle={"body1-md"}>
            {" "}
            {dayjs(start_date).format("DD MMM YYYY")}.
          </Text>
        </Text>
      ) : (
        <Text d={"inline"} mt={{ base: "ms-12", md: "ms-8" }} textStyle={"body1"}>
          Your payment is successful. Just a few more steps and you are all set to start classes.
          Course start date will be announced soon.
        </Text>
      )}

      <Box mt={"ms-32"}>
        <Heading as="h5">Joining the below links is mandatory.</Heading>
        <Box mt={"ms-12"} display={"flex"}>
          {["zoom", "slack"].map((item) => (
            <Box
              key={item}
              mr={item === "zoom" ? { base: "ms-12", md: "ms-16" } : "0px"}
              borderRadius={"ms-16"}
              p={{ base: "ms-8", md: "ms-24" }}
              border="1px solid #D9D9D9"
              d={"flex"}
              flexDirection={"column"}
              justifyItems={"center"}
              minW={{ base: "166px", md: "264px" }}
              minH={"148px"}
              justifyContent={"center"}
            >
              <Box textAlign={"center"} mb={{ base: "28px", md: "20px" }}>
                {item === "zoom" ? <Zoom boxSize="40px" /> : <Slack boxSize="40px" />}
              </Box>
              <Box textAlign={"center"}>
                <Link isExternal={true} href={item === "slack" ? slack_link : zoom_link}>
                  <Button
                    onClick={() => {
                      sendTrackingEvent({
                        event: item === "slack" ? "sp_op_join_slack" : "sp_op_join_orientation",
                        eventLabel: location.pathname,
                      });
                    }}
                    size={isMobile ? "sm" : "md"}
                    variant={"secondary"}
                  >
                    {item === "slack" ? "join slack" : `join orientation`}
                  </Button>
                </Link>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box mt={"ms-24"}>
        <Heading as="h5">Facing any issues?</Heading>
        <Text mt={"ms-12"} textStyle={{ base: "body2", md: "body1" }}>
          <Text
            display={"inline"}
            textStyle={{ base: "body2-md", md: "body1-md" }}
            color={"ms-blue.500"}
            cursor={"pointer"}
          >
            {" "}
            <Link
              isExternal={true}
              href="https://us06web.zoom.us/webinar/register/WN_EcaTm9LjRbWWNXcw9jPY0w"
              onClick={() => {
                sendTrackingEvent({
                  event: "sp_op_join_q&a_session",
                  eventLabel: location.pathname,
                });
              }}
            >
              Join our Q&A session{" "}
            </Link>
          </Text>
          Every Friday, 6-7 PM.
        </Text>
      </Box>
    </Box>
  );
};
export default ScholarPaymentSuccess;
