import { Box, Image, Heading, Text, Button } from "@chakra-ui/react";
import React from "react";
import CalendlyLogo from "../../../../assets/images/CoursePrograms/CalendlyLogo.svg";
interface Props {
  handleCalendlyBooking: () => void;
}
const CalendlyConfirmModalContent = ({ handleCalendlyBooking }: Props) => {
  return (
    <Box>
      <Image src={CalendlyLogo} />
      <Heading mt={"ms-24"} as="h4">
        You’re being redirected to Calendly
      </Heading>
      <Text mt={"ms-8"} textStyle={"body1"}>
        Book a slot for the interview by clicking on the button below.
      </Text>
      <Box mt={"ms-24"} display={"flex"}>
        <Button onClick={handleCalendlyBooking} ml={"auto"} variant="primary">
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default CalendlyConfirmModalContent;
