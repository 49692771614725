import { Box, Slide, SquareProps, Text, useDisclosure, useOutsideClick } from "@chakra-ui/react";
import { SmallCloseIcon } from "@chakra-ui/icons";
import React, { useState, useEffect, useContext } from "react";
import MobileRequestCallBtn from "./MobileRequestCallBtn";
// import DesktopRequestCallBtn from "./DesktopRequestCallBtn";
import { MotionStyle } from "framer-motion";
import { requestCallback } from "../../../api/utils/api";
import { AppContext } from "../../../context/ApplicationContext";
import { useDebouncedCallback } from "use-debounce/lib";
import useSnackBar from "../../common/general/SnackBar";
import { axiosErrorHandler } from "../../../api/utils/error";
import { useLocation } from "react-router-dom";
import { sendTrackingEvent } from "../../common/utils/gtm";

type Props = {
  mobilePopupPorps: SquareProps;
  formSliderPorps?: string;
  styleProps?: MotionStyle;
  source?: "ONWARD_PAYMENT_DETAILS_PAGE" | "ONWARD_ONBOARDING_PAGE" | "PREPLEAF";
};

const RequestCallback = ({ mobilePopupPorps, formSliderPorps, styleProps, source }: Props) => {
  const ref = React.useRef(null);
  const snackbar = useSnackBar();
  const state = useContext(AppContext)[0];
  const location = useLocation();

  const [isThere, setIsThere] = useState(false);

  const [formPage, setFormPage] = useState(1);

  const {
    isOpen: isSlideOpen,
    onToggle: onToggleIsSlideOpen,
    onClose: onCloseIsSlideOpen,
  } = useDisclosure();

  const { isOpen: isVisible, onToggle: onToggleIsVisible } = useDisclosure();

  useOutsideClick({
    ref: ref,
    handler: () => onCloseIsSlideOpen(),
  });

  useEffect(() => {
    setIsThere(true);
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (formPage === 2) {
      timer = setTimeout(() => {
        if (formPage === 2) {
          onCloseIsSlideOpen();
          setFormPage(1);
        }
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [formPage]);

  const handleSubmit = useDebouncedCallback(
    async (
      name: string,
      phone_number: string,
      source: "ONWARD_PAYMENT_DETAILS_PAGE" | "ONWARD_ONBOARDING_PAGE" | "PREPLEAF"
    ) => {
      requestCallback({
        name,
        phone_number,
        source,
      })
        .then((res) => {
          sendTrackingEvent({
            event: "dash_request_callback_btn",
            eventLabel: location.pathname,
            requestCallBackId: res?.id?.toString() || "",
          });
          setFormPage(2);
          onToggleIsSlideOpen();
        })
        .catch((err) => {
          const e = axiosErrorHandler(err);
          if (typeof e === "object" && "message" in e) {
            snackbar.error(e.message);
          } else {
            snackbar.error("something went wrong");
          }
        });
    },
    200
  );

  return isThere ? (
    <Box display={!isVisible ? "block" : "none"}>
      {/* Mobile Request call floating button */}
      {!isSlideOpen && (
        <MobileRequestCallBtn
          handleprop={mobilePopupPorps}
          handleclick={() => {
            if (source) {
              handleSubmit(
                state.profileData?.name || "",
                state.profileData?.phone_number || "",
                source
              );
            }
          }}
        />
      )}

      <Slide
        direction="bottom"
        in={isSlideOpen}
        className={isSlideOpen ? formSliderPorps : "bottom-[0px]"}
        style={
          isSlideOpen
            ? {
                margin: "auto",
                left: "auto",
                right: "0px",
                zIndex: 10,
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                ...styleProps,
              }
            : {
                margin: "auto",
                left: "auto",
                right: "0px",
                zIndex: 10,
                bottom: "0px",
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }
        }
      >
        <Box
          p={{ base: "32px 16px", md: "24px 32px" }}
          justifyContent="flex-end"
          overflow="hidden"
          display="flex"
          w="100%"
        >
          <Box
            ref={ref}
            bg="#FFFFFF"
            w={{ base: "85%", md: "100%" }}
            maxW={{ base: "100%", md: "327px" }}
            borderRadius="16px"
            overflow="hidden"
            boxShadow="0px -2px 11px -2px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
          >
            {/* After form submit */}
            <Box
              p="16px"
              h="320px"
              display={formPage === 2 ? "flex" : "none"}
              flexDir="column"
              textAlign="center"
              position="relative"
              alignItems="center"
              justifyContent="center"
            >
              <Box cursor="pointer" w="fit-content" position="absolute" right="8px" top="8px">
                <SmallCloseIcon
                  onClick={() => {
                    onToggleIsSlideOpen();
                    setFormPage(1);
                  }}
                  w="24px"
                  h="24px"
                  color="#6C6768"
                />
              </Box>

              <svg
                width="57"
                height="56"
                viewBox="0 0 57 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28.5 4.66669C15.6667 4.66669 5.16667 15.1667 5.16667 28C5.16667 40.8334 15.6667 51.3334 28.5 51.3334C41.3333 51.3334 51.8333 40.8334 51.8333 28C51.8333 15.1667 41.3333 4.66669 28.5 4.66669ZM28.5 46.6667C18.21 46.6667 9.83334 38.29 9.83334 28C9.83334 17.71 18.21 9.33335 28.5 9.33335C38.79 9.33335 47.1667 17.71 47.1667 28C47.1667 38.29 38.79 46.6667 28.5 46.6667ZM39.21 17.6867L23.8333 33.0634L17.79 27.0434L14.5 30.3334L23.8333 39.6667L42.5 21L39.21 17.6867Z"
                  fill="#6FCD9E"
                />
              </svg>

              <Text
                fontSize="16px"
                color="#21191B"
                fontWeight="600"
                fontFamily="Open Sans"
                mt="24px"
              >
                We have received your details, you will be receiving a call from our team shortly.
              </Text>
            </Box>
          </Box>
        </Box>
      </Slide>
    </Box>
  ) : null;
};

export default RequestCallback;
