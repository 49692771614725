import { Skeleton } from "@chakra-ui/react";
import loadable from "@loadable/component";
import PrivateRoute from "../components/common/auth/PrivateRoute";
import Layout from "../components/common/layouts/Layout";
import useApplicationHook from "../hooks/useApplicationHook";
import ApplicationClosed from "../pages/Application/msat/ApplicationClosed";
import ScholarPaymentFailed from "../pages/Courses/NewCoursesFlow/ScholarProgram/ScholarPaymentFailed";

import CodingFailed from "../pages/Courses/NewCoursesFlow/CodingFailed";
import CodingInstructions from "../pages/Courses/NewCoursesFlow/CodingInstructions";
import CodingPending from "../pages/Courses/NewCoursesFlow/CodingPending";
import CodingSuccess from "../pages/Courses/NewCoursesFlow/CodingSuccess";
import MasaiXInterviewFailed from "../pages/Courses/NewCoursesFlow/MasaiXProgram/MasaiXInterviewFailed";
import MasaiInterviewPending from "../pages/Courses/NewCoursesFlow/MasaiXProgram/MasaiXInterviewPending";
import MasaiXInterviewSuccess from "../pages/Courses/NewCoursesFlow/MasaiXProgram/MasaiXInterviewSuccess";
import ScholarMSFTSuccess from "../pages/Courses/NewCoursesFlow/ScholarProgram/ScholarMSFTCodingSuccess";
import ScholarMSFTFailed from "../pages/Courses/NewCoursesFlow/ScholarProgram/ScholarMSFTFailed";
import ScholarMSFTPending from "../pages/Courses/NewCoursesFlow/ScholarProgram/ScholarMSFTPending";
import PaymentSuccess from "../pages/Courses/NewCoursesFlow/PaymentSuccess";
import PaymentPending from "../pages/Courses/NewCoursesFlow/PaymentPending";
import Onboarding from "../pages/Courses/Onboarding";

function Loading() {
  return (
    <>
      <Skeleton h="15rem" m="2rem" isLoaded={false} />
      <Skeleton h="15rem" m="2rem" isLoaded={false} />
      <Skeleton h="15rem" m="2rem" isLoaded={false} />
    </>
  );
}

const OnboardingPending = loadable(() => import("../pages/Application/onboarding/OnboardingPending"), {
  fallback: <Loading />,
});

const ApplicationDetails = loadable(() => import("../pages/Application/ApplicationDetails"), {
  fallback: <Loading />,
});

const MSATInstructions = loadable(() => import("../pages/Application/msat/MSATInstructions"), {
  fallback: <Loading />,
});

const MSATDecisionPending = loadable(() => import("../pages/Application/msat/MSATDecisionPending"), {
  fallback: <Loading />,
});

const MSATSuccess = loadable(() => import("../pages/Application/msat/MSATSuccess"), {
  fallback: <Loading />,
});

const MSATFail = loadable(() => import("../pages/Application/msat/MSATFail"), {
  fallback: <Loading />,
});

const ApplicationRejectionPreMSAT = loadable(() => import("../pages/ApplicationRejectionPreMSAT"), {
  fallback: <Loading />,
});

const CapPricingAndPaymentDetails = loadable(() => import("../pages/Courses/NewCoursesFlow/CapProgram/CapPricingAndPaymentDetails"), {
  fallback: <Loading />,
});

type RouteProps = {
  path: string;
  name: string;
  isPrivate: boolean;
  component: JSX.Element;
  hidden: boolean;
  useLayout: boolean;
  exact?: boolean;
};

const routes: RouteProps[] = [
  {
    path: "/batch/:batchID/:applicantID/applicant-details",
    name: "Applicant Form",
    exact: true,
    isPrivate: true,
    component: <ApplicationDetails />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/onboarding-pending",
    name: "Onboarding Pending",
    exact: true,
    isPrivate: true,
    component: <OnboardingPending />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/msat-instructions",
    name: "MSAT Instructions",
    exact: true,
    isPrivate: false,
    component: <MSATInstructions />,
    hidden: false,
    useLayout: true,
  },
  {
    // can do (ineligible | msat | documentation)
    path: "/batch/:batchID/:applicantID/reject/ineligible",
    name: "Applicant Rejected Ineligible",
    exact: true,
    isPrivate: true,
    component: <ApplicationRejectionPreMSAT />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/reject/documentation",
    name: "Applicant Rejected Documentation",
    exact: true,
    isPrivate: true,
    component: <>Applicant Rejected Documentation</>,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/status",
    name: "Application Status",
    exact: true,
    isPrivate: true,
    component: <>Application Status</>,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/verify",
    name: "Applicant Verification",
    exact: true,
    isPrivate: true,
    component: <>Applicant Verification</>,
    hidden: false,
    useLayout: true,
  },

  {
    path: "/batch/:batchID/:applicantID/msat-pending",
    name: "Applicant cleared MSAT but Communication Evaluation Left",
    exact: true,
    isPrivate: true,
    component: <MSATDecisionPending />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/msat-success",
    name: "Applicant cleared MSAT and Communication Evaluation",
    exact: true,
    isPrivate: true,
    component: <MSATSuccess />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/reject/msat",
    name: "Applicant not cleared MSAT and Communication Evaluation",
    exact: true,
    isPrivate: true,
    component: <MSATFail />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/onboarding",
    name: "Applicant Onboarding - 1",
    exact: true,
    isPrivate: true,
    component: <Onboarding />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/application-closed",
    name: "Application Closed",
    exact: true,
    isPrivate: true,
    component: <ApplicationClosed />,
    hidden: false,
    useLayout: true,
  },

  // New courses applications routes
  {
    path: "/batch/:batchID/:applicantID/instructions",
    name: "Scholar Instructions",
    exact: true,
    isPrivate: true,
    component: <PaymentPending />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/payment-success",
    name: "Scholar Payement Success",
    exact: true,
    isPrivate: true,
    component: <PaymentSuccess />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/scholar-payment-failed",
    name: "Scholar Payement Failed",
    exact: true,
    isPrivate: true,
    component: <ScholarPaymentFailed />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/scholar-msft-instructions",
    name: "Scholar MSFT Instructions",
    exact: true,
    isPrivate: false,
    component: <MSATInstructions isScholarhipTest={true} showNewMSAT={true} />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/scholar-msft-pending",
    name: "Applicant cleared MSFT but Score Calculation Left",
    exact: true,
    isPrivate: true,
    component: <ScholarMSFTPending />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/scholar-msft-reject",
    name: "Applicant not cleared MSFT",
    exact: true,
    isPrivate: true,
    component: <ScholarMSFTFailed />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/scholar-msft-success",
    name: "Applicant cleared MSFT",
    exact: true,
    isPrivate: true,
    component: <ScholarMSFTSuccess testType="msft" />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/coding-instructions",
    name: "Applicant will appear for Coding Test",
    exact: true,
    isPrivate: true,
    component: <CodingInstructions />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/coding-pending",
    name: "Applicant will appear for Coding Test",
    exact: true,
    isPrivate: true,
    component: <CodingPending />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/coding-success",
    name: "Applicant will appear for Coding Test",
    exact: true,
    isPrivate: true,
    component: <CodingSuccess />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/coding-reject",
    name: "Applicant will appear for Coding Test",
    exact: true,
    isPrivate: true,
    component: <CodingFailed />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/masaix-interview-pending",
    name: "Applicant will appear for Technical Interview",
    exact: true,
    isPrivate: true,
    component: <MasaiInterviewPending currentStage="INTERVIEW" />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/masaix-interview-booked",
    name: "Applicant has booked interview successfully",
    exact: true,
    isPrivate: true,
    component: <MasaiInterviewPending currentStage="INTERVIEW" bookingStatus={true} />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/masaix-interview-success",
    name: "Applicant has cleared interview successfully",
    exact: true,
    isPrivate: true,
    component: <MasaiXInterviewSuccess currentStage="AADHAAR_VERIFICATION" />,
    hidden: false,
    useLayout: true,
  },
  {
    path: "/batch/:batchID/:applicantID/masaix-interview-reject",
    name: "Applicant has cleared interview successfully",
    exact: true,
    isPrivate: true,
    component: <MasaiXInterviewFailed currentStage="AADHAAR_VERIFICATION" />,
    hidden: false,
    useLayout: true,
  },

  // CAP flow
  {
    path: "/batch/:batchID/:applicantID/application-created",
    name: "Cap Instructions",
    exact: true,
    isPrivate: true,
    component: <CapPricingAndPaymentDetails />,
    hidden: false,
    useLayout: true,
  },
];

const RouteWrapper = ({ children }: { children: JSX.Element }) => {
  const [{ loading }] = useApplicationHook();
  return <>{loading ? <Skeleton isLoaded={false} h="full" /> : children}</>;
};

const ApplicationRoutes: React.FC = () => {
  return (
    <>
      {routes.map((route) => (
        <PrivateRoute key={route.path} path={route.path}>
          <Layout>
            <RouteWrapper>{route.component}</RouteWrapper>
          </Layout>
        </PrivateRoute>
      ))}
    </>
  );
};

export default ApplicationRoutes;
