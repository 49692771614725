import { Box } from "@chakra-ui/react";
import { Banner } from "../../../pages/Courses/Banner";
import { ProfileAssessment } from "../../../api/schemas/schema";

interface Props {
  bestAssessment?: ProfileAssessment;
  isLoading: boolean;
  isMasaiCoursePrefered?: boolean;
}

const MSATResultBanner = ({ bestAssessment, isLoading, isMasaiCoursePrefered }: Props) => {
  return (
    <Box>
      {!isLoading && (
        <Box>
          {(bestAssessment?.eligible_courses?.length && !isMasaiCoursePrefered) || (isMasaiCoursePrefered && bestAssessment?.eligible_courses?.includes(14)) ? (
            <Banner border="none" borderRadius="8px 8px 0px 0px" hideShareButton={true} text={<>Congratulations! You have cleared the MSAT!</>} type="success" title={"MSAT Result"} />
          ) : (
            <Banner
              border="none"
              borderRadius="8px 8px 0px 0px"
              hideShareButton={true}
              text={<>{bestAssessment?.attempt_count === 1 ? `You could not clear the 1st attempt of ${"MSAT"}.` : `You could not clear the 2nd attempt of ${"MSAT"}.!`}</>}
              type="error"
              title={"MSAT Not Clear"}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default MSATResultBanner;
