import React from "react";
import { Box, Image, Heading, ModalCloseButton } from "@chakra-ui/react";
import SocialMedia from "./SocialMedia";
const CopyCodeModalContent = ({
  setShowCopyCodeModal,
  copiedContent,
}: {
  setShowCopyCodeModal: React.Dispatch<React.SetStateAction<boolean>>;
  copiedContent?: string;
}) => {
  return (
    <Box py="24px">
      <ModalCloseButton
        color="ms-grey.700"
        right="ms-24"
        onClick={() => {
          setShowCopyCodeModal(false);
        }}
        boxSize="24px"
        _focus={{
          outline: "none",
        }}
      />
      <Image pointerEvents={"none"} mx="auto" src="/img/referral/referral-modal-icon.svg" />
      <Box mt="16px ">
        <Heading textAlign={"center"} mx="auto" as="h4">
          {copiedContent} Copied Successfully!
        </Heading>
        <SocialMedia
          gtm_events={{
            whatsapp: "d_invite_wa",
            telegram: "d_invite_tg",
            facebook: "d_invite_fb",
            linkedin: "d_invite_li",
          }}
        />
      </Box>
    </Box>
  );
};

export default CopyCodeModalContent;
