import { Box, Image, Text, Heading, ListItem, UnorderedList, Button } from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import { InformationOutline } from "../../../../../assets/icons/components";
import logo from "../../../../../assets/masailogo.svg";
import { sendTrackingEvent } from "../../../../../components/common/utils/gtm";
interface Props {
  setViewDetailsModal: (value: boolean) => void;
  handleConfirm: () => void;
}
const ViewScholarShipDetailsModal = ({
  handleConfirm,

  setViewDetailsModal,
}: Props) => {
  const location = useLocation();
  return (
    <Box>
      <Image mb={"ms-8"} src={logo} alt="mettl" />
      <Heading as="h4">Scholarship Test Information</Heading>
      <UnorderedList mt="ms-16" pl={{ md: "ms-8" }}>
        <ListItem color={"ms-grey.700"} mb="ms-4">
          <Text textStyle={{ base: "body2", md: "body1" }}>
            Scholarship is a course price waiver program awarded on the basis of performance in the
            Prepleaf Scholarship Test.
          </Text>
        </ListItem>
        <ListItem color={"ms-grey.700"} mb="ms-4">
          <Text textStyle={{ base: "body2", md: "body1" }}>
            You will have one attempt at the Scholarship test.
          </Text>
        </ListItem>
        <ListItem color={"ms-grey.700"} mb="ms-4">
          <Text textStyle={{ base: "body2", md: "body1" }}>
            Based on your performance you might receive a flat exemption on the prices of the
            Scholar Program Course that you wish to apply for.
          </Text>
        </ListItem>
        <ListItem color={"ms-grey.700"} mb="ms-4">
          <Text textStyle={{ base: "body2", md: "body1" }}>
            Any kind of plagiarism or malpractice is strictly prohibited and users indulging in the
            same would be barred from taking admission into any of the Masai courses in the future.
          </Text>
        </ListItem>
        <ListItem color={"ms-grey.700"} mb="ms-4">
          <Text textStyle={{ base: "body2", md: "body1" }}>
            Prepleaf Scholarship isn’t a mandatory requirement for admission into Scholar Program
            Courses, it can be taken on the basis of the students’ interest.
          </Text>
        </ListItem>
      </UnorderedList>
      <Box mt={{ base: "ms-16", md: "ms-32" }} display={"flex"}>
        <Box>
          <InformationOutline boxSize="20px" color={"ms-alert"} />{" "}
        </Box>
        <Text ml={"6px"} textStyle={{ base: "body2", md: "body1" }} display={"inline"}>
          Avail upto{" "}
          <Text
            display={"inline"}
            textStyle={{ base: "body2-md", md: "body1-md" }}
            color={"ms-blue.500"}
          >
            75% scholarship
          </Text>
          <Text textStyle={{ base: "body2", md: "body1" }} display={"inline"}>
            {" "}
            on the introductory price based on your performance in the test
          </Text>
        </Text>
      </Box>
      <Box mt={"ms-32"} d={"flex"} justifyContent={"right"}>
        <Button
          mr={"ms-8"}
          variant={"secondary"}
          onClick={() => {
            sendTrackingEvent({
              event: "sp_pp_view_details_db_cancel",
              eventLabel: location.pathname,
            });
            setViewDetailsModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant={"primary"}
          onClick={() => {
            sendTrackingEvent({
              event: "sp_pp_view_details_db_apply",
              eventLabel: location.pathname,
            });
            setViewDetailsModal(false);
            handleConfirm();
          }}
        >
          Apply Now
        </Button>
      </Box>
    </Box>
  );
};

export default ViewScholarShipDetailsModal;
