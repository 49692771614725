import { Box } from "@chakra-ui/react";
import React, { useContext } from "react";
import { Constants } from "../../../components/common/constants";
import { isCourseProgramsIntersectsProfilePrograms } from "../../../components/common/utils/isProgramPresent";
import { AppContext } from "../../../context/ApplicationContext";
import MasaiXCodingSuccess from "./MasaiXProgram/MasaiXCodingSuccess";
import ScholarMSFTCodingSuccess from "./ScholarProgram/ScholarMSFTCodingSuccess";

const CodingSuccess = () => {
  const state = useContext(AppContext)[0];

  return (
    <Box mb={`${Constants.bottomNavbarHeight}`}>
      {isCourseProgramsIntersectsProfilePrograms(
        state.applicationData?.batch_campus.batch?.course?.course_programs,
        [
          {
            id: 1,
            name: "Scholar",
          },
        ]
      ) ? (
        <ScholarMSFTCodingSuccess testType="coding" />
      ) : isCourseProgramsIntersectsProfilePrograms(
          state.applicationData?.batch_campus.batch?.course?.course_programs,
          [
            {
              id: 3,
              name: "MasaiX",
            },
          ]
        ) ? (
        <MasaiXCodingSuccess currentStage={"CODING"} />
      ) : null}
    </Box>
  );
};

export default CodingSuccess;
