import { useState } from "react";
import { Box, Button, Input, Skeleton, Text } from "@chakra-ui/react";
import UpdateUserDetails from "./UpdateDetails";
import { useLocation } from "react-router-dom";
import UpdateUseBatch from "./UpdateBatch";

const GetUserToUpdate = () => {
  const [email, setEmail] = useState("");
  const [profileToBeFetched, setProfileToBeFetched] = useState("");
  // check the current route and render the respective component
  const location = useLocation();

  const isKYCRoute = location.pathname.includes("update-kyc-details");
  const isBatchRoute = location.pathname.includes("update-user-batch");
  return (
    <Skeleton p={{ base: "16px", md: "24px" }} h={"100vh"} isLoaded={true}>
      <Text as="h2" textStyle="h2">
        {isKYCRoute ? "Update Users KYC Details" : "Update Users Batch"}
      </Text>

      <Box display={"flex"} gap="10px">
        <Box>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Enter user email below to fetch the profile
          </label>
          <Input name="email" type="email" placeholder="Enter user email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Box>
        <Button
          mt="30px"
          disabled={!email}
          size="md"
          textStyle="btn-md"
          variant="primary"
          onClick={() => {
            setProfileToBeFetched(email);
          }}
        >
          Get Profile
        </Button>
      </Box>

      {isKYCRoute && profileToBeFetched && <UpdateUserDetails email={email} />}
      {isBatchRoute && profileToBeFetched && <UpdateUseBatch email={email} />}
    </Skeleton>
  );
};

export default GetUserToUpdate;
