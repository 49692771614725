import { Box, Button, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useSnackBar from "../common/general/SnackBar";
import { Dropdown, InputCustom } from "../common/general";
import SelectCity from "../../pages/Application/CityDropDown";
import { createCity } from "../../api/utils/api";
import { useProfile } from "../../api/utils/hooks/useProfile";
import { axiosErrorHandler } from "../../api/utils/error";
import { FileUploader } from "react-drag-drop-files";
import "../../pages/Application/styles.css";
import { useMutation } from "react-query";
import { getProfileImagePresignedUrl } from "../../api/utils/api/v2/profile";
import { GetS3PresignedUrlToUploadDPType } from "../../api/schemas/schema";
import axios from "axios";
import { AddressType } from "../../types/onboarding.types";

interface Props {
  loading: boolean;
  addressData: AddressType;
  onSubmit: (data: AddressType) => void;
  setAddressData: React.Dispatch<React.SetStateAction<AddressType>>;
  setOpenAddAddressModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const IITCourseAddressForm = ({ loading, addressData, setAddressData, setOpenAddAddressModal, onSubmit }: Props) => {
  const { data: profile } = useProfile();
  const [profilImage, setProfileImage] = useState<File>();
  const [address, setAddress] = useState<AddressType>({
    apartment: "",
    street: "",
    landmark: "",
    city: "",
    state: "",
    pincode: 0,
    gender: "",
    tshirtSize: "",
    studentName: "",
    passportSizePic: "",
  });
  const snackbar = useSnackBar();

  const fileTypes = ["JPEG", "PNG", "JPG"];
  const [errorMessage, setErrorMessage] = useState("");
  const onTypeError = () => {
    setErrorMessage("Please upload only image files of type JPEG, PNG or JPG");
  };
  const onSizeError = () => {
    setErrorMessage("Image size should be less than equal to 5MB");
  };

  const [error, setError] = useState({
    apartment: false,
    street: false,
    landmark: false,
    city: false,
    pincode: false,
    state: false,
    studentName: false,
    passportSizePic: false,
    gender: false,
    tshirtSize: false,
  });

  const handleSubmit = () => {
    setError({
      apartment: address?.apartment ? false : true,
      street: address?.street ? false : true,
      landmark: address?.landmark ? false : true,
      city: address?.city ? false : true,
      pincode: address?.pincode ? false : true,
      state: address?.state ? false : true,
      studentName: address?.studentName ? false : true,
      gender: address?.gender ? false : true,
      passportSizePic: address?.passportSizePic ? false : true,
      tshirtSize: address?.tshirtSize ? false : true,
    });

    let isValid = false;
    if (address?.apartment && address?.street && address?.city && address?.pincode && address?.state && address?.passportSizePic && address?.tshirtSize && address?.gender && address?.studentName) {
      isValid = true;
    }
    if (isValid && address) {
      // do api call
      setAddressData(address);
      setOpenAddAddressModal(false);
      onSubmit && onSubmit(address);
    }
  };

  const { mutate: presignedUrlMutate, isLoading: setPresignedUrlLoading } = useMutation(getProfileImagePresignedUrl, {
    onSuccess: async (data: GetS3PresignedUrlToUploadDPType) => {
      const imageData = profilImage;
      const presigned_url = data.presigned_url;
      try {
        await axios.put(presigned_url, imageData, {
          headers: {
            "Content-Type": "image/png",
          },
        });
        const imageUrl = presigned_url.split("?")[0];
        if (!imageUrl) {
          setError((prev) => {
            return { ...prev, passportSizePic: false };
          });
          setAddress((prev) => ({ ...prev, passportSizePic: "" }));
        } else {
          setError((prev) => {
            return { ...prev, passportSizePic: false };
          });
          setAddress((prev) => ({ ...prev, passportSizePic: imageUrl }));
        }
      } catch (error) {
        setError((prev) => {
          return { ...prev, passportSizePic: false };
        });
        setAddress((prev) => ({ ...prev, passportSizePic: "" }));
        console.error("Error uploading image:", error);
      }
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  useEffect(() => {
    setAddress(addressData);
  }, [addressData]);

  useEffect(() => {
    if (profilImage) presignedUrlMutate();
  }, [profilImage]);

  return (
    <Box
      maxH={{ base: "80vh", md: "67vh" }}
      py={2}
      overflow={"auto"}
      css={{
        "&::-webkit-scrollbar": {
          width: "0px",
          display: "none",
        },
        "&::-webkit-scrollbar-track": {
          width: "0px",
          display: "none",
        },
        "&::-webkit-scrollbar-thumb": {
          width: "0px",
          display: "none",
        },
      }}
    >
      <Box mb="16px" d="flex" alignItems={"center"} gap="8px">
        <Text textStyle="h5" as="h5">
          Please share your delivery address for your Welcome Kit
        </Text>
      </Box>
      <Box d="grid" gap="16px">
        <Box>
          <InputCustom
            required
            type="text"
            getValueCallback={(value) => {
              if (!value) {
                setError((prev) => {
                  return { ...prev, apartment: true };
                });
                setAddress((prev) => ({ ...prev, apartment: "" }));
              } else {
                setError((prev) => {
                  return { ...prev, apartment: false };
                });
                setAddress((prev) => ({ ...prev, apartment: value.trim() }));
              }
            }}
            isInvalid={error.apartment}
            errorMessage={"Please enter valid address"}
            placeholder="Enter Your Flat, House no., Building, Apartment"
            onClick={() => {
              //
            }}
            preFilledValue={address?.apartment}
            customBackgroundColor="#fff"
            label={"Flat, House no., Building, Apartment *"}
          />
        </Box>
        <Box>
          <InputCustom
            required
            type="text"
            getValueCallback={(value) => {
              if (!value) {
                setError((prev) => {
                  return { ...prev, street: false };
                });
                setAddress((prev) => ({ ...prev, street: "" }));
              } else {
                setError((prev) => {
                  return { ...prev, street: false };
                });
                setAddress((prev) => ({ ...prev, street: value.trim() }));
              }
            }}
            isInvalid={error.street}
            errorMessage={"Please enter valid address"}
            placeholder="Enter Full Address "
            onClick={() => {
              //
              console.log("street");
            }}
            preFilledValue={address?.street}
            customBackgroundColor="#fff"
            label={"Area, Street, Sector, Village*"}
          />
        </Box>

        <Box>
          <InputCustom
            required
            type="text"
            getValueCallback={(value) => {
              if (!value) {
                setError((prev) => {
                  return { ...prev, landmark: false };
                });
                setAddress((prev) => ({ ...prev, landmark: "" }));
              } else {
                setError((prev) => {
                  return { ...prev, landmark: false };
                });
                setAddress((prev) => ({ ...prev, landmark: value.trim() }));
              }
            }}
            isInvalid={error.landmark}
            preFilledValue={address?.landmark}
            errorMessage={"Please enter valid address"}
            placeholder="Enter Your Nearest Landmark "
            onClick={() => {
              //
            }}
            customBackgroundColor="#fff"
            label={"Landmark"}
          />
        </Box>

        <Box>
          <InputCustom
            required
            type="text"
            getValueCallback={(value) => {
              if (!value || !Number(value) || value.length > 6 || value.length < 6) {
                setError((prev) => {
                  return { ...prev, pincode: true };
                });
                setAddress((prev) => ({ ...prev, pincode: 0 }));
              } else {
                setError((prev) => {
                  return { ...prev, pincode: false };
                });
                setAddress((prev) => ({ ...prev, pincode: +value }));
              }
            }}
            isInvalid={error.pincode}
            preFilledValue={String(address?.pincode || 0)}
            errorMessage={"Please enter valid Pincode"}
            placeholder="Enter Pincode"
            onClick={() => {
              //
            }}
            customBackgroundColor="#fff"
            label={"Pincode *"}
          />
        </Box>

        <Box>
          <SelectCity
            label={<>Town/City *</>}
            inputSize="lg"
            placeholder="Search city name..."
            isClearable={false}
            value={address?.city ? { id: +(address?.cityId || 0), name: address.city } : undefined}
            onChange={(value) => {
              if (value?.id === null && !Number(value?.name)) {
                // create new college
                createCity({
                  name: value.name,
                  created_by: profile?.slug || "",
                })
                  .then((res) => {
                    // console.log("Value: ", value);
                    setAddress((prev) => ({ ...prev, city: res?.name, cityId: res.id }));
                    snackbar.success("City addedd successfully.");
                  })
                  .catch((err) => {
                    const e = axiosErrorHandler(err);
                    if (typeof e === "object" && "message" in e) {
                      snackbar.error(e.message);
                    } else {
                      snackbar.error("something went wrong");
                    }
                  });
              } else {
                if (!value || Number(value?.name)) {
                  setError((prev) => {
                    return { ...prev, city: true };
                  });
                  setAddress((prev) => ({ ...prev, city: "", cityId: 0 }));
                } else {
                  setError((prev) => {
                    return { ...prev, city: false };
                  });
                  setAddress((prev) => ({ ...prev, city: value?.name, cityId: +(value?.id || 0) }));
                }
              }
            }}
            isInvalid={error.city}
            errorMessage="Please add the city in which you are currently residing."
            // disabled={profileData?.twelfth_pass ? true : false}
          />
        </Box>

        <Box>
          <Dropdown
            flip={true}
            options={[
              "Andhra Pradesh",
              "Arunachal Pradesh",
              "Assam",
              "Bihar",
              "Chhattisgarh",
              "Goa",
              "Gujarat",
              "Haryana",
              "Himachal Pradesh",
              "Jammu and Kashmir",
              "Jharkhand",
              "Karnataka",
              "Kerala",
              "Madhya Pradesh",
              "Maharashtra",
              "Manipur",
              "Meghalaya",
              "Mizoram",
              "Nagaland",
              "Odisha",
              "Punjab",
              "Rajasthan",
              "Sikkim",
              "Tamil Nadu",
              "Telangana",
              "Tripura",
              "Uttarakhand",
              "Uttar Pradesh",
              "West Bengal",
              "Andaman and Nicobar Islands",
              "Chandigarh",
              "Dadra and Nagar Haveli",
              "Daman and Diu",
              "Delhi",
              "Lakshadweep",
              "Puducherry",
            ]}
            label={<>State *</>}
            placeholder="Select state"
            value={address?.state}
            getValueCallback={(value) => {
              if (!value) {
                setError((prev) => {
                  return { ...prev, state: true };
                });
                setAddress((prev) => ({ ...prev, state: "" }));
              } else {
                setError((prev) => {
                  return { ...prev, state: false };
                });
                setAddress((prev) => ({ ...prev, state: value }));
              }
              //
            }}
            isInvalid={error.state}
            errorMessage="Please add the state in which you are currently residing."
          />
        </Box>

        <Box>
          <InputCustom
            required
            type="text"
            getValueCallback={(value) => {
              if (!value) {
                setError((prev) => {
                  return { ...prev, studentName: false };
                });
                setAddress((prev) => ({ ...prev, studentName: "" }));
              } else {
                setError((prev) => {
                  return { ...prev, studentName: false };
                });
                setAddress((prev) => ({ ...prev, studentName: value.trim() }));
              }
            }}
            isInvalid={error.studentName}
            preFilledValue={address?.studentName}
            errorMessage={"Please enter valid name"}
            placeholder="Enter Your Name"
            onClick={() => {
              //
            }}
            customBackgroundColor="#fff"
            label={"Name of the student (as per Aadhar card) *"}
          />
        </Box>

        <Box>
          <Dropdown
            options={["Male", "Female", "Others"]}
            value={address?.gender}
            placeholder="Select your gender"
            getValueCallback={(value) => {
              if (!value) {
                setError((prev) => {
                  return { ...prev, gender: true };
                });
                setAddress((prev) => ({ ...prev, gender: "" }));
              } else {
                setError((prev) => {
                  return { ...prev, gender: false };
                });
                setAddress((prev) => ({ ...prev, gender: value }));
              }
              //
            }}
            isInvalid={error.gender}
            errorMessage="Please select your gender"
            label={<>Gender *</>}
          />
        </Box>

        <Box>
          <Dropdown
            options={["XS", "S", "M", "L", "XL", "XXL", "XXXL"]}
            value={address?.tshirtSize}
            placeholder="Select your T-shirt size"
            getValueCallback={(value) => {
              if (!value) {
                setError((prev) => {
                  return { ...prev, tshirtSize: true };
                });
                setAddress((prev) => ({ ...prev, tshirtSize: "" }));
              } else {
                setError((prev) => {
                  return { ...prev, tshirtSize: false };
                });
                setAddress((prev) => ({ ...prev, tshirtSize: value }));
              }
              //
            }}
            isInvalid={error.tshirtSize}
            errorMessage="Please select your T-shirt size"
            label={<>T-Shirt size *</>}
          />
        </Box>

        <Box>
          <Text fontSize={"15px"} mb={2} fontWeight={700}>
            Professional passport size photo upload against white background *
          </Text>
          <FileUploader
            label="Upload Photo"
            disabled={setPresignedUrlLoading}
            classes="profile-upload"
            handleChange={(file: File) => {
              setProfileImage(file);
              //   presignedUrlMutate();
            }}
            name="file"
            types={fileTypes}
            maxSize={50}
            onTypeError={onTypeError}
            onSizeError={onSizeError}
          />
          {error.passportSizePic ? (
            <Text fontSize={"14px"} color={"red.500"}>
              Failed to upload
            </Text>
          ) : null}
        </Box>

        <Button
          isLoading={loading}
          onClick={handleSubmit}
          disabled={
            loading ||
            !(
              address?.apartment &&
              address?.street &&
              address?.city &&
              address?.pincode &&
              address?.state &&
              address?.passportSizePic &&
              address?.tshirtSize &&
              address?.gender &&
              address?.studentName
            )
          }
          variant="primary"
        >
          Use this Address
        </Button>
      </Box>
    </Box>
  );
};

export default IITCourseAddressForm;
