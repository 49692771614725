import { Box, Button, Heading, Link, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { applyFor2ndAttemptOnAssessment, getApplicationById } from "../../../api/utils/api";
import { axiosErrorHandler } from "../../../api/utils/error";
import { InformationOutline } from "../../../assets/icons/components";
import { ModalTemplate } from "../../../components/common/general";
import useSnackBar from "../../../components/common/general/SnackBar";
import { isCourseProgramsIntersectsProfilePrograms } from "../../../components/common/utils/isProgramPresent";
import { AppContext, ApplicationActionType } from "../../../context/ApplicationContext";
import CoursesFailPage from "../../Application/msat/CoursesFailPage";
import { handleNewMsatScore } from "../../Application/msat/HandleNewMsatScore";
import { Banner } from "../Banner";
import ScoreDetails from "./ScoreDetails";
interface Props {
  text?: string;
  title?: string;
}
const CodingFailed = ({ text, title }: Props) => {
  const [state, dispatch] = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const snackbar = useSnackBar();
  const history = useHistory();
  const scores = state.applicationData?.coding_result;

  const [isMalPracticeDetectedModal, setIsMalPracticeDetectedModal] = useState(false);
  const isMalPracticeDetected = state.applicationData?.coding_result?.isMalPracticeDetected;
  const isMobile = useBreakpointValue({ base: true, md: false });

  const isEligibleFor2ndAttempt =
    state.applicationData?.prev_submission === null &&
    isCourseProgramsIntersectsProfilePrograms(
      state.applicationData?.batch_campus.batch?.course?.course_programs,
      [
        {
          id: 3,
          name: "MasaiX",
        },
      ]
    );

  useEffect(() => {
    if (isMalPracticeDetected) {
      setIsMalPracticeDetectedModal(true);
    }
  }, [isMalPracticeDetected]);
  const handleApplyFor2ndAttempt = async () => {
    if (!state.applicationData?.slug) return;
    try {
      setLoading(true);
      await applyFor2ndAttemptOnAssessment({ application_slug: state.applicationData?.slug || "" });
    } catch (err) {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    } finally {
      setLoading(false);
      if (state.profileData) {
        const res = await getApplicationById({
          profile_slug: state.profileData?.slug,
          application_slug: state.applicationData.slug,
        });
        // update data
        dispatch({
          type: ApplicationActionType.SET_APPLICATION,
          payload: {
            applicationData: res,
          },
        });
        history.push(`/batch/${res.batch_campus.batch_id}/${res.slug}/coding-instructions`);
      }
    }
  };

  return (
    <>
      <Box>
        <Stack px={{ base: "ms-16", md: "ms-24" }} pt={"ms-24"} spacing={"6"}>
          <Box>
            {isEligibleFor2ndAttempt ? (
              <Banner
                type="error"
                text={
                  text ||
                  `You could not clear the coding test in 1st attempt. But you have one more attempt left.`
                }
                title={title || "Try another attempt"}
                extras={
                  <Button
                    variant="primary"
                    height={"48px"}
                    width={isMobile ? "210px" : "242px"}
                    onClick={handleApplyFor2ndAttempt}
                    isLoading={loading}
                  >
                    attempt coding test
                  </Button>
                }
              />
            ) : (
              <Banner
                type="error"
                text={
                  text ||
                  `You could not clear the coding test and hence you’re not eligible for this course in this batch, check out our other courses that you can apply for.`
                }
                title={title || "Better luck next time"}
              />
            )}
          </Box>
          {scores && <ScoreDetails scoreDetailsData={handleNewMsatScore(scores, "coding")} />}
        </Stack>
        {!isEligibleFor2ndAttempt && <CoursesFailPage />}
      </Box>
      <ModalTemplate
        title=""
        isOpen={isMalPracticeDetectedModal}
        onClose={() => {
          //
        }}
        marginX={2}
        maxWidth="552px"
        showCloseButtonIcon={false}
        closeOnOverlayClick={false}
        backdropFilter="blur(10px) hue-rotate(90deg)"
      >
        <Box textAlign={"center"}>
          <InformationOutline boxSize="80px" color={"ms-error"} />
          <Heading mt={"ms-24"} as="h3">
            {" "}
            Plagiarism Detected!
          </Heading>
          <Text color={"ms-grey.700"} mt={"ms-16"} textStyle={"body1"}>
            Plagiarsim has been detected, you will not be eligible for any courses offered by Masai.
            Your access to the platform will be restricted . Kindly reach out to us at{" "}
            <Text display={"inline"} color={"ms-blue.500"} mt={"ms-16"} textStyle={"body1-md"}>
              <Link
                cursor={"pointer"}
                isExternal={true}
                href="mailto:admissions@masaischool.com"
                color="ms-blue.500"
                textDecoration={"underline"}
              >
                admissions@masaischool.com
              </Link>{" "}
            </Text>
            for any queries.
          </Text>
        </Box>
      </ModalTemplate>
    </>
  );
};

export default CodingFailed;
