import React from "react";
import { Box, HStack, Text } from "@chakra-ui/react";
interface Props {
  eventDetail: { icon: React.ReactElement; text: string };
}
const EventDetail = ({ eventDetail }: Props) => {
  return (
    <HStack alignItems={"flex-start"} spacing={"ms-4"}>
      <Box>{eventDetail.icon} </Box>
      <Text textStyle="body2-md" color="ms-grey.800">
        {eventDetail.text}
      </Text>
    </HStack>
  );
};

export default EventDetail;
