import { Box, Input, InputGroup, InputRightElement, Text, Button } from "@chakra-ui/react";
import React, { useState, useContext, useEffect } from "react";
import CouponModal from "./CouponModal";
import { useMutation } from "react-query";
import { AppContext, ApplicationActionType } from "../../context/ApplicationContext";
import usePayment from "../../api/utils/hooks/usePayment";
import useSnackBar from "../common/general/SnackBar";
import { axiosErrorHandler } from "../../api/utils/error";
import { verifyCoupon } from "../../api/utils/api";
import { PaymentType, ProfileType, ProgramProfileType, SemesterPaymentType } from "../../api/schemas/schema";
import { PaymentMethod } from "../../constants/IIt-course/iit-form-onboarding";
import { CreatePhonePayPayment, GetPhonePayPaymentStatus } from "../../api/utils/api/v2/payment";
import { getActiveBatch } from "../../api/utils/api/v2/batch";

interface CoursePropTypes {
  selectedCoursePrice?: number;
  discountedAmount: null | number;
  setDiscountedAmount: (discountAmount: number | null) => void;
  successHandler?: () => void;
  priceAfterDiscount?: number;
  registrationFee?: number;
  profile?: ProfileType;
  setCouponValue: (value: string) => void;
  couponValue: string;
  selectedCourseId: string;
  setDiscountPrice?: (discountPrice: number) => void;
  setCouponCode?: (couponCode: string) => void;
  activeTab?: PaymentMethod;
  semesterPaymentOptionsData?: SemesterPaymentType;
  paymentType: PaymentType;
  refectchFees?: () => void;
}
function CouponInput({
  selectedCoursePrice,
  discountedAmount,
  setDiscountedAmount,
  successHandler,
  priceAfterDiscount,
  registrationFee,
  profile,
  setCouponValue,
  couponValue,
  selectedCourseId,
  setDiscountPrice,
  setCouponCode,
  activeTab,
  semesterPaymentOptionsData,
  paymentType,
  refectchFees,
}: CoursePropTypes) {
  const [appState, setAppState] = useContext(AppContext);
  const snackbar = useSnackBar();
  const [isCouponApplied, setIsCouponApplied] = useState(appState?.couponCode ? true : false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { startRazorPay, isLoading: isPaymentLoading, isApiLoading: isPaymentApiLoading } = usePayment("SEMESTER_FEES", profile?.program_profile_type as ProgramProfileType);

  const { mutate: verifyCouponMutate, isLoading: isCouponVerifing } = useMutation(verifyCoupon, {
    onSuccess: (data) => {
      if (successHandler) successHandler();
      setIsModalOpen(true);

      let discount = 0;
      discount = Math.floor(((priceAfterDiscount || 0) * data?.discount_percentage) / 100);
      const minFinalAmount = (registrationFee || 0) * 0.7;
      const finalAmount = (selectedCoursePrice || 0) - discount;

      //   if (finalAmount < minFinalAmount) {
      //     // Calculate the discount to maintain minFinalAmount
      //     const adjustedDiscount = (selectedCoursePrice || 0) - minFinalAmount;

      //     // Ensure the adjusted discount is not greater than the original discount
      //     const newDiscount = Math.min(adjustedDiscount, discount);

      //     setDiscountedAmount(newDiscount);
      //     setDiscountPrice && setDiscountPrice(newDiscount);
      //   } else {
      setDiscountedAmount(discount);
      setDiscountPrice && setDiscountPrice(discount);
      //   }

      setAppState({
        type: ApplicationActionType.SET_COUPON_CODE,
        payload: { couponCode: undefined },
      });
      setAppState({
        type: ApplicationActionType.SET_COUPON_CODE,
        payload: { couponCode: couponValue },
      });
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: createPhonePayPayment, isLoading: isPhonePayApiLoading } = useMutation(CreatePhonePayPayment, {
    onSuccess: (data) => {
      const paymentUrl = data?.redirect_url;
      window.open(paymentUrl, "_self");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: verifyPhonePayPayment, isLoading: isVerifyPhonePayApiLoading } = useMutation(GetPhonePayPaymentStatus, {
    onSuccess: () => {
      refectchFees && refectchFees();
      // remove query param from url
      window.history.replaceState({}, document.title, window.location.pathname);
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: handlePaymentCreateOption, isLoading: isActiveApiBatchLoading } = useMutation(getActiveBatch, {
    onSuccess: (data) => {
      const isPhonePayEnabled = data && data.meta_data && data.meta_data.isPhonePayEnabled === "True";
      if (isPhonePayEnabled) {
        const redirectUrl = window.location.pathname;
        createPhonePayPayment({
          course_id: Number(selectedCourseId),
          payment_type: paymentType as PaymentType,
          redirect_url: redirectUrl,
          coupon_code: appState.couponCode,
          semester_payent_type: semesterPaymentOptionsData,
        });
      } else {
        startRazorPay(selectedCourseId || "", paymentType as PaymentType, appState.couponCode, semesterPaymentOptionsData);
      }
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const handleCouponApply = () => {
    verifyCouponMutate({
      coupon_code: couponValue,
      course_id: selectedCourseId ? +selectedCourseId : 0,
      is_partial_payment: semesterPaymentOptionsData === "PARTIALLY_PAID" ? true : false,
      paymentType: paymentType,
    });
  };
  const handleCouponRemove = () => {
    setDiscountedAmount(null);
    setDiscountPrice && setDiscountPrice(0);
    setAppState({
      type: ApplicationActionType.SET_COUPON_CODE,
      payload: { couponCode: undefined },
    });
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePayment = () => {
    handlePaymentCreateOption(selectedCourseId);
  };

  useEffect(() => {
    setIsCouponApplied(appState?.couponCode ? true : false);
  }, [appState]);

  useEffect(() => {
    setAppState({
      type: ApplicationActionType.SET_COUPON_CODE,
      payload: { couponCode: undefined },
    });
  }, []);

  useEffect(() => {
    const merchantTransactionId = location.search.split("merchantTransactionId=")[1];
    if (merchantTransactionId) {
      verifyPhonePayPayment(merchantTransactionId);
    }
  }, [location.search]);

  const isForNontech = profile?.meta && profile?.meta?.source_page === "iit-ropar-ai-for-non-tech";
  return (
    <Box>
      <Text mb={2} p="body2">
        Have a coupon?
      </Text>
      {appState.couponCode && !isForNontech ? (
        <Box alignItems={"center"} display={"flex"} p={"0.75rem"} border={"1px solid  #CCC"} borderRadius={"0.5rem"}>
          <Box style={{ width: "calc(100% - 66.5px)" }}>
            <Text p="body2">
              {appState.couponCode} has been applied!{" "}
              <Text as={"span"} fontWeight={800}>
                You have saved ₹{discountedAmount}
              </Text>
            </Text>
          </Box>
          <Box w={"66.5px"}>
            <Text onClick={() => handleCouponRemove()} _hover={{ cursor: "pointer" }} fontWeight={600} color={"#D61E27"}>
              REMOVE
            </Text>
          </Box>
        </Box>
      ) : (
        <InputGroup>
          <Input
            value={couponValue}
            onChange={(e) => {
              setCouponValue && setCouponValue(e.target.value);
              setCouponCode && setCouponCode(e.target.value);
            }}
            placeholder="Enter coupon code"
            onKeyDown={(e) => {
              if (e.key === "Enter" && couponValue) {
                handleCouponApply();
              }
            }}
          />
          <InputRightElement pr={8}>
            <CouponApply isLoading={isCouponVerifing} onCouponRemove={handleCouponRemove} onCouponApply={handleCouponApply} isCouponApplied={isCouponApplied} isDisabled={couponValue.length === 0} />
          </InputRightElement>
        </InputGroup>
      )}

      <CouponModal
        isPaymentLoading={isPaymentLoading || isPhonePayApiLoading || isActiveApiBatchLoading || isVerifyPhonePayApiLoading}
        isPaymentApiLoading={isPaymentApiLoading || isPhonePayApiLoading || isActiveApiBatchLoading || isVerifyPhonePayApiLoading}
        startRazorPay={startRazorPay}
        handlePayment={handlePayment}
        selectedCoursePrice={selectedCoursePrice || 0}
        discountAmount={discountedAmount || 0}
        title=""
        isOpen={isModalOpen}
        onClose={handleModalClose}
        selectedCourseId={selectedCourseId}
        activeTab={activeTab}
        semesterPaymentOptionsData={semesterPaymentOptionsData}
        paymentType={paymentType}
      />
    </Box>
  );
}

export default CouponInput;

interface CouponApplyProps {
  isDisabled: boolean;
  isCouponApplied: boolean;
  onCouponApply: () => void;
  onCouponRemove: () => void;
  isLoading?: boolean;
}
function CouponApply({ isDisabled, isCouponApplied, onCouponApply, onCouponRemove, isLoading }: CouponApplyProps) {
  if (isCouponApplied) {
    return (
      <Button isLoading={isLoading} variant="link" onClick={() => onCouponRemove()} _hover={{ cursor: "pointer" }} fontWeight={600} color={"#D61E27"}>
        REMOVE
      </Button>
    );
  }
  return (
    <Button isDisabled={isDisabled} isLoading={isLoading} variant="link" onClick={() => onCouponApply()}>
      APPLY
    </Button>
  );
}
