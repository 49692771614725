import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const PlusOne = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M13.5 7V9H15.5V17H17.5V7H13.5ZM11.5 13H9.5V15H7.5V13H5.5V11H7.5V9H9.5V11H11.5V13Z"
    />
  </Icon>
);

export default PlusOne;
