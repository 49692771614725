import { useBreakpointValue } from "@chakra-ui/react";
import { useContext } from "react";
import { CleverTapContext } from "./CleverTapProvider";

export enum CleverTapEvents {
  viewed_screen = "viewed_screen",
  viewed_screen_event = "viewed_screen_event",
  click_profile_navigation = "click_profile_navigation",
  click_profile_icon = "click_profile_icon",
  click_dashboard_navigation_tab = "click_dashboard_navigation_tab",
  click_switch_section = "click_switch_section",
  click_input_field = "click_input_field",
  click_submit = "click_submit",
  click_cancel = "click_cancel",
  click_profile_modal = "click_profile_modal",
  click_apply_now = "click_apply_now",
  click_back_to_courses = "click_back_to_courses",
  click_know_more = "click_know_more",
  click_close_banner = "click_close_banner",
  click_course_type_radio_button = "click_course_type_radio_button",
  click_continue = "click_continue",
  click_close_modal = "click_close_modal",
  click_resend_otp = "click_resend_otp",
  click_verify = "click_verify",
  click_add_referral = "click_add_referral",
  viewed_modal = "viewed_modal",
  click_save = "click_save",
  click_view_details = "click_view_details",
  click_register = "click_register",
  click_social_icon = "click_social_icon",
  click_get_started = "click_get_started",
  click_book_now = "click_book_now",
  click_raise_request = "click_raise_request",
  click_join = "click_join",
  click_practice_test = "click_practice_test",
  click_play_video = "click_play_video",
  click_start_msat = "click_start_msat",
  click_redirect_mettl = "click_redirect_mettl",
  click_participate = "click_participate",
  click_remove = "click_remove",
  click_add_another = "click_add_another",
  click_copy = "click_copy",
  click_sign_out = "click_sign_out",
  click_category_tab = "click_category_tab",
  click_join_discord = "click_join_discord",
  click_refer_prepleaf = "click_refer_prepleaf",
  click_share = "click_share",
  click_checkbox = "click_checkbox",
  click_view_all = "click_view_all",
  click_contact_us = "click_contact_us",
  click_start_self_learning = "click_start_self_learning",
  click_skip = "click_skip",
  click_my_referral = "click_my_referral",
  click_chevron = "click_chevron",
}

export enum CourseTypes {
  FT = "FT",
  PT = "PT",
}

export enum BannerTypes {
  EmailUpdate = "email_update",
}

export enum ButtonTypes {
  primary = "primary",
  secondary = "secondary",
  link = "link",
}

export type CleverTapParameters = {
  button_text?: string | number;
  screen_name?: ScreenNames | string;
  screen_size?: string;
  button_sequence?: number;
  button_type?: ButtonTypes | string;
  hamburger?: boolean;
  bottom_navigation?: boolean;
  field_name?: string;
  field_type?: string;
  field_sequence?: string;
  selected?: string;
  screen_position?: string;
  course_type?: CourseTypes | string;
  batch_start_date?: string;
  course_name?: string;
  modal_name?: ModalNames | string;
  banner_type?: BannerTypes | string;
  event_name?: string;
  event_start_date?: string;
  event_type?: string;
  testimonial_name?: string;
  contest_name?: string;
  contest_start_date?: string;
  contest_type?: string;
  contest_end_date?: string;
  page_no?: number;
};
export enum ScreenNames {
  coursesPage = "/dashboard/courses",
  dashboardCourses = "/dashboard/courses",
  coursesDashboard = "/dashboard",
  dashboardEvents = "/dashboard/events",
  applicationDetails = "/dashboard/applicant-details",
  selfLearning = "/dashboard/self-learning",
  myReferral = "/dashboard/my-referrals",
  myApplications = "/dashboard/my-applications",
  msatFailed = "/dashboard/msat-failed",
  applicationIneligible = "dashboard/application-ineligible",
  mfpSuccess = "/dashboard/mfp-passed",
  msatSuccess = "/dashboard/mettl-passed",
  profile = "/dashboard/profile",
  onboarding = "/dasboard/onboarding",
  msatInstructions = "dashboard/msat-instructions",
  contests = "/dashboard/contests",
  referral = "/dasboard/referral",
  msatDecisionPending = "/dashboard/cs-pending",
}

export enum ModalNames {
  courseType = "course_type",
  otpVerification = "otp_verification",
  changeName = "change_name",
  referralSubmittedSuccess = "referral_submitted_successfully",
  bootcampRegisteredSuccessfully = "bootcamp_registered_successfully",
  onboardingCalendly = "assisted_onboarding_calendly",
  counsellingCalendly = "counselling_session_calendly",
  redirectingToMETTL = "redirecting_to_mettl",
  signOut = "sign_out",
  isaVideo = "isa_video",
  msatSuccess = "msat_success",
  onboardingLinks = "onboarding_links",
  haveReferralCode = "have_referral_code",
}

export type CleverTapProfileParams = {
  Name?: string;
  Identity: string | number;
  Email: string;
  Phone?: string;
  Gender?: string;
  DOB?: Date;
  "MSG-email"?: boolean;
  "MSG-push"?: boolean;
  "MSG-sms"?: boolean;
  "MSG-whatsapp"?: boolean;
};

const useCleverTap = () => {
  const clevertap = useContext(CleverTapContext)[0];
  const screenSize = useBreakpointValue({ base: "mobile", md: "desktop" });

  const fireEvent = (event: CleverTapEvents, params: CleverTapParameters) => {
    clevertap.event.push(event, {
      ...params,
      screenSize,
      version: 4,
      website: "dashboard",
    });
  };

  const profilePush = (params: CleverTapProfileParams) => {
    clevertap.profile.push({
      Site: params,
    });
  };
  const onUserLogin = (params: CleverTapProfileParams) => {
    clevertap.onUserLogin.push({
      Site: params,
    });
  };

  return [
    {
      fireEvent,
      profilePush,
      onUserLogin,
    },
  ];
};

export default useCleverTap;
