import { Box, Button, Heading } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

const AdminIndex = () => {
  const history = useHistory();
  return (
    <Box margin="20px">
      <Heading as="h4" color={"#000"} mb="20px" justifyItems={"center"}>
        Navigate to different pages
      </Heading>
      <Box display={"flex"} gap="50px" mb="24px">
        <Button
          onClick={() => {
            history.push("/admin/events/all");
          }}
          variant="primary"
          mb="8px"
        >
          Events
        </Button>

        <Button
          onClick={() => {
            history.push("/admin/payments/all");
          }}
          variant="primary"
        >
          Payments
        </Button>
        <Button
          onClick={() => {
            history.push("/admin/users");
          }}
          variant="primary"
        >
          Users
        </Button>

        <Button
          onClick={() => {
            history.push("/admin/batch");
          }}
          variant="primary"
        >
          Batch Details
        </Button>

        <Button
          onClick={() => {
            history.push("/admin/coupon");
          }}
          variant="primary"
        >
          Coupons
        </Button>
      </Box>
    </Box>
  );
};

export default AdminIndex;
