import { Box, Heading, Text, Image, Button, useBreakpointValue } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  BlueArrow,
  NewMSATModal1,
  NewMSATModal2,
  NewMSATModal3,
  NewMSATModal4,
  UpDownArrow,
} from "../../../../assets/images";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import { isCourseProgramsIntersectsProfilePrograms } from "../../../../components/common/utils/isProgramPresent";
import { AppContext } from "../../../../context/ApplicationContext";

interface Props {
  setIsOpen: (isOpen: boolean) => void;
  handleClose: () => void;
  type?: string;
  isScholarhipTest?: boolean;
}
const NewMSATModalContent = ({ setIsOpen, handleClose, type, isScholarhipTest }: Props) => {
  const state = useContext(AppContext)[0];

  const location = useLocation();
  const pathData: { logo: string; text: string | React.ReactElement }[] = [
    { logo: NewMSATModal2, text: "Read Instructions" },
    { logo: NewMSATModal1, text: "Allow System Permissions" },
    {
      logo: NewMSATModal3,
      text: (
        <>
          Attempt
          <br />{" "}
          {type === "coding"
            ? "Coding"
            : isCourseProgramsIntersectsProfilePrograms(
                state.applicationData?.batch_campus.batch?.course?.course_programs,
                [
                  {
                    id: 1,
                    name: "Scholar",
                  },
                ]
              )
            ? "MSFT"
            : `MSAT`}{" "}
          Test
        </>
      ),
    },
    { logo: NewMSATModal4, text: "View Test Result" },
  ];

  const isMobile = useBreakpointValue({ base: true, md: false });
  const renderOneInstruction = (
    item: { logo: string; text: string | React.ReactElement },
    index?: number,
    hideArrow?: boolean
  ) => {
    return (
      <Box d={"flex"}>
        <Box textAlign={"center"} maxW={isMobile ? "70px" : "100px"}>
          <Box minW={"94px"}>
            <Image m={"auto"} maxW={isMobile ? "70px" : "100px"} src={item.logo} />
          </Box>
          <Text minW={"94px"} mt={{ base: "ms-4", md: "ms-8" }} textStyle={"body2-md"}>
            {item.text}
          </Text>
        </Box>

        {hideArrow ? (
          <></>
        ) : (
          <>
            {index !== pathData.length - 1 && (
              <Box mt={"50px"} mx={"ms-16"}>
                <Image src={BlueArrow} />
              </Box>
            )}{" "}
          </>
        )}
      </Box>
    );
  };
  return (
    <Box d={"flex"} flexDirection={"column"}>
      <Heading mb={{ base: "ms-4", md: "ms-8" }} as="h4" color={"ms-red.900"}>
        Before you move ahead,
      </Heading>
      <Text textStyle="body2" color="ms-grey.700">
        Here are a few steps that you have to follow to successfully
        {!isMobile && <br />} complete the{" "}
        {isScholarhipTest ? "Scholarship Test" : type === "coding" ? "Coding Test" : "MSAT"}.
      </Text>
      {isMobile ? (
        <>
          <Box mt={"ms-32"} d={"flex"}>
            <Box w={"100%"} mr={"ms-32"}>
              <Box d={"flex"} justifyContent={"space-between"} mb={"ms-16"}>
                <Box>{renderOneInstruction(pathData[0], 0, true)}</Box>
                <Box mt={"35px"}>
                  <Image src={BlueArrow} />
                </Box>
                <Box> {renderOneInstruction(pathData[1], 1, true)}</Box>
              </Box>
              <Box d={"flex"} justifyContent={"space-between"}>
                <Box>{renderOneInstruction(pathData[0], 2, true)}</Box>
                <Box mt={"35px"}>
                  <Image src={BlueArrow} />
                </Box>
                <Box> {renderOneInstruction(pathData[1], 3, true)}</Box>
              </Box>
            </Box>
            <Box alignSelf={"center"} ml={"auto"}>
              <Image src={UpDownArrow} />
            </Box>
          </Box>
        </>
      ) : (
        <Box mt={"ms-32"} d={"flex"} justifyContent={"space-between"}>
          {pathData.map((item, index) => {
            return renderOneInstruction(item, index);
          })}
        </Box>
      )}
      <Box mt={"40px"} ml={"auto"}>
        <Button
          variant={"primary"}
          onClick={() => {
            const eventName = type === "coding" ? "sp_db_test_steps_got_it" : "mtbt_got_it";
            sendTrackingEvent({
              event: eventName,
              eventLabel: location.pathname,
            });
            handleClose();
          }}
        >
          GOT IT
        </Button>
      </Box>
    </Box>
  );
};

export default NewMSATModalContent;
