import React, { useEffect } from "react";
import { useBestAssessment } from "../../api/utils/hooks/useBestAssessment";
import { GetCourseV2Type } from "../../api/schemas/schema";
import { useHistory } from "react-router-dom";
import { Box, Image, Text } from "@chakra-ui/react";
import { UncheckedRadioDisabled } from "../../assets/images";
import CheckedRadioImage from "../../assets/images/CheckedRadioImage.svg";
import UncheckedRadioImage from "../../assets/images/UncheckedRadioImage.svg";

interface Props {
  selectedCourse: number | null;
  setSelectedCourse: (value: number | null) => void;
  isMasaiCoursePrefered: boolean;
  allCourses: GetCourseV2Type[];
}

const IItCourseSelectionCard = ({
  setSelectedCourse,
  selectedCourse,
  isMasaiCoursePrefered,
  allCourses,
}: Props) => {
  const { data: bestAssessment } = useBestAssessment();
  const history = useHistory();
  const [courses, setCourses] = React.useState<
    {
      id: number;
      name: string;
      isEligible: boolean;
      batchId?: number;
    }[]
  >([]);

  useEffect(() => {
    if (allCourses) {
      setCourses(
        allCourses?.map((course: GetCourseV2Type) => ({
          id: course.id,
          name: course.name,
          isEligible: bestAssessment?.eligible_courses?.includes(course.id) ? true : false,
          batchId: course.active_batch?.id,
        }))
      );
      setSelectedCourse(allCourses[0].id);
    }
  }, [allCourses, bestAssessment?.eligible_courses]);

  return (
    <Box
      position={"relative"}
      borderBottomLeftRadius="12px"
      borderBottomRightRadius={"12px"}
      bgColor={"#fff"}
      pb={{ base: 4, md: 0 }}
    >
      <Box height="96%" position={"absolute"} top="12px" left="65%"></Box>

      {courses[0]?.isEligible ? (
        <Box p="16px" border="1px solid #ccc" rounded={"8px"} maxW={"90%"} mx={"auto"}>
          {/* courses header */}
          <Box pb="4px" borderBottom={"1px solid #ccc"}>
            <Text textStyle={"body2"} color="ms-grey.900">
              Chosen course, proceed with registration
            </Text>
          </Box>
          {/* courses list items */}

          <Box mt="12px" display={"grid"} gap="24px">
            {courses.map((course) => (
              <Box
                onClick={() => {
                  course.isEligible && setSelectedCourse(course.id);
                }}
                key={course.id}
                display={course.batchId ? "block" : "none"}
              >
                <Box display={"flex"} gap="6px">
                  {!course.isEligible ? (
                    <Image cursor={"pointer"} src={UncheckedRadioDisabled} />
                  ) : selectedCourse === course.id ? (
                    <Box cursor={"pointer"}>
                      <Image src={CheckedRadioImage} />
                    </Box>
                  ) : (
                    <Box cursor={"pointer"}>
                      <Image src={UncheckedRadioImage} />
                    </Box>
                  )}
                  <Box>
                    <Text
                      textStyle={"body1-md"}
                      color={course.isEligible ? "ms-grey.900" : "ms-grey.500"}
                      mb="8px"
                    >
                      {course.name}{" "}
                    </Text>
                    {course.isEligible && (
                      <Text
                        cursor={"pointer"}
                        onClick={() => {
                          if (!isMasaiCoursePrefered) {
                            history.push(`/mc-iitg-cs`);
                          } else {
                            history.push(`/courses/${course.id}/details`);
                          }
                        }}
                        as="a"
                        textStyle={"body1-md"}
                        color="ms-blue.500"
                      >
                        (view details)
                      </Text>
                    )}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box
          color={"#3B3435"}
          textAlign={"center"}
          maxW={"430px"}
          mx={"auto"}
          p={2}
          display={"flex"}
          flexDir={"column"}
          gap={4}
          my={8}
        >
          <Text fontSize={"24px"} fontWeight={600}>
            You have 1 attempt left!
          </Text>
          <Text fontSize={"16px"} fontWeight={600}>
            You&apos;ve utilized one attempt. You still have one more chance. Please proceed by
            completing the foundation course first, and then you may retake the entrance test.
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default IItCourseSelectionCard;
