import { Box, Heading, Image, Text } from "@chakra-ui/react";
import { useActiveApplication } from "../../api/utils/hooks/useApplication";
import OnboardingSingleStep from "../V2/Onboarding/OnboardingSingleStep";
import { useGetPaymentStatus } from "../../api/utils/hooks/usePayment";

interface Props {
  onboardingSteps: {
    id: number;
    key: string;
    value: string;
  }[];
  currentOnboardingStep: number;
  showIITGuwahatiDashboard?: boolean;
  timelines?: { date: string; overdue: boolean }[];
  isAllStepsCompleted?: boolean;
  isScholarshipPage?: boolean;
  isMsatResultPage?: boolean;
}

const OnboardingStepTracker = ({ onboardingSteps, isMsatResultPage = false, currentOnboardingStep, showIITGuwahatiDashboard, timelines, isAllStepsCompleted, isScholarshipPage }: Props) => {
  //   const location = useLocation();
  const { data: activeApplication } = useActiveApplication();
  const { data: paymentData } = useGetPaymentStatus("SEMESTER_FEES");
  const hasUserPaidSemesterFees = paymentData?.status === "PAID" ? true : false;
  const courseName = `Course: ${activeApplication?.course_name}` || "";

  return (
    <Box
      boxShadow={"0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"}
      minH={{ base: isMsatResultPage ? "80px" : showIITGuwahatiDashboard ? "170px" : "130px", md: isMsatResultPage ? "130px" : "170px" }}
      py="20px"
      px="20px"
      pos={"relative"}
      overflow={"hidden"}
      borderRadius={"16px"}
      w={"full"}
      bgColor={"#EEFFF7"}
    >
      <Heading as="h3" color="#6FCD9E" display={isMsatResultPage ? "none" : "flex"}>
        {isScholarshipPage ? "Scholarship  submission" : "Onboarding"}
      </Heading>

      {isScholarshipPage ? (
        <Box mt={4} />
      ) : (
        <Text mt="4px" color="ms-grey.700" textStyle="body2-md" display={isMsatResultPage ? "none" : "flex"}>
          {courseName}
        </Text>
      )}
      <Box
        mt="12px"
        display={{ base: showIITGuwahatiDashboard ? "flex" : "none", md: "flex" }}
        justifyContent={"space-between"}
        overflowX={{ base: "auto", md: "visible" }}
        pos={"relative"}
        minH={"90px"}
        h={"full"}
        maxWidth={{ base: "100%", md: showIITGuwahatiDashboard ? "70%" : "50%" }}
        css={{
          "&::-webkit-scrollbar": {
            width: "0px",
            display: "none",
          },
          "&::-webkit-scrollbar-track": {
            width: "0px",
            display: "none",
          },
          "&::-webkit-scrollbar-thumb": {
            width: "0px",
            display: "none",
          },
        }}
      >
        {/* Render all 3 steps */}

        {onboardingSteps.map((step, index) => (
          <Box
            key={step.key}
            minW={{
              base: `${index === onboardingSteps.length - 1 ? "auto" : "220px"}`,
              md: "auto",
            }}
            flex={step.id === onboardingSteps.length ? "0" : "1"}
            ml={2}
          >
            <OnboardingSingleStep
              currentOnboardingStep={currentOnboardingStep}
              hideLine={step.id === onboardingSteps.length}
              onboardingStep={step}
              showIITGuwahatiDashboard={showIITGuwahatiDashboard}
              hasUserPaidSemesterFees={hasUserPaidSemesterFees}
              timeline={timelines && timelines[index]}
              isAllStepsCompleted={isAllStepsCompleted}
              isMsatResultPage={isMsatResultPage}
            />
          </Box>
        ))}
      </Box>
      <Box
        pos={"absolute"}
        bg={"#B7E6CF"}
        border={"1px solid #6FCD9E"}
        px={8}
        py={5}
        style={{
          transform: "rotate(-15deg)",
        }}
        display={{ base: "none", md: "flex" }}
        top={"-24px"}
        rounded={"24px"}
        right={"-20px"}
      >
        <Image
          src="/img/iit-course/onboardingTracker.svg"
          objectFit={"contain"}
          rounded={"14px"}
          style={{
            transform: "rotate(15deg)",
          }}
        />
      </Box>
    </Box>
  );
};

export default OnboardingStepTracker;
