/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Image, Text, Link, Button, useBreakpointValue, Avatar } from "@chakra-ui/react";
import { CheckCircle, LinkedIn } from "../../../assets/icons/components";
import Comma1 from "../../../assets/icons/components/Comma1";
import Comma2 from "../../../assets/icons/components/Comma2";
// import { GetLevelupAlumniType } from "../../../api/utils/api/v2/levelup";
import { useHistory } from "react-router-dom";
import { GetLevelupAlumniType } from "../../../api/schemas/schema";

interface AlumniCardProps extends GetLevelupAlumniType {
  onTextClick?: () => void;
  onCtaClick?: () => void;
  extendedText?: boolean;
  hideButton?: boolean;
  userSessionDetails?: any;
  shouldDisableButton?: boolean;
  btnText?: string;
  joinLink?: string;
  currentStep?: number;
  isAlumnisDataLoading?: boolean;
}

const NewAlumniCard = ({
  hideButton,
  registered,
  onCtaClick,
  onTextClick,
  name,
  profile,
  extendedText,
  shouldDisableButton,
  btnText,
  joinLink,
  currentStep,
  isAlumnisDataLoading,
}: AlumniCardProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const history = useHistory();

  const description =
    "We were taught by exceptional teachers, most of whom had been tech leads, and everyone worked with the most prestigious companies in the tech ecosystem. I share warm relations with all my teachers, and every moment in my job I realise how valuable and relevant the teachings were, which they taught us.";

  const highlight = "Computer Sceicne Tutor to SDE-I at NoBroker";
  const imgUrl =
    "https://masai-website-images.s3.ap-south-1.amazonaws.com/prottay_Ray_84e76e45ce.jpg";

  const designation = "Backend SDE";
  const company = "NoBroker";
  const experience = "2";

  return (
    <>
      <Box
        maxW={!hideButton ? "343px" : ""}
        p="12px"
        rounded="12px"
        bg={registered ? "#EEFFF7" : "white"}
        onClick={() => {
          if (onTextClick) onTextClick();
        }}
      >
        <Box>
          <Box display="flex" gap="14px">
            <Box
              maxW="73px"
              maxH="73px"
              w="full"
              h="full"
              border={profile?.imageUrl || profile?.profileImageUrl ? "1px solid #3470E4" : ""}
              bg="white"
              rounded="8px"
              overflow="hidden"
            >
              {profile?.imageUrl || profile?.profileImageUrl ? (
                <Image
                  objectFit="contain"
                  objectPosition="bottom"
                  src={profile?.imageUrl || profile?.profileImageUrl || imgUrl}
                  alt="almniImage"
                  rounded="7px"
                />
              ) : (
                <Avatar size="lg" bg="ms-purple.500" color="white" name={name} fontWeight="bold" />
              )}
            </Box>
            <Box>
              <Text textStyle="body2-md" textTransform="capitalize">
                {name}
              </Text>
              <Box mt="4px" display="flex" textStyle="caption">
                {(profile?.currentRole && profile?.currentRole) || designation}
                {(profile?.currentCompany && ` | ${profile?.currentCompany}`) || ` | ${company}`}
                {(profile?.totalExperience && ` | Exp: ${profile?.totalExperience}`) ||
                  ` | ${experience} Yrs`}
              </Box>
              <Link
                mt="6px"
                h="24px"
                w="24px"
                target="_blank"
                display="block"
                href={profile?.linkedInProfile || ""}
                onClick={(e) => e.stopPropagation()}
                _focus={{ boxShadow: "none" }}
              >
                <LinkedIn boxSize="24px" rounded="4px" />
              </Link>
            </Box>
          </Box>

          {!hideButton && <Box h="1px" bg="#D9D9D9" my="10px" />}
          {currentStep === 1 && isMobile && <Box h="1px" bg="#D9D9D9" my="10px" />}

          <Text
            textAlign={!hideButton ? "center" : "left"}
            mt="2"
            color="#6E71CC"
            {...{ fontWeight: "600", fontSize: "12px", lineHeight: "", fontFamily: "Open Sans" }}
          >
            {profile?.highlightText || highlight}
          </Text>

          {currentStep && currentStep === 1 ? (
            <Text textStyle="body2" color="#544D4F" noOfLines={extendedText ? -1 : 2} mt="10px">
              <Text as="span" display="inline-block" position="relative" mx="-5px" top="-8px">
                <Comma1 w="27px" h="24px" />
              </Text>{" "}
              {profile?.testimonialText || description}{" "}
              <Text as="span" display="inline-block" position="relative" mx="-5px" top="-8px">
                <Comma2 w="27px" h="24px" />
              </Text>
            </Text>
          ) : currentStep && currentStep === 2 ? (
            <></>
          ) : (
            <Text textStyle="body2" color="#544D4F" noOfLines={extendedText ? -1 : 2} mt="10px">
              <Text as="span" display="inline-block" position="relative" mx="-5px" top="-8px">
                <Comma1 w="27px" h="24px" />
              </Text>{" "}
              {profile?.testimonialText || description}{" "}
              <Text as="span" display="inline-block" position="relative" mx="-5px" top="-8px">
                <Comma2 w="27px" h="24px" />
              </Text>
            </Text>
          )}
        </Box>

        {!hideButton ? (
          registered ? (
            <Box
              h="47px"
              mt="14px"
              mx="-12px"
              mb="-12px"
              gap="13px"
              bg="#6FCD9E"
              color="white"
              display="flex"
              roundedBottom="11px"
              alignItems="center"
              justifyContent="center"
            >
              <CheckCircle />

              <Text textStyle="h6">Will get back soon.</Text>
            </Box>
          ) : (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                if (btnText === "Join Session") {
                  window.location.href = joinLink || "";
                } else {
                  if (onCtaClick) onCtaClick();
                }
              }}
              variant={btnText === "Join Session" ? "primary" : "secondary"}
              size="sm"
              textStyle="btn-sm"
              w="full"
              mt="13px"
              disabled={shouldDisableButton || isAlumnisDataLoading}
              isLoading={isAlumnisDataLoading}
            >
              {btnText || "get in touch"}
            </Button>
          )
        ) : null}
      </Box>
    </>
  );
};

export default NewAlumniCard;
