import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const JoinAlumniSession = () => {
  const history = useHistory();
  const query = useLocation();
  const sessionId = new URLSearchParams(query.search).get("sessionId");
  const token = new URLSearchParams(query.search).get("token");

  console.log("sessionId", sessionId);
  console.log("token", token);
  console.log("REACT_APP_LEVELUP_UI", process.env.REACT_APP_LEVELUP_UI);

  useEffect(() => {
    window.addEventListener("message", (e) => {
      const data = e.data;
      console.log("message received", data);
      if (data.type === "levelup-redirect") {
        // smothly redirect to /alumni
        history.push("/alumni");
      }
    });
  }, []);

  return (
    <iframe
      src={`${process.env.REACT_APP_LEVELUP_UI}/hosts/sessions/live/${sessionId}?token=${token}`}
      style={{ width: "100%", height: "100%" }}
      allow="camera;microphone;fullscreen;speaker;display-capture;autoplay;"
    />
  );
};

export default JoinAlumniSession;
