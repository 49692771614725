import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const ChartIcon = (props: Props) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      d="M29.3337 9.2265L27.4537 7.33317L23.6537 11.6265C20.907 8.53317 17.107 6.6665 12.8137 6.6665C8.96033 6.6665 5.42699 8.21317 2.66699 10.6665L4.56033 12.5598C6.82699 10.5732 9.69366 9.33317 12.8137 9.33317C16.467 9.33317 19.6003 11.0132 21.8403 13.6532L18.0003 17.9998L12.667 12.6665L2.66699 22.6665L4.66699 24.6665L12.667 16.6665L18.0003 21.9998L23.4003 15.9065C24.4003 17.7065 25.067 19.7732 25.3337 21.9998H28.0003C27.707 18.9065 26.7337 16.1198 25.2803 13.7865L29.3337 9.2265Z"
      fill="currentColor"
    />
  </Icon>
);

export default ChartIcon;
