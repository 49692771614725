import React, { useContext } from "react";
import { Box, Heading, Flex } from "@chakra-ui/react";
import ReactPlayer from "react-player";
import { sendTrackingEvent } from "../../common/utils/gtm";
import { AppContext } from "../../../context/ApplicationContext";
interface Props {
  url: string;
  heading?: string;
  width?: string | { base?: string; md?: string; lg?: string; xl?: string };
  height?: string;
  baseHeight?: string;
  mdHeight?: string;
  padding?: string;
  eventName?: string;
  eventLabel?: string;
  controls?: boolean;
}

const YoutubeCard = ({
  url,
  heading,
  width,
  height,
  padding,
  baseHeight,
  mdHeight,
  eventName,
  eventLabel,
  controls,
}: Props) => {
  const state = useContext(AppContext)[0];
  const batch_id = state.applicationData?.batch_campus.batch_id;
  return (
    <Box p={padding ? padding : "ms-8"} width={"100%"}>
      <Heading as={"h4"} color={"ms-red.900"}>
        {heading}
      </Heading>
      <Box
        borderRadius="0.5rem"
        overflow="hidden"
        my={"ms-8"}
        h={{
          base: baseHeight ? baseHeight : "320px",
          md: mdHeight ? mdHeight : height ? height : "181px",
        }}
        minW={{ md: "360px" }}
        w={width}
      >
        <Flex w={"full"} h={"100%"} borderRadius="0.5rem" overflow="hidden">
          <ReactPlayer
            controls={controls}
            onPlay={() => {
              console.log("Video started playing onPlay");
            }}
            onStart={() => {
              console.log("Video started playing");
              eventName &&
                eventLabel &&
                sendTrackingEvent({
                  event: eventName,
                  eventLabel: eventLabel,
                });
            }}
            onEnded={() => {
              console.log("Video Ended playing");
            }}
            height="inherit"
            width={"inherit"}
            url={url}
          />
        </Flex>
      </Box>
    </Box>
  );
};

export default YoutubeCard;
