import { Box, Heading, Text } from "@chakra-ui/react";
import React, { useContext } from "react";
import { AppContext } from "../../../../context/ApplicationContext";
import { Banner } from "../../../Courses/Banner";
import ApplicationStatusCard from "./ApplicationStatusCard";
import InterviewSessionCards from "./InterviewSessionCards";
import { StatusTypes, Steps } from "./types";

const Onboarding = ({
  children,
  statusArray,
  subtitle,
  success,
  successMessage,
  handleStepCick,
}: {
  children: JSX.Element | JSX.Element[];
  statusArray: StatusTypes[];
  subtitle?: string | JSX.Element | JSX.Element[];
  success?: boolean;
  successMessage?: string | JSX.Element | JSX.Element[];
  handleStepCick: (step: Steps) => void;
}) => {
  const state = useContext(AppContext)[0];
  const showItemsOnTop =
    (state?.applicationData?.counselling_sessions &&
      state?.applicationData?.counselling_sessions.length > 0) ||
    (state?.applicationData?.onboarding_sessions &&
      state?.applicationData?.onboarding_sessions.length > 0);
  return (
    <Box px={["1rem", "1.5rem"]} py="1rem">
      <Heading as="h3">Onboarding</Heading>
      {subtitle && (
        <Text mt="0.5.5rem" textStyle="body1">
          {subtitle}
        </Text>
      )}
      {success && (
        <Box mt="1rem">
          <Banner type="success" title="Congratulations!🤩" text={successMessage} />
        </Box>
      )}
      {showItemsOnTop && <InterviewSessionCards />}
      <Box mt="1.5rem">
        <ApplicationStatusCard onStepChanged={handleStepCick} statusArray={statusArray}>
          {children}
        </ApplicationStatusCard>
      </Box>
      {!showItemsOnTop && <InterviewSessionCards />}
    </Box>
  );
};

export default Onboarding;
