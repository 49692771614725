import {
  EmpowerHerDetailsType,
  IITEMIOptions,
  IITOboardingFormInputInterface,
  NewAadhaarPANCardUploadedDataType,
  PanCardImageUrlInterface,
  PapOnboardingInterface,
} from "../../../../constants/IIt-course/iit-form-onboarding";
import { ErrorResponseModelType, GetProfileTypeV2, ProgramProfileType } from "../../../schemas/schema";
import api from "../../axios";

export const getProfile = async (): Promise<GetProfileTypeV2> => {
  const client = await api.client;
  const response = await client.get_profile_v2_profile__get();
  return response.data as GetProfileTypeV2;
};

export const updateProfile = async ({
  date_of_birth,
  graduation_year,
  graduation_month,
  is_working,
  dp_url,
  program_profile_type,
  other_address,
  jwtToken,
  meta,
}: {
  date_of_birth?: string;
  graduation_year?: number;
  graduation_month?: number;
  is_working?: boolean;
  dp_url?: string;
  program_profile_type?: ProgramProfileType;
  other_address?: { [key: string]: unknown };
  jwtToken?: string;
  meta?: IITOboardingFormInputInterface | PanCardImageUrlInterface | IITEMIOptions | EmpowerHerDetailsType | PapOnboardingInterface | NewAadhaarPANCardUploadedDataType;
}) => {
  const client = await api.client;
  const response = await client.update_profile_v2_profile__patch(null, {
    date_of_birth,
    graduation_year,
    graduation_month,
    is_working,
    dp_url,
    other_address,
    jwtToken,
    meta,
    program_profile_type,
  });
  return response.data;
};

export const sendEmailOtpV2 = async (new_email: string): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.send_otp_v2_email_verification_send_otp_post(null, {
    new_email: new_email,
  });
  return response.data;
};
export const verifyEmailOtpV2 = async ({ otp_input }: { otp_input: string }): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.verify_otp_v2_email_verification_verify_otp_post(null, {
    otp_input: otp_input,
  });
  return response.data;
};

export const sendPhoneOtpV2 = async (new_phone_number: string): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.send_otp_v2_phone_verification_send_otp_post(null, {
    // add +91 if not present
    new_phone_number: new_phone_number.indexOf("+91") === -1 ? "+91" + new_phone_number : new_phone_number,
  });
  return response.data;
};
export const verifyPhoneOtpV2 = async ({ otp_input }: { otp_input: string }): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.verify_otp_v2_phone_verification_verify_otp_post(null, {
    otp_input: otp_input,
  });
  return response.data;
};

export const notifyMe = async (course_name: string): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.notify_me_v2_notify_me_post(null, {
    course_name,
  });
  return response.data;
};

export const getProfileImagePresignedUrl = async () => {
  const client = await api.client;
  const response = await client.get_s3_presigned_url_v2_profile_s3_presigned_url_get();
  return response.data;
};

export const sendBorucherOnEmail = async ({
  send_broucher_email,
  request_callback_immediate,
  phone_btn_click,
  pay_btn_click,
}: {
  send_broucher_email?: boolean;
  request_callback_immediate?: boolean;
  phone_btn_click?: boolean;
  pay_btn_click?: boolean;
}): Promise<ErrorResponseModelType> => {
  const client = await api.client;
  const response = await client.send_emails_v2_profile_send_emails_post(null, {
    send_broucher_email,
    request_callback_immediate,
    phone_btn_click,
    pay_btn_click,
  });
  return response.data;
};

export const validateEligibility = async (courseId: string) => {
  const client = await api.client;
  const response = await client.validate_eligibility_v3_applications_validate_eligibility_post(null, {
    course_id: courseId,
  });
  return response.data;
};
