import React from "react";
import { Box, Checkbox, Text } from "@chakra-ui/react";

interface Props {
  option: {
    value: string | number;
    label: string | number;
    supportLabel?: string | number;
  };
  size: "md" | "lg";
  color?: string;
  checked?: boolean;
  isDisabled?: boolean;
  isIndeterminate?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SingleCheckbox = ({
  size,
  color,
  checked,
  onChange,
  option,
  isDisabled,
  isIndeterminate,
}: Props) => {
  return (
    <>
      <Box>
        <Checkbox
          onChange={onChange}
          value={option.value}
          d="flex"
          alignItems={"start"}
          size={size}
          colorScheme={color ? color : "ms-blue"}
          isDisabled={isDisabled}
          iconColor="white"
          isIndeterminate={isIndeterminate}
          _hover={
            !checked && !isIndeterminate && !isDisabled
              ? {
                  "& .chakra-checkbox__control": {
                    bg: "ms-blue.50",
                    border: "2px solid #3470E4;",
                  },
                }
              : {}
          }
        >
          <Box mt={-1} d="flex" flexDirection={"column"}>
            <Text textStyle={size === "md" ? "body2" : "body1"} color="ms-red.900">
              {option.label}
            </Text>
            {option.supportLabel ? (
              <Text textStyle="caption" color="ms-grey.700">
                {option.supportLabel}
              </Text>
            ) : null}
          </Box>
        </Checkbox>
      </Box>
    </>
  );
};

export default SingleCheckbox;
