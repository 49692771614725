import dayjs from "dayjs";
import { TimeIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { GetEventType } from "../../api/schemas/schema";
import { CalendarIcon } from "../../assets/icons/components";
import { useFounderWebinars } from "../../api/utils/hooks/useEvents";
import { Box, Button, Heading, Image, Skeleton, Text } from "@chakra-ui/react";
import { useMutation } from "react-query";
import useSnackBar from "../common/general/SnackBar";
import { registerForEvent } from "../../api/utils/api/v2/activity";
import { axiosErrorHandler } from "../../api/utils/error";

interface Props {
  setIsFounderWebinarModalOpen: (param: boolean) => void;
}

const FoundWebinarModalContent = ({ setIsFounderWebinarModalOpen }: Props) => {
  const snackbar = useSnackBar();
  const [webinarDetail, setWebinarDetail] = useState<GetEventType>();
  const { data: webinarData, isLoading } = useFounderWebinars();
  const [registeredInFounderWebinar, setRegisteredInFounderWebinar] = useState<boolean>(false);
  const { mutate: foudnerWebinarMuatate, isLoading: isFounderWebinarLoading } = useMutation(
    registerForEvent,
    {
      onSuccess: () => {
        snackbar.success("Registeration Successful");
        setIsFounderWebinarModalOpen(false);
      },
      onError: (error) => {
        const e = axiosErrorHandler(error);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("Something went wrong");
        }
      },
    }
  );
  const handleRegisterNow = () => {
    if (webinarDetail?.id) {
      foudnerWebinarMuatate({ event_id: webinarDetail?.id });
    }
  };

  useEffect(() => {
    if (webinarData && webinarData.length) {
      setWebinarDetail(webinarData[0]);
      if (webinarData[0]?.registered) {
        setRegisteredInFounderWebinar(true);
      }
    }
  }, [webinarData]);
  return (
    <>
      {isLoading ? (
        <Skeleton h="300px" w={"full"} px={"20px"} />
      ) : webinarDetail ? (
        <Box>
          <Heading pr={"20px"} as="h4" size="md">
            Register for a counselling session with one of our founders
          </Heading>
          <Text mt={"4px"} fontSize={"14px"}>
            Get your queries resolved directly from our Co-Founder
          </Text>
          <Box
            bg={"#FFF3CC"}
            border={"1px solid #E5E5E5"}
            rounded={"12px"}
            w={"full"}
            h={"auto"}
            mt={"14px"}
          >
            <Box display={"flex"} flexDir={"row"} justifyContent={"space-between"}>
              <Box p={"10px"}>
                <Text>Event Details :</Text>
                <Heading as="h4" size="md" mt={"10px"}>
                  Founder’s Webinar
                </Heading>
                <Text fontSize={"14px"}>
                  By {webinarDetail?.details?.onward[0].speakerShortAbout}{" "}
                  {/* <span style={{ color: "#544D4F", fontSize: "12px" }}>(Co-Founder, Masai)</span> */}
                </Text>
              </Box>
              {/* Webinar Speaker Image */}
              <Box
                bg={"white"}
                rounded={"50%"}
                w={"94px"}
                h={"94px"}
                overflow={"hidden"}
                roundedTopRight={"0px"}
                roundedBottomRight={"0px"}
              >
                <Image
                  src={webinarDetail?.details?.onward[0]?.speakerImage || ""}
                  alt="speaker-image"
                  objectFit={"contain"}
                />
              </Box>
            </Box>
            <Box display={"flex"} gap={"12px"} alignItems={"center"} p={"10px"}>
              {/* Showing Date */}
              <Box
                display={"flex"}
                gap={"6px"}
                pr={"16px"}
                alignItems={"center"}
                borderRight={"1px solid #B3B3B3"}
              >
                <Box>
                  {" "}
                  <CalendarIcon boxSize="20px" />
                </Box>
                <Text fontSize={"14px"} as={"b"}>
                  {dayjs(webinarDetail?.event_start_date).format("Do MMM, dddd")}
                </Text>
              </Box>
              {/* Showing Time */}
              <Box display={"flex"} gap={"6px"} pl={"12px"} alignItems={"center"}>
                <Box>
                  {" "}
                  <TimeIcon boxSize="20px" />
                </Box>
                <Text fontSize={"14px"} as={"b"}>
                  {dayjs(webinarDetail?.event_start_date).format("hh:mma")} -{" "}
                  {dayjs(webinarDetail?.event_end_data).format("hh:mma")}
                </Text>
              </Box>
            </Box>
            <Box p={"10px"}>
              <Button
                variant="primary"
                w={"full"}
                onClick={handleRegisterNow}
                isLoading={isFounderWebinarLoading}
                disabled={registeredInFounderWebinar}
              >
                {registeredInFounderWebinar ? "Registered" : "Register"}
              </Button>
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default FoundWebinarModalContent;
