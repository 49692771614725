import { useState, useContext } from "react";
import { Box, HStack, Menu, Button, MenuButton, MenuItem, MenuList, Avatar, Image, useBreakpointValue, Flex, Spinner } from "@chakra-ui/react";
import { AccountOutline, Logout, ChevronDown, ChevronUp, MyTutorialsIcon, MyBookmarksIcon, MyCertificatesIcon } from "../../../assets/icons/components";
import { Link, useLocation } from "react-router-dom";
import { userSignout } from "../../../api/utils/axios";
import ModalTemplate from "../general/ModalTemplate";
import useCleverTap, { CleverTapEvents, ScreenNames, ButtonTypes, ModalNames } from "../../../clevertap/useCleverTap";
import { sendTrackingEvent } from "../utils/gtm";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import { AppContext } from "../../../context/ApplicationContext";
import { ProgramProfileType } from "../../../enums/ProgramType.enum";

interface Props {
  username?: string;
  children?: string[] | null;
  imgSrc?: string;
}

const iconProps = {
  boxSize: "24px",
  color: "ms-grey.700",
};

export const HeaderRightSection = ({ username, imgSrc }: Props) => {
  const desktopScreens = useBreakpointValue({ base: "none", md: "block" });
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const clevertap = useCleverTap()[0];
  const location = useLocation();
  const { data: profile } = useProfile();
  const state = useContext(AppContext)[0];

  const isMasaiCoursePrefered = state.coursePreference === ProgramProfileType.MASAI;
  const isRoparCoursePrefered = state.coursePreference === ProgramProfileType.IIT_ROPAR;
  const isMandiV2CoursePrefered = state.coursePreference === ProgramProfileType.IIT_MANDI_V2;

  const profileLinks = [
    {
      id: "rightHeaderMenu__myProfile",
      title: "My Profile",
      link: "/profile",
      icon: <AccountOutline {...iconProps} />,
      onClick: undefined,
      gtmId: "website_learn_header_profile_tab",
    },
    {
      id: "rightHeaderMenu__myTutorials",
      title: "My Tutorials",
      link: null,
      icon: <MyTutorialsIcon {...iconProps} />,
      onClick: () => {
        window.location.href = `${window.location.protocol}//${process.env.REACT_APP_LEARN_DOMAIN}/learn/my-tutorials`;
      },
      gtmId: "website_learn_header_tutorials",
    },
    {
      id: "rightHeaderMenu__myBookmarks",
      title: "My Bookmarks",
      link: null,
      icon: <MyBookmarksIcon {...iconProps} />,
      onClick: () => {
        window.location.href = `${window.location.protocol}//${process.env.REACT_APP_LEARN_DOMAIN}/learn/bookmarks`;
      },
      gtmId: "website_learn_header_bookmarks",
    },
    {
      id: "rightHeaderMenu__myCertificates",
      title: "My Certificates",
      link: null,
      icon: <MyCertificatesIcon {...iconProps} />,
      onClick: () => {
        window.location.href = `${window.location.protocol}//${process.env.REACT_APP_LEARN_DOMAIN}/learn/my-certificates`;
      },
      gtmId: "website_learn_header_certificates",
    },
    {
      id: "rightHeaderMenu__signOut",
      title: "Sign out",
      link: null,
      icon: <Logout {...iconProps} />,
      gtmId: "signOut",
      onClick: () => {
        setOpen(true);
        // clever tap event
        clevertap.fireEvent(CleverTapEvents.click_profile_navigation, {
          screen_name: ScreenNames.dashboardCourses,
          button_text: "sign out",
          button_type: ButtonTypes.link,
          button_sequence: 4,
        });
        clevertap.fireEvent(CleverTapEvents.viewed_modal, {
          modal_name: ModalNames.signOut,
        });
      },
    },
  ];

  const handleSignout = async () => {
    setLoading(true);
    await userSignout({ redirectTrue: false, viaButtonClick: true, isRoparCoursePrefered, isMandiV2CoursePrefered });
    setLoading(false);
    sendTrackingEvent({ event: "dash-sign_out-yes", eventLabel: location.pathname });
    clevertap.fireEvent(CleverTapEvents.click_sign_out, {
      modal_name: ModalNames.signOut,
      button_text: "sign_out",
      button_type: ButtonTypes.primary,
    });
  };

  const onClickProfileIconTrack = () => {
    // clever tap event
    clevertap.fireEvent(CleverTapEvents.click_profile_icon, {
      screen_name: ScreenNames.dashboardCourses,
      button_type: "dropdown",
    });
  };

  const onClickProfileMenuTrack = (text: string) => {
    const btnText = text.toLowerCase();
  };

  const handleSendEvent = (gtmId: string) => {
    switch (gtmId) {
      case "myProfile":
        sendTrackingEvent({ event: "dash-my_profile", eventLabel: location.pathname });
        break;
      case "myApplications":
        sendTrackingEvent({ event: "dash-my_application", eventLabel: location.pathname });
        break;
      case "signOut":
        sendTrackingEvent({ event: "dash-sign_out", eventLabel: location.pathname });
        break;
      default:
        return;
    }
  };

  const filterProfileDropDownLinks = () => {
    const filteredLinks = profileLinks.filter((links) => {
      if (!isMasaiCoursePrefered) {
        return links.id !== "rightHeaderMenu__myCertificates" && links.id !== "rightHeaderMenu__myBookmarks" && links.id !== "rightHeaderMenu__myTutorials";
      } else {
        return links;
      }
    });
    return filteredLinks;
  };

  return (
    <>
      <ModalTemplate
        title="Are you sure you want to sign out?"
        isOpen={open}
        onClose={() => {
          sendTrackingEvent({ event: "dash-sign_out-X", eventLabel: location.pathname });
          setOpen(false);
          clevertap.fireEvent(CleverTapEvents.click_close_modal, {
            modal_name: ModalNames.signOut,
          });
        }}
        showCloseButtonIcon={true}
        showFooterContent={true}
        footerContent={
          <Flex mt="2rem" gap="1rem">
            <Button
              variant="secondary"
              onClick={() => {
                setOpen(false);
                sendTrackingEvent({ event: "dash-sign_out-cancel", eventLabel: location.pathname });
                clevertap.fireEvent(CleverTapEvents.click_cancel, {
                  modal_name: ModalNames.signOut,
                  button_text: "CANCEL",
                  button_type: ButtonTypes.secondary,
                });
              }}
            >
              Cancel
            </Button>
            <Button id="modalSignOutButton" disabled={loading} onClick={handleSignout} variant="primary" position={loading ? "relative" : "static"}>
              SIGN OUT
              {loading && <Spinner speed={"0.5s"} thickness={"2px"} size={"md"} position={"absolute"} color={"ms-blue.500"} />}
            </Button>
          </Flex>
        }
      >
        <></>
      </ModalTemplate>
      <Menu autoSelect={false} placement="bottom-start" offset={[8, 4]}>
        {({ isOpen }) => (
          <>
            <Box
              id="user-profile"
              onClick={() => {
                sendTrackingEvent({
                  event: "dash-candidate_profile",
                  eventLabel: location.pathname,
                });
              }}
            >
              <MenuButton
                onClick={onClickProfileIconTrack}
                p="0"
                textTransform="initial"
                size="body1-md"
                as={Button}
                isActive={isOpen}
                border="none"
                _hover={{
                  bg: "none",
                }}
                _focus={{ border: "none", bg: "none" }}
                _active={{ bg: "none" }}
                rightIcon={
                  isOpen ? (
                    <Box display={desktopScreens}>
                      <ChevronUp css={{ color: "black" }} boxSize="4" />
                    </Box>
                  ) : (
                    <Box display={desktopScreens}>
                      <ChevronDown css={{ color: "black" }} boxSize="4" />
                    </Box>
                  )
                }
              >
                <Box>
                  <HStack id="rightHeader__menuButton">
                    {profile?.dp_url ? (
                      <Image boxSize={"24px"} borderRadius="50%" src={profile?.dp_url} />
                    ) : (
                      <Box>
                        <Avatar size="xs" bg="ms-brick.500" color="white" name={username} fontWeight="bold" src={imgSrc} icon={<AccountOutline boxSize="6" />} />
                      </Box>
                    )}

                    {/* <Text display={desktopScreens} variant="body2-md" pt="ms-2">
                      {username}
                    </Text> */}
                  </HStack>
                </Box>
              </MenuButton>
            </Box>
            <MenuList
              borderRadius="ms-16"
              minWidth="182px"
              py="0px"
              overflowX="hidden"
              maxHeight="245px"
              boxShadow="0px -2px 11px -2px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
            >
              {filterProfileDropDownLinks().map((item, idx) => {
                return item.link ? (
                  <Link
                    onClick={() => {
                      handleSendEvent(item.gtmId);
                      onClickProfileMenuTrack(item.title);
                    }}
                    to={item.link}
                    key={item.link}
                    id={item.id}
                  >
                    <MenuItem
                      boxShadow={profileLinks.length - 1 !== idx ? "0px 1px 0px #D9D9D9" : "none"}
                      _hover={{ bgColor: "ms-blue.50", boxShadow: "none", color: "ms-blue.500" }}
                      h="48px"
                      icon={item.icon}
                      iconSpacing="ms-8"
                      textStyle="body1"
                      color="ms-red.900"
                    >
                      {item.title}
                    </MenuItem>
                  </Link>
                ) : (
                  <MenuItem
                    key={idx}
                    onClick={() => {
                      handleSendEvent(item.gtmId);
                      item && item.onClick && item.onClick();
                    }}
                    boxShadow={profileLinks.length - 1 !== idx ? "0px 1px 0px #D9D9D9" : "none"}
                    _hover={{ bgColor: "ms-blue.50", boxShadow: "none", color: "ms-blue.500" }}
                    h="48px"
                    icon={item.icon}
                    iconSpacing="ms-8"
                    textStyle="body1"
                    color="ms-red.900"
                    className={item.id}
                  >
                    {item.title}
                  </MenuItem>
                );
              })}
            </MenuList>
          </>
        )}
      </Menu>
    </>
  );
};
