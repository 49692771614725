import { Box, Button, Heading, Image, Spinner, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
interface Props {
  testCardsData: {
    key: number;
    heading: string;
    text: string;
    btnText: string;
    image: string;
    handleClick: () => void;
    disableBtn?: boolean;
  }[];
  isScholarhipTest?: boolean;
  loading?: boolean;
}
const AboutMSATCards = ({ testCardsData, isScholarhipTest, loading }: Props) => {
  const renderTestCard = (
    testCard: {
      key: number;
      heading: string;
      text: string;
      btnText: string;
      image: string;
      isExternal?: boolean;
      handleClick: () => void;
      disableBtn?: boolean;
    },
    loading: boolean
  ) => {
    return (
      <Box
        mb={testCard.key === 1 ? { base: "ms-12", md: "ms-16" } : ""}
        p={"ms-16"}
        border={"1px solid #D9D9D9"}
        borderRadius={"ms-8"}
        maxW={"330px"}
      >
        <Box d={"flex"}>
          <Box mr={"10px"}>
            <Image
              mb={{ lg: "ms-8", xl: "0" }}
              alignSelf={"flex-start"}
              src={testCard.image}
              alt="test"
            />
          </Box>
          <Box>
            <Heading as="h5" color={"ms-red.900"}>
              {testCard.heading}
            </Heading>
            <Text textStyle="body2" color={"ms-grey.800"}>
              {testCard.text}
            </Text>
          </Box>
        </Box>
        <Box mt={"ms-16"} d={"flex"}>
          {testCard.isExternal ? (
            <Box ml={"auto"}>
              <Link
                to={isScholarhipTest ? "/scholarship-test-syllabus" : "/msat-syllabus"}
                target="_blank"
              >
                <Button
                  disabled={testCard.disableBtn}
                  ml={"auto"}
                  variant={"secondary"}
                  onClick={testCard.handleClick}
                >
                  {testCard.btnText}
                </Button>
              </Link>
            </Box>
          ) : (
            <Button
              ml={"auto"}
              variant={"secondary"}
              onClick={testCard.handleClick}
              disabled={loading || testCard.disableBtn}
              position={loading ? "relative" : "static"}
            >
              {testCard.btnText}
              {loading && (
                <Spinner
                  speed={"0.5s"}
                  thickness={"2px"}
                  size={"md"}
                  position={"absolute"}
                  color={"ms-blue.500"}
                />
              )}
            </Button>
          )}
        </Box>
      </Box>
    );
  };
  return (
    <Box>
      {testCardsData?.map((testCard, i) => (
        <Box key={i}> {renderTestCard(testCard, loading ? true : false)}</Box>
      ))}
    </Box>
  );
};

export default AboutMSATCards;
