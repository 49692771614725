import { Box, Image, SquareProps, Tooltip } from "@chakra-ui/react";
import React from "react";
import MobileRequestcallback from "../../../assets/images/request-callback/mobilerequestcallback.gif";

const MobileRequestCallBtn = ({
  handleprop,
  handleclick,
}: {
  handleprop: SquareProps;
  handleclick: () => void;
}) => {
  return (
    <Tooltip
      label="For any query Request callback"
      hasArrow
      placement="left"
      bg="white"
      color="black"
      maxW="150px"
      borderRadius={"6px"}
      border="1px solid #D9D9D9"
    >
      <Box
        id="requestCallbackMobile"
        h="49px"
        w="48px"
        zIndex="10"
        right="15px"
        bottom="80px"
        bg="#4358F6"
        cursor="pointer"
        position="fixed"
        onClick={handleclick}
        borderRadius="39px"
        display={{ base: "block", md: "block" }}
        boxShadow="0px 0px 25px -5px rgba(0, 0, 0, 0.1), 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 0px 10px -5px rgba(0, 0, 0, 0.04)"
        {...handleprop}
      >
        <Image src={MobileRequestcallback} />
      </Box>
    </Tooltip>
  );
};

export default MobileRequestCallBtn;
