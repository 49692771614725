import { Skeleton } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { isCourseProgramsIntersectsProfilePrograms } from "../../../components/common/utils/isProgramPresent";
import { AppContext } from "../../../context/ApplicationContext";
import CapPricingAndPaymentDetails from "./CapProgram/CapPricingAndPaymentDetails";
import ScholarInstructionsPayment from "./ScholarProgram/ScholarInstructionsPayment";
import PaymentPendingMasaiOne from "./MasaiOnePayment/PaymentPendingMasaiOne";

type CurrentApplicationType = "CAP" | "SCHOLAR" | "MASAI_ONE";

export default function PaymentPending() {
  const [isCapApplication, setCapApplication] = useState<null | boolean>(null);
  const [currentApplicationType, setCurrentApplicationType] = useState<CurrentApplicationType>();
  const state = useContext(AppContext)[0];
  useEffect(() => {
    if (
      isCourseProgramsIntersectsProfilePrograms(
        state.applicationData?.batch_campus.batch?.course?.course_programs,
        [
          {
            id: 4,
            name: "CAP",
          },
        ]
      )
    ) {
      setCurrentApplicationType("CAP");
    } else if (
      isCourseProgramsIntersectsProfilePrograms(
        state.applicationData?.batch_campus.batch?.course?.course_programs,
        [
          {
            id: 1,
            name: "Scholar",
          },
        ]
      )
    ) {
      setCurrentApplicationType("SCHOLAR");
    } else if (
      isCourseProgramsIntersectsProfilePrograms(
        state.applicationData?.batch_campus.batch?.course?.course_programs,
        [
          {
            id: 1,
            name: "Masai_One",
          },
        ]
      )
    ) {
      setCurrentApplicationType("MASAI_ONE");
    }
  }, [state.applicationData?.batch_campus.batch?.course?.course_programs]);
  return (
    <Skeleton isLoaded={currentApplicationType ? true : false}>
      {currentApplicationType === "CAP" ? (
        <CapPricingAndPaymentDetails />
      ) : currentApplicationType === "SCHOLAR" ? (
        <ScholarInstructionsPayment />
      ) : (
        <PaymentPendingMasaiOne />
      )}
    </Skeleton>
  );
}
