import CourseDropDown from "../CourseDropDown";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, MenuList, MenuButton, MenuItem, Menu, Button } from "@chakra-ui/react";

interface Props {
  hideDropDown: boolean;
}

const CourseDropDownBtn = ({ hideDropDown }: Props) => {
  return (
    <Box display={hideDropDown ? "none" : "block"}>
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} bgColor={"#D6E2FA"}>
          Courses
        </MenuButton>
        <MenuList>
          <MenuItem _focus={{ bg: "white" }} bgColor={{ bg: "white" }} _hover={{ bg: "white" }} _active={{ bg: "white" }}>
            <CourseDropDown />
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default CourseDropDownBtn;
