export function getRandomElementsFromArray<T>(arr: Array<T>, numElements?: number): Array<T> {
  const shuffledArray = arr.slice(); // Create a copy of the array
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  if (numElements === undefined || numElements >= arr.length) {
    return shuffledArray; // Return the shuffled array
  } else {
    return shuffledArray.slice(0, numElements); // Return a slice of the shuffled array
  }
}
