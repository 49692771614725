import { useContext } from "react";
import { AppContext } from "../../context/ApplicationContext";
import OnboardingProgressPage from "../Application/onboarding/OnboardingProgressPage";
import CapPaymentSuccess from "./NewCoursesFlow/CapProgram/CapPaymentSuccess";

import { Skeleton } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { isCourseProgramsIntersectsProfilePrograms } from "../../components/common/utils/isProgramPresent";

export default function Onboarding() {
  const [isCapApplication, setCapApplication] = useState<null | boolean>(null);
  const state = useContext(AppContext)[0];
  useEffect(() => {
    if (state.applicationData) {
      if (
        isCourseProgramsIntersectsProfilePrograms(
          state.applicationData?.batch_campus.batch?.course?.course_programs,
          [
            {
              id: 4,
              name: "CAP",
            },
          ]
        )
      ) {
        setCapApplication(true);
      } else {
        setCapApplication(false);
      }
    }
  }, [state.applicationData?.batch_campus.batch?.course?.course_programs]);

  return (
    <Skeleton isLoaded={isCapApplication === true || isCapApplication === false}>
      {isCapApplication ? (
        <CapPaymentSuccess />
      ) : isCapApplication === false ? (
        <OnboardingProgressPage />
      ) : null}
    </Skeleton>
  );
}
