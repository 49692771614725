import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { CustomSlider } from "../../common/general";
import EmpowerHerScholarshipBanner from "./EmpowerHerScholarshipBanner";
import SonuSoodScholarshipBanner from "./SonuSoodScholarshipBanner";

const ScholarshipsComponent = () => {
  return (
    <Box maxW="1440px" mx={"auto"} px={{ base: "16px", xl: "80px" }}>
      <Text fontSize={{ base: "20px", md: "24px" }} fontWeight={700} fontFamily={"inter"}>
        Scholarships
      </Text>
      <Box display="grid" gridAutoColumns="100%" position="relative" mb={4}>
        <CustomSlider
          px="0px"
          hasButton={false}
          pagination={true}
          isContainer={false}
          customNavigation={{
            nextEl: ".swiper-button-next1-scholarship-banner",
            prevEl: ".swiper-button-prev1-scholarship-banner",
          }}
          prevBtnClass="swiper-button-prev1-scholarship-banner"
          nextBtnClass="swiper-button-next1-scholarship-banner"
          autoplay={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 16,
            },
            //Mobile
            380: {
              slidesPerView: 1,
              spaceBetween: 16,
            },
            568: {
              slidesPerView: 1,
              spaceBetween: 16,
            },
            688: {
              slidesPerView: 1,
              spaceBetween: 16,
            },
            //Tablet
            768: {
              slidesPerView: 1,
              spaceBetween: 16,
            },
            850: {
              slidesPerView: 1,
              spaceBetween: 16,
            },
            //Laptop
            1024: {
              slidesPerView: 1,
              spaceBetween: 32,
            },
            //Desktop
            1224: {
              slidesPerView: 1,
              spaceBetween: 32,
            },
            1300: {
              slidesPerView: 1,
              spaceBetween: 32,
            },
          }}
        >
          <EmpowerHerScholarshipBanner />
          <SonuSoodScholarshipBanner />
        </CustomSlider>
      </Box>
    </Box>
  );
};

export default ScholarshipsComponent;
