import { Box, Flex, Text } from "@chakra-ui/react";
import { FileOutline, TrashCanOutline } from "../../../../assets/icons/components";

interface Props {
  text: string;
  onClick: () => void;
}

const DragAndDropSkeleton = ({ text, onClick }: Props) => {
  return (
    <Box minW={{ base: "283px", md: "492px" }}>
      <Flex
        borderRadius={"ms-8"}
        border="1px dashed #B3B3B3"
        bgColor={"#FBFBFB"}
        alignItems={"center"}
        justifyContent={"space-between"}
        p={{ base: "ms-12", md: "ms-16" }}
      >
        <Box d={"flex"} alignItems={"center"}>
          <FileOutline boxSize={"6"} />
          <Text ml={"ms-10"} textStyle={"caption"}>
            {text}
          </Text>
        </Box>
        <Box cursor={"pointer"} onClick={onClick}>
          <TrashCanOutline color={"ms-error"} boxSize={"6"} />
        </Box>
      </Flex>
    </Box>
  );
};

export default DragAndDropSkeleton;
