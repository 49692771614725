import React, { useState } from "react";
import { Flex, Button, Spinner } from "@chakra-ui/react";
import { useCalendly } from "../../hooks/useCalendly";
import useSnackBar from "../common/general/SnackBar";
import { axiosErrorHandler } from "../../api/utils/error";
import { sendTrackingEvent } from "../common/utils/gtm";
import { useLocation } from "react-router-dom";

interface Props {
  handleBooking: () => void;
  isCounselling?: boolean;
}
export const OverviewCardFooter = ({ handleBooking, isCounselling }: Props) => {
  const calendly = useCalendly();
  const snackbar = useSnackBar();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  return (
    <Flex justifyContent={"flex-end"} mt="ms-4">
      <Button
        size={"sm"}
        disabled={loading}
        position={loading ? "relative" : "static"}
        variant={"secondary"}
        onClick={
          isCounselling
            ? () => {
                handleBooking();
              }
            : async () => {
                sendTrackingEvent({
                  event: "assisted_onboarding_book",
                  eventLabel: location.pathname,
                });
                //generate one time link and redirect
                setLoading(true);
                try {
                  const url = await calendly.generateOTLOnboarding();
                  setLoading(false);
                  console.log(`found ${url}`);
                  if (!url) throw new Error("Invalid URL");
                  else window.location.href = url;
                } catch (err) {
                  setLoading(false);
                  const e = axiosErrorHandler(err);
                  if (typeof e === "object" && "message" in e) {
                    snackbar.error(e.message);
                  } else {
                    snackbar.error("something went wrong");
                  }
                }
              }
        }
      >
        BOOK NOW
        {loading && (
          <Spinner
            speed={"0.5s"}
            thickness={"2px"}
            size={"md"}
            position={"absolute"}
            color={"ms-blue.500"}
          />
        )}
      </Button>
    </Flex>
  );
};
