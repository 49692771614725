import { Box, Button, Heading, Text, Tooltip, useBreakpointValue } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { ProfileAssessment } from "../../../api/schemas/schema";
import useSnackBar from "../../common/general/SnackBar";
import { useActiveApplication } from "../../../api/utils/hooks/useApplication";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { sendTrackingEvent } from "../../common/utils/gtm";
import { AppContext, ApplicationActionType } from "../../../context/ApplicationContext";
import usePayment, { useGetPaymentStatus } from "../../../api/utils/hooks/usePayment";
import { ModalTemplateV2 } from "../../common/general";
import calculateFinalPayment from "../../common/V2/CalculateFinalPayment";
import { useCourses } from "../../../api/utils/hooks/useCourse";
import { CoursesType } from "../ReserveSeat";
import { useBestAssessment } from "../../../api/utils/hooks/useBestAssessment";
import { useMutation } from "react-query";
import { createAssessment } from "../../../api/utils/api/v2/assessment";
import { axiosErrorHandler } from "../../../api/utils/error";
import { ProgramProfileType } from "../../../enums/ProgramType.enum";
interface Props {
  lastAssessmentLoading?: boolean;
  bestAssessmentLoading: boolean;
  msatResultStatus?: "PASSED" | "FAILED" | "PENDING";
  isCreateApplicationLoading: boolean;
  setCreateNewTest: React.Dispatch<React.SetStateAction<boolean>>;
  setShowMsatConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
  isFirstAttempt: boolean;
  lastAssessment?: ProfileAssessment;
  bestAssessment?: ProfileAssessment;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createApplicationMutate: any;
  selectedCourse: number | null;
  createNewTest: boolean;
  setSelectedCourse?: React.Dispatch<React.SetStateAction<number | null>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  profile?: any;
}

const MSATButtons = ({
  bestAssessmentLoading,
  msatResultStatus,
  isCreateApplicationLoading,
  setCreateNewTest,
  setShowMsatConfirmModal,
  isFirstAttempt,
  bestAssessment,
  createApplicationMutate,
  selectedCourse,
  profile,
}: Props) => {
  const [state, dispatch] = useContext(AppContext);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const snackbar = useSnackBar();
  const { data: activeApplication } = useActiveApplication();
  const history = useHistory();
  const location = useLocation();

  const { data: allCourses } = useCourses();
  const { data: paymentStatus } = useGetPaymentStatus("REGISTRATION_FEES");
  const [courseDataType, setCourseDataType] = useState<CoursesType[]>([]);
  const [paymentAmount, setPaymentAmount] = useState<undefined | number>(undefined);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const { startRazorPay, isLoading: isPaymentLoading, isApiLoading: isPaymentApiLoading } = usePayment("REGISTRATION_FEES", profile?.program_profile_type as ProgramProfileType);

  const [openRedirectModal, setOpenRedirectModal] = useState(false);
  const { refetch: refetchBestAssessment } = useBestAssessment();
  const { mutate: createAssessmentMutate, isLoading: isCreateAssessmentLoading } = useMutation(createAssessment, {
    onSuccess: (data) => {
      window.location.href = data.test_url;
      refetchBestAssessment();
    },
    onError: (error) => {
      setOpenRedirectModal(false);
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const handleButtonClick = () => {
    if (state.coursePreference !== ProgramProfileType.MASAI) {
      if (selectedCourse) {
        sendTrackingEvent({
          event: "p_pay",
          eventLabel: window.location.href,
          p_amount: (paymentAmount || 0).toString(),
        });
        startRazorPay(state.selectedCourse || "", "REGISTRATION_FEES");
      } else {
        snackbar.error("Please select a course");
      }
    } else {
      sendTrackingEvent({
        event: "d_msat_confirm_course",
        eventLabel: location.pathname,
      });
      if (selectedCourse) {
        if (activeApplication?.course_id === selectedCourse) {
          history.push("/onboarding");
        } else {
          createApplicationMutate({
            course_id: selectedCourse?.toString() || "",
          });
        }
      } else {
        snackbar.error("Please select a course");
      }
    }
  };

  useEffect(() => {
    if (!allCourses) return;
    const tempCourses: CoursesType[] = [];
    allCourses.forEach((course, index) => {
      if (index === 0) {
        dispatch({
          type: ApplicationActionType.SET_SELECTED_COURSE,
          payload: { selectedCourse: course.id.toString() },
        });
        tempCourses.push({
          ...course,
          Image: "/img/iit-course/iitgcourse.svg",
        });
      }
    });
    setCourseDataType(tempCourses);
  }, [allCourses]);

  // Calculating all the possible changes to payment amount
  useEffect(() => {
    if (state.selectedCourse && allCourses) {
      const course = allCourses?.find((course) => state.selectedCourse === course.id.toString());
      const registrationFee = course?.registration_fee || 0;
      const { finalAmount } = calculateFinalPayment({
        registrationFee,
      });

      setPaymentAmount(finalAmount);
    }
  }, [state.selectedCourse, profile, allCourses]);

  useEffect(() => {
    if (isPaymentLoading) setIsPaymentModalOpen(true);
    else {
      setIsPaymentModalOpen(false);
    }
  }, [isPaymentLoading]);

  const hasUserPaid = paymentStatus?.status === "PAID" && paymentStatus?.is_iit_guwahati_course ? true : paymentStatus?.status === "PAID" && paymentStatus?.is_iit_mandi_course ? true : false;
  const hasPassedMsat = bestAssessment && bestAssessment?.eligible_courses && bestAssessment?.eligible_courses?.length > 0;
  const isMasaiCoursePrefered = state.coursePreference === ProgramProfileType.MASAI;
  return (
    <>
      <Box mb="16px" maxW={state.coursePreference === ProgramProfileType.MASAI ? "full" : "95%"} mx={"auto"}>
        {!bestAssessmentLoading && (
          <Box mt="16px" display="flex">
            {msatResultStatus === "PASSED" && (
              <Button
                isLoading={isCreateApplicationLoading || isPaymentApiLoading}
                width={"100%"}
                onClick={handleButtonClick}
                variant="primary"
                disabled={
                  selectedCourse === null ||
                  (selectedCourse !== null && hasUserPaid) ||
                  isCreateApplicationLoading ||
                  (isMasaiCoursePrefered && (!hasPassedMsat || hasUserPaid || courseDataType.filter((course) => course.id.toString() === state.selectedCourse).length === 0))
                }
              >
                {state.coursePreference === ProgramProfileType.MASAI && (activeApplication?.course_id === selectedCourse ? "Continue Course" : "Confirm Course")}
                {state.coursePreference !== ProgramProfileType.MASAI && "Pay Registration Fee"}
              </Button>
            )}

            {state.coursePreference === ProgramProfileType.MASAI && msatResultStatus === "PASSED" && isFirstAttempt && (
              <Tooltip
                minW={{ base: "100%", md: "450px" }}
                label={
                  <Box display={"flex"} flexDir={"column"} alignItems={"center"} gap={2}>
                    <Text>If you wish to improve your score you may retake MSAT and be eligible for more courses. You have 1 more attempt left.</Text>
                  </Box>
                }
                fontSize="md"
                bg="white"
                color="black"
                borderRadius="md"
                padding="4"
                boxShadow="md"
              >
                <Button
                  ml="8px"
                  onClick={() => {
                    sendTrackingEvent({
                      event: "d_msat_retake",
                      eventLabel: location.pathname,
                    });
                    setCreateNewTest(true);
                    setShowMsatConfirmModal(true);
                  }}
                  bgColor={isMobile ? "#fff" : ""}
                  color={isMobile ? "#544D4F" : "ms-blue.500"}
                  variant="secondary"
                  width={"100%"}
                  disabled={hasUserPaid}
                >
                  Retake MSAT
                </Button>
              </Tooltip>
            )}
            {msatResultStatus === "FAILED" && isFirstAttempt ? (
              <Box display={"flex"} flexDir={{ base: "column", md: "row" }} alignItems={"center"} justifyContent={"space-between"} w={"full"} gap={4}>
                {state.coursePreference !== ProgramProfileType.MASAI && (
                  <Button
                    flex={1}
                    onClick={() => {
                      window.location.href = `${window.location.protocol}//${process.env.REACT_APP_LEARN_DOMAIN}/learn/tutorials/prep-course`;
                    }}
                    variant="primary"
                    width={"100%"}
                  >
                    GoTO Introductory Course
                  </Button>
                )}
                <Button
                  flex={1}
                  onClick={() => {
                    if (state.coursePreference !== ProgramProfileType.MASAI) {
                      sendTrackingEvent({
                        event: "d_start_msat_confirm",
                        eventLabel: location.pathname,
                      });
                      // set localstorage for the last assessment hide_switch = "true"
                      localStorage.setItem("hide_switch", "true");
                      const selectedCourseId = allCourses && allCourses.length > 0 ? allCourses[0].id : -1;
                      createAssessmentMutate({
                        for_practice: false,
                        course_preference: selectedCourseId,
                      });
                      setOpenRedirectModal(true);
                    } else {
                      sendTrackingEvent({
                        event: "d_msat_retake",
                        eventLabel: location.pathname,
                      });
                      setCreateNewTest(true);
                      setShowMsatConfirmModal(true);
                    }
                  }}
                  variant={"primary"}
                  width={"100%"}
                  isLoading={isCreateAssessmentLoading}
                  disabled={hasUserPaid}
                >
                  {state.coursePreference !== ProgramProfileType.MASAI ? "Retake Entrance Test" : "Retake MSAT"}
                </Button>
              </Box>
            ) : ["ENDED", "GRADED"].includes(bestAssessment?.status?.toUpperCase() || "") ? null : (
              <Button
                onClick={() => {
                  setCreateNewTest(false);
                  setShowMsatConfirmModal(true);
                }}
                variant="primary"
                width={"100%"}
                disabled={hasUserPaid}
              >
                Continue MSAT
              </Button>
            )}
          </Box>
        )}
      </Box>
      <ModalTemplateV2 isOpen={isPaymentModalOpen} isCentered onClose={() => setIsPaymentModalOpen(false)}>
        <Heading mb={3} color={"#6FCD9E"} as={"h3"}>
          Payment Successful
        </Heading>
        <Text color={"#F3F2F2"} p={"body1"}>
          Redirecting you to the dashboard
        </Text>
      </ModalTemplateV2>

      <ModalTemplateV2
        closeOnOverlayClick={false}
        isOpen={openRedirectModal}
        closeOnEsc={false}
        hideIconWithConfetti={true}
        showConfetti={false}
        onClose={() => {
          //
        }}
        waiting={true}
        text={"You are being redirected to our Entrance Test Platform."}
      />
    </>
  );
};

export default MSATButtons;
