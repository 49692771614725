import React from "react";
import { Box, Image, Text } from "@chakra-ui/react";
interface Props {
  image: string;
  feedback: string;
  name: string;
  role: string;
  company: string;
}
const TestimonialCard = ({ image, name, role, company, feedback }: Props) => {
  return (
    <Box
      h="full"
      padding={"16px"}
      bgColor={"#fff"}
      borderRadius={"ms-16"}
      border={{ base: "1px solid #85A9EF", md: "1px solid #D6E2FA" }}
      boxShadow="0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)"
    >
      <Box display={"flex"} gap="10px">
        <Image
          minW="41px"
          minH="41px"
          objectFit="cover"
          rounded={"50%"}
          src={image}
          alt={"student photo"}
          w="40px"
          h="40px"
        />
        <Box>
          <Text color={"#544D4F"} textStyle="body1-md">
            {name}
          </Text>
          <Text color={"#544D4F"} textStyle="caption" display="flex" alignItems="center">
            {role}
          </Text>
        </Box>
      </Box>
      <Text mt="8px" textStyle="caption">
        {feedback}
      </Text>
      {company && (
        <Image
          mt="8px"
          ml="2px"
          h="24px"
          maxW="100px"
          alt="company"
          src={company}
          objectFit="contain"
        />
      )}
    </Box>
  );
};

export default TestimonialCard;
