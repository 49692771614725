import { Box } from "@chakra-ui/layout";
import React from "react";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import OTPModal from "../auth/OTPModal";
import { sendPhoneOtpV2, verifyPhoneOtpV2 } from "../../../api/utils/api/v2/profile";
import { useMutation } from "react-query";
import useSnackBar from "../general/SnackBar";
import { axiosErrorHandler } from "../../../api/utils/error";
import PhoneEdit from "./PhoneEdit";

interface Props {
  showPhoneOtpModal: boolean;
  setShowPhoneOtpModal: React.Dispatch<React.SetStateAction<boolean>>;
  updatedPhone?: string;
  setShowUpdatePhoneModal: React.Dispatch<React.SetStateAction<boolean>>;
  isClosable?: boolean;
}
const OTPVerifyModalV2 = ({
  updatedPhone,
  showPhoneOtpModal,
  setShowUpdatePhoneModal,
  setShowPhoneOtpModal,
  isClosable,
}: Props) => {
  const onOTPModalClose = () => {
    setShowPhoneOtpModal(false);
    refetchProfile();
  };
  const { data: profile, refetch: refetchProfile, isLoading: profileLoading } = useProfile();
  const handlePhoneEditClick = () => {
    setShowPhoneOtpModal(false);
    setShowUpdatePhoneModal(true);
  };
  const snackbar = useSnackBar();
  const { mutate: sendPhoneOtpMutate, isLoading: sendPhoneOtpLoading } = useMutation(
    sendPhoneOtpV2,
    {
      onSuccess: (data) => {
        snackbar.success("Otp sent on mobile!");
        if (!showPhoneOtpModal) {
          setShowPhoneOtpModal(true);
        }
        setShowUpdatePhoneModal(false);
      },
      onError: (error) => {
        const e = axiosErrorHandler(error);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("Something went wrong");
        }
      },
    }
  );
  const { mutate: verifyPhoneOtpMutate, isLoading: verifyPhoneOtpLoading } = useMutation(
    verifyPhoneOtpV2,
    {
      onSuccess: (data) => {
        snackbar.success("Phone verified successfully!");
        if (showPhoneOtpModal) {
          setShowPhoneOtpModal(false);
        }
        setShowUpdatePhoneModal(false);

        refetchProfile();
      },
      onError: (error) => {
        const e = axiosErrorHandler(error);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("Something went wrong");
        }
      },
    }
  );

  return (
    <Box>
      <OTPModal
        isOpen={showPhoneOtpModal}
        onClose={() => {
          // console.log("clearing timers due to otp modal closed!");
          onOTPModalClose();
        }}
        title={"Phone Verification"}
        primaryText={`Please enter the OTP to verify your phone number`}
        secondaryText={
          <PhoneEdit
            handlePhoneEditClick={handlePhoneEditClick}
            phone={updatedPhone ? updatedPhone : profile?.phone_number}
          />
        }
        otpLength={6}
        timeGap={60}
        onComplete={(value) => {
          verifyPhoneOtpMutate({
            otp_input: value,
          });
        }}
        onResendCallback={async () => {
          sendPhoneOtpMutate(profile?.phone_number || "");
        }}
        loading={profileLoading || sendPhoneOtpLoading || verifyPhoneOtpLoading}
        isClosable={isClosable}
      />
    </Box>
  );
};

export default OTPVerifyModalV2;
