import { Flex, Image, Text } from "@chakra-ui/react";

interface Props {
  boldText: string;
  imageUrl: string;
}

const OnboardingTopSection = ({ boldText, imageUrl }: Props) => {
  return (
    <Flex borderBottom={"1px solid #E5E5E5"} paddingBottom={"14px"} gap={3} flexDir={"column"} px={{ base: "16px", md: "24px" }}>
      <Text fontSize={16} fontWeight={500} lineHeight={"24px"} fontFamily={"inter"}>
        Your application for
      </Text>
      <Flex gap={3} alignItems={"center"}>
        <Image src={imageUrl} objectFit={"contain"} w={"58px"} h={"58px"} />
        <Text fontSize={18} fontWeight={700} fontFamily={"Poppins"} lineHeight={"28px"}>
          {boldText}
        </Text>
      </Flex>
    </Flex>
  );
};

export default OnboardingTopSection;
