import React from "react";

import { Box } from "@chakra-ui/react";
import ProfileFieldCard from "./ProfileFieldCard";
import { useProfile } from "../../../api/utils/hooks/useProfile";

const MonthYearGraduation = () => {
  const { data: profile } = useProfile();
  return (
    <Box>
      <ProfileFieldCard
        keyName="month-year-graduation"
        title={`${
          typeof profile?.graduation_month === "number" && profile?.graduation_month >= 0
            ? profile.graduation_month
            : "NA"
        } / ${
          profile?.graduation_year && profile?.graduation_year >= 0
            ? profile?.graduation_year
            : "NA"
        }`}
        heading="Month and Year of Graduation"
      />
    </Box>
  );
};

export default MonthYearGraduation;
