import { Box, Button, Heading, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import AlertInfo from "../../assets/images/counselling-session/alert-info.svg";
import Prepleaf from "../../assets/images/CoursePrograms/prepleaf-logo.svg";
import { COUNSELLING_TYPE } from "../../api/schemas/schema";
import { useLocation } from "react-router-dom";
import { getCounsellingIneligibleSourceType } from "../../utils/user";

interface Props {
  handleCounsellingRequest: (para: COUNSELLING_TYPE) => void;
  submitCounsellingRequestLoading: boolean;
}

const InEligibleUserModalContent = ({
  handleCounsellingRequest,
  submitCounsellingRequestLoading,
}: Props) => {
  const location = useLocation();
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box>
      <Image src={AlertInfo} alt="alert-icon" w={"56px"} h={"56px"} />
      <Heading as="h4" size="md">
        Unfortunately, you are not eligible for Masai
      </Heading>
      <Text mt={"8px"}>
        <span style={{ display: "inline" }}>However, you can apply to courses offered by </span>
        <img
          src={Prepleaf}
          width={isMobile ? "60px" : "72px"}
          height={"24px"}
          style={{ display: "inline", marginBottom: "-6px" }}
        />
      </Text>
      <Text mt={"6px"}>
        Connect with a team member for a counselling session by clicking the button below.
      </Text>
      <Box
        display={"flex"}
        gap={"12px"}
        justifyContent={isMobile ? "center" : "end"}
        mt={isMobile ? "10px" : "4px"}
      >
        <Button
          variant="primary"
          isDisabled={submitCounsellingRequestLoading}
          mr={"20px"}
          onClick={() => {
            const source = getCounsellingIneligibleSourceType(location?.pathname || "");
            handleCounsellingRequest(source);
          }}
        >
          Request Callback
        </Button>
      </Box>
    </Box>
  );
};

export default InEligibleUserModalContent;
