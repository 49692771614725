import { Box, Image, Button, Heading, Text, useBreakpointValue, Spinner } from "@chakra-ui/react";
import checkboxImage from "../../../../assets/images/checkboxImage.svg";
import { PaymentStatusType } from "../../../../api/schemas/schema";

interface Props {
  handleMasaiOnePayment: () => void;
  loading?: boolean;
  registrationPaymentStatus?: PaymentStatusType;
}
const RegistrationFeeCard = ({
  registrationPaymentStatus,
  handleMasaiOnePayment,
  loading,
}: Props) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const benefits = [
    "Get 2 attempts to clear MSAT",
    "Unlimited Practice tests",
    "Comprehensive assessment",
  ];
  const btnSize = useBreakpointValue<"md" | "lg">({
    base: "md",
    md: "lg",
  });
  return (
    <Box
      py={"32px"}
      px={"16px"}
      ml={{ lg: "48px" }}
      mt={{ base: "24px", lg: "0px" }}
      boxShadow={
        "0px -2px 11px -2px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);"
      }
      border={"1px solid #D9D9D9"}
      borderRadius={"16px"}
    >
      <Box>
        <Heading textAlign={"center"} as="h3">
          MSAT Registration fee
        </Heading>
        <Box mt={"14px"} mx="auto" justifyContent={"center"} display={"flex"} alignItems={"center"}>
          <Heading
            fontSize={"24px"}
            lineHeight={"32px"}
            fontWeight={700}
            as="s"
            mr={"6px"}
            color="ms-brick.500"
          >
            ₹ 1,000
          </Heading>
          <Heading color={"ms-blue.500"} as="h1">
            {" "}
            ₹ 499
          </Heading>
        </Box>
        <Box mt={"16px"} display={"grid"} gap="16px">
          {benefits.map((benefit) => (
            <Box key={benefit} display={"flex"} alignItems={"center"}>
              <Image src={checkboxImage} alt="payment" mr={"8px"} />
              <Text textStyle={"body1-md"}>{benefit}</Text>
            </Box>
          ))}
        </Box>
        <Button
          maxW="100%"
          w="100%"
          size={btnSize}
          variant="primary"
          mt={"ms-16"}
          textStyle={{ base: "btn-md", md: "btn-lg" }}
          onClick={() => {
            handleMasaiOnePayment();
          }}
          disabled={loading}
        >
          {registrationPaymentStatus === "FAILED"
            ? "Retry Payment"
            : registrationPaymentStatus === "PENDING"
            ? "Continue Payment"
            : "Proceed to Payment"}
          {loading && (
            <Spinner
              speed={"0.5s"}
              thickness={"2px"}
              size={"md"}
              position={"absolute"}
              color={"ms-blue.500"}
            />
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default RegistrationFeeCard;
