import React from "react";
import { Text, Image, Flex, Link } from "@chakra-ui/react";
import { FrequentlyAskedQuestion } from "../../../../assets/images";

import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import { useLocation } from "react-router-dom";
const FacingAnyIssues = ({
  isLineBreak,
  fromModal,
}: {
  isLineBreak?: boolean;
  fromModal?: boolean;
}) => {
  const location = useLocation();
  return (
    <Flex justifyContent={{ md: "space-between" }} flexDirection={{ base: "column", md: "row" }}>
      <Flex alignItems={"flex-start"}>
        <Image src={FrequentlyAskedQuestion} alt="contact us" mr={"ms-8"} mt={"ms-6"} />
        <Text textStyle={"body2-md"} color={"ms-grey.700"}>
          Facing any issues? {isLineBreak && <br />}
          <Link
            isExternal={true}
            href="https://zoom.us/meeting/register/tJMlfu-prTIqE9C1Ol8PyAxAmDQDbV6BtCD-#/registration"
            color="ms-blue.500"
            textDecoration={"underline"}
            onClick={() => {
              const eventName = fromModal ? "dialog_box_join_q&a_session" : "join_q&a_session";
              sendTrackingEvent({
                event: eventName,
                eventLabel: location.pathname,
              });
              // fromModal
              //   ? clevertap.fireEvent(CleverTapEvents.click_contact_us, {
              //       screen_name: ScreenNames.onboarding,
              //       button_text: "Join our Q&A session",
              //       button_type: "link",
              //       modal_name: ModalNames.onboardingLinks,
              //     })
              //   : clevertap.fireEvent(CleverTapEvents.click_contact_us, {
              //       screen_name: ScreenNames.onboarding,
              //       button_text: "Join our Q&A session",
              //       button_type: "link",
              //       screen_position: "mandatory join session banner",
              //     });
            }}
          >
            Join our Q&A session
          </Link>{" "}
          Tuesday & Friday, 5 pm to 6 pm
        </Text>
      </Flex>
    </Flex>
  );
};

export default FacingAnyIssues;
