import { Flex, Text, HStack, Box, useClipboard, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { LinkedinShareButton, FacebookShareButton, TwitterShareButton } from "react-share";
import { LinkedIn, Facebook, Twitter } from "../../../../assets/icons/components";
import useCleverTap, { ButtonTypes, CleverTapEvents } from "../../../../clevertap/useCleverTap";
import { Link } from "./Link";
import { sendTrackingEvent } from "../../../../components/common/utils/gtm";

export const Share = ({
  linkedInUrl,
  facebookUrl,
  twitterUrl,
  copyUrl,
  title,
  hashtag,
  screen_name,
  gtmEvents,
}: {
  linkedInUrl: string;
  facebookUrl: string;
  twitterUrl: string;
  copyUrl?: string;
  hashtag?: string;
  title: string;
  screen_name: string;
  gtmEvents?: {
    [key: string]: string;
  };
}) => {
  const clevertap = useCleverTap()[0];

  const { hasCopied, onCopy } = useClipboard(copyUrl ? copyUrl : "");

  const [toastText, setToastText] = useState("");
  const toast = useToast();
  // clever tap
  const onShareTrack = (btnText: string, sequence: number) => {
    const gtm_event = gtmEvents ? gtmEvents[btnText] : "";
    sendTrackingEvent({
      event: gtm_event,
      eventLabel: location.pathname,
    });
  };
  useEffect(() => {
    if (hasCopied)
      toast({
        position: "top",
        isClosable: true,
        render: () => (
          <Flex
            width={"140px"}
            height={"48px"}
            borderRadius={"ms-8"}
            justifyContent={"center"}
            color="white"
            p={3}
            bg="ms-red.900"
          >
            {toastText}
          </Flex>
        ),
      });
  }, [hasCopied]);
  return (
    <Flex textAlign={"center"} gap="1rem" flexDir={"column"}>
      <Text fontSize="sm" fontWeight={600} p="ms-8">
        Share with your friends!
      </Text>
      <HStack spacing={"ms-24"} justify={"center"} mb="40px">
        {/* TODO: get URLS  */}
        <LinkedinShareButton url={linkedInUrl} onClick={() => onShareTrack("linkedin", 1)}>
          <LinkedIn boxSize="24px" />
        </LinkedinShareButton>
        <FacebookShareButton
          url={facebookUrl}
          hashtag={hashtag}
          onClick={() => onShareTrack("facebook", 2)}
        >
          <Facebook boxSize="24px" />
        </FacebookShareButton>
        <TwitterShareButton
          title={title + "\n"}
          url={twitterUrl}
          onClick={() => onShareTrack("twitter", 3)}
        >
          <Twitter boxSize="24px" />
        </TwitterShareButton>
        {copyUrl && (
          <Box
            onClick={() => {
              setToastText("Link Copied!");
              onCopy();
              onShareTrack("copyUrl", 4);
            }}
            cursor={"pointer"}
          >
            <Link boxSize="6" />
          </Box>
        )}
      </HStack>
    </Flex>
  );
};
