import { Box, Heading, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useContext } from "react";
import { ProgramType } from "..";
import { AppContext } from "../../../context/ApplicationContext";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { isCourseProgramsIntersectsProfilePrograms } from "../../../components/common/utils/isProgramPresent";
interface Props {
  type?: ProgramType;
}
const InstructionsWelcome = ({ type }: Props) => {
  const state = useContext(AppContext)[0];
  const application = state.applicationData;
  dayjs.extend(advancedFormat);
  const courseData = {
    course: application?.batch_campus.batch.course.name,
    start_date: dayjs(application?.batch_campus.batch.start_date).format("Do MMM YYYY"),
    application_end_date: dayjs(application?.batch_campus.batch.application_end_date).format(
      "Do MMM YYYY"
    ),
    course_type: application?.batch_campus.batch.course.course_type,
  };

  return (
    <Box>
      <Heading as="h4">Welcome to the Masai School Coding Test</Heading>
      <Box mt={"ms-8"} d={"flex"} flexDirection={{ base: "column", md: "row" }}>
        <Box display={"flex"} mr={"ms-16"}>
          <Text textStyle={"body2-md"}>Course: </Text>
          <Box display={"flex"} flexDirection={{ base: "column", md: "row" }}>
            <Text textStyle={"body2-md"} ml={"ms-4"} color={"ms-blue.500"}>
              {isCourseProgramsIntersectsProfilePrograms(
                state.applicationData?.batch_campus.batch?.course?.course_programs,
                [
                  {
                    id: 3,
                    name: "MasaiX",
                  },
                ]
              )
                ? "MasaiX:"
                : "Scholar:"}{" "}
              {courseData.course}
            </Text>
            <Text textStyle={"body2-md"} color={"ms-cyan.500"} ml={"ms-4"}>
              ({courseData?.course_type === "PART_TIME" ? "Part Time" : "Full Time"})
            </Text>
          </Box>
        </Box>

        {courseData.course !== "Programming: Advanced" && (
          <Box mt={{ base: "ms-8", md: "0px" }} mr={"ms-16"} display={"flex"}>
            <Text textStyle={"body2"}>Course starts on </Text>
            <Text ml={"ms-4"} textStyle={"body2-md"}>
              {courseData.start_date}
            </Text>
          </Box>
        )}

        <Box display={{ base: "none", md: "block" }} mr={"ms-16"} border="1px solid #3B3435"></Box>
        <Box mt={{ base: "ms-8", md: "0px" }} display={"flex"}>
          <Text textStyle={"body2"}>
            Last date to attempt {type === "scholar" ? "Scholarship Test" : "Coding Test"}:
          </Text>
          <Text ml={"ms-4"} textStyle={"body2-md"}>
            {courseData.application_end_date}
          </Text>
        </Box>
      </Box>
      <Box
        display={{ base: "none", md: "block" }}
        mt={"ms-12"}
        mb={"ms-24"}
        border="1px solid #F3F2F2"
      ></Box>
    </Box>
  );
};

export default InstructionsWelcome;
