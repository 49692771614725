import { Box, Text, useBreakpointValue } from "@chakra-ui/react";
import Lottie from "react-lottie-player";
import successLottie from "../../assets/successLottie.json";
import { useLastAssessment } from "../../api/utils/hooks/useAssessment";

interface Props {
  isMasaiCoursePrefered?: boolean;
}

const IITGResultBanner = ({ isMasaiCoursePrefered }: Props) => {
  const isTablet = useBreakpointValue({ base: true, xl: false });
  const { data: lastAssessment } = useLastAssessment();

  return (
    <>
      <Box
        position="absolute"
        isolation="isolate"
        top={{ base: "-70.829px", xl: "-80.829px" }}
        left={{ xl: "-80.829px" }}
        right={{ base: "-70.829px", xl: "0px" }}
        bg="#3470E4"
        h={{ base: "162px", xl: "188px" }}
        w={{ base: "162px", xl: "188px" }}
        rounded="100%"
        display="flex"
        justifyContent={{ xl: "end" }}
        alignItems="end"
        p="16px"
      >
        <Box
          bg="#0B2352"
          w="50%"
          h="50%"
          position="absolute"
          rounded="100%"
          top="24%"
          left="24%"
          opacity="0.2"
        ></Box>

        <Lottie
          loop
          animationData={successLottie}
          play
          style={{ width: "100%", maxWidth: isTablet ? "68px" : "110px" }}
        />
      </Box>

      <Box
        maxW={{ base: "80%", xl: "100%" }}
        textAlign={{ base: "left", xl: "center" }}
        pt={{ base: "16px", xl: "51px" }}
        px={{ base: "12px" }}
      >
        <Text textStyle={{ base: "h6", md: "h3" }} color="#3470E4">
          Benchmarking Test Result
        </Text>
        <Text mt="8px" textStyle={{ base: "body1", md: "h6" }}>
          {lastAssessment?.eligible_courses?.length && lastAssessment?.eligible_courses?.length >= 1
            ? "Congratulations! Your scores show that you're ready for this course."
            : "Your scores show that you are falling slightly below our direct admission threshold. Nevertheless, continue to complete your Onboarding."}
        </Text>
      </Box>
    </>
  );
};

export default IITGResultBanner;
