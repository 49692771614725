import React, { useEffect, useMemo, useState } from "react";
import { ActionMeta, CreatableSelect, SingleValue } from "chakra-react-select";
import { get, map, size } from "lodash";

// import styles from "./College.module.css";
import { GetCollegeType } from "../../api/schemas/schema";
import { getColleges } from "../../api/utils/api";
import { Box } from "@chakra-ui/react";
import { axiosErrorHandler } from "../../api/utils/error";

const namePossilitiesCache: {
  [name: string]: {
    keywords: {
      [abbreviation: string]: boolean;
    };
    mkl: number;
  };
} = {};

const createCacheForName = (name: string) => {
  const keywords: {
    [abbreviation: string]: boolean;
  } = {};
  const initialChars = name
    .split(" ")
    .filter((word) => word[0] && isCap(word[0]))
    .map((word) => word[0].toLowerCase());
  for (let i = 1; i <= initialChars.length; i++) {
    keywords[initialChars.slice(0, i).join("")] = true;
  }
  namePossilitiesCache[name] = {
    keywords,
    mkl: initialChars.length,
  };
};

const isCap = (char: string) => {
  const code = char.charCodeAt(0);
  return code >= 65 && code <= 90;
};

const isSubSequence = (name: string, inputValue: string) => {
  const inputValueLength = inputValue.length;
  let matchedLength = 0;
  for (let i = 0; i < name.length && matchedLength < inputValueLength; i++) {
    if (name[i] === inputValue[matchedLength]) {
      matchedLength += 1;
    }
  }
  return matchedLength === inputValueLength;
};

const calculateMatch = (item: { name: string; id: number }, inputValue: string) => {
  let match = 0;
  const name = item.name;
  const lName = item.name.toLowerCase();
  // const firstKeyword: boolean = inputValue.split(" ").length > 1;
  const fullKeyword = inputValue.replace(/\s/g, "");
  if (!inputValue || lName === "other") {
    match = 0.10001;
  }

  if (!namePossilitiesCache[name]) {
    createCacheForName(name);
  }
  // keyword search
  if (namePossilitiesCache[name].keywords[fullKeyword]) {
    match += 0.8 * (inputValue.length / namePossilitiesCache[name].mkl);
  } else {
    // if multiple tokens
    // if (firstKeyword && namePossilitiesCache[name].keywords[firstKeyword]) {
    //   match += 0.3 * (inputValue.length / namePossilitiesCache[name].mkl);
    // }
  }

  // substring search
  if (lName.includes(inputValue)) {
    const foundAt = lName.indexOf(inputValue);
    // substring match
    match += 0.2;
    // score based on substring match position
    // (foundAt + 1) is used because lName.length is not 0 based
    match += 0.06 * (1 - (foundAt + 1) / lName.length);
    // if it matches start of a word
    // const bb = foundAt === 0 || lName[foundAt - 1] === " "
    // match += 0.04 * (bb);
  }

  // subsequence search
  if (isSubSequence(lName, inputValue)) {
    match += 0.15 + 0.05 * (inputValue.length / lName.length);
  }

  return {
    item,
    match,
  };
};

interface SelectCollegeProps {
  value?: { id: number | null; name: string };
  onChange: (college: { id: number | null; name: string }) => void;
  isDisabled?: boolean;
  inputSize?: "lg" | "md" | "sm";
}

// eslint-disable-next-line react/prop-types
function SelectCollege({
  value: college,
  onChange: setCollege,
  isDisabled,
  inputSize,
}: SelectCollegeProps) {
  // const { data: verifiedCollegeData, isFetching: isFetchingVerified } = useQuery(
  //   ["college-list", "verified"],
  //   () => userApi.listColleges({ isVerified: true }),
  //   {
  //     staleTime: 1e8,
  //   }
  // );
  // const { data: nonVerifiedCollegeData } = useQuery(
  //   ["college-list", "non-verified"],
  //   () => userApi.listColleges({ isVerified: false }),
  //   {
  //     staleTime: 1e8,
  //   }
  // );
  // get colleges
  useEffect(() => {
    getCollegesList();
  }, []);
  const [collegeList, setCollegeList] = useState<GetCollegeType[]>();
  const getCollegesList = () => {
    getColleges()
      .then((res) => {
        console.log("Res: ", res);
        setCollegeList(res);
      })
      .catch((err) => {
        const e = axiosErrorHandler(err);
        if (typeof e === "object" && "message" in e) {
          // snackbar.error(e.message);
        } else {
          // snackbar.error("something went wrong");
        }
      });
  };

  const verifiedColleges = useMemo(
    () => (collegeList ? collegeList.filter((college) => college.is_verified === true) : []),
    [collegeList]
  );

  const nonVerifiedColleges = useMemo(
    () => (collegeList ? collegeList.filter((college) => college.is_verified === false) : []),
    [collegeList]
  );
  // const nonVerifiedColleges = useMemo(
  //   () => (nonVerifiedCollegeData ? nonVerifiedCollegeData.items : []),
  //   [nonVerifiedCollegeData]
  // );

  const [searchQuery, setSearchQuery] = useState("");
  const formattedInputValue = searchQuery.replace(/\s+/g, " ").toLowerCase();
  const selectedCollegeName = useMemo(() => get(college, "name"), [college]);
  const selectedCollegeId = useMemo(() => get(college, "id"), [college]);
  const selectedValue = useMemo(() => ({ label: selectedCollegeName, value: selectedCollegeId }), [
    selectedCollegeId,
    selectedCollegeName,
  ]);
  useEffect(() => {
    console.log("Selected value: ", selectedValue);
  }, [selectedValue]);
  const handleChange = (
    item: SingleValue<{ label: string; value: number; __isNew__?: boolean }>,
    actionMeta: ActionMeta<{ label: string; value: number }>
  ) => {
    if (!item) return;
    setSearchQuery("");
    if (item?.__isNew__) {
      setCollege({ name: item.label, id: null });
    } else {
      setCollege({ id: item.value, name: item.label });
    }
  };

  const verifiedOptions = useMemo(
    () =>
      map(verifiedColleges, (item) => calculateMatch(item, formattedInputValue))
        .filter((i) => i.match >= 0.1)
        .sort((a, b) => b.match - a.match)
        .map((i) => i.item)
        .map((item) => ({
          label: item.name,
          value: item.id,
        })),
    [formattedInputValue, verifiedColleges]
  );

  const nonVerifiedOptions = useMemo(() => {
    return map(nonVerifiedColleges, (item) => calculateMatch(item, formattedInputValue))
      .filter((i) => i.match >= 0.1)
      .sort((a, b) => b.match - a.match)
      .map((i) => i.item)
      .map((item) => ({
        label: item.name,
        value: item.id,
      }));
  }, [formattedInputValue, nonVerifiedColleges]);

  const allOptions = useMemo(() => {
    if (size(verifiedOptions) === 0) {
      return nonVerifiedOptions;
    }
    return verifiedOptions;
  }, [nonVerifiedOptions, verifiedOptions]);

  return (
    <Box cursor={"pointer"}>
      <CreatableSelect
        // className={styles.test}
        size={inputSize}
        filterOption={() => true}
        onChange={handleChange}
        onInputChange={setSearchQuery}
        options={allOptions}
        value={
          college?.name &&
          typeof college.name === "string" &&
          college.id &&
          typeof college.id === "number"
            ? { label: college.name, value: college.id }
            : undefined
        }
        loadingMessage={() => "Fetching your college..."}
        openMenuOnClick={size(searchQuery) >= 3}
        useBasicStyles
        selectedOptionColor={"ms-blue.50"}
        classNamePrefix="react-select"
        maxMenuHeight={200}
        placeholder={"Search college name..."}
        isDisabled={isDisabled}
      />
    </Box>
  );
}

export default SelectCollege;
