import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const IndianRupee = (props: Props) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M10.8283 17.5L5.51463 11.6667V10H8.28013C8.97809 10 9.5807 9.76028 10.088 9.28083C10.5947 8.80194 10.9008 8.20833 11.0061 7.5H4.72449V5.83333H10.7691C10.5452 5.34722 10.2128 4.94778 9.77192 4.635C9.33049 4.32278 8.83323 4.16667 8.28013 4.16667H4.72449V2.5H14.2062V4.16667H11.6382C11.8226 4.40278 11.9872 4.65972 12.1321 4.9375C12.2769 5.21528 12.3889 5.51389 12.4679 5.83333H14.2062V7.5H12.6062C12.5008 8.68056 12.0399 9.67 11.2234 10.4683C10.4069 11.2672 9.42583 11.6667 8.28013 11.6667H7.70727L13.021 17.5H10.8283Z"
      fill="currentColor"
    />
  </Icon>
);

export default IndianRupee;
