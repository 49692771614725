import { Box, Heading, Text, Button, Image, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

import { ModalTemplate } from "../../../../../components/common/general";
import { useLocation } from "react-router-dom";

import FellowshipBannerImage from "../../../../../assets/images/CoursePrograms/FellowshipDesktopBorder.svg";
import FellowshipBannerImageMobile from "../../../../../assets/images/CoursePrograms/FellowshipDesktopBorderMobile.svg";

import ViewScholarShipDetailsModal from "./ViewScholarShipDetailsModal";
import { sendTrackingEvent } from "../../../../../components/common/utils/gtm";

interface Props {
  textElement?: JSX.Element;
  hideButtons?: boolean;
  handleConfirm: () => void;
}
const ApplyForFellowship = ({ textElement, hideButtons, handleConfirm }: Props) => {
  const [viewDetailsModal, setViewDetailsModal] = React.useState<boolean>(false);

  const location = useLocation();
  const headingSize = useBreakpointValue<"h6" | "h3">({
    base: "h6",
    lg: "h3",
  });
  const image = useBreakpointValue<string>({
    base: FellowshipBannerImageMobile,
    lg: FellowshipBannerImage,
  });
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <>
      <Box
        display={"flex"}
        borderRadius={"ms-16"}
        pl={{ base: "ms-12", md: "ms-24" }}
        border="1px solid #D9D9D9"
        justifyContent={"space-between"}
      >
        <Box pt={{ base: "ms-12", md: "ms-16" }} mr={{ base: "ms-8", md: "ms-32" }} pb={"ms-8"}>
          <Heading color={"ms-blue.500"} as={headingSize}>
            Prepleaf Scholarship
          </Heading>
          {textElement ? (
            textElement
          ) : (
            <Text mt={"ms-8"} textStyle={{ base: "caption", lg: "body1" }}>
              Apply for Prepleaf Scholarship, clear the eligibility test and avail upto{" "}
              <Text
                display={"inline"}
                color={"ms-blue.500"}
                textStyle={{ base: "caption", lg: "body1-md" }}
                fontWeight={{ base: "600", lg: "700" }}
              >
                75% scholarship
              </Text>{" "}
              on the introductory prices of Scholar Program, based on your performance in the test.
            </Text>
          )}

          {!hideButtons && (
            <Box
              alignItems={"center"}
              mt={{ base: "ms-12", md: "ms-8" }}
              d={"flex"}
              justifyContent={"space-between"}
            >
              <Text
                onClick={() => {
                  sendTrackingEvent({
                    event: "sp_pp_view_masai_fellowship_view_more_details",
                    eventLabel: location.pathname,
                  });
                  setViewDetailsModal(true);
                }}
                textDecoration={"underline"}
                color={"ms-blue.500"}
                textStyle={{ base: "caption", md: "body1" }}
                cursor={"pointer"}
              >
                View More Details
              </Text>

              <Button
                size={isMobile ? "sm" : "md"}
                onClick={() => {
                  sendTrackingEvent({
                    event: "sp_pp_masai_fellowship_apply_now",
                    eventLabel: location.pathname,
                  });
                  handleConfirm();
                }}
                variant={"secondary"}
              >
                Apply now
              </Button>
            </Box>
          )}
        </Box>
        <Image
          alignSelf={{ base: "flex-start", lg: "center" }}
          // position={{ base: "absolute", lg: "relative" }}
          // right={0}
          src={image}
          alt="Scholarship"
        />
      </Box>

      <ModalTemplate
        title=""
        isOpen={viewDetailsModal}
        onClose={() => {
          sendTrackingEvent({
            event: "sp_pp_view_details_db_x",
            eventLabel: location.pathname,
          });
          setViewDetailsModal(false);
        }}
        showCloseButtonIcon={true}
        maxWidth={"740px"}
        marginX={2}
        scrollBehavior="inside"
      >
        <ViewScholarShipDetailsModal
          handleConfirm={handleConfirm}
          setViewDetailsModal={setViewDetailsModal}
        />
      </ModalTemplate>
    </>
  );
};

export default ApplyForFellowship;
