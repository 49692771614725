import { Box, Button, Image } from "@chakra-ui/react";
import React from "react";

const JoinCommunity = () => {
  return (
    <Box border={"1px solid #E5E5E5"} maxW={{ md: "330px" }} w={"full"} h="fit-content" overflow="hidden" rounded={"8px"} pos={"relative"}>
      <Image src="https://masai-website-images.s3.ap-south-1.amazonaws.com/ezgif_5_065d0120a7_05e0fb8704.webp" />
      <Button
        size="sm"
        textTransform={"uppercase"}
        pos={"absolute"}
        w={"max-content"}
        right={"-20px"}
        left={0}
        bottom={"20px"}
        mx={"auto"}
        border={"1px"}
        borderColor={"#3470E4"}
        color={"#3470E4"}
        bg={"white"}
        as={"a"}
        href="https://shorturl.at/npFP5"
        target="_blank"
      >
        Join CommuniTy
      </Button>
    </Box>
  );
};

export default JoinCommunity;
