import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { AppContext } from "../../../context/ApplicationContext";
import { ModalTemplate } from "../../common/general";
import BookADemo from "./BookADemo";
import BookADemoInterviewBookingModal from "./CalendlyBookingModal";

type InitPorp = {
  name: string;
  email_address: string;
  in_college: number | null;
  graduation_year: string;
  college_id: number | null;
  phone_number: string;
};

const BookADemoModal = ({
  setShowModal,
  showModal,
  phone = null,
  startingStep = 1,
  source,
}: {
  setShowModal: (t: boolean) => void;
  showModal: boolean;
  phone?: string | null;
  startingStep: number;
  source?: string;
}) => {
  const state = useContext(AppContext)[0];
  const [formStep, setFormStep] = useState(0);
  const [formData, setFormData] = useState<InitPorp | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(phone);
  const [openCalendlyModal, setOpenCalendlyModal] = useState<boolean>(false);
  const initValues = {
    name: state.profileData?.name || "",
    email_address: state.profileData?.email || "",
    in_college:
      state.profileData?.professional_status === "CURRENTLY_STUDING" &&
      state.profileData?.college?.id
        ? 1
        : state.profileData?.professional_status && state.profileData?.college?.id
        ? 0
        : null,
    graduation_year:
      state.profileData?.graduation_year && state.profileData?.graduation_year !== "0"
        ? state.profileData?.graduation_year
        : "",
    college_id: state.profileData?.college?.id || null,
    phone_number: state.profileData?.phone_number || "",
    source,
  };

  useEffect(() => {
    setFormStep(startingStep);
    setPhoneNumber(phone);
  }, [startingStep, phone]);

  useEffect(() => {
    if (
      state.profileData?.name &&
      state.profileData?.email &&
      (state.profileData?.professional_status === "CURRENTLY_STUDING" ||
        state.profileData?.professional_status === "CURRENTLY_WORKING" ||
        state.profileData?.professional_status === "NEITHER_STUDYING_NOR_WORKING") &&
      state.profileData?.college?.id &&
      state.profileData?.graduation_year &&
      state.profileData?.college?.id &&
      state.profileData?.phone_number &&
      showModal
    ) {
      setOpenCalendlyModal(true);
      setShowModal(false);
    } else if (showModal) {
      setOpenCalendlyModal(false);
    }
  }, [showModal]);

  useEffect(() => {
    console.log("formData:", formData);
  }, [formData]);

  return (
    <>
      <ModalTemplate
        title=""
        isCentered={formStep !== 3}
        showFooterContent={false}
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setFormStep(startingStep);
          setPhoneNumber("");
        }}
        paddingX={"36px"}
        paddingY={"36px"}
        maxWidth="470px"
        marginX={2}
        borderRadius="10px"
        showCloseButtonIcon={false}
        scrollBehavior={"outside"}
      >
        <BookADemo
          phoneNumber={phoneNumber || ""}
          closeModal={() => {
            setShowModal(false);
            setFormStep(startingStep);
          }}
          formData={formData}
          setFormData={setFormData}
          nextStep={() => {
            setShowModal(false);
            setOpenCalendlyModal(true);
          }}
        />
      </ModalTemplate>

      {openCalendlyModal && (
        <BookADemoInterviewBookingModal
          onClose={() => {
            setOpenCalendlyModal(false);
          }}
          open={openCalendlyModal}
          onSuccess={() => setOpenCalendlyModal(false)}
          data={
            formData
              ? {
                  ...formData,
                  email: formData.email_address,
                  in_college: formData?.in_college === 1 ? true : false,
                  college_id: formData?.college_id ? formData.college_id : -1,
                  source,
                }
              : {
                  ...initValues,
                  email: initValues.email_address,
                  in_college: initValues?.in_college === 1 ? true : false,
                  college_id: initValues?.college_id ? initValues.college_id : -1,
                }
          }
        />
      )}
    </>
  );
};

export default BookADemoModal;
