import { Box, Heading, SimpleGrid, Skeleton } from "@chakra-ui/react";

interface Props {
  title: string;
  children: JSX.Element[] | JSX.Element;
  loading: boolean;
}

const CardsLayout = ({ title, children, loading }: Props) => {
  return (
    <Box px={["ms-16", "ms-24"]}>
      <Skeleton isLoaded={!loading}>
        <Heading as="h3" mt="ms-24" mb="ms-16">
          {title}
        </Heading>
        <SimpleGrid
          templateColumns={{ lg: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }}
          spacing={["ms-16", "ms-24"]}
          w="fit-content"
          mb="ms-24"
        >
          {children}
        </SimpleGrid>
      </Skeleton>
    </Box>
  );
};

export default CardsLayout;
