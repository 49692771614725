import { SmallCloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  Skeleton,
  SkeletonText,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useCalendly } from "../../../hooks/useCalendly";
import { sendTrackingEvent } from "../../common/utils/gtm";

const BookADemoInterviewBookingModal = ({
  onClose,
  open,
  onSuccess,
  data,
}: {
  onClose: () => void;
  onSuccess: () => void;
  open: boolean;
  data: {
    name: string;
    email: string;
    graduation_year: string;
    in_college: boolean;
    college_id: number;
    phone_number: string;
    source?: string;
  };
}) => {
  const [calendlyURL, setCalendlyURL] = useState<string | null>();
  const calendly = useCalendly();
  const toast = useToast();
  const [listenerAdded, setListenerAdded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const isMounted = useRef<boolean>(false);

  const generateOneTimeLink = async (): Promise<string | null> => {
    try {
      const otlData = await calendly.generateOTLCAPBookADemo(data);
      sendTrackingEvent({
        event: "dashboard_book_demo_generate_one_time_link",
        eventLabel: location.pathname,
        capDemoSessionId: otlData?.id?.toString() || "",
      });
      const url = otlData?.one_time_link;
      console.log(`calendly one time link: ${url}`);
      return url || "";
    } catch (err) {
      toast({
        title: "Something went wrong!",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      onClose();
    }
    return null;
  };

  /** Injects the message listener for the iframe*/
  useEffect((): (() => void) => {
    const listener = (e: MessageEvent) => {
      if (e.data.event && e.data.event.indexOf("calendly") === 0 && e.data.payload.event) {
        createInterviewBooking(e.data.payload.event.uri);
      }
    };
    if (!listenerAdded) {
      window.addEventListener("message", listener);
      setListenerAdded(true);
    }
    return () => {
      // window.removeEventListener("message",listener)
    };
  }, [listenerAdded]);

  const createInterviewBooking = useDebouncedCallback(async (uri: string) => {
    try {
      const sessionsData = await calendly.createCAPDemoSession(
        uri,
        data?.phone_number,
        location.pathname
      );

      const sessions = sessionsData?.join_url;

      sendTrackingEvent({
        event: "dashboard_book_demo_create_session",
        eventLabel: location.pathname,
        capDemoSessionId: sessionsData?.id?.toString() || "",
      });

      if (!sessions) {
        toast({
          title: "Something went wrong, please try again!",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        onClose();
        onSuccess();
      } else {
        toast({
          title: "Session booked successfully",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        onClose();
        onSuccess();
      }
    } catch (err) {
      toast({
        title: "Something went wrong!",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
  }, 200);

  useEffect(() => {
    let head: HTMLHeadElement | null;
    let script: HTMLScriptElement;
    const date = Date.now().toString();
    (async (): Promise<void> => {
      if (!calendlyURL && open && data) {
        const calendlyUrl = await generateOneTimeLink();
        setCalendlyURL(calendlyUrl || null);
        head = document.querySelector("head");
        script = document.createElement("script");
        script.setAttribute("id", `${date}`);
        script.setAttribute("src", "https://assets.calendly.com/assets/external/widget.js");
        if (head) {
          head.appendChild(script);
        }
        script.addEventListener("load", () => {
          setTimeout(() => setLoading(false), 1500);
        });
      }
    })();
    return () => {
      document.getElementById(date)?.remove();
    };
  }, [calendlyURL, open]);
  const name = data?.name || "";
  const email = data?.email || "";

  return (
    <Modal
      isOpen={open}
      onClose={() => {
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent m="1rem" mt="2rem" borderRadius="ms-16" p="1rem" maxW="fit-content" h="auto">
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Heading as="h3">Need Assistance? Schedule a call with our Admissions Expert.</Heading>
          <SmallCloseIcon
            onClick={onClose}
            w="26px"
            h="26px"
            color="#6C6768"
            cursor={"pointer"}
            ml={"2"}
          />
        </Box>
        <Heading my="0.5rem" as="h6">
          Select a Date & Time
        </Heading>
        <Box overflow="scroll" h={{ base: "25rem", md: "50rem" }}>
          {loading && (
            <>
              <Skeleton h="5rem" my="1rem" isLoaded={!loading} />
              <Skeleton h="5rem" my="1rem" isLoaded={!loading} />
              <SkeletonText my="1rem" isLoaded={!loading} />
              <Skeleton my="1rem" h="1rem" isLoaded={!loading} />
            </>
          )}
          <Box
            h="full"
            w="full"
            as="div"
            className="calendly-inline-widget"
            data-url={calendlyURL + `?name=${name}&email=${email}`}
          ></Box>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default BookADemoInterviewBookingModal;
