import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce/lib";
import { ApplicationStatus } from "../api/schemas/schema";
import { getApplicationById } from "../api/utils/api";
import { SnackBar } from "../components/common/general";
import { isProgramPresent } from "../components/common/utils/isProgramPresent";
import { AppContext, ApplicationActionType } from "../context/ApplicationContext";

// use this hook inside /batch/:batchID/:applicationID routes only
// mapRoutesToStatus is a map of status to route
const mapRoutesToStatus = new Map<
  ApplicationStatus,
  (batchID: string, applicationID: string) => string
>();
mapRoutesToStatus.set(
  "PROFILE_UNFILLED",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/applicant-details`
);
mapRoutesToStatus.set(
  "INELIGIBLE",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/reject/ineligible`
);
mapRoutesToStatus.set(
  "METTL_TO_BE_TAKEN",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/msat-instructions`
);
mapRoutesToStatus.set(
  "METTL_STARTED",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/msat-instructions`
);
mapRoutesToStatus.set(
  "METTL_DESCISION_PENDING",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/msat-pending`
);
mapRoutesToStatus.set(
  "METTL_FAILED",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/reject/msat`
);
mapRoutesToStatus.set(
  "METTL_PASSED",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/msat-success`
);
mapRoutesToStatus.set(
  "INTERVIEW_BOOKING_PENDING",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/applicant-details`
);
mapRoutesToStatus.set(
  "INTERVIEW_BOOKED",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/applicant-details`
);
mapRoutesToStatus.set(
  "INTERVIEW_DECISION_PENDING",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/msat-pending`
);
mapRoutesToStatus.set(
  "ONBOARDING_PENDING",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/onboarding-pending`
);
mapRoutesToStatus.set(
  "ONBOARDING_STARTED",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/onboarding`
);
mapRoutesToStatus.set(
  "ONBOARDING_COMPLETE",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/onboarding`
);
mapRoutesToStatus.set(
  "FELLOW",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/onboarding`
);
mapRoutesToStatus.set(
  "FDN_FELLOW",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/onboarding`
);
mapRoutesToStatus.set(
  "ASYNC",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/onboarding`
);
mapRoutesToStatus.set(
  "ISA_SUCCESSFUL",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/onboarding`
);
mapRoutesToStatus.set(
  "APPLICATION_CLOSED",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/application-closed`
);

mapRoutesToStatus.set(
  "BATCH_CHANGE",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/application-closed`
);
mapRoutesToStatus.set(
  "DROP_OUT",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/application-closed`
);

mapRoutesToStatus.set(
  "DISCONTINUED",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/application-closed`
);
// New courses Status Redirect
mapRoutesToStatus.set(
  "PAYMENT_PENDING",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/instructions`
);

mapRoutesToStatus.set(
  "PAYMENT_SUCCESSFUL",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/payment-success`
);
mapRoutesToStatus.set(
  "PAYMENT_FAILED",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/instructions`
);

mapRoutesToStatus.set(
  "SCHOLARSHIP_TEST_TO_BE_TAKEN",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/scholar-msft-instructions`
);
mapRoutesToStatus.set(
  "SCHOLARSHIP_TEST_DECISION_PENDING",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/scholar-msft-pending`
);
mapRoutesToStatus.set(
  "SCHOLARSHIP_TEST_FAILED",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/scholar-msft-reject`
);
mapRoutesToStatus.set(
  "SCHOLARSHIP_TEST_PASSED",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/scholar-msft-success`
);
mapRoutesToStatus.set(
  "CODING_TEST_TO_TAKEN",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/coding-instructions`
);
mapRoutesToStatus.set(
  "CODING_TEST_STARTED",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/coding-instructions`
);
mapRoutesToStatus.set(
  "CODING_TEST_DESCISION_PENDING",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/coding-pending`
);
mapRoutesToStatus.set(
  "CODING_TEST_FAILED",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/coding-reject`
);
mapRoutesToStatus.set(
  "CODING_TEST_PASSED",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/coding-success`
);

mapRoutesToStatus.set(
  "INTERVIEW_BOOKING_PENDING",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/masaix-interview-pending`
);

mapRoutesToStatus.set(
  "INTERVIEW_BOOKED",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/masaix-interview-booked`
);
mapRoutesToStatus.set(
  "INTERVIEW_PASSED",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/masaix-interview-success`
);
mapRoutesToStatus.set(
  "INTERVIEW_FAILED",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/masaix-interview-reject`
);
mapRoutesToStatus.set(
  "APPLICATION_CREATED",
  (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/application-created`
);
// mapRoutesToStatus.set(
//   "PAYMENT_SUCCESSFUL",
//   (batchId, applicationSlug) => `/batch/${batchId}/${applicationSlug}/application-created`
// );

const getPath = (status: ApplicationStatus, batchId: string, applicationSlug: string) => {
  const pathFn = mapRoutesToStatus?.get(status);
  let path = "";
  if (typeof pathFn === "function") {
    path = pathFn(batchId, applicationSlug);
  }
  return path;
};

const useApplicationHook = () => {
  const [state, dispatch] = useContext(AppContext);
  const { profileData, applicationData } = state;
  const params = useParams() as { applicantID: string; batchID: string };
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(true);
  const snackbar = SnackBar();
  const fetchApplicationData = useDebouncedCallback(
    React.useCallback(
      async (slug: string) => {
        try {
          if (!profileData) return;
          // set applicationData back to null on fetch
          dispatch({
            type: ApplicationActionType.SET_APPLICATION,
            payload: {
              applicationData: null,
            },
          });
          const res = await getApplicationById({
            profile_slug: profileData?.slug,
            application_slug: slug,
          });

          // update data
          dispatch({
            type: ApplicationActionType.SET_APPLICATION,
            payload: {
              applicationData: res,
            },
          });
          // redirect to correct route
          const path = getRoute(
            res.status,
            res.batch_campus.batch.is_archived,
            res.batch_campus.batch.application_end_date
          );
          if (!path) history.replace("/");
          else history.replace(path);

          setLoading(false);
        } catch (err) {
          console.error(err);
          snackbar.error("Something went wrong!");
          history.push("/");
          setLoading(false);
        }
      },
      [profileData]
    ),
    200
  );

  useEffect(() => {
    if (params.applicantID && profileData?.slug) {
      fetchApplicationData(params.applicantID);
    }
    return () => {
      //
    };
  }, [params.applicantID, profileData]);

  useEffect(() => {
    if (state.fetchApplicationData) {
      fetchApplicationData(params.applicantID);
    }
  }, [state.fetchApplicationData]);

  // find the route to redirect to
  const getRoute = React.useCallback(
    (status: ApplicationStatus, isArchived: boolean, end_date: string) => {
      if (
        isProgramPresent("scholar", state.profileData?.programs) ||
        isProgramPresent("cap", state.profileData?.programs) ||
        isProgramPresent("masaix", state.profileData?.programs)
      ) {
        // TODO: Application close logic is pending
        if (
          // isArchived &&
          dayjs().isAfter(end_date) &&
          ![
            "ONBOARDING_COMPLETE",
            "ONBOARDING_STARTED",
            "ONBOARDING_PENDING",
            "FELLOW",
            "FDN_FELLOW",
            "ISA_SUCCESSFUL",
            "ASYNC",
            "PAYMENT_SUCCESSFUL",
          ].includes(status)
        ) {
          return getPath("APPLICATION_CLOSED", params.batchID, params.applicantID);
        }
        return getPath(status, params.batchID, params.applicantID);
      } else if (status) {
        // * redirect to application closed if batch is closed, and user is not in onboarding stages
        if (
          // isArchived &&
          dayjs().isAfter(end_date) &&
          ![
            "ONBOARDING_COMPLETE",
            "ONBOARDING_STARTED",
            "ONBOARDING_PENDING",
            "FELLOW",
            "FDN_FELLOW",
            "ISA_SUCCESSFUL",
            "ASYNC",
          ].includes(status)
        ) {
          return getPath("APPLICATION_CLOSED", params.batchID, params.applicantID);
        }
        // * redirecting to application details, if users mandatory profile details are not filled.
        // * Check if profile is filled
        if (state.profileData) {
          const isProfileCompleted = [
            state.profileData?.email,
            state.profileData?.phone_number,
            state.profileData?.graduation_year,
            state.profileData?.date_of_birth,
            state.profileData?.job_after_graduation,
            state.profileData?.gender,
            state.profileData.tenth_percentage,
            state.profileData.twelfth_percentage,
            state.profileData.ug_degree,
          ].every((el) => el !== "" && el !== null && el !== undefined);

          if (!isProfileCompleted) {
            return getPath("PROFILE_UNFILLED", params.batchID, params.applicantID);
          }
        }

        if (state.profileData) {
          const UGDegree = state.profileData?.ug_degree;
          if (
            (typeof UGDegree === "undefined" ||
              !["NA", "Not_Complete", "Drop_Out"].includes(UGDegree)) &&
            !state.profileData.college
          ) {
            return getPath("PROFILE_UNFILLED", params.batchID, params.applicantID);
          }
        }
        // * Check if application is closed or batch is archived
        return getPath(status, params.batchID, params.applicantID);
      }
    },
    [params, profileData]
  );

  return [
    { loading, applicationData },
    {
      fetchApplicationData,
    },
  ];
};

export default useApplicationHook;
