import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
  isActive?: boolean;
}
export const BottomTabCourses = (props: Props) => (
  <Icon viewBox={props.isActive ? "0 0 24 24" : "0 0 20 20"} {...props}>
    {props?.isActive ? (
      <>
        <path
          opacity="0.2"
          d="M19.5 3V18H6.75C6.15326 18 5.58097 18.2371 5.15901 18.659C4.73705 19.081 4.5 19.6533 4.5 20.25V5.25C4.5 4.65326 4.73705 4.08097 5.15901 3.65901C5.58097 3.23705 6.15326 3 6.75 3H19.5Z"
          fill="#3470E4"
        />
        <path
          d="M19.5 2.25H6.75C5.95435 2.25 5.19129 2.56607 4.62868 3.12868C4.06607 3.69129 3.75 4.45435 3.75 5.25V21C3.75 21.1989 3.82902 21.3897 3.96967 21.5303C4.11032 21.671 4.30109 21.75 4.5 21.75H18C18.1989 21.75 18.3897 21.671 18.5303 21.5303C18.671 21.3897 18.75 21.1989 18.75 21C18.75 20.8011 18.671 20.6103 18.5303 20.4697C18.3897 20.329 18.1989 20.25 18 20.25H5.25C5.25 19.8522 5.40804 19.4706 5.68934 19.1893C5.97064 18.908 6.35218 18.75 6.75 18.75H19.5C19.6989 18.75 19.8897 18.671 20.0303 18.5303C20.171 18.3897 20.25 18.1989 20.25 18V3C20.25 2.80109 20.171 2.61032 20.0303 2.46967C19.8897 2.32902 19.6989 2.25 19.5 2.25ZM18.75 17.25H6.75C6.22326 17.2493 5.70572 17.388 5.25 17.6522V5.25C5.25 4.85218 5.40804 4.47064 5.68934 4.18934C5.97064 3.90804 6.35218 3.75 6.75 3.75H18.75V17.25Z"
          fill="#3470E4"
        />
      </>
    ) : (
      <>
        <path
          opacity="0.2"
          d="M16.25 2.5V15H5.625C5.12772 15 4.65081 15.1975 4.29917 15.5492C3.94754 15.9008 3.75 16.3777 3.75 16.875V4.375C3.75 3.87772 3.94754 3.40081 4.29917 3.04917C4.65081 2.69754 5.12772 2.5 5.625 2.5H16.25Z"
          fill="white"
        />
        <path
          d="M16.25 1.875H5.625C4.96196 1.875 4.32607 2.13839 3.85723 2.60723C3.38839 3.07607 3.125 3.71196 3.125 4.375V17.5C3.125 17.6658 3.19085 17.8247 3.30806 17.9419C3.42527 18.0592 3.58424 18.125 3.75 18.125H15C15.1658 18.125 15.3247 18.0592 15.4419 17.9419C15.5592 17.8247 15.625 17.6658 15.625 17.5C15.625 17.3342 15.5592 17.1753 15.4419 17.0581C15.3247 16.9408 15.1658 16.875 15 16.875H4.375C4.375 16.5435 4.5067 16.2255 4.74112 15.9911C4.97554 15.7567 5.29348 15.625 5.625 15.625H16.25C16.4158 15.625 16.5747 15.5592 16.6919 15.4419C16.8092 15.3247 16.875 15.1658 16.875 15V2.5C16.875 2.33424 16.8092 2.17527 16.6919 2.05806C16.5747 1.94085 16.4158 1.875 16.25 1.875ZM15.625 14.375H5.625C5.18605 14.3744 4.75477 14.49 4.375 14.7102V4.375C4.375 4.04348 4.5067 3.72554 4.74112 3.49112C4.97554 3.2567 5.29348 3.125 5.625 3.125H15.625V14.375Z"
          fill="currentColor"
        />
      </>
    )}
  </Icon>
);

export default BottomTabCourses;
