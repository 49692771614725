import { Box, Text } from "@chakra-ui/react";
import AlumniStats from "../../../components/V2/Alumni/AlumniStats";
import AlumniTestimonials from "../../../components/V2/Alumni/AlumniTestimonials";

const Alumni = () => {
  return (
    <Box p="12px 16px" pb={{ base: "72px", md: "12px" }} bg="#F2F6FF" h="full" minH="100vh">
      <Text textStyle="body1-md" mb="12px">
        Our Stats
      </Text>
      <AlumniStats />
      <Box mt="18px">
        <AlumniTestimonials />
      </Box>
    </Box>
  );
};

export default Alumni;
