import { ApplicationType, ProfileAssessment, ProfileType } from "../api/schemas/schema";
import { ProgramProfileType } from "../enums/ProgramType.enum";
import { PAPCourses, ScholorShipCourses } from "../pages/V2/Onboarding";

export interface ApplicationProgressData {
  iconUrl: string;
  courseName: PAPCourses | ProgramProfileType | ScholorShipCourses;
  courseId: string;
  applicationDeadline?: string | Date;
  currentStepName: string;
  currentStepNumber: number;
  totalSteps: number;
  btnText: string;
  onSubmit: () => void;
}

export const showApplicationProgressCard = (
  bestAssessment: ProfileAssessment,
  activeApplication: ApplicationType,
  profile: ProfileType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history: any,
  empowerHerBestAssessmentFor: ProfileAssessment,
  hasPaidRegistrationFees: boolean
): { showProgress: boolean; data: ApplicationProgressData | null } => {
  // This function is used to show the application progress card
  const hasFormFiled = profile && profile?.meta?.highest_qualification;
  const hasAssessment = bestAssessment && bestAssessment?.course_preference === 14;
  const hasActiveApplicationForDA = activeApplication && activeApplication?.course_id === 14;
  const hasOnbaordingCompletedForDA = activeApplication && activeApplication?.course_id === 14 && activeApplication?.status === "ONBOARDING_COMPLETE";
  const hasActiveApplicationForSD = activeApplication && activeApplication?.course_id === 12;
  const hasAssesmentCompleted = bestAssessment && ["ENDED", "GRADED"].includes(bestAssessment?.status?.toUpperCase() || "");

  const hasAssessmentForEmpowerHer = empowerHerBestAssessmentFor && empowerHerBestAssessmentFor?.course_preference === 20;
  const hasAssesmentCompletedForEmpowerHer = empowerHerBestAssessmentFor && ["ENDED", "GRADED"].includes(empowerHerBestAssessmentFor?.status?.toUpperCase() || "");
  const hasFormFiledForEmpowerHer = profile && profile?.meta?.familyIncome;
  const hasActiveApplicationForEmpowerHer = profile && profile?.meta?.hasMarkedEmpowerHerChoosen;
  const hasAcceptedDeclarationForEmpowerHer = profile && profile?.meta?.hasAcceptedDeclaration;
  const hasClearedMSATForEmpowerHer = empowerHerBestAssessmentFor && empowerHerBestAssessmentFor?.eligible_courses?.includes(20);

  if (hasFormFiled && hasActiveApplicationForSD) {
    return {
      showProgress: true,
      data: {
        iconUrl: "/img/revamp/courses/sd.svg",
        courseName: PAPCourses.SD,
        currentStepName: "Workspace",
        currentStepNumber: 3,
        totalSteps: 3,
        btnText: "Join",
        onSubmit: () => {
          history.push("courses/12/details");
        },
        courseId: "12",
      },
    };
  } else if (hasFormFiled && !hasActiveApplicationForDA) {
    return {
      showProgress: true,
      data: {
        iconUrl: "/img/revamp/courses/sd.svg",
        courseName: PAPCourses.SD,
        currentStepName: "Trial Period",
        currentStepNumber: 2,
        applicationDeadline: "5th August",
        totalSteps: 3,
        btnText: "Join Now",
        onSubmit: () => {
          history.push("courses/12/details");
        },
        courseId: "12",
      },
    };
  } else if (hasAssessment && hasActiveApplicationForDA && hasOnbaordingCompletedForDA) {
    return {
      showProgress: true,
      data: {
        iconUrl: "/img/revamp/courses/da.svg",
        courseName: PAPCourses.DA,
        currentStepName: "Orientation",
        currentStepNumber: 3,
        totalSteps: 4,
        btnText: "Join",
        onSubmit: () => {
          history.push("/onboarding/14");
        },
        courseId: "14",
      },
    };
  } else if (hasAssessment && hasActiveApplicationForDA && !hasOnbaordingCompletedForDA) {
    return {
      showProgress: true,
      data: {
        iconUrl: "/img/revamp/courses/da.svg",
        courseName: PAPCourses.DA,
        currentStepName: "Eligibility Form",
        currentStepNumber: 2,
        applicationDeadline: "15th August",
        btnText: "Continue",
        totalSteps: 4,
        onSubmit: () => {
          history.push("/onboarding/14");
        },
        courseId: "14",
      },
    };
  } else if (hasAssessment && !hasActiveApplicationForDA && !hasFormFiled) {
    return {
      showProgress: true,
      data: {
        iconUrl: "/img/revamp/courses/da.svg",
        courseName: PAPCourses.DA,
        currentStepName: "Clear MSAT",
        currentStepNumber: 1,
        applicationDeadline: "15th August",
        totalSteps: 4,
        btnText: hasAssesmentCompleted ? "Take MSAT" : "Continue MSAT",
        onSubmit: () => {
          history.push("/onboarding/14");
        },
        courseId: "14",
      },
    };
  }
  //   empower her flow
  else if (hasFormFiledForEmpowerHer && !hasAssesmentCompletedForEmpowerHer) {
    return {
      showProgress: true,
      data: {
        iconUrl: "/img/empower-her/secretary.svg",
        courseName: ScholorShipCourses.EMPOWER_HER,
        currentStepName: "Clear Assessment",
        currentStepNumber: 1,
        applicationDeadline: "5th August",
        totalSteps: 5,
        btnText: "Take MSAT",
        onSubmit: () => {
          history.push("/empowerHer-onboarding");
        },
        courseId: "20",
      },
    };
  } else if (hasAssessmentForEmpowerHer && !hasClearedMSATForEmpowerHer && hasFormFiledForEmpowerHer) {
    return {
      showProgress: true,
      data: {
        iconUrl: "/img/empower-her/secretary.svg",
        courseName: ScholorShipCourses.EMPOWER_HER,
        currentStepName: "Clear Assessment",
        currentStepNumber: 2,
        applicationDeadline: "5th August",
        totalSteps: 5,
        btnText: "Take MSAT",
        onSubmit: () => {
          history.push("/empowerHer-onboarding");
        },
        courseId: "20",
      },
    };
  } else if (hasClearedMSATForEmpowerHer && !hasActiveApplicationForEmpowerHer) {
    return {
      showProgress: true,
      data: {
        iconUrl: "/img/empower-her/secretary.svg",
        courseName: ScholorShipCourses.EMPOWER_HER,
        currentStepName: "Upload Documents",
        currentStepNumber: 3,
        applicationDeadline: "5th August",
        totalSteps: 5,
        btnText: "Documents",
        onSubmit: () => {
          history.push("/empowerHer-onboarding");
        },
        courseId: "20",
      },
    };
  } else if (hasActiveApplicationForEmpowerHer && !hasPaidRegistrationFees) {
    return {
      showProgress: true,
      data: {
        iconUrl: "/img/empower-her/secretary.svg",
        courseName: ScholorShipCourses.EMPOWER_HER,
        currentStepName: "Confirm Admission",
        currentStepNumber: 4,
        applicationDeadline: "5th August",
        totalSteps: 5,
        btnText: "Confirm Admission",
        onSubmit: () => {
          history.push("/empowerHer-onboarding");
        },
        courseId: "20",
      },
    };
  } else if (hasActiveApplicationForEmpowerHer && hasPaidRegistrationFees && !hasAcceptedDeclarationForEmpowerHer) {
    return {
      showProgress: true,
      data: {
        iconUrl: "/img/empower-her/secretary.svg",
        courseName: ScholorShipCourses.EMPOWER_HER,
        currentStepName: "Accept Scholarship Conduct",
        currentStepNumber: 5,
        applicationDeadline: "5th August",
        totalSteps: 5,
        btnText: "Accept Declaration",
        onSubmit: () => {
          history.push("/empowerHer-onboarding");
        },
        courseId: "20",
      },
    };
  } else if (hasActiveApplicationForEmpowerHer && hasPaidRegistrationFees) {
    return {
      showProgress: true,
      data: {
        iconUrl: "/img/empower-her/secretary.svg",
        courseName: ScholorShipCourses.EMPOWER_HER,
        currentStepName: "Admission Complete",
        currentStepNumber: 5,
        applicationDeadline: "5th August",
        totalSteps: 5,
        btnText: "Start Learning",
        onSubmit: () => {
          history.push("/empower-her");
        },
        courseId: "20",
      },
    };
  }

  return {
    showProgress: false,
    data: null,
  };
};

export const showApplicationProgressCardForIIT = (
  activeApplication: ApplicationType,
  profile: ProfileType,
  isPaymentDoneForIITG: boolean,
  isPaymentDoneForIITMandi: boolean,
  lastAssessment: ProfileAssessment,
  isRegisterationPaymentDone: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history: any
): { showProgress: boolean; data: ApplicationProgressData | null } => {
  // This function is used to show the application progress card
  const isOnboardingCompleted = activeApplication && activeApplication?.status === "ONBOARDING_COMPLETE";
  const isOrientationVideoWatched = profile?.meta && profile?.meta?.hasOrientationVideoWatched === "True";
  const isBenchmarkingDone = lastAssessment && ["ENDED", "GRADED"].includes(lastAssessment?.status?.toUpperCase());
  const isSemesterFeesPaid = (profile?.meta && profile?.meta?.selfPaid) || isRegisterationPaymentDone;

  if ((isPaymentDoneForIITMandi || isPaymentDoneForIITG) && isOrientationVideoWatched && isSemesterFeesPaid && isOnboardingCompleted && isBenchmarkingDone) {
    return {
      showProgress: true,
      data: {
        iconUrl: isPaymentDoneForIITG
          ? "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp"
          : "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT_M_81a2d8f496.webp",
        courseName: isPaymentDoneForIITG ? ProgramProfileType.IIT_GUWAHATI : ProgramProfileType.IIT_MANDI,
        currentStepName: "Admission Complete",
        currentStepNumber: 5,
        totalSteps: 5,
        btnText: "Start Learning",
        onSubmit: () => {
          history.push(isPaymentDoneForIITG ? "/mc-iitg-cs" : "/iit-mandi-ai-ml");
        },
        courseId: isPaymentDoneForIITG ? "17" : "18",
      },
    };
  } else if ((isPaymentDoneForIITMandi || isPaymentDoneForIITG) && isOrientationVideoWatched && isSemesterFeesPaid && isOnboardingCompleted && !isBenchmarkingDone) {
    return {
      showProgress: true,
      data: {
        iconUrl: isPaymentDoneForIITG
          ? "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp"
          : "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT_M_81a2d8f496.webp",
        courseName: isPaymentDoneForIITG ? ProgramProfileType.IIT_GUWAHATI : ProgramProfileType.IIT_MANDI,
        currentStepName: "Benchmarking Test",
        currentStepNumber: 4,
        applicationDeadline: "4th August",
        totalSteps: 5,
        btnText: "Take Test",
        onSubmit: () => {
          history.push(isPaymentDoneForIITG ? "/iitg-onboarding" : "/iit-onboarding");
        },
        courseId: isPaymentDoneForIITG ? "17" : "18",
      },
    };
  } else if ((isPaymentDoneForIITMandi || isPaymentDoneForIITG) && isOrientationVideoWatched && isSemesterFeesPaid && !isOnboardingCompleted) {
    return {
      showProgress: true,
      data: {
        iconUrl: isPaymentDoneForIITG
          ? "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp"
          : "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT_M_81a2d8f496.webp",
        courseName: isPaymentDoneForIITG ? ProgramProfileType.IIT_GUWAHATI : ProgramProfileType.IIT_MANDI,
        currentStepName: "Profile Details",
        currentStepNumber: 3,
        applicationDeadline: "4th August",
        totalSteps: 5,
        btnText: "Complete Profile",
        onSubmit: () => {
          history.push(isPaymentDoneForIITG ? "/iitg-onboarding" : "/iit-onboarding");
        },
        courseId: isPaymentDoneForIITG ? "17" : "18",
      },
    };
  } else if ((isPaymentDoneForIITMandi || isPaymentDoneForIITG) && isOrientationVideoWatched && !isSemesterFeesPaid) {
    return {
      showProgress: true,
      data: {
        iconUrl: isPaymentDoneForIITG
          ? "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp"
          : "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT_M_81a2d8f496.webp",
        courseName: isPaymentDoneForIITG ? ProgramProfileType.IIT_GUWAHATI : ProgramProfileType.IIT_MANDI,
        currentStepName: "Trimester Fee",
        applicationDeadline: "4th August",
        currentStepNumber: 2,
        totalSteps: 5,
        btnText: "Complete Onboarding",
        onSubmit: () => {
          history.push(isPaymentDoneForIITG ? "/iitg-onboarding" : "/iit-onboarding");
        },
        courseId: isPaymentDoneForIITG ? "17" : "18",
      },
    };
  } else if ((isPaymentDoneForIITMandi || isPaymentDoneForIITG) && !isOrientationVideoWatched) {
    return {
      showProgress: true,
      data: {
        iconUrl: isPaymentDoneForIITG
          ? "https://masai-website-images.s3.ap-south-1.amazonaws.com/Frame_1321317269_99107d38aa.webp"
          : "https://masai-website-images.s3.ap-south-1.amazonaws.com/IIT_M_81a2d8f496.webp",
        courseName: isPaymentDoneForIITG ? ProgramProfileType.IIT_GUWAHATI : ProgramProfileType.IIT_MANDI,
        currentStepName: "Orientation Videos",
        applicationDeadline: "4th August",
        currentStepNumber: 1,
        totalSteps: 5,
        btnText: "Watch Videos",
        onSubmit: () => {
          history.push(isPaymentDoneForIITG ? "/iitg-onboarding" : "/iit-onboarding");
        },
        courseId: isPaymentDoneForIITG ? "17" : "18",
      },
    };
  }

  return {
    showProgress: false,
    data: null,
  };
};
