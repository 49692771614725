import React, { useContext } from "react";
import { Box, Stack } from "@chakra-ui/react";
import { Banner } from "../../Courses/Banner";
import { AppContext } from "../../../context/ApplicationContext";
import dayjs from "dayjs";
import CoursesFailPage from "./CoursesFailPage";
import Events from "../../Events/Events";

const ApplicationClosed = () => {
  const { applicationData } = useContext(AppContext)[0];

  const batchStartDate = dayjs(applicationData?.batch_campus.batch.start_date);
  const name = applicationData?.batch_campus.course_specialization?.name;

  const text =
    applicationData?.status === "APPLICATION_CLOSED" ? (
      // * application closed
      <>
        It looks like your application for the <b>{name}</b> course (Starting from{" "}
        <b>{batchStartDate.format("DD MMM YYYY")})</b> has been closed. You can try our other
        courses, contests and events.{" "}
      </>
    ) : (
      // * archived
      <>
        The application for the <b>{name}</b> has been closed. You can apply to the other batches.
      </>
    );

  return (
    <>
      <Stack px={"ms-24"} pt={"ms-48"} pb="4rem" spacing={"6"}>
        <Banner type="error" text={text} title="Your application has been closed" />
      </Stack>
      <CoursesFailPage />
      <Box>
        <Events hideEmptyState={true} showOnlyEvents={true} />
      </Box>
    </>
  );
};

export default ApplicationClosed;
