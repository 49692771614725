import React from "react";
import { Radio } from "@chakra-ui/react";
import { Box, Text } from "@chakra-ui/react";
interface Props {
  option: {
    key: string;
    value: string;
    subtext?: string;
    customSize?: "regular" | "large";
  };
  size?: "regular" | "large";
}
const RadioButton = ({ option, size }: Props) => {
  return (
    <Radio
      d="flex"
      alignItems={"start"}
      borderColor={"ms-grey.500"}
      _checked={{
        bg: "ms-blue.500",
        borderColor: "inherit",
      }}
      _hover={{ borderColor: "ms-blue.500" }}
      value={option.key}
      size={size === "regular" ? "md" : "lg"}
    >
      <Box mt={-1} d="flex" flexDirection={"column"}>
        <Text textStyle={size === "regular" ? "body2" : "body1"} color="ms-red.900">
          {option.value}
        </Text>
        {option.subtext ? (
          <Text textStyle="caption" color="ms-grey.700">
            {option.subtext}
          </Text>
        ) : null}
      </Box>
    </Radio>
  );
};

export default RadioButton;
