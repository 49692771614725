import { useQuery } from "react-query";
import { getHiresOfTheWeek } from "../../api/v2/levelup_internal";
import { GetHiresOfTheWeekAPIResponse } from "../../../schemas/schema";

export const useGetHiresOfTheWeek = (onComplete?: (data: GetHiresOfTheWeekAPIResponse) => void, handleError?: () => void) => {
  return useQuery({
    queryKey: ["getHiresOfTheWeek"],
    queryFn: async () => (await getHiresOfTheWeek()) as GetHiresOfTheWeekAPIResponse,
    onSuccess(data) {
      onComplete && onComplete(data);
    },
    onError() {
      handleError && handleError();
    },
    staleTime: 1000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: true,
    retryDelay: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30000), // Exponential backoff delay
    refetchOnWindowFocus: false,
    enabled: true,
  });
};
