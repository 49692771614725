import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const MyTutorialsIcon = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M11.5 14.5L18.5 10L11.5 5.5V14.5ZM8 18C7.45 18 6.97933 17.8043 6.588 17.413C6.196 17.021 6 16.55 6 16V4C6 3.45 6.196 2.979 6.588 2.587C6.97933 2.19567 7.45 2 8 2H20C20.55 2 21.021 2.19567 21.413 2.587C21.8043 2.979 22 3.45 22 4V16C22 16.55 21.8043 17.021 21.413 17.413C21.021 17.8043 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97933 21.8043 2.588 21.413C2.196 21.021 2 20.55 2 20V6H4V20H18V22H4Z"
      fill="currentColor"
    />
  </Icon>
);

export default MyTutorialsIcon;
