import { Box, Text } from "@chakra-ui/react";
import { InformationOutline } from "../../../../assets/icons/components";

interface Props {
  bookingStatus?: boolean;
}
const MasaiXInterviewRealodText = ({ bookingStatus }: Props) => {
  return (
    <Box
      display={bookingStatus ? "flex" : "none"}
      alignItems={{ base: "flex-start", md: "center" }}
    >
      <InformationOutline color="ms-yellow.500" boxSize="24px" />
      <Text ml={"ms-12"} textStyle={{ base: "body2", md: "body1" }} color={"ms-grey.700"}>
        Please make sure to refresh this page after 30 minutes of completing the interview to view
        your interview result.
      </Text>
    </Box>
  );
};

export default MasaiXInterviewRealodText;
