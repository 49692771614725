import {
  Box,
  Heading,
  ListItem,
  OrderedList,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import { InformationOutline } from "../../../../../assets/icons/components";
import { PermissionsImage } from "../../../../../assets/images";
import CameraControls from "./CameraControls";

const BeforeCameraAccess = () => {
  const headingSize = useBreakpointValue<"h6" | "h4">({ base: "h6", md: "h4" });
  return (
    <Box
      d={{ base: "flex", md: "grid" }}
      flexDirection="column-reverse"
      gridTemplateColumns={{ md: "50% 50%" }}
    >
      {/* left side */}
      <Box>
        <Box
          mt={{ base: "16px", md: "0px" }}
          d={"flex"}
          justifyContent={"center"}
          borderRadius={"16px"}
          h={"386px"}
          bgColor={"ms-grey.900"}
          alignItems={"center"}
          p={"12px"}
        >
          <InformationOutline boxSize="6" color={"#fff"} />
          <Text ml={"ms-8"} color="#fff" textStyle={{ base: "body2", md: "body1" }}>
            Allow camera permission to use the camera
          </Text>
        </Box>
        <CameraControls disabled={true} />
      </Box>
      {/* right side */}
      <Box px={{ base: "0px", md: "ms-32" }}>
        <Heading
          mt={{ base: "16px", md: "0px" }}
          mb={{ base: "ms-12", md: "ms-16" }}
          as={headingSize}
        >
          Allow permission to use the camera
        </Heading>
        <Box d={"flex"}>
          <OrderedList mr={"ms-32"}>
            <ListItem mb={{ base: "ms-8", md: "ms-12" }}>
              <Text textStyle={"body2-md"}> Check top left corner of your browser</Text>
            </ListItem>
            <ListItem>
              <Text textStyle={"body2-md"}> Click “Allow” button to give access</Text>
            </ListItem>
          </OrderedList>
          <Box d={{ base: "none", md: "block" }}>
            <Image src={PermissionsImage} alt="permissions" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BeforeCameraAccess;
