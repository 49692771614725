import { Box, Button, Image, Text } from "@chakra-ui/react";
import { convertUrlToCDNUrl } from "../../utils/utils";

const ConnectWithAlumni = () => {
  return (
    <Box pos={"relative"} height='fit-content' maxW={{ md: "330px" }} w="full" border={"1px solid #E5E5E5"} py={"20px"} px={"16px"} alignSelf={"stretch"} rounded={"8px"} bg={"#F5F0FF"} overflow={"hidden"}>
      <Text fontSize={"14px"} fontWeight={700} maxW={"261px"} fontFamily={"inter"}>
        Have a query? Get it resolved by talking to our Alumni.
      </Text>
      <Text maxW="224px" fontSize={"12px"} mt={"8px"} fontWeight={500} fontFamily={"inter"} lineHeight={"16px"} color={"#6C6768"}>
        Join 1000+ others who have contacted our alumni. Your success is a chat away!
      </Text>
      <Button size="sm" bg={"white"} color={"#3470E4"} border={"1px solid #3470E4"} mt={"20px"} as={"a"} href={"/alumni"}>
        Connect with ALUMNI
      </Button>
      <Image
        src={convertUrlToCDNUrl("https://masai-website-images.s3.ap-south-1.amazonaws.com/Image_nm_218c34d7da.webp")}
        objectFit={"contain"}
        pos={"absolute"}
        bottom={"-5px"}
        right={"-12px"}
        w={"100px"}
        h={"110px"}
      />
    </Box>
  );
};

export default ConnectWithAlumni;
