import React from "react";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { OverviewCardLeft } from "./OverviewCardLeft";
import { OverviewCardFooter } from "./OverviewCardFooter";
import CounsellingIcon from "../../assets/icons/components/Others/CounsellingIcon";

export const CounsellingOverviewCard = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <Box border={"0.5px solid #D9D9D9"} borderRadius={"ms-16"} w="full">
        <Flex>
          <OverviewCardLeft icon={<CounsellingIcon boxSize="20" />} bg="ms-purple.50" />
          <Flex w="full" direction={"column"} padding={{ base: "ms-12", md: "ms-16" }}>
            <Heading as="h6">Counselling Session</Heading>
            <Box minHeight={"96px"}>
              <Text textStyle="body2" mt={{ base: "ms-4", md: "ms-8" }}>
                Speak to a Masai Professional, on how to advance your career.
              </Text>
            </Box>
            <OverviewCardFooter handleBooking={onClick} isCounselling={true} />
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
