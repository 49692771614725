export const launchFormDataFullTime = [
  {
    type: "input",
    name: "pan",
    title: "What is your PAN Card Number ?",
    isCompulsary: true,
    placeholder: "Your answer",
    pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
  },
  {
    name: "mother_toungue",
    type: "dropdown",
    title: `What is your Mother tongue?`,
    isCompulsary: true,
    dropdownOptions: [
      "Assamese",
      "Bengali",
      "Bodo",
      "Dogri",
      "Gujarati",
      "Hindi",
      "Kannada",
      "Kashmiri",
      "Konkani",
      "Maithili",
      "Malayalam",
      "Manipuri",
      "Marathi",
      "Nepali",
      "Oria",
      "Punjabi",
      "Sanskrit",
      "Santali",
      "Sindhi",
      "Tamil",
      "Telugu",
      "Urdu",
      "Others",
    ],
  },
  {
    type: "input",
    name: "current_city",
    title: "Which city do you live in ?",
    isCompulsary: true,
    placeholder: "Your answer",
  },
  {
    type: "input",
    name: "pincode",
    title: "What is your pincode?",
    isCompulsary: true,
    placeholder: "Your answer",
    pattern: /^([0-9]){6}?$/,
  },
  {
    type: "dropdown",
    name: "state",
    title: "Which State/UT do you live in ?",
    isCompulsary: true,
    dropdownOptions: [
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chhattisgarh",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttarakhand",
      "Uttar Pradesh",
      "West Bengal",
      "Andaman & Nicobar Islands",
      "Chandigarh",
      "Dadla and Nagar Haveli and Daman & Diu",
      "New Delhi",
      "Jammu & Kashmir",
      "Ladakh",
      "Lakshadweep",
      "Puducherry",
    ],
  },
  {
    type: "radio",
    name: "annual_income",
    title: "What is your annual family income ?",
    isCompulsary: true,
    radioOptions: [
      {
        key: "0 - 2.5 Lakhs",
        value: "0 - 2.5 Lakhs",
      },
      {
        key: "2.5 - 5 Lakhs",
        value: "2.5 - 5 Lakhs",
      },
      {
        key: "5 - 7.5 Lakhs",
        value: "5 - 7.5 Lakhs",
      },
      {
        key: "7.5 - 10 Lakhs",
        value: "7.5 - 10 Lakhs",
      },
      {
        key: "10 Lakhs and Above",
        value: "10 Lakhs and Above",
      },
    ],
  },
  {
    type: "input",
    name: "parent_name",
    title: "What is your Father or your Mother's name ?",
    isCompulsary: true,
    placeholder: "Your answer",
  },
  {
    type: "input",
    name: "parent_mobile_number",
    title:
      "Please share your Father or your Mother's phone number. (enter 10 digit, should be different from registered number, we may reach out to them at some stage in the curriculum, whenever needed)",
    isCompulsary: true,
    placeholder: "Your answer",
    pattern: /^([0-9]){10}?$/,
  },
  {
    type: "input",
    name: "parent_residential_address",
    title:
      "Please share your Parent's residential Address. ( This may be used for some communication)",
    isCompulsary: true,
    placeholder: "Your answer",
  },
  {
    type: "input",
    name: "friend_name",
    title: "Please share the name of any one of your friend's name. ",
    isCompulsary: true,
    placeholder: "Your answer",
  },
  {
    type: "input",
    name: "friend_mobile_number",
    title: "Please share your friend's phone number. (Enter the 10 digit mobile number)",
    isCompulsary: true,
    placeholder: "Your answer",
    pattern: /^([0-9]){10}?$/,
  },
  {
    name: "how_did_you_hear_about_us",
    type: "radio",
    title: `How did you come to know about Masai ?`,
    isCompulsary: true,
    radioOptions: [
      {
        key: "Masai Official Website",
        value: "Masai Official Website",
      },
      {
        key: "Youtube",
        value: "Youtube",
      },
      {
        key: "Google search",
        value: "Google search",
      },
      {
        key: "Referral from Masai student / alumni",
        value: "Referral from Masai student / alumni",
      },
      {
        key: "Referral from friends & family",
        value: "Referral from friends & family",
      },
      {
        key: "Facebook",
        value: "Facebook",
      },
      {
        key: "Instagram",
        value: "Instagram",
      },
      {
        key: "LinkedIn",
        value: "LinkedIn",
      },
      {
        key: "Bootcamp",
        value: "Bootcamp",
      },
      {
        key: "Other",
        value: "Other",
      },
    ],
  },
  {
    name: "other_products_you_have_enquired_about",
    type: "radio",
    title: `Did you try for any other coding Bootcamp / PAP based school?`,
    isCompulsary: true,
    radioOptions: [
      {
        key: "Yes",
        value: "Yes",
      },
      {
        key: "No",
        value: "No",
      },
    ],
  },
  {
    name: "current_status",
    type: "radio",
    title: `What is your current status just before joining Masai ?`,
    isCompulsary: true,
    radioOptions: [
      {
        key: "Studying",
        value: "Studying",
      },
      {
        key: "Working",
        value: "Working",
      },
      {
        key: "Completed studies but not working",
        value: "Completed studies but not working",
      },
      {
        key: "Applied for a Job & Waiting",
        value: "Applied for a Job & Waiting",
      },
      {
        key: "Quit Job to join Masai",
        value: "Quit Job to join Masai",
      },
    ],
  },
  {
    type: "input",
    name: "current_status_section_current_company",
    title: "Enter the name of your current company ?",
    isCompulsary: true,
    placeholder: "Your answer",
  },
  {
    type: "input",
    name: "current_status_section_work_experience",
    title: "What is your total work experience (in months) ?",
    isCompulsary: true,
    placeholder: "Your answer",
  },
  {
    type: "input",
    name: "current_status_section_current_designation",
    title: "What is your current designation in the company ?",
    isCompulsary: true,
    placeholder: "Your answer",
  },
  {
    type: "input",
    name: "current_status_section_current_ctc",
    title: "Enter your current or last CTC? ( Lakhs per annum) ",
    isCompulsary: true,
    placeholder: "Your answer",
  },
  // Section End for before masai question check

  {
    name: "highest_education_degree",
    type: "radio",
    title: `What's your highest educational degree?`,
    isCompulsary: true,
    radioOptions: [
      {
        key: "Graduation",
        value: "Graduation",
      },
      {
        key: "Post Graduation",
        value: "Post Graduation",
      },
      {
        key: "Diploma",
        value: "Diploma",
      },
      {
        key: "Pre - University / XII",
        value: "Pre - University / XII",
      },
    ],
  },
  // Section starts for highest_education_degree check

  // highest_education_qualification_UG_section:Graduation
  // highest_education_qualification_PG_section: Post Graduation
  // highest_education_qualification_Diploma_section: Diploma

  {
    name: "highest_education_qualification_PG_section",
    type: "dropdown",
    title: `What's your major as per the highest educational qualification?`,
    isCompulsary: true,
    dropdownOptions: [
      "Post Graduation Non CS/IT (MBA / MSC / MA / Mcom)",
      "Post Graduation CS/IT (MCA/Mtech)",
      "Post Graduation Non CS/IT (MBA / MSC / MA / Mcom)",
    ],
  },
  {
    name: "highest_education_qualification_UG_section",
    type: "dropdown",
    title: `What's your major as per the highest educational qualification?`,
    isCompulsary: true,
    dropdownOptions: ["Btech (CS/IT)", "Btech (Non CS/IT)", "Graduation CS/IT (BCA/BSc)","BA/B.Com/B.Sc (Non-CS)"],
  },
  {
    name: "highest_education_qualification_Diploma_section",
    type: "dropdown",
    title: `What's your major as per the highest educational qualification?`,
    isCompulsary: true,
    dropdownOptions: ["Diploma (CS/IT)", "Diploma (Non CS/IT)"],
  },
  // Section ends for highest_education_degree check
  {
    type: "input",
    name: "final_year_grades",
    title:
      "Final Year grades (CGPA / Percentage) [In case of drop out prior to final year, mention the final exam grades.]\n\nPlease enter a value between 0 and 10. Kindly divide percentage marks by 10 if GPA is not provided.",
    isCompulsary: true,
    placeholder: "Your answer",
    footerText:
      "Please enter a value between 0 and 10. Kindly divide percentage marks by 10 if GPA is not provided.",
  },

  {
    name: "past_coding_experience",
    type: "radio",
    title: `Do you have any past coding experience ?`,
    isCompulsary: true,
    radioOptions: [
      {
        key: "0 - No experience",
        value: "0 - No experience",
      },
      {
        key: "1 - Very Basic knowledge",
        value: "1 - Very Basic knowledge",
      },
      {
        key: "2 - Studied a programming language partially",
        value: "2 - Studied a programming language partially",
      },
      {
        key: `3 - Completed "Introduction to programming"`,
        value: `3 - Completed "Introduction to programming"`,
      },
      {
        key: "4 - Can share some work/project done in the past",
        value: "4 - Can share some work/project done in the past",
      },
    ],
  },
  {
    type: "input",
    name: "prior_projects",
    title:
      "If you have some coding background, give some brief info on projects or experience else write NA. ",
    isCompulsary: true,
    placeholder: "Your answer",
  },

  {
    name: "current_os",
    type: "radio",
    title: ` Which Operating System do you currently use?`,
    isCompulsary: true,
    radioOptions: [
      {
        key: "Windows",
        value: "Windows",
      },
      {
        key: "Linux/Ubuntu",
        value: "Linux/Ubuntu",
      },
      {
        key: "iOS",
        value: "iOS",
      },
      {
        key: `Other`,
        value: `Other`,
      },
    ],
  },
  {
    type: "radio",
    name: "current_ram",
    title: "What is the RAM size installed on your system ?",
    isCompulsary: true,
    radioOptions: [
      {
        key: "Less than 2GB",
        value: "Less than 2GB",
      },
      {
        key: "4-8 GB",
        value: "4-8 GB",
      },
      {
        key: "More than 8 GB",
        value: "More than 8 GB",
      },
    ],
  },
  {
    type: "radio",
    name: "current_processor",
    title: "What is the type of your processor ? (Ex. i3, 2.0 GHz)",
    isCompulsary: true,
    radioOptions: [
      {
        key: "i3",
        value: "i3",
      },
      {
        key: "i5",
        value: "i5",
      },
      {
        key: "i7",
        value: "i7",
      },
      {
        key: "Other",
        value: "Other",
      },
    ],
  },
  {
    type: "radio",
    name: "current_hard_disk",
    title: "What is the free storage space available on your system ?",
    isCompulsary: true,
    radioOptions: [
      {
        key: "Less than 250 GB",
        value: "Less than 250 GB",
      },
      {
        key: "250 -500 GB",
        value: "250 -500 GB",
      },
      {
        key: "More than 500 GB",
        value: "More than 500 GB",
      },
    ],
  },

  {
    name: "webcam_condition",
    type: "radio",
    title: `Do you have a laptop camera / webcam in working condition ?`,
    isCompulsary: true,
    radioOptions: [
      {
        key: "Yes",
        value: "Yes",
      },
      {
        key: "No",
        value: "No",
      },
    ],
  },
  {
    name: "network_infrastructure",
    type: "dropdown",
    title: `What Internet Connection do you intend to use for the course opted at Masai ?`,
    isCompulsary: true,
    dropdownOptions: [
      "Broadband",
      "Wifi Dongle",
      "Mobile Hotspot / Tethering",
      "Fibre to Home (FTH)",
    ],
  },
  {
    type: "radio",
    name: "private_desk",
    title: "Do you have a private desk at home ?",
    isCompulsary: true,
    radioOptions: [
      {
        key: "Yes",
        value: "Yes",
      },
      {
        key: "No",
        value: "No",
      },
    ],
  },
  {
    type: "radio",
    name: "power_backup",
    title: "Do you have a power back up at home ?",
    isCompulsary: true,
    radioOptions: [
      {
        key: "Yes",
        value: "Yes",
      },
      {
        key: "No",
        value: "No",
      },
    ],
  },
  {
    type: "radio",
    name: "commitments",
    title:
      "Do you have any of the listed commitments that might affect your active participation in the program enrolled ?",
    isCompulsary: true,
    radioOptions: [
      {
        key: "Health issues",
        value: "Health issues",
      },
      {
        key: "Semester Exams",
        value: "Semester Exams",
      },
      {
        key: "Family obligations",
        value: "Family obligations",
      },
      {
        key: "None",
        value: "None",
      },
      {
        key: "Others",
        value: "Others",
      },
    ],
  },
  {
    type: "radio",
    name: "expected_salary",
    title:
      "Select your aspirational salary range (salary that you want to earn) after you complete this course",
    isCompulsary: true,
    radioOptions: [
      {
        key: "5 - 7.5 Lakhs per year",
        value: "5 - 7.5 Lakhs per year",
      },
      {
        key: "I don't want to do a job after course completion",
        value: "I don't want to do a job after course completion",
      },
      {
        key: "I wish to start my career irrespective of the salary slab",
        value: "I wish to start my career irrespective of the salary slab",
      },
    ],
  },
  {
    type: "input",
    name: "linked_in_url",
    title:
      "Please add your Linkedin profile link (Example: https://www.linkedin.com/in/satyanadella/)",
    isCompulsary: true,
    placeholder: "Your answer",
  },
  {
    name: "bank_account",
    type: "radio",
    title: `Do you have a Bank Account ? `,
    isCompulsary: true,
    radioOptions: [
      {
        key: "Yes",
        value: "Yes",
      },
      {
        key: "No",
        value: "No",
      },
    ],
  },
  {
    name: "internet_banking",
    type: "radio",
    title: `Do you have Internet banking enabled on your bank account ? `,
    isCompulsary: true,
    radioOptions: [
      {
        key: "Yes",
        value: "Yes",
      },
      {
        key: "No",
        value: "No",
      },
    ],
  },
  {
    name: "cheque",
    type: "radio",
    title: `Do you own a cheque book ? `,
    isCompulsary: true,
    radioOptions: [
      {
        key: "Yes",
        value: "Yes",
      },
      {
        key: "No",
        value: "No",
      },
    ],
  },
  {
    name: "mobile_number_bank_account",
    type: "radio",
    title: `Is your mobile number registered with your bank account ? (Note the mobile number registered with your Bank account should be the same that you used for course registration) `,
    isCompulsary: true,
    radioOptions: [
      {
        key: "Yes",
        value: "Yes",
      },
      {
        key: "No",
        value: "No",
      },
    ],
  },
  {
    name: "aadhaar_bank_link",
    type: "radio",
    title: `Is your Aadhaar linked with your bank account ? `,
    isCompulsary: true,
    radioOptions: [
      {
        key: "Yes",
        value: "Yes",
      },
      {
        key: "No",
        value: "No",
      },
    ],
  },
  {
    type: "input",
    name: "cibil_score",
    title: `Credit Score (CIBIL score). Use the link to check your credit score <br/> \n <a target="_blank"  href="https://homeloans.sbi/getcibil">https://homeloans.sbi/getcibil</a> or \n\n <a target="_blank" href="https://consumer.experian.in/ECV-OLN/signIn?_ga=2.47060775.1094535989.1624278871-1376078730.1624278871">https://consumer.experian.in</a> \n \n (If you are not able to generate the score , enter the message displayed in the report generated)`,
    isCompulsary: true,
    placeholder: "Your answer",
    footerText:
      "1.https://homeloans.sbi/getcibil  or 2.https://consumer.experian.in/ECV-OLN/signIn",
  },
];
