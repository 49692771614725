import { Box, Image, Heading, Text, useBreakpointValue } from "@chakra-ui/react";

import ScholarInstructionImageMobile from "../../../../../assets/images/CoursePrograms/scholar-instruction-image-mobile.svg";
import NoQuestionAskedImage from "../../../../../assets/images/CoursePrograms/no_question_asked.svg";
import numberWithCommas from "../../../utils/numberWithCommas";
interface Props {
  coursePrice?: number;
  level?: number;
}
const ScholarInstructionsPricing = ({ coursePrice, level }: Props) => {
  const headingSize = useBreakpointValue<"h3" | "h4">({
    base: "h4",
    md: "h3",
  });
  const headingSize2 = useBreakpointValue<"h3" | "h1">({
    base: "h3",
    md: "h1",
  });

  return (
    <Box mt={{ base: "ms-24", md: "0px" }} pl={{ md: "ms-32" }}>
      <Heading mb={"ms-12"} display={{ base: "block", md: "none" }} as="h4">
        Course Price
      </Heading>
      <Box
        textAlign={{ base: "center" }}
        borderRadius={"16px"}
        boxShadow="0px -2px 11px -2px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
        px={{ base: "ms-12", md: "24px" }}
        pt={"ms-24"}
      >
        <Box
          mb={{ base: "32px", md: "0px" }}
          display={{ base: "flex", md: "block" }}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Image
            mr={{ base: "ms-8", md: "0px" }}
            m={{ md: "auto" }}
            src={ScholarInstructionImageMobile}
          />
          <Heading mt={{ base: "0", md: "ms-8" }} as={headingSize}>
            Introductory Pricing
          </Heading>
        </Box>
        <Box mt={"ms-32"}>
          <Heading d={"inline"} as="h1" color={"ms-blue.500"} fontFamily={""}>
            ₹{""}
            <Heading d={"inline"} as="h1" color={"ms-blue.500"}>
              {coursePrice && numberWithCommas(coursePrice)}
            </Heading>
            <Text
              textDecor="line-through"
              ml="8px"
              fontSize="20px"
              d={"inline"}
              color={"ms-blue.500"}
              fontFamily={"Poppins"}
            >
              {level === 1 ? "₹ 30,000" : "₹ 50,000"}
            </Text>
          </Heading>{" "}
        </Box>
        <Box mt={"ms-24"}>
          <Text textStyle={"body1"} color={"ms-grey.700"} d={"inline"}>
            {" "}
            Get skilled today at a price of just
            <br />{" "}
            <Text d={"inline"} textStyle={"body1-md"} color={"ms-blue.500"}>
              {coursePrice === undefined ? "NA" : `₹ ${Math.floor(coursePrice / 6)}`}
            </Text>{" "}
            per month for 6 months
          </Text>
          <Image mx={"auto"} mt={"ms-16"} src={NoQuestionAskedImage} alt="no question asked" />
        </Box>

        <Box
          p={"16px"}
          mx={{ base: "-12px", md: "-24px" }}
          mt={{ base: "ms-16", md: "mt-16" }}
          bgColor={"ms-blue.50"}
        >
          <Text textStyle={"caption"} color={"ms-grey.700"}>
            Avail a 2 week free trial. Full refund if you wish to discontinue within 2 weeks.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default ScholarInstructionsPricing;
