import { useState } from "react";
import { Box, Button, Input } from "@chakra-ui/react";
import { useMutation } from "react-query";
import useSnackBar from "../../../common/general/SnackBar";
import { axiosErrorHandler } from "../../../../api/utils/error";
import { updateProfileByEmail } from "../../../../api/utils/api/v2/admin";

const EmpowerHerVerifyUser = () => {
  const snackbar = useSnackBar();
  const [email, setEmail] = useState("");
  const { mutate: updateProfileMutate, isLoading: updateProfileLoading } = useMutation(updateProfileByEmail, {
    onSuccess: () => {
      snackbar.success("User verified Successfully");
      setEmail("");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const handleSaveDetails = () => {
    updateProfileMutate({
      email: email,
      meta: {
        hasMarkedEmpowerHerChoosen: true,
      },
    });
  };

  return (
    <Box>
      <Box display={"flex"} flexDirection={"column"} gap="10px" margin="10px">
        <Box display={"flex"} flexDirection={"column"} gap="10px" mt="40px" width={"400px"}>
          <label htmlFor="email" style={{ fontWeight: "bold", fontSize: "14px" }}>
            Enter user email below *
          </label>
          <Input name="email" type="email" placeholder="Enter user email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <Button isLoading={updateProfileLoading} isDisabled={!email || updateProfileLoading} width={"100px"} variant="primary" onClick={handleSaveDetails} ml="auto">
            Verify
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EmpowerHerVerifyUser;
