import { Box, Flex, Text } from "@chakra-ui/react";

const IconWithLabel = ({
  icon,
  label,
  spacing = "4px",
  textStyle = "body2-md",
  color = "ms-grey.800",
}: {
  icon: JSX.Element;
  label: string | JSX.Element;
  spacing?: string | number | string[] | number[];
  textStyle?: string | string[];
  color?: string;
}) => {
  return (
    <Flex justify="start" gap={spacing} alignItems={"center"}>
      {/* some issue with icon, that i moved it up mannually */}
      <Box pb="4px">{icon}</Box>
      <Box>
        <Text textStyle={textStyle} color={color}>
          {label}
        </Text>
      </Box>
    </Flex>
  );
};

export default IconWithLabel;
