import { useQuery } from "react-query";

import { ProfileType } from "../../schemas/schema";

import { getProfile } from "../api/v2/profile";

export function useProfile(onComplete?: (data: ProfileType) => void) {
  return useQuery({
    queryKey: "getProfile",
    queryFn: async () => (await getProfile()) as ProfileType,
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,
    onSuccess(data) {
      onComplete && onComplete(data);
    },
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
