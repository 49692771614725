import { Box, useBreakpointValue } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";

import advancedFormat from "dayjs/plugin/advancedFormat";
import ScholarInstructionImage1 from "../../../../../assets/images/CoursePrograms/scholar-instructions-1.svg";
import ScholarInstructionImage2 from "../../../../../assets/images/CoursePrograms/scholar-instructions-2.svg";
import ScholarInstructionImage3 from "../../../../../assets/images/CoursePrograms/scholar-instructions-3.svg";
import { BottomStickyNavbar, ModalTemplate } from "../../../../../components/common/general";
import { sendTrackingEvent } from "../../../../../components/common/utils/gtm";
import { AppContext } from "../../../../../context/ApplicationContext";

import { useParams } from "react-router-dom";
import { applyForScholarship, sendEventForTracking } from "../../../../../api/utils/api";
import { axiosErrorHandler } from "../../../../../api/utils/error";
import useSnackBar from "../../../../../components/common/general/SnackBar";
import useApplicationHook from "../../../../../hooks/useApplicationHook";
import ScholarshipOptionModal from "../ScholarshipOptionModal";
import NeedHelp from "./NeedHelp";
import ScholarInstructionsDetails from "./ScholarInstructionsDetails";
import ScholarInstructionsHeader from "./ScholarInstructionsHeader";
import ScholarInstructionsPricing from "./ScholarInstructionsPricing";
import { useMatomo } from "@datapunt/matomo-tracker-react";
interface Props {
  handleProceedToPayment: () => void;
}
const ScholarInstructions = ({ handleProceedToPayment }: Props) => {
  const [isScholarshipOptionModalOpen, setScholarshipOptionModalOpen] = useState<boolean>(false);
  const [scholarShipOption, setScholarShipOption] = useState<string>("");
  const state = useContext(AppContext)[0];
  const { applicationData } = state;
  const [loading, setLoading] = useState<boolean>(false);
  const snackbar = useSnackBar();
  const params = useParams() as { applicantID: string };
  const { fetchApplicationData } = useApplicationHook()[1];
  const scholarResultPresent =
    applicationData?.scholar_result !== null && applicationData?.scholar_result !== undefined;
  const courseLevel = applicationData?.batch_campus?.batch?.course?.level;
  useEffect(() => {
    if (!applicationData?.slug) return;
    const handleConfirm = async () => {
      try {
        setLoading(true);
        if (scholarShipOption === "withScholarship") {
          await applyForScholarship({ application_slug: applicationData?.slug });
        } else if (scholarShipOption == "withoutScholarship") {
          await sendEventForTracking({
            payment_pending_continue_without_scholarship_click: true,
            payment_pending_next_button_click: false,
            application_slug: applicationData?.slug,
          });
          handleProceedToPayment();
        }
      } catch (err) {
        const e = axiosErrorHandler(err);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("something went wrong");
        }
      } finally {
        setLoading(false);
        fetchApplicationData && fetchApplicationData(params.applicantID);
      }
    };
    handleConfirm();
  }, [scholarShipOption]);

  const level = state.applicationData?.batch_campus?.batch?.course?.level;
  const courseName = state.applicationData?.batch_campus.batch.course.name;
  const startDate = dayjs(state.applicationData?.batch_campus.batch.start_date).format(
    "Do MMM YYYY"
  );
  dayjs.extend(advancedFormat);
  const whatYouWillLearnData: { icon: string; key: string; value: string }[] = [
    {
      icon: ScholarInstructionImage1,
      key: level === 1 ? "Basic Programming" : level === 2 ? "Advanced Programming" : "",
      value: "26 Weeks",
    },
    { icon: ScholarInstructionImage2, key: "English Fluency Marathon", value: "5 Weeks" },
    {
      icon: ScholarInstructionImage3,
      key: `Build Projects & Create 
    Your Own Portfolio `,
      value: "",
    },
  ];
  const coursePrice = state.applicationData?.batch_campus.batch.course_price;
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const handleNextBtnClick = () => {
    if (scholarResultPresent || courseLevel == 2) handleProceedToPayment();
    else setScholarshipOptionModalOpen(true);
  };
  const { trackEvent } = useMatomo();
  return (
    <>
      <ModalTemplate
        title=""
        isOpen={isScholarshipOptionModalOpen}
        onClose={() => {
          sendTrackingEvent({
            event: "sp_pp_db_x",
            eventLabel: location.pathname,
          });
          setScholarshipOptionModalOpen(false);
        }}
        showCloseButtonIcon={true}
        marginX={2}
        maxWidth="500px"
      >
        <ScholarshipOptionModal
          handleConfirm={(option) => {
            setScholarShipOption(option);
          }}
          coursePrice={Number(coursePrice)}
        />
      </ModalTemplate>

      <Box p={{ base: "ms-16", lg: "ms-24" }}>
        <ScholarInstructionsHeader
          courseName={courseName || "NA"}
          startDate={startDate || "NA"}
          level={level ?? -1}
        />
        <Box mt={"24px"} display={"grid"} gridTemplateColumns={{ base: "100%", lg: "55% 45%" }}>
          <ScholarInstructionsDetails whatYouWillLearnData={whatYouWillLearnData} />
          <ScholarInstructionsPricing coursePrice={coursePrice} level={level ?? -1} />
          <Box d={{ base: "block", md: "none" }} mt={"ms-24"}>
            <NeedHelp />
          </Box>
        </Box>
      </Box>
      <BottomStickyNavbar
        btnPrimaryProps={{
          isDisabled: false,
          size: isMobile ? "sm" : "lg",
          onClick: async () => {
            trackEvent({
              category: "Dashboard",
              action: "Next",
              name: level === 1 ? "dashboard_next_scholar_l1" : "dashboard_next_scholar_l2",
              value: 300,
            });
            sendTrackingEvent({ event: "sp_pp_proceed_payment", eventLabel: location.pathname });
            await sendEventForTracking({
              payment_pending_continue_without_scholarship_click: false,
              payment_pending_next_button_click: true,
              application_slug: String(applicationData?.slug),
            });
            handleNextBtnClick();
          },
        }}
        btnPrimary={"Next"}
      />
    </>
  );
};

export default ScholarInstructions;
