import React, { useContext } from "react";
import { Text, Stack, Box } from "@chakra-ui/react";
import { ScoreCardTop } from "./ScoreCardTop";
import { ScoreCardWrapper } from "./ScoreCardWraper";
import { AppContext } from "../../context/ApplicationContext";
import { ApplicationStatusEnums } from "../../api/schemas/schema";
import { SchoolOutline } from "../../assets/icons/components";
interface Props {
  scoreDetail: {
    id: number;
    title?: string;
    icon?: JSX.Element;
    marksObtained?: string;
    totalMarks?: number;
    // assumed status as passed failed and pending for now
    status?: string;
    // if score is pending for communication skills section
    pendingText?: string;
    hideMarks?: boolean;
  };
  isNewMsat?: boolean;
}
export const ScoreCard = ({ scoreDetail, isNewMsat }: Props) => {
  const state = useContext(AppContext)[0];
  const mettlStatus = state.applicationData?.status;
  const getCredibilityStatus = (): { text: string; color: string } => {
    const status = scoreDetail?.status?.toLowerCase();

    switch (status) {
      case "medium":
      case "high":
        return { text: "Passed", color: "ms-success" };
      case "low":
        if (mettlStatus === ApplicationStatusEnums.METTL_DESCISION_PENDING) {
          return { text: "Decision Pending", color: "ms-warning" };
        } else if (mettlStatus === ApplicationStatusEnums.METTL_PASSED) {
          return { text: "Passed", color: "ms-success" };
        } else if (mettlStatus === ApplicationStatusEnums.METTL_FAILED) {
          return { text: "Not Cleared", color: "ms-error" };
        } else {
          return { text: "Decision Pending", color: "ms-warning" };
        }
      default:
        return { text: "Decision Pending", color: "ms-warning" };
    }
  };

  return (
    <>
      <ScoreCardWrapper key={scoreDetail.id}>
        <Stack
          minW={{ base: "full", xl: "264px" }}
          maxW={{ base: "full", md: "264px" }}
          spacing={{ base: "ms-4", md: "ms-8" }}
        >
          <ScoreCardTop
            id={scoreDetail.id}
            title={scoreDetail.title}
            icon={scoreDetail.icon}
            status={scoreDetail.status}
            finalCredibilityStatus={getCredibilityStatus().text}
            finalCredibilityStatusColor={getCredibilityStatus().color}
            isNewMsat={isNewMsat}
          />
          <Text textStyle={"body1-md"}>{scoreDetail.title}</Text>
          {!scoreDetail.status ? (
            <Text color={"ms-warning"} textStyle={"body2"}>
              {scoreDetail.pendingText}
            </Text>
          ) : (
            scoreDetail.marksObtained &&
            !isNaN(Number(scoreDetail.marksObtained)) && (
              <>
                {scoreDetail.status === "PASSED_WITH_MASS_RECRUIT" ? (
                  <Box d={"flex"} alignItems={"flex-start"}>
                    <SchoolOutline boxSize="5" color="ms-green.500" />
                    <Text mt={"-2px"} ml={"ms-4"} textStyle={"body2"} color="ms-grey.700">
                      Grace mark credited based on 10th & 12th Percentage.
                    </Text>
                  </Box>
                ) : (
                  <>
                    {isNewMsat ? (
                      <>
                        {!(
                          mettlStatus === ApplicationStatusEnums.METTL_FAILED &&
                          scoreDetail.status === "FAILED"
                        ) ? (
                          <>
                            {isNewMsat && scoreDetail.status === "PENDING" ? (
                              <Text color={"ms-warning"} textStyle={"body2"}>
                                {scoreDetail.pendingText}
                              </Text>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : null}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )
          )}
          {/* Helper text for credibility score */}
          {!isNewMsat && scoreDetail.id === 4 && getCredibilityStatus().text === "Passed" && (
            <Text textStyle={"body2"} color="ms-grey.900">
              {"You have successfully passed the credibility test"}
            </Text>
          )}
          {!isNewMsat &&
            scoreDetail.id === 4 &&
            getCredibilityStatus().text === "Decision Pending" && (
              <Text color={"ms-warning"} textStyle={"body2"}>
                {scoreDetail.pendingText}
              </Text>
            )}
        </Stack>
      </ScoreCardWrapper>
    </>
  );
};
