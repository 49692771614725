import { Box, Input, Text } from "@chakra-ui/react";
import CustomUploadFileInput from "./CustomUploadFileInput";
import { IITGuwahatiProfileDetailInputInterface } from "../../../constants/IIt-course/iit-form-onboarding";
import { useEffect, useState } from "react";
import { GetS3PresignedUrlType, ProfileType } from "../../../api/schemas/schema";
import { ProgramProfileType } from "../../../enums/ProgramType.enum";
import CustomFormHeading from "./CustomFormHeading";
import axios from "axios";

interface Props {
  profileDetailForm: IITGuwahatiProfileDetailInputInterface;
  setProfileDetailForm: (data: IITGuwahatiProfileDetailInputInterface) => void;
  profile: ProfileType;
  isAadhaarVerified: boolean;
  isMobileInputInvalid: boolean;
  setIsMobileInputInvalid: (arg: boolean) => void;
}

const CoApplicantForm = ({ profileDetailForm, setProfileDetailForm, profile, isAadhaarVerified, isMobileInputInvalid, setIsMobileInputInvalid }: Props) => {
  const pattern = new RegExp(/^\d{10}$/);
  const [profilePic, setProfilePic] = useState<File | undefined>();
  const [isSelfEmployed, setIsSelfEmployed] = useState<boolean>(false);
  const [coApplicant, setCoApplicant] = useState<string | undefined>();
  const [bankStatement, setBankStatement] = useState<File | undefined>();
  const [coApplicantPanFront, setCoApplicantPanFront] = useState<File | undefined>();
  const [coApplicantAadhaarBack, setCoApplicantAadhaarBack] = useState<File | undefined>();
  const [coApplicantAadhaarFront, setCoApplicantAadhaarFront] = useState<File | undefined>();
  const [coApplicantRelationProof, setCoApplicantRelationProof] = useState<File | undefined>();
  const [coApplicantName, setCoApplicantName] = useState<string>("Co-Applicant’s");
  const isIITRopar = profile?.program_profile_type === ProgramProfileType.IIT_ROPAR;
  const isIITMandiV2 = profile?.program_profile_type === ProgramProfileType.IIT_MANDI_V2;
  const [relationShipSubText, setRelationShipSubText] = useState<string>("Relationship proof with you*");

  const handleChange = (keyName: string, value: string) => {
    setProfileDetailForm({ ...profileDetailForm, [keyName]: value });
  };

  const handleOnSuccess = async (presignedUrl: string, imageFile: File): Promise<string> => {
    const imageData = imageFile;
    const fileType = imageData as File;
    try {
      await axios.put(presignedUrl, imageData, {
        headers: {
          "Content-Type": fileType.type || "image/png",
        },
      });
      const imageUrl = presignedUrl.split("?")[0];
      return imageUrl;
    } catch (error) {
      console.error("Error uploading image:", error);
      return "";
    }
  };

  useEffect(() => {
    const coApplicantName = isIITRopar
      ? `Your ${profile?.meta?.coApplicantForRopar}'s`
      : isIITMandiV2
      ? `Your ${profile?.meta?.coApplicantForMandiV2}'s`
      : `Your ${profile?.meta?.coApplicant || "Co-Applicant"}’s`;
    setCoApplicantName(coApplicantName);

    const coApplicant = isIITRopar ? profile?.meta?.coApplicantForRopar : isIITMandiV2 ? profile?.meta?.coApplicantForMandiV2 : profile?.meta?.coApplicant;
    if (coApplicant === "Mother" || coApplicant === "Relative") {
      const relationShipSubText = coApplicant === "Mother" ? "(Your 10th or 12th Marksheet)" : "(Relationship proof with you)";
      setCoApplicant(coApplicant);
      setRelationShipSubText(relationShipSubText);
    }
    let employmentType = isIITRopar ? profile?.meta?.employmentTypeForRopar : isIITMandiV2 ? profile?.meta?.employmentTypeForMandiV2 : profile?.meta?.employmentType;

    if (coApplicant) {
      employmentType = isIITRopar ? profile?.meta?.coApplicantEmploymentTypeForRopar : isIITMandiV2 ? profile?.meta?.coApplicantEmploymentTypeForMandiV2 : profile?.meta?.coApplicantEmploymentType;
    }

    if (employmentType) {
      setIsSelfEmployed(employmentType === "self_employed");
    }
  }, [profile?.meta]);

  return (
    <Box mt={8} display={"flex"} flexDir={"column"} gap={5}>
      <Text textStyle={"h5"} mb={1}>
        Your Co-Applicant Info
      </Text>
      <Box display={"flex"} flexDir={{ base: "column", md: "row" }} gap={{ base: 5, md: 16 }}>
        {/* Parent's email address */}
        <Box w={"full"} maxW={"510px"}>
          <Text textStyle={"body2-md"} color={"black"}>
            {isAadhaarVerified ? 1 : 2}. {coApplicantName} Email Address*
          </Text>
          <Box>
            <Input
              type="text"
              name="coApplicantEmail"
              color={"blackAlpha.900"}
              value={isIITRopar ? profileDetailForm?.coApplicantEmailForRopar || "" : isIITMandiV2 ? profileDetailForm?.coApplicantEmailForMandiV2 || "" : profileDetailForm?.coApplicantEmail || ""}
              bg={"whiteAlpha.700"}
              size="lg"
              onChange={(data) => handleChange(isIITRopar ? "coApplicantEmailForRopar" : isIITMandiV2 ? "coApplicantEmailForMandiV2" : "coApplicantEmail", data.target.value.trim())}
            />
          </Box>
        </Box>

        {/* Parent phone number */}
        <Box w={"full"} maxW={"510px"}>
          <Text textStyle={"body2-md"} color={"black"}>
            {isAadhaarVerified ? 2 : 3}.{coApplicantName} Phone Number*
          </Text>
          <Box>
            <Input
              type="tel"
              value={
                isIITRopar
                  ? profileDetailForm?.coApplicantPhoneNumberForRopar || ""
                  : isIITMandiV2
                  ? profileDetailForm?.coApplicantPhoneNumberForMandiV2 || ""
                  : profileDetailForm?.coApplicantPhoneNumber || ""
              }
              name="coApplicantPhoneNumber"
              color={"blackAlpha.900"}
              bg={"whiteAlpha.700"}
              size="lg"
              onChange={(data) => {
                if (!pattern.test(data.target.value)) {
                  setIsMobileInputInvalid(true);
                } else {
                  setIsMobileInputInvalid(false);
                }
                handleChange(isIITRopar ? "coApplicantPhoneNumberForRopar" : isIITMandiV2 ? "coApplicantPhoneNumberForMandiV2" : "coApplicantPhoneNumber", data.target.value.trim());
              }}
            />
            {isMobileInputInvalid && (
              <Text color="ms-error" textStyle="body2">
                Invalid Mobile Number
              </Text>
            )}
          </Box>
        </Box>
      </Box>

      <Box display={"flex"} flexDir={{ base: "column", md: "row" }} gap={{ base: 5, md: 16 }}>
        {/* Parent name */}
        <Box maxW={"510px"} w={"full"}>
          <Text textStyle={"body2-md"} color={"black"}>
            {isAadhaarVerified ? 3 : 4}. {coApplicantName} Name*
          </Text>
          <Box>
            <Input
              type="text"
              name="coApplicantName"
              value={isIITRopar ? profileDetailForm?.coApplicantNameForRopar || "" : isIITMandiV2 ? profileDetailForm?.coApplicantNameForMandiV2 || "" : profileDetailForm?.coApplicantName || ""}
              color={"blackAlpha.900"}
              bg={"whiteAlpha.700"}
              size="lg"
              onChange={(data) => handleChange(isIITRopar ? "coApplicantNameForRopar" : isIITMandiV2 ? "coApplicantNameForMandiV2" : "coApplicantName", data.target.value.trim())}
            />
          </Box>
        </Box>

        {/* Business/company name */}
        <Box maxW={"510px"} w={"full"}>
          <Text textStyle={"body2-md"} color={"black"}>
            {isAadhaarVerified ? 4 : 5}. {coApplicantName} {isSelfEmployed ? "business name" : "company name"}*
          </Text>
          <Box>
            <Input
              type="text"
              value={
                isIITRopar
                  ? isSelfEmployed
                    ? profileDetailForm?.coApplicantBusinessNameForRopar || ""
                    : profileDetailForm?.coApplicantCompanyNameForRopar || ""
                  : isIITMandiV2
                  ? isSelfEmployed
                    ? profileDetailForm?.coApplicantBusinessNameForMandiV2 || ""
                    : profileDetailForm?.coApplicantCompanyNameForMandiV2 || ""
                  : isSelfEmployed
                  ? profileDetailForm?.coApplicantBusinessName || ""
                  : profileDetailForm?.coApplicantCompanyName || ""
              }
              name="coApplicantCompanyName"
              color={"blackAlpha.900"}
              bg={"whiteAlpha.700"}
              size="lg"
              onChange={(data) =>
                handleChange(
                  isIITRopar
                    ? isSelfEmployed
                      ? "coApplicantBusinessNameForRopar"
                      : "coApplicantCompanyNameForRopar"
                    : isIITMandiV2
                    ? isSelfEmployed
                      ? "coApplicantBusinessNameForMandiV2"
                      : "coApplicantCompanyNameForMandiV2"
                    : isSelfEmployed
                    ? "coApplicantBusinessName"
                    : "coApplicantCompanyName",
                  data.target.value.trim()
                )
              }
            />
          </Box>
        </Box>
      </Box>

      <Box display={"flex"} flexDir={{ base: "column", md: "row" }} gap={{ base: 5, md: 16 }}>
        {/* Monthly income */}
        <Box maxW={"510px"} w={"full"}>
          <Text textStyle={"body2-md"} color={"black"}>
            {isAadhaarVerified ? 5 : 6}. {coApplicantName} monthly {isSelfEmployed ? "income" : "salary"}*
          </Text>
          <Box>
            <Input
              type="number"
              value={
                isIITRopar
                  ? isSelfEmployed
                    ? profileDetailForm?.coApplicantMonthlyBusinessIncomeForRopar || ""
                    : profileDetailForm?.coApplicantMonthlySalaryForRopar || ""
                  : isIITMandiV2
                  ? isSelfEmployed
                    ? profileDetailForm?.coApplicantMonthlyBusinessIncomeForMandiV2 || ""
                    : profileDetailForm?.coApplicantMonthlySalaryForMandiV2 || ""
                  : isSelfEmployed
                  ? profileDetailForm?.coApplicantMonthlyBusinessIncome || ""
                  : profileDetailForm?.coApplicantMonthlySalary || ""
              }
              name="coApplicantMonthlyIncome"
              color={"blackAlpha.900"}
              bg={"whiteAlpha.700"}
              size="lg"
              onChange={(data) =>
                handleChange(
                  isIITRopar
                    ? isSelfEmployed
                      ? "coApplicantMonthlyBusinessIncomeForRopar"
                      : "coApplicantMonthlySalaryForRopar"
                    : isIITMandiV2
                    ? isSelfEmployed
                      ? "coApplicantMonthlyBusinessIncomeForMandiV2"
                      : "coApplicantMonthlySalaryForMandiV2"
                    : isSelfEmployed
                    ? "coApplicantMonthlyBusinessIncome"
                    : "coApplicantMonthlySalary",
                  data.target.value.trim()
                )
              }
            />
          </Box>
        </Box>
      </Box>

      <Text textStyle={"h5"} mb={1} mt={5}>
        Your Co-Applicant Documents
      </Text>
      <Box display={"flex"} flexDir={{ base: "column", md: "row" }} gap={{ base: 5, md: 16 }}>
        {/* ----------------------- Bank Statement Start ---------------*/}
        <Box maxW={"510px"} w={"full"}>
          <Text textStyle={"body2-md"} color={"black"} display={"flex"} gap={"2px"}>
            {isAadhaarVerified ? 4 : 7}.
            <CustomFormHeading
              url={
                isIITRopar
                  ? profileDetailForm?.coApplicantBankStatementForRopar || ""
                  : isIITMandiV2
                  ? profileDetailForm?.coApplicantBankStatementForMandiV2 || ""
                  : profileDetailForm?.coApplicantBankStatement || ""
              }
              heading={`${coApplicantName} 12 months’ bank statement`}
            />
          </Text>
          <CustomUploadFileInput
            disabled={false}
            acceptedFileTypes={["PDF"]}
            toastMessage="Bank Statement uploaded successfully"
            fileUploadData={bankStatement as File}
            setFileUploadData={setBankStatement}
            handleDelete={() => {
              if (isIITRopar) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantBankStatementForRopar: undefined });
              } else if (isIITMandiV2) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantBankStatementForMandiV2: undefined });
              } else {
                setProfileDetailForm({ ...profileDetailForm, coApplicantBankStatement: undefined });
              }
            }}
            handleFileUpload={async (presignedUrls: GetS3PresignedUrlType) => {
              const imageUrl = await handleOnSuccess(presignedUrls.presigned_url_for_pan_back, bankStatement as File);
              if (isIITRopar) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantBankStatementForRopar: imageUrl });
              } else if (isIITMandiV2) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantBankStatementForMandiV2: imageUrl });
              } else {
                setProfileDetailForm({ ...profileDetailForm, coApplicantBankStatement: imageUrl });
              }
            }}
          />
        </Box>
        {/* -------------------- Bank Statement End ---------------*/}

        {/*-------------------- Co-Applicant Profile Picture Start -----------------*/}
        <Box maxW={"510px"} w={"full"}>
          <Text textStyle={"body2-md"} color={"black"} display={"flex"} gap={"2px"}>
            {isAadhaarVerified ? 5 : 8}.
            <CustomFormHeading
              url={
                isIITRopar
                  ? profileDetailForm?.coApplicantProfilePicForRopar || ""
                  : isIITMandiV2
                  ? profileDetailForm?.coApplicantProfilePicForMandiV2 || ""
                  : profileDetailForm?.coApplicantProfilePic || ""
              }
              heading={`${coApplicantName} Profile Picture*`}
            />
          </Text>
          <CustomUploadFileInput
            disabled={false}
            toastMessage="Profile Picture Uploaded Successfully"
            fileUploadData={profilePic as File}
            setFileUploadData={setProfilePic}
            handleDelete={() => {
              if (isIITRopar) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantProfilePicForRopar: undefined });
              } else if (isIITMandiV2) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantProfilePicForMandiV2: undefined });
              } else {
                setProfileDetailForm({ ...profileDetailForm, coApplicantProfilePic: undefined });
              }
            }}
            handleFileUpload={async (presignedUrls: GetS3PresignedUrlType) => {
              const imageUrl = await handleOnSuccess(presignedUrls.presigned_url_for_co_applicant_profile_pic, profilePic as File);
              if (isIITRopar) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantProfilePicForRopar: imageUrl });
              } else if (isIITMandiV2) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantProfilePicForMandiV2: imageUrl });
              } else {
                setProfileDetailForm({ ...profileDetailForm, coApplicantProfilePic: imageUrl });
              }
            }}
          />
        </Box>
        {/*-------------------- Co-Applicant Profile Picture End -----------------*/}
      </Box>

      <Box display={"flex"} flexDir={{ base: "column", md: "row" }} gap={{ base: 5, md: 16 }}>
        {/*--------------------- Co-Applicant Pan Front Start --------------------- */}
        <Box maxW={"510px"} w={"full"}>
          <Text textStyle={"body2-md"} color={"black"} display={"flex"} gap={"2px"}>
            {isAadhaarVerified ? 6 : 9}.
            <CustomFormHeading
              url={
                isIITRopar ? profileDetailForm?.coApplicantPanCardForRopar || "" : isIITMandiV2 ? profileDetailForm?.coApplicantPanCardForMandiV2 || "" : profileDetailForm?.coApplicantPanCard || ""
              }
              heading={`${coApplicantName} Pan Card (Front)*`}
            />
          </Text>
          <CustomUploadFileInput
            disabled={false}
            toastMessage="PAN Card Uploaded Successfully"
            fileUploadData={coApplicantPanFront as File}
            setFileUploadData={setCoApplicantPanFront}
            handleDelete={() => {
              if (isIITRopar) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantPanCardForRopar: undefined });
              } else if (isIITMandiV2) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantPanCardForMandiV2: undefined });
              } else {
                setProfileDetailForm({ ...profileDetailForm, coApplicantPanCard: undefined });
              }
            }}
            handleFileUpload={async (presignedUrls: GetS3PresignedUrlType) => {
              const imageUrl = await handleOnSuccess(presignedUrls.presigned_url_for_pan_front, coApplicantPanFront as File);
              if (isIITRopar) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantPanCardForRopar: imageUrl });
              } else if (isIITMandiV2) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantPanCardForMandiV2: imageUrl });
              } else {
                setProfileDetailForm({ ...profileDetailForm, coApplicantPanCard: imageUrl });
              }
            }}
          />
        </Box>
        {/*--------------------- Co-Applicant Pan Front End --------------------- */}

        {/*------------------- Co-Applicant Aadhaar Front Picture Start ---------------*/}
        <Box maxW={"510px"} w={"full"}>
          <Text textStyle={"body2-md"} color={"black"} display={"flex"} gap={"2px"}>
            {isAadhaarVerified ? 7 : 10}.
            <CustomFormHeading
              url={
                isIITRopar
                  ? profileDetailForm?.coApplicantAadhaarFrontForRopar || ""
                  : isIITMandiV2
                  ? profileDetailForm?.coApplicantAadhaarFrontForMandiV2 || ""
                  : profileDetailForm?.coApplicantAadhaarFront || ""
              }
              heading={`${coApplicantName} Aadhaar Picture (Front)*`}
            />
          </Text>
          <CustomUploadFileInput
            disabled={false}
            toastMessage="Aadhaar Front Uploaded Successfully"
            fileUploadData={coApplicantAadhaarFront as File}
            setFileUploadData={setCoApplicantAadhaarFront}
            handleDelete={() => {
              if (isIITRopar) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantAadhaarFrontForRopar: undefined });
              } else if (isIITMandiV2) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantAadhaarFrontForMandiV2: undefined });
              } else {
                setProfileDetailForm({ ...profileDetailForm, coApplicantAadhaarFront: undefined });
              }
            }}
            handleFileUpload={async (presignedUrls: GetS3PresignedUrlType) => {
              const imageUrl = await handleOnSuccess(presignedUrls.presigned_url_for_co_applicant_aadhaar_front, coApplicantAadhaarFront as File);
              if (isIITRopar) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantAadhaarFrontForRopar: imageUrl });
              } else if (isIITMandiV2) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantAadhaarFrontForMandiV2: imageUrl });
              } else {
                setProfileDetailForm({ ...profileDetailForm, coApplicantAadhaarFront: imageUrl });
              }
            }}
          />
        </Box>
        {/*------------------- Co-Applicant Aadhaar Front Picture End ---------------*/}
      </Box>

      <Box display={"flex"} flexDir={{ base: "column", md: "row" }} gap={{ base: 5, md: 16 }}>
        {/*--------------------- Co-Applicant Aadhaar Back Start------------------------*/}
        <Box maxW={"510px"} w={"full"}>
          <Text textStyle={"body2-md"} color={"black"} display={"flex"} gap={"2px"}>
            {isAadhaarVerified ? 8 : 11}.
            <CustomFormHeading
              url={
                isIITRopar
                  ? profileDetailForm?.coApplicantAadhaarBackForRopar || ""
                  : isIITMandiV2
                  ? profileDetailForm?.coApplicantAadhaarBackForMandiV2 || ""
                  : profileDetailForm?.coApplicantAadhaarBack || ""
              }
              heading={`${coApplicantName} Aadhaar Picture (Back)*`}
            />
          </Text>
          <CustomUploadFileInput
            disabled={false}
            toastMessage="Aadhaar Back Uploaded Successfully"
            fileUploadData={coApplicantAadhaarBack as File}
            setFileUploadData={setCoApplicantAadhaarBack}
            handleDelete={() => {
              if (isIITRopar) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantAadhaarBackForRopar: undefined });
              } else if (isIITMandiV2) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantAadhaarBackForMandiV2: undefined });
              } else {
                setProfileDetailForm({ ...profileDetailForm, coApplicantAadhaarBack: undefined });
              }
            }}
            handleFileUpload={async (presignedUrls: GetS3PresignedUrlType) => {
              const imageUrl = await handleOnSuccess(presignedUrls.presigned_url_for_co_applicant_aadhaar_back, coApplicantAadhaarBack as File);
              if (isIITRopar) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantAadhaarBackForRopar: imageUrl });
              } else if (isIITMandiV2) {
                setProfileDetailForm({ ...profileDetailForm, coApplicantAadhaarBackForMandiV2: imageUrl });
              } else {
                setProfileDetailForm({ ...profileDetailForm, coApplicantAadhaarBack: imageUrl });
              }
            }}
          />
        </Box>
        {/*--------------------- Co-Applicant Aadhaar Back End -------------------------*/}

        {/*--------------------- Co-Applicant Relationship Proof Start------------------*/}
        {((coApplicant && coApplicant === "Mother") || coApplicant === "Relative") && (
          <Box maxW={"510px"} w={"full"}>
            <Text textStyle={"body2-md"} color={"black"} display={"flex"} gap={"2px"}>
              {isAadhaarVerified ? 9 : 12}.{" "}
              <CustomFormHeading
                url={
                  isIITRopar
                    ? profileDetailForm?.coApplicantRelationProofForRopar || ""
                    : isIITMandiV2
                    ? profileDetailForm?.coApplicantRelationProofForMandiV2 || ""
                    : profileDetailForm?.coApplicantRelationProof || ""
                }
                heading={`${coApplicantName} Relationship Proof with you*`}
                subHeading={`${relationShipSubText}`}
              />
            </Text>
            <CustomUploadFileInput
              disabled={false}
              acceptedFileTypes={["PDF"]}
              toastMessage="Relationship Proof Uploaded Successfully"
              fileUploadData={coApplicantRelationProof as File}
              setFileUploadData={setCoApplicantRelationProof}
              handleDelete={() => {
                if (isIITRopar) {
                  setProfileDetailForm({ ...profileDetailForm, coApplicantRelationProofForRopar: undefined });
                } else if (isIITMandiV2) {
                  setProfileDetailForm({ ...profileDetailForm, coApplicantRelationProofForMandiV2: undefined });
                } else {
                  setProfileDetailForm({ ...profileDetailForm, coApplicantRelationProof: undefined });
                }
              }}
              handleFileUpload={async (presignedUrls: GetS3PresignedUrlType) => {
                const imageUrl = await handleOnSuccess(presignedUrls.presigned_url_for_co_applicant_relation_proof, coApplicantRelationProof as File);
                if (isIITRopar) {
                  setProfileDetailForm({ ...profileDetailForm, coApplicantRelationProofForRopar: imageUrl });
                } else if (isIITMandiV2) {
                  setProfileDetailForm({ ...profileDetailForm, coApplicantRelationProofForMandiV2: imageUrl });
                } else {
                  setProfileDetailForm({ ...profileDetailForm, coApplicantRelationProof: imageUrl });
                }
              }}
            />
          </Box>
        )}
        {/*--------------------- Co-Applicant Relationship Proof End -------------------*/}
      </Box>
    </Box>
  );
};

export default CoApplicantForm;
