/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Text } from "@chakra-ui/react";
import moment from "moment";
import { DayTimeSlot, SlotTime } from "./utils";

type Props = {
  selectedDaySlot?: DayTimeSlot;
  selectedTimeSlot?: SlotTime;
  setSelectedTimeSlot: (value: any) => void;
};

export const TimeSelection = ({
  selectedDaySlot,
  selectedTimeSlot,
  setSelectedTimeSlot,
}: Props) => {
  return (
    <Box display={"flex"} flexDirection={"column"} py="2" maxW={{ base: "full", sm: "680px" }}>
      <Text color="dark" fontWeight={500} fontSize="15px" mb="2">
        Available Slots
      </Text>
      {selectedDaySlot && selectedDaySlot?.slotTimes?.length > 0 ? (
        <Box maxW="full" overflowX="auto" p="2" pl="0">
          <Box display="flex" flexWrap="wrap" gap="4" px="4px">
            {selectedDaySlot?.slotTimes.map((slotTime: any, index: number) => {
              const isSelected = selectedTimeSlot?.time === slotTime.time;
              return (
                <Button
                  key={index}
                  whiteSpace="nowrap"
                  borderWidth={isSelected ? "1px" : undefined}
                  borderColor={isSelected ? "#5a85e4" : "#CCC"}
                  color={isSelected ? "#18222D" : "#1C1C28"}
                  backgroundColor={isSelected ? "#F2F6FF" : "#FFF"}
                  onClick={() => setSelectedTimeSlot(slotTime)}
                  _hover={{
                    color: isSelected ? "#18222D" : "gray",
                  }}
                >
                  {moment(slotTime.time).format("hh:mm a").toUpperCase()}
                </Button>
              );
            })}
          </Box>
        </Box>
      ) : (
        <Box
          border={"1px solid #CCCCCC"}
          bg={"white"}
          rounded={"8px"}
          py={10}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          mb={3}
        >
          No slot available
        </Box>
      )}
    </Box>
  );
};
