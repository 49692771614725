import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const PeopleSpeak = (props: Props) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M13.958 3.63398C15.6413 5.46732 15.6413 8.00898 13.958 9.69232L12.558 8.28398C13.258 7.30065 13.258 6.02565 12.558 5.04232L13.958 3.63398ZM16.7163 0.833984C19.9997 4.20898 19.9663 9.25898 16.7163 12.5007L15.358 11.1423C17.6663 8.49232 17.6663 4.70898 15.358 2.19232L16.7163 0.833984ZM11.133 11.4673C12.758 12.0173 14.1663 12.9173 14.1663 14.1673V18.334H0.833008V14.1673C0.833008 12.9173 2.24134 12.0173 3.87467 11.4673L4.39134 12.5007L4.58301 12.9173C3.33301 13.3007 2.41634 13.8507 2.41634 14.1673V16.7507H5.93301L6.66634 12.5257L5.88301 10.959C6.39967 10.9007 6.94134 10.859 7.49967 10.859C8.05801 10.859 8.59967 10.9007 9.11634 10.959L8.33301 12.5257L9.06634 16.7507H12.583V14.1673C12.583 13.8507 11.6663 13.3007 10.4163 12.9173L10.608 12.5007L11.133 11.4673ZM7.49967 5.00065C6.58301 5.00065 5.83301 5.75065 5.83301 6.66732C5.83301 7.58398 6.58301 8.33398 7.49967 8.33398C8.41634 8.33398 9.16634 7.58398 9.16634 6.66732C9.16634 5.75065 8.41634 5.00065 7.49967 5.00065ZM7.49967 10.0007C5.65801 10.0007 4.16634 8.50898 4.16634 6.66732C4.16634 4.82565 5.65801 3.33398 7.49967 3.33398C9.34134 3.33398 10.833 4.82565 10.833 6.66732C10.833 8.50898 9.34134 10.0007 7.49967 10.0007Z"
      fill="currentColor"
    />
  </Icon>
);

export default PeopleSpeak;
