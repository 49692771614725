import React from "react";
import { useAllSpeakers } from "../../../../api/utils/hooks/useAdmin";
import { Box, Button, Text } from "@chakra-ui/react";
import { GetSpeakerType } from "../../../../api/schemas/schema";

interface Props {
  setSelectedSpeakers: (speakers: GetSpeakerType[]) => void;
  selectedSpeakers: GetSpeakerType[];
}

const SpeakerListModal = ({ setSelectedSpeakers, selectedSpeakers }: Props) => {
  const { data: allSpeakers } = useAllSpeakers();
  return (
    <Box>
      {allSpeakers?.map((speaker, index) => (
        <Box mb="16px" key={index} display={"flex"} alignItems={"center"} gap="16px">
          <Button
            variant="primary"
            onClick={() => {
              if (selectedSpeakers.find((selectedSpeaker) => selectedSpeaker.id === speaker.id)) {
                setSelectedSpeakers(
                  selectedSpeakers.filter((selectedSpeaker) => selectedSpeaker.id !== speaker.id)
                );
              } else {
                setSelectedSpeakers([...selectedSpeakers, speaker]);
              }
            }}
          >
            {selectedSpeakers.find((selectedSpeaker) => selectedSpeaker.id === speaker.id)
              ? "Remove"
              : "Add"}
          </Button>
          <Box>
            <Text textStyle="body2-md">{speaker.name}</Text>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default SpeakerListModal;
