import { Flex, Grid, Icon, Text, VStack } from "@chakra-ui/react";
import { MdOutlinePeopleAlt, MdOutlinePersonPinCircle } from "react-icons/md";
import { IoMdClipboard, IoMdCalendar } from "react-icons/io";
import React from "react";

function Item({ icon, num, text }: { icon: React.ReactElement; num: string; text: string }) {
  return (
    <Flex gap={3} textAlign={"left"}>
      <span>{icon}</span>
      <span>
        <Text color={"ms-blue.500"} textStyle={{ base: "h3", md: "h2" }}>
          {num}
        </Text>
        <Text fontSize={{ base: "sm", md: "lg" }}>{text}</Text>
      </span>
    </Flex>
  );
}

const iconSize = { base: 6, md: 10 };
const icons = [
  {
    icon: <Icon color="#A4C0FF" as={MdOutlinePeopleAlt} w={iconSize} h={iconSize} />,
    num: "10000+",
    text: "Participants",
  },
  {
    icon: <Icon color="#A4C0FF" as={MdOutlinePersonPinCircle} w={iconSize} h={iconSize} />,
    num: "100+",
    text: "Speakers",
  },
  {
    icon: <Icon color="#A4C0FF" as={IoMdClipboard} w={iconSize} h={iconSize} />,
    num: "200+",
    text: "Hrs of content",
  },
  {
    icon: <Icon color="#A4C0FF" as={IoMdCalendar} w={iconSize} h={iconSize} />,
    num: "150+",
    text: "Workshops",
  },
];

function WhyAttendEvent() {
  return (
    <Flex
      justifyContent={"center"}
      backgroundColor={"#F2F6FF"}
      rounded="2xl"
      px={{ base: 4, md: 8 }}
      py={{ base: 4, md: 12 }}
    >
      <VStack spacing={"24px"} maxW={{ base: "100%", xl: "70%" }} style={{ textAlign: "center" }}>
        <Text fontFamily={"heading"} fontWeight={700} fontSize={{ base: "xl", md: "2xl" }}>
          Why should you attend our events?
        </Text>
        <Flex flexDirection="column" gap={2}>
          <Text fontSize={{ base: "sm", md: "md" }}>
            Elevate your skills, expand your horizons, and connect with a vibrant community full of
            like minded individuals by joining our events, masterclasses, and contests.
          </Text>
          <Text fontSize={{ base: "sm", md: "md" }}>
            Learn from industry experts, dive deep into focused subjects, and showcase your talents
            for a chance to win exciting prizes. Unleash your potential and embark on a journey of
            growth and excellence with us.
          </Text>
        </Flex>
        <Grid w="100%" templateColumns={{ md: "repeat(4, 1fr)", base: "repeat(2, 1fr)" }} gap={2}>
          {icons.map((icon, index) => (
            <Item {...icon} key={index} />
          ))}
        </Grid>
      </VStack>
    </Flex>
  );
}

export default WhyAttendEvent;
