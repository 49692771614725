import { useContext, useEffect, useMemo } from "react";
import { convertGetEventToActivity, fixHTMLBold, getEventTypeText } from "./Activity";
import { Box, Button, Center, Flex, HStack, Heading, Image, Skeleton, Text, VStack, keyframes, useBreakpointValue } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { CheckCircle, ClockOutline } from "../../assets/icons/components";
import CountDown from "./CountDown";
import QRCode from "react-qr-code";
import { useCurrentTime, useTimeLeft } from "./useCountDown";
import { WhatsappIcon } from "react-share";
import WhyAttendEvent from "./WhyAttend";
import Speaker from "./Speaker";
import { useHistory, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { Activity } from "./Activity";
import { useEvent } from "../../api/utils/hooks/useEvents";
import { joinEvent } from "../../api/utils/api/v2/activity";
import { axiosErrorHandler } from "../../api/utils/error";
import { useMutation } from "react-query";
import useSnackBar from "../common/general/SnackBar";
import { useProfile } from "../../api/utils/hooks/useProfile";
import { AppContext, ApplicationActionType } from "../../context/ApplicationContext";
import { ProgramProfileType } from "../../enums/ProgramType.enum";
dayjs.extend(advancedFormat);

function ActivityDetailsLoader({ id }: { id: number }) {
  const history = useHistory();
  const state = useContext(AppContext)[0];
  const { data: event, isLoading, isSuccess } = useEvent(id);
  const activity = useMemo(() => {
    if (!event) return null;
    return convertGetEventToActivity(event);
  }, [event]);

  useEffect(() => {
    if (event) {
      if (event.event_type === "IIT_MANDI_COUNSELING" && state.coursePreference !== ProgramProfileType.IIT_MANDI) {
        history.push(state.coursePreference === ProgramProfileType.IIT_GUWAHATI ? "/mc-iitg-cs" : "/");
      } else if (event.event_type === "IIT_COUNSELING" && state.coursePreference !== ProgramProfileType.IIT_GUWAHATI) {
        history.push(state.coursePreference === ProgramProfileType.IIT_MANDI ? "/iit-mandi-ai-ml" : "/");
      }
    }
  }, [event, state]);

  return isLoading ? <Skeleton h="100vh" /> : isSuccess && activity ? <ActivityDetails activity={activity} /> : <div>Error</div>;
}

const ping = keyframes`
    75%, 100% {
      transform: scale(2);
      opacity: 0;
  }
`;

function ActivityDetails({ isAdmin, activity }: { isAdmin?: boolean; activity: Activity }) {
  const dispatch = useContext(AppContext)[1];
  const snackbar = useSnackBar();
  const { data: profile, refetch: profileRefetch } = useProfile((data) => {
    dispatch({
      type: ApplicationActionType.SET_COURSE_PREFERENCE,
      payload: {
        coursePreference: data?.program_profile_type as ProgramProfileType,
      },
    });
  });

  const { timeLeft: timeLeftToStart } = useTimeLeft(activity?.startTime || "");
  const { timeLeft: timeLeftToEnd } = useTimeLeft(activity?.endTime || "");
  const bufferTime = 15 * 60 * 1000; // 10 minutes in milliseconds
  const isLive = timeLeftToStart && timeLeftToEnd && timeLeftToStart - bufferTime < 0 && timeLeftToEnd > 0;
  const isUpcoming = timeLeftToStart && timeLeftToStart - bufferTime > 0;
  const isMobile = useBreakpointValue({ base: true, md: false });

  const params = useParams() as { activityID: string };
  const activityID = parseInt(params.activityID);
  const { currentTime } = useCurrentTime();
  const timeNow = useMemo(() => (currentTime === null ? dayjs() : dayjs(currentTime)), [currentTime]);

  useEffect(() => {
    if (!activity || isAdmin) return;
    const endTime = dayjs(activity?.endTime);
    // add 15 minutes buffer time
    // endTime = endTime.add(15, "minute");
    if (timeNow.isAfter(endTime) && !activity.videoUrl) {
      // set a key in localstorage for further redirection
      const event_data = {
        activityID: `${activityID}`,
        message: "Event has ended",
      };
      localStorage.setItem("event_redirect", JSON.stringify(event_data));
      //window.location.href = `/activities`;
      window.location.href = `/`;
    }
  }, [activity, activityID]);

  const { mutate: joinEventMutation } = useMutation(joinEvent, {
    onSuccess: () => {
      console.log("joined");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      console.log("e:", e);
    },
  });

  return (
    <Box mb={{ base: "90px", md: "0px" }}>
      {!activity?.videoUrl && (
        <Box background={activity.bgHexColor} px={{ base: 2, md: 6 }} py={{ base: 3, md: 8 }} position={"relative"}>
          <Box position={"relative"} zIndex={2} display={"flex"} flexDirection={"column"} gap={{ base: 2, md: 4 }} maxW={{ md: "600px" }} paddingY={4} paddingX={2}>
            {!activity.videoUrl && (
              <Box display={"flex"} gap={1} ml="-2px">
                <CheckCircle color="green.300" boxSize={isMobile ? "24px" : "36px"} />
                <Text as="span" fontSize={isMobile ? "md" : "2xl"} fontWeight={600}>
                  You have registered successfully!
                </Text>
              </Box>
            )}

            <Heading fontWeight={800} as={isMobile ? "h3" : "h2"}>
              <span dangerouslySetInnerHTML={{ __html: fixHTMLBold(activity.heading) }} />
            </Heading>
            <Text>By {activity.speakers[0].shortAbout}</Text>

            <Flex flexDirection={{ base: "column", md: "row" }} alignItems={{ base: "flex-start", md: "center" }} flexWrap={"wrap"} gap={4}>
              <Box display={"flex"} alignItems={"center"} gap={2} borderRight={{ md: "solid 1px #A5A5A5" }} pr={4}>
                <CalendarIcon boxSize={"20px"} color={"#6E71CC"} />
                <Text fontWeight={600}>{dayjs(activity.startTime).format("Do MMM, dddd")}</Text>
              </Box>
              <Box display={"flex"} alignItems={"center"} gap={2}>
                <ClockOutline color="#6e71cc" boxSize="24px" />
                <Text fontWeight={600}>
                  {dayjs(activity.startTime).format("hh:mm a")} - {dayjs(activity.endTime).format("hh:mm a")}
                </Text>
              </Box>
            </Flex>
            {isLive ? (
              <Box mt="1" color="#3EC782" display="flex" alignItems="center" gap="2">
                <Box as="span" h="24px" w="24px" display="flex" alignItems="center" justifyContent="center">
                  <Text as="span" position="relative" display="flex" h="3" w="3">
                    <Text as="span" position="absolute" display="inline-flex" h="full" w="full" rounded="full" animation={`${ping} 1s cubic-bezier(0, 0, 0.2, 1) infinite`} bg="ms-green.300" />
                    <Text as="span" position="relative" display="inline-flex" rounded="full" h="3" w="3" bg="ms-green.300" />
                  </Text>
                </Box>
                <Text as="span" textStyle={{ base: "h6", md: "h4" }}>
                  Event is live now
                </Text>
              </Box>
            ) : (
              <></>
            )}
            <Box mt={{ base: 2, md: 3 }}>
              {isUpcoming ? (
                <CountDown till={activity.startTime} />
              ) : isLive ? (
                <Box display={{ base: "none", md: "block" }}>
                  <Button
                    w={{ md: "full" }}
                    maxW="469px"
                    as="a"
                    variant="primary"
                    href={`/activities/${activity.id}/details/live`}
                    onClick={() => {
                      joinEventMutation({ event_id: activity.id });
                    }}
                  >
                    Join Now
                  </Button>
                </Box>
              ) : (
                <Box h="64px"></Box>
              )}
            </Box>
          </Box>
          <Box h="full" right={0} zIndex={0} bottom={0} position={"absolute"} maxW={isMobile ? "67%" : ""}>
            {!isMobile ? (
              <Image h="full" objectFit="contain" objectPosition="bottom" alt={activity.heading} maxW={isMobile ? "100%" : "500px"} src={isMobile ? activity.mobileBgImage : activity.bgImage} />
            ) : activity.mobileBgImage ? (
              <Image
                h="full"
                ml="auto"
                objectFit="contain"
                objectPosition="bottom"
                alt={activity.heading}
                maxW={isMobile ? "67%" : "500px"}
                src={isMobile ? activity.mobileBgImage : activity.bgImage}
              />
            ) : (
              <></>
            )}
          </Box>
        </Box>
      )}
      {isLive ? (
        <Box background={activity.bgHexColor} w="full" py={isUpcoming ? 0 : { base: 2, md: 3 }} display={{ md: "none" }} px="4">
          <Box>
            <Button
              w={"full"}
              as="a"
              variant="primary"
              href={`/activities/${activity.id}/details/live`}
              onClick={() => {
                joinEventMutation({ event_id: activity.id });
              }}
            >
              Join Now
            </Button>
          </Box>
        </Box>
      ) : (
        <></>
      )}
      <VStack spacing={12} alignItems={"stretch"} px={{ md: 8, base: 4 }} mb={8}>
        {activity?.videoUrl && (
          <Box mt="20px" h="full" top="0px" rounded="16px" overflow="hidden" maxW={{ base: "100%" }} pt={{ base: "56.25%" }} position={{ base: "relative" }} transition="opacity 1s ease-in-out">
            <Box as={ReactPlayer} position={{ base: "absolute" }} left="0" top="0" width="100% !important" height={{ base: "100% !important" }} url={activity?.videoUrl} controls={true} />
          </Box>
        )}
        {!["IIT_COUNSELING", "IIT_MANDI_COUNSELING"].includes(activity.type) && !activity?.videoUrl && !isLive && timeLeftToEnd && timeLeftToEnd >= 0 && (
          <Box py={{ base: 4, md: 8 }}>
            <Flex alignItems="center" justifyContent="space-between" flexDirection={{ base: "column", md: "row" }} py={4} border="1px" borderColor="#3470E4" px={4} borderRadius="8px">
              <Box w="full">
                <HStack alignItems={"center"}>
                  <Box>
                    <WhatsappIcon size={isMobile ? 36 : 56} bgStyle={{ fill: "white" }} iconFillColor="#24d366" />
                  </Box>
                  <Box>
                    <Text fontSize={{ base: "md", md: "xl" }} fontFamily="Poppins" fontWeight="bold">
                      Join this event’s Whatsapp Group to get
                    </Text>
                  </Box>
                </HStack>
                <Flex flexDirection={{ base: "column", md: "row" }} mt="5" gap={2} pl={{ base: "0", md: "56px" }}>
                  <HStack>
                    <Box>
                      <svg style={{ marginLeft: 2 }} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10 18C14.4 18 18 14.4 18 10C18 5.6 14.4 2 10 2C5.6 2 2 5.6 2 10C2 14.4 5.6 18 10 18ZM10 0C15.5 0 20 4.5 20 10C20 15.5 15.5 20 10 20C4.5 20 0 15.5 0 10C0 4.5 4.5 0 10 0ZM15 11.9L14.3 13.2L9 10.3V5H10.5V9.4L15 11.9Z"
                          fill="#6E71CC"
                        />
                      </svg>
                    </Box>{" "}
                    <Box>
                      <Text color="#6E71CC" fontSize="sm" fontWeight={600}>
                        Timely Updates
                      </Text>
                    </Box>
                  </HStack>
                  <HStack>
                    <Box>
                      <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          opacity="0.2"
                          d="M20.25 4.5V8.25H3.75V4.5C3.75 4.30109 3.82902 4.11032 3.96967 3.96967C4.11032 3.82902 4.30109 3.75 4.5 3.75H19.5C19.6989 3.75 19.8897 3.82902 20.0303 3.96967C20.171 4.11032 20.25 4.30109 20.25 4.5Z"
                          fill="#6E71CC"
                        />
                        <path
                          d="M19.5 3H17.25V2.25C17.25 2.05109 17.171 1.86032 17.0303 1.71967C16.8897 1.57902 16.6989 1.5 16.5 1.5C16.3011 1.5 16.1103 1.57902 15.9697 1.71967C15.829 1.86032 15.75 2.05109 15.75 2.25V3H8.25V2.25C8.25 2.05109 8.17098 1.86032 8.03033 1.71967C7.88968 1.57902 7.69891 1.5 7.5 1.5C7.30109 1.5 7.11032 1.57902 6.96967 1.71967C6.82902 1.86032 6.75 2.05109 6.75 2.25V3H4.5C4.10218 3 3.72064 3.15804 3.43934 3.43934C3.15804 3.72064 3 4.10218 3 4.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H19.5C19.8978 21 20.2794 20.842 20.5607 20.5607C20.842 20.2794 21 19.8978 21 19.5V4.5C21 4.10218 20.842 3.72064 20.5607 3.43934C20.2794 3.15804 19.8978 3 19.5 3ZM6.75 4.5V5.25C6.75 5.44891 6.82902 5.63968 6.96967 5.78033C7.11032 5.92098 7.30109 6 7.5 6C7.69891 6 7.88968 5.92098 8.03033 5.78033C8.17098 5.63968 8.25 5.44891 8.25 5.25V4.5H15.75V5.25C15.75 5.44891 15.829 5.63968 15.9697 5.78033C16.1103 5.92098 16.3011 6 16.5 6C16.6989 6 16.8897 5.92098 17.0303 5.78033C17.171 5.63968 17.25 5.44891 17.25 5.25V4.5H19.5V7.5H4.5V4.5H6.75ZM19.5 19.5H4.5V9H19.5V19.5ZM15.9056 11.4694C15.9754 11.539 16.0307 11.6217 16.0684 11.7128C16.1062 11.8038 16.1256 11.9014 16.1256 12C16.1256 12.0986 16.1062 12.1962 16.0684 12.2872C16.0307 12.3783 15.9754 12.461 15.9056 12.5306L11.4056 17.0306C11.336 17.1004 11.2533 17.1557 11.1622 17.1934C11.0712 17.2312 10.9736 17.2506 10.875 17.2506C10.7764 17.2506 10.6788 17.2312 10.5878 17.1934C10.4967 17.1557 10.414 17.1004 10.3444 17.0306L8.09437 14.7806C7.95364 14.6399 7.87458 14.449 7.87458 14.25C7.87458 14.051 7.95364 13.8601 8.09437 13.7194C8.23511 13.5786 8.42598 13.4996 8.625 13.4996C8.82402 13.4996 9.01489 13.5786 9.15563 13.7194L10.875 15.4397L14.8444 11.4694C14.914 11.3996 14.9967 11.3443 15.0878 11.3066C15.1788 11.2688 15.2764 11.2494 15.375 11.2494C15.4736 11.2494 15.5712 11.2688 15.6622 11.3066C15.7533 11.3443 15.836 11.3996 15.9056 11.4694Z"
                          fill="#6E71CC"
                        />
                      </svg>
                    </Box>{" "}
                    <Box>
                      <Text color="#6E71CC" fontSize="sm" fontWeight={600}>
                        Access to Exclusive Events
                      </Text>
                    </Box>
                  </HStack>
                </Flex>
              </Box>
              <Flex alignItems={"flex-end"} justifyContent={"flex-end"} flexDirection={"column"}>
                {isMobile ? (
                  <>
                    <Center mt={4}>
                      <Button
                        as={"a"}
                        href={activity.whatsAppLink}
                        target="_blank"
                        variant="primary"
                        textTransform="uppercase"
                        onClick={() => {
                          joinEventMutation({ event_id: activity.id });
                        }}
                      >
                        join now <WhatsappIcon size={28} bgStyle={{ fill: "transparent" }} iconFillColor="#fff" />
                      </Button>
                    </Center>
                  </>
                ) : (
                  <>
                    <Flex flexDirection={"column"} width={200} justifyItems={"center"} alignItems={"center"} gap={2}>
                      <Text textAlign={"center"}>Scan this to join</Text>
                      <QRCode size={120} value={activity.whatsAppLink} />
                    </Flex>
                  </>
                )}
              </Flex>
            </Flex>
          </Box>
        )}
        <Flex py={{ md: 8 }} direction={{ base: "column", md: "row" }} textAlign={{ base: "center", md: "left" }}>
          <Box mb={{ base: 8, md: 0 }} borderRight={{ base: "none", md: "solid 1px #D9D9D9" }} pr={{ md: 10 }} minW={{ md: "300px" }} display="flex" justifyContent="center" flexDir="column">
            <Text mt={{ base: "8px", md: "0px" }} mb={{ md: 2 }}>
              About the event
            </Text>
            <Text fontWeight={700} fontFamily={"heading"} fontSize={{ base: "lg", md: "2xl" }}>
              {activity.title}
            </Text>
            <Text whiteSpace={"pre-line"} fontFamily={"heading"} fontSize={isMobile ? "2xl" : "4xl"} color="#6E71CC">
              {getEventTypeText(activity.type)}
            </Text>
          </Box>
          <Box pl={{ md: 10 }}>
            <Text whiteSpace={"pre-line"}>{activity.description}</Text>
          </Box>
        </Flex>

        {!activity?.videoUrl && <WhyAttendEvent />}
        {Array.isArray(activity?.speakers) && activity.speakers.map((speaker) => <Speaker key={speaker.name} speaker={speaker} />)}
      </VStack>
    </Box>
  );
}
export { ActivityDetails };
export default ActivityDetailsLoader;
