import React from "react";
import { SingleQuestion } from "./NewLaunchForm";
interface Props {
  question: SingleQuestion;
  course_type?: string;
}
const RadioQuestion = ({ question, course_type }: Props) => {
  return (
    <div key={question.name}>
      {question.radioOptions?.map((option, i) => (
        <input
          key={option.key}
          required
          type="radio"
          value={option.key}
          name={question.name}
          cf-label={option.value}
          cf-questions={question.title}
        />
      ))}
    </div>
  );
};

export default RadioQuestion;
