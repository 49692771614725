import { Box, Button } from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { GetAdminEventType } from "../../../../api/schemas/schema";

interface Props {
  event?: GetAdminEventType;
}

const PreviewEventModal = ({ event }: Props) => {
  const dataToSend = event;
  // Create a ref for the iframe
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const sendMessageToIframe = () => {
    // Access the iframe element using the ref
    const iframe = iframeRef.current;
    console.log("dataToSend:", dataToSend);
    if (iframe) {
      // Check if the iframe is ready to receive messages
      console.log("sending data:", dataToSend, iframe?.contentWindow);
      iframe?.contentWindow?.postMessage(
        dataToSend,
        `${window.location.protocol}//${process.env.REACT_APP_MASAI_WEBSITE_URL}`
      );
    }
  };

  useEffect(() => {
    // Set up the iframe event listener after the component has mounted
    const iframe = iframeRef.current;
    console.log("event in useeffect--:", event);
    if (iframe) {
      iframe.onload = () => {
        // Check if the iframe is ready to receive messages
        sendMessageToIframe();
      };
    }
  }, []); // Empty dependency array ensures this effect runs only once after mount

  return (
    <Box>
      <Box display="flex" gap="8px" mb="10px" alignItems="center">
        <Button onClick={sendMessageToIframe}>Refresh</Button>
      </Box>
      <iframe
        ref={iframeRef}
        src={`${window.location.protocol}//${process.env.REACT_APP_MASAI_WEBSITE_URL}/preview-event?hideNavbar=true&hideFooter=true`}
        title="Masai School Events"
        width="100%"
        height={"700px"}
      ></iframe>
      {/* <ActivityDetails activity_id={activityId} /> */}
    </Box>
  );
};

export default PreviewEventModal;
