import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const Search = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M10.6777 19.3554C15.4702 19.3554 19.3554 15.4702 19.3554 10.6777C19.3554 5.88513 15.4702 2 10.6777 2C5.88513 2 2 5.88513 2 10.6777C2 15.4702 5.88513 19.3554 10.6777 19.3554ZM10.6777 17.4389C6.94356 17.4389 3.91646 14.4118 3.91646 10.6777C3.91646 6.94356 6.94356 3.91646 10.6777 3.91646C14.4118 3.91646 17.4389 6.94356 17.4389 10.6777C17.4389 14.4118 14.4118 17.4389 10.6777 17.4389Z"
      fill="currentColor"
    />
    <path
      d="M15.5964 17.2368L20.3853 21.7398C20.7709 22.1024 21.3773 22.0837 21.7398 21.6982C22.1023 21.3126 22.0837 20.7062 21.6981 20.3437L16.9092 15.8406C16.5237 15.4781 15.9173 15.4968 15.5547 15.8823C15.1922 16.2679 15.2109 16.8743 15.5964 17.2368Z"
      fill="currentColor"
    />
  </Icon>
);

export default Search;
