import {
  Box,
  Button,
  Heading,
  Image,
  Input,
  Spinner,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { Rating } from "react-simple-star-rating";
import successImage from "../../../assets/images/success.gif";

const FeebackForm = ({ onSubmit }: { onSubmit: (rating: number, description: string) => void }) => {
  const [rating, setRating] = useState(0);
  const [description, setDescription] = useState("");
  const [showThanks, setShowThanks] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const headingSize = useBreakpointValue<"h4" | "h3">({
    base: "h4",
    md: "h3",
  });

  const handleRating = (data: number) => {
    setRating(data);
  };
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await onSubmit(rating, description);
      setShowThanks(true);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Box display="flex" flexDir="column">
      {!showThanks ? (
        <>
          <Heading
            as="h3"
            fontWeight="700"
            id="feedbackform"
            color="#0A0103"
            fontSize={{ base: "20px", md: "24px" }}
            px="4px"
          >
            How would you rate the process?
          </Heading>

          <Box mt="17px" as="form">
            <Box px="4px">
              <Rating
                size={32}
                transition
                allowFraction
                iconsCount={5}
                disableFillHover
                initialValue={0}
                onClick={handleRating}
                emptyIcon={
                  <svg
                    style={{ display: "inline" }}
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 4L23.5922 15.0557H35.2169L25.8123 21.8885L29.4046 32.9443L20 26.1115L10.5954 32.9443L14.1877 21.8885L4.7831 15.0557H16.4078L20 4Z"
                      fill="#E7E6E6"
                    />
                  </svg>
                }
                fillIcon={
                  <svg
                    style={{ display: "inline" }}
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 4L23.5922 15.0557H35.2169L25.8123 21.8885L29.4046 32.9443L20 26.1115L10.5954 32.9443L14.1877 21.8885L4.7831 15.0557H16.4078L20 4Z"
                      fill="#FFDB66"
                    />
                  </svg>
                }
              />
            </Box>

            <Box mt="25px" overflow="hidden">
              <Text textStyle="body2-md" px="4px">
                How was your experience?
              </Text>

              <Box mt="6px" px="4px">
                <Input
                  h="48px"
                  type="text"
                  borderRadius="8px"
                  value={description}
                  placeholder="Describe your experience here...."
                  onChange={(el) => {
                    const inputValue = el.target.value;
                    if (/^\s/.test(inputValue)) {
                      setDescription("");
                    } else {
                      setDescription(inputValue);
                    }
                  }}
                  _focusVisible={{
                    zIndex: 1,
                    borderColor: "#3470e4",
                    boxShadow: "#3470e4 0px 0px 0px 1px",
                  }}
                />

                <Box display="flex" justifyContent="flex-end">
                  <Button
                    mt="16px"
                    size="sm"
                    onClick={handleSubmit}
                    variant="primary"
                    isLoading={isLoading}
                    disabled={rating && !isLoading ? false : true}
                  >
                    submit
                    {isLoading && (
                      <Spinner
                        speed={"0.5s"}
                        thickness={"2px"}
                        size={"md"}
                        position={"absolute"}
                        color={"ms-blue.500"}
                      />
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box
          gap="6px"
          display="flex"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          textAlign={"center"}
          minH="252px"
        >
          <Box overflow={"hidden"}>
            <Image
              w="120px"
              alt="Success"
              pointerEvents="none"
              transform="scale(2)"
              src={successImage}
            />
          </Box>
          <Heading as={headingSize} px="4px">
            Thank you for sharing your feedback with us!
          </Heading>
        </Box>
      )}
    </Box>
  );
};

export default FeebackForm;
