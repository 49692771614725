import { Box, Divider, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LeaderboardDataType } from "../../api/schemas/schema";
import { getLeaderboardByContestId } from "../../api/utils/api";
import { axiosErrorHandler } from "../../api/utils/error";

import useSnackBar from "../../components/common/general/SnackBar";
import Pagination from "../Profile/referrals/Pagination";

import LeaderboardRow from "./LeaderboardRow";
interface Props {
  contestName?: string;
  contestId?: number;
  pathname: string;
}
const LeaderboardModalContent = ({ contestName, contestId, pathname }: Props) => {
  const [leaderboard, setLeaderboard] = useState<LeaderboardDataType[]>();
  const [myScore, setMyScore] = useState<LeaderboardDataType>();
  const [loading, setLoading] = useState<boolean>(false);
  const search = useLocation().search;
  const page = new URLSearchParams(search).get("page");
  const currentOffset = page === null ? 0 : (Number(page) - 1) * 6;
  const [offset, setOffset] = useState<number>(currentOffset);
  const [totalLeaderboardEntries, setTotalLeaderboardEntries] = useState<number>(0);
  const limit = 7;
  const history = useHistory();

  const snackbar = useSnackBar();
  const getNextPage = () => {
    setOffset(offset + limit);
  };
  const getPrevPage = () => {
    setOffset(offset - limit);
  };
  useEffect(() => {
    const currentPage = offset / limit + 1;
    fetchLeaderboard(offset);
    history.push({
      pathname,
      search: `page=${currentPage}`,
    });
  }, [offset]);
  const fetchLeaderboard = async (offset = 0) => {
    if (!contestId) {
      return;
    }
    setLoading(true);
    try {
      await Promise.allSettled([
        getLeaderboardByContestId({ contestId, offset, limit }).then((res) => {
          // data, total
          setLeaderboard(res.data);
          setTotalLeaderboardEntries(res.total || 0);
          setMyScore(res.my_score);
        }),
      ]);
    } catch (err) {
      const e = axiosErrorHandler(err);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("something went wrong");
      }
    }
    setLoading(false);
  };
  return (
    <Box>
      <Heading as="h2">Leaderboard</Heading>
      {/* <Box mt={"ms-16"} d="flex" alignItems={"center"}>
        <Badge variant={"blue"} label="CONTEST" size="lg" />
      </Box> */}
      <Heading mt={"ms-8"} as="h4">
        {contestName}
      </Heading>

      <Box mt={"ms-24"}>
        {/* table header */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          py={"ms-8"}
          px={"ms-24"}
          alignItems={"center"}
        >
          <Box d={"flex"} alignItems={"center"}>
            <Text py={"ms-4"} textStyle={"body2-md"}>
              Rank
            </Text>
            <Text ml={"72px"} textStyle={"body2-md"}>
              User
            </Text>
          </Box>
          <Text py={"ms-4"} textStyle={"body2-md"}>
            Score
          </Text>
        </Box>
        {/* table rows */}
        <Box mt={"ms-8"}>
          {leaderboard && totalLeaderboardEntries > 0 ? (
            <>
              {leaderboard?.map((scoreData: LeaderboardDataType, i) => (
                <Box mb={"ms-8"} key={i}>
                  <LeaderboardRow scoreData={scoreData} />
                </Box>
              ))}
              {myScore?.rank && myScore.rank > limit && (
                <Box mb={"ms-8"}>
                  <Divider my={"ms-16"} />
                  <LeaderboardRow scoreData={myScore} currentUser={true} />
                </Box>
              )}
            </>
          ) : (
            <Box>
              <Text py={"ms-4"} textStyle={"body2"} textAlign={"center"}>
                {loading
                  ? "Loading..."
                  : "Leaderboard not available. Please check after some time."}
              </Text>
            </Box>
          )}
        </Box>
        {/* pagination */}
        <Box>
          {leaderboard && totalLeaderboardEntries > 0 && (
            <Pagination
              total={totalLeaderboardEntries}
              limit={limit}
              getNextPage={getNextPage}
              getPrevPage={getPrevPage}
              offset={offset}
              mb={"0x"}
              mt={"24px"}
            />
          )}{" "}
        </Box>
      </Box>
    </Box>
  );
};

export default LeaderboardModalContent;
