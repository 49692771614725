import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const Slack = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M21.6925 11.0768C22.9669 11.0768 24 10.0437 24 8.76928C24 7.49488 22.9668 6.46178 21.6924 6.46178C20.4179 6.46178 19.3846 7.49497 19.3846 8.76948V11.0768H21.6925ZM15.2308 11.0768C16.5053 11.0768 17.5385 10.0436 17.5385 8.76908V2.30794C17.5385 1.03343 16.5053 0.000244141 15.2308 0.000244141C13.9563 0.000244141 12.9231 1.03343 12.9231 2.30794V8.76908C12.9231 10.0436 13.9563 11.0768 15.2308 11.0768Z"
      fill="#2EB67D"
    />
    <path
      d="M2.30747 12.9232C1.03308 12.9232 -1.90735e-05 13.9563 -1.90735e-05 15.2307C-1.90735e-05 16.5051 1.03318 17.5382 2.30757 17.5382C3.58208 17.5382 4.61537 16.505 4.61537 15.2305V12.9232H2.30747ZM8.76921 12.9232C7.49471 12.9232 6.46152 13.9564 6.46152 15.2309V21.6921C6.46152 22.9666 7.49471 23.9998 8.76921 23.9998C10.0437 23.9998 11.0769 22.9666 11.0769 21.6921V15.2309C11.0769 13.9564 10.0437 12.9232 8.76921 12.9232Z"
      fill="#E01E5A"
    />
    <path
      d="M12.9232 21.6925C12.9232 22.9669 13.9563 24 15.2307 24C16.5051 24 17.5382 22.9668 17.5382 21.6924C17.5382 20.4179 16.505 19.3846 15.2305 19.3846L12.9232 19.3846L12.9232 21.6925ZM12.9232 15.2308C12.9232 16.5053 13.9564 17.5385 15.2309 17.5385L21.6921 17.5385C22.9666 17.5385 23.9998 16.5053 23.9998 15.2308C23.9998 13.9563 22.9666 12.9231 21.6921 12.9231L15.2309 12.9231C13.9564 12.9231 12.9232 13.9563 12.9232 15.2308Z"
      fill="#ECB22E"
    />
    <path
      d="M11.0767 2.30747C11.0767 1.03308 10.0436 -1.86345e-05 8.76916 -1.86902e-05C7.49476 -1.87459e-05 6.46166 1.03318 6.46166 2.30757C6.46166 3.58208 7.49485 4.61537 8.76936 4.61537L11.0766 4.61537L11.0767 2.30747ZM11.0766 8.76921C11.0766 7.49471 10.0435 6.46152 8.76896 6.46152L2.30781 6.46152C1.03331 6.46152 0.000122227 7.49471 0.000122171 8.76921C0.000122115 10.0437 1.03331 11.0769 2.30782 11.0769L8.76896 11.0769C10.0435 11.0769 11.0766 10.0437 11.0766 8.76921Z"
      fill="#36C5F0"
    />
  </Icon>
);

export default Slack;
