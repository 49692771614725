import {
  AadhaarInfoType,
  ErrorResponseModelType,
  SendAadharOTPResponse,
} from "../../schemas/schema";
import api from "../axios";
import { Client } from "../../generated/client";

type VerifyAOTPResponse = Awaited<
  ReturnType<Client["verify_aadhaar_otp_profiles__profile_slug__verify_aadhaar_otp_post"]>
>["data"];

export const sendOtpAadhaar = async ({
  profile_slug,
  application_slug,
  aadhaar_id,
  address,
  coc_isa_mbp_consent,
  scholarship_consent,
}: {
  profile_slug: string;
  application_slug: string;
  aadhaar_id: string;
  address: string;
  coc_isa_mbp_consent: boolean;
  scholarship_consent: boolean;
  }): Promise<SendAadharOTPResponse> => {
  const client = await api.client;
  const response = await client.send_aadhaar_otp_profiles__profile_slug__send_aadhaar_otp_post(
    profile_slug,
    null,
    {
      params: {
        application_slug,
        aadhaar_id,
        address,
        coc_isa_mbp_consent,
        scholarship_consent,
      },
    }
  );
  return response.data;
};

export const verifyOtpAadhaar = async ({
  profile_slug,
  application_slug,
  otp_input,
}: {
  profile_slug: string;
  application_slug: string;
    otp_input?: number;
}): Promise<VerifyAOTPResponse> => {
  const client = await api.client;
  const response = await client.verify_aadhaar_otp_profiles__profile_slug__verify_aadhaar_otp_post(
    profile_slug,
    null,
    {
      params: {
        application_slug,
        // otp_input,
      },
    }
  );
  return response.data;
};
export const processAadhaarOCR = async ({
  application_slug,
  coc_isa_mbp_consent,
  files,
}: {
  profile_slug: string;
  application_slug: string;
  coc_isa_mbp_consent: boolean;
  files: string[];
}): Promise<AadhaarInfoType> => {
  const client = await api.client;

  const response = await client.fetch_aadhar_info_through_ocr_fetch_aadhar_info_through_ocr_post(
    null,
    { aadhaar_front: files[0].split(",")[1], aadhaar_back: files[1].split(",")[1] },
    {
      params: {
        application_slug,
        coc_isa_mbp_consent,
      },
    }
  );
  return response.data;
};

export const confirmAadhaarDetails = async ({
  application_slug,
  ocr_aadhaar_info_entry_id,
}: {
  ocr_aadhaar_info_entry_id: number;
  application_slug: string;
}): Promise<ErrorResponseModelType> => {
  const client = await api.client;

  const response = await client.verify_aadhaar_verify_aadhaar_post(null, {
    application_slug,
    ocr_aadhaar_info_entry_id,
  });
  return response.data;
};
