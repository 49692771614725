import api from "../../axios";

export const createAssessment = async ({ for_practice, course_preference }: { for_practice: boolean; course_preference: number }) => {
  const client = await api.client;
  const response = await client.create_profile_assessment_v2_profile_assessment_post(null, {
    for_practice,
    course_preference,
  });
  return response.data;
};

export const getLastAssessment = async () => {
  const client = await api.client;
  const response = await client.get_last_profile_assessment_v2_profile_assessment_last_get();
  return response.data;
};

export const getBestAssessment = async () => {
  const client = await api.client;
  const response = await client.get_best_profile_assessment_v2_profile_assessment_best_get();
  return response.data;
};

export const getBestAssessmentForEmpowerHer = async () => {
  const client = await api.client;
  const response = await client.get_best_profile_assessment_v2_profile_assessment_best_for_empower_her_get();
  return response.data;
};

export const recordCancelAssessment = async () => {
  const client = await api.client;
  const response = await client.record_assessment_cancellation_v2_profile_record_assessment_cancellation_get();
  return response.data;
};
