import { Box, Button, Heading, Image, Text, useRadio, useRadioGroup } from "@chakra-ui/react";

import CheckedRadioImage from "../../../../assets/images/CheckedRadioImage.svg";
import UncheckedRadioImage from "../../../../assets/images/UncheckedRadioImage.svg";

import { sendTrackingEvent } from "../../../../components/common/utils/gtm";
import numberWithCommas from "../../utils/numberWithCommas";

interface Props {
  handleConfirm: (option: string) => void;
  coursePrice: number;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RadioCard = (props: any) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box {...checkbox} cursor="pointer" borderRadius="ms-16" h={"100%"}>
        {props.children}
      </Box>
    </Box>
  );
};
const ScholarshipOptionModal = ({ handleConfirm, coursePrice }: Props) => {
  const options = ["with-scholarship", "without-scholarship"];

  const { getRadioProps } = useRadioGroup({
    name: "scholarship select options",
    defaultValue: "with-scholarship",
    onChange: console.log,
  });

  const withScholarship = getRadioProps({ value: "with-scholarship" }).isChecked;
  const withoutScholarship = getRadioProps({ value: "without-scholarship" }).isChecked;

  return (
    <>
      <Box>
        <Box>
          <Heading as="h4">Select from the following: </Heading>
          <Box mt={"ms-24"} d={"grid"} rowGap={"ms-24"}>
            {options.map((value) => {
              const radio = getRadioProps({ value });
              return (
                <Box key={value}>
                  <RadioCard {...radio}>
                    <Box d={"flex"}>
                      {" "}
                      <Image
                        src={radio.isChecked ? CheckedRadioImage : UncheckedRadioImage}
                        alt="checked"
                        mr={{ base: "ms-4", md: "ms-8" }}
                        alignSelf={"flex-start"}
                      />
                      <Box>
                        <Heading mb={"ms-4"} as="h6">
                          {" "}
                          {value === "with-scholarship"
                            ? "With Scholarship"
                            : "Without Scholarship"}
                        </Heading>
                        {value === "with-scholarship" ? (
                          <Text color={"ms-grey.700"} textStyle={"body1"}>
                            (Take and clear the test to avail discount upto{" "}
                            <Text d={"inline"} color={"ms-blue.500"} textStyle={"body1-md"}>
                              ₹11,250
                            </Text>
                            )
                          </Text>
                        ) : (
                          <Text color={"ms-grey.700"} textStyle={"body1"}>
                            (Pay the full amount of{" "}
                            <Text d={"inline"} color={"ms-blue.500"} textStyle={"body1-md"}>
                              ₹{coursePrice && numberWithCommas(coursePrice)}
                            </Text>
                            )
                          </Text>
                        )}
                      </Box>
                    </Box>
                  </RadioCard>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box mt={"ms-24"} display={"flex"} justifyContent={"flex-end"}>
          <Button
            variant={"primary"}
            onClick={() => {
              handleConfirm(withScholarship ? "withScholarship" : "withoutScholarship");
              sendTrackingEvent({
                event: "sp_pp_msft_db_confirm",
                eventLabel: location.pathname,
              });
            }}
            disabled={withScholarship === false && withoutScholarship === false}
          >
            Continue
          </Button>{" "}
        </Box>
      </Box>
    </>
  );
};

export default ScholarshipOptionModal;
