import { Box, Button, Text, Select, Textarea } from "@chakra-ui/react";
import { useState } from "react";
import { RadioButtonGroup } from "../../common/general";
import { useMutation } from "react-query";
import useSnackBar from "../../common/general/SnackBar";
import { axiosErrorHandler } from "../../../api/utils/error";
import { sendTrackingEvent } from "../../common/utils/gtm";
import { useLocation } from "react-router-dom";
import { submitFeedback } from "../../../api/utils/api/v2/levelup_internal";
import { FeedbackType } from "../../../api/schemas/schema";

export type GetAlumniFeedbackFormType = {
  rating: string | null;
  feedback: string;
  queryType: string;
};

interface AlumniFeedbackFormProps {
  sessionId: string;
  setFeedbackModalOpen: (value: boolean) => void;
  alumniName: string;
}

const queryTypeOptions = ["Yes", "Partially", "No"];

const AlumniFeedbackForm = ({
  sessionId,
  setFeedbackModalOpen,
  alumniName,
}: AlumniFeedbackFormProps) => {
  const snackbar = useSnackBar();

  const [formValues, setFormValues] = useState<GetAlumniFeedbackFormType>({
    rating: null,
    feedback: "",
    queryType: "",
  });

  const location = useLocation();

  const { mutate: submitFeedbackMutation, isLoading: submitFeedbackLoading } = useMutation(
    submitFeedback,
    {
      onSuccess: () => {
        //
        setFeedbackModalOpen(false);
      },
      onError: (error) => {
        const e = axiosErrorHandler(error);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("Something went wrong");
        }
      },
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFormValuesChange = (key: string, value?: any) => {
    setFormValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const getIsDisabledValue = () => {
    if (formValues.rating === null || formValues.queryType === "" || formValues.feedback === "") {
      return true;
    }
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={{ md: "12px" }}
      padding={"8"}
      minW={{ base: "auto", lg: "572px" }}
      py="0"
    >
      <Text color="ms-grey.900" as="h4" textStyle={"h4"}>
        Feedback - Connect with {alumniName}
      </Text>

      <Box display={"flex"} flexDirection={"column"} gap={{ md: "24px" }}>
        {/* currently working */}
        <Box mt={"ms-16"}>
          <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
            1. How would you rate your conversation with our alumni?*
          </Text>
          <RadioButtonGroup
            options={[
              { key: "1", value: "1" },
              { key: "2", value: "2" },
              { key: "3", value: "3" },
              { key: "4", value: "4" },
              { key: "5", value: "5" },
            ]}
            selectedValue={formValues?.rating ? formValues?.rating?.toString() : ""}
            onChange={(key) => {
              handleFormValuesChange("rating", key);
            }}
            gridTemplateComlumns={{ base: "repeat(3, 1fr)", lg: "repeat(5,1fr)" }}
            columnGap={"ms-4"}
            rowGap={"ms-24"}
            disabled={false}
          />
        </Box>

        {/* query dropdown * */}
        <Box mt={"ms-16"}>
          <Text mb={"ms-8"} textStyle="body2-md" color="ms-red.900">
            2. Was our alumni able to solve your queries? *
          </Text>
          <Select
            name="queryType"
            value={formValues.queryType}
            onChange={(event) => handleFormValuesChange(event.target.name, event.target.value)}
          >
            <option value="" disabled selected>
              Select
            </option>
            {queryTypeOptions.map((queryType) => {
              return (
                <option key={queryType} value={queryType}>
                  {queryType}
                </option>
              );
            })}
          </Select>
        </Box>

        {/* feedback */}
        <Box mt={{ base: "ms-16", md: "0px" }}>
          <Text mb={{ base: "ms-2", md: "2px" }} textStyle="body2-md" color="ms-red.900">
            3. Any additional feedback you would wish to share?
          </Text>

          <Box mt={{ base: "ms-16", md: "0px" }}>
            <Textarea
              value={formValues.feedback || ""}
              onChange={(e) => handleFormValuesChange("feedback", e.target.value)}
              placeholder="Type here..."
              rows={4}
              cols={20}
            />
          </Box>
        </Box>
      </Box>

      <Box
        boxShadow={"0px -8px 32px 0px rgba(0, 0, 0, 0.10);"}
        display={"flex"}
        margin={"auto"}
        marginRight={"0px"}
        mt="4"
      >
        <Button
          isLoading={submitFeedbackLoading}
          variant="primary"
          onClick={() => {
            sendTrackingEvent({
              event: "d_counselling_submit",
              eventLabel: location.pathname,
            });
            const questions = [
              "How would you rate your conversation with our alumni?",
              "Was our alumni able to solve your queries?",
              "Any additional feedback you would wish to share?",
            ];
            const feedbacks = questions.map((feedback, indx) => {
              return {
                feedbackQuestion: feedback,
                rating: indx === 0 ? formValues.rating : "",
                feedback: indx === 1 ? formValues.queryType : indx === 2 ? formValues.feedback : "",
              };
            });
            const payload = {
              sessionId: sessionId,
              feedbacks,
            };

            submitFeedbackMutation(payload as FeedbackType);
          }}
          isDisabled={getIsDisabledValue()}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AlumniFeedbackForm;
