const calculateFinalPayment = ({
  referred_by,
  registrationFee,
  referralDiscount,
  discountedAmount,
  scholarDiscountPercentage,
}: {
  referred_by?: string;
  registrationFee: number;
  discountedAmount?: number;
  referralDiscount?: number;
  scholarDiscountPercentage?: number;
}) => {
  let showBanner = "";
  let finalAmount = registrationFee;

  // Referral Discount Only and should not have scholarDiscountPercentage > 0
  if (referred_by && referralDiscount && !(scholarDiscountPercentage || 0)) {
    showBanner = "Referral";
    finalAmount = Math.floor(
      (registrationFee && referralDiscount && registrationFee - referralDiscount) || 0
    );
  }

  // Scholarship Discount Only and should not have referred_by
  else if (scholarDiscountPercentage && !referred_by) {
    showBanner = "Scholar";
    finalAmount = Math.floor(registrationFee - registrationFee * (scholarDiscountPercentage / 100));
  }

  // Referral Discount + Scholarship Discount
  if (referred_by && referralDiscount && scholarDiscountPercentage) {
    showBanner = "Referral&Scholar";
    finalAmount = Math.floor(registrationFee - referralDiscount);
    finalAmount = Math.floor(finalAmount - finalAmount * (scholarDiscountPercentage / 100));
  }

  // For coupon discount calculation
  if (discountedAmount) {
    finalAmount = Math.floor(finalAmount - discountedAmount);
  }

  // if discount gets less then 50% of actual amount then the max discount becomes 50% of the total amount
  if (finalAmount < registrationFee * 0.5) {
    finalAmount = registrationFee * 0.5;
    return { finalAmount, showBanner, discount: Math.floor(registrationFee - finalAmount) };
  }

  return { finalAmount, showBanner, discount: Math.floor(registrationFee - finalAmount) };
};

export default calculateFinalPayment;
