import { CourseSelected } from "../../../../enums/ProgramType.enum";
import { RazorpaySuccessReportType, CreatePaymentRecordType, PaymentType } from "../../../schemas/schema";
import api from "../../axios";
export const RazorPaySuccessPost = async (data: RazorpaySuccessReportType) => {
  const client = await api.client;
  const response = await client.report_razorpay_success_v2_payment_record_report_razorpay_success_post(null, data);
  return response.data;
};

export const CreatePaymentRecordPost = async (data: CreatePaymentRecordType) => {
  const client = await api.client;
  const response = await client.create_payment_record_v2_payment_record_post(null, data);
  return response.data;
};

export const GetPaymentStatus = async (paymentType: PaymentType) => {
  const client = await api.client;
  const response = await client.get_payment_status_v2_payment_record_payment_status_get(paymentType);
  return response.data;
};

export const GetSemesterFee = async (course_profile: CourseSelected) => {
  const client = await api.client;
  const response = await client.semester_payment_v2_payment_record_semester_payment_get({
    course_offered_by: course_profile,
  });
  return response.data;
};

export const HandleRoparStudents = async () => {
  const client = await api.client;
  const response = await client.handle_ropar_student_v2_payment_record_ropar_student_post({});
  return response.data;
};

export const CreatePhonePayPayment = async (data: CreatePaymentRecordType) => {
  const client = await api.client;
  const response = await client.create_payment_record_phone_pay_v2_payment_record_create_phone_pay_post(null, data);
  return response.data;
};

export const GetPhonePayPaymentStatus = async (merchantTransactionId: string) => {
  const client = await api.client;
  const response = await client.report_razorpay_success_v2_payment_record_report_phonepay_success_post(merchantTransactionId);
  return response.data;
};
