import { useMutation } from "react-query";
import { convertUrlToCDNUrl } from "../../../utils/utils";
import { Box, Button, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import { createApplicationV3 } from "../../../api/utils/api/v2/application";
import { axiosErrorHandler } from "../../../api/utils/error";
import useSnackBar from "../../common/general/SnackBar";
import { useHistory, useParams } from "react-router-dom";
import { OnboardingStepInterface } from "../../../constants/onboarding";
import { softwareDevelopmentOnbaordingStepList } from "../../../constants/revamp/papOnbaording";
import { ApplicationType } from "../../../api/schemas/schema";

interface Props {
  setCurrentOnboardingStep: (value: number) => void;
  setOnboardingStepList: (args: OnboardingStepInterface[]) => void;
  activeApplication: ApplicationType;
}

const EligibleCard = ({ setCurrentOnboardingStep, setOnboardingStepList, activeApplication }: Props) => {
  const snackbar = useSnackBar();
  const history = useHistory();
  const { courseId } = useParams<{ courseId: string }>();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { mutate: createApplicationMutate, isLoading: isCreateApplicationLoading } = useMutation(createApplicationV3, {
    onSuccess: (data) => {
      setCurrentOnboardingStep(3);
      softwareDevelopmentOnbaordingStepList[0].isCurrentStep = false;
      softwareDevelopmentOnbaordingStepList[0].isStepCompleted = true;
      softwareDevelopmentOnbaordingStepList[1].isStepCompleted = true;
      softwareDevelopmentOnbaordingStepList[1].isCurrentStep = false;
      setOnboardingStepList([...softwareDevelopmentOnbaordingStepList]);
      if (data && data.slack_link) {
        window.open(data?.slack_link, "_blank");
      }
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });
  return (
    <Box
      border={"1px solid #E5E5E5"}
      bg={"white"}
      boxShadow={"0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);"}
      rounded={"16px"}
      maxW={"602px"}
      w={"full"}
      px={{ base: "20px", md: "60px" }}
      py={{ base: "30px" }}
      display={"flex"}
      alignItems={"center"}
      flexDir={"column"}
      minH={{ base: "auto", md: "410px" }}
      justifyContent={"center"}
    >
      <Image w="48px" h="48px" mx="auto" src={convertUrlToCDNUrl("https://masai-website-images.s3.ap-south-1.amazonaws.com/psicons_c9f98d477a.webp")} alt="consent check" />
      <Text fontSize={{ base: "20px", md: "24px" }} mt={5} fontFamily={"Poppins"} fontWeight={700} color={"#3470E4"}>
        You&apos;re Eligible!
      </Text>
      <Text fontSize={{ base: "14px", md: "16px" }} textAlign={"center"} fontFamily={"Poppins"} fontWeight={500} color={"#544D4F"} mt={2}>
        Click the button below to complete your application. <br />
        Note: Once applied, you cannot change the course.
      </Text>
      <Flex gap={{ base: 4, md: 6 }} direction={{ base: "column", md: "row" }} align={"center"} justify={"center"} mt={{ base: "30px", md: "48px" }}>
        <Button
          size={isMobile ? "sm" : "md"}
          variant="primary"
          onClick={() => {
            createApplicationMutate({ course_id: courseId || "12" });
          }}
          isDisabled={isCreateApplicationLoading || !!activeApplication}
          isLoading={isCreateApplicationLoading}
        >
          Confirm Application
        </Button>
        <Button
          size={isMobile ? "sm" : "md"}
          variant="solid"
          color={"#3470E4"}
          bg={"#F2F6FF"}
          onClick={() => {
            history.push("/");
          }}
        >
          Not Interested
        </Button>
      </Flex>
    </Box>
  );
};

export default EligibleCard;
