import { Box, Text, Image } from "@chakra-ui/react";
import React from "react";
import { useProfile } from "../../../../api/utils/hooks/useProfile";
import { sendTrackingEvent } from "../../../common/utils/gtm";
import { useLocation } from "react-router-dom";
import { LinkedIn } from "../../../../assets/icons/components";

const SocialMedia = ({
  gtm_events,
}: {
  gtm_events?: {
    [key: string]: string;
  };
}) => {
  const { data: profile } = useProfile();
  const shareUrl = `${window.location.protocol}//${process.env.REACT_APP_MASAI_WEBSITE_URL}?referralCode=${profile?.referral_code || 0}`;
  const location = useLocation();
  return (
    <Box display={"grid"} justifyContent={"center"}>
      <Text textStyle={"body2-md"} my="12px" mx="auto">
        Or invite via
      </Text>
      <Box display={"flex"} agp="16px" alignItems={"center"} gap="16px">
        {/* Whatsapp Button */}
        <Box
          as="a"
          target="_blank"
          href={`https://wa.me/?text=${encodeURIComponent(shareUrl)}`}
          rel="noopener noreferrer"
          cursor={"pointer"}
          display={"flex"}
          gap="8px"
          alignItems={"center"}
          bgColor={"#22C47B"}
          borderRadius={"8px"}
          py="8px"
          px="12px"
          onClick={() => {
            sendTrackingEvent({
              event: gtm_events?.whatsapp || "",
              eventLabel: location.pathname,
            });
          }}
        >
          <Text
            textStyle={"body2-md"}
            letterSpacing={"1.25px"}
            fontSize={"14px"}
            fontWeight={600}
            lineHeight={"24px"}
            color="#fff"
          >
            WhatsApp
          </Text>
          <Image src="/img/referral/whatsapp.svg" />
        </Box>
        {/* LinkedIn Button */}
        <Box
          as="a"
          target="_blank"
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            shareUrl
          )}`}
          cursor={"pointer"}
          py="8px"
          px="16px"
          border="1px solid #CCC"
          borderRadius={"8px"}
          onClick={() => {
            sendTrackingEvent({
              event: gtm_events?.linkedin || "",
              eventLabel: location.pathname,
            });
          }}
        >
          <LinkedIn boxSize="24px" />
        </Box>
        {/* Telegram Button */}
        <Box
          as="a"
          target="_blank"
          href={`https://t.me/share/url?url=${encodeURIComponent(shareUrl)}`}
          cursor={"pointer"}
          py="8px"
          px="16px"
          border="1px solid #CCC"
          borderRadius={"8px"}
          onClick={() => {
            sendTrackingEvent({
              event: gtm_events?.telegram || "",
              eventLabel: location.pathname,
            });
          }}
        >
          <Image src="/img/referral/telegram.svg" />
        </Box>
        {/* Facebook Button */}
        <Box
          as="a"
          target="_blank"
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`}
          cursor={"pointer"}
          py="8px"
          px="16px"
          border="1px solid #CCC"
          borderRadius={"8px"}
          onClick={() => {
            sendTrackingEvent({
              event: gtm_events?.facebook || "",
              eventLabel: location.pathname,
            });
          }}
        >
          <Image src="/img/referral/facebook.svg" />
        </Box>
      </Box>
    </Box>
  );
};

export default SocialMedia;
