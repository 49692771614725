import React from "react";
import { Icon } from "@chakra-ui/react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GreenCheckCircle = (props: any) => {
  return (
    <Icon viewBox="0 0 57 56" fill="#6FCD9E" {...props}>
      <path
        d="M28.4998 4.66675C15.6665 4.66675 5.1665 15.1667 5.1665 28.0001C5.1665 40.8334 15.6665 51.3334 28.4998 51.3334C41.3332 51.3334 51.8332 40.8334 51.8332 28.0001C51.8332 15.1667 41.3332 4.66675 28.4998 4.66675ZM28.4998 46.6667C18.2098 46.6667 9.83317 38.2901 9.83317 28.0001C9.83317 17.7101 18.2098 9.33341 28.4998 9.33341C38.7898 9.33341 47.1665 17.7101 47.1665 28.0001C47.1665 38.2901 38.7898 46.6667 28.4998 46.6667ZM39.2098 17.6867L23.8332 33.0634L17.7898 27.0434L14.4998 30.3334L23.8332 39.6667L42.4998 21.0001L39.2098 17.6867Z"
      />
    </Icon>
  );
};

export default GreenCheckCircle;
