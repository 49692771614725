import { GetEventType } from "../../api/schemas/schema";
import get from "lodash.get";

export interface Speaker {
  shortAbout: string;
  name: string;
  // png
  image: string;
  linkedInUrl: string;
  about: string;
}

export interface Activity {
  id: number;
  title: string;
  heading: string;
  bgImage: string;
  mobileBgImage: string;
  bgHexColor: string;
  startTime: string;
  endTime: string;
  type: GetEventType["event_type"];
  description: string;
  speakers: Speaker[];
  thumbnail: string;
  mobileThumbnail: string;
  registrationCount: number;
  whatsAppLink: string;
  whatsAppQRImageUrl: string;
  recordingUrl: string;
  liveUrl: string;
  isRegistered: boolean;
  meetingID: string;
  meetingPass: string;
  videoUrl: string;
  eventLocation?: "ONLINE" | "OFFLINE";
}

const platforms = ["onward", "website"];

export function convertGetEventToActivity(event: GetEventType, platform = "onward"): Activity {
  const anotherPlatform = platforms.find((p) => p !== platform) || "onward";
  const getDetail = (key: string) => {
    return get(event, ["details", platform, 0, key], get(event, ["details", anotherPlatform, 0, key]));
  };
  const title = get(event, "name");
  const registrationCount = get(event, "total_registrations", 0);
  const startTime = get(event, "event_start_date");
  const endTime = get(event, "event_end_data");
  const bgHexColor = getDetail("bgHexColor");
  const heading = getDetail("heading");
  const bgImage = getDetail("bgImage");
  const mobileBgImage = getDetail("mobileBgImage");
  const type = get(event, "event_type");
  const description = getDetail("description");
  const speakerName = getDetail("speakerName");
  const speakerImage = getDetail("speakerImage");
  const speakerAbout = getDetail("speakerAbout");
  const speakerShortAbout = getDetail("speakerShortAbout");
  const speakerLinkedInUrl = getDetail("speakerLinkedInUrl");

  const speaker0: Speaker = {
    name: speakerName,
    image: speakerImage,
    about: speakerAbout,
    shortAbout: speakerShortAbout,
    linkedInUrl: speakerLinkedInUrl,
  };

  const speakers = [speaker0];

  for (let i = 2; i < 5; i++) {
    const speakerName = getDetail("speakerName" + i);
    const speakerImage = getDetail("speakerImage" + i);
    const speakerAbout = getDetail("speakerAbout" + i);
    const speakerShortAbout = getDetail("speakerShortAbout" + i);
    const speakerLinkedInUrl = getDetail("speakerLinkedInUrl" + i);

    if (!speakerName || !speakerLinkedInUrl || !speakerImage || !speakerAbout) break;

    const speaker: Speaker = {
      name: speakerName,
      image: speakerImage,
      about: speakerAbout,
      shortAbout: speakerShortAbout,
      linkedInUrl: speakerLinkedInUrl,
    };

    speakers.push(speaker);
  }
  const thumbnail = getDetail("thumbnail");
  const mobileThumbnail = getDetail("mobileThumbnail") || thumbnail;
  const whatsAppLink = getDetail("whatsAppLink");
  const whatsAppQRImageUrl = getDetail("whatsAppQRImageUrl");
  const recordingUrl = getDetail("recordingUrl");
  const liveUrl = getDetail("liveUrl");
  const videoUrl = getDetail("videoUrl");
  const meetingID = getDetail("meetingID");
  const meetingPass = getDetail("meetingPass");
  const isRegistered = get(event, "registered", false);
  const id = get(event, "id");
  const eventLocation = get(event, "event_location", "ONLINE");

  const activity: Activity = {
    id,
    bgHexColor,
    title,
    heading,
    registrationCount,
    bgImage,
    mobileBgImage,
    endTime,
    startTime,
    type,
    description,
    speakers,
    thumbnail,
    mobileThumbnail,
    whatsAppLink,
    whatsAppQRImageUrl,
    recordingUrl,
    liveUrl,
    isRegistered,
    meetingID,
    meetingPass,
    videoUrl,
    eventLocation,
  };
  return activity;
}

export function fixHTMLBold(html: string) {
  return html.replace(/<b>/g, "<span style='color:#ED0331'>").replace(/<\/b>/g, "</span>");
}

export const sampleEvent: GetEventType = {
  id: 1,
  name: "A prompt engineering",
  event_type: "WEBINAR",
  event_start_date: "2023-08-27T12:00:00Z",
  event_end_data: "2023-08-27T13:30:00Z",
  total_registrations: 12,
  details: {
    onward: [
      {
        bgHexColor: "rgba(0,0,255,0.1)",
        heading: "learn to build amazing tools using <b>ChatGPT</b> in 60 minutes",
        bgImage: "https://dashboard.masaischool.com/static/media/event.0a928c16.png",
        mobileBgImage: "https://dashboard.masaischool.com/static/media/event.0a928c16.png",
        description: "✅ Breakdown of MERN Stack,✅ How to learn MERN Stack?,✅ MEAN Stack VS MERN Stack,✅ Best MERN stack projects to build",
        speakerName: "Prabhanjan",
        speakerImage: "https://dashboard.masaischool.com/static/media/InstructorImage.0f048b44.svg",
        speakerAbout: "Fullstack Developer",
        speakerShortAbout: "Masai School",
        speakerLinkedInUrl: "linkedin.com",
        thumbnail: "https://dashboard.masaischool.com/static/media/event.0a928c16.png",
        mobileThumbnail: "https://dashboard.masaischool.com/static/media/event.0a928c16.png",
        whatsAppLink: "https://chat.whatsapp.com/i",
        whatsAppQRImageUrl: "https://dashboard.masaischool.com/static/media/event.0a928c16.png",
        recordingUrl: "https://dashboard.masaischool.com/static/media/event.0a928c16.png",
        liveUrl: "https://dashboard.masaischool.com/static/media/event.0a928c16.png",
        videoUrl: "https://dashboard.masaischool.com/static/media/event.0a928c16.png",
      },
    ],
    website: [
      {
        bgHexColor: "rgba(255,0,0,0.1)",
        heading: "Crack the Code",
      },
    ],
  },
  registered: false,
  event_location: "ONLINE",
  cost: "0",
};

export function getEventTypeText(type: GetEventType["event_type"]) {
  if (type === "FOUNDER_WEBINAR") {
    return "Founder Webinar";
  }
  if (type === "CRACK_THE_CODE") {
    return "Crack the Code";
  }
  if (type === "TECHTONIC_SHIFT") {
    return "Techtonic Shift";
  }
  if (type === "AMA") {
    return "Ask me Anything";
  }
  if (type === "MASTERCLASS") {
    return "Masterclass";
  }
  if (type === "IIT_COUNSELING") {
    return "IIT Guwahati AMA";
  }
  if (type === "IIT_MANDI_COUNSELING") {
    return "IIT Mandi AMA";
  }
  return type;
}
