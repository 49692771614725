import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}
export const TryArrow = (props: Props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12ZM12 2L6.46 7.46L7.88 8.88L11 5.75V15H13V5.75L16.13 8.88L17.55 7.45L12 2Z"
      fill="currentColor"
    />
  </Icon>
);

export default TryArrow;
