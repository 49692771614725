import React, { useMemo } from "react";
import { useTimeLeft } from "./useCountDown";
import { Box, Heading, Text } from "@chakra-ui/react";

function CountDown({ till }: { till: string }) {
  const { timeLeft, isLoading } = useTimeLeft(till);
  const daysLeft = useMemo(() => {
    if (!timeLeft) return "--";
    return Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  }, [timeLeft]);
  const hoursLeft = useMemo(() => {
    if (!timeLeft) return "--";
    return Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
  }, [timeLeft]);
  const minutesLeft = useMemo(() => {
    if (!timeLeft) return "--";
    return Math.floor((timeLeft / (1000 * 60)) % 60);
  }, [timeLeft]);
  const secondsLeft = useMemo(() => {
    if (!timeLeft) return "--";
    return Math.floor((timeLeft / 1000) % 60);
  }, [timeLeft]);
  const toShow = useMemo(() => {
    if (isLoading)
      return [
        ["--", "Days"],
        ["--", "Hours"],
        ["--", "Mins"],
      ];
    if (daysLeft) {
      return [
        [daysLeft, "Days"],
        [hoursLeft, "Hours"],
        [minutesLeft, "Mins"],
      ];
    }
    return [
      [hoursLeft, "Hours"],
      [minutesLeft, "Mins"],
      [secondsLeft, "Secs"],
    ];
  }, [isLoading, daysLeft, hoursLeft, minutesLeft, secondsLeft]);
  return (
    <Box
      textAlign={"center"}
      alignItems={"center"}
      display={"flex"}
      flexWrap={"nowrap"}
      gap={{ base: 2, md: 4 }}
    >
      {toShow.map(([value, unit], index) => (
        <>
          <Box
            p={2}
            minW={{ base: "64px", md: "100px" }}
            minH={{ md: "100px", base: "64px" }}
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            flexDirection={"column"}
            gap={{ md: 2 }}
            bgColor={"white"}
            key={index}
            rounded={"lg"}
          >
            <Text
              lineHeight={{ base: "1.4rem", md: "1.5rem" }}
              color="#555555"
              fontWeight={700}
              fontSize={{ md: "4xl", base: "2xl" }}
            >
              {value}
            </Text>
            <Text fontSize={{ base: "xs", md: "sm" }} fontWeight={600} color="#FF0A36">
              {unit}
            </Text>
          </Box>
          {index !== toShow.length - 1 ? (
            <Heading color="#555555" as={"h2"}>
              :
            </Heading>
          ) : null}
        </>
      ))}
    </Box>
  );
}

export default CountDown;
