import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const DostNav = () => {
  return (
    <Box bg="#FFE180" px="16px" display="flex" gap="8px" py="5px" alignItems="center">
      <Link to="/">
        <ArrowBackIcon boxShadow="32px" w="32px" h="32px" />
      </Link>
      <Text textStyle="body2-md">Dost Programme</Text>
    </Box>
  );
};

export default DostNav;
