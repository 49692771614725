import { Box, Button, Heading, Skeleton, Text, useBreakpointValue } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import MSATResultCard from "../../../components/V2/MSATResult/MSATResultCard";
import CourseSelectionCard from "../../../components/V2/MSATResult/CourseSelectionCard";
import { useMutation } from "react-query";
import { createApplication, startOnboarding } from "../../../api/utils/api/v2/application";
import useSnackBar from "../../../components/common/general/SnackBar";
import { axiosErrorHandler } from "../../../api/utils/error";
import { ModalTemplate, ModalTemplateV2 } from "../../../components/common/general";
import MSATModalContent from "../../../components/V2/ModalContent/MSATModalContent";
import { useHistory } from "react-router-dom";

import CounsellingBookNow from "../../../components/common/V2/CounsellingBookNow";
import MSATButtons from "../../../components/V2/MSATResult/MSATButtons";
import { useActiveApplication } from "../../../api/utils/hooks/useApplication";
import { recordCancelAssessment } from "../../../api/utils/api/v2/assessment";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import { useAllCounsellingStatus } from "../../../api/utils/hooks/useCounsellingStatus";
import NavbarTimerBanner from "../../../components/V2/NavbarTimerBanner/NavbarTimerBanner";
import { useBestAssessment, useBestAssessmentForEmpowerHer } from "../../../api/utils/hooks/useBestAssessment";
import { COUNSELLING_TYPE, GetCourseV2Type } from "../../../api/schemas/schema";
import { AppContext, ApplicationActionType } from "../../../context/ApplicationContext";
import IItCourseSelectionCard from "../../../components/iit-course/IItCourseSelectionCard";
import WaitingListCard from "../../../components/iit-course/WaitingListCard";
import { useCourses } from "../../../api/utils/hooks/useCourse";
import { ProgramProfileType } from "../../../enums/ProgramType.enum";
import MsatSDETCourseInfo from "../../../components/common/MsatSDETCourseInfo";
import VideoModal from "../../../components/iit-course/modals/VideoModal";
import OnboardingStepTracker from "../../../components/IIT-Onboarding/OnboardingStepTracker";
import { useFounderWebinars } from "../../../api/utils/hooks/useEvents";

const MAX_ATTEMPTS = 20; // Maximum number of attempts
const POLL_INTERVAL = 15000; // Polling interval in milliseconds (10 seconds)

const onboardingSteps = [
  {
    id: 1,
    key: "webinar-card",
    value: "Register for a free Webinar",
    marginLeft: "-2px",
  },
  {
    id: 2,
    key: "start-msat",
    value: "Finish MSAT & Select Course",
    marginLeft: "-22px",
  },
  {
    id: 3,
    key: "onboarding",
    value: "Complete Onboarding",
    marginLeft: "-12px",
  },
];

const MSATResult = () => {
  const { data: bestAssessment, status, isLoading: bestAssessmentLoading, refetch } = useBestAssessment();
  const { data: allCourses, refetch: refetchCourses, isLoading: isCourseLoading } = useCourses();
  const [attempts, setAttempts] = useState(0);
  const [state, dispatch] = useContext(AppContext);
  const [msatResultStatus, setMsatResultStatus] = React.useState<"PASSED" | "FAILED" | "PENDING">();
  const [isFirstAttempt, setIsFirstAttempt] = React.useState<boolean>(true);
  const [showMsatConfirmModal, setShowMsatConfirmModal] = React.useState(false);
  const [selectedCourse, setSelectedCourse] = React.useState<number | null>(null);
  const snackbar = useSnackBar();
  const history = useHistory();
  const [showMSATResultPendingModal, setShowMSATResultPendingModal] = React.useState(false); // [TODO
  const [createNewTest, setCreateNewTest] = React.useState<boolean>(false);
  const [pollInterval, setPollInterval] = useState<NodeJS.Timeout | null>(null); // State to store the interval reference
  const [totalEligibeCourses, setTotalEligibeCourses] = React.useState<number>(0);
  const [registrationFee, setRegistrationFee] = React.useState<number>(0);
  const [hasGiven2Attempts, setHasGiven2Attempts] = React.useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { data: application, isLoading: isApplicationLoading, refetch: refetchApplication } = useActiveApplication();
  const { data: allCounsellingStatus, refetch: refetchAllCounsellingStatus } = useAllCounsellingStatus();
  const [currentOnboardingStep, setCurrentOnboardingStep] = useState(1);

  const [timelines, setTimelines] = useState<{ date: string; overdue: boolean }[]>();

  const { refetch: refetchProfile, data: profile } = useProfile((data) => {
    dispatch({
      type: ApplicationActionType.SET_COURSE_PREFERENCE,
      payload: {
        coursePreference: data?.program_profile_type as ProgramProfileType,
      },
    });
  });
  const { data: founderWebinars } = useFounderWebinars();

  const restrictedApplicationStatus = ["ONBOARDING_COMPLETE", "FELLOW", "ISA_SUCCESSFUL"];

  const isMasaiCoursePrefered = profile?.program_profile_type === "MASAI" ? true : false;

  useEffect(() => {
    if (!isApplicationLoading && application && restrictedApplicationStatus.includes(application?.status)) {
      console.log("Restricted Application Status found redirecting to home page");
      history.push("/");
    }
    if (!bestAssessmentLoading && !bestAssessment) {
      history.push("/");
    }
  }, [bestAssessmentLoading, isApplicationLoading, bestAssessment, application]);

  useEffect(() => {
    if (bestAssessment?.status !== "GRADED") {
      setMsatResultStatus("PENDING");
    } else if (bestAssessment?.eligible_courses?.length && bestAssessment?.eligible_courses?.length > 0) {
      setMsatResultStatus("PASSED");
    } else {
      setMsatResultStatus("FAILED");
    }

    setTotalEligibeCourses(bestAssessment?.eligible_courses?.filter((course) => course !== 20)?.length || 0);
    setIsFirstAttempt(bestAssessment?.attempt_count === 1);
    if (bestAssessment?.attempt_count === 2 && bestAssessment?.eligible_courses?.filter((course) => course !== 20)?.length === 1 && bestAssessment.eligible_courses.includes(15)) {
      setHasGiven2Attempts(true);
    }
  }, [bestAssessment]);

  useEffect(() => {
    if (attempts > MAX_ATTEMPTS) {
      if (pollInterval) {
        clearInterval(pollInterval);
      }
      return;
    }

    if (attempts < MAX_ATTEMPTS && status === "success") {
      // Check if the desired result is obtained, and if so, stop polling
      if (bestAssessment && bestAssessment.status === "GRADED") {
        if (pollInterval) {
          clearInterval(pollInterval);
        }
      }
    }
  }, [attempts, bestAssessment, status, pollInterval]);

  useEffect(() => {
    // Polling mechanism using setInterval
    const intervalId = setInterval(() => {
      setAttempts((prevAttempts) => prevAttempts + 1);
      refetch(); // Manually trigger a refetch after each polling interval
    }, POLL_INTERVAL);

    setPollInterval(intervalId); // Store the interval reference in state

    // Clear interval when component unmounts or when desired result is obtained
    return () => {
      clearInterval(intervalId);
    };
  }, [refetch]);

  useEffect(() => {
    refetchApplication();
    refetchCourses();
  }, []);

  useEffect(() => {
    if (!isMasaiCoursePrefered && allCourses && allCourses.length > 0) {
      setRegistrationFee(allCourses[0].registration_fee || 15000);
    }
  }, [allCourses]);

  useEffect(() => {
    if (founderWebinars && !founderWebinars[0]?.registered) {
      setCurrentOnboardingStep(1);
    } else if (!bestAssessment || (bestAssessment && bestAssessment?.status !== "GRADED")) {
      setCurrentOnboardingStep(2);
    } else if (!application || (application && application.status !== "ONBOARDING_COMPLETE")) {
      setCurrentOnboardingStep(3);
    } else if (founderWebinars && founderWebinars[0]?.registered && bestAssessment && bestAssessment?.status === "GRADED" && application && application.status === "ONBOARDING_COMPLETE") {
      setCurrentOnboardingStep(4);
    }
  }, [founderWebinars, profile, application, bestAssessment]);

  const { mutate: startOnboardingMuatate, isLoading: isStartOnboardingLoading } = useMutation(startOnboarding, {
    onSuccess: () => {
      history.push("/onboarding");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  const { mutate: createApplicationMutate, isLoading: isCreateApplicationLoading } = useMutation(createApplication, {
    onSuccess: () => {
      startOnboardingMuatate();
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  useEffect(() => {
    if (bestAssessment && bestAssessment?.status === "ENDED" && !showMSATResultPendingModal) {
      setShowMSATResultPendingModal(true);
    } else {
      setShowMSATResultPendingModal(false);
    }
  }, [bestAssessment]);

  const { mutate: cancelAssessmentMutate } = useMutation(recordCancelAssessment, {
    onSuccess: () => {
      refetchProfile();
      refetchAllCounsellingStatus();
      setShowMsatConfirmModal(false);
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  return (
    <>
      <NavbarTimerBanner showTimer={false} />
      {bestAssessmentLoading ? (
        <Skeleton h="100vh" my="1rem" isLoaded={!bestAssessmentLoading || !isCourseLoading} />
      ) : (
        <Box py="40px" border={"1px solid red"} overflow={"hidden"} minH="100vh" height={"100%"} px="16px" bgColor={"ms-blue.50"}>
          <Box>
            <OnboardingStepTracker
              currentOnboardingStep={currentOnboardingStep}
              showIITGuwahatiDashboard={true}
              onboardingSteps={onboardingSteps}
              timelines={timelines}
              isAllStepsCompleted={currentOnboardingStep === 4}
              isMsatResultPage={true}
            />
          </Box>
          {!showMSATResultPendingModal && (
            <Box
              mt={{ base: "40px", md: "100px" }}
              mb={{ base: "20px", md: "0px" }}
              alignContent={{ md: "center" }}
              display={{ md: "grid" }}
              gridTemplateColumns={{ md: "repeat(2,1fr)" }}
              gap={{ md: "32px" }}
            >
              {msatResultStatus !== "PENDING" && (
                <MSATResultCard isMasaiCoursePrefered={isMasaiCoursePrefered} bestAssessment={bestAssessment} loadingbestAssessment={bestAssessmentLoading} refetch={refetch} />
              )}
              {/* Second attempt failed */}
              {msatResultStatus === "FAILED" && !isFirstAttempt ? (
                state.coursePreference === ProgramProfileType.MASAI ? (
                  <Box mt="12px">
                    <CounsellingBookNow
                      source={isMasaiCoursePrefered ? COUNSELLING_TYPE.MSAT_FAIL_2ND_ATTEMPT : COUNSELLING_TYPE.MSAT_FAIL_2ND_ATTEMPT_IITG}
                      borderRadius="12px"
                      boxShadow="0px 2px 4px -1px rgba(135, 200, 213, 0.65), 0px 4px 6px -1px #B0E0EB;"
                    />
                  </Box>
                ) : (
                  <Box>
                    <WaitingListCard />
                  </Box>
                )
              ) : (
                <Box bgColor={"#fff"} border="1px solid #D9D9D9" borderRadius="12px" maxW={"full"} mt={{ base: "24px", md: "0px" }}>
                  <Box borderTopLeftRadius="12px" borderTopRightRadius={"12px"} p="16px" bgColor={"ms-yellow.100"}>
                    {state.coursePreference === ProgramProfileType.MASAI &&
                      (msatResultStatus === "PASSED" || msatResultStatus === "FAILED") &&
                      (hasGiven2Attempts ? (
                        <Box display={"flex"}>
                          {msatResultStatus === "PASSED" ? <MsatSDETCourseInfo /> : <Text textStyle={"body2"}>Based on your MSAT result you have not cleared cut off for any of our courses. </Text>}
                        </Box>
                      ) : (
                        <Text textStyle={"body2"}>
                          {msatResultStatus === "PASSED"
                            ? `Based on your MSAT result you have cleared cut off for ${totalEligibeCourses} courses.`
                            : "Based on your MSAT result you have not cleared cut off for any of our courses."}
                        </Text>
                      ))}

                    {state.coursePreference !== ProgramProfileType.MASAI && (msatResultStatus === "PASSED" || msatResultStatus === "FAILED") && (
                      <Text fontSize={"16px"} fontWeight={600} textAlign={"center"}>
                        {msatResultStatus === "PASSED" ? "Based on your Entrance Test result you have cleared cut off." : "Based on your Entrance Test result you have not cleared the cut off."}
                      </Text>
                    )}

                    {state.coursePreference === ProgramProfileType.MASAI &&
                      msatResultStatus === "PASSED" &&
                      (hasGiven2Attempts ? (
                        <Text mt="4px" textStyle="body2-md">
                          Get started now and pave the way for an exciting career in SDET! 🚀
                          <span
                            onClick={() => {
                              setOpenDrawer(true);
                              setVideoUrl("https://youtu.be/a0dbBTdAjVc");
                            }}
                            style={{ marginLeft: "10px", color: "blue", textDecoration: "underline", cursor: "pointer" }}
                          >
                            Watch this Video
                          </span>
                        </Text>
                      ) : (
                        <Text mt="4px" textStyle="body2-md">
                          Select course that you want to apply for or Retake MSAT.
                        </Text>
                      ))}
                  </Box>
                  {state.coursePreference !== ProgramProfileType.MASAI && msatResultStatus === "PASSED" && (
                    <Box bg={"#3B3435"} px={6} py={4} color={"white"} maxW={"90%"} mx={"auto"} rounded={"12px"} my={5}>
                      <Text fontSize={"16px"} fontWeight={400}>
                        Reserve your seat at just
                      </Text>
                      <Heading as={"h3"}>₹ {registrationFee}/-</Heading>
                      <Text fontSize={"12px"} fontWeight={100} color={"#B3B3B3"}>
                        Note: Registration fees are non refundable.
                      </Text>
                    </Box>
                  )}
                  {state.coursePreference === ProgramProfileType.MASAI ? (
                    <CourseSelectionCard selectedCourse={selectedCourse} setSelectedCourse={setSelectedCourse} />
                  ) : state.coursePreference === ProgramProfileType.IIT_GUWAHATI ? (
                    <IItCourseSelectionCard
                      selectedCourse={selectedCourse}
                      setSelectedCourse={setSelectedCourse}
                      isMasaiCoursePrefered={isMasaiCoursePrefered}
                      allCourses={allCourses as GetCourseV2Type[]}
                    />
                  ) : null}
                  <Box
                    display={{
                      base: "none",
                      md: "block",
                    }}
                    px="16px"
                  >
                    <MSATButtons
                      msatResultStatus={msatResultStatus}
                      isFirstAttempt={isFirstAttempt}
                      createNewTest={createNewTest}
                      setShowMsatConfirmModal={setShowMsatConfirmModal}
                      createApplicationMutate={createApplicationMutate}
                      isCreateApplicationLoading={isCreateApplicationLoading}
                      bestAssessment={bestAssessment}
                      selectedCourse={selectedCourse}
                      bestAssessmentLoading={false}
                      setCreateNewTest={setCreateNewTest}
                      profile={profile}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          )}

          {/* Buttons here */}
          <Box
            display={{
              base: "block",
              md: "none",
            }}
          >
            <MSATButtons
              msatResultStatus={msatResultStatus}
              isFirstAttempt={isFirstAttempt}
              createNewTest={createNewTest}
              setShowMsatConfirmModal={setShowMsatConfirmModal}
              createApplicationMutate={createApplicationMutate}
              isCreateApplicationLoading={isCreateApplicationLoading || isStartOnboardingLoading}
              bestAssessment={bestAssessment}
              selectedCourse={selectedCourse}
              bestAssessmentLoading={false}
              setCreateNewTest={setCreateNewTest}
            />
          </Box>
        </Box>
      )}
      <ModalTemplate
        title=""
        isOpen={showMsatConfirmModal}
        onClose={() => {
          cancelAssessmentMutate();
          setShowMsatConfirmModal(false);
        }}
        marginX={2}
        maxWidth="484px"
        showCloseButtonIcon={false}
        bgColor="ms-blue.100"
      >
        <MSATModalContent createNewTest={createNewTest} setShowMsatConfirmModal={setShowMsatConfirmModal} />
      </ModalTemplate>
      {showMSATResultPendingModal && (
        <ModalTemplateV2
          closeOnOverlayClick={false}
          isOpen={showMSATResultPendingModal}
          hideIconWithConfetti={true}
          waiting={true}
          onClose={() => {
            setShowMSATResultPendingModal(false);
          }}
          text={
            <Box>
              <Heading as="h5" color="#fff">
                {!isMasaiCoursePrefered ? "Thank you for attempting Entrance Test!" : "Thank you for attempting MSAT!"}{" "}
              </Heading>
              <Text mt="4px" textStyle="body1" color="#fff">
                Please wait while we are getting your scores.
              </Text>
            </Box>
          }
          extra={
            <>
              <Button
                as="a"
                onClick={() => {
                  window.location.reload();
                }}
                mt="28px"
                variant="secondary"
              >
                Refresh Result Status
              </Button>
            </>
          }
        />
      )}

      <ModalTemplate
        modalOverlayProps={{ backgroundColor: "rgba(1, 10, 21, 0.8)" }}
        title={""}
        isOpen={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
          setVideoUrl("");
        }}
        showCloseButtonIcon={true}
        maxWidth="740px"
        paddingX={isMobile ? "12px" : "16px"}
        paddingY={isMobile ? "0px" : "16px"}
      >
        <VideoModal videoUrl={videoUrl} />
      </ModalTemplate>
    </>
  );
};

export default MSATResult;
