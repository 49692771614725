import { Box, Button, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CSVFileUpload from "../CSVFileUpload";
import DropdownNew from "../../../common/general/DropdownNew";
import { useMutation } from "react-query";
import {
  updateDostRequestStatus,
  updateDostRequestStatusBulk,
} from "../../../../api/utils/api/v2/admin";
import { axiosErrorHandler } from "../../../../api/utils/error";
import { DostRequestStatusType } from "../../../../api/schemas/schema";
import useSnackBar from "../../../common/general/SnackBar";
import { useAllDostRequests } from "../../../../api/utils/hooks/useAdmin";

const DostBulkUpload = ({ offset, limit }: { offset: number; limit: number }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [csvData, setCSVData] = useState<string[]>([]); // Change the type as per your CSV structure
  const [statusToBeUpdated, setStatusToBeUpdated] = useState<DostRequestStatusType>("COMPLETED");
  const {
    data: allDostRequests,
    refetch: refetchAllDostRequests,
    isLoading: allDostRequestsLoading,
  } = useAllDostRequests(offset, limit);
  const snackbar = useSnackBar();
  const {
    mutate: updateDostRequestStatusBulkMutate,
    isLoading: updateDostRequestStatusBulkLoading,
  } = useMutation(updateDostRequestStatusBulk, {
    onSuccess: () => {
      refetchAllDostRequests();
      snackbar.success("Status updated successfully!");
    },
    onError: (error) => {
      const e = axiosErrorHandler(error);
      if (typeof e === "object" && "message" in e) {
        snackbar.error(e.message);
      } else {
        snackbar.error("Something went wrong");
      }
    },
  });

  return (
    <Box my="20px" border="1px solid" borderColor={"ms-grey.200"} p="20px" borderRadius={"12px"}>
      <Heading as="h3" mb="16px" color="ms-grey.700">
        Update dost request status in Bulk
        <Text as="span" textStyle={"body2-md"} ml="12px">
          (Upload csv file which should just have 1 column containing list of emails)
        </Text>
      </Heading>

      <CSVFileUpload csvData={csvData} setCsvData={setCSVData} />
      {csvData.length > 0 && (
        <Box
          bgColor={"ms-grey.100"}
          mt="12px"
          borderRadius={"12px"}
          border="1px solid"
          borderColor={"ms-grey.200"}
          height={"200px"}
          overflow={"scroll"}
          p="20px"
        >
          {csvData.map((email, i) => {
            return (
              <Text key={i} textStyle={"body1-md"}>
                {email}
              </Text>
            );
          })}
        </Box>
      )}
      <Box mt="16px" display={"flex"} gap="8px" alignItems={"center"}>
        <DropdownNew
          options={[
            {
              text: "BOOKED",
            },
            {
              text: "COMPLETED",
            },
          ]}
          value={statusToBeUpdated}
          placeholder="Select status to update"
          getValueCallback={(value) => {
            if (value === "BOOKED" || value === "COMPLETED") {
              setStatusToBeUpdated(value);
            }
          }}
        />
        <Button
          onClick={() => {
            // updateDostRequestStatus({
            //   request_id: 1,
            //   new_status: "BOOKED",
            // });
            updateDostRequestStatusBulkMutate({
              emails: csvData,
              new_status: statusToBeUpdated,
            });
          }}
          disabled={csvData.length === 0}
          variant="primary"
          isLoading={updateDostRequestStatusBulkLoading}
        >
          Update Dost Requests
        </Button>
      </Box>
    </Box>
  );
};

export default DostBulkUpload;
