import { Box, Flex, Skeleton, useBreakpointValue } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { AppContext } from "../../../../context/ApplicationContext";
import StepProgress from "./StepProgress";
import { StatusTypes, Steps, StepsInfomration } from "./types";
import { OnboardingContext } from "../OnboardingProgressPage";

interface ApplicationStatusCardProps {
  children: JSX.Element | JSX.Element[];
  onStepChanged?: (step: Steps) => void;
  statusArray: StatusTypes[];
}

const ApplicationStatusCard = ({
  children,
  onStepChanged,
  statusArray,
}: ApplicationStatusCardProps) => {
  const [state] = useContext(AppContext);
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const onboarding = useContext(OnboardingContext)[0];
  const status = state.applicationData?.status;
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [loading, setLoading] = React.useState<boolean>(false);

  if (!status) {
    return <Skeleton isLoaded={false} />;
  }

  const handleStepCick = (stepNumber: number, stepState: Steps) => {
    setLoading(true);
    console.log(stepNumber, stepState);
    setActiveStep(stepNumber);
    onStepChanged && onStepChanged(stepState);
    setLoading(false);
  };

  useEffect(() => {
    setActiveStep(currentStep + 1);
  }, []);

  const currentStep = statusArray.reduce((a, step) => (step === "completed" ? a + 1 : a), 0);

  const transformStepToStatus = (stepNumber: number): StatusTypes => {
    const stepState = onboarding.oboardingStatus ?? 0;
    if (stepNumber < stepState) {
      return "completed";
    } else if (stepNumber === stepState) {
      return "in-progress";
    } else if (stepNumber > stepState) {
      return "pending";
    }
    return "pending";
  };

  const steps: StepsInfomration[] = [
    {
      description: "Launch Form",
      stepNo: 1,
      stepState: Steps.STEP_ONE,
      status: transformStepToStatus(1),
    },
    {
      description: "Pre-Course",
      stepNo: 2,
      stepState: Steps.STEP_TWO,
      status: transformStepToStatus(2),
    },
  ];

  return (
    <Skeleton isLoaded={!loading}>
      <Box borderRadius="ms-16" p={["0", "1.5rem"]} border={["", "1px solid #E6E5E5"]} minH="456px">
        <Box>
          <Flex direction="row" gap="0.5rem" pl={{ base: "2rem", sm: "0" }}>
            {steps.map((step) => (
              <StepProgress
                key={step.description}
                handleStepCick={handleStepCick}
                description={step.description}
                stepNumber={step.stepNo}
                active={activeStep === step.stepNo}
                status={step.status}
                stepState={step.stepState}
                // hide last stepper
                hideStepper={step.stepNo === steps.length}
              />
            ))}
          </Flex>
        </Box>
        {children}
      </Box>
    </Skeleton>
  );
};

export default ApplicationStatusCard;
