import { Box } from "@chakra-ui/react";
import { listUnappliedBatches } from "../../../api/utils/api";

import CardsLayout from "../../../components/common/layouts/CardsLayout";
import { useCallback, useContext, useEffect, useState } from "react";
import { GetBatch } from "../../../api/schemas/schema";
import { AppContext } from "../../../context/ApplicationContext";
import { useHistory } from "react-router-dom";

import useSnackBar from "../../../components/common/general/SnackBar";
import { axiosErrorHandler } from "../../../api/utils/error";
import ScholarMasaiXCourseCard from "../../Courses/NewCoursesFlow/ScholarProgram/ScholarMasaiXCourseCard";
import { isCourseProgramsIntersectsProfilePrograms } from "../../../components/common/utils/isProgramPresent";

interface Props {
  showCapOnly?: boolean;
}
const CoursesFailPage = ({ showCapOnly }: Props) => {
  const [unappliedBatches, setUnappliedBatches] = useState<GetBatch[]>([]);
  const state = useContext(AppContext)[0];
  const [loading, setLoading] = useState<boolean>(false);
  const snackbar = useSnackBar();
  const history = useHistory();
  useEffect(() => {
    fetchInfo();
  }, [state.profileData?.slug]);

  const fetchInfo = useCallback(async () => {
    setLoading(true);
    if (state.profileData?.slug) {
      try {
        const res = await Promise.allSettled([
          listUnappliedBatches({ profile_slug: state.profileData.slug }).then((res) => {
            setUnappliedBatches(res);
          }),
        ]);
      } catch (err) {
        const e = axiosErrorHandler(err);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("something went wrong");
        }
      }

      setLoading(false);
    }
  }, [state.profileData?.slug]);

  useEffect(() => {
    if (showCapOnly && unappliedBatches.length > 0) {
      const capBatches = unappliedBatches.filter((batch) => {
        return isCourseProgramsIntersectsProfilePrograms(batch?.course.course_programs, [
          {
            id: 4,
            name: "CAP",
          },
        ]);
      });
      setUnappliedBatches(capBatches);
    }
  }, [unappliedBatches]);

  return (
    <Box>
      {unappliedBatches?.length > 0 && (
        <CardsLayout loading={loading} title="Recommended for you">
          {unappliedBatches
            ?.filter((batch) => !batch.is_archived)
            .map((data) => (
              <ScholarMasaiXCourseCard key={data.id} batchData={data} />
            ))}
        </CardsLayout>
      )}
    </Box>
  );
};

export default CoursesFailPage;
