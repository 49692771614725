import React, { useState } from "react";
import { Box, Button, Flex, Heading, Spinner, Text } from "@chakra-ui/react";
import { InputCustom } from "../../common/general";
import { useProfile } from "../../../api/utils/hooks/useProfile";
import { phoneRegExp } from "./ProfileFieldCard";
import { useMutation } from "react-query";
import { sendPhoneOtpV2 } from "../../../api/utils/api/v2/profile";
import useSnackBar from "../../common/general/SnackBar";
import { axiosErrorHandler } from "../../../api/utils/error";

interface Props {
  updatedPhone: string;
  setUpdatedPhone: React.Dispatch<React.SetStateAction<string>>;
  showPhoneOtpModal: boolean;
  setShowPhoneOtpModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdatePhoneModalContent = ({
  showPhoneOtpModal,
  setShowPhoneOtpModal,
  updatedPhone,
  setUpdatedPhone,
}: Props) => {
  const { data: profile } = useProfile();
  const snackbar = useSnackBar();

  const { mutate: sendPhoneOtpMutate, isLoading: sendPhoneOtpLoading } = useMutation(
    sendPhoneOtpV2,
    {
      onSuccess: (data) => {
        snackbar.success("Otp sent on mobile!");
        if (!showPhoneOtpModal) {
          setShowPhoneOtpModal(true);
        }
      },
      onError: (error) => {
        const e = axiosErrorHandler(error);
        if (typeof e === "object" && "message" in e) {
          snackbar.error(e.message);
        } else {
          snackbar.error("Something went wrong");
        }
      },
    }
  );

  return (
    <Box>
      <Heading as="h4">
        {profile?.phone_number ? "Update your mobile number" : "Add your mobile number"}
      </Heading>
      {profile?.phone_number && (
        <>
          <Text mt={"ms-16"} textStyle="body2" color="ms-grey.700">
            Your current mobile number:
          </Text>
          <Text mb={"ms-8"} mt={"ms-4"} textStyle="body2-md" color="ms-grey.700">
            {profile?.phone_number}
          </Text>
        </>
      )}
      <Text mt={"ms-24"} mb={"ms-8"} textStyle="body2-md" color="ms-grey.800">
        {profile?.phone_number
          ? "Please enter your new mobile number"
          : "Please enter your mobile number"}
      </Text>
      <InputCustom
        required
        type="tel"
        isInvalid={updatedPhone !== "" && !phoneRegExp.test(updatedPhone)}
        getValueCallback={(value) => {
          setUpdatedPhone(value);
        }}
        errorMessage={"Please enter a valid 10 digit mobile number"}
        placeholder="Enter your new mobile number"
        onClick={() => {
          //
        }}
      />
      <Flex>
        <Button
          mt={updatedPhone !== "" && !phoneRegExp.test(updatedPhone) ? "14px" : "46px"}
          onClick={() => {
            sendPhoneOtpMutate(updatedPhone || profile?.phone_number || "");
          }}
          size="md"
          variant="primary"
          ml={"auto"}
          disabled={!updatedPhone || sendPhoneOtpLoading || !phoneRegExp.test(updatedPhone)}
          position={sendPhoneOtpLoading ? "relative" : "static"}
        >
          update & verify
          {sendPhoneOtpLoading && (
            <Spinner
              speed={"0.5s"}
              thickness={"2px"}
              size={"md"}
              position={"absolute"}
              color={"ms-blue.500"}
            />
          )}
        </Button>
      </Flex>
    </Box>
  );
};

export default UpdatePhoneModalContent;
