export interface HeaderMenuList {
  title: string;
  url?: string;
  id: string;
  isNew: boolean;
  hasDropDown: boolean;
  subMenuList: { title: string; url: string; id: string; isNew: boolean; isExternal: boolean }[];
}

export const masaiHeaderMenuList: HeaderMenuList[] = [
  { title: "Home", url: "/", id: "home1", isNew: false, hasDropDown: false, subMenuList: [] },
  {
    title: "Scholarship",
    id: "scholarship3",
    isNew: false,
    hasDropDown: true,
    subMenuList: [
      { title: "Sonu Sood Scholarship", url: "/sonu-sood-scholarship", id: "sonu-sood-scholarship4", isNew: false, isExternal: false },
      { title: "EmpowerHer Scholarship", url: "/empower-her", id: "empowerher-scholarship5", isNew: false, isExternal: false },
    ],
  },
  {
    title: "More",
    id: "more6",
    isNew: false,
    hasDropDown: true,
    subMenuList: [
      { title: "MSAT (Admission Test)", url: "/msat", id: "msat9", isNew: false, isExternal: false },
      { title: "Events", url: "/activities", id: "events15", isNew: false, isExternal: false },
      { title: "Alumni Connect", url: "/alumni", id: "alumni-connect16", isNew: false, isExternal: false },
      { title: "Success Stories", url: "https://www.masaischool.com/success-stories", id: "success-stories17", isNew: false, isExternal: true },
    ],
  },
];

export const iitHeaderMenuList: HeaderMenuList[] = [{ title: "Home", url: "/", id: "home1", isNew: false, hasDropDown: false, subMenuList: [] }];

export const iitRoparHeaderMenuList: HeaderMenuList[] = [{ title: "Home", url: "/iit-ropar-ai", id: "home1", isNew: false, hasDropDown: false, subMenuList: [] }];
