const textStyles = {
  h1: {
    fontWeight: "800",
    fontSize: "48px",
    lineHeight: "62px",
    fontFamily: "Poppins",
  },
  h2: {
    fontWeight: "700",
    fontSize: "34px",
    lineHeight: "40px",
    fontFamily: "Poppins",
  },
  h3: {
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "32px",
    fontFamily: "Poppins",
  },
  h4: {
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "28px",
    fontFamily: "Poppins",
  },
  h5: {
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "24px",
    fontFamily: "Poppins",
  },
  h6: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
    fontFamily: "Poppins",
  },
  subtitle1: {
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "34px",
    fontFamily: "Open Sans",
  },
  subtitle2: {
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "34px",
    fontFamily: "Open Sans",
  },
  body1: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Open Sans",
  },
  "body1-md": {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Open Sans",
  },
  body2: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
    fontFamily: "Open Sans",
  },
  "body2-md": {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "24px",
    fontFamily: "Open Sans",
  },
  "btn-sm": {
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    fontFamily: "Open Sans",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
  },
  "btn-md": {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "24px",
    fontFamily: "Open Sans",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
  },
  "btn-lg": {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "24px",
    fontFamily: "Open Sans",
    textTransform: "uppercase",
  },
  caption: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    fontFamily: "Open Sans",
  },
  overline: {
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "16px",
    fontFamily: "Open Sans",
  },
};

export default textStyles;
