import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Code } from "../../../assets/images";

const MSATSectionCards = ({ result }: { result: { [key: string]: string }[] }) => {
  return (
    <Box
      display={"grid"}
      gridGap={"8px"}
      gridTemplateColumns={{
        base: "100%",
        md: "repeat(2,1fr)",
      }}
      gap={{ md: "24px" }}
    >
      {result?.map((card) => {
        return (
          <Box
            p="8px"
            border="1px solid"
            borderColor={"ms-purple.100"}
            key={card.name}
            borderRadius={"16px"}
            display={"flex"}
            gap="12px"
            bg="white"
            flexWrap="wrap"
          >
            <Box minW="fit-content">
              <Image src={Code} alt="marks" />
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box>
                <Text textStyle="body1-md">{card.name}</Text>
                <Text color="ms-grey.700" mt="4px" textStyle="body2">
                  Marks: {card.marks}/{card.maxScore}
                </Text>
              </Box>
              <Box>
                <Text
                  textStyle={"body1-md"}
                  color={
                    card.status === "PASSED"
                      ? "ms-success"
                      : card.status === "NOT CLEARED"
                      ? "ms-error"
                      : "ms-warning"
                  }
                >
                  {card.status}
                </Text>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default MSATSectionCards;
