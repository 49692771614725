import { Icon } from "@chakra-ui/icons";

interface Props {
  color?: string;
  boxSize?: string;
}

export const CalendarIcon = (props: Props) => (
  <Icon viewBox="0 0 16 18" {...props}>
    <path
      d="M5.5 8.33337H3.83333V10H5.5V8.33337ZM8.83333 8.33337H7.16667V10H8.83333V8.33337ZM12.1667 8.33337H10.5V10H12.1667V8.33337ZM13.8333 2.50004H13V0.833374H11.3333V2.50004H4.66667V0.833374H3V2.50004H2.16667C1.24167 2.50004 0.5 3.25004 0.5 4.16671V15.8334C0.5 16.2754 0.675595 16.6993 0.988155 17.0119C1.30072 17.3244 1.72464 17.5 2.16667 17.5H13.8333C14.2754 17.5 14.6993 17.3244 15.0118 17.0119C15.3244 16.6993 15.5 16.2754 15.5 15.8334V4.16671C15.5 3.72468 15.3244 3.30076 15.0118 2.9882C14.6993 2.67564 14.2754 2.50004 13.8333 2.50004ZM13.8333 15.8334H2.16667V6.66671H13.8333V15.8334Z"
      fill="currentColor"
    />
  </Icon>
);

export default CalendarIcon;
