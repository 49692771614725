import { useQuery } from "react-query";

import { ProfileAssessment } from "../../schemas/schema";
import { getLastAssessment } from "../api/v2/assessment";

export function useLastAssessment(onComplete?: (data: ProfileAssessment) => void) {
  return useQuery({
    queryKey: "getLastAssessment",
    queryFn: async () => (await getLastAssessment()) as ProfileAssessment,
    staleTime: 60000,
    retry: false, // Number of times to retry a failed query
    retryOnMount: false,
    onSuccess(data) {
      onComplete && onComplete(data);
    },
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
